// export const baseUrl = 'http://localhost:3001/api/v1'
// export const imageBaseUrl = `http://localhost:3001/uploads/`
// export const notificationsUrl = 'http://localhost:3001'
// export const dashboardUrl = 'http://localhost:3000'
// export const MoyaserClientBaseUrl = `http://localhost:3000`
// export const publishable_api_key =
//   'pk_test_RFaF1XG7UcDkTyLrc3QiExhyg9G1HaywgsioPT6E'

export const baseUrl = 'https://dresscodesa.com:8080/api/v1'
export const imageBaseUrl = `https://dresscodesa.com:8080/uploads/`
export const notificationsUrl = 'https://dresscodesa.com:8080'
export const dashboardUrl = 'https://dresscodesa.com:3000'
export const MoyaserClientBaseUrl = `https://dresscodesa.com`
export const publishable_api_key =
  'pk_live_FQM2rD2iMWLEUS7vRkMSvmUft1KfLA9NktAB2wJ9'
