import React from 'react'
import './animated.css'
const AnimatedLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        background: '#ffffff0a',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: '10000',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default AnimatedLoader
