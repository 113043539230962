import { CSSProperties, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Chip,
  tooltipClasses,
  Tooltip,
  Typography,
  CardMedia,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import { calculateShippingPrice } from './utlts/productShippingPrice'
import { calculateProductsAfterDiscount } from './utlts/calculateAllProductsAfterDiscount'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
  useLazyGetAllCartsQuery,
  useLazyVerifyCartQuery,
  useVerifyCartMutation,
} from '../../../redux/apis/cartApi'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { imageBaseUrl } from '../../../constants/baseUrl'
import { colors } from './styles'
import { useSubmitPointsMutation } from '../../../redux/apis/pointsApi'
import Popover from '@mui/material/Popover'
import { useLazyCouponQueryQuery } from '../../../redux/apis/couponApi'
import styled from '@emotion/styled'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    textAlign: 'center',
    background: '#fff',
    color: '#333',
    padding: '10px',
    border: '1px solid #eee',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
})
const cartPrice = (total, quantity, price) => {
  const pricePlusTax = total / quantity
  const tax = pricePlusTax - price
  return tax
}
const ProductDiscount = (product) => {
  if (JSON.parse(localStorage.getItem('couponData'))) {
    const { products, couponEnter } = JSON.parse(
      localStorage.getItem('couponData')
    )
    if (products?.length && couponEnter !== '') {
      return products?.some((item) => item === product?.product?._id)
    }
  }
}

export const Cart03 = (props) => {
  const [getCartdata, { data: cartData, isSuccess: isSuccessData }] =
    useLazyGetAllCartsQuery()
  const [, { language: lng }] = useTranslation()
  const isRTL = lng === 'ar'
  const [fullCart, setFullCart] = useState()
  const [open, setOpen] = useState(false)
  const [addToCart] = useAddToCartMutation()
  const { data, isSuccess, isError, isLoading } = useGetAllCartsQuery()
  const { currentUser } = useSelector((state) => state)
  const [anchorEl, setAnchorEl] = useState(null)

  const [removeItem, { isLoading: removeLoading }] = useDeleteFromCartMutation()
  const nav = useNavigate()
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const [couponPrice, setTotalCouponPrice] = useState(0)
  const [acceptTerms, setAcceptTerms] = useState(false)
const HandleProperties = (item) => {
  const concatenatedValues = item?.properties
    ?.map((obj) => {
      return Object.values({ obj })
        .map((ob) => {
          return lng === 'en' ? obj.value_en : obj.value_ar
        })
        .join('/')
    })
    .join('/')

  return concatenatedValues
}
  useEffect(() => {
    isSuccess &&
      setFullCart([
        ...data?.data?.cashItems?.items,
        ...data?.data?.onlineItems.items,
      ])
  }, [data?.data?.cashItems?.items, data?.data?.onlineItems.items])

  const updateQty = (method, id, qty, paymentType,properties) => {
    let newData ;
    properties.length?newData = {  qualities:properties,
      id: id,
      paymentType: paymentType,
    }:newData = { 
      id: id,
      paymentType: paymentType,
    }
    if (method === '+') {
      // setQty((prev) => prev + 1),
      addToCart({
        quantity: qty + 1,
        ...newData
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      // setQty((prev) => prev - 1)
      addToCart({
        quantity: qty - 1,
        ...newData
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }
  const handlePaymentClick = () => {
    if (!currentUser ||
      (typeof currentUser === 'object' &&
        Object.keys(currentUser).length === 0)) {
      toast.error(
        lng === 'en' ? 'You Have To Login First' : 'يجب عليك تسجيل الدخول اولا'
      )
    }else
    nav('/checkout')
  }
  const toggle = () => {
    setOpen((prev) => !prev)
  }
  const drawerStyle = {
    width: 300,
    flexShrink: 0,
    position: 'fixed',
    top: 0,
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
  }
  const drawerPaperStyle = {
    width: 300,
    direction: isRTL ? 'rtl' : 'ltr',
    textAlign: isRTL ? 'right' : 'left',
    height: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
  }
  const [coupon, setCoupon] = useState('')
  const [SubmitCouponA, { isError: ErrorCoupon, isLoading: isLoadingC }] =
    useLazyCouponQueryQuery()
  const [addPoints, { isLoading: PointsLoading, isError: PointsError }] =
    useSubmitPointsMutation()
  const [couponAdded, setCouponAdded] = useState({
    couponEnter: '',
    persentage: 0,
    products: [],
    total: 0,
  })
  const handleDelete = () => {
    setCouponAdded((prev) => ({
      ...prev,
      couponEnter: '',
      persentage: 0,
      products: [],
      total: 0,
    }))
  }
  useEffect(() => {
    const couponData = JSON.parse(localStorage.getItem('couponData'))
    if (couponData && Object.keys(couponData).length) setCouponAdded(couponData)
  }, [])
  const SubmitPoints = () => {
    if (currentUser && currentUser.points) {
      addPoints(currentUser.id)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
          getCartdata()
        })
        .catch((e) => {
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    } else {
      toast.error(
        lng === 'en'
          ? `There 's No Points To Submit `
          : `ليست هناك نقاط للاضافه بعد `
      )
    }
  }

  useEffect(() => {
    if (couponAdded.couponEnter !== '') {
      const { persentage, products } = couponAdded
      if (isSuccess && !isLoading) {
        const {
          cashItems: { items: cash },
          onlineItems: { items: online },
        } = data?.data
        calculateProductsAfterDiscount(
          online,
          cash,
          products,
          persentage,
          (total) => {
            setCouponAdded({
              ...couponAdded,
              total,
            })

            setTotalCouponPrice(total)
          }
        )
      }
    }
  }, [data?.data, couponAdded.couponEnter])

  const SubmitCoupon = (e) => {
    e.preventDefault()
    if (coupon !== '') {
      SubmitCouponA(coupon)
        .unwrap()
        .then((res) => {
          setCouponAdded({
            ...couponAdded,
            couponEnter: coupon,
            persentage: res?.data?.discount,
            products: res?.data?.productsCouponsIds,
          })
          setCoupon('')

          toast.success(
            lng === 'en' ? 'coupon added succefully' : 'تم اضافه الكوبون بنجاح'
          )
        })
        .catch((e) => {
          toast.error(e?.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    }
  }
  useEffect(() => {
    localStorage.setItem('couponData', JSON.stringify(couponAdded))
  }, [couponAdded.couponEnter])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const calculateCartPrice = (online, cash) => {
    if (online || cash) {
      return (online + cash) * currencyPrice
    } else {
      return 0
    }
  }
  const OpenAnchor = Boolean(anchorEl)
  const id = OpenAnchor ? 'simple-popover' : undefined
  return (
    <Box
      sx={{
        bgcolor: colors.mainBgColor,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '65%', sm: '91%', lg: '65%' },
          p: {
            lg: 4,
            md: 3,
            xs: '0px',
          },
          direction: lng === 'ar' ? 'rtl' : 'ltr',
          bgcolor: colors?.secondBgColor,
          minHeight: '45vh',
        }}
      >
        {/* Drawer content */}
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            sx={{
              fontSize: '35px',
              color: colors.header,
              my: 2,
              px: { xs: 2, md: 0 },
            }}
          >
            {lng === 'en' ? 'Your Cart' : 'عربتك'}
          </Typography>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            pb: '20px',
          }}
        >
          <Stack
            sx={{
              gap: 1,
              height: '90%',
              overflow: 'auto',
              '::-webkit-scrollbar': {
                width: '6px  !important',
              },
              '::-webkit-scrollbar-thumb': {
                background: colors.buttonBg,
                borderRadius: '10px',
              },
              '::-webkit-scrollbar:hover': {
                width: '8px',
              },
            }}
          >
            {/* MAP PRODUCTS HERE */}
            {isError && !isLoading && (
              // <Typography
              //   color={'error'}
              //   sx={{
              //     m: 5,
              //     fontSize: '1rem',
              //     textAlign: 'center',
              //   }}
              // >
              //   {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
              // </Typography>
              <EmptyFavorite lng={lng} />
            )}

            {isSuccess
              ? data &&
                fullCart?.map((item) => (
                  <>
                    <Stack
                      direction={'row-reverse'}
                      justifyContent={'space-between'}
                      height={'100%'}
                      px={'10px'}
                    >
                      <Stack
                        sx={{ textAlign: 'start' }}
                        height={'auto'}
                        width={'70%'}
                        px={{ xs: '10px', md: '50px' }}
                      >
                        <Typography
                          sx={{
                            color: colors.title,
                            fontSize: '22px',
                            fontWeight: 'bold',
                          }}
                        >
                          {item.product[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                        >
                          {HandleProperties(item)}
                        </Typography>
                        {item?.totalShipping ? (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {lng === 'en' ? 'shipping Price' : '  سعر الشحن'}

                            <span
                              style={{
                                margin: '10px',
                              }}
                            >
                              {item?.totalShipping}

                              {currencySymbol}
                            </span>
                          </Typography>
                        ) : null}

                        <Typography>
                          <Box
                            sx={{
                              color: colors.priceAfter,
                              fontSize: '1.2rem',
                              // fontWeight: 'bold',
                              my: 1,
                            }}
                          >
                            <Box component={'span'} sx={{ mx: 0.5 }}>
                              {currencySymbol}
                            </Box>
                            {/* {item.product.priceAfterDiscount > 0
                              ? (
                                  (cartPrice(
                                    item.total,
                                    item.quantity,
                                    item.product.finalPrice
                                  ) +
                                    item.product.priceAfterDiscount) *
                                  currencyPrice
                                ).toFixed(2)
                              : (
                                  (cartPrice(
                                    item.total,
                                    item.quantity,
                                    item.product.finalPrice
                                  ) +
                                    item.product.priceBeforeDiscount) *
                                  currencyPrice
                                ).toFixed(2)} */}

                            {couponAdded?.couponEnter !== '' ? (
                              <>
                                {ProductDiscount(item) ? (
                                  <>
                                    {(
                                      (item?.totalWithoutShipping -
                                        item?.totalWithoutShipping *
                                          (couponAdded?.persentage / 100)) *
                                      currencyPrice
                                    ).toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {(
                                      item?.totalWithoutShipping * currencyPrice
                                    ).toFixed(2)}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {' '}
                                {(
                                  item?.totalWithoutShipping * currencyPrice
                                ).toFixed(2)}
                              </>
                            )}
                          </Box>
                          {item.product.priceAfterDiscount > 0 &&
                          item?.product?.priceAfterDiscount !==
                            item?.product?.priceBeforeDiscount ? (
                            <Box
                              component={'span'}
                              sx={{
                                color: colors.priceBefore,
                                textDecoration: `line-through 1px solid `,
                                fontSize:
                                  item.product.priceAfterDiscount > 0
                                    ? '0.7rem'
                                    : '1.5rem',
                              }}
                            >
                              {`${(
                                (cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) +
                                  item.product.priceBeforeDiscount) *
                                currencyPrice
                              ).toFixed(2)} ${currencySymbol}`}
                            </Box>
                          ) : null}
                        </Typography>
                        <Stack sx={{ margin: '10px 0px' }}>
                          {ProductDiscount(item) &&
                          couponAdded.couponEnter !== '' ? (
                            <Stack
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: '2px 7px',
                                background: '#000',
                                borderRadius: '10px',
                                fontSize: ' 13px',
                                color: ' #fff',
                                width: 'fit-content',
                                textWrap: 'nowrap',
                                borderRadius: '0px',
                              }}
                            >
                              <Stack
                                component={'span'}
                                sx={{ margin: '0px 10px' }}
                              >
                                {lng === 'en' ? 'discount' : 'نسبه الخصم'}
                              </Stack>
                              <Stack component={'span'}>
                                {couponAdded?.persentage}%
                              </Stack>
                            </Stack>
                          ) : null}
                        </Stack>
                        <Stack
                          justifyContent={'center'}
                          direction={'column'}
                          alignItems={'center'}
                          gap={1}
                          width={'100%'}
                        >
                          {removeLoading ? (
                            <RotateRightIcon
                              sx={{
                                color: colors.remove,
                                cursor: 'pointer',
                              }}
                            />
                          ) : (
                            <Stack
                              direction={'row'}
                              width={{ xs: '60%', lg: '35%', xl: '30%' }}
                              justifyContent={'space-between'}
                              sx={{}}
                            >
                              <Typography
                                sx={{
                                  fontSize: '20px',
                                }}
                              >
                                {lng === 'en' ? 'Quantity' : 'الكمية'}
                              </Typography>
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  color: colors.remove,
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  removeItem(item.product.id)
                                    .unwrap()
                                    .then((res) => {
                                      toast.success(
                                        res[
                                          `success_${
                                            lng === 'en' ? 'en' : 'ar'
                                          }`
                                        ]
                                      )
                                    })
                                    .catch((e) =>
                                      toast.error(
                                        e.data[
                                          `error_${lng === 'en' ? 'en' : 'ar'}`
                                        ]
                                      )
                                    )
                                }
                              />
                            </Stack>
                          )}

                          <Stack
                            direction={'row'}
                            width={{
                              xs: '80%',
                              sm: '40%',
                              lg: '22%',
                              xl: '22%',
                            }}
                            justifyContent={'space-between'}
                            sx={{
                              border: '1px solid black',
                              borderRadius: 1,
                              alignItems: 'center',
                              my: 1,
                              border: '2px solid #ddd',
                              height: '42px !important',
                              'margin-right': '46px',
                              'border-radius': ' 0px',
                            }}
                          >
                            <Button
                              size="small"
                              onClick={() =>
                                updateQty(
                                  '-',
                                  item.product._id,
                                  item.quantity,
                                  item.paymentType,
                                  item.properties
                                )
                              }
                              disabled={item.quantity === 1}
                              sx={{
                                color: 'black',
                                minWidth: 40,
                              }}
                            >
                              -
                            </Button>
                            <Typography>{item.quantity}</Typography>
                            <Button
                              size="small"
                              onClick={() =>
                                updateQty(
                                  '+',
                                  item.product._id,
                                  item.quantity,
                                  item.paymentType,
                                  item.properties
                                )
                              }
                              sx={{
                                color: 'black !important',
                                minWidth: 45,
                              }}
                            >
                              +
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                      <CardMedia
                        component="img"
                        src={imageBaseUrl + item.product.images[0]}
                        sx={{
                          width: {
                            md: '25%',
                            xs: '30%',
                          },
                          height: { xs: '200px', md: '210px', lg: '400px' },
                          objectFit: 'cover',
                        }}
                      />
                    </Stack>
                  </>
                ))
              : !isError && <AnimatedLoader />}
          </Stack>
        </Box>
        {isSuccess && fullCart?.length > 0 && (
          <>
            <Divider />
            <Stack
              direction={'row'}
              justifyContent={{ xs: 'center', sm: 'flex-end' }}
            >
              <Box
                sx={{
                  my: 2,
                  width: { xs: '90%', md: '40%', lg: '40%', xl: '31%' },
                  px: { sm: '5px' },
                }}
              >
                {couponAdded.couponEnter !== '' ? (
                  <Chip
                    label={couponAdded.couponEnter}
                    sx={{
                      padding: '10px',
                      background: colors?.buttonBg,
                      margin: '10px auto',
                      color: '#fff',
                      borderRadius: '0px !important',
                      '& > .MuiSvgIcon-root': {
                        color: '#fff !important',
                      },
                    }}
                    onDelete={handleDelete}
                    disabled={data?.data?.couponUsed}
                  />
                ) : null}
                <Box>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        display: 'flex',
                        ' flex-direction': 'column',
                      }}
                    >
                      <form
                        onSubmit={(e) => SubmitCoupon(e)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          margin: '10px auto',
                        }}
                      >
                        <input
                          label="coupon"
                          value={coupon}
                          className="couponInput"
                          style={{
                            padding: '13px',
                            height: '30px',
                            border: '1px solid #ddd ',
                            padding: '10px',
                            background: 'transparent',
                            outline: 'none',
                            marginRight: '5px',
                            marginLeft: '3px',
                            height: '19px !IMPORTANT',
                            width: '100% !important',
                          }}
                          placeholder={
                            lng === 'en' ? 'discount code' : 'كود الخصم'
                          }
                          onChange={(e) => setCoupon(e.target.value)}
                        ></input>
                        <CustomWidthTooltip
                          title={
                            data?.data?.isPointUsed
                              ? lng === 'en'
                                ? "You can't use coupons because you have already used Your Points "
                                : ' لا يمكنك استخدام كوبون لانك استخدمت نقاطك بالفعل '
                              : ''
                          }
                          sx={{
                            background: '#fff !important',
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              height: '39px',
                              overflow: 'hidden',
                              width: '27%',
                              'margin-right': '10px',
                            }}
                          >
                            <Button
                              sx={{
                                border: '1px solid #ddd',
                                padding: { xs: '5px', sm: '10px' },
                                height: '45px',

                                background: colors?.buttonBg,
                                color: `${colors?.button} !important`,
                                transition: '0.7s all ease-in-out',
                                fontSize: { xs: '10px', sm: '12px' },
                                '&:hover': {
                                  background: colors?.buttonBg,
                                  opacity: '0.7',
                                },
                                width: '100% !important',
                                margin: { xs: 0 },
                                borderRadius: 0,
                              }}
                              disabled={
                                data?.data?.couponUsed ||
                                data?.data?.isPointUsed ||
                                isLoadingC
                              }
                              type={'submit'}
                            >
                              {lng === 'en' ? 'submit' : 'تاكيد'}
                            </Button>
                          </span>
                        </CustomWidthTooltip>
                      </form>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        direction: lng === 'en' ? 'rtl' : 'ltr',
                      }}
                    >
                      {data?.data?.couponUsed ? (
                        <>
                          {lng === 'en'
                            ? 'You Already Have Used coupon For this cart '
                            : 'تم  استخدام الكوبون في هذه العربه'}
                        </>
                      ) : null}
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          {currentUser?.points}
                          <HelpOutlineIcon
                            sx={{
                              width: '20px',
                              height: '20px',
                              marginRight: '10px',
                              cursor: 'pointer',
                            }}
                            aria-describedby={id}
                            type="button"
                            onClick={handleClick}
                          />
                        </span>
                        {lng === 'en' ? 'Your Points' : 'النقاط'}
                      </Typography>

                      <CustomWidthTooltip
                        title={
                          couponAdded?.couponEnter !== ''
                            ? lng === 'en'
                              ? 'You Have To Delete the coupon To Use Your Points'
                              : 'يجب حذف الكوبون حتي تتمكن من استخدام نقاطك'
                            : ''
                        }
                        sx={{
                          background: '#fff !important',
                        }}
                      >
                        <div style={{ width: '100%' }}>
                          {data?.data?.isPointUsed ? null : (
                            <Button
                              disabled={
                                couponAdded?.couponEnter !== '' ||
                                data?.data?.isPointUsed
                              }
                              sx={{
                                m: 1,
                                border: '1px solid #ddd',
                                padding: '10px',
                                height: '40px',
                                margin: '10px auto',
                                background: colors?.buttonBg,
                                color: `${colors?.button} !important`,
                                transition: '0.7s all ease-in-out',
                                width: '100%',
                                borderRadius: 0,

                                '&:hover': {
                                  background: colors?.buttonBg,
                                  opacity: '0.7',
                                },
                              }}
                              onClick={SubmitPoints}
                            >
                              {lng === 'en'
                                ? 'Use Your  Points '
                                : 'استخدم نقاطك'}
                            </Button>
                          )}
                        </div>
                      </CustomWidthTooltip>

                      <Popover
                        id={id}
                        open={OpenAnchor}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        disableScrollLock
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                          padding: '3px',
                        }}
                      >
                        <Typography
                          sx={{
                            p: 2,
                            textTransform: 'capitalize',
                            fontSize: {
                              xs: '13px',
                              width: 'fit-content',
                            },
                          }}
                        >
                          {lng === 'en' ? (
                            <>
                              {' '}
                              Those Points Are calculated in checkout for every
                              succefully purchesed products{' '}
                            </>
                          ) : (
                            <>هذه النقاط يتم احتسابها لكل عمليه شراء ناجحه</>
                          )}
                        </Typography>
                      </Popover>

                      <Stack
                        component={'span'}
                        sx={{
                          margin: '3px',
                          textAlign: 'right',
                          color: '#000',
                          fontWeight: '',
                        }}
                      >
                        {data?.data?.isPointUsed ? (
                          <>
                            {lng === 'en'
                              ? 'Your Points Have been Used before '
                              : 'لقد استخدمت نقاطك من قبل '}
                          </>
                        ) : null}
                      </Stack>
                    </Box>
                  </Box>
                </Box>

                {[
                  ...data?.data?.onlineItems?.items,
                  ...data?.data?.cashItems?.items,
                ].filter((item) => item?.product?.shippingPrice > 0).length >
                0 ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    my={3}
                    flexWrap={'wrap'}
                  >
                    <Typography sx={{ color: '#000' }}>
                      {lng === 'en' ? '  Shipping Price:' : 'المجموع الفرعي'}
                    </Typography>
                    <Typography
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                      }}
                    >
                      {(
                        calculateShippingPrice(couponAdded)(
                          data?.data?.onlineItems.totalPrice +
                            data?.data?.cashItems.totalPrice,
                          data?.data?.onlineItems.items,
                          data?.data?.cashItems.items,
                          true
                        ) * currencyPrice
                      ).toFixed(2)}
                      {currencySymbol}
                    </Typography>
                  </Stack>
                ) : null}
                {[
                  ...data?.data?.onlineItems?.items,
                  ...data?.data?.cashItems?.items,
                ].filter((item) => item?.product?.shippingPrice > 0).length >
                0 ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    my={3}
                    flexWrap={'wrap'}
                  >
                    <Typography sx={{ color: '#000' }}>
                      {lng === 'en' ? '  Shipping Price:' : 'رسوم الشحن :'}
                    </Typography>
                    <Typography
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                      }}
                    >
                      {[
                        ...data?.data?.onlineItems?.items,
                        ...data?.data?.cashItems?.items,
                      ].reduce((acc, item) => {
                        return acc + item?.product?.shippingPrice
                      }, 0) || 0}{' '}
                      {currencySymbol}
                    </Typography>
                  </Stack>
                ) : null}

                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'row-reverse  ',
                    margin: '10px 0px',
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    {data?.data && !isError ? (
                      <>
                        {(
                          calculateCartPrice(
                            data?.data?.onlineItems.totalPrice,
                            data?.data?.cashItems.totalPrice
                          ) * currencyPrice
                        ).toFixed(2)}{' '}
                        {currencySymbol}
                      </>
                    ) : (
                      0
                    )}
                  </span>
                  {lng === 'en' ? 'Total price' : 'المجموع  الكلي'}
                </Typography>

                {couponAdded?.couponEnter !== '' ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      flexDirection: 'row-reverse  ',
                      margin: '10px 0px',
                      fontSize:{
                        xs:'12px',
                        md:'13px',
                        lg:'14px',
                        xl:'15px'
                      }
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      {(couponPrice * currencyPrice).toFixed(2)}{' '}
                      {currencySymbol}
                    </span>
                    {lng === 'en'
                      ? 'Total Price After Coupon'
                      : ' المجموع  الكلي بعد الكوبون'}
                  </Typography>
                ) : null}

                {/* <Stack
                  direction={'column'}
                  alignItems={'flex-start'}
                  gap={'6px'}
                  sx={{
                    margin: '10px 0px',
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      onChange={() => setAcceptTerms(!acceptTerms)}
                      type="checkbox"
                      style={{
                        height: '15px',
                        width: '15px',
                        accentColor: '#000',
                        fontSize: '11px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: '11px',
                      }}
                    >
                      {lng === 'en' ? 'You Have To' : 'يجب عليك السماح'}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: 'none',
                        color: '#333 !important',
                        fontSize: '11px',
                        mx: '5px',
                      }}
                    >
                      {lng === 'en'
                        ? 'Share Your Location To Continue Payment'
                        : ' بتحديد موقعك لاتمام عمليه الدفع'}
                    </Typography>
                  </Stack>
                </Stack> */}
                <Button
                  variant="contained"
                  disabled={isError && !isSuccess}
                  sx={{
                    color: '#fff !important',

                    bgcolor: `${colors.buttonBg} !important`,
                    width: '100%',

                    borderRadius: 0,
                  }}
                  onClick={() => handlePaymentClick()}
                >
                  {lng === 'en' ? 'Confirm payment' : 'تأكيد الدفع'}
                </Button>
              </Box>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  )
}

const EmptyFavorite = ({ lng }) => {
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      mt={10}
      sx={{ minHeight: '80vh' }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          bgcolor: '#9e8356',
          width: { xs: '200px', md: '250px' },
          height: { xs: '200px', md: '250px' },
          borderRadius: '50%',
          mb: 2,
        }}
      >
        <ShoppingCartIcon
          sx={{
            fontSize: '75px',
          }}
          color="#000"
        />
      </Stack>
      <Typography sx={{ fontSize: '1.5rem' }} fontWeight={'bold'}>
        {lng === 'en'
          ? 'There are no products in the shopping cart'
          : 'لا توجد منتجات في سلة التسوق'}
      </Typography>
      <Typography variant="caption" color={'GrayText'}>
        {lng === 'en'
          ? 'add products in your cart to find them easily'
          : 'أضف المنتجات إلى سلة التسوق الخاصة بك للعثور عليها بسهولة'}
      </Typography>
      <Typography
        component={Link}
        to={'/departments'}
        sx={{
          color: '#ffff',
          bgcolor: '#000000',
          fontSize: { xs: '1.2rem', md: '1.3rem' },
          textDecoration: 'none',
          px: 7,
          py: 1.5,
          cursor: 'pointer',
          mt: 5,
        }}
      >
        {lng == 'en' ? 'Start Shopping' : 'ابدأ التسوق'}
      </Typography>
    </Stack>
  )
}
