import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetAllAttributesQuery } from '../../redux/apis/attributeApi'
import RemoveIcon from '@mui/icons-material/Remove'
import { object, string } from 'yup'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
} from '@mui/material'
import { toast } from 'react-toastify'
function FilerAttributesLarge({
  filterQuery,
  setFilterQuery,
  colors,
  filterMenuStyles,
  extraStyles,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lang }] = useTranslation()
  const { attributesData } = GenerateAttributeData()
  const open = Boolean(anchorEl)
  const [query, setQuery] = useState('')

  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const manipulateQuery = () => {
    let query = ''
    let selAttsValues = selectedAtts
      .reduce((item, obj) => {
        return item.concat(obj.values)
      }, [])
      .map(({ value_en }) => value_en)
    selAttsValues.map((val) => (query += `attributes.values.value_en=${val}&`))
    if (priceState) {
      query += !query ? `` : '&'
      query += `priceBeforeDiscount[gte]=
      ${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    }
    return query
  }
  const [selectedAtts, setSelectedAtts] = useState([])
  const [priceState, setPriceState] = useState()
  const hundleAddAtt = (attribute, selectedValue, event) => {
    const existedAtt = selectedAtts.find(
      (item) => item.key_en === attribute.key_en
    )
    const { checked, value } = event.target
    if (checked) {
      existedAtt
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === attribute.key_en
                ? {
                    key_en: attribute.key_en,
                    key_ar: attribute.key_ar,
                    values: [...existedAtt.values, { ...selectedValue }],
                  }
                : item
            )
          )
        : setSelectedAtts([
            ...selectedAtts,
            {
              key_en: attribute.key_en,
              value_en: attribute.key_ar,
              values: [selectedValue],
            },
          ])
    } else {
      existedAtt?.values?.length > 1
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === existedAtt.key_en
                ? {
                    ...existedAtt,
                    values: existedAtt?.values.filter(
                      (val) => val.value_en !== value
                    ),
                  }
                : item
            )
          )
        : setSelectedAtts(
            selectedAtts.filter((sel) => sel.key_en !== existedAtt?.key_en)
          )
    }
  }
  useEffect(() => {
    setFilterQuery(manipulateQuery())
  }, [selectedAtts, priceState])
  const formik = useFormik({
    initialValues: { from: '', to: '' },
    validationSchema: object({
      from: string().required(),
      to: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      setPriceState(values)
      let submitQueries = `priceBeforeDiscount[gte]=${values.from}&priceBeforeDiscount[lte]=${values.to}`
      setFilterQuery(
        filterQuery ? `${filterQuery}&${submitQueries}` : submitQueries
      )
      resetForm()
    },
  })
  const refetchAllProducts = () => {
    setFilterQuery('')
    setSelectedAtts([])
    setPriceState()
  }
  const clearPriceFilter = () => {
    let priceQueries = `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    setFilterQuery(filterQuery.replace(priceQueries, ''))
    setTimeout(() => {
      setPriceState()
    }, 240)
  }
  const submitButtonRef = useRef(null)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      disableSubmitButton()
    }
  }

  const disableSubmitButton = () => {}

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Stack
      sx={{
        ...extraStyles,
      }}
    >
      <Box
        sx={{
          borderTop: '2px solid #dddddd',
          borderBottom: '2px solid #dddddd',
        }}
      >
        <Accordion
          expanded={expanded === 'price'}
          onChange={handleChange('price')}
          sx={{
            m: '0 !important',
            border: `1px solid transparent`,
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              justifyContent: 'center',
            }}
            expandIcon={expanded === 'price' ? <RemoveIcon /> : <AddIcon />}
          >
            <Typography sx={filterMenuStyles.priceBtn}>
              {lang === 'en' ? 'Price' : 'السعر'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    width: 0.45,
                  }}
                >
                  <Typography
                    sx={{
                      ...filterMenuStyles.checkBoxLabel,
                      fontSize: '12px',
                    }}
                  >
                    {lang === 'en' ? 'From' : 'من'}
                  </Typography>
                  <InputBase
                    name="from"
                    type="number"
                    value={formik.values.from}
                    onChange={formik.handleChange}
                    disabled={priceState?.from && priceState?.to}
                    sx={{
                      border: `1px solid ${
                        formik.errors.from && formik.touched.from
                          ? 'red'
                          : '#ddd'
                      } `,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: 0.45,
                  }}
                >
                  <Typography
                    sx={{
                      ...filterMenuStyles.checkBoxLabel,
                      fontSize: '12px',
                    }}
                  >
                    {lang === 'en' ? 'To' : 'إلي'}
                  </Typography>
                  <InputBase
                    type="number"
                    name="to"
                    value={formik.values.to}
                    onChange={formik.handleChange}
                    disabled={priceState?.from && priceState?.to}
                    sx={{
                      border: `1px solid ${
                        formik.errors.to && formik.touched.to ? 'red' : '#ddd'
                      } `,
                    }}
                  />
                </Box>
              </Stack>

              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Button
                  // disabled={priceState?.from && priceState?.to}
                  ref={submitButtonRef}
                  type="submit"
                  sx={filterMenuStyles.formPriceBtn}
                >
                  {lang === 'en' ? 'Search by price' : 'بحث بالسعر'}
                </Button>
                {priceState?.from && priceState?.to ? (
                  <CloseIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={clearPriceFilter}
                  />
                ) : null}
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
        {attributesData?.length > 0 &&
          attributesData?.map((attribute) => (
            <Accordion
              expanded={expanded === attribute.key_en}
              onChange={handleChange(attribute.key_en)}
              sx={{
                m: '0 !important',
                direction: lang === 'en' ? 'ltr' : 'rtl',
              }}
              elevation={0}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                expandIcon={
                  expanded === attribute.key_en ? <RemoveIcon /> : <AddIcon />
                }
              >
                <Typography
                  sx={{ ...filterMenuStyles.checkBoxLabel, color: '#000' }}
                >
                  {attribute[`key_${lang}`]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={
                  {
                    // overflow: 'hidden',
                  }
                }
              >
                {attribute.values?.map((value,idx) => (
                  <Box
                  key={idx}
                    sx={{
                      display: 'flex',
                      direction: lang === 'en' ? 'ltr' : 'rtl',
                    }}
                  >
                    <input
                      type="checkbox"
                      id={value.value_en}
                      value={value.value_en}
                      checked={selectedAtts.find(
                        (item) =>
                          item.key_en === attribute.key_en &&
                          item.values.find(
                            ({ value_en }) => value_en === value.value_en
                          )
                      )}
                      style={{
                        accentColor: '#363636',
                      }}
                      onChange={(event) =>
                        hundleAddAtt(attribute, value, event)
                      }
                    />
                    <Typography
                      component="label"
                      htmlFor={value.value_en}
                      sx={filterMenuStyles.checkBoxLabel}
                    >
                      {value[`value_${lang}`]}
                    </Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      {selectedAtts?.length > 0 || filterQuery ? (
        <Stack direction={'row'} justifyContent={'center'}>
          <RefreshIcon
            sx={{
              color: colors.filter.checkBoxLabel,
              cursor: 'pointer',
            }}
            onClick={refetchAllProducts}
          />
        </Stack>
      ) : undefined}
    </Stack>
  )
}

export default FilerAttributesLarge

const GenerateAttributeData = () => {
  const [getAllAttributes] = useLazyGetAllAttributesQuery()
  const [attributesData, setAtts] = useState()
  useEffect(() => {
    getAllAttributes()
      .unwrap()
      .then((res) => {
        setAtts(res.data)
      })
      .catch((err) => {
        setAtts()
      })
  }, [])
  return {
    attributesData,
  }
}
