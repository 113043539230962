export const colors = {
  main: '#000000',
  primary: '#9E8356',
  iconColor: '#e5e5e5',
  activeIconColor: '#28ACB3',
  menuBgColor: 'red',
  iconColor: 'red',
}
export default {
  navIcons: {
    icon: {
      color: colors.primary,
      cursor: 'pointer',
      fontSize: {
        xs: '1.6rem',
        md: '1.8rem',
        lg: '2rem',
      },
    },
    languageButton: {
      minWidth: 0,
      width: 35,
      height: 26,
      fontSize: '16px',
      color: '#000',
      bgcolor: `#fff !important`,
    },
    authButton: {
      bgcolor: `${colors.primary} !important`,
      color: '#fff',
      textTransform: 'capitalize',
      letterSpacing: 1,
    },
  },
  navDrawer: {
    Drawer: {
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: 240,
        pt: '60px',
        pb: '10px',
        bgcolor: colors.main,
      },
    },
    Box: {
      flex: 1,
      mb: 2,
      width: '100%',
    },
  },
  profileMenu: {
    Button: {
      width: {
        md: 50,
        xs: 40,
      },
      height: {
        md: 40,
        xs: 30,
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 0,
      borderRadius: 1,
      bgcolor: `transparent !important`,
    },
    CloseIcon: {
      fontSize: {
        lg: '30px',
        xs: '25',
      },
      color: "#fff",
    },
    PersonIcon: {
      fontSize: {
        xs: '1.8rem',
        md: '1.8rem',
        lg: '2rem',
      },

      color: "#fff",
    },
    Menu: {
      width: '100%',
      '.MuiPaper-root': {
        bgcolor: colors.main,
        border: `1px solid #fff`,
        p: '10px',
      },
    },
    MenuListProps: {
      'aria-labelledby': 'basic-button',
    },
    Stack: {
      color: '#fff',
      fontWeight: 'bold',
      gap: '6px',
      cursor: 'pointer',
    },
  },
}
