import React from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  CardMedia,
  Container,
  InputBase,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import contactImage from './contact.png'
import Banner from '../../../components/Banners/Banner'
import { useFormik } from 'formik'
import { ContactNoon6Style } from './ContactNoon6Style'
import { useContactMutation } from '../../../redux/apis/contactsApis'
import * as yup from 'yup'
import { toast } from 'react-toastify'

// ===========================|| CONTACT TEXT INPUT ||=========================== //
const ContactTextInput = (props) => {
  return (
    <Box>
      <Container>
        <InputBase
          type="text"
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          sx={{
            bgcolor: ' transparent !important',
            width: '100%',
            p: '18px 20px',
            color: 'black',
            border:
              props.error && props.touched
                ? `2px solid red`
                : `2px solid ${
                    props.color?.borderColor ? props.color?.borderColor : 'gray'
                  }`,
            mt: 4,
            '& input::placeholder': {
              fontSize: '20px',
            },
          }}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
        <Typography
          sx={{
            fontSize: '12px',
            color: 'red',
          }}
        >
          {props.error && props.touched ? props.error : undefined}
        </Typography>
      </Container>
    </Box>
  )
}

// =============================================================================================== //

// ===========================|| FORMIK DATA AND VALIDATION ||=========================== //
const useContact = () => {
  const [_, { language: lang }] = useTranslation()

  const contactData = {
    name: '',
    email: '',
    phone: '',
    message: '',
    contactType: '',
  }
  const contactValidation = yup.object({
    name: yup
      .string()
      .required(lang === 'en' ? 'Name is required' : 'الاسم مطلوب'),
    email: yup
      .string()
      .email(lang === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صحيح')
      .required(
        lang === 'en' ? 'Email is required' : 'البريد الالكتروني مطلوب'
      ),
    phone: yup
      .string()
      .matches(
        /^\+?[0-9]+$/,
        lang === 'en' ? 'Invalid phone number' : 'رقم الهاتف غير صالح'
      )
      .required(lang === 'en' ? 'Phone is required' : 'رقم الهاتف مطلوب'),
    message: yup
      .string()
      .required(lang === 'en' ? 'Message is required' : 'اكتب رسالتك مطلوبة'),
    contactType: yup
      .string()
      .required(lang === 'en' ? 'Select Contact Type' : 'اختار نوع التواصل')
      .ensure()
      .oneOf(
        ['complaints', 'suggestions', 'customerService'],
        lang === 'ar'
          ? 'يجب أن يكون نوع الاتصال أحد القيم التالية: شكاوي، اقتراحات، خدمة العملاء'
          : 'ContactType must be one of the following values: complaints, suggestions, customerService'
      ),
  })

  return { contactData, contactValidation }
}
// =============================================================================================== //

// ===========================|| CONTACT FORM ||=========================== //
const ContactForm = (props) => {
  const [_, { language: lang }] = useTranslation()
  const [contact, { isLoading }] = useContactMutation()
  const { contactData, contactValidation } = useContact()

  const formik = useFormik({
    initialValues: contactData,
    validationSchema: contactValidation,
    onSubmit: (values) => {
      formik.resetForm()
      contact(values)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lang}`])
          formik.resetForm()
        })
        .catch((err) => {
          toast.error(err?.data[`error_${lang}`])
        })
    },
  })

  return (
    <Box>
      <Box
        sx={{
          direction: lang === 'en' ? 'ltr' : 'rtl',
          bgcolor: 'transparent',
          width: { xs: '100%', md: '90%', lg: '80%' },
          mx: 'auto',
          mb: 2,
          py: 2,
          mt: { xs: 0, md: 2 },
        }}
      >
        <Container>
          <form onSubmit={formik.handleSubmit}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Box width={{ xs: '100%', md: '50%' }}>
                <ContactTextInput
                  value={formik.values.name}
                  name={'name'}
                  placeholder={lang === 'en' ? 'Name' : 'الاسم'}
                  error={formik.errors.name}
                  touched={formik.touched.name}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  label={lang === 'en' ? 'Name' : 'الاسم'}
                  color={props?.color}
                />
              </Box>

              <Box width={{ xs: '100%', md: '50%' }}>
                <ContactTextInput
                  value={formik.values.email}
                  name={'email'}
                  placeholder={lang === 'en' ? 'Email' : 'البريد الالكتروني'}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  label={lang === 'en' ? 'Email' : 'البريد الالكتروني'}
                  color={props?.color}
                />
              </Box>
            </Stack>

            <Box>
              <ContactTextInput
                value={formik.values.phone}
                name={'phone'}
                placeholder={lang === 'en' ? 'Phone' : 'رقم الهاتف'}
                error={formik.errors.phone}
                touched={formik.touched.phone}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Phone' : 'رقم الهاتف'}
                color={props?.color}
              />
            </Box>
            <Container>
              {/* type of connections */}
              <Box>
                <Box my={2}></Box>
                <select
                  value={formik.values.contactType}
                  name="contactType"
                  style={{
                    width: '100%',
                    border: `2px solid ${
                      props.color?.borderColor
                        ? props.color?.borderColor
                        : 'gray'
                    }`,
                    padding: '18px 20px',
                    fontSize: '20px',
                    color: formik.values.contactType
                      ? props.color?.textColor
                      : '#333',
                    outline: 'none',
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option
                    value="select one"
                    hidden
                    style={{
                      color: props.color?.textColor
                        ? props.color?.textColor
                        : '#000',
                    }}
                  >
                    {lang === 'en'
                      ? 'Select Contact Type'
                      : 'أختار نوع التواصل'}
                  </option>
                  <option
                    value="complaints"
                    style={{
                      color: props.color?.textColor
                        ? props.color?.textColor
                        : '#000',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Complaints' : 'الشكاوي'}
                  </option>
                  <option
                    value="suggestions"
                    style={{
                      color: props.color?.textColor
                        ? props.color?.textColor
                        : '#000',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Suggestions' : 'الاقتراحات'}
                  </option>
                  <option
                    value="customerService"
                    style={{
                      color: props.color?.textColor
                        ? props.color?.textColor
                        : '#000',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Customer Service' : 'خدمة العملاء'}
                  </option>
                </select>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'red',
                  }}
                >
                  {formik.errors.contactType && formik.touched.contactType
                    ? formik.errors.contactType
                    : undefined}
                </Typography>
              </Box>
              {/* Message */}
              <Box
                sx={{
                  mt: 4,
                  position: 'relative',
                }}
              >
                <textarea
                  value={formik.values.message}
                  name="message"
                  placeholder={lang === 'en' ? 'Message' : 'الرسالة'}
                  style={{
                    width: '100%',
                    // padding: '12px 16px',
                    border: '2px solid #000',

                    height: '200px',
                    outline: 'none',
                    fontSize: '20px',
                    resize: 'none',
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'red',
                  }}
                >
                  {formik.errors.message && formik.touched.message
                    ? formik.errors.message
                    : undefined}
                </Typography>
              </Box>
            </Container>
            {/* Button */}
            <Box
              sx={{
                mt: 4,
                textAlign: 'center',
              }}
            >
              <Button
                disabled={isLoading}
                variant="contained"
                sx={{
                  backgroundColor: props.Button?.backgroundColor
                    ? `${props.Button?.backgroundColor} !important`
                    : 'black !important',
                  color: props.Button?.color,
                  fontWeight: 'bold',
                  fontSize: { xs: '20px', md: '26px' },
                  transition: 'all 0.3s',
                  px: {
                    md: '60px',
                    xs: '25px',
                  },
                  py: {
                    md: '8px',
                    xs: '6.5px',
                  },
                  '&:active': {
                    transform: 'scale(0.9)',
                  },
                }}
                type="submit"
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress
                      sx={{
                        color: props.Button?.color || 'white',
                      }}
                    />
                  </Box>
                ) : lang === 'en' ? (
                  'Send'
                ) : (
                  'ارسال'
                )}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Box>
  )
}

// =============================================================================================== //

// =============================================================================================== //

// ===========================|| CONTACT US - NOON 6 ||=========================== //
const ContactNoon6 = ({ image, imageWidth }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        pt: 2,
        mb: 10,
        overflow: 'hidden',
      }}
    >
      <Box mt={{ xs: 0, md: 8 }}>
        <ContactForm
          Button={ContactNoon6Style?.Button}
          color={ContactNoon6Style.color}
        />
      </Box>
    </Box>
  )
}

export default ContactNoon6
