import { Box, Grid, Typography, CardMedia } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetSectionByTypeQuery } from '../../../../redux/apis/sectionsApi'
import { colors } from './styles'
import styles from './styles'
import './styleCss.css'
import { useNavigate } from 'react-router-dom'
import { imageBaseUrl } from '../../../../constants/baseUrl'
import { Link } from 'react-router-dom'
import AnimatedLoader from '../../../animatedLoader/AnimatedLoader'

const ErrorSection = ({ error, lang }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color='error'
      >
        {/* {error?.data && error?.data[`error_${lang}`]} */}
      </Typography>
    </Box>
  )
}
const AboutHeader = ({ title, desc, lang }) => {
  const navigate = useNavigate()
  return (
    <Box
      width={0.9}
      mx={'auto'}
      sx={{
        textAlign: { xs: 'center', md: 'initial' },
      }}
    >
      {/* <Typography variant={'h3'} sx={styles.aboutSection.typography}>
        {title}
      </Typography> */}

      <Box
        variant='h6'
        sx={styles.aboutSection.dangerously}
        // i need to make it end "..."
        dangerouslySetInnerHTML={{
          __html: desc.length > 200 ? `${desc.slice(0, 200)}....` : desc,
        }}
      />
      <Box
        sx={{
          mt: 2,
          fontSize:{xs:'initial',xl:"1.5rem"}
        }}
      >
        <Link to={'/departments'} className='btn btn-fill-middle' >
          {lang == 'ar' ? 'تسوق الأن' : 'Shop now'}
        </Link>
      </Box>
    </Box>
  )
}
const Index = () => {
  const [_, { language: lang }] = useTranslation()
  const { data, isSuccess, isError, error, isLoading } =
    useGetSectionByTypeQuery('aboutus')
  const desc = data?.data[0][`description_${lang}`]
  const title = data?.data[0][`title_${lang}`]
  const aboutImage = data?.data[0].image
  return (
    
    <Box bgcolor={colors.bgColor}>
      {isLoading && <AnimatedLoader/>}
      {/* {isError && <ErrorSection error={error} lang={lang} />} */}
      {!isLoading && !isError && isSuccess && (
        <Grid
          container
          sx={{
            width: '85%',
            height: { xs: '100%', md: '100vh' },
            mx: 'auto',
            backgroundColor: colors.bgColor,
            direction: lang === 'ar' ? 'rtl' : 'ltr',
            // py: 5,
            justifyContent: 'center',
            flexDirection: { xs: 'initial', md: 'row-reverse' },
            gap: 2,
          }}
        >
         
          <Grid item xs={12} md={5.5} height={"100%"}>
            <CardMedia
              component='img'
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              src={imageBaseUrl + aboutImage}
              alt={`about`}
            />
          </Grid>
          <Grid item xs={12} md={5.5} sx={styles.aboutSection.flexHeader}>
            <AboutHeader title={title} desc={desc} lang={lang} />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Index
