import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeroVideo5Style } from './HeroVideo5Style'
import { useTranslation } from 'react-i18next'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { useGetSectionByTypeQuery } from '../../../../../redux/apis/sectionsApi'
import { imageBaseUrl } from '../../../../../constants/baseUrl'
import AnimatedLoader from '../../../../animatedLoader/AnimatedLoader'
import { Link } from 'react-router-dom'

// =============================||VIDEO CONTAINER||================================

const VideoBack = ({ video, play, mouseEnter, mouseLeave, videoRef }) => {
  return (
    <Box>
      <video
        ref={videoRef}
        onMouseEnter={mouseEnter}
        src={video}
        autoPlay={play}
        muted
        loop
        style={{
          width: '100%',
          height: '100%',
          zIndex: -1,
          position: 'absolute',
          top: 0,
          inset: 0,
          objectFit: 'cover',
        }}
      />
    </Box>
  )
}

//  ===================================================================

const HeroVideo5 = ({ backgroundVideo }) => {
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const [play, setPlay] = useState(true)
  const ref = React.useRef()
  const [show, setShow] = useState(true)

  const mouseEnter = () => {
    setShow(true)
  }
  const mouseLeave = () => {
    setTimeout(() => {
      setShow(false)
    }, 500)
  }
  const playVideo = () => {
    setPlay(!play)
    play ? ref.current.pause() : ref.current.play()
  }
  const { data, error, isLoading } = useGetSectionByTypeQuery('slider')
  if (isLoading) return <AnimatedLoader />
  if (!isLoading && error) {
    return (
      <Box
        sx={{
          minHeight: { xs: '65vh', md: '70vh ', xl: '75vh' },
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '2rem', sm: '3rem', md: '2rem' },

            textAlign: 'center',
            mt: 7,
            mb: 2,
            'font-size': {
              md: '2rem',
              xs: '1.1rem',
            },
            'font-weight': 'bold',
            border: `1px solid #9e8356`,
            p: '10px',
          }}
        >
          {lang === 'en'
            ? 'There is no slider content available now'
            : 'لا يوجد محتوى سلايدر متوفر الان'}
        </Typography>
      </Box>
    )
  }
  const videoExtensions = [
    '.mp4',
    '.webm',
    '.ogg',
    '.ogv',
    '.avi',
    '.mov',
    '.qt',
    '.flv',
    '.wmv',
    '.mkv',
    '.avchd',
    '.mpg',
    '.mpeg',
    '.mp2',
    '.m4v',
    '.m4p',
  ]
  const checkSliderVideo = videoExtensions.find((ex) =>
    data?.data[0]?.image?.endsWith(ex)
  )

  return (
    <Box
      sx={{
        // minHeight: { xs: '70vh',sm:'auto', md: "auto" },
        width: '100%',
        aspectRatio: { xs: 1, md: 2 },
        height: 'auto',
        position: 'relative',
        top: { xs: 90, md: 20, lg: 0 },
        mb: { xs: 15, md: 0 },
      }}
    >
      {!checkSliderVideo ? (
        <Box
          sx={{
            position: 'absolute',
            // top: 0,
            // left: 0,
            height: '100%',
            width: '100%',
            inset: 0,
            backgroundImage: `url(${imageBaseUrl + data?.data[0]?.image})`,
            backgroundPosition: 'center center',
            backgroundSize: { xs: 'cover', md: 'contain' },
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: { xs: '5%', md: '17%' },
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Button
              component={Link}
              to={'/departments'}
              variant="contained"
              sx={{
                color: HeroVideo5Style.Btn?.colorBtn
                  ? HeroVideo5Style.Btn.colorBtn
                  : 'white',
                backgroundColor: HeroVideo5Style.Btn?.backgroundColorBtn
                  ? `${HeroVideo5Style.Btn.backgroundColorBtn} !important`
                  : `black !important`,
                py: { xs: '8px', sm: '20px' },
                px: '45px',
                width: { md: '30%', lg: '23%' },

                textTransform: 'capitalize',
                borderTop: `7px solid ${
                  HeroVideo5Style.Btn?.borderColor
                    ? HeroVideo5Style.Btn.borderColor
                    : 'white'
                }`,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '1rem', md: '1.6rem' },
                }}
              >
                {lang === 'en' ? 'Shop Now' : 'تسوق الان'}
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {/* Video */}
          <VideoBack
            // video={backgroundVideo}
            video={imageBaseUrl + data?.data[0]?.image}
            mouseEnter={mouseEnter}
            play={play}
            videoRef={ref}
          />
          {/* <Box
            onMouseLeave={mouseLeave}
            onMouseOver={() => {
              setShow(true)
            }}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              bgcolor: '#80808085',
              display: 'flex',
              opacity: show ? 1 : 0,
              alignItems: 'center',
              justifyContent: 'center',
              width: '90px',
              height: '90px',
              borderRadius: '50%',
              cursor: 'pointer',
              zIndex: 2,
              transition: 'all 300ms ease-in-out ',
            }}
            // onClick={() => {
            onClick={playVideo}
          >
            {play ? (
              <PauseIcon
                sx={{
                  color: 'white',
                  fontSize: '70px',
                }}
              />
            ) : (
              <PlayArrowIcon
                sx={{
                  color: 'white',
                  fontSize: '70px',
                }}
              />
            )}
          </Box> */}

          <Box
            sx={{
              position: 'absolute',
              bottom: { xs: '5%', md: '17%' },
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Button
              component={Link}
              to={'/departments'}
              variant="contained"
              sx={{
                color: HeroVideo5Style.Btn?.colorBtn
                  ? HeroVideo5Style.Btn.colorBtn
                  : 'white',
                backgroundColor: HeroVideo5Style.Btn?.backgroundColorBtn
                  ? `${HeroVideo5Style.Btn.backgroundColorBtn} !important`
                  : `black !important`,
                py: { xs: '8px', sm: '20px' },
                px: '45px',
                width: { md: '30%', lg: '23%' },

                textTransform: 'capitalize',
                borderTop: `7px solid ${
                  HeroVideo5Style.Btn?.borderColor
                    ? HeroVideo5Style.Btn.borderColor
                    : 'white'
                }`,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '1rem', md: '1.6rem' },
                }}
              >
                {lang === 'en' ? 'Shop Now' : 'تسوق الان'}
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default HeroVideo5
