import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

// import { ScrollColors } from './colors'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { ScrollColors } from '../../../similarproduct/colors'
import {
  useGetMostSellingProductsQuery,
  useLazyGetMostSellingProductsQuery,
  useLazyGetProductsOfCategoryQuery,
} from '../../../../../redux/apis/ProductApis'
import Card from '../../NewCard'

const ErrorSection = ({ isError, error }) => {
  const [, { language: lang }] = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color='error'
      >
        {isError
          ? error?.data && error?.data[`error_${lang}`]
          : error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}

const SliderHeader = ({ colors }) => {
  const [, { language: lang }] = useTranslation()
  const navigate = useNavigate()
  return (
    <Box sx={{ textAlign: lang === 'en' ? 'start' : 'end' }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '1.8rem', lg: '2.7rem' },
          color: colors.title,
        }}
      >
        {lang === 'en' ? 'similar products' : 'المنتجات المتشابهه'}
      </Typography>
      {/* <Button
        variant="outlined"
        sx={{
          mt: 2,
          color: colors.buttonTextColor,
          // borderRadius: props.borderRaduisBtn ? '40px' : '0px',
          padding: { xs: '0.4rem 3rem', lg: '0.6rem 5rem' },
          border: `1px solid ${colors.buttonBorderColor} !important`,
          backgroundColor: 'transparent !important',
        }}
        onClick={() => {
          navigate('/departments')
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '10px', sm: '12px', md: '18px', lg: '22px' },
            // i need to make words not capital
            textTransform: 'capitalize',
          }}
        >
          {lang === 'en' ? 'View All' : 'عرض الكل'}
        </Typography>
      </Button> */}
    </Box>
  )
}

const Slider = ({ data, lang, productid }) => {
  console.log(productid)
  return (
    <Grid
      width={'100%'}
      item
      xs={12}
      md={9}
      py={4}
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box px={2}>
        <Swiper
          style={{ direction: 'ltr' }}
          className='mySwiper'
          slidesPerView={3}
          spaceBetween={30}
          freeMode={true}
          modules={[FreeMode]}
          breakpoints={{
            320: {
              slidesPerView: 1.8,
              spaceBetween: 30,
            },
            719: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {data
            ?.filter(item => item?.id !== productid)
            .map(item => (
              <SwiperSlide key={item.title}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                      "& img":{
                        height: { xs: '210px', sm: '320px', md: '400px',
                        lg:'450px',xl:'470px' },
                      }
                  }}
                >
                  <Card item={item} />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </Grid>
  )
}

const MostSelling = ({ productId = '' }) => {
  const [data, setData] = useState({})
  const [getMostSellingProducts, { isSuccess, isError, error }] =
    useLazyGetMostSellingProductsQuery()

  // const [getSimilarProducts,{ isSuccess, isError, error} ] =
  // useLazyGetProductsOfCategoryQuery();
  useEffect(() => {
    getMostSellingProducts(`limit=6`)
      .unwrap()
      .then(res => {
        setData(res?.data)
      })
      .catch(err => console.log(err))
  }, [])
  const [, { language: lang }] = useTranslation()
  const { colors } = ScrollColors
  return (
    <>
      {console.log(data)}
      {data?.length > 1 ? (
        <Box
          mt={10}
          sx={{
            direction: lang === 'en' ? 'ltr' : 'rtl',
           }}
          my={data?.data !== undefined ? 5 : 0}
          py={data?.data !== undefined ? 3 : 0}
        >
          {isError && error && <ErrorSection error={error} isError={isError} />}
          {isSuccess && !isError && data?.length > 0 ? (
            <>
              <Stack>
                <Typography
                  sx={{
                    margin: {
                      xs: '10px auto',
                      sm: '14px auto',
                      md: '40px auto',
                    },
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: '40px auto',
                    fontSize: {
                      xs: '1.5rem',
                      sm: '1.5rem',
                      md: '30px',
                    },
                  }}
                  component={'h3'}
                >
                  {lang === 'en' ? 'Customers Also Bought' : 'عملاء اشترو ايضا'}
                </Typography>

                <Stack
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                ></Stack>
              </Stack>
              <Grid
                sx={{
                  jusitifyContent: 'center',
                }}
              >
                {/* Newest title and btn */}

                {/* Slider */}
                <Slider
                  data={data?.filter(pId => pId.id !== productId)}
                  productid={productId}
                  lang={lang}
                />
              </Grid>
            </>
          ) : null}
        </Box>
      ) : null}
    </>
  )
}

export default MostSelling
