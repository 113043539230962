// export default SelectPayment
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MoyasarLogo from '../../../assets/payment/moyasar.png'
import TabbyLogo from '../../../assets/payment/tabby.png'
import TamaraLogo from '../../../assets/payment/tamara.png'
import { useGetAllCartsQuery } from '../../../redux/apis/cartApi'
import {
  useLazyCheckoutTabbyQuery,
  useLazyCheckoutTamaraQuery,
} from '../../../redux/apis/ordersApi'
import { toast } from 'react-toastify'
import moyasaImg1 from '../../../assets2/moyasar_1.png'
import moyasarimg2 from '../../../assets2/moyasar_2.svg'
import moyasarImg3 from '../../../assets2/moyasar_3.png'

function SelectPayment() {
  const [selectedPayment, setSelectedPayment] = useState('')
  const navigate = useNavigate()
  const [checkoutTabby, { isLoading: tabbyLoad }] = useLazyCheckoutTabbyQuery()
  const [checkoutTamara, { isLoading: tamaraLoad }] =
    useLazyCheckoutTamaraQuery()

  const { data, isLoading, isSuccess } = useGetAllCartsQuery()
  const [, { language: lang }] = useTranslation()
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://checkout.tabby.ai/tabby-promo.js'
    script.async = true
    if (isSuccess) {
      script.onload = () => {
        // Once the script is loaded, initialize TabbyPromo
        new TabbyPromo({
          selector: '#TabbyPromo',
          currency: 'SAR',
          price: `${data?.data?.onlineItems?.totalPrice}`, // Use the state variable for price
          installmentsCount: 4,
          lang: lang === 'en' ? 'en' : 'ar',
          source: 'product',
          publicKey: 'pk_test_b7684c78-7362-4500-928d-aa995c57fdfc',
          merchantCode: 'Aldhubaibi',
        })
      }

      // Append the script to the document body
      document.body.appendChild(script)

      // Clean up the script on unmount
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [data])

  const handleChange = (event) => {
     
    setSelectedPayment(event.target.value)
  }

  const handleNextPayment = () => {
     
    if (selectedPayment === 'moyasar') {
      navigate('/payment-moyasar')
    } else if (selectedPayment === 'tabby') {
      checkoutTabby()
        .unwrap()
        .then((data) => {
           
          window.location.replace(data?.data?.url)
        })
        .catch((err) => {
          console.error('Error during Tabby checkout:', err)
        })
      // toast.error(lang==="en"? "unavailable at the moment": "غير متوفر حاليا")
    } else if (selectedPayment === 'tamara') {
      checkoutTamara()
        .unwrap()
        .then((data) => {
           
          window.location.replace(data?.data?.checkout_url)
        })
        .catch((err) => {
          console.error('Error during Tabby checkout:', err)
        })
      // toast.error(lang==="en"? "unavailable at the moment": "غير متوفر حاليا")
    }
  }

  const renderPaymentOption = (value, logo, labelEn, labelAr, imagesList) => (
    <Box
      key={value}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '20px',
        background: 'white',
        borderRadius: '5px',
        padding: '12px 10px',
        border: '2px solid #000',
        my: '20px',
        flexDirection: 'column',
      }}
    >
      <FormControlLabel
        value={value}
        sx={{
          mx: 0,
          "& .css-1sz3m2o-MuiButtonBase-root-MuiRadio-root.Mui-checked":{
            color:'black !important'
          }
        }}
        control={
          <Radio
            sx={{
              mx: 0,
              "&.css-111kf8v-MuiButtonBase-root-MuiRadio-root.Mui-checked":{
                color:'black !important'
              }
            }}
          />
        }
        label={
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Stack 
              mx={3}
              component={'img'}
                src={logo}
                alt={`${lang === 'em' ? labelEn : labelAr} Logo`}
                style={{ width: '70px', height: '70px', marginRight: 10 }}
              sx={{
                objectFit:'contain'
              }}
              />
              {imagesList && Array.isArray(imagesList) ? (
                imagesList.map((photo, idx) => (
                  <img
                    key={idx}
                    src={photo}
                    alt={`${lang === 'em' ? labelEn : labelAr} Logo`}
                    style={{
                      width: '65px',
                      height: '60px',
                      marginRight: 10,
                      objectFit: 'contain',
                    }}
                  />
                ))
              ) : (
                <Typography
                  style={{
                    fontSize: {
                      md: '20px',
                      xs: '14px',
                    },
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                  {lang === 'en' ? labelEn : labelAr}
                </Typography>
              )}
            </div>
          </>
        }
      />
      <Box
        sx={{
          width: '90%',
          direction: lang === 'en' ? 'ltr' : 'rtl',
          mx: { xs: '15px' },
        }}
      >
        {value === 'tabby' ? (
          <div id="TabbyPromo"></div>
        ) : (
          <Typography variant="p" sx={{ fontSize: '20px' }}>
            {isSuccess &&
              `${lang === 'en' ? 'total' : 'الاجمالي'} : SAR ${
                data?.data?.onlineItems?.totalPrice
              }`}
          </Typography>
        )}
      </Box>
    </Box>
  )

  return (
    <Box
      className="parent"
      sx={{
        width: { xs: '95%', sm: '80%', md: '50%', lg: '40%' },
        m: 'auto',
        my: '30px',
        direction: lang === 'en' ? 'ltr' : 'rtl',
        py: {
          lg: '8vh',
          xs: '6vh',
        },
      }}
    >
      {/* Select Payment Title */}
      <Typography
        variant="h3"
        sx={{
          color: '#000',
          p: '20px',
          borderBottom: '3px solid #000',
          width: 'fit-content',
          fontSize: '28px',
          fontWeight: 'bold',
          mb: '20px',
          mx: 'auto',
        }}
      >
        {lang === 'en' ? 'Select Payment' : 'اختر طريقة الدفع'}
      </Typography>
      {/* Payment Options */}
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedPayment}
        onChange={handleChange}
        sx={{
          color: '#000',
        }}
      >
        {renderPaymentOption(
          'moyasar',
          moyasaImg1,
          'Payment By Moyasar',
          'الدفع عن طريق ميسر',
          [ moyasarimg2, moyasarImg3]
        )}
        {/* {renderPaymentOption(
          'tabby',
          TabbyLogo,
          'Payment By Tabby',
          'الدفع عن طريق تابي'
        )} */}
        {renderPaymentOption(
          'tamara',
          TamaraLogo,
          'Payment By Tamara',
          'الدفع عن طريق تمارا'
        )}
      </RadioGroup>

      {/* Next Button */}
      <Box
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}
      >
        <Button
          variant="contained"
          sx={{
            maxWidth: '120px',
            fontSize: '14px',
            padding: '12px 32px',
            borderRadius: 2,
            bgcolor: '#000 !important',
            color:'#fff !important'
          }}
          onClick={handleNextPayment}
          disabled={!selectedPayment}
        >
          {tabbyLoad || tamaraLoad ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress
                sx={{
                  color: 'white',
                }}
              />
            </Box>
          ) : lang === 'en' ? (
            'Next'
          ) : (
            'التالي'
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default SelectPayment
