import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  CardMedia,
  CircularProgress,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Nooncolors } from '../colors'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FavoriteIconCard } from './loveicon'
import { Link, useNavigate } from 'react-router-dom'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../../../../redux/apis/cartApi'
import { toast } from 'react-toastify'
import '../NewCard/styles.css'
import { ProductModalAction } from '../../../../redux/slices/productModal/productModalSlice'
import { baseUrl, imageBaseUrl } from '../../../../constants/baseUrl'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import './styles.css'

// import required modules
import { HashNavigation, Navigation, Pagination } from 'swiper/modules'
function calculateDiscountPercentage(priceBefore, priceAfter) {
  // Ensure both inputs are valid numbers
  if (typeof priceBefore !== 'number' || typeof priceAfter !== 'number') {
    return 'Invalid input. Please provide valid numbers.'
  }

  // Ensure that priceBefore is not zero to avoid division by zero
  if (priceBefore === 0) {
    return false
  }

  // Calculate the discount percentage
  const discount = priceBefore - priceAfter
  const discountPercentage = (discount / priceBefore) * 100

  // Return the result
  return discountPercentage
}
// ================================customBreadCumbs=============================
function MouseOverPopover({
  children,
  text,
  cartData,
  setCartData,
  key,
  attr,
  values,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setCartData((prev) => {
      const newQualities = prev?.qualities?.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      return {
        ...prev,
        qualities: [
          ...newQualities,
          {
            key_en: attr?.key_en,
            key_ar: attr?.key_ar,
            value_en: values?.value_en,
            value_ar: values?.value_ar,
            price: values?.price,
          },
        ],
      }
    })
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setCartData((prev) => ({
      ...prev,
      qualities: cartData?.qualitiesBefore,
    }))
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disablePortal
        disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}> {text}</Typography>
      </Popover>
    </div>
  )
}
//   ========================================Attr===============================

const Attrs = ({ colors, attr, setCartData, cartData, cartIn }) => {

  const [, { language: lng }] = useTranslation()
  const handleUpdateQualities = ({ attr, values }) => {

    setCartData((prev) => {
      const newQualities = prev.qualities.filter(
        (v) => v.key_en !== "colors" 
      )
 
      const qualitiesBeforeHover = [
        ...newQualities,
        {
          key_ar: values.key_ar,
          key_en:  values.key_en,
          value_en: values.value_en,
          value_ar: values.value_ar,
          price: values?.price,
        },
      ]

      return {
        ...prev,
        qualities: qualitiesBeforeHover,
        qualitiesBefore: qualitiesBeforeHover,
        chooseQuality: {
          key_ar: values.key_ar,
          key_en:  values.key_en,
          value_en: values.value_en,
          value_ar: values.value_ar,
          price: values?.price,
        },
      }
    })
  }
  const isSelectedAtt = (val) => val?.value_en ==  cartData?.chooseQuality?.value_en

  return cartData?(
    <Box
      component={Swiper}
  
      slidesPerView={'auto'}
      spaceBetween={5}
      className="mySwiper"
      style={{ '--swiper-navigation-size': '24px' }}
      navigation={true}
      modules={[Navigation]}
      dir="ltr"
      sx={{
     
        '--swiper-navigation': {
          '&.swiper-button-prev': {
            left: '0px',
          },
          '&.swiper-button-next': {
            right: '0px',
          },
        },
      }}
    >
    

    {attr.map(item=> 
     <SwiperSlide key={item.color} style={{ width: 'auto', height: 'auto', padding:"3px" ,display: 'flex',margin:"0 !important",
            alignItems: 'center',
            justifyContent: 'center',}}>
        <ButtonBase
          sx={{
            border: '1px solid #fff',
           
          
            background: `${item.color}`,
            boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
            cursor: 'pointer',
            borderRadius: { xs: '4px', sm: '8px' },
            height: { xs: '12px', sm: '20px' },
            width: { xs: '12px', sm: '20px' },
            position: 'relative',
            '&::after': {
              position: isSelectedAtt(item) ? 'absolute' : 'none',
              height: '1px',
              content: isSelectedAtt(item) ? "''" : 'unset',
              width: '40px',
              background: '#333',
              transform: 'rotate(136deg)',
              top: '10px',
              left: '-3px',
            },
            filter: cartIn ? 'opacity(0.5)' : 'opacity(1)',
            height: '25px !important',
            width: '26px !important',
          }}
          disabled={cartIn}
          key={item.color}
          onClick={() =>
            handleUpdateQualities({
              item,
              values: {
                value_en: item.value_en,
                value_ar: item.value_ar,
                price: item?.price,
                color: item.color,
                key_ar: item.key_ar,
                key_en:  item.key_en,

              },
            })
          }
        ></ButtonBase>
      </SwiperSlide>)}
    </Box>
  ):null;
}

//

export default function Card({ item, ...addtionalsx }) {
  const [qImage, setQimages] = useState([])
  const [newQualityColors, setNewQualityColors] = useState([])
  const [cartData, setCartData] = useState({
    quantity: 1,
    qualities: [],
    id: item?._id || '',
    chooseQuality: {},
  })

  const [DeleteCart, { isLoading: DeleteFromCartLoading }] =
    useDeleteFromCartMutation()
  const [addToCart, { isLoading: cardLoad }] = useAddToCartMutation()
  const colors = Nooncolors
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const [, { language: lng }] = useTranslation()
  const [cartIn, setCartIn] = useState(false)
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const ProductModalActions = ProductModalAction
  const dispatch = useDispatch()

  const openModal = () => {
    dispatch(ProductModalActions.openModal(item))
  }
  useEffect(() => {
    const uniqueColors = [];
    const processedColors = new Set(); // Use a Set for efficient uniqueness check

    item?.qualities?.forEach((item) => {
      const colorObj = item.values.find((val) => val?.color?.startsWith("#"));
      const color = colorObj?.color;

      if (color && !processedColors.has(color)) {
        processedColors.add(color);
        uniqueColors.push({
          color,
          quantity: item.quantity,
          price: item.price,
          image: item.image[0], // Assuming you want the first image
          key_ar: colorObj.key_ar,
          key_en:  colorObj.key_en,
          value_en: colorObj?.value_en,
          value_ar: colorObj?.value_ar,
        });
      }
    });

 
    setNewQualityColors(uniqueColors)
    setCartData((prev) => ({
      ...prev,
      id: item?._id,
      title: item?.title_en,
      qualities: item?.qualities || [],
      quantity: 1,
      paymentType: item?.paymentType,
    }))
  }, [item])



  const {
    data: cartItems,
    error,
    isError: IsCartInErorr,
    isSuccess,
  } = useGetAllCartsQuery(undefined)
  const navigate = useNavigate()
  const HandleAddToCart = () => {


    if (cartData.paymentType === 'both') {
      navigate(`/products/${cartData.id}/${cartData.title}`)
      return
    }
    if ( item?.generalQualities?.length>1) {
      navigate(`/products/${cartData.id}/${cartData.title}`)
      return
    }
    if ( item?.generalQualities?.length===1&& !newQualityColors?.length) {
      navigate(`/products/${cartData.id}/${cartData.title}`)
      return
    }
    if ( item?.generalQualities?.length===1&& newQualityColors?.length&&!Object.keys(cartData.chooseQuality).length) {
        toast.error(lng === 'en' ?`you must select color`:`يجب عليك تحديد اللون`)
      return
    }
    if (
      !cartData.qualities.length &&
      !Object.keys(cartData.chooseQuality).length
    ) {
      let newCartData = {
        quantity: cartData?.quantity,
        id: cartData?.id,
        paymentType: cartData?.paymentType,
      }
      addToCart(newCartData)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) => {
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    } else if (Object.keys(cartData.chooseQuality).length) {
      let newChooseQuality = {
        key_en: cartData?.chooseQuality?.key_en,
        key_ar: cartData?.chooseQuality?.key_ar,
        value_en: cartData?.chooseQuality?.value_en,
        value_ar: cartData?.chooseQuality?.value_ar,
      }
      let newCartData = {
        quantity: cartData?.quantity,
        id: cartData?.id,
        qualities: [newChooseQuality],
        paymentType: cartData?.paymentType,
      }
      addToCart(newCartData)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) => {
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    }

  
  }
  const AddToCartFunc = () => {
  
      const qualitiesAfterDeletePrice = cartData?.qualities?.map((item) => {
        const { price, ...rest } = item; // Destructure and omit price
        return rest;
    });
    

      console.log(
        qualitiesAfterDeletePrice,
        cartData,
        'qualitiesAfterDeletePrice'
      )

      HandleAddToCart(
        qualitiesAfterDeletePrice.filter((item) => !item?.key_en === 'colors')
      )
    // }
  }
  useEffect(() => {
    if (isSuccess && cartItems && !error) {
      const cards = [
        ...cartItems?.data?.cashItems?.items,
        ...cartItems?.data?.onlineItems?.items,
      ]

     
    }
  }, [cartItems])
  useEffect(() => {
    if (Object.keys(cartData.chooseQuality).length&&newQualityColors.length) {
    let newQuality=  newQualityColors.find(item=>item?.value_en ===cartData?.chooseQuality?.value_en)
     
      const images2 =newQuality?.image
      console.log(newQuality?.image,"newQuality?.image");

      images2? setQimages([images2]):null
      const exteraPrices = (newQuality?.price+item?.finalPrice)

      setCartData({
        ...cartData,
        qualityAfterprice: exteraPrices,
      })
    }
  }, [cartData?.qualities, cartData?.chooseQuality])
  const extraPrice =cartData?.chooseQuality?.value_en? +newQualityColors.find(item=>item?.value_en ===cartData?.chooseQuality?.value_en)?.price||0:0
  const extraQuantity=item?.quantity>0?cartData?.chooseQuality?.value_en? +newQualityColors.find(item=>item?.value_en ===cartData?.chooseQuality?.value_en)?.quantity||0:item?.quantity:item?.quantity
  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100% !important',
        flexDirection: 'column',
      }}
    >
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Link
          to={`/products/${item?._id}/${item?.title_en.replace(/\s/g, '-')}`}
        >
          <CardMedia
            sx={{
              height: { xs: '300px', sm: '320px', md: '400px' },
              width: '100%',
              objectFit: 'contain',
            }}
            src={
              qImage?.length > 0
                ? `${imageBaseUrl}${qImage[0]}`
                : `${imageBaseUrl}${item?.images[0]}`
            }
            component={'img'}
          />
        </Link>

        <Box
          sx={{
            position: 'absolute',
            top: '85%',
            right: '14%',
            width: '75%',
            direction: 'ltr',
          }}
        >
          <Button
            className="btn btn-fill-middle"
            sx={{
              padding: { xs: '3px', md: '6px' },
              background: ' #fff',
              border: '1px solid #000',
              borderRadius: '0px',
              color: '#333',
              fontSize: '18px',
              width: '100%',
              position: 'relative',
              fontSize: { xs: '13px', md: '14px' },
            }}
            onClick={openModal}
          >
            {lng === 'en' ? 'Queick view' : 'نظره سريعه'}
          </Button>
        </Box>

        {item?.priceAfterDiscount > 0 &&
        item?.priceAfterDiscount !== item?.priceBeforeDiscount ? (
          <Typography
            sx={{
              width: 'fit-content',
              position: 'absolute',
              top: '7px',
              background: '#fff',
              padding: '2px 20px',
              border: '1px solid #ddd',
              ' font-weight': 'bold',
              'font-size': '16px',
              height: '30px',
              ' align-items': 'center',
              display: 'flex',
              height: '25px',
              left: '1px',
            }}
          >
            {lng === 'en' ? 'sale' : 'تخفيض'}
          </Typography>
        ) : null}
      </Stack>
      <Stack
        sx={{
          width: '97%',
          margin: 'auto',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row',
            padding: {
              xs: '1px',
              sm: '4px',
              md: '10px',
            },mt:"5px"
          }}
        >
          {item?.priceAfterDiscount > 0 &&
          item?.priceAfterDiscount !== item?.priceBeforeDiscount ? (
            <Typography
              sx={{
                fontSize: { xs: '11px', sm: '13px' },
              }}
              component={'p'}
            >
              {calculateDiscountPercentage(
                item?.priceBeforeDiscount,
                item?.priceAfterDiscount
              )}{' '}
              %
            </Typography>
          ) : null}

          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: colors.priceBeforeColor,
                textDecoration:
                  item?.priceAfterDiscount > 0 &&
                  item?.priceAfterDiscount !== item?.priceBeforeDiscount
                    ? 'line-through'
                    : 'none',
                fontSize:
                  item?.priceAfterDiscount > 0 &&
                  item?.priceAfterDiscount !== item?.priceBeforeDiscount
                    ? '0.7rem'
                    : 'initial',
                fontSize: { xs: '11px', sm: '13px' },
              }}
            >
              {}
              {(
                (item?.priceBeforeDiscount + (extraPrice || 0)) *
                currencyPrice
              ).toFixed(2)}{' '}
              {currencySymbol}
            </Typography>
            {item?.priceAfterDiscount > 0 &&
              item?.priceAfterDiscount !== item?.priceBeforeDiscount && (
                <Typography
                  sx={{
                    color: colors.priceAfterColor,

                    fontSize: { xs: '11px', sm: '13px' },
                  }}
                >
                  {(
                    (item?.priceAfterDiscount + (extraPrice || 0)) *
                    currencyPrice
                  ).toFixed(2)}{' '}
                  {currencySymbol}
                </Typography>
              )}
          </Stack>
        </Stack>
        <Stack>
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              margin: {
                xs: '4px auto',
                sm: '7px auto',
                md: '10px auto',
              },
              width: '100%',
              position: 'relative',
            }}
          >
            {newQualityColors.length > 0 ? (
              <Attrs
                colors={{
                  attrKeyColor: colors?.attrKeyColor,
                  attrValueColor: colors?.attrValueColor,
                  attrValueBgColor: colors?.attrValueBgColor,
                  attrValueBorderColor: colors?.attrValueBorderColor,
                  attrAciveColors: colors?.attrAciveColors,
                }}
                attr={newQualityColors}
                setCartData={setCartData}
                cartData={cartData}
                cartIn={cartIn}
              />
            ) : null}

            <Box
              sx={{
                flex: '1 1 0%',
              }}
            >
              {' '}
            </Box>

            <FavoriteIconCard card={true} lng={lng} data={item} />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        {cartIn ? (
          <Button
            className="btn btn-fill-middle"
            sx={{
              padding: { xs: '2px', md: '6px' },
              background: ' #fff',
              border: '1px solid #000',
              borderRadius: '0px',
              color: '#333',
              fontSize: { xs: '13px', md: '14px' },

              width: '99%',
              margin: 'auto',
            }}
            disabled={cardLoad || DeleteFromCartLoading}
            onClick={() => AddToCartFunc(cartData)}
          >
            {cardLoad || DeleteFromCartLoading ? (
              <>
                <Typography>
                  {lng === 'en' ? 'loading' : 'جاري التحميل'}
                </Typography>
                <CircularProgress
                  size={20}
                  style={{ marginRight: '4px' }}
                  color="inherit"
                />
              </>
            ) : lng === 'en' ? (
              'Delete From Cart'
            ) : (
              '   حذف من عربه التسوق    '
            )}
          </Button>
        ) : (
          <Button
            className="btn btn-fill-middle"
            sx={{
              padding: { xs: '2px', md: '6px' },
              background: ' #fff',
              border: '1px solid #000',
              borderRadius: '0px',
              color: '#333',
              fontSize: { xs: '13px', md: '14px' },
              width: '100%',
              margin: 'auto',
              position: 'relative',
              '&:hover': {
                '& div': {
                  opacity: 1,
                },
              },
            }}
            disabled={cardLoad || DeleteFromCartLoading||extraQuantity==0}
            onClick={AddToCartFunc}
          >
            {cardLoad || DeleteFromCartLoading ? (
              <>
                <Typography>
                  {lng === 'en' ? 'loading' : 'جاري التحميل'}
                </Typography>
                <CircularProgress
                  size={20}
                  style={{ marginRight: '4px' }}
                  color="inherit"
                />
              </>
            ) : extraQuantity==0?lng === 'en' ? (
              'out of stock'
            ) : (
              'المنتج غير متاح'
            ):lng === 'en' ? (
              'choose'
            ) : (
              '   اختر    '
            )}

            {cartData.paymentType === 'both' ||
            (item?.generalQualities?.length  &&
              !Object.keys(cartData.chooseQuality).length) ? (
              <Box 
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  inset: 0,
                  backgroundColor: 'rgba(0, 0, 0, 1)',
                  backdropFilter: 'blur(10px)',
                  padding: { xs: '2px', md: '6px' },
                  opacity: 0,
                  transitionDelay: '0s',
                }}
              >
                {' '}
                {  extraQuantity==0?lng === 'en' ? (
              'out of stock'
            ) : (
              'المنتج غير متاح'
            ):lng === 'en' ? 'choose Options' : "حدد خياراتك"}
              </Box>
            ) : (
              ''
            )}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
