import { useEffect, useState } from 'react'
import { cityEnglish } from '../../components/providers/city-english'
export const useFilterAreas = (value) => {
  const [state, setState] = useState([])
  useEffect(() => {
    if (value) {
      const filter = locations.cities.filter((obj) => obj.region_id === value)
      setState(filter)

         
    } else {
      setState([])
    }
  }, [value])
  return { filteredAreas: state }
}
export const locations = {
  regions: [
    {
      region_id: 1,
      capital_city_id: 3,
      code: 'RD',
      name_ar: 'منطقة الرياض',
      name_en: 'Riyadh',
      population: 6777146,
    },
    {
      region_id: 2,
      capital_city_id: 6,
      code: 'MQ',
      name_ar: 'منطقة مكة المكرمة',
      name_en: 'Makkah',
      population: 6915006,
    },
    {
      region_id: 3,
      capital_city_id: 14,
      code: 'MN',
      name_ar: 'منطقة المدينة المنورة',
      name_en: 'Madinah',
      population: 1777933,
    },
    {
      region_id: 4,
      capital_city_id: 11,
      code: 'QA',
      name_ar: 'منطقة القصيم',
      name_en: 'Qassim',
      population: 1215858,
    },
    {
      region_id: 5,
      capital_city_id: 13,
      code: 'SQ',
      name_ar: 'المنطقة الشرقية',
      name_en: 'Eastern Province',
      population: 4105780,
    },
    {
      region_id: 6,
      capital_city_id: 15,
      code: 'AS',
      name_ar: 'منطقة عسير',
      name_en: 'Asir',
      population: 1913392,
    },
    {
      region_id: 7,
      capital_city_id: 1,
      code: 'TB',
      name_ar: 'منطقة تبوك',
      name_en: 'Tabuk',
      population: 791535,
    },
    {
      region_id: 8,
      capital_city_id: 10,
      code: 'HA',
      name_ar: 'منطقة حائل',
      name_en: 'Hail',
      population: 597144,
    },
    {
      region_id: 9,
      capital_city_id: 2213,
      code: 'SH',
      name_ar: 'منطقة الحدود الشمالية',
      name_en: 'Northern Borders',
      population: 320524,
    },
    {
      region_id: 10,
      capital_city_id: 17,
      code: 'GA',
      name_ar: 'منطقة جازان',
      name_en: 'Jazan',
      population: 1365110,
    },
    {
      region_id: 11,
      capital_city_id: 3417,
      code: 'NG',
      name_ar: 'منطقة نجران',
      name_en: 'Najran',
      population: 505652,
    },
    {
      region_id: 12,
      capital_city_id: 1542,
      code: 'BA',
      name_ar: 'منطقة الباحة',
      name_en: 'Bahah',
      population: 411888,
    },
    {
      region_id: 13,
      capital_city_id: 2237,
      code: 'GO',
      name_ar: 'منطقة الجوف',
      name_en: 'Jawf',
      population: 440009,
    },
  ],
  cities: [
    {
      city_id: 1,
      region_id: 7,
      name_ar: 'تبوك',
      name_en: 'Tabuk',
    },
    {
      city_id: 2,
      region_id: 7,
      name_ar: 'نعمي',
      name_en: "Na'mi",
    },
    {
      city_id: 3,
      region_id: 1,
      name_ar: 'الرياض',
      name_en: 'Riyadh',
    },
    {
      city_id: 4,
      region_id: 7,
      name_ar: 'حميط',
      name_en: 'Humayt',
    },
    {
      city_id: 5,
      region_id: 2,
      name_ar: 'الطائف',
      name_en: 'At Taif',
    },
    {
      city_id: 6,
      region_id: 2,
      name_ar: 'مكة المكرمة',
      name_en: 'Makkah',
    },
    {
      city_id: 7,
      region_id: 7,
      name_ar: 'رجم الطيارة',
      name_en: 'Rajm At Tayarah',
    },
    {
      city_id: 8,
      region_id: 7,
      name_ar: 'الثميد',
      name_en: 'Ath Thumayd',
    },
    {
      city_id: 9,
      region_id: 7,
      name_ar: 'عسيلة',
      name_en: "'Usaylah",
    },
    {
      city_id: 10,
      region_id: 8,
      name_ar: 'حائل',
      name_en: 'Hail',
    },
    {
      city_id: 11,
      region_id: 4,
      name_ar: 'بريدة',
      name_en: 'Buraidah',
    },
    {
      city_id: 12,
      region_id: 5,
      name_ar: 'الهفوف',
      name_en: 'Al Hafuf',
    },
    {
      city_id: 13,
      region_id: 5,
      name_ar: 'الدمام',
      name_en: 'Dammam',
    },
    {
      city_id: 14,
      region_id: 3,
      name_ar: 'المدينة المنورة',
      name_en: 'Madinah',
    },
    {
      city_id: 15,
      region_id: 6,
      name_ar: 'ابها',
      name_en: 'Abha',
    },
    {
      city_id: 16,
      region_id: 7,
      name_ar: 'حالة عمار',
      name_en: 'Halat Ammar',
    },
    {
      city_id: 17,
      region_id: 10,
      name_ar: 'جازان',
      name_en: 'Jazan',
    },
    {
      city_id: 18,
      region_id: 2,
      name_ar: 'جدة',
      name_en: 'Jeddah',
    },
    {
      city_id: 19,
      region_id: 7,
      name_ar: 'الشايب',
      name_en: 'Ash Shayib',
    },
    {
      city_id: 20,
      region_id: 7,
      name_ar: 'الفوهة',
      name_en: 'Al Fawhah',
    },
    {
      city_id: 21,
      region_id: 7,
      name_ar: 'اللوز',
      name_en: 'Al Lawz',
    },
    {
      city_id: 22,
      region_id: 7,
      name_ar: 'عين الأخضر',
      name_en: "'Ayn Al Akhdar",
    },
    {
      city_id: 23,
      region_id: 7,
      name_ar: 'ذات الحاج',
      name_en: 'Dhat Al Hajj',
    },
    {
      city_id: 24,
      region_id: 1,
      name_ar: 'المجمعة',
      name_en: "Al Majma'ah",
    },
    {
      city_id: 25,
      region_id: 7,
      name_ar: 'قيال',
      name_en: 'Qiyal',
    },
    {
      city_id: 26,
      region_id: 7,
      name_ar: 'الاخضر',
      name_en: 'Al Akhdar',
    },
    {
      city_id: 27,
      region_id: 7,
      name_ar: 'البديعة',
      name_en: "Al Badi'ah",
    },
    {
      city_id: 28,
      region_id: 7,
      name_ar: 'مغيرة',
      name_en: 'Mughayrah',
    },
    {
      city_id: 29,
      region_id: 7,
      name_ar: 'الهوجاء',
      name_en: "Al Hawja'",
    },
    {
      city_id: 30,
      region_id: 7,
      name_ar: 'البديع',
      name_en: "Al Badi'",
    },
    {
      city_id: 31,
      region_id: 5,
      name_ar: 'الخبر',
      name_en: 'Al Khobar',
    },
    {
      city_id: 32,
      region_id: 7,
      name_ar: 'ابار قنا',
      name_en: 'Abar Qana',
    },
    {
      city_id: 33,
      region_id: 7,
      name_ar: 'الجبعاوية',
      name_en: "Al Jab'awiyah",
    },
    {
      city_id: 34,
      region_id: 7,
      name_ar: 'الحميضة',
      name_en: 'Al Humaydah',
    },
    {
      city_id: 35,
      region_id: 7,
      name_ar: 'البيانة',
      name_en: 'Al Bayyanah',
    },
    {
      city_id: 36,
      region_id: 7,
      name_ar: 'حقل',
      name_en: 'Haql',
    },
    {
      city_id: 37,
      region_id: 7,
      name_ar: 'الدرة',
      name_en: 'Ad Durrah',
    },
    {
      city_id: 38,
      region_id: 7,
      name_ar: 'الزيتة',
      name_en: 'Az Zaytah',
    },
    {
      city_id: 39,
      region_id: 7,
      name_ar: 'علقان',
      name_en: "'Alaqan",
    },
    {
      city_id: 40,
      region_id: 7,
      name_ar: 'الوادي الجديد',
      name_en: 'Al Wadi Al Jadid',
    },
    {
      city_id: 41,
      region_id: 7,
      name_ar: 'مليح',
      name_en: 'Mulayh',
    },
    {
      city_id: 42,
      region_id: 7,
      name_ar: 'ابو الحنشان',
      name_en: 'Abu Al Hinshan',
    },
    {
      city_id: 43,
      region_id: 7,
      name_ar: 'مقنا',
      name_en: 'Maqna',
    },
    {
      city_id: 44,
      region_id: 5,
      name_ar: 'ابو قعر',
      name_en: "Abu Qa'ar",
    },
    {
      city_id: 45,
      region_id: 5,
      name_ar: 'مركز العوجاء',
      name_en: "Markaz Al 'Awja",
    },
    {
      city_id: 46,
      region_id: 5,
      name_ar: 'مركز العليمة',
      name_en: "Markaz Al 'Ulayyimah",
    },
    {
      city_id: 47,
      region_id: 5,
      name_ar: 'حفر الباطن',
      name_en: 'Hafar Al Batin',
    },
    {
      city_id: 48,
      region_id: 5,
      name_ar: 'القلت',
      name_en: 'Al Qalt',
    },
    {
      city_id: 49,
      region_id: 5,
      name_ar: 'النظيم',
      name_en: 'An Nadhim',
    },
    {
      city_id: 50,
      region_id: 5,
      name_ar: 'ابن طوالة',
      name_en: 'Ibn Tuwalah',
    },
    {
      city_id: 51,
      region_id: 5,
      name_ar: 'الصداوي',
      name_en: 'As Sidawi',
    },
    {
      city_id: 52,
      region_id: 5,
      name_ar: 'ام قليب',
      name_en: 'Umm Qulaib',
    },
    {
      city_id: 53,
      region_id: 5,
      name_ar: 'عريفج',
      name_en: 'Urayfij',
    },
    {
      city_id: 54,
      region_id: 5,
      name_ar: 'ابن شرار',
      name_en: 'Ibn Sharar',
    },
    {
      city_id: 55,
      region_id: 5,
      name_ar: 'القيصومة',
      name_en: 'Al Qaysumah',
    },
    {
      city_id: 56,
      region_id: 5,
      name_ar: 'الرقعي الجديدة',
      name_en: "Ar Ruq'i Al Jadidah",
    },
    {
      city_id: 57,
      region_id: 5,
      name_ar: 'ذبحة',
      name_en: 'Dhabhah',
    },
    {
      city_id: 58,
      region_id: 5,
      name_ar: 'الصفيري',
      name_en: 'As Sufairy',
    },
    {
      city_id: 59,
      region_id: 5,
      name_ar: 'الوايلية',
      name_en: 'Al Wayliyah',
    },
    {
      city_id: 60,
      region_id: 5,
      name_ar: 'الفيوان',
      name_en: 'Al Fiwan',
    },
    {
      city_id: 61,
      region_id: 5,
      name_ar: 'الحماطيات',
      name_en: 'Al Hamatiyat',
    },
    {
      city_id: 62,
      region_id: 6,
      name_ar: 'خميس مشيط',
      name_en: 'Khamis Mushayt',
    },
    {
      city_id: 63,
      region_id: 5,
      name_ar: 'الجبو',
      name_en: 'Al Jabu',
    },
    {
      city_id: 64,
      region_id: 5,
      name_ar: 'المسناة',
      name_en: 'Al Masnah',
    },
    {
      city_id: 65,
      region_id: 6,
      name_ar: 'احد رفيده',
      name_en: 'Ahad Rifaydah',
    },
    {
      city_id: 66,
      region_id: 5,
      name_ar: 'ام عشر الشرقية',
      name_en: 'Umm Ishar Ash Sharqiyyah',
    },
    {
      city_id: 67,
      region_id: 5,
      name_ar: 'القطيف',
      name_en: 'Al Qatif',
    },
    {
      city_id: 68,
      region_id: 7,
      name_ar: 'بوهان',
      name_en: 'Buhan',
    },
    {
      city_id: 69,
      region_id: 7,
      name_ar: 'السنانيات',
      name_en: 'As Sananiyat',
    },
    {
      city_id: 70,
      region_id: 7,
      name_ar: 'حزايا',
      name_en: 'Hazaya',
    },
    {
      city_id: 71,
      region_id: 7,
      name_ar: 'أكباد',
      name_en: 'Akbad',
    },
    {
      city_id: 72,
      region_id: 7,
      name_ar: 'بئر الحيز',
      name_en: 'Bir Al Hayz',
    },
    {
      city_id: 73,
      region_id: 7,
      name_ar: 'جريداء',
      name_en: 'Jurayda',
    },
    {
      city_id: 74,
      region_id: 7,
      name_ar: 'تيماء',
      name_en: "Tayma'",
    },
    {
      city_id: 75,
      region_id: 7,
      name_ar: 'العسافية',
      name_en: 'Al Assafiyah',
    },
    {
      city_id: 76,
      region_id: 3,
      name_ar: 'عردة',
      name_en: "'Ardah",
    },
    {
      city_id: 77,
      region_id: 7,
      name_ar: 'الكتيب',
      name_en: 'Al Kutaib',
    },
    {
      city_id: 78,
      region_id: 7,
      name_ar: 'بئر فجر',
      name_en: "Bi'r Fajr",
    },
    {
      city_id: 79,
      region_id: 7,
      name_ar: 'القليبة',
      name_en: 'Al Qalibah',
    },
    {
      city_id: 80,
      region_id: 4,
      name_ar: 'عنيزة',
      name_en: 'Unayzah',
    },
    {
      city_id: 81,
      region_id: 5,
      name_ar: 'الرافعية',
      name_en: "Ar Raf'iyah",
    },
    {
      city_id: 82,
      region_id: 5,
      name_ar: 'الكبريت',
      name_en: 'Al Kabarit',
    },
    {
      city_id: 83,
      region_id: 5,
      name_ar: 'رغوة',
      name_en: 'Raghwah',
    },
    {
      city_id: 84,
      region_id: 5,
      name_ar: 'حمى',
      name_en: 'Hima',
    },
    {
      city_id: 85,
      region_id: 5,
      name_ar: 'الزبر',
      name_en: 'Az Zabr',
    },
    {
      city_id: 86,
      region_id: 5,
      name_ar: 'السفانية',
      name_en: 'As Saffaniyah',
    },
    {
      city_id: 87,
      region_id: 5,
      name_ar: 'المحوى',
      name_en: 'Al Mahawa',
    },
    {
      city_id: 88,
      region_id: 5,
      name_ar: 'أم غور',
      name_en: 'Umm Ghawr',
    },
    {
      city_id: 89,
      region_id: 5,
      name_ar: 'قرية العليا',
      name_en: "Qaryat Al 'Ulya",
    },
    {
      city_id: 90,
      region_id: 5,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 91,
      region_id: 5,
      name_ar: 'جرارة',
      name_en: 'Jarrarah',
    },
    {
      city_id: 92,
      region_id: 5,
      name_ar: 'قرية',
      name_en: 'Qurayyah',
    },
    {
      city_id: 93,
      region_id: 5,
      name_ar: 'البويبيات',
      name_en: 'Al Buwaybiyat',
    },
    {
      city_id: 94,
      region_id: 5,
      name_ar: 'السعيرة',
      name_en: "As Su'ayyirah",
    },
    {
      city_id: 95,
      region_id: 5,
      name_ar: 'مناخ',
      name_en: 'Manakh',
    },
    {
      city_id: 96,
      region_id: 5,
      name_ar: 'الحيرا',
      name_en: 'Al Hayra',
    },
    {
      city_id: 97,
      region_id: 5,
      name_ar: 'ام الشفلح',
      name_en: 'Umm Ash Shifallah',
    },
    {
      city_id: 98,
      region_id: 5,
      name_ar: 'اللهابة',
      name_en: 'Al Lahabah',
    },
    {
      city_id: 99,
      region_id: 5,
      name_ar: 'الفريدة',
      name_en: 'Al Farridah',
    },
    {
      city_id: 100,
      region_id: 5,
      name_ar: 'الشامية',
      name_en: 'Ash Shamiyah',
    },
    {
      city_id: 101,
      region_id: 1,
      name_ar: 'العيطلية',
      name_en: "Al 'Aytaliyah",
    },
    {
      city_id: 102,
      region_id: 5,
      name_ar: 'سحمة',
      name_en: 'Sihmah',
    },
    {
      city_id: 103,
      region_id: 5,
      name_ar: 'الشملول / ام عقلا',
      name_en: 'Ash Shamlul (Umm Aqla)',
    },
    {
      city_id: 104,
      region_id: 5,
      name_ar: 'ام الهوشات',
      name_en: 'Umm Al Hawshat',
    },
    {
      city_id: 105,
      region_id: 5,
      name_ar: 'الشيط',
      name_en: 'Ash Shayyit',
    },
    {
      city_id: 106,
      region_id: 5,
      name_ar: 'العاذرية',
      name_en: "Al 'Adhiriyah",
    },
    {
      city_id: 107,
      region_id: 5,
      name_ar: 'الشيحية',
      name_en: 'Ash Shihiyah',
    },
    {
      city_id: 108,
      region_id: 1,
      name_ar: 'حزوة / العمانية',
      name_en: 'Hizwah (Al Umaniyah)',
    },
    {
      city_id: 109,
      region_id: 5,
      name_ar: 'القرعاء',
      name_en: "Al Qar'a",
    },
    {
      city_id: 110,
      region_id: 5,
      name_ar: 'اللصافة',
      name_en: 'Al Lisafah',
    },
    {
      city_id: 111,
      region_id: 5,
      name_ar: 'النقيرة',
      name_en: 'An Nuqayrah',
    },
    {
      city_id: 112,
      region_id: 5,
      name_ar: 'هجرة أولاد حثلين',
      name_en: 'Hijrat Awlad Hithlin',
    },
    {
      city_id: 113,
      region_id: 5,
      name_ar: 'الجبيل',
      name_en: 'Al Jubail',
    },
    {
      city_id: 114,
      region_id: 5,
      name_ar: 'فرزان',
      name_en: 'Farzan',
    },
    {
      city_id: 115,
      region_id: 5,
      name_ar: 'النعيرية',
      name_en: "An Nu'ayriyah",
    },
    {
      city_id: 116,
      region_id: 5,
      name_ar: 'ام ضليع',
      name_en: "Umm Dulay'",
    },
    {
      city_id: 117,
      region_id: 5,
      name_ar: 'مليجة',
      name_en: 'Mulayjah',
    },
    {
      city_id: 118,
      region_id: 5,
      name_ar: 'الصرار',
      name_en: 'As Sarrar',
    },
    {
      city_id: 119,
      region_id: 5,
      name_ar: 'حنيذ',
      name_en: 'Hanidh',
    },
    {
      city_id: 120,
      region_id: 5,
      name_ar: 'مغطي',
      name_en: 'Mughati',
    },
    {
      city_id: 121,
      region_id: 5,
      name_ar: 'شفية',
      name_en: 'Shifiyah',
    },
    {
      city_id: 122,
      region_id: 5,
      name_ar: 'عتيق',
      name_en: 'Utayyiq',
    },
    {
      city_id: 123,
      region_id: 5,
      name_ar: 'الحسي',
      name_en: 'Al Husayy',
    },
    {
      city_id: 124,
      region_id: 5,
      name_ar: 'ثاج',
      name_en: 'Thaj',
    },
    {
      city_id: 125,
      region_id: 5,
      name_ar: 'الحناة',
      name_en: 'Al Hinnah',
    },
    {
      city_id: 126,
      region_id: 5,
      name_ar: 'الكهفة',
      name_en: 'Al Kahafah',
    },
    {
      city_id: 127,
      region_id: 5,
      name_ar: 'الصحاف',
      name_en: 'As Sahaf',
    },
    {
      city_id: 128,
      region_id: 5,
      name_ar: 'العيينة',
      name_en: 'Al Uyainah',
    },
    {
      city_id: 129,
      region_id: 5,
      name_ar: 'القليب',
      name_en: 'Al Qulayyib',
    },
    {
      city_id: 130,
      region_id: 5,
      name_ar: 'الونان',
      name_en: 'Al Wannan',
    },
    {
      city_id: 131,
      region_id: 5,
      name_ar: 'غنوى',
      name_en: 'Ghanwa',
    },
    {
      city_id: 132,
      region_id: 5,
      name_ar: 'الزغين',
      name_en: 'Az Zughayn',
    },
    {
      city_id: 133,
      region_id: 5,
      name_ar: 'نطاع',
      name_en: "Nita'",
    },
    {
      city_id: 134,
      region_id: 5,
      name_ar: 'ام الحمام',
      name_en: 'Umm Al Hamam',
    },
    {
      city_id: 135,
      region_id: 5,
      name_ar: 'ام ربيعة',
      name_en: "Umm Rubay'ah",
    },
    {
      city_id: 136,
      region_id: 5,
      name_ar: 'ابو حدرية',
      name_en: 'Abu Hadriyah',
    },
    {
      city_id: 137,
      region_id: 5,
      name_ar: 'منيفة',
      name_en: 'Munifah',
    },
    {
      city_id: 138,
      region_id: 1,
      name_ar: 'الافلاج',
      name_en: 'Al Aflaj',
    },
    {
      city_id: 139,
      region_id: 4,
      name_ar: 'خيطان',
      name_en: 'Khaitan',
    },
    {
      city_id: 140,
      region_id: 1,
      name_ar: 'الوسيعة',
      name_en: "Al Wasi'ah",
    },
    {
      city_id: 141,
      region_id: 1,
      name_ar: 'تمرية',
      name_en: 'Tamriyah',
    },
    {
      city_id: 142,
      region_id: 1,
      name_ar: 'ابو خسيفاء',
      name_en: 'Abu Khusayfa',
    },
    {
      city_id: 143,
      region_id: 1,
      name_ar: 'النخيل',
      name_en: 'An Nakhil',
    },
    {
      city_id: 144,
      region_id: 1,
      name_ar: 'السحيمي',
      name_en: 'As Suhaymi',
    },
    {
      city_id: 145,
      region_id: 1,
      name_ar: 'مصدة',
      name_en: 'Masadah',
    },
    {
      city_id: 146,
      region_id: 1,
      name_ar: 'أم سديرة',
      name_en: 'Umm Sudayrah',
    },
    {
      city_id: 147,
      region_id: 1,
      name_ar: 'التنهاة',
      name_en: 'At Tanhah',
    },
    {
      city_id: 148,
      region_id: 1,
      name_ar: 'قري التويم',
      name_en: 'Qura At Tuwaym',
    },
    {
      city_id: 149,
      region_id: 1,
      name_ar: 'الشحمة',
      name_en: 'Ash Shahmah',
    },
    {
      city_id: 150,
      region_id: 1,
      name_ar: 'الودي',
      name_en: 'Al Wuday',
    },
    {
      city_id: 151,
      region_id: 1,
      name_ar: 'جوي',
      name_en: 'Juwayy',
    },
    {
      city_id: 152,
      region_id: 1,
      name_ar: 'مقبلة',
      name_en: 'Muqbilah',
    },
    {
      city_id: 153,
      region_id: 1,
      name_ar: 'حرمة',
      name_en: 'Harmah',
    },
    {
      city_id: 154,
      region_id: 7,
      name_ar: 'المعظم',
      name_en: "Al Ma'dham",
    },
    {
      city_id: 155,
      region_id: 1,
      name_ar: 'جراب',
      name_en: 'Jirab',
    },
    {
      city_id: 156,
      region_id: 1,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 157,
      region_id: 1,
      name_ar: 'النغيق',
      name_en: 'An Nughayq',
    },
    {
      city_id: 158,
      region_id: 1,
      name_ar: 'حويمضة',
      name_en: 'Huwaimidah',
    },
    {
      city_id: 159,
      region_id: 1,
      name_ar: 'البتيراء',
      name_en: "Al Butaira'",
    },
    {
      city_id: 160,
      region_id: 1,
      name_ar: 'المشاش',
      name_en: 'Al Mishash',
    },
    {
      city_id: 161,
      region_id: 1,
      name_ar: 'الفروثي',
      name_en: 'Al Furuthi',
    },
    {
      city_id: 162,
      region_id: 1,
      name_ar: 'جلاجل',
      name_en: 'Jalajil',
    },
    {
      city_id: 163,
      region_id: 1,
      name_ar: 'الدخيلة',
      name_en: 'Ad Dakhilah',
    },
    {
      city_id: 164,
      region_id: 1,
      name_ar: 'الحصون',
      name_en: 'Al Husun',
    },
    {
      city_id: 165,
      region_id: 1,
      name_ar: 'حوطة سدير',
      name_en: 'Hawtat Sudair',
    },
    {
      city_id: 166,
      region_id: 1,
      name_ar: 'روضة سدير',
      name_en: 'Rawdat Sudair',
    },
    {
      city_id: 167,
      region_id: 1,
      name_ar: 'تمير',
      name_en: 'Tumair',
    },
    {
      city_id: 168,
      region_id: 1,
      name_ar: 'الارطاوية',
      name_en: 'Al Artawiyah',
    },
    {
      city_id: 169,
      region_id: 1,
      name_ar: 'العمار',
      name_en: "Al 'Amar",
    },
    {
      city_id: 170,
      region_id: 1,
      name_ar: 'الخيس',
      name_en: 'Al Khis',
    },
    {
      city_id: 171,
      region_id: 1,
      name_ar: 'المعشبة',
      name_en: "Al Ma'ashbah",
    },
    {
      city_id: 172,
      region_id: 1,
      name_ar: 'التويم',
      name_en: 'At Tuwaym',
    },
    {
      city_id: 173,
      region_id: 1,
      name_ar: 'الخطامة',
      name_en: 'Al Khutamah',
    },
    {
      city_id: 174,
      region_id: 1,
      name_ar: 'رويضة بوضاء',
      name_en: 'Ruwaydah Buwadaa',
    },
    {
      city_id: 175,
      region_id: 1,
      name_ar: 'الشعب',
      name_en: "Ash Shi'b",
    },
    {
      city_id: 176,
      region_id: 1,
      name_ar: 'عشيرة سدير',
      name_en: 'Asharat Sudair',
    },
    {
      city_id: 177,
      region_id: 1,
      name_ar: 'الجنيفي',
      name_en: 'Al Junayfi',
    },
    {
      city_id: 178,
      region_id: 1,
      name_ar: 'العطار',
      name_en: "Al 'Attar",
    },
    {
      city_id: 179,
      region_id: 1,
      name_ar: 'ام الجماجم',
      name_en: 'Umm Al Jamajim',
    },
    {
      city_id: 180,
      region_id: 1,
      name_ar: 'مشلح',
      name_en: 'Mishlah',
    },
    {
      city_id: 181,
      region_id: 1,
      name_ar: 'ام رجوم',
      name_en: 'Umm Rujum',
    },
    {
      city_id: 182,
      region_id: 1,
      name_ar: 'الرويضة',
      name_en: 'Ar Ruwaydah',
    },
    {
      city_id: 183,
      region_id: 1,
      name_ar: 'الفيصلية',
      name_en: 'Al Faysaliyah',
    },
    {
      city_id: 184,
      region_id: 1,
      name_ar: 'بوضاء',
      name_en: "Bawda'",
    },
    {
      city_id: 185,
      region_id: 1,
      name_ar: 'الحائر',
      name_en: 'Al Hair',
    },
    {
      city_id: 186,
      region_id: 1,
      name_ar: 'وشي',
      name_en: 'Wushayy',
    },
    {
      city_id: 187,
      region_id: 1,
      name_ar: 'عودة سدير',
      name_en: "'Awdat Sudayr",
    },
    {
      city_id: 188,
      region_id: 1,
      name_ar: 'مبايض',
      name_en: 'Mubayid',
    },
    {
      city_id: 189,
      region_id: 1,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 190,
      region_id: 3,
      name_ar: 'دبدبة فضلاء',
      name_en: 'Dibdibbat Fudala',
    },
    {
      city_id: 191,
      region_id: 7,
      name_ar: 'الحجب',
      name_en: 'Al Hajab',
    },
    {
      city_id: 192,
      region_id: 7,
      name_ar: 'الضلفة',
      name_en: 'Adh Dhalfah',
    },
    {
      city_id: 193,
      region_id: 3,
      name_ar: 'أبو طاقة',
      name_en: 'Abu Taqah',
    },
    {
      city_id: 194,
      region_id: 3,
      name_ar: 'العين الجديدة',
      name_en: "Al 'Ayn Al Jadidah",
    },
    {
      city_id: 195,
      region_id: 3,
      name_ar: 'قعرة الدومة',
      name_en: "Qa'arah Al Daumah",
    },
    {
      city_id: 196,
      region_id: 3,
      name_ar: 'أم زرب',
      name_en: 'Umm Zarb',
    },
    {
      city_id: 197,
      region_id: 3,
      name_ar: 'هدية',
      name_en: 'Hadiyah',
    },
    {
      city_id: 198,
      region_id: 3,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 199,
      region_id: 3,
      name_ar: 'العلا',
      name_en: 'Al Ula',
    },
    {
      city_id: 200,
      region_id: 7,
      name_ar: 'الجهراء',
      name_en: 'Al Jahara',
    },
    {
      city_id: 201,
      region_id: 7,
      name_ar: 'رحيب',
      name_en: 'Ruhayb',
    },
    {
      city_id: 202,
      region_id: 3,
      name_ar: 'شلال',
      name_en: 'Shalal',
    },
    {
      city_id: 203,
      region_id: 3,
      name_ar: 'ضاعا',
      name_en: "Da'a",
    },
    {
      city_id: 204,
      region_id: 3,
      name_ar: 'جيدة',
      name_en: 'Jaydah',
    },
    {
      city_id: 205,
      region_id: 3,
      name_ar: 'قلبان عشرة',
      name_en: "Qulban 'Isharah",
    },
    {
      city_id: 206,
      region_id: 7,
      name_ar: 'النجيل',
      name_en: 'An Najil',
    },
    {
      city_id: 207,
      region_id: 3,
      name_ar: 'الرزيقية',
      name_en: 'Ar Ruzayqiyah',
    },
    {
      city_id: 208,
      region_id: 3,
      name_ar: 'الحميدية',
      name_en: 'Al Hamidiyah',
    },
    {
      city_id: 209,
      region_id: 3,
      name_ar: 'صدر',
      name_en: 'Sadr',
    },
    {
      city_id: 210,
      region_id: 3,
      name_ar: 'مغيراء',
      name_en: "Mughayra'",
    },
    {
      city_id: 211,
      region_id: 3,
      name_ar: 'قصيب ابو سيال',
      name_en: 'Qusayb Abu Siyal',
    },
    {
      city_id: 212,
      region_id: 7,
      name_ar: 'ابو اراكة',
      name_en: 'Abu Arakah',
    },
    {
      city_id: 213,
      region_id: 3,
      name_ar: 'مدائن الصالح',
      name_en: 'Madain As Salih',
    },
    {
      city_id: 214,
      region_id: 3,
      name_ar: 'عورش',
      name_en: 'Awarsh',
    },
    {
      city_id: 215,
      region_id: 7,
      name_ar: 'النشيفة',
      name_en: 'An Nushayfah',
    },
    {
      city_id: 216,
      region_id: 3,
      name_ar: 'الزباير',
      name_en: 'Az Zubayir',
    },
    {
      city_id: 217,
      region_id: 3,
      name_ar: 'الضليعة',
      name_en: "Ad Dulay'ah",
    },
    {
      city_id: 218,
      region_id: 3,
      name_ar: 'متان العريقة',
      name_en: "Mitan Al 'Urayqah",
    },
    {
      city_id: 219,
      region_id: 3,
      name_ar: 'الابرق',
      name_en: 'Al Abraq',
    },
    {
      city_id: 220,
      region_id: 3,
      name_ar: 'اميرة',
      name_en: 'Amirah',
    },
    {
      city_id: 221,
      region_id: 3,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 222,
      region_id: 3,
      name_ar: 'كتيفة مصادر',
      name_en: 'Kutayfat Masadir',
    },
    {
      city_id: 223,
      region_id: 7,
      name_ar: 'الراس',
      name_en: 'Ar Ras',
    },
    {
      city_id: 224,
      region_id: 7,
      name_ar: 'البيت',
      name_en: 'Al Bayt',
    },
    {
      city_id: 225,
      region_id: 7,
      name_ar: 'بئر بحار',
      name_en: 'Bir Bahar',
    },
    {
      city_id: 226,
      region_id: 7,
      name_ar: 'سبحان',
      name_en: 'Sabhan',
    },
    {
      city_id: 227,
      region_id: 5,
      name_ar: 'الظهران',
      name_en: 'Dhahran',
    },
    {
      city_id: 228,
      region_id: 7,
      name_ar: 'أم الريح',
      name_en: 'Umm Ar Rih',
    },
    {
      city_id: 229,
      region_id: 7,
      name_ar: 'حرم',
      name_en: 'Haram',
    },
    {
      city_id: 230,
      region_id: 7,
      name_ar: 'عكوز',
      name_en: "'Akuz",
    },
    {
      city_id: 231,
      region_id: 7,
      name_ar: 'السديد',
      name_en: 'As Sudayd',
    },
    {
      city_id: 232,
      region_id: 7,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 233,
      region_id: 7,
      name_ar: 'الوجه',
      name_en: 'Al Wajh',
    },
    {
      city_id: 234,
      region_id: 7,
      name_ar: 'النابع',
      name_en: "An Nabi'",
    },
    {
      city_id: 235,
      region_id: 7,
      name_ar: 'عنتر',
      name_en: "'Antar",
    },
    {
      city_id: 236,
      region_id: 7,
      name_ar: 'المنجور',
      name_en: 'Al Manjur',
    },
    {
      city_id: 237,
      region_id: 7,
      name_ar: 'ابا القزاز',
      name_en: 'Aba Al Qizaz',
    },
    {
      city_id: 238,
      region_id: 7,
      name_ar: 'بداء',
      name_en: "Bada'",
    },
    {
      city_id: 239,
      region_id: 7,
      name_ar: 'خرباء',
      name_en: "Khurba'",
    },
    {
      city_id: 240,
      region_id: 7,
      name_ar: 'الكر',
      name_en: 'Al Kurr',
    },
    {
      city_id: 241,
      region_id: 5,
      name_ar: 'برق الأسيدية',
      name_en: 'Burq Al Usaydiyah',
    },
    {
      city_id: 242,
      region_id: 5,
      name_ar: 'الفاضلي',
      name_en: 'Al Fadili',
    },
    {
      city_id: 243,
      region_id: 5,
      name_ar: 'بقيق',
      name_en: 'Buqayq',
    },
    {
      city_id: 244,
      region_id: 5,
      name_ar: 'قرية',
      name_en: 'Qurayyah',
    },
    {
      city_id: 245,
      region_id: 5,
      name_ar: 'ظلوم',
      name_en: 'Dhulum',
    },
    {
      city_id: 246,
      region_id: 5,
      name_ar: 'عين دار الجديده',
      name_en: "New 'Ayn  Dar",
    },
    {
      city_id: 247,
      region_id: 5,
      name_ar: 'عين دار القديمة',
      name_en: "Old 'Ayn  Dar",
    },
    {
      city_id: 248,
      region_id: 5,
      name_ar: 'علاة',
      name_en: 'Allat',
    },
    {
      city_id: 249,
      region_id: 5,
      name_ar: 'فودة',
      name_en: 'Fudah',
    },
    {
      city_id: 250,
      region_id: 5,
      name_ar: 'الكدادية',
      name_en: 'Al Kadadiyah',
    },
    {
      city_id: 251,
      region_id: 5,
      name_ar: 'يكرب',
      name_en: 'Yakrub',
    },
    {
      city_id: 252,
      region_id: 5,
      name_ar: 'الجابرية',
      name_en: 'Al Jabiriyah',
    },
    {
      city_id: 253,
      region_id: 5,
      name_ar: 'صلاصل',
      name_en: 'Salasil',
    },
    {
      city_id: 254,
      region_id: 5,
      name_ar: 'شهيلاء',
      name_en: "Shuhayla'",
    },
    {
      city_id: 255,
      region_id: 5,
      name_ar: 'عصيفرات',
      name_en: 'Usayfirat',
    },
    {
      city_id: 256,
      region_id: 6,
      name_ar: 'طريب',
      name_en: 'Tarib',
    },
    {
      city_id: 257,
      region_id: 5,
      name_ar: 'الدغيمية',
      name_en: 'Ad Dughaymiyah',
    },
    {
      city_id: 258,
      region_id: 1,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 259,
      region_id: 1,
      name_ar: 'المنسف',
      name_en: 'Al Mansaf',
    },
    {
      city_id: 260,
      region_id: 1,
      name_ar: 'منسية الغربية',
      name_en: 'Mansiyah Al Gharbiyah',
    },
    {
      city_id: 261,
      region_id: 1,
      name_ar: 'عشيرة',
      name_en: "'Ushayrah",
    },
    {
      city_id: 262,
      region_id: 1,
      name_ar: 'الفيصلية',
      name_en: 'Al Faysaliyah',
    },
    {
      city_id: 263,
      region_id: 1,
      name_ar: 'الثوير',
      name_en: 'Ath Thuwayr',
    },
    {
      city_id: 264,
      region_id: 1,
      name_ar: 'زليغف',
      name_en: 'Zulayghif',
    },
    {
      city_id: 265,
      region_id: 1,
      name_ar: 'مزارع الاثلة',
      name_en: "Mazari' Al Athlah",
    },
    {
      city_id: 266,
      region_id: 1,
      name_ar: 'مزارع الرحية',
      name_en: "Mazari' Ar Ruhayyah",
    },
    {
      city_id: 267,
      region_id: 1,
      name_ar: 'قصيباء',
      name_en: 'Qusayba',
    },
    {
      city_id: 268,
      region_id: 1,
      name_ar: 'مزرعة بيضاء نثيل',
      name_en: "Mazra'at Bayda Nuthayl",
    },
    {
      city_id: 269,
      region_id: 1,
      name_ar: 'امارة المستوي',
      name_en: 'Imarat Al Mistawi',
    },
    {
      city_id: 270,
      region_id: 1,
      name_ar: 'الزلفي',
      name_en: 'Az Zulfi',
    },
    {
      city_id: 271,
      region_id: 1,
      name_ar: 'سمنان',
      name_en: 'Samnan',
    },
    {
      city_id: 272,
      region_id: 1,
      name_ar: 'علقة',
      name_en: "'Iliqah",
    },
    {
      city_id: 273,
      region_id: 3,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 274,
      region_id: 3,
      name_ar: 'المضاويح',
      name_en: 'Al Mudhawih',
    },
    {
      city_id: 275,
      region_id: 3,
      name_ar: 'ابا البقر',
      name_en: 'Aba Al Baqar',
    },
    {
      city_id: 276,
      region_id: 3,
      name_ar: 'الحرضة',
      name_en: 'Al Hardhah',
    },
    {
      city_id: 277,
      region_id: 3,
      name_ar: 'الضرس',
      name_en: 'Adh Dhars',
    },
    {
      city_id: 278,
      region_id: 3,
      name_ar: 'الخريبة',
      name_en: 'Al Khuraybah',
    },
    {
      city_id: 279,
      region_id: 3,
      name_ar: 'العرائد',
      name_en: "Al 'Araid",
    },
    {
      city_id: 280,
      region_id: 3,
      name_ar: 'غمرة',
      name_en: 'Ghamrah',
    },
    {
      city_id: 281,
      region_id: 3,
      name_ar: 'العقيلة',
      name_en: "Al 'Uqaylah",
    },
    {
      city_id: 282,
      region_id: 3,
      name_ar: 'العدلة',
      name_en: "Al 'Adlah",
    },
    {
      city_id: 283,
      region_id: 3,
      name_ar: 'الديسة',
      name_en: 'Ad Disah',
    },
    {
      city_id: 284,
      region_id: 3,
      name_ar: 'السليمي',
      name_en: 'As Sulaymi',
    },
    {
      city_id: 285,
      region_id: 3,
      name_ar: 'الجرف',
      name_en: 'Al Jarf',
    },
    {
      city_id: 286,
      region_id: 3,
      name_ar: 'الهذلولي',
      name_en: 'Al Hadhluli',
    },
    {
      city_id: 287,
      region_id: 3,
      name_ar: 'جدعاء',
      name_en: "Jad'a",
    },
    {
      city_id: 288,
      region_id: 3,
      name_ar: 'خيبر',
      name_en: 'Khaybar',
    },
    {
      city_id: 289,
      region_id: 3,
      name_ar: 'اللحن',
      name_en: 'Al Lihin',
    },
    {
      city_id: 290,
      region_id: 3,
      name_ar: 'العشاش',
      name_en: "Al 'Ishash",
    },
    {
      city_id: 291,
      region_id: 3,
      name_ar: 'الصلصلة',
      name_en: 'As Silsilah',
    },
    {
      city_id: 292,
      region_id: 3,
      name_ar: 'الثمد',
      name_en: 'Al Thamad',
    },
    {
      city_id: 293,
      region_id: 3,
      name_ar: 'العينية',
      name_en: 'Al Uyaynah',
    },
    {
      city_id: 294,
      region_id: 1,
      name_ar: 'رماح',
      name_en: 'Rumah',
    },
    {
      city_id: 295,
      region_id: 1,
      name_ar: 'حفر العتك',
      name_en: 'Hafar Al Atk',
    },
    {
      city_id: 296,
      region_id: 1,
      name_ar: 'المزيرع',
      name_en: "Al Muzayri'",
    },
    {
      city_id: 297,
      region_id: 1,
      name_ar: 'شوية',
      name_en: 'Shawyah',
    },
    {
      city_id: 298,
      region_id: 1,
      name_ar: 'الحفنة',
      name_en: 'Al Hifnah',
    },
    {
      city_id: 299,
      region_id: 1,
      name_ar: 'الغيلانة',
      name_en: 'Al Ghaylanah',
    },
    {
      city_id: 300,
      region_id: 1,
      name_ar: 'الرمحية',
      name_en: 'Ar Rumhiyah',
    },
    {
      city_id: 301,
      region_id: 5,
      name_ar: 'الراكة',
      name_en: 'Ar Rakah',
    },
    {
      city_id: 302,
      region_id: 7,
      name_ar: 'الخريطة',
      name_en: 'Al Khuraytah',
    },
    {
      city_id: 303,
      region_id: 5,
      name_ar: 'الثقبة',
      name_en: 'Ath Thuqbah',
    },
    {
      city_id: 304,
      region_id: 5,
      name_ar: 'العزيزية',
      name_en: "Al 'Aziziyah",
    },
    {
      city_id: 305,
      region_id: 7,
      name_ar: 'شدوا',
      name_en: 'Shadwa',
    },
    {
      city_id: 306,
      region_id: 1,
      name_ar: 'الغاط',
      name_en: 'Al Ghat',
    },
    {
      city_id: 307,
      region_id: 1,
      name_ar: 'مليح',
      name_en: 'Mulayh',
    },
    {
      city_id: 308,
      region_id: 7,
      name_ar: 'الشبعان',
      name_en: 'Ash Shaban',
    },
    {
      city_id: 309,
      region_id: 7,
      name_ar: 'الدقم',
      name_en: 'Ad Duqum',
    },
    {
      city_id: 310,
      region_id: 7,
      name_ar: 'القرص',
      name_en: 'Al Qars',
    },
    {
      city_id: 311,
      region_id: 7,
      name_ar: 'حراض',
      name_en: 'Hirad',
    },
    {
      city_id: 312,
      region_id: 7,
      name_ar: 'الشبحة',
      name_en: 'Ash Shibahah',
    },
    {
      city_id: 313,
      region_id: 7,
      name_ar: 'روضة الاغيدرات',
      name_en: 'Rawdat Al Aghaydirat',
    },
    {
      city_id: 314,
      region_id: 7,
      name_ar: 'الخضراء الجديدة',
      name_en: 'Al Khadra Al Jadidah',
    },
    {
      city_id: 315,
      region_id: 7,
      name_ar: 'سمور',
      name_en: 'Samur',
    },
    {
      city_id: 316,
      region_id: 7,
      name_ar: 'الرويضات',
      name_en: 'Al Ruwaydat',
    },
    {
      city_id: 317,
      region_id: 7,
      name_ar: 'المهدرة',
      name_en: 'Al Mahadrah',
    },
    {
      city_id: 318,
      region_id: 7,
      name_ar: 'بقيلة',
      name_en: 'Buqaylah',
    },
    {
      city_id: 319,
      region_id: 7,
      name_ar: 'النعيلة',
      name_en: "An Nu'aylah",
    },
    {
      city_id: 320,
      region_id: 7,
      name_ar: 'الزغلية',
      name_en: 'Az Zaghliyah',
    },
    {
      city_id: 321,
      region_id: 7,
      name_ar: 'توله',
      name_en: 'Tuwalah',
    },
    {
      city_id: 322,
      region_id: 7,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 323,
      region_id: 7,
      name_ar: 'املج',
      name_en: 'Umluj',
    },
    {
      city_id: 324,
      region_id: 7,
      name_ar: 'فم شثاث',
      name_en: 'Famm Shithath',
    },
    {
      city_id: 325,
      region_id: 7,
      name_ar: 'الحرة',
      name_en: 'Al Harrah',
    },
    {
      city_id: 326,
      region_id: 7,
      name_ar: 'العمبجة',
      name_en: "Al 'Ambijah",
    },
    {
      city_id: 327,
      region_id: 7,
      name_ar: 'الشدخ',
      name_en: 'Ash Shidakh',
    },
    {
      city_id: 328,
      region_id: 3,
      name_ar: 'المرامية',
      name_en: 'Al Maramiyah',
    },
    {
      city_id: 329,
      region_id: 1,
      name_ar: 'مزارع البدائع',
      name_en: "Mazari' Al Badai'",
    },
    {
      city_id: 330,
      region_id: 1,
      name_ar: 'الخاتلة',
      name_en: 'Al Khatilah',
    },
    {
      city_id: 331,
      region_id: 1,
      name_ar: 'البلاد الوسطى',
      name_en: 'Al Bilad Al Wusta',
    },
    {
      city_id: 332,
      region_id: 1,
      name_ar: 'العليا',
      name_en: "Al 'Ulayya",
    },
    {
      city_id: 333,
      region_id: 1,
      name_ar: 'الحسيان',
      name_en: 'Al Hisyan',
    },
    {
      city_id: 334,
      region_id: 1,
      name_ar: 'الضلعي',
      name_en: "Ad Dul 'Ayi",
    },
    {
      city_id: 335,
      region_id: 1,
      name_ar: 'تنيبيكة',
      name_en: 'Tunaibikah',
    },
    {
      city_id: 336,
      region_id: 1,
      name_ar: 'عبلة',
      name_en: "'Ablah",
    },
    {
      city_id: 337,
      region_id: 1,
      name_ar: 'عبلية',
      name_en: "'Abliyah",
    },
    {
      city_id: 338,
      region_id: 1,
      name_ar: 'هجرة السلات',
      name_en: 'Hijrat As Silat',
    },
    {
      city_id: 339,
      region_id: 1,
      name_ar: 'ام طلحة',
      name_en: 'Umm Talhah',
    },
    {
      city_id: 340,
      region_id: 1,
      name_ar: 'معرج قليشة',
      name_en: "Mua'rij Qulaishah",
    },
    {
      city_id: 341,
      region_id: 1,
      name_ar: 'داغان',
      name_en: 'Daghan',
    },
    {
      city_id: 342,
      region_id: 1,
      name_ar: 'الجرفية',
      name_en: 'Al Jurfiyah',
    },
    {
      city_id: 343,
      region_id: 1,
      name_ar: 'حشاشة',
      name_en: 'Hashashah',
    },
    {
      city_id: 344,
      region_id: 1,
      name_ar: 'المردمة',
      name_en: 'Al Mardamah',
    },
    {
      city_id: 345,
      region_id: 1,
      name_ar: 'لهدة الصياهد',
      name_en: 'Lahdat As Sayahid',
    },
    {
      city_id: 346,
      region_id: 1,
      name_ar: 'البرزاء',
      name_en: 'Al Barza',
    },
    {
      city_id: 347,
      region_id: 1,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 348,
      region_id: 1,
      name_ar: 'الخالدية',
      name_en: 'Al Khalidiyah',
    },
    {
      city_id: 349,
      region_id: 1,
      name_ar: 'الحمادة',
      name_en: 'Al Hamadah',
    },
    {
      city_id: 350,
      region_id: 1,
      name_ar: 'الداهمية',
      name_en: 'Al Dahimaiyyah',
    },
    {
      city_id: 351,
      region_id: 3,
      name_ar: 'الحمنة',
      name_en: 'Al Hamnah',
    },
    {
      city_id: 352,
      region_id: 3,
      name_ar: 'أم الغيران',
      name_en: 'Umm Al Ghiran',
    },
    {
      city_id: 353,
      region_id: 3,
      name_ar: 'المندسة',
      name_en: 'Al Mundassah',
    },
    {
      city_id: 354,
      region_id: 3,
      name_ar: 'السليمية',
      name_en: 'As Sulaymiyah',
    },
    {
      city_id: 355,
      region_id: 3,
      name_ar: 'البراقية',
      name_en: 'Al Barraqiyah',
    },
    {
      city_id: 356,
      region_id: 3,
      name_ar: 'السراقي',
      name_en: 'As Siraqi',
    },
    {
      city_id: 357,
      region_id: 3,
      name_ar: 'آبار دحمولة',
      name_en: 'Abar Dahmulah',
    },
    {
      city_id: 358,
      region_id: 3,
      name_ar: 'القويعية',
      name_en: "Al Quway'iyah",
    },
    {
      city_id: 359,
      region_id: 3,
      name_ar: 'بدائع الهراسين',
      name_en: "Badai' Al Harasin",
    },
    {
      city_id: 360,
      region_id: 3,
      name_ar: 'مهد الذهب',
      name_en: 'Mahd Adh Dhahab',
    },
    {
      city_id: 361,
      region_id: 3,
      name_ar: 'صفينة',
      name_en: 'Sufaynah',
    },
    {
      city_id: 362,
      region_id: 3,
      name_ar: 'الرقابية',
      name_en: 'Ar Riqabiyah',
    },
    {
      city_id: 363,
      region_id: 3,
      name_ar: 'الصلحانية',
      name_en: 'As Sulhaniyah',
    },
    {
      city_id: 364,
      region_id: 3,
      name_ar: 'الغاشية',
      name_en: 'Al Ghashiyah',
    },
    {
      city_id: 365,
      region_id: 3,
      name_ar: 'الاصيحر',
      name_en: 'Al Asayhir',
    },
    {
      city_id: 366,
      region_id: 3,
      name_ar: 'السويرقية',
      name_en: 'As Suwayriqiyah',
    },
    {
      city_id: 367,
      region_id: 3,
      name_ar: 'ثرب',
      name_en: 'Tharb',
    },
    {
      city_id: 368,
      region_id: 3,
      name_ar: 'حاذة',
      name_en: 'Hadhah',
    },
    {
      city_id: 369,
      region_id: 3,
      name_ar: 'العمق',
      name_en: 'Al Umaq',
    },
    {
      city_id: 370,
      region_id: 3,
      name_ar: 'الصعبية',
      name_en: "As Sa'biyah",
    },
    {
      city_id: 371,
      region_id: 2,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 372,
      region_id: 2,
      name_ar: 'الجحفة',
      name_en: 'Al Juhfah',
    },
    {
      city_id: 373,
      region_id: 3,
      name_ar: 'راين',
      name_en: 'Rayin',
    },
    {
      city_id: 374,
      region_id: 2,
      name_ar: 'الزويراء',
      name_en: 'Az Zuwayra',
    },
    {
      city_id: 375,
      region_id: 2,
      name_ar: 'النويبع',
      name_en: "Al Nuwaybi'",
    },
    {
      city_id: 376,
      region_id: 2,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 377,
      region_id: 2,
      name_ar: 'رابغ',
      name_en: 'Rabigh',
    },
    {
      city_id: 378,
      region_id: 2,
      name_ar: 'مستورة',
      name_en: 'Masturah',
    },
    {
      city_id: 379,
      region_id: 2,
      name_ar: 'الابواء',
      name_en: "Al Abwa'",
    },
    {
      city_id: 380,
      region_id: 2,
      name_ar: 'شلوة',
      name_en: 'Shuluwah',
    },
    {
      city_id: 381,
      region_id: 2,
      name_ar: 'كلية',
      name_en: 'Kilayyah',
    },
    {
      city_id: 382,
      region_id: 2,
      name_ar: 'حجر',
      name_en: 'Hajur',
    },
    {
      city_id: 383,
      region_id: 2,
      name_ar: 'صعبر',
      name_en: "Sa'bar",
    },
    {
      city_id: 384,
      region_id: 2,
      name_ar: 'التنضبية',
      name_en: 'At Tandabiyah',
    },
    {
      city_id: 385,
      region_id: 2,
      name_ar: 'قويزة',
      name_en: 'Quwayzah',
    },
    {
      city_id: 386,
      region_id: 2,
      name_ar: 'النزة',
      name_en: 'An Nazzah',
    },
    {
      city_id: 387,
      region_id: 2,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 388,
      region_id: 2,
      name_ar: 'الصدر',
      name_en: 'As Sadr',
    },
    {
      city_id: 389,
      region_id: 2,
      name_ar: 'المغاربة',
      name_en: 'Al Magharibah',
    },
    {
      city_id: 390,
      region_id: 2,
      name_ar: 'الكويسية',
      name_en: 'Al Kuwaysiyah',
    },
    {
      city_id: 391,
      region_id: 2,
      name_ar: 'ابو حليفاء',
      name_en: 'Abu Hulayfa',
    },
    {
      city_id: 392,
      region_id: 2,
      name_ar: 'محجوبة',
      name_en: 'Mahjubah',
    },
    {
      city_id: 393,
      region_id: 1,
      name_ar: 'بئر الفيضة',
      name_en: 'Bir Al Faydah',
    },
    {
      city_id: 394,
      region_id: 1,
      name_ar: 'الهميج',
      name_en: 'Al Humayj',
    },
    {
      city_id: 395,
      region_id: 1,
      name_ar: 'الذيبية',
      name_en: 'Adh Dhibiyah',
    },
    {
      city_id: 396,
      region_id: 1,
      name_ar: 'خضراء',
      name_en: 'Khadra',
    },
    {
      city_id: 397,
      region_id: 1,
      name_ar: 'البدايع',
      name_en: "Al Badayi'",
    },
    {
      city_id: 398,
      region_id: 1,
      name_ar: 'بطاحة',
      name_en: 'Battahah',
    },
    {
      city_id: 399,
      region_id: 1,
      name_ar: 'الصقرة',
      name_en: 'As Saqrah',
    },
    {
      city_id: 400,
      region_id: 1,
      name_ar: 'وبرة',
      name_en: 'Wabrah',
    },
    {
      city_id: 401,
      region_id: 1,
      name_ar: 'أبو عشرة',
      name_en: "Abu 'Isharah",
    },
    {
      city_id: 402,
      region_id: 1,
      name_ar: 'المعلق',
      name_en: "Al Mu'allaq",
    },
    {
      city_id: 403,
      region_id: 1,
      name_ar: 'الرميثي',
      name_en: 'Ar Rumaythi',
    },
    {
      city_id: 404,
      region_id: 1,
      name_ar: 'الهرانية',
      name_en: 'Al Hiraniyah',
    },
    {
      city_id: 405,
      region_id: 4,
      name_ar: 'الرضم',
      name_en: 'Ar Radum',
    },
    {
      city_id: 406,
      region_id: 1,
      name_ar: 'الثعل',
      name_en: "Ath Tha'al",
    },
    {
      city_id: 407,
      region_id: 1,
      name_ar: 'الانسيات',
      name_en: 'Al Unsiyat',
    },
    {
      city_id: 408,
      region_id: 1,
      name_ar: 'فرعة الرميثي',
      name_en: "Far'at Ar Rumaythi",
    },
    {
      city_id: 409,
      region_id: 1,
      name_ar: 'البطين',
      name_en: 'Al Butayn',
    },
    {
      city_id: 410,
      region_id: 1,
      name_ar: 'مشرفة',
      name_en: 'Mushrifah',
    },
    {
      city_id: 411,
      region_id: 1,
      name_ar: 'بديدة',
      name_en: 'Bidaydah',
    },
    {
      city_id: 412,
      region_id: 1,
      name_ar: 'المجهلية',
      name_en: 'Al Mijhiliyah',
    },
    {
      city_id: 413,
      region_id: 1,
      name_ar: 'البحرة',
      name_en: 'Al Baharah',
    },
    {
      city_id: 414,
      region_id: 1,
      name_ar: 'بديعة',
      name_en: "Buday'ah",
    },
    {
      city_id: 415,
      region_id: 1,
      name_ar: 'الجثوم',
      name_en: 'Al Juthum',
    },
    {
      city_id: 416,
      region_id: 1,
      name_ar: 'الشويطن',
      name_en: 'Ash Shuwaytin',
    },
    {
      city_id: 417,
      region_id: 1,
      name_ar: 'المحامة',
      name_en: 'Al Mahamah',
    },
    {
      city_id: 418,
      region_id: 1,
      name_ar: 'عفيف',
      name_en: 'Afif',
    },
    {
      city_id: 419,
      region_id: 1,
      name_ar: 'ابرقية',
      name_en: 'Abraqiyah',
    },
    {
      city_id: 420,
      region_id: 1,
      name_ar: 'الجمانية',
      name_en: 'Al Jammaniyah',
    },
    {
      city_id: 421,
      region_id: 1,
      name_ar: 'الاشعرية',
      name_en: 'Al Ashariyah',
    },
    {
      city_id: 422,
      region_id: 1,
      name_ar: 'الخضارة',
      name_en: 'Al Khadarah',
    },
    {
      city_id: 423,
      region_id: 1,
      name_ar: 'الصالحية',
      name_en: 'As Salhiyah',
    },
    {
      city_id: 424,
      region_id: 1,
      name_ar: 'بدائع العضيان',
      name_en: "Badai' Al Idyan",
    },
    {
      city_id: 425,
      region_id: 1,
      name_ar: 'أم أرطى',
      name_en: 'Umm Arta',
    },
    {
      city_id: 426,
      region_id: 1,
      name_ar: 'المكلاة',
      name_en: 'Al Maklah',
    },
    {
      city_id: 427,
      region_id: 1,
      name_ar: 'عشيران',
      name_en: "'Ushayran",
    },
    {
      city_id: 428,
      region_id: 1,
      name_ar: 'بعيثران',
      name_en: "Bu'aythiran",
    },
    {
      city_id: 429,
      region_id: 1,
      name_ar: 'أم قصور',
      name_en: 'Umm Qusur',
    },
    {
      city_id: 430,
      region_id: 1,
      name_ar: 'عبدة',
      name_en: "'Abdah",
    },
    {
      city_id: 431,
      region_id: 1,
      name_ar: 'خريصة',
      name_en: 'Khuraysah',
    },
    {
      city_id: 432,
      region_id: 1,
      name_ar: 'عجابة',
      name_en: "'Ajabah",
    },
    {
      city_id: 433,
      region_id: 1,
      name_ar: 'المديفع',
      name_en: "Al Mudayfi'",
    },
    {
      city_id: 434,
      region_id: 1,
      name_ar: 'القدراوية',
      name_en: 'Al Qidrawiyah',
    },
    {
      city_id: 435,
      region_id: 8,
      name_ar: 'أوبيرة',
      name_en: 'Ubayrah',
    },
    {
      city_id: 436,
      region_id: 1,
      name_ar: 'أم أثلة',
      name_en: 'Umm Athlah',
    },
    {
      city_id: 437,
      region_id: 1,
      name_ar: 'الأخضر',
      name_en: 'Al Akhdar',
    },
    {
      city_id: 438,
      region_id: 1,
      name_ar: 'مطيعة',
      name_en: "Muti'ah",
    },
    {
      city_id: 439,
      region_id: 1,
      name_ar: 'المدارة',
      name_en: 'Al Madarah',
    },
    {
      city_id: 440,
      region_id: 1,
      name_ar: 'عسيلة',
      name_en: "'Usaylah",
    },
    {
      city_id: 441,
      region_id: 1,
      name_ar: 'الحلوة',
      name_en: 'Al Hilwah',
    },
    {
      city_id: 442,
      region_id: 1,
      name_ar: 'الدبيجة',
      name_en: 'Ad Dubayjah',
    },
    {
      city_id: 443,
      region_id: 1,
      name_ar: 'ثادق',
      name_en: 'Thadiq',
    },
    {
      city_id: 444,
      region_id: 1,
      name_ar: 'الروبضة / رغبة',
      name_en: 'Ar Rawbidah / Raghabah',
    },
    {
      city_id: 445,
      region_id: 1,
      name_ar: 'رويضة السهول',
      name_en: 'Ruwaydat As Suhul',
    },
    {
      city_id: 446,
      region_id: 1,
      name_ar: 'مشاش السهول',
      name_en: 'Mishash As Suhul',
    },
    {
      city_id: 447,
      region_id: 1,
      name_ar: 'الحسي',
      name_en: 'Al Husayy',
    },
    {
      city_id: 448,
      region_id: 1,
      name_ar: 'الصفرات',
      name_en: 'As Sufarat',
    },
    {
      city_id: 449,
      region_id: 1,
      name_ar: 'البير',
      name_en: 'Al Bir',
    },
    {
      city_id: 450,
      region_id: 1,
      name_ar: 'رويغب',
      name_en: 'Ruwayghib',
    },
    {
      city_id: 451,
      region_id: 3,
      name_ar: 'النجف',
      name_en: 'An Najf',
    },
    {
      city_id: 452,
      region_id: 3,
      name_ar: 'السطيح',
      name_en: 'As Sutayh',
    },
    {
      city_id: 453,
      region_id: 3,
      name_ar: 'الخيمة',
      name_en: 'Al Khaymah',
    },
    {
      city_id: 454,
      region_id: 5,
      name_ar: 'سيهات',
      name_en: 'Sayhat',
    },
    {
      city_id: 455,
      region_id: 3,
      name_ar: 'تفيدة',
      name_en: 'Tafidah',
    },
    {
      city_id: 456,
      region_id: 5,
      name_ar: 'تاروت',
      name_en: 'Tarut',
    },
    {
      city_id: 457,
      region_id: 3,
      name_ar: 'ندياء',
      name_en: 'Nadya',
    },
    {
      city_id: 458,
      region_id: 3,
      name_ar: 'مشرفة',
      name_en: 'Mushrifah',
    },
    {
      city_id: 459,
      region_id: 3,
      name_ar: 'دوينه',
      name_en: 'Duwayyinah',
    },
    {
      city_id: 460,
      region_id: 3,
      name_ar: 'عدن',
      name_en: "'Adan",
    },
    {
      city_id: 461,
      region_id: 3,
      name_ar: 'الأحمر',
      name_en: 'Al Ahmar',
    },
    {
      city_id: 462,
      region_id: 3,
      name_ar: 'المثلث',
      name_en: 'Al Muthallath',
    },
    {
      city_id: 463,
      region_id: 3,
      name_ar: 'الفرع',
      name_en: "Al Far'",
    },
    {
      city_id: 464,
      region_id: 3,
      name_ar: 'البقاع',
      name_en: "Al Biqa'",
    },
    {
      city_id: 465,
      region_id: 3,
      name_ar: 'النجيل',
      name_en: 'An Najil',
    },
    {
      city_id: 466,
      region_id: 3,
      name_ar: 'البثنة',
      name_en: 'Al Bathnah',
    },
    {
      city_id: 467,
      region_id: 3,
      name_ar: 'خيف حسين',
      name_en: 'Khayf Husayn',
    },
    {
      city_id: 468,
      region_id: 3,
      name_ar: 'البقارية',
      name_en: 'Al Baqqariyah',
    },
    {
      city_id: 469,
      region_id: 3,
      name_ar: 'الفقعلي',
      name_en: "Al Fuq'ali",
    },
    {
      city_id: 470,
      region_id: 3,
      name_ar: 'المشريف',
      name_en: 'Al Mushayrif',
    },
    {
      city_id: 471,
      region_id: 3,
      name_ar: 'أم المسن',
      name_en: 'Umm Al Misin',
    },
    {
      city_id: 472,
      region_id: 3,
      name_ar: 'البديع',
      name_en: "Al Badi'",
    },
    {
      city_id: 473,
      region_id: 3,
      name_ar: 'الدارة',
      name_en: 'Ad Darah',
    },
    {
      city_id: 474,
      region_id: 3,
      name_ar: 'القري',
      name_en: 'Al Quray',
    },
    {
      city_id: 475,
      region_id: 3,
      name_ar: 'السليم',
      name_en: 'As Sulaym',
    },
    {
      city_id: 476,
      region_id: 3,
      name_ar: 'اللثامة',
      name_en: 'Al Luthamah',
    },
    {
      city_id: 477,
      region_id: 3,
      name_ar: 'المقنع',
      name_en: "Al Muqanna'",
    },
    {
      city_id: 478,
      region_id: 3,
      name_ar: 'ضفيان',
      name_en: 'Dufyan',
    },
    {
      city_id: 479,
      region_id: 3,
      name_ar: 'عضاد',
      name_en: "'Adad",
    },
    {
      city_id: 480,
      region_id: 3,
      name_ar: 'العدوة',
      name_en: "Al 'Idwah",
    },
    {
      city_id: 481,
      region_id: 3,
      name_ar: 'النباة',
      name_en: 'An Nabah',
    },
    {
      city_id: 482,
      region_id: 3,
      name_ar: 'البريدي',
      name_en: 'Al Baridi',
    },
    {
      city_id: 483,
      region_id: 3,
      name_ar: 'ينبع',
      name_en: 'Yanbu',
    },
    {
      city_id: 484,
      region_id: 3,
      name_ar: 'القراصة',
      name_en: 'Al Qarrasah',
    },
    {
      city_id: 485,
      region_id: 3,
      name_ar: 'العيص',
      name_en: "Al 'Is",
    },
    {
      city_id: 486,
      region_id: 3,
      name_ar: 'الجابرية',
      name_en: 'Al Jabiriyah',
    },
    {
      city_id: 487,
      region_id: 3,
      name_ar: 'ينبع النخل',
      name_en: 'Yanbu An Nakhil',
    },
    {
      city_id: 488,
      region_id: 3,
      name_ar: 'جراجر',
      name_en: 'Jarajir',
    },
    {
      city_id: 489,
      region_id: 3,
      name_ar: 'ترعة',
      name_en: "Tir'ah",
    },
    {
      city_id: 490,
      region_id: 3,
      name_ar: 'المربع',
      name_en: "Al Murabba'",
    },
    {
      city_id: 491,
      region_id: 3,
      name_ar: 'نبط',
      name_en: 'Nabt',
    },
    {
      city_id: 492,
      region_id: 3,
      name_ar: 'السليلة',
      name_en: 'As Salilah',
    },
    {
      city_id: 493,
      region_id: 1,
      name_ar: 'القرائن',
      name_en: 'Al Qarain',
    },
    {
      city_id: 494,
      region_id: 1,
      name_ar: 'الشكيرة',
      name_en: 'Ash Shukayyrah',
    },
    {
      city_id: 495,
      region_id: 1,
      name_ar: 'هجرة المغر',
      name_en: 'Hijrat Al Mughur',
    },
    {
      city_id: 496,
      region_id: 1,
      name_ar: 'مزارع المحتجبة',
      name_en: "Mazari' Al Muhtajibah",
    },
    {
      city_id: 497,
      region_id: 1,
      name_ar: 'الغرابة',
      name_en: 'Al Ghurabah',
    },
    {
      city_id: 498,
      region_id: 1,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 499,
      region_id: 1,
      name_ar: 'غسلة',
      name_en: 'Ghuslah',
    },
    {
      city_id: 500,
      region_id: 1,
      name_ar: 'شقراء',
      name_en: "Shaqra'",
    },
    {
      city_id: 501,
      region_id: 1,
      name_ar: 'الهفوف',
      name_en: 'Al Hafuf',
    },
    {
      city_id: 502,
      region_id: 1,
      name_ar: 'حلوان',
      name_en: 'Halwan',
    },
    {
      city_id: 503,
      region_id: 1,
      name_ar: 'حصرة',
      name_en: 'Hasarah',
    },
    {
      city_id: 504,
      region_id: 1,
      name_ar: 'الثمامية',
      name_en: 'Ath Thumamiyah',
    },
    {
      city_id: 505,
      region_id: 1,
      name_ar: 'لبيدة',
      name_en: 'Lubaydah',
    },
    {
      city_id: 506,
      region_id: 1,
      name_ar: 'العضيانية',
      name_en: "Al 'Idayyaniyah",
    },
    {
      city_id: 507,
      region_id: 1,
      name_ar: 'عجلة',
      name_en: "'Ajlah",
    },
    {
      city_id: 508,
      region_id: 1,
      name_ar: 'سعدة',
      name_en: "Sa'dah",
    },
    {
      city_id: 509,
      region_id: 1,
      name_ar: 'جفن ضب',
      name_en: 'Jafn Dabb',
    },
    {
      city_id: 510,
      region_id: 1,
      name_ar: 'دلقان',
      name_en: 'Dalqan',
    },
    {
      city_id: 511,
      region_id: 1,
      name_ar: 'أم السباع',
      name_en: "Umm As Siba'",
    },
    {
      city_id: 512,
      region_id: 1,
      name_ar: 'الجله الأعلى',
      name_en: "Al Jilh Al A'la",
    },
    {
      city_id: 513,
      region_id: 1,
      name_ar: 'الخفق الجنوبي',
      name_en: 'Al Khafaq Al Janubi',
    },
    {
      city_id: 514,
      region_id: 1,
      name_ar: 'الدحوة',
      name_en: 'Ad Dihwah',
    },
    {
      city_id: 515,
      region_id: 1,
      name_ar: 'الخفق القديم',
      name_en: 'Al Khafaq Al Qadim',
    },
    {
      city_id: 516,
      region_id: 1,
      name_ar: 'الشرمية',
      name_en: 'Ash Sharmiyah',
    },
    {
      city_id: 517,
      region_id: 1,
      name_ar: 'مراغان',
      name_en: 'Maraghan',
    },
    {
      city_id: 518,
      region_id: 1,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 519,
      region_id: 1,
      name_ar: 'الجروية',
      name_en: 'Al Jarwiyah',
    },
    {
      city_id: 520,
      region_id: 1,
      name_ar: 'العوشزية',
      name_en: 'Al Awshaziyah',
    },
    {
      city_id: 521,
      region_id: 1,
      name_ar: 'أبو رجوم',
      name_en: 'Abu Rijum',
    },
    {
      city_id: 522,
      region_id: 1,
      name_ar: 'القلتة',
      name_en: 'Al Qaltah',
    },
    {
      city_id: 523,
      region_id: 1,
      name_ar: 'لعلع',
      name_en: "Li'li'",
    },
    {
      city_id: 524,
      region_id: 1,
      name_ar: 'الغريري',
      name_en: 'Al Ghurayri',
    },
    {
      city_id: 525,
      region_id: 1,
      name_ar: 'الخنقة',
      name_en: 'Al Khunqah',
    },
    {
      city_id: 526,
      region_id: 1,
      name_ar: 'النسق القديم',
      name_en: 'An Nasaq Al Qadim',
    },
    {
      city_id: 527,
      region_id: 1,
      name_ar: 'وثيلان',
      name_en: 'Wuthaylan',
    },
    {
      city_id: 528,
      region_id: 1,
      name_ar: 'بيضاء نثيل',
      name_en: "Bayda' Nathil",
    },
    {
      city_id: 529,
      region_id: 1,
      name_ar: 'أم الدباء',
      name_en: 'Umm Ad Diba',
    },
    {
      city_id: 530,
      region_id: 1,
      name_ar: 'الأمار',
      name_en: 'Al Amar',
    },
    {
      city_id: 531,
      region_id: 1,
      name_ar: 'عسيلان',
      name_en: "'Usaylan",
    },
    {
      city_id: 532,
      region_id: 1,
      name_ar: 'السدرية',
      name_en: 'As Sidriyah',
    },
    {
      city_id: 533,
      region_id: 1,
      name_ar: 'أم الشبرم',
      name_en: 'Umm Ash Shubrum',
    },
    {
      city_id: 534,
      region_id: 1,
      name_ar: 'الحجاجي',
      name_en: 'Al Hijjaji',
    },
    {
      city_id: 535,
      region_id: 1,
      name_ar: 'الغبياء',
      name_en: 'Al Ghubayya',
    },
    {
      city_id: 536,
      region_id: 1,
      name_ar: 'فيضة بن سعيدان',
      name_en: 'Faydah Bin Saeedan',
    },
    {
      city_id: 537,
      region_id: 1,
      name_ar: 'أبو حميض',
      name_en: 'Abu Humayd',
    },
    {
      city_id: 538,
      region_id: 1,
      name_ar: 'النبيبيع',
      name_en: "An Nubaybi'",
    },
    {
      city_id: 539,
      region_id: 1,
      name_ar: 'النباع',
      name_en: "An Naba'",
    },
    {
      city_id: 540,
      region_id: 1,
      name_ar: 'الملقى',
      name_en: 'Al Malqa',
    },
    {
      city_id: 541,
      region_id: 1,
      name_ar: 'حجيلاء',
      name_en: 'Hujayla',
    },
    {
      city_id: 542,
      region_id: 1,
      name_ar: 'العبد',
      name_en: "Al 'Abd",
    },
    {
      city_id: 543,
      region_id: 1,
      name_ar: 'أم الجثجاث',
      name_en: 'Umm Al Jithjath',
    },
    {
      city_id: 544,
      region_id: 1,
      name_ar: 'أم جفر',
      name_en: 'Umm Jafr',
    },
    {
      city_id: 545,
      region_id: 1,
      name_ar: 'الخلفية',
      name_en: 'Al Khalfiyah',
    },
    {
      city_id: 546,
      region_id: 2,
      name_ar: 'الجدعان',
      name_en: "Al Jidh'an",
    },
    {
      city_id: 547,
      region_id: 2,
      name_ar: 'زنقاحة',
      name_en: 'Zinqahah',
    },
    {
      city_id: 548,
      region_id: 2,
      name_ar: 'العمشان',
      name_en: "Al 'Umshan",
    },
    {
      city_id: 549,
      region_id: 2,
      name_ar: 'الجذية',
      name_en: 'Al Judhayyah',
    },
    {
      city_id: 550,
      region_id: 2,
      name_ar: 'العقدة',
      name_en: "Al 'Uqdah",
    },
    {
      city_id: 551,
      region_id: 2,
      name_ar: 'القرا',
      name_en: 'Al Qara',
    },
    {
      city_id: 552,
      region_id: 2,
      name_ar: 'الفطيمة',
      name_en: 'Al Futaymah',
    },
    {
      city_id: 553,
      region_id: 2,
      name_ar: 'المحارزة',
      name_en: 'Al Maharizah',
    },
    {
      city_id: 554,
      region_id: 2,
      name_ar: 'الحمة',
      name_en: 'Al Himah',
    },
    {
      city_id: 555,
      region_id: 2,
      name_ar: 'صيادة',
      name_en: 'Sayyadah',
    },
    {
      city_id: 556,
      region_id: 2,
      name_ar: 'الدفينة',
      name_en: 'Al Dufaynah',
    },
    {
      city_id: 557,
      region_id: 2,
      name_ar: 'الوهط',
      name_en: 'Al Wahat',
    },
    {
      city_id: 558,
      region_id: 2,
      name_ar: 'الغنم',
      name_en: 'Al Ghunnam',
    },
    {
      city_id: 559,
      region_id: 2,
      name_ar: 'ابو غيل',
      name_en: 'Abu Ghayl',
    },
    {
      city_id: 560,
      region_id: 2,
      name_ar: 'ام البكار',
      name_en: 'Umm Al Bikar',
    },
    {
      city_id: 561,
      region_id: 2,
      name_ar: 'الفعور',
      name_en: "Al Fu'ur",
    },
    {
      city_id: 562,
      region_id: 2,
      name_ar: 'الصخيرة',
      name_en: 'As Sukhayrah',
    },
    {
      city_id: 563,
      region_id: 2,
      name_ar: 'السريح',
      name_en: 'As Sirayh',
    },
    {
      city_id: 564,
      region_id: 2,
      name_ar: 'الملعب',
      name_en: "Al Mal'ab",
    },
    {
      city_id: 565,
      region_id: 2,
      name_ar: 'الغمير',
      name_en: 'Al Ghimayr',
    },
    {
      city_id: 566,
      region_id: 2,
      name_ar: 'الوزير',
      name_en: 'Al Wazir',
    },
    {
      city_id: 567,
      region_id: 2,
      name_ar: 'ثمالة',
      name_en: 'Thumalah',
    },
    {
      city_id: 568,
      region_id: 2,
      name_ar: 'النصبة',
      name_en: 'An Nusbah',
    },
    {
      city_id: 569,
      region_id: 2,
      name_ar: 'عباسة',
      name_en: "'Abbasah",
    },
    {
      city_id: 570,
      region_id: 2,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 571,
      region_id: 2,
      name_ar: 'غرابة',
      name_en: 'Ghirabah',
    },
    {
      city_id: 572,
      region_id: 2,
      name_ar: 'الحلقة',
      name_en: 'Al Halqah',
    },
    {
      city_id: 573,
      region_id: 2,
      name_ar: 'الرخيلة',
      name_en: 'Ar Rikhaylah',
    },
    {
      city_id: 574,
      region_id: 1,
      name_ar: 'القصب',
      name_en: 'Al Qasab',
    },
    {
      city_id: 575,
      region_id: 1,
      name_ar: 'المشاش',
      name_en: 'Al Mishash',
    },
    {
      city_id: 576,
      region_id: 1,
      name_ar: 'الصوح',
      name_en: 'As Suh',
    },
    {
      city_id: 577,
      region_id: 1,
      name_ar: 'اشيقر',
      name_en: 'Ushayqir',
    },
    {
      city_id: 578,
      region_id: 1,
      name_ar: 'ام طليحة',
      name_en: 'Umm Tulayhah',
    },
    {
      city_id: 579,
      region_id: 1,
      name_ar: 'الداهنة',
      name_en: 'Ad Dahinah',
    },
    {
      city_id: 580,
      region_id: 1,
      name_ar: 'الحريق',
      name_en: 'Al Hariq',
    },
    {
      city_id: 581,
      region_id: 1,
      name_ar: 'الجريفة',
      name_en: 'Al Juraifah',
    },
    {
      city_id: 582,
      region_id: 1,
      name_ar: 'الوقف',
      name_en: 'Al Waqf',
    },
    {
      city_id: 583,
      region_id: 1,
      name_ar: 'العلوة',
      name_en: "Al 'Uluwah",
    },
    {
      city_id: 584,
      region_id: 1,
      name_ar: 'فرحة',
      name_en: 'Farhah',
    },
    {
      city_id: 585,
      region_id: 1,
      name_ar: 'عروى',
      name_en: "'Arawa",
    },
    {
      city_id: 586,
      region_id: 1,
      name_ar: 'فردة',
      name_en: 'Fardah',
    },
    {
      city_id: 587,
      region_id: 1,
      name_ar: 'مويسل',
      name_en: 'Muwaysil',
    },
    {
      city_id: 588,
      region_id: 1,
      name_ar: 'الشاة',
      name_en: 'Ash Shat',
    },
    {
      city_id: 589,
      region_id: 1,
      name_ar: 'السر',
      name_en: 'Al Sirr',
    },
    {
      city_id: 590,
      region_id: 1,
      name_ar: 'مشرف',
      name_en: 'Mushrif',
    },
    {
      city_id: 591,
      region_id: 1,
      name_ar: 'بدائع سويقة',
      name_en: "Badai' Suwayqah",
    },
    {
      city_id: 592,
      region_id: 1,
      name_ar: 'الصلبية',
      name_en: 'As Sulubiyah',
    },
    {
      city_id: 593,
      region_id: 1,
      name_ar: 'حدة (مشرفة)',
      name_en: 'Heddah(Mushrifah)',
    },
    {
      city_id: 594,
      region_id: 1,
      name_ar: 'مزارع الروضة',
      name_en: "Mazari' Ar Rawdah",
    },
    {
      city_id: 595,
      region_id: 4,
      name_ar: 'هرمولة',
      name_en: 'Hurmulah',
    },
    {
      city_id: 596,
      region_id: 4,
      name_ar: 'فيضة ابان الاحمر',
      name_en: 'Faydat Aban Alahmar',
    },
    {
      city_id: 597,
      region_id: 4,
      name_ar: 'الدارة',
      name_en: 'Ad Darah',
    },
    {
      city_id: 598,
      region_id: 1,
      name_ar: 'مطربة',
      name_en: 'Mutribah',
    },
    {
      city_id: 599,
      region_id: 1,
      name_ar: 'رغلة',
      name_en: 'Raghlah',
    },
    {
      city_id: 600,
      region_id: 1,
      name_ar: 'مشرفة',
      name_en: 'Mushrifah',
    },
    {
      city_id: 601,
      region_id: 1,
      name_ar: 'ضبة',
      name_en: 'Dabbah',
    },
    {
      city_id: 602,
      region_id: 1,
      name_ar: 'العطاوي',
      name_en: "Al 'Atawi",
    },
    {
      city_id: 603,
      region_id: 1,
      name_ar: 'الحفائر',
      name_en: 'Al Hafair',
    },
    {
      city_id: 604,
      region_id: 1,
      name_ar: 'مزارع طينان',
      name_en: "Mazari' Tinan",
    },
    {
      city_id: 605,
      region_id: 1,
      name_ar: 'منيفة',
      name_en: 'Munifah',
    },
    {
      city_id: 606,
      region_id: 1,
      name_ar: 'قويعان',
      name_en: "Quway'an",
    },
    {
      city_id: 607,
      region_id: 1,
      name_ar: 'الحنابج',
      name_en: 'Al Hanabij',
    },
    {
      city_id: 608,
      region_id: 1,
      name_ar: 'الشفلحية',
      name_en: 'Ash Shifallahiyah',
    },
    {
      city_id: 609,
      region_id: 1,
      name_ar: 'روضة وثيلان',
      name_en: 'Rawdat Wuthaylan',
    },
    {
      city_id: 610,
      region_id: 1,
      name_ar: 'ابو ركب',
      name_en: 'Abu Rukab',
    },
    {
      city_id: 611,
      region_id: 1,
      name_ar: 'الخالدية',
      name_en: 'Al Khalidiyah',
    },
    {
      city_id: 612,
      region_id: 1,
      name_ar: 'الحفنة',
      name_en: 'Al Hifnah',
    },
    {
      city_id: 613,
      region_id: 1,
      name_ar: 'أبو عرينة',
      name_en: 'Abu Uraynah',
    },
    {
      city_id: 614,
      region_id: 1,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 615,
      region_id: 1,
      name_ar: 'بحار الجديد',
      name_en: 'Bihar Al Jadid',
    },
    {
      city_id: 616,
      region_id: 1,
      name_ar: 'حميان',
      name_en: 'Hamayyan',
    },
    {
      city_id: 617,
      region_id: 6,
      name_ar: 'الجربوعية',
      name_en: 'Al Jarboeyyah',
    },
    {
      city_id: 618,
      region_id: 1,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 619,
      region_id: 1,
      name_ar: 'نميلين',
      name_en: 'Numaileen',
    },
    {
      city_id: 620,
      region_id: 1,
      name_ar: 'الدهاسية',
      name_en: 'Ad Dahasiyah',
    },
    {
      city_id: 621,
      region_id: 1,
      name_ar: 'المصلوم',
      name_en: 'Al Maslum',
    },
    {
      city_id: 622,
      region_id: 1,
      name_ar: 'سعدة',
      name_en: "Sa'dah",
    },
    {
      city_id: 623,
      region_id: 1,
      name_ar: 'أم رضمة',
      name_en: 'Umm Radmah',
    },
    {
      city_id: 624,
      region_id: 1,
      name_ar: 'السنارية',
      name_en: 'As Sinnariyah',
    },
    {
      city_id: 625,
      region_id: 1,
      name_ar: 'الديرية',
      name_en: 'Ad Dirirah',
    },
    {
      city_id: 626,
      region_id: 1,
      name_ar: 'المغزل',
      name_en: 'Al Mighzal',
    },
    {
      city_id: 627,
      region_id: 1,
      name_ar: 'جوباح',
      name_en: 'Jubah',
    },
    {
      city_id: 628,
      region_id: 1,
      name_ar: 'القرنة',
      name_en: 'Al Qarnah',
    },
    {
      city_id: 629,
      region_id: 1,
      name_ar: 'وثيلان',
      name_en: 'Wuthaylan',
    },
    {
      city_id: 630,
      region_id: 1,
      name_ar: 'عين الصوينع',
      name_en: "'Ayn As Suwayni'",
    },
    {
      city_id: 631,
      region_id: 1,
      name_ar: 'الأرطاوي',
      name_en: 'Al Artawi',
    },
    {
      city_id: 632,
      region_id: 1,
      name_ar: 'الثندوة',
      name_en: 'Ath Thiduwah',
    },
    {
      city_id: 633,
      region_id: 1,
      name_ar: 'أبو جلال',
      name_en: 'Abu Jilal',
    },
    {
      city_id: 634,
      region_id: 1,
      name_ar: 'جنوب البرود',
      name_en: 'Janub Al Burud',
    },
    {
      city_id: 635,
      region_id: 1,
      name_ar: 'غرب البرود',
      name_en: 'Gharb Al Burud',
    },
    {
      city_id: 636,
      region_id: 1,
      name_ar: 'الريشية',
      name_en: 'Ar Rishiyah',
    },
    {
      city_id: 637,
      region_id: 1,
      name_ar: 'عبلة الرفايع',
      name_en: "'Ablah Al Rafayia",
    },
    {
      city_id: 638,
      region_id: 1,
      name_ar: 'مزرعة البيضة',
      name_en: "Mazra'at Al Bidah",
    },
    {
      city_id: 639,
      region_id: 1,
      name_ar: 'السليسية',
      name_en: 'As Sulaysiyah',
    },
    {
      city_id: 640,
      region_id: 1,
      name_ar: 'مزرعة سليسان',
      name_en: "Mazra'at Sulaysan",
    },
    {
      city_id: 641,
      region_id: 1,
      name_ar: 'العويجاء',
      name_en: "Al 'Uwayja",
    },
    {
      city_id: 642,
      region_id: 1,
      name_ar: 'العدوة',
      name_en: "Al 'Idwah",
    },
    {
      city_id: 643,
      region_id: 1,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 644,
      region_id: 1,
      name_ar: 'بدائع الهمجة',
      name_en: "Badai' Al Hamjah",
    },
    {
      city_id: 645,
      region_id: 1,
      name_ar: 'فيضة القوازين',
      name_en: 'Faydat Al Quwazin',
    },
    {
      city_id: 646,
      region_id: 1,
      name_ar: 'مزارع مصيقرة',
      name_en: "Mazari' Musayqirat",
    },
    {
      city_id: 647,
      region_id: 1,
      name_ar: 'الفيحاء',
      name_en: 'Al Fayha',
    },
    {
      city_id: 648,
      region_id: 1,
      name_ar: 'الدسمة',
      name_en: 'Ad Dasmah',
    },
    {
      city_id: 649,
      region_id: 1,
      name_ar: 'صفاقة',
      name_en: 'Saffaqah',
    },
    {
      city_id: 650,
      region_id: 1,
      name_ar: 'قبيعة',
      name_en: "Qubay'ah",
    },
    {
      city_id: 651,
      region_id: 1,
      name_ar: 'الشبرمية',
      name_en: 'Ash Shubrumiyah',
    },
    {
      city_id: 652,
      region_id: 1,
      name_ar: 'كويكبة',
      name_en: 'Kuwaykibah',
    },
    {
      city_id: 653,
      region_id: 8,
      name_ar: 'الرعيلة',
      name_en: 'Al Raeelah',
    },
    {
      city_id: 654,
      region_id: 1,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 655,
      region_id: 1,
      name_ar: 'الرقيبة',
      name_en: 'Ar Raqibah',
    },
    {
      city_id: 656,
      region_id: 1,
      name_ar: 'بدع الصعكي',
      name_en: "Bid' As Si'aki",
    },
    {
      city_id: 657,
      region_id: 1,
      name_ar: 'سلطانة',
      name_en: 'Sultanah',
    },
    {
      city_id: 658,
      region_id: 1,
      name_ar: 'عبلة',
      name_en: "'Ablah",
    },
    {
      city_id: 659,
      region_id: 1,
      name_ar: 'الملينية',
      name_en: 'Al Milayyiniyah',
    },
    {
      city_id: 660,
      region_id: 1,
      name_ar: 'البديعة',
      name_en: "Al Badi'ah",
    },
    {
      city_id: 661,
      region_id: 1,
      name_ar: 'روضة جهام',
      name_en: 'Rawdat Jaham',
    },
    {
      city_id: 662,
      region_id: 1,
      name_ar: 'مزارع الجديدة',
      name_en: "Mazari' Al Jadidah",
    },
    {
      city_id: 663,
      region_id: 1,
      name_ar: 'منيفة المغايرة',
      name_en: 'Munifah Al Maghayirah',
    },
    {
      city_id: 664,
      region_id: 1,
      name_ar: 'الدرعية',
      name_en: "Ad Dir'iyah",
    },
    {
      city_id: 665,
      region_id: 1,
      name_ar: 'الرمحية',
      name_en: 'Ar Rumayhah',
    },
    {
      city_id: 666,
      region_id: 1,
      name_ar: 'حسو الحيد',
      name_en: 'Hasu Al Hayd',
    },
    {
      city_id: 667,
      region_id: 1,
      name_ar: 'حديجة',
      name_en: 'Hudayjah',
    },
    {
      city_id: 668,
      region_id: 1,
      name_ar: 'الدارة',
      name_en: 'Ad Darah',
    },
    {
      city_id: 669,
      region_id: 1,
      name_ar: 'الدوادمي',
      name_en: 'Ad Duwadimi',
    },
    {
      city_id: 670,
      region_id: 1,
      name_ar: 'الدمثي',
      name_en: 'Ad Damthi',
    },
    {
      city_id: 671,
      region_id: 1,
      name_ar: 'أوضاخ',
      name_en: 'Udakh',
    },
    {
      city_id: 672,
      region_id: 1,
      name_ar: 'كبشان',
      name_en: 'Kabshan',
    },
    {
      city_id: 673,
      region_id: 1,
      name_ar: 'حديجة',
      name_en: 'Hudayjah',
    },
    {
      city_id: 674,
      region_id: 1,
      name_ar: 'القرارة',
      name_en: 'Al Qararah',
    },
    {
      city_id: 675,
      region_id: 1,
      name_ar: 'العوشزية',
      name_en: 'Al Awshaziyah',
    },
    {
      city_id: 676,
      region_id: 1,
      name_ar: 'الجمش',
      name_en: 'Al Jimsh',
    },
    {
      city_id: 677,
      region_id: 1,
      name_ar: 'نجخ',
      name_en: 'Najkh',
    },
    {
      city_id: 678,
      region_id: 1,
      name_ar: 'جريسة',
      name_en: 'Juraysah',
    },
    {
      city_id: 679,
      region_id: 1,
      name_ar: 'ام العثاكل',
      name_en: "Umm Al 'Athakil",
    },
    {
      city_id: 680,
      region_id: 1,
      name_ar: 'عرجاء',
      name_en: "Arja'",
    },
    {
      city_id: 681,
      region_id: 1,
      name_ar: 'أم سليم',
      name_en: 'Umm Sulaym',
    },
    {
      city_id: 682,
      region_id: 1,
      name_ar: 'ساجر',
      name_en: 'Sajir',
    },
    {
      city_id: 683,
      region_id: 1,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 684,
      region_id: 1,
      name_ar: 'نفي',
      name_en: 'Nifi',
    },
    {
      city_id: 685,
      region_id: 1,
      name_ar: 'مصدة',
      name_en: 'Masadah',
    },
    {
      city_id: 686,
      region_id: 1,
      name_ar: 'الخفيفية',
      name_en: 'Al Khufayfiyah',
    },
    {
      city_id: 687,
      region_id: 1,
      name_ar: 'عسيلة الوسطي',
      name_en: "'Usaylah Al Wusta",
    },
    {
      city_id: 688,
      region_id: 1,
      name_ar: 'أرطاوي الرقاص',
      name_en: 'Artawi Ar Raqqas',
    },
    {
      city_id: 689,
      region_id: 1,
      name_ar: 'عشيران',
      name_en: "'Ushayran",
    },
    {
      city_id: 690,
      region_id: 1,
      name_ar: 'مزرعة الخفيسة',
      name_en: "Mazra'at Al Khufaysah",
    },
    {
      city_id: 691,
      region_id: 1,
      name_ar: 'عين القنور',
      name_en: "'Ayn Al Qannur",
    },
    {
      city_id: 692,
      region_id: 1,
      name_ar: 'التسرير',
      name_en: 'At Tasrir',
    },
    {
      city_id: 693,
      region_id: 1,
      name_ar: 'الاثلة',
      name_en: 'Al Athlah',
    },
    {
      city_id: 694,
      region_id: 1,
      name_ar: 'عريفيجان',
      name_en: "'Urayfijan",
    },
    {
      city_id: 695,
      region_id: 1,
      name_ar: 'السكران',
      name_en: 'As Sakran',
    },
    {
      city_id: 696,
      region_id: 1,
      name_ar: 'جفن',
      name_en: 'Jifin',
    },
    {
      city_id: 697,
      region_id: 1,
      name_ar: 'البرود',
      name_en: 'Al Burud',
    },
    {
      city_id: 698,
      region_id: 1,
      name_ar: 'جفنا',
      name_en: 'Jifana',
    },
    {
      city_id: 699,
      region_id: 1,
      name_ar: 'الصالحية (العرضان)',
      name_en: 'As Salhiyah(Al Ardan)',
    },
    {
      city_id: 700,
      region_id: 1,
      name_ar: 'العاذرية',
      name_en: "Al 'Adhiriyah",
    },
    {
      city_id: 701,
      region_id: 1,
      name_ar: 'البجادية',
      name_en: 'Al Bijadiyah',
    },
    {
      city_id: 702,
      region_id: 1,
      name_ar: 'الرفائع',
      name_en: "Ar Rafai'",
    },
    {
      city_id: 703,
      region_id: 1,
      name_ar: 'جهام',
      name_en: 'Jaham',
    },
    {
      city_id: 705,
      region_id: 1,
      name_ar: 'الحزيمية',
      name_en: 'Al Huzaymiyah',
    },
    {
      city_id: 706,
      region_id: 1,
      name_ar: 'خف',
      name_en: 'Khuff',
    },
    {
      city_id: 707,
      region_id: 1,
      name_ar: 'المحمدية',
      name_en: 'Al Muhammadiyah',
    },
    {
      city_id: 708,
      region_id: 1,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 709,
      region_id: 1,
      name_ar: 'ماسل',
      name_en: 'Masal',
    },
    {
      city_id: 710,
      region_id: 1,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 711,
      region_id: 1,
      name_ar: 'منيفة',
      name_en: 'Munifah',
    },
    {
      city_id: 712,
      region_id: 1,
      name_ar: 'رضوى',
      name_en: 'Radwa',
    },
    {
      city_id: 713,
      region_id: 1,
      name_ar: 'ارطاوي حليت',
      name_en: 'Artawi Hillit',
    },
    {
      city_id: 714,
      region_id: 1,
      name_ar: 'عواضة',
      name_en: "'Awwadah",
    },
    {
      city_id: 715,
      region_id: 1,
      name_ar: 'شرارة',
      name_en: 'Shararah',
    },
    {
      city_id: 716,
      region_id: 1,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 717,
      region_id: 1,
      name_ar: 'صقرة',
      name_en: 'Saqrah',
    },
    {
      city_id: 718,
      region_id: 1,
      name_ar: 'العقيشية',
      name_en: 'Al Uqayshiyah',
    },
    {
      city_id: 719,
      region_id: 1,
      name_ar: 'القرين',
      name_en: 'Al Qurayn',
    },
    {
      city_id: 720,
      region_id: 1,
      name_ar: 'عقلة الغويري',
      name_en: 'Uqlat Al Uwayri',
    },
    {
      city_id: 721,
      region_id: 1,
      name_ar: 'ام زموع',
      name_en: "Umm Zumu'",
    },
    {
      city_id: 722,
      region_id: 1,
      name_ar: 'عين البراحة',
      name_en: "'Ayn Al Barahah",
    },
    {
      city_id: 723,
      region_id: 1,
      name_ar: 'مغيراء',
      name_en: "Mughayra'",
    },
    {
      city_id: 724,
      region_id: 1,
      name_ar: 'الضحوي',
      name_en: 'Ad Dahawi',
    },
    {
      city_id: 725,
      region_id: 1,
      name_ar: 'عسيلة',
      name_en: "'Usaylah",
    },
    {
      city_id: 726,
      region_id: 1,
      name_ar: 'المغيب',
      name_en: 'Al Maghib',
    },
    {
      city_id: 727,
      region_id: 1,
      name_ar: 'فيضة المفص',
      name_en: 'Faydat Al Mafass',
    },
    {
      city_id: 728,
      region_id: 1,
      name_ar: 'عريدة',
      name_en: "'Urayyidah",
    },
    {
      city_id: 729,
      region_id: 1,
      name_ar: 'الشعراء',
      name_en: "Ash Sha'ra'",
    },
    {
      city_id: 730,
      region_id: 1,
      name_ar: 'العازمية',
      name_en: "Al 'Azimiyah",
    },
    {
      city_id: 731,
      region_id: 1,
      name_ar: 'المحوي',
      name_en: 'Al Mahawi',
    },
    {
      city_id: 732,
      region_id: 1,
      name_ar: 'المدرع',
      name_en: "Al Mudarri'",
    },
    {
      city_id: 733,
      region_id: 1,
      name_ar: 'سرورة',
      name_en: 'Sururah',
    },
    {
      city_id: 734,
      region_id: 1,
      name_ar: 'عصماء',
      name_en: "Asma'",
    },
    {
      city_id: 735,
      region_id: 1,
      name_ar: 'ام طليحة',
      name_en: 'Umm Tulayhah',
    },
    {
      city_id: 736,
      region_id: 1,
      name_ar: 'مشرفة نفي',
      name_en: 'Mushrifah Nifi',
    },
    {
      city_id: 737,
      region_id: 1,
      name_ar: 'مساوي',
      name_en: 'Musawi',
    },
    {
      city_id: 738,
      region_id: 1,
      name_ar: 'النقعة',
      name_en: "An Niq'ah",
    },
    {
      city_id: 739,
      region_id: 1,
      name_ar: 'افقرا',
      name_en: 'Afqirah',
    },
    {
      city_id: 740,
      region_id: 1,
      name_ar: 'الرفاع',
      name_en: "Ar Rufa'",
    },
    {
      city_id: 741,
      region_id: 1,
      name_ar: 'الفقارة',
      name_en: 'Al Fuqarah',
    },
    {
      city_id: 742,
      region_id: 1,
      name_ar: 'الودي',
      name_en: 'Al Wuday',
    },
    {
      city_id: 743,
      region_id: 1,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 744,
      region_id: 1,
      name_ar: 'الخبة',
      name_en: 'Al Khubbah',
    },
    {
      city_id: 745,
      region_id: 1,
      name_ar: 'النبوان',
      name_en: 'An Nabuwan',
    },
    {
      city_id: 746,
      region_id: 1,
      name_ar: 'الرديفة',
      name_en: 'Ar Radifah',
    },
    {
      city_id: 747,
      region_id: 1,
      name_ar: 'الرشاوية',
      name_en: 'Ar Rishawiyah',
    },
    {
      city_id: 748,
      region_id: 1,
      name_ar: 'مطربة',
      name_en: 'Mutribah',
    },
    {
      city_id: 749,
      region_id: 1,
      name_ar: 'المستجدة',
      name_en: 'Al Mustajiddah',
    },
    {
      city_id: 750,
      region_id: 1,
      name_ar: 'العامرية',
      name_en: 'Al Amiriyah',
    },
    {
      city_id: 751,
      region_id: 1,
      name_ar: 'الحازمية (الرفيعة)',
      name_en: "Al Hazimiyyah(Ar Rafi'ah)",
    },
    {
      city_id: 752,
      region_id: 1,
      name_ar: 'الظلماوي',
      name_en: 'Adh Dhalmawi',
    },
    {
      city_id: 753,
      region_id: 1,
      name_ar: 'شبيرمة',
      name_en: 'Shubayrimah',
    },
    {
      city_id: 754,
      region_id: 1,
      name_ar: 'عصام',
      name_en: 'Isam',
    },
    {
      city_id: 755,
      region_id: 1,
      name_ar: 'عبادة',
      name_en: 'Abbadah',
    },
    {
      city_id: 757,
      region_id: 1,
      name_ar: 'العبل',
      name_en: "Al 'Abal",
    },
    {
      city_id: 758,
      region_id: 1,
      name_ar: 'ارطاوي الحماميد',
      name_en: 'Artawi Al Hamamid',
    },
    {
      city_id: 759,
      region_id: 1,
      name_ar: 'بدائع ابن نجم',
      name_en: "Badai' Ibn Najim",
    },
    {
      city_id: 760,
      region_id: 3,
      name_ar: 'المرية',
      name_en: 'Al Marriyah',
    },
    {
      city_id: 761,
      region_id: 3,
      name_ar: 'الشقران',
      name_en: 'Ash Shuqran',
    },
    {
      city_id: 762,
      region_id: 3,
      name_ar: 'الكريزية',
      name_en: 'Al Kurayziyah',
    },
    {
      city_id: 763,
      region_id: 3,
      name_ar: 'ذارة',
      name_en: 'Dharah',
    },
    {
      city_id: 764,
      region_id: 3,
      name_ar: 'قصيباء',
      name_en: 'Qusayba',
    },
    {
      city_id: 765,
      region_id: 3,
      name_ar: 'رحبة علوش',
      name_en: "Rahbat 'Allush",
    },
    {
      city_id: 766,
      region_id: 3,
      name_ar: 'المقرن',
      name_en: 'Al Muqrin',
    },
    {
      city_id: 767,
      region_id: 3,
      name_ar: 'الرفائع',
      name_en: "Ar Rafai'",
    },
    {
      city_id: 768,
      region_id: 3,
      name_ar: 'صبحا',
      name_en: 'Sabha',
    },
    {
      city_id: 769,
      region_id: 3,
      name_ar: 'حزرة',
      name_en: 'Hazrah',
    },
    {
      city_id: 770,
      region_id: 3,
      name_ar: 'غراب',
      name_en: 'Ghurab',
    },
    {
      city_id: 771,
      region_id: 3,
      name_ar: 'الحجرية',
      name_en: 'Al Hijriyah',
    },
    {
      city_id: 772,
      region_id: 3,
      name_ar: 'أبو مغير',
      name_en: 'Abu Mughayr',
    },
    {
      city_id: 773,
      region_id: 3,
      name_ar: 'العد',
      name_en: "Al 'Idd",
    },
    {
      city_id: 774,
      region_id: 3,
      name_ar: 'الربثة',
      name_en: 'Ar Rabtah',
    },
    {
      city_id: 775,
      region_id: 3,
      name_ar: 'البركة',
      name_en: 'Al Birkah',
    },
    {
      city_id: 776,
      region_id: 3,
      name_ar: 'النفازي',
      name_en: 'An Nafazi',
    },
    {
      city_id: 777,
      region_id: 3,
      name_ar: 'الحناكية',
      name_en: 'Al Hinakiyah',
    },
    {
      city_id: 778,
      region_id: 3,
      name_ar: 'المحفر',
      name_en: 'Al Mahaffar',
    },
    {
      city_id: 779,
      region_id: 3,
      name_ar: 'النخيل',
      name_en: 'An Nakhil',
    },
    {
      city_id: 780,
      region_id: 3,
      name_ar: 'الصمد',
      name_en: 'As Samad',
    },
    {
      city_id: 781,
      region_id: 3,
      name_ar: 'الضميرية',
      name_en: 'Ad Dumayriyah',
    },
    {
      city_id: 782,
      region_id: 3,
      name_ar: 'المحامة',
      name_en: 'Al Mahamah',
    },
    {
      city_id: 783,
      region_id: 3,
      name_ar: 'الحسو',
      name_en: 'Al Hisu',
    },
    {
      city_id: 784,
      region_id: 3,
      name_ar: 'الهميج',
      name_en: 'Al Humayj',
    },
    {
      city_id: 785,
      region_id: 3,
      name_ar: 'صخيبرة',
      name_en: 'Sukhaybirah',
    },
    {
      city_id: 786,
      region_id: 3,
      name_ar: 'هدبان',
      name_en: 'Hadban',
    },
    {
      city_id: 787,
      region_id: 3,
      name_ar: 'بلغة',
      name_en: 'Bilghah',
    },
    {
      city_id: 788,
      region_id: 3,
      name_ar: 'الماوية',
      name_en: 'Al Mawiyah',
    },
    {
      city_id: 789,
      region_id: 3,
      name_ar: 'قصيرة',
      name_en: 'Qusayrah',
    },
    {
      city_id: 790,
      region_id: 3,
      name_ar: 'العوشزي',
      name_en: 'Al Awshazi',
    },
    {
      city_id: 791,
      region_id: 3,
      name_ar: 'عرجاء',
      name_en: "Arja'",
    },
    {
      city_id: 792,
      region_id: 3,
      name_ar: 'طلال',
      name_en: 'Talal',
    },
    {
      city_id: 793,
      region_id: 1,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 794,
      region_id: 1,
      name_ar: 'الطوقي',
      name_en: 'At Tawqi',
    },
    {
      city_id: 795,
      region_id: 1,
      name_ar: 'حريملاء',
      name_en: 'Huraymila',
    },
    {
      city_id: 796,
      region_id: 1,
      name_ar: 'ملهم',
      name_en: 'Malham',
    },
    {
      city_id: 797,
      region_id: 1,
      name_ar: 'سدوس',
      name_en: 'Sudus',
    },
    {
      city_id: 798,
      region_id: 1,
      name_ar: 'حزوى',
      name_en: 'Hizwa',
    },
    {
      city_id: 799,
      region_id: 1,
      name_ar: 'دقلة',
      name_en: 'Diqalah',
    },
    {
      city_id: 800,
      region_id: 1,
      name_ar: 'حليفة',
      name_en: 'Hulayfah',
    },
    {
      city_id: 801,
      region_id: 1,
      name_ar: 'صلبوخ',
      name_en: 'Sulbukh',
    },
    {
      city_id: 802,
      region_id: 1,
      name_ar: 'البرة',
      name_en: 'Al Barrah',
    },
    {
      city_id: 803,
      region_id: 1,
      name_ar: 'العويند',
      name_en: 'Al Uwaynid',
    },
    {
      city_id: 804,
      region_id: 1,
      name_ar: 'البويب',
      name_en: 'Al Buwayb',
    },
    {
      city_id: 805,
      region_id: 7,
      name_ar: 'عيينة',
      name_en: 'Uyaynah',
    },
    {
      city_id: 806,
      region_id: 1,
      name_ar: 'إبن غنام',
      name_en: 'Ibn Ghannam',
    },
    {
      city_id: 807,
      region_id: 1,
      name_ar: 'الغنامية',
      name_en: 'Al Ghanamiyah',
    },
    {
      city_id: 808,
      region_id: 1,
      name_ar: 'العماج',
      name_en: "Al 'Ammaj",
    },
    {
      city_id: 809,
      region_id: 1,
      name_ar: 'هيت',
      name_en: 'Hit',
    },
    {
      city_id: 810,
      region_id: 1,
      name_ar: 'الحائر',
      name_en: 'Al Hair',
    },
    {
      city_id: 811,
      region_id: 1,
      name_ar: 'كهف برمة',
      name_en: 'Kahf Barmah',
    },
    {
      city_id: 812,
      region_id: 1,
      name_ar: 'بنبان',
      name_en: 'Banban',
    },
    {
      city_id: 813,
      region_id: 1,
      name_ar: 'الثمامة',
      name_en: 'Ath Thumamah',
    },
    {
      city_id: 814,
      region_id: 1,
      name_ar: 'لبن',
      name_en: 'Laban',
    },
    {
      city_id: 815,
      region_id: 1,
      name_ar: 'العريدية',
      name_en: "Al 'Uraydiyah",
    },
    {
      city_id: 816,
      region_id: 1,
      name_ar: 'العباسية',
      name_en: "Al 'Abbasiyah",
    },
    {
      city_id: 817,
      region_id: 1,
      name_ar: 'أم رضمة',
      name_en: 'Umm Radmah',
    },
    {
      city_id: 818,
      region_id: 1,
      name_ar: 'أم سليم',
      name_en: 'Umm Sulaym',
    },
    {
      city_id: 819,
      region_id: 1,
      name_ar: 'ثرمداء',
      name_en: "Tharmada'",
    },
    {
      city_id: 820,
      region_id: 1,
      name_ar: 'مرات',
      name_en: 'Marat',
    },
    {
      city_id: 821,
      region_id: 1,
      name_ar: 'أثيثية',
      name_en: 'Uthaythiyah',
    },
    {
      city_id: 822,
      region_id: 1,
      name_ar: 'لبخة',
      name_en: 'Labkhah',
    },
    {
      city_id: 823,
      region_id: 1,
      name_ar: 'روضة الفرس',
      name_en: 'Rawdat Al Faras',
    },
    {
      city_id: 824,
      region_id: 1,
      name_ar: 'ام طلحة',
      name_en: 'Umm Talhah',
    },
    {
      city_id: 825,
      region_id: 1,
      name_ar: 'حويتة',
      name_en: 'Huwaytah',
    },
    {
      city_id: 826,
      region_id: 1,
      name_ar: 'الطويلة',
      name_en: 'At Tawilah',
    },
    {
      city_id: 827,
      region_id: 1,
      name_ar: 'أوبير',
      name_en: 'Ubayr',
    },
    {
      city_id: 828,
      region_id: 1,
      name_ar: 'الدرعية',
      name_en: "Ad Dir'iyah",
    },
    {
      city_id: 829,
      region_id: 1,
      name_ar: 'عرقة',
      name_en: "'Irqah",
    },
    {
      city_id: 830,
      region_id: 1,
      name_ar: 'العيينة',
      name_en: 'Al Uyainah',
    },
    {
      city_id: 831,
      region_id: 1,
      name_ar: 'الوصيل',
      name_en: 'Al Wusayl',
    },
    {
      city_id: 832,
      region_id: 1,
      name_ar: 'أبا الكباش',
      name_en: 'Aba Al Kibash',
    },
    {
      city_id: 833,
      region_id: 1,
      name_ar: 'الجبيلة',
      name_en: 'Al Jubaylah',
    },
    {
      city_id: 834,
      region_id: 1,
      name_ar: 'العمارية',
      name_en: "Al 'Ammariyah",
    },
    {
      city_id: 835,
      region_id: 1,
      name_ar: 'بوضة',
      name_en: 'Bawdah',
    },
    {
      city_id: 836,
      region_id: 1,
      name_ar: 'العليا',
      name_en: "Al 'Ulayya",
    },
    {
      city_id: 837,
      region_id: 1,
      name_ar: 'قصر القرينة',
      name_en: 'Qasr Al Quraynah',
    },
    {
      city_id: 838,
      region_id: 1,
      name_ar: 'قصر فيهق',
      name_en: 'Qasr Fayhaq',
    },
    {
      city_id: 839,
      region_id: 1,
      name_ar: 'ضرما',
      name_en: 'Duruma',
    },
    {
      city_id: 840,
      region_id: 1,
      name_ar: 'قصور المقبل',
      name_en: 'Qusur Al Muqbil',
    },
    {
      city_id: 841,
      region_id: 1,
      name_ar: 'الغطغط',
      name_en: 'Al Ghutghut',
    },
    {
      city_id: 842,
      region_id: 1,
      name_ar: 'قصر ابن شهيل',
      name_en: 'Qasr Ibn Shuhayl',
    },
    {
      city_id: 843,
      region_id: 1,
      name_ar: 'المغرفية',
      name_en: 'Al Maghrafiyah',
    },
    {
      city_id: 844,
      region_id: 1,
      name_ar: 'جو',
      name_en: 'Jaww',
    },
    {
      city_id: 845,
      region_id: 1,
      name_ar: 'الغزيز',
      name_en: 'Al Ghuzayz',
    },
    {
      city_id: 846,
      region_id: 1,
      name_ar: 'الثمامي',
      name_en: 'Ath Thumamy',
    },
    {
      city_id: 847,
      region_id: 1,
      name_ar: 'وعلة',
      name_en: "Wa'alah",
    },
    {
      city_id: 848,
      region_id: 1,
      name_ar: 'الشامانية',
      name_en: 'Ash Shamaniyah',
    },
    {
      city_id: 849,
      region_id: 1,
      name_ar: 'القصورية',
      name_en: 'Al Qusuriyah',
    },
    {
      city_id: 850,
      region_id: 1,
      name_ar: 'مزلة',
      name_en: 'Mazallah',
    },
    {
      city_id: 851,
      region_id: 1,
      name_ar: 'عصيلة',
      name_en: "'Usaylah",
    },
    {
      city_id: 852,
      region_id: 1,
      name_ar: 'الشواة',
      name_en: 'Ash Shawah',
    },
    {
      city_id: 853,
      region_id: 1,
      name_ar: 'عليان',
      name_en: "'Ilayyan",
    },
    {
      city_id: 854,
      region_id: 1,
      name_ar: 'عيدان',
      name_en: 'Idan',
    },
    {
      city_id: 855,
      region_id: 1,
      name_ar: 'سيح الدبول',
      name_en: 'Sayh Ad Dubul',
    },
    {
      city_id: 856,
      region_id: 1,
      name_ar: 'الهمجة',
      name_en: 'Al Hamjah',
    },
    {
      city_id: 857,
      region_id: 4,
      name_ar: 'ابو سديرة',
      name_en: 'Abu Sudairah',
    },
    {
      city_id: 858,
      region_id: 1,
      name_ar: 'الكدرة',
      name_en: 'Al Kadrah',
    },
    {
      city_id: 859,
      region_id: 1,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 860,
      region_id: 1,
      name_ar: 'الخيس',
      name_en: 'Al Khis',
    },
    {
      city_id: 861,
      region_id: 1,
      name_ar: 'الدويرة',
      name_en: 'Ad Duwayrah',
    },
    {
      city_id: 862,
      region_id: 1,
      name_ar: 'أبو مروة',
      name_en: 'Abu Marwah',
    },
    {
      city_id: 863,
      region_id: 1,
      name_ar: 'سماح',
      name_en: 'Samah',
    },
    {
      city_id: 864,
      region_id: 1,
      name_ar: 'القرنة',
      name_en: 'Al Qarnah',
    },
    {
      city_id: 865,
      region_id: 1,
      name_ar: 'داحس',
      name_en: 'Dahis',
    },
    {
      city_id: 866,
      region_id: 1,
      name_ar: 'دسمان',
      name_en: 'Dasman',
    },
    {
      city_id: 867,
      region_id: 1,
      name_ar: 'العويسية',
      name_en: "Al 'Uwaysiyah",
    },
    {
      city_id: 868,
      region_id: 1,
      name_ar: 'العلياء',
      name_en: "Al 'Ulayya",
    },
    {
      city_id: 869,
      region_id: 1,
      name_ar: 'مزرعة مزعلة',
      name_en: "Mazra'at Miz'ilah",
    },
    {
      city_id: 870,
      region_id: 1,
      name_ar: 'القوسة',
      name_en: 'Al Qawsah',
    },
    {
      city_id: 871,
      region_id: 1,
      name_ar: 'الروغ',
      name_en: 'Ar Rawgh',
    },
    {
      city_id: 872,
      region_id: 1,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 873,
      region_id: 1,
      name_ar: 'البعيثية',
      name_en: "Al Bu'aythiyah",
    },
    {
      city_id: 874,
      region_id: 1,
      name_ar: 'الشتلانية',
      name_en: 'Ash Shatlaniyah',
    },
    {
      city_id: 875,
      region_id: 1,
      name_ar: 'جدالة',
      name_en: 'Jaddalah',
    },
    {
      city_id: 876,
      region_id: 1,
      name_ar: 'قفرة',
      name_en: 'Qafarah',
    },
    {
      city_id: 877,
      region_id: 1,
      name_ar: 'أجزالا',
      name_en: 'Ajzala',
    },
    {
      city_id: 878,
      region_id: 1,
      name_ar: 'الجفارة',
      name_en: 'Al Jafarah',
    },
    {
      city_id: 879,
      region_id: 1,
      name_ar: 'غودة',
      name_en: 'Ghawdah',
    },
    {
      city_id: 880,
      region_id: 1,
      name_ar: 'القويعية',
      name_en: "Al Quway'iyah",
    },
    {
      city_id: 881,
      region_id: 1,
      name_ar: 'حلبان',
      name_en: 'Halaban',
    },
    {
      city_id: 882,
      region_id: 1,
      name_ar: 'سنام',
      name_en: 'Sanam',
    },
    {
      city_id: 883,
      region_id: 1,
      name_ar: 'الرجع',
      name_en: "Ar Raj'",
    },
    {
      city_id: 884,
      region_id: 1,
      name_ar: 'طحي',
      name_en: 'Tuhayy',
    },
    {
      city_id: 885,
      region_id: 1,
      name_ar: 'الرويضة',
      name_en: 'Ar Ruwaydah',
    },
    {
      city_id: 886,
      region_id: 1,
      name_ar: 'الخنيقية',
      name_en: 'Al Khunayqiyah',
    },
    {
      city_id: 887,
      region_id: 1,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 888,
      region_id: 1,
      name_ar: 'المثناة',
      name_en: 'Al Mathnah',
    },
    {
      city_id: 889,
      region_id: 1,
      name_ar: 'الجلة',
      name_en: 'Jellah',
    },
    {
      city_id: 890,
      region_id: 1,
      name_ar: 'الربواء',
      name_en: 'Ar Rabwa',
    },
    {
      city_id: 891,
      region_id: 1,
      name_ar: 'لجعة',
      name_en: "Laj'ah",
    },
    {
      city_id: 892,
      region_id: 1,
      name_ar: 'نخيلان',
      name_en: 'Nukhaylan',
    },
    {
      city_id: 893,
      region_id: 1,
      name_ar: 'عنان',
      name_en: "'Inan",
    },
    {
      city_id: 894,
      region_id: 1,
      name_ar: 'البدع',
      name_en: "Al Bada'",
    },
    {
      city_id: 895,
      region_id: 1,
      name_ar: 'صبحاء',
      name_en: 'Sabha',
    },
    {
      city_id: 896,
      region_id: 2,
      name_ar: 'القصر',
      name_en: 'Al Qasr',
    },
    {
      city_id: 897,
      region_id: 2,
      name_ar: 'ترعة',
      name_en: "Tir'ah",
    },
    {
      city_id: 898,
      region_id: 2,
      name_ar: 'ظلم',
      name_en: 'Dhalam',
    },
    {
      city_id: 899,
      region_id: 2,
      name_ar: 'الخفاشة',
      name_en: 'Al Khafashah',
    },
    {
      city_id: 900,
      region_id: 2,
      name_ar: 'براش',
      name_en: 'Barash',
    },
    {
      city_id: 901,
      region_id: 2,
      name_ar: 'الشريف',
      name_en: 'Ash Sharif',
    },
    {
      city_id: 902,
      region_id: 1,
      name_ar: 'الجلة وتبراك',
      name_en: 'Jellah And Tebrak',
    },
    {
      city_id: 903,
      region_id: 2,
      name_ar: 'الفرع',
      name_en: "Al Far'",
    },
    {
      city_id: 904,
      region_id: 2,
      name_ar: 'حرجل',
      name_en: 'Harjal',
    },
    {
      city_id: 905,
      region_id: 2,
      name_ar: 'هديل',
      name_en: 'Hidayl',
    },
    {
      city_id: 906,
      region_id: 2,
      name_ar: 'ام الحصن',
      name_en: 'Umm Al Hisn',
    },
    {
      city_id: 907,
      region_id: 2,
      name_ar: 'اللحيان',
      name_en: 'Al Lahyan',
    },
    {
      city_id: 908,
      region_id: 2,
      name_ar: 'الاسراب',
      name_en: 'Al Asrab',
    },
    {
      city_id: 909,
      region_id: 2,
      name_ar: 'العثرية',
      name_en: 'Al Athriyah',
    },
    {
      city_id: 910,
      region_id: 4,
      name_ar: 'الوطاة',
      name_en: 'Al Watah',
    },
    {
      city_id: 911,
      region_id: 4,
      name_ar: 'غضي',
      name_en: 'Ghudayy',
    },
    {
      city_id: 912,
      region_id: 4,
      name_ar: 'الحيطان',
      name_en: 'Al Haytan',
    },
    {
      city_id: 913,
      region_id: 4,
      name_ar: 'الصريف',
      name_en: 'As Sarif',
    },
    {
      city_id: 914,
      region_id: 4,
      name_ar: 'الفايزية',
      name_en: 'Al Fayziyah',
    },
    {
      city_id: 915,
      region_id: 4,
      name_ar: 'الصعيبية',
      name_en: "As Su'aybiyah",
    },
    {
      city_id: 916,
      region_id: 4,
      name_ar: 'نقرة العمارين',
      name_en: "Nuqrat Al 'Amarin",
    },
    {
      city_id: 917,
      region_id: 4,
      name_ar: 'رغيلة',
      name_en: 'Rughaylah',
    },
    {
      city_id: 918,
      region_id: 4,
      name_ar: 'مزارع الدغمانيات',
      name_en: "Mazari' Ad Daghmaniyat",
    },
    {
      city_id: 919,
      region_id: 4,
      name_ar: 'مزارع الراشد',
      name_en: "Mazari' Ar Rashid",
    },
    {
      city_id: 920,
      region_id: 4,
      name_ar: 'العليا',
      name_en: "Al 'Ulayya",
    },
    {
      city_id: 921,
      region_id: 4,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 922,
      region_id: 4,
      name_ar: 'أبلق',
      name_en: 'Ablaq',
    },
    {
      city_id: 923,
      region_id: 4,
      name_ar: 'ضراس',
      name_en: 'Diras',
    },
    {
      city_id: 924,
      region_id: 4,
      name_ar: 'أم مكرية',
      name_en: 'Umm Makriyah',
    },
    {
      city_id: 925,
      region_id: 4,
      name_ar: 'الخناصة',
      name_en: 'Al Khinnasah',
    },
    {
      city_id: 926,
      region_id: 4,
      name_ar: 'مزارع الصقيريات',
      name_en: "Mazari' As Suqayriyat",
    },
    {
      city_id: 927,
      region_id: 4,
      name_ar: 'رياض الرماح',
      name_en: 'Riyad Ar Rimah',
    },
    {
      city_id: 928,
      region_id: 4,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 929,
      region_id: 4,
      name_ar: 'روض ابن كميان',
      name_en: 'Rawd Ibn Kumayyan',
    },
    {
      city_id: 930,
      region_id: 4,
      name_ar: 'القرو',
      name_en: 'Al Qaru',
    },
    {
      city_id: 931,
      region_id: 4,
      name_ar: 'الحديدية',
      name_en: 'Al Hadidiyah',
    },
    {
      city_id: 932,
      region_id: 4,
      name_ar: 'البعيثة',
      name_en: "Al Bi'ithah",
    },
    {
      city_id: 933,
      region_id: 4,
      name_ar: 'الصغيرية',
      name_en: 'As Sughayriyah',
    },
    {
      city_id: 934,
      region_id: 4,
      name_ar: 'حويلان',
      name_en: 'Huwaylan',
    },
    {
      city_id: 935,
      region_id: 4,
      name_ar: 'الصباخ',
      name_en: 'As Subakh',
    },
    {
      city_id: 936,
      region_id: 4,
      name_ar: 'اللسيب',
      name_en: 'Al Lusayb',
    },
    {
      city_id: 937,
      region_id: 4,
      name_ar: 'الدعيسة',
      name_en: "Ad Da'isah",
    },
    {
      city_id: 938,
      region_id: 4,
      name_ar: 'العاقول',
      name_en: "Al 'Aqul",
    },
    {
      city_id: 939,
      region_id: 4,
      name_ar: 'البصر',
      name_en: 'Al Busur',
    },
    {
      city_id: 940,
      region_id: 13,
      name_ar: 'المريشدية',
      name_en: 'Al Murayshdiyah',
    },
    {
      city_id: 941,
      region_id: 4,
      name_ar: 'الطرفية',
      name_en: 'At Turfiyah',
    },
    {
      city_id: 942,
      region_id: 4,
      name_ar: 'القرعاء',
      name_en: "Al Qar'a",
    },
    {
      city_id: 943,
      region_id: 4,
      name_ar: 'المليداء',
      name_en: "Al Mulayda'",
    },
    {
      city_id: 944,
      region_id: 4,
      name_ar: 'البطين',
      name_en: 'Al Butayn',
    },
    {
      city_id: 945,
      region_id: 4,
      name_ar: 'محير الترمس',
      name_en: 'Mihir At Turmus',
    },
    {
      city_id: 946,
      region_id: 4,
      name_ar: 'شري',
      name_en: 'Shari',
    },
    {
      city_id: 947,
      region_id: 4,
      name_ar: 'الشقة العليا',
      name_en: "Ash Shiqqah Al 'Ulya",
    },
    {
      city_id: 948,
      region_id: 4,
      name_ar: 'الشقة السفلى',
      name_en: 'Ash Shiqqah As Suflah',
    },
    {
      city_id: 949,
      region_id: 4,
      name_ar: 'الزبيرة',
      name_en: 'Az Zubayrah',
    },
    {
      city_id: 950,
      region_id: 4,
      name_ar: 'قصيباء',
      name_en: 'Qusayba',
    },
    {
      city_id: 951,
      region_id: 4,
      name_ar: 'القوارة',
      name_en: 'Al Quwarah',
    },
    {
      city_id: 952,
      region_id: 4,
      name_ar: 'الهدية',
      name_en: 'Al Hudayyah',
    },
    {
      city_id: 953,
      region_id: 4,
      name_ar: 'الغماس',
      name_en: 'Al Ghammas',
    },
    {
      city_id: 954,
      region_id: 4,
      name_ar: 'مدرج',
      name_en: 'Mudarraj',
    },
    {
      city_id: 955,
      region_id: 4,
      name_ar: 'قبة',
      name_en: 'Qibah',
    },
    {
      city_id: 956,
      region_id: 1,
      name_ar: 'أم سريحة',
      name_en: 'Umm Surayhah',
    },
    {
      city_id: 957,
      region_id: 1,
      name_ar: 'الخروعية',
      name_en: "Al Kharwa'iyah",
    },
    {
      city_id: 958,
      region_id: 1,
      name_ar: 'المجذمية',
      name_en: 'Al Majdhamiyah',
    },
    {
      city_id: 959,
      region_id: 1,
      name_ar: 'الحصاة',
      name_en: 'Alhasat',
    },
    {
      city_id: 960,
      region_id: 1,
      name_ar: 'الحرملية',
      name_en: 'Al Harmaliyah',
    },
    {
      city_id: 961,
      region_id: 1,
      name_ar: 'خبراء الثوير',
      name_en: 'Khabra Ath Thawir',
    },
    {
      city_id: 962,
      region_id: 1,
      name_ar: 'الرين',
      name_en: 'Ar Rayn',
    },
    {
      city_id: 963,
      region_id: 1,
      name_ar: 'محيرقة',
      name_en: 'Muhairiqah',
    },
    {
      city_id: 964,
      region_id: 1,
      name_ar: 'مرقان',
      name_en: 'Marqan',
    },
    {
      city_id: 965,
      region_id: 1,
      name_ar: 'ام نخيلة',
      name_en: 'Umm Nakhilah',
    },
    {
      city_id: 966,
      region_id: 1,
      name_ar: 'ام الدبي',
      name_en: 'Umm Ad Dibay',
    },
    {
      city_id: 967,
      region_id: 1,
      name_ar: 'خبراء حلوة',
      name_en: 'Khabra Halwah',
    },
    {
      city_id: 968,
      region_id: 1,
      name_ar: 'حجيلا',
      name_en: 'Hujayla',
    },
    {
      city_id: 969,
      region_id: 1,
      name_ar: 'الفويلق',
      name_en: 'Al Fuwayliq',
    },
    {
      city_id: 970,
      region_id: 1,
      name_ar: 'القويع',
      name_en: "Al Quway'",
    },
    {
      city_id: 971,
      region_id: 1,
      name_ar: 'مزعل',
      name_en: "Miz'il",
    },
    {
      city_id: 972,
      region_id: 1,
      name_ar: 'بدائع المشاعلة',
      name_en: "Badai' Al Masha'ilah",
    },
    {
      city_id: 973,
      region_id: 1,
      name_ar: 'خبراء فاران',
      name_en: 'Khabra Faran',
    },
    {
      city_id: 974,
      region_id: 1,
      name_ar: 'خبيب الريم',
      name_en: 'Khubayb Ar Rim',
    },
    {
      city_id: 975,
      region_id: 1,
      name_ar: 'القرارة',
      name_en: 'Al Qararah',
    },
    {
      city_id: 976,
      region_id: 1,
      name_ar: 'أم طليح',
      name_en: 'Umm Tulayh',
    },
    {
      city_id: 977,
      region_id: 1,
      name_ar: 'المانعية',
      name_en: "Al Mani'iyah",
    },
    {
      city_id: 978,
      region_id: 1,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 979,
      region_id: 1,
      name_ar: 'العدوة',
      name_en: "Al 'Idwah",
    },
    {
      city_id: 980,
      region_id: 1,
      name_ar: 'الذيبية',
      name_en: 'Adh Dhibiyah',
    },
    {
      city_id: 981,
      region_id: 1,
      name_ar: 'الصدر',
      name_en: 'As Sadr',
    },
    {
      city_id: 982,
      region_id: 1,
      name_ar: 'ابرقية',
      name_en: 'Abraqiyah',
    },
    {
      city_id: 983,
      region_id: 1,
      name_ar: 'حجرفة',
      name_en: 'Hajrufah',
    },
    {
      city_id: 984,
      region_id: 1,
      name_ar: 'معانيق',
      name_en: "Ma'aniq",
    },
    {
      city_id: 985,
      region_id: 1,
      name_ar: 'الصيهد',
      name_en: 'As Sayhad',
    },
    {
      city_id: 986,
      region_id: 1,
      name_ar: 'اللغفة',
      name_en: 'Al Laghfah',
    },
    {
      city_id: 987,
      region_id: 1,
      name_ar: 'الخفق الجديد',
      name_en: 'Al Khafaq Al Jadid',
    },
    {
      city_id: 988,
      region_id: 1,
      name_ar: 'ظلماء',
      name_en: 'Dhalma',
    },
    {
      city_id: 989,
      region_id: 1,
      name_ar: 'الخلائق الشرقية',
      name_en: 'Al Khalaiq Ash Sharqiyah',
    },
    {
      city_id: 990,
      region_id: 1,
      name_ar: 'المزاحمية',
      name_en: 'Al Muzahimiyah',
    },
    {
      city_id: 991,
      region_id: 1,
      name_ar: 'تبراك',
      name_en: 'Tebrak',
    },
    {
      city_id: 992,
      region_id: 1,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 993,
      region_id: 1,
      name_ar: 'الاوسط',
      name_en: 'Al Awsat',
    },
    {
      city_id: 994,
      region_id: 1,
      name_ar: 'الحويرة',
      name_en: 'Al Huwayrrah',
    },
    {
      city_id: 995,
      region_id: 1,
      name_ar: 'خشم معضد',
      name_en: "Khashm Mi'dad",
    },
    {
      city_id: 996,
      region_id: 1,
      name_ar: 'البخرا',
      name_en: 'Al Bakhra',
    },
    {
      city_id: 997,
      region_id: 1,
      name_ar: 'اللغفية',
      name_en: 'Al Lughfiyah',
    },
    {
      city_id: 998,
      region_id: 1,
      name_ar: 'المسبر',
      name_en: 'Al Masbar',
    },
    {
      city_id: 999,
      region_id: 1,
      name_ar: 'النظيم',
      name_en: 'An Nadhim',
    },
    {
      city_id: 1000,
      region_id: 1,
      name_ar: 'حفرة نساح',
      name_en: 'Hafirat Nisah',
    },
    {
      city_id: 1001,
      region_id: 1,
      name_ar: 'عجاج',
      name_en: "'Ajaj",
    },
    {
      city_id: 1002,
      region_id: 1,
      name_ar: 'المشاعلة',
      name_en: "Al Masha'ilah",
    },
    {
      city_id: 1003,
      region_id: 1,
      name_ar: 'قنيفذة',
      name_en: 'Qunayfidhah',
    },
    {
      city_id: 1004,
      region_id: 1,
      name_ar: 'عفرية',
      name_en: 'Ifriyah',
    },
    {
      city_id: 1005,
      region_id: 1,
      name_ar: 'الخلائق الغربية',
      name_en: 'Al Khalaiq Al Gharbiyah',
    },
    {
      city_id: 1006,
      region_id: 1,
      name_ar: 'الجفير',
      name_en: 'Al Jufayr',
    },
    {
      city_id: 1007,
      region_id: 3,
      name_ar: 'الأسطح',
      name_en: 'Al Astah',
    },
    {
      city_id: 1008,
      region_id: 3,
      name_ar: 'الرجع',
      name_en: "Ar Raj'",
    },
    {
      city_id: 1009,
      region_id: 3,
      name_ar: 'دمان',
      name_en: 'Duman',
    },
    {
      city_id: 1010,
      region_id: 3,
      name_ar: 'المفرق',
      name_en: 'Al Mafraq',
    },
    {
      city_id: 1011,
      region_id: 3,
      name_ar: 'الغزلان',
      name_en: 'Al Ghuzlan',
    },
    {
      city_id: 1012,
      region_id: 3,
      name_ar: 'الجديد',
      name_en: 'Al Jadid',
    },
    {
      city_id: 1013,
      region_id: 3,
      name_ar: 'وجمة',
      name_en: 'Wajmah',
    },
    {
      city_id: 1014,
      region_id: 3,
      name_ar: 'عرقوص',
      name_en: 'Arqus',
    },
    {
      city_id: 1015,
      region_id: 3,
      name_ar: 'العنيق',
      name_en: "Al 'Unayq",
    },
    {
      city_id: 1016,
      region_id: 3,
      name_ar: 'مرتجة',
      name_en: 'Murattijah',
    },
    {
      city_id: 1017,
      region_id: 3,
      name_ar: 'الفقرة',
      name_en: 'Al Faqrah',
    },
    {
      city_id: 1018,
      region_id: 3,
      name_ar: 'خلص',
      name_en: 'Khals',
    },
    {
      city_id: 1019,
      region_id: 3,
      name_ar: 'القفاف',
      name_en: 'Al Qifaf',
    },
    {
      city_id: 1020,
      region_id: 3,
      name_ar: 'أم البرك',
      name_en: 'Umm Al Birak',
    },
    {
      city_id: 1021,
      region_id: 3,
      name_ar: 'مريخة',
      name_en: 'Muraykhah',
    },
    {
      city_id: 1022,
      region_id: 3,
      name_ar: 'العبلا',
      name_en: "Al 'Abla",
    },
    {
      city_id: 1023,
      region_id: 3,
      name_ar: 'مرموماء',
      name_en: 'Marmuma',
    },
    {
      city_id: 1024,
      region_id: 3,
      name_ar: 'قرظة',
      name_en: 'Qurudhah',
    },
    {
      city_id: 1025,
      region_id: 3,
      name_ar: 'أم فحي',
      name_en: 'Umm Fahi',
    },
    {
      city_id: 1026,
      region_id: 3,
      name_ar: 'البركة',
      name_en: 'Al Birkah',
    },
    {
      city_id: 1027,
      region_id: 3,
      name_ar: 'الهدى',
      name_en: 'Al Hada',
    },
    {
      city_id: 1028,
      region_id: 3,
      name_ar: 'الغور',
      name_en: 'Al Ghur',
    },
    {
      city_id: 1029,
      region_id: 3,
      name_ar: 'مثعر',
      name_en: "Math'ar",
    },
    {
      city_id: 1030,
      region_id: 3,
      name_ar: 'الحسينية',
      name_en: 'Al Husayniyah',
    },
    {
      city_id: 1031,
      region_id: 3,
      name_ar: 'الشفية',
      name_en: 'Ash Shufayyah',
    },
    {
      city_id: 1032,
      region_id: 3,
      name_ar: 'السليمية',
      name_en: 'As Sulaymiyah',
    },
    {
      city_id: 1033,
      region_id: 3,
      name_ar: 'أبو قرن',
      name_en: 'Abu Qarn',
    },
    {
      city_id: 1034,
      region_id: 3,
      name_ar: 'المسجد',
      name_en: 'Al Masjid',
    },
    {
      city_id: 1035,
      region_id: 3,
      name_ar: 'اللهبان',
      name_en: 'Al Lahban',
    },
    {
      city_id: 1036,
      region_id: 3,
      name_ar: 'الجوابرة',
      name_en: 'Al Jawabirat',
    },
    {
      city_id: 1037,
      region_id: 3,
      name_ar: 'خرص',
      name_en: 'Khuruss',
    },
    {
      city_id: 1038,
      region_id: 3,
      name_ar: 'عاصر',
      name_en: 'Asir',
    },
    {
      city_id: 1039,
      region_id: 3,
      name_ar: 'الأثود',
      name_en: 'Al Athud',
    },
    {
      city_id: 1040,
      region_id: 3,
      name_ar: 'ترجم',
      name_en: 'Tarjim',
    },
    {
      city_id: 1041,
      region_id: 3,
      name_ar: 'كتيدة',
      name_en: 'Kitaydah',
    },
    {
      city_id: 1042,
      region_id: 3,
      name_ar: 'سفاء',
      name_en: 'Safa',
    },
    {
      city_id: 1043,
      region_id: 3,
      name_ar: 'رحقان',
      name_en: 'Rahqan',
    },
    {
      city_id: 1044,
      region_id: 3,
      name_ar: 'ثعيل',
      name_en: "Thu'ayyil",
    },
    {
      city_id: 1045,
      region_id: 3,
      name_ar: 'المفرق',
      name_en: 'Al Mafraq',
    },
    {
      city_id: 1046,
      region_id: 3,
      name_ar: 'الميث',
      name_en: 'Al Mayth',
    },
    {
      city_id: 1047,
      region_id: 3,
      name_ar: 'بئر الغنم',
      name_en: 'Bir Al Ghanam',
    },
    {
      city_id: 1048,
      region_id: 3,
      name_ar: 'المرتجة',
      name_en: 'Al Murtajjah',
    },
    {
      city_id: 1049,
      region_id: 3,
      name_ar: 'المعيزلة',
      name_en: "Al Ma'ayzilah",
    },
    {
      city_id: 1050,
      region_id: 3,
      name_ar: 'الحار',
      name_en: 'Al Har',
    },
    {
      city_id: 1051,
      region_id: 3,
      name_ar: 'الصديرة',
      name_en: 'As Sudayyirah',
    },
    {
      city_id: 1052,
      region_id: 3,
      name_ar: 'دريبس',
      name_en: 'Diraybis',
    },
    {
      city_id: 1053,
      region_id: 3,
      name_ar: 'بدر',
      name_en: 'Badr',
    },
    {
      city_id: 1054,
      region_id: 3,
      name_ar: 'بئر قيضي',
      name_en: "Bi'r Qaydi",
    },
    {
      city_id: 1055,
      region_id: 3,
      name_ar: 'الواسطة',
      name_en: 'Al Wasithah',
    },
    {
      city_id: 1056,
      region_id: 3,
      name_ar: 'الرايس',
      name_en: 'Ar Rayis',
    },
    {
      city_id: 1057,
      region_id: 3,
      name_ar: 'المسيجيد',
      name_en: 'Al Musayjid',
    },
    {
      city_id: 1058,
      region_id: 1,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 1059,
      region_id: 1,
      name_ar: 'خفس دغرة',
      name_en: 'Khafs Daghrah',
    },
    {
      city_id: 1060,
      region_id: 1,
      name_ar: 'البياض',
      name_en: 'Al Biyad',
    },
    {
      city_id: 1061,
      region_id: 1,
      name_ar: 'الخرج',
      name_en: 'Al Kharj',
    },
    {
      city_id: 1062,
      region_id: 1,
      name_ar: 'اليمامة',
      name_en: 'Al Yamamah',
    },
    {
      city_id: 1063,
      region_id: 1,
      name_ar: 'الرفاع',
      name_en: "Ar Rufa'",
    },
    {
      city_id: 1064,
      region_id: 1,
      name_ar: 'البرة',
      name_en: 'Al Barrah',
    },
    {
      city_id: 1065,
      region_id: 1,
      name_ar: 'فرزان',
      name_en: 'Farzan',
    },
    {
      city_id: 1066,
      region_id: 1,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 1067,
      region_id: 1,
      name_ar: 'الرفائع',
      name_en: "Ar Rafai'",
    },
    {
      city_id: 1068,
      region_id: 1,
      name_ar: 'العذار',
      name_en: "Al 'Adhar",
    },
    {
      city_id: 1069,
      region_id: 1,
      name_ar: 'زميقة',
      name_en: 'Zumayqah',
    },
    {
      city_id: 1070,
      region_id: 1,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 1071,
      region_id: 1,
      name_ar: 'الهياثم',
      name_en: 'Al Hayathim',
    },
    {
      city_id: 1072,
      region_id: 1,
      name_ar: 'البدع القديم',
      name_en: "Al Bada' Al Qadim",
    },
    {
      city_id: 1073,
      region_id: 1,
      name_ar: 'الدلم',
      name_en: 'Ad Dilam',
    },
    {
      city_id: 1075,
      region_id: 1,
      name_ar: 'نعجان',
      name_en: "Na'jan",
    },
    {
      city_id: 1076,
      region_id: 1,
      name_ar: 'حي القطار',
      name_en: 'Hai Al Qitar',
    },
    {
      city_id: 1077,
      region_id: 1,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 1078,
      region_id: 1,
      name_ar: 'السلمية',
      name_en: 'As Silimiyah',
    },
    {
      city_id: 1079,
      region_id: 1,
      name_ar: 'البدع',
      name_en: "Al Bada'",
    },
    {
      city_id: 1080,
      region_id: 1,
      name_ar: 'السهباء',
      name_en: "As Sahba'",
    },
    {
      city_id: 1081,
      region_id: 1,
      name_ar: 'الثليما',
      name_en: 'Ath Thalima',
    },
    {
      city_id: 1082,
      region_id: 1,
      name_ar: 'التوضيحية',
      name_en: 'At Tawdihiyyah',
    },
    {
      city_id: 1083,
      region_id: 1,
      name_ar: 'المحطة',
      name_en: 'Al Mahattah',
    },
    {
      city_id: 1084,
      region_id: 1,
      name_ar: 'الرغيب',
      name_en: 'Ar Rughayb',
    },
    {
      city_id: 1085,
      region_id: 1,
      name_ar: 'المحمدي',
      name_en: 'Al Muhammadi',
    },
    {
      city_id: 1086,
      region_id: 1,
      name_ar: 'الصحنة',
      name_en: 'As Sahanah',
    },
    {
      city_id: 1087,
      region_id: 3,
      name_ar: 'الجريسية',
      name_en: 'Al Juraysiyah',
    },
    {
      city_id: 1088,
      region_id: 3,
      name_ar: 'الغمر',
      name_en: 'Al Ghamr',
    },
    {
      city_id: 1089,
      region_id: 3,
      name_ar: 'المزرع',
      name_en: "Al Mazri'",
    },
    {
      city_id: 1090,
      region_id: 3,
      name_ar: 'هبا',
      name_en: 'Haba',
    },
    {
      city_id: 1091,
      region_id: 3,
      name_ar: 'الصحن',
      name_en: 'As Sahan',
    },
    {
      city_id: 1092,
      region_id: 3,
      name_ar: 'مشوقة',
      name_en: 'Mashuqah',
    },
    {
      city_id: 1093,
      region_id: 3,
      name_ar: 'أم العراد',
      name_en: "Umm Al 'Arad",
    },
    {
      city_id: 1094,
      region_id: 3,
      name_ar: 'الهرارة',
      name_en: 'Al Harrarah',
    },
    {
      city_id: 1095,
      region_id: 3,
      name_ar: 'السدرة',
      name_en: 'As Sidrah',
    },
    {
      city_id: 1096,
      region_id: 3,
      name_ar: 'الجصة',
      name_en: 'Al Jissah',
    },
    {
      city_id: 1097,
      region_id: 3,
      name_ar: 'الحويمضة',
      name_en: 'Al Huwaymidah',
    },
    {
      city_id: 1098,
      region_id: 3,
      name_ar: 'قران',
      name_en: 'Qaran',
    },
    {
      city_id: 1099,
      region_id: 3,
      name_ar: 'الخفيق',
      name_en: 'Al Khufayq',
    },
    {
      city_id: 1100,
      region_id: 3,
      name_ar: 'الهميجة',
      name_en: 'Al Humayjah',
    },
    {
      city_id: 1101,
      region_id: 3,
      name_ar: 'الصالحية',
      name_en: 'As Salhiyah',
    },
    {
      city_id: 1102,
      region_id: 3,
      name_ar: 'مريغان',
      name_en: 'Murayghan',
    },
    {
      city_id: 1103,
      region_id: 3,
      name_ar: 'العشاي',
      name_en: "Al 'Ashshay",
    },
    {
      city_id: 1104,
      region_id: 2,
      name_ar: 'القعر الاسفل',
      name_en: "Al Qa'ar Al Asfal",
    },
    {
      city_id: 1105,
      region_id: 2,
      name_ar: 'خليص',
      name_en: 'Khulays',
    },
    {
      city_id: 1106,
      region_id: 2,
      name_ar: 'الحدباء',
      name_en: 'Al Hadba',
    },
    {
      city_id: 1107,
      region_id: 2,
      name_ar: 'الرهوة',
      name_en: 'Ar Rahwah',
    },
    {
      city_id: 1108,
      region_id: 2,
      name_ar: 'القوز',
      name_en: 'Al Qawz',
    },
    {
      city_id: 1109,
      region_id: 2,
      name_ar: 'الخنفسة',
      name_en: 'Al Khunfusah',
    },
    {
      city_id: 1110,
      region_id: 2,
      name_ar: 'حشاش',
      name_en: 'Hishash',
    },
    {
      city_id: 1111,
      region_id: 2,
      name_ar: 'الضبية',
      name_en: 'Ad Dubayyah',
    },
    {
      city_id: 1112,
      region_id: 2,
      name_ar: 'البريكة',
      name_en: 'Al Buraykah',
    },
    {
      city_id: 1113,
      region_id: 2,
      name_ar: 'الفز',
      name_en: 'Al Fazz',
    },
    {
      city_id: 1114,
      region_id: 2,
      name_ar: 'الطينة',
      name_en: 'At Tinah',
    },
    {
      city_id: 1115,
      region_id: 2,
      name_ar: 'ام الجرم',
      name_en: 'Umm Al Jurm',
    },
    {
      city_id: 1116,
      region_id: 2,
      name_ar: 'الدوارة',
      name_en: 'Ad Duwwarah',
    },
    {
      city_id: 1117,
      region_id: 2,
      name_ar: 'ابو مراغة',
      name_en: 'Abu Maraghah',
    },
    {
      city_id: 1118,
      region_id: 2,
      name_ar: 'الحفياء',
      name_en: 'Al Hafya',
    },
    {
      city_id: 1119,
      region_id: 2,
      name_ar: 'الخيف',
      name_en: 'Al Khayf',
    },
    {
      city_id: 1120,
      region_id: 2,
      name_ar: 'المكسر',
      name_en: 'Al Maksir',
    },
    {
      city_id: 1121,
      region_id: 2,
      name_ar: 'الفيج',
      name_en: 'Al Fij',
    },
    {
      city_id: 1122,
      region_id: 2,
      name_ar: 'السهم',
      name_en: 'Al Saham',
    },
    {
      city_id: 1123,
      region_id: 2,
      name_ar: 'الدف',
      name_en: 'Ad Daff',
    },
    {
      city_id: 1124,
      region_id: 2,
      name_ar: 'المشريفة',
      name_en: 'Al Musharifah',
    },
    {
      city_id: 1125,
      region_id: 2,
      name_ar: 'الوقب',
      name_en: 'Al Waqib',
    },
    {
      city_id: 1126,
      region_id: 2,
      name_ar: 'الحلقة',
      name_en: 'Al Halqah',
    },
    {
      city_id: 1127,
      region_id: 2,
      name_ar: 'جليل',
      name_en: 'Julayyil',
    },
    {
      city_id: 1128,
      region_id: 2,
      name_ar: 'حلمة',
      name_en: 'Hilimah',
    },
    {
      city_id: 1129,
      region_id: 2,
      name_ar: 'الجضع',
      name_en: "Al Jid'",
    },
    {
      city_id: 1130,
      region_id: 2,
      name_ar: 'البيار',
      name_en: 'Al Biyar',
    },
    {
      city_id: 1131,
      region_id: 2,
      name_ar: 'المعالي',
      name_en: "Al Ma'ali",
    },
    {
      city_id: 1132,
      region_id: 2,
      name_ar: 'المديد',
      name_en: 'Al Madid',
    },
    {
      city_id: 1133,
      region_id: 2,
      name_ar: 'المريصيع',
      name_en: "Al Maraysi'",
    },
    {
      city_id: 1134,
      region_id: 2,
      name_ar: 'جليلة',
      name_en: 'Jilayyilah',
    },
    {
      city_id: 1135,
      region_id: 2,
      name_ar: 'المسماة',
      name_en: 'Al Masammah',
    },
    {
      city_id: 1136,
      region_id: 2,
      name_ar: 'الحصينية',
      name_en: 'Al Husayniyah',
    },
    {
      city_id: 1137,
      region_id: 2,
      name_ar: 'الغروف',
      name_en: 'Al Ghuruf',
    },
    {
      city_id: 1138,
      region_id: 2,
      name_ar: 'البحول',
      name_en: 'Al Bahawal',
    },
    {
      city_id: 1139,
      region_id: 2,
      name_ar: 'دوقة',
      name_en: 'Dawqah',
    },
    {
      city_id: 1140,
      region_id: 2,
      name_ar: 'البخترية',
      name_en: 'Al Bakhtariyah',
    },
    {
      city_id: 1141,
      region_id: 2,
      name_ar: 'البرزة',
      name_en: 'Al Barzah',
    },
    {
      city_id: 1142,
      region_id: 2,
      name_ar: 'السليم',
      name_en: 'As Sulaym',
    },
    {
      city_id: 1143,
      region_id: 2,
      name_ar: 'مهايع',
      name_en: "Al Mahaya'",
    },
    {
      city_id: 1144,
      region_id: 2,
      name_ar: 'المعداء',
      name_en: "Al Ma'ada",
    },
    {
      city_id: 1145,
      region_id: 2,
      name_ar: 'ام العرمط',
      name_en: "Umm Al 'Urmut",
    },
    {
      city_id: 1146,
      region_id: 2,
      name_ar: 'المليحة',
      name_en: 'Al Milayhah',
    },
    {
      city_id: 1147,
      region_id: 2,
      name_ar: 'الدحلة',
      name_en: 'Ad Dahlah',
    },
    {
      city_id: 1148,
      region_id: 2,
      name_ar: 'الصليلات',
      name_en: 'As Salaylat',
    },
    {
      city_id: 1149,
      region_id: 2,
      name_ar: 'غصين',
      name_en: 'Ghusayyin',
    },
    {
      city_id: 1150,
      region_id: 2,
      name_ar: 'الكامل',
      name_en: 'Al Kamil',
    },
    {
      city_id: 1151,
      region_id: 2,
      name_ar: 'العيبة العليا',
      name_en: "Al 'Aybah Al 'Ulya",
    },
    {
      city_id: 1152,
      region_id: 5,
      name_ar: 'الطليعة',
      name_en: 'Al Taleah',
    },
    {
      city_id: 1153,
      region_id: 2,
      name_ar: 'القعور',
      name_en: "Al Qu'ur",
    },
    {
      city_id: 1154,
      region_id: 2,
      name_ar: 'الغريب',
      name_en: 'Al Gharayyib',
    },
    {
      city_id: 1155,
      region_id: 2,
      name_ar: 'مشرق',
      name_en: 'Mishriq',
    },
    {
      city_id: 1156,
      region_id: 2,
      name_ar: 'اللصب',
      name_en: 'Al Lusub',
    },
    {
      city_id: 1157,
      region_id: 2,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 1158,
      region_id: 2,
      name_ar: 'العمودة',
      name_en: "Al 'Amudah",
    },
    {
      city_id: 1159,
      region_id: 2,
      name_ar: 'الوقبة',
      name_en: 'Al Waqbah',
    },
    {
      city_id: 1160,
      region_id: 2,
      name_ar: 'الفارع',
      name_en: "Al Fari'",
    },
    {
      city_id: 1161,
      region_id: 2,
      name_ar: 'الخديد',
      name_en: 'Al Khidayd',
    },
    {
      city_id: 1162,
      region_id: 2,
      name_ar: 'المغاوي',
      name_en: 'Al Maghawi',
    },
    {
      city_id: 1163,
      region_id: 2,
      name_ar: 'المقفي',
      name_en: 'Al Maqfa',
    },
    {
      city_id: 1164,
      region_id: 2,
      name_ar: 'الحطامية',
      name_en: 'Al Hatamiyah',
    },
    {
      city_id: 1165,
      region_id: 2,
      name_ar: 'كدوة الأعوج',
      name_en: "Kidwat Al 'Awaj",
    },
    {
      city_id: 1166,
      region_id: 2,
      name_ar: 'هشيمة',
      name_en: 'Hishaymah',
    },
    {
      city_id: 1167,
      region_id: 2,
      name_ar: 'خبت مزقة',
      name_en: 'Khabt Mizqah',
    },
    {
      city_id: 1168,
      region_id: 2,
      name_ar: 'السبت',
      name_en: 'As Sabt',
    },
    {
      city_id: 1169,
      region_id: 2,
      name_ar: 'النيلة',
      name_en: 'An Nilah',
    },
    {
      city_id: 1170,
      region_id: 2,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 1171,
      region_id: 2,
      name_ar: 'الحليفة',
      name_en: 'Al Hulayfah',
    },
    {
      city_id: 1172,
      region_id: 2,
      name_ar: 'المسيليم',
      name_en: 'Al Musaylim',
    },
    {
      city_id: 1173,
      region_id: 2,
      name_ar: 'القرى',
      name_en: 'Al Qara',
    },
    {
      city_id: 1174,
      region_id: 6,
      name_ar: 'جمعة ربيعة',
      name_en: "Jum'at At Rabiah",
    },
    {
      city_id: 1175,
      region_id: 2,
      name_ar: 'الفالق',
      name_en: 'Al Faliq',
    },
    {
      city_id: 1176,
      region_id: 2,
      name_ar: 'خميس حرب',
      name_en: 'Khamis Harb',
    },
    {
      city_id: 1177,
      region_id: 2,
      name_ar: 'الجرد',
      name_en: 'Al Jard',
    },
    {
      city_id: 1178,
      region_id: 2,
      name_ar: 'الركح',
      name_en: 'Ar Rakh',
    },
    {
      city_id: 1179,
      region_id: 2,
      name_ar: 'السبطة',
      name_en: 'As Sabatah',
    },
    {
      city_id: 1180,
      region_id: 2,
      name_ar: 'الثلوث',
      name_en: 'Ath Thuluth',
    },
    {
      city_id: 1181,
      region_id: 2,
      name_ar: 'الشصرة',
      name_en: 'Ash Shasarah',
    },
    {
      city_id: 1182,
      region_id: 2,
      name_ar: 'عمرات',
      name_en: "'Amrat",
    },
    {
      city_id: 1183,
      region_id: 2,
      name_ar: 'عاجة',
      name_en: "'Ajah",
    },
    {
      city_id: 1184,
      region_id: 2,
      name_ar: 'العقدة (السمارية)',
      name_en: "Al 'Uqdah(Al Smariyah)",
    },
    {
      city_id: 1185,
      region_id: 2,
      name_ar: 'الخمجان',
      name_en: 'Al Khumjan',
    },
    {
      city_id: 1186,
      region_id: 2,
      name_ar: 'الكدنة',
      name_en: 'Al Kadanah',
    },
    {
      city_id: 1187,
      region_id: 2,
      name_ar: 'شينانة',
      name_en: 'Shaynanah',
    },
    {
      city_id: 1188,
      region_id: 2,
      name_ar: 'الجلمة',
      name_en: 'Al Jilamah',
    },
    {
      city_id: 1189,
      region_id: 2,
      name_ar: 'الصرح',
      name_en: 'As Sarh',
    },
    {
      city_id: 1190,
      region_id: 2,
      name_ar: 'الصعيب',
      name_en: "As Sa'ayb",
    },
    {
      city_id: 1191,
      region_id: 2,
      name_ar: 'القرية',
      name_en: 'Al Qurayyah',
    },
    {
      city_id: 1192,
      region_id: 2,
      name_ar: 'المضحاة',
      name_en: 'Al Madhah',
    },
    {
      city_id: 1193,
      region_id: 2,
      name_ar: 'النهيمية',
      name_en: 'An Nihaymiyah',
    },
    {
      city_id: 1194,
      region_id: 2,
      name_ar: 'الغريف',
      name_en: 'Al Gharif',
    },
    {
      city_id: 1195,
      region_id: 2,
      name_ar: 'الشرع',
      name_en: "Ash Shar'",
    },
    {
      city_id: 1196,
      region_id: 2,
      name_ar: 'الدغمية',
      name_en: 'Ad Dughmiyah',
    },
    {
      city_id: 1197,
      region_id: 2,
      name_ar: 'أم راكة',
      name_en: 'Umm Rakah',
    },
    {
      city_id: 1198,
      region_id: 2,
      name_ar: 'الدبيلة',
      name_en: 'Ad Dubayyilah',
    },
    {
      city_id: 1199,
      region_id: 2,
      name_ar: 'جبار',
      name_en: 'Jabbar',
    },
    {
      city_id: 1200,
      region_id: 2,
      name_ar: 'الحجف',
      name_en: 'Al Hajf',
    },
    {
      city_id: 1201,
      region_id: 2,
      name_ar: 'أبو جميدة',
      name_en: 'Abu Jumaydah',
    },
    {
      city_id: 1202,
      region_id: 2,
      name_ar: 'غثاة',
      name_en: 'Ghithah',
    },
    {
      city_id: 1203,
      region_id: 2,
      name_ar: 'الضحياء',
      name_en: 'Ad Dahya',
    },
    {
      city_id: 1204,
      region_id: 4,
      name_ar: 'الطراق',
      name_en: 'At Tiraq',
    },
    {
      city_id: 1205,
      region_id: 8,
      name_ar: 'الخريمي',
      name_en: 'Al Khuraymi',
    },
    {
      city_id: 1206,
      region_id: 8,
      name_ar: 'مزارع الدوية',
      name_en: "Mazari' Ad Dawayyah",
    },
    {
      city_id: 1207,
      region_id: 8,
      name_ar: 'القرفاء',
      name_en: 'Al Qarfa',
    },
    {
      city_id: 1208,
      region_id: 6,
      name_ar: 'الطويفح',
      name_en: 'Al Tuwaifah',
    },
    {
      city_id: 1209,
      region_id: 8,
      name_ar: 'مبرز',
      name_en: 'Mubaraz',
    },
    {
      city_id: 1210,
      region_id: 8,
      name_ar: 'السليل',
      name_en: 'As Sulayyil',
    },
    {
      city_id: 1211,
      region_id: 8,
      name_ar: 'سبيعة',
      name_en: "Sibi'ah",
    },
    {
      city_id: 1212,
      region_id: 8,
      name_ar: 'الرشاوية',
      name_en: 'Ar Rishawiyah',
    },
    {
      city_id: 1213,
      region_id: 8,
      name_ar: 'الميركة',
      name_en: 'Al Mirakah',
    },
    {
      city_id: 1214,
      region_id: 8,
      name_ar: 'الشلالة',
      name_en: 'Ash Shallalah',
    },
    {
      city_id: 1215,
      region_id: 8,
      name_ar: 'أم العماير',
      name_en: "Umm Al 'Amayir",
    },
    {
      city_id: 1216,
      region_id: 8,
      name_ar: 'مزارع تخاييل',
      name_en: 'Mazari Takhayil',
    },
    {
      city_id: 1217,
      region_id: 8,
      name_ar: 'بدائع إبضة',
      name_en: "Badai' Ibdah",
    },
    {
      city_id: 1218,
      region_id: 8,
      name_ar: 'الحورا',
      name_en: 'Al Hawra',
    },
    {
      city_id: 1219,
      region_id: 8,
      name_ar: 'قرية البطين',
      name_en: 'Qaryat Al Butain',
    },
    {
      city_id: 1220,
      region_id: 8,
      name_ar: 'إبضة',
      name_en: 'Ibdah',
    },
    {
      city_id: 1221,
      region_id: 8,
      name_ar: 'ثرال',
      name_en: 'Thiral',
    },
    {
      city_id: 1222,
      region_id: 8,
      name_ar: 'زيخين',
      name_en: 'Zikhin',
    },
    {
      city_id: 1223,
      region_id: 8,
      name_ar: 'الخيط',
      name_en: 'Al Khayt',
    },
    {
      city_id: 1224,
      region_id: 8,
      name_ar: 'الصلبية',
      name_en: 'As Sulubiyah',
    },
    {
      city_id: 1225,
      region_id: 8,
      name_ar: 'المعازل',
      name_en: "Al Ma'azil",
    },
    {
      city_id: 1226,
      region_id: 8,
      name_ar: 'المضابيع',
      name_en: "Al Madabi'",
    },
    {
      city_id: 1227,
      region_id: 8,
      name_ar: 'الساقية',
      name_en: 'As Saqiyah',
    },
    {
      city_id: 1228,
      region_id: 8,
      name_ar: 'الشنان',
      name_en: 'Ash Shinan',
    },
    {
      city_id: 1229,
      region_id: 8,
      name_ar: 'العدوة',
      name_en: "Al 'Idwah",
    },
    {
      city_id: 1230,
      region_id: 8,
      name_ar: 'السعيرة',
      name_en: "As Su'ayyirah",
    },
    {
      city_id: 1231,
      region_id: 8,
      name_ar: 'رك',
      name_en: 'Rakk',
    },
    {
      city_id: 1232,
      region_id: 8,
      name_ar: 'الفويلق / الرشاوية',
      name_en: 'Al Fuwaileq / Ar Rishawiyah',
    },
    {
      city_id: 1233,
      region_id: 8,
      name_ar: 'الابيتر',
      name_en: 'Al Ubaitir',
    },
    {
      city_id: 1234,
      region_id: 8,
      name_ar: 'الكهيفية',
      name_en: 'Al Kihayfiyah',
    },
    {
      city_id: 1235,
      region_id: 8,
      name_ar: 'العظيم',
      name_en: "Al 'Udhaim",
    },
    {
      city_id: 1236,
      region_id: 2,
      name_ar: 'المقيصر',
      name_en: 'Al Muqaysir',
    },
    {
      city_id: 1237,
      region_id: 2,
      name_ar: 'الوطاة',
      name_en: 'Al Watah',
    },
    {
      city_id: 1238,
      region_id: 2,
      name_ar: 'الزرب',
      name_en: 'Az Zirb',
    },
    {
      city_id: 1239,
      region_id: 2,
      name_ar: 'اللويات',
      name_en: 'Al Luwayyat',
    },
    {
      city_id: 1240,
      region_id: 2,
      name_ar: 'الأريقط',
      name_en: 'Al Urayqit',
    },
    {
      city_id: 1241,
      region_id: 2,
      name_ar: 'المعيزيلة',
      name_en: "Al Mu'azilah",
    },
    {
      city_id: 1242,
      region_id: 2,
      name_ar: 'الشرفية',
      name_en: 'Ash Shurufiyah',
    },
    {
      city_id: 1243,
      region_id: 2,
      name_ar: 'البريكة',
      name_en: 'Al Buraykah',
    },
    {
      city_id: 1244,
      region_id: 2,
      name_ar: 'الظليم',
      name_en: 'Ad Dhalim',
    },
    {
      city_id: 1245,
      region_id: 2,
      name_ar: 'الحنو',
      name_en: 'Al Hinu',
    },
    {
      city_id: 1246,
      region_id: 2,
      name_ar: 'الجعيرة',
      name_en: "Al Ju'ayrah",
    },
    {
      city_id: 1247,
      region_id: 2,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 1248,
      region_id: 2,
      name_ar: 'الخرمة',
      name_en: 'Al Khurmah',
    },
    {
      city_id: 1249,
      region_id: 2,
      name_ar: 'الغريف',
      name_en: 'Al Gharif',
    },
    {
      city_id: 1250,
      region_id: 2,
      name_ar: 'شبيرم',
      name_en: 'Shubayrim',
    },
    {
      city_id: 1251,
      region_id: 2,
      name_ar: 'مطية',
      name_en: 'Mityah',
    },
    {
      city_id: 1252,
      region_id: 2,
      name_ar: 'دف زيني',
      name_en: 'Daff Zayni',
    },
    {
      city_id: 1253,
      region_id: 2,
      name_ar: 'النويدرة',
      name_en: 'An Nuwaydirah',
    },
    {
      city_id: 1254,
      region_id: 2,
      name_ar: 'الصمد',
      name_en: 'As Samad',
    },
    {
      city_id: 1255,
      region_id: 2,
      name_ar: 'المرشدية',
      name_en: 'Al Murshidiyah',
    },
    {
      city_id: 1256,
      region_id: 2,
      name_ar: 'الدوح الكبير',
      name_en: 'Ad Dawh Al Kabir',
    },
    {
      city_id: 1257,
      region_id: 2,
      name_ar: 'الجموم',
      name_en: 'Al Jumum',
    },
    {
      city_id: 1258,
      region_id: 2,
      name_ar: 'ابو عروة',
      name_en: "Abu 'Urwah",
    },
    {
      city_id: 1259,
      region_id: 2,
      name_ar: 'البرابير',
      name_en: 'Al Brabir',
    },
    {
      city_id: 1260,
      region_id: 2,
      name_ar: 'قرن السرور',
      name_en: 'Qarn As Surur',
    },
    {
      city_id: 1261,
      region_id: 2,
      name_ar: 'الحميمة',
      name_en: 'Al Humaimah',
    },
    {
      city_id: 1262,
      region_id: 2,
      name_ar: 'حرة الجبل',
      name_en: 'Harat Al Jabal',
    },
    {
      city_id: 1263,
      region_id: 2,
      name_ar: 'سمد الحميمة',
      name_en: 'Samd Al Humaymah',
    },
    {
      city_id: 1264,
      region_id: 2,
      name_ar: 'النزهة',
      name_en: 'An Nuzhah',
    },
    {
      city_id: 1265,
      region_id: 6,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 1266,
      region_id: 6,
      name_ar: 'القذال',
      name_en: 'Al Qadhal',
    },
    {
      city_id: 1267,
      region_id: 6,
      name_ar: 'شواحط',
      name_en: 'Shawahit',
    },
    {
      city_id: 1268,
      region_id: 6,
      name_ar: 'عدمن',
      name_en: "'Adaman",
    },
    {
      city_id: 1269,
      region_id: 6,
      name_ar: 'المنيظر',
      name_en: 'Al Munaydhir',
    },
    {
      city_id: 1270,
      region_id: 6,
      name_ar: 'محبة',
      name_en: 'Mahabbah',
    },
    {
      city_id: 1271,
      region_id: 6,
      name_ar: 'الفصيلة',
      name_en: 'Al Fasilah',
    },
    {
      city_id: 1272,
      region_id: 6,
      name_ar: 'المسلمة',
      name_en: 'Al Maslamah',
    },
    {
      city_id: 1273,
      region_id: 6,
      name_ar: 'الخوش',
      name_en: 'Al Khawsh',
    },
    {
      city_id: 1274,
      region_id: 6,
      name_ar: 'الكنبي',
      name_en: 'Al Kanabi',
    },
    {
      city_id: 1275,
      region_id: 6,
      name_ar: 'القيوار',
      name_en: 'Al Qiwar',
    },
    {
      city_id: 1276,
      region_id: 6,
      name_ar: 'القنعات',
      name_en: "Al Qana'at",
    },
    {
      city_id: 1277,
      region_id: 6,
      name_ar: 'الميفاء',
      name_en: 'Al Mifa',
    },
    {
      city_id: 1278,
      region_id: 6,
      name_ar: 'صعابان',
      name_en: "Sa'aban",
    },
    {
      city_id: 1279,
      region_id: 6,
      name_ar: 'مكهاف',
      name_en: 'Makhaf',
    },
    {
      city_id: 1280,
      region_id: 6,
      name_ar: 'المنظر',
      name_en: 'Al Mandhar',
    },
    {
      city_id: 1281,
      region_id: 6,
      name_ar: 'أم فرع',
      name_en: "Umm Fura'",
    },
    {
      city_id: 1282,
      region_id: 6,
      name_ar: 'القريحة',
      name_en: 'Al Qurayhah',
    },
    {
      city_id: 1283,
      region_id: 6,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 1284,
      region_id: 6,
      name_ar: 'أم شعبين',
      name_en: "Umm Sh'bayn",
    },
    {
      city_id: 1285,
      region_id: 2,
      name_ar: 'مشرف',
      name_en: 'Mushrif',
    },
    {
      city_id: 1286,
      region_id: 6,
      name_ar: 'قزة',
      name_en: 'Quzzah',
    },
    {
      city_id: 1287,
      region_id: 6,
      name_ar: 'سيالة',
      name_en: 'Sayalah',
    },
    {
      city_id: 1288,
      region_id: 6,
      name_ar: 'الأحسر',
      name_en: 'Al Ahsar',
    },
    {
      city_id: 1289,
      region_id: 6,
      name_ar: 'الفاضية',
      name_en: 'Al Fadiyah',
    },
    {
      city_id: 1290,
      region_id: 6,
      name_ar: 'نعص',
      name_en: "Na's",
    },
    {
      city_id: 1291,
      region_id: 6,
      name_ar: 'الضحي',
      name_en: 'Ad Dahi',
    },
    {
      city_id: 1292,
      region_id: 6,
      name_ar: 'الحيد عبس',
      name_en: "Al Hayd 'Abs",
    },
    {
      city_id: 1293,
      region_id: 6,
      name_ar: 'الطرقة',
      name_en: 'At Taraqah',
    },
    {
      city_id: 1294,
      region_id: 6,
      name_ar: 'المجاردة',
      name_en: 'Al Majardah',
    },
    {
      city_id: 1295,
      region_id: 6,
      name_ar: 'الحمة',
      name_en: 'Al Himah',
    },
    {
      city_id: 1296,
      region_id: 6,
      name_ar: 'العقيقة',
      name_en: 'Al Aqiqah',
    },
    {
      city_id: 1297,
      region_id: 6,
      name_ar: 'بارق',
      name_en: 'Bariq',
    },
    {
      city_id: 1298,
      region_id: 6,
      name_ar: 'الهرير الشرقي - الجازي',
      name_en: 'Al Hurayr Ash Sharqi (Al Jazi)',
    },
    {
      city_id: 1299,
      region_id: 6,
      name_ar: 'طيب الإسم',
      name_en: 'Tayyib Al Ism',
    },
    {
      city_id: 1300,
      region_id: 6,
      name_ar: 'العمارة',
      name_en: "Al 'Ammarah",
    },
    {
      city_id: 1301,
      region_id: 6,
      name_ar: 'بيشة',
      name_en: 'Bishah',
    },
    {
      city_id: 1302,
      region_id: 6,
      name_ar: 'المزارقة',
      name_en: 'Al Mazarqah',
    },
    {
      city_id: 1303,
      region_id: 6,
      name_ar: 'آل غيثان',
      name_en: 'Al Ghaythan',
    },
    {
      city_id: 1304,
      region_id: 6,
      name_ar: 'آل الشريفي',
      name_en: 'Al Ash Shirayfi',
    },
    {
      city_id: 1305,
      region_id: 6,
      name_ar: 'آل التوم',
      name_en: 'Al At Tum',
    },
    {
      city_id: 1306,
      region_id: 6,
      name_ar: 'آل سويد',
      name_en: 'Al Suwayd',
    },
    {
      city_id: 1307,
      region_id: 6,
      name_ar: 'الصمدة',
      name_en: 'As Simidah',
    },
    {
      city_id: 1308,
      region_id: 6,
      name_ar: 'الفيض',
      name_en: 'Al Fayd',
    },
    {
      city_id: 1309,
      region_id: 6,
      name_ar: 'آل مريح',
      name_en: 'Al Mirayyih',
    },
    {
      city_id: 1310,
      region_id: 6,
      name_ar: 'صفوان',
      name_en: 'Safwan',
    },
    {
      city_id: 1311,
      region_id: 6,
      name_ar: 'الرونة',
      name_en: 'Ar Runah',
    },
    {
      city_id: 1312,
      region_id: 6,
      name_ar: 'آل الذيب',
      name_en: 'Al Adh Dhib',
    },
    {
      city_id: 1313,
      region_id: 6,
      name_ar: 'روضان',
      name_en: 'Rawdan',
    },
    {
      city_id: 1314,
      region_id: 4,
      name_ar: 'فيضة الشعب',
      name_en: "Faydah Ash Shi'b",
    },
    {
      city_id: 1315,
      region_id: 2,
      name_ar: 'حداء',
      name_en: "Hada'",
    },
    {
      city_id: 1316,
      region_id: 2,
      name_ar: 'المقايث',
      name_en: 'Al Maqayth',
    },
    {
      city_id: 1317,
      region_id: 2,
      name_ar: 'القرينة / هداة الشام',
      name_en: 'Al Qurainah / Hadat Ash Sham',
    },
    {
      city_id: 1318,
      region_id: 2,
      name_ar: 'القعضبة',
      name_en: "Al Qi'dubah",
    },
    {
      city_id: 1319,
      region_id: 2,
      name_ar: 'الزلال',
      name_en: 'Az Zallal',
    },
    {
      city_id: 1320,
      region_id: 2,
      name_ar: 'الرويغة',
      name_en: 'Ar Ruwayghag',
    },
    {
      city_id: 1321,
      region_id: 2,
      name_ar: 'العصب',
      name_en: "Al 'Asb",
    },
    {
      city_id: 1322,
      region_id: 2,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 1323,
      region_id: 2,
      name_ar: 'المعلاة',
      name_en: "Al Ma'lah",
    },
    {
      city_id: 1324,
      region_id: 2,
      name_ar: 'الجرف',
      name_en: 'Al Jarf',
    },
    {
      city_id: 1325,
      region_id: 2,
      name_ar: 'الجيشي',
      name_en: 'Al Jayshi',
    },
    {
      city_id: 1326,
      region_id: 2,
      name_ar: 'الزريب',
      name_en: 'Az Zurayb',
    },
    {
      city_id: 1327,
      region_id: 2,
      name_ar: 'البناية',
      name_en: 'Al Binayah',
    },
    {
      city_id: 1328,
      region_id: 2,
      name_ar: 'فيدة',
      name_en: 'Faydah',
    },
    {
      city_id: 1329,
      region_id: 2,
      name_ar: 'الشامية',
      name_en: 'Ash Shamiyah',
    },
    {
      city_id: 1330,
      region_id: 2,
      name_ar: 'المقيطيع',
      name_en: "Al Muqayti'",
    },
    {
      city_id: 1331,
      region_id: 2,
      name_ar: 'المقايتة',
      name_en: 'Al Maqaytah',
    },
    {
      city_id: 1332,
      region_id: 2,
      name_ar: 'المقر',
      name_en: 'Al Miqarr',
    },
    {
      city_id: 1333,
      region_id: 2,
      name_ar: 'ابو حصانية',
      name_en: 'Abu Hasaniyah',
    },
    {
      city_id: 1334,
      region_id: 2,
      name_ar: 'النوارية',
      name_en: 'An Nawariyah',
    },
    {
      city_id: 1335,
      region_id: 2,
      name_ar: 'الخيف',
      name_en: 'Al Khayf',
    },
    {
      city_id: 1336,
      region_id: 2,
      name_ar: 'الضرس',
      name_en: 'Adh Dhars',
    },
    {
      city_id: 1337,
      region_id: 2,
      name_ar: 'القرية',
      name_en: 'Al Qurayyah',
    },
    {
      city_id: 1338,
      region_id: 2,
      name_ar: 'مشجي',
      name_en: 'Mushaji',
    },
    {
      city_id: 1339,
      region_id: 2,
      name_ar: 'الخلاصة',
      name_en: 'Al Khulasah',
    },
    {
      city_id: 1340,
      region_id: 2,
      name_ar: 'القاحة',
      name_en: 'Al Qahah',
    },
    {
      city_id: 1341,
      region_id: 2,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 1342,
      region_id: 2,
      name_ar: 'عسفان',
      name_en: "'Isfan",
    },
    {
      city_id: 1343,
      region_id: 2,
      name_ar: 'المجمعة',
      name_en: "Al Majma'ah",
    },
    {
      city_id: 1344,
      region_id: 2,
      name_ar: 'مدركة',
      name_en: 'Madrakah',
    },
    {
      city_id: 1345,
      region_id: 2,
      name_ar: 'عين شمس',
      name_en: "'Ayn Shams",
    },
    {
      city_id: 1346,
      region_id: 2,
      name_ar: 'الريان',
      name_en: 'Ar Rayyan',
    },
    {
      city_id: 1347,
      region_id: 1,
      name_ar: 'جرير',
      name_en: 'Jarir',
    },
    {
      city_id: 1348,
      region_id: 1,
      name_ar: 'الفائزية',
      name_en: 'Al Faiziyah',
    },
    {
      city_id: 1349,
      region_id: 1,
      name_ar: 'المصارير',
      name_en: 'Al Masarir',
    },
    {
      city_id: 1350,
      region_id: 1,
      name_ar: 'النويعمة',
      name_en: "An Nuway'mah",
    },
    {
      city_id: 1351,
      region_id: 1,
      name_ar: 'وادي الدواسر',
      name_en: 'Wadi Ad Dawasir',
    },
    {
      city_id: 1352,
      region_id: 1,
      name_ar: 'ابرق النعام',
      name_en: "Abraq An Na 'Am",
    },
    {
      city_id: 1353,
      region_id: 1,
      name_ar: 'كمدة',
      name_en: 'Kamdah',
    },
    {
      city_id: 1354,
      region_id: 1,
      name_ar: 'الفاو',
      name_en: 'Al Faw',
    },
    {
      city_id: 1355,
      region_id: 1,
      name_ar: 'حرجة الزفر',
      name_en: 'Hirjat Az Zufur',
    },
    {
      city_id: 1356,
      region_id: 1,
      name_ar: 'حجلة السواد',
      name_en: 'Hajlat As Suwad',
    },
    {
      city_id: 1357,
      region_id: 1,
      name_ar: 'الكواكب',
      name_en: 'Al Kawakib',
    },
    {
      city_id: 1358,
      region_id: 1,
      name_ar: 'الدلعبي',
      name_en: "Ad Dhala'aby",
    },
    {
      city_id: 1359,
      region_id: 1,
      name_ar: 'بني لبيب',
      name_en: 'Bani Labib',
    },
    {
      city_id: 1360,
      region_id: 1,
      name_ar: 'خشم البازوم',
      name_en: 'Khashm Al Bazum',
    },
    {
      city_id: 1361,
      region_id: 1,
      name_ar: 'السليل',
      name_en: 'As Sulayyil',
    },
    {
      city_id: 1362,
      region_id: 1,
      name_ar: 'خيران',
      name_en: 'Khayran',
    },
    {
      city_id: 1363,
      region_id: 1,
      name_ar: 'تمرة',
      name_en: 'Tamrah',
    },
    {
      city_id: 1364,
      region_id: 1,
      name_ar: 'حمام',
      name_en: 'Himam',
    },
    {
      city_id: 1365,
      region_id: 1,
      name_ar: 'ريدا',
      name_en: 'Rayda',
    },
    {
      city_id: 1366,
      region_id: 2,
      name_ar: 'الوسقة',
      name_en: 'Al Wasqah',
    },
    {
      city_id: 1367,
      region_id: 2,
      name_ar: 'المدرج',
      name_en: 'Al Midrij',
    },
    {
      city_id: 1368,
      region_id: 12,
      name_ar: 'الحدبة',
      name_en: 'Al Hadabah',
    },
    {
      city_id: 1369,
      region_id: 2,
      name_ar: 'الخصرة',
      name_en: 'Al Khasirah',
    },
    {
      city_id: 1370,
      region_id: 2,
      name_ar: 'عوية',
      name_en: "'Uwayyah",
    },
    {
      city_id: 1371,
      region_id: 2,
      name_ar: 'المشاش',
      name_en: 'Al Mishash',
    },
    {
      city_id: 1372,
      region_id: 6,
      name_ar: 'روام',
      name_en: 'Rwam',
    },
    {
      city_id: 1373,
      region_id: 2,
      name_ar: 'الحصاحص',
      name_en: 'Al Hasahis',
    },
    {
      city_id: 1374,
      region_id: 2,
      name_ar: 'المدير',
      name_en: 'Al Mudir',
    },
    {
      city_id: 1375,
      region_id: 2,
      name_ar: 'ذي رهجان',
      name_en: 'Dhi Rahjan',
    },
    {
      city_id: 1376,
      region_id: 2,
      name_ar: 'اللغيسيم',
      name_en: 'Al Lughaysim',
    },
    {
      city_id: 1377,
      region_id: 2,
      name_ar: 'الوقرين',
      name_en: 'Al Waqrayn',
    },
    {
      city_id: 1378,
      region_id: 2,
      name_ar: 'الصواملة',
      name_en: 'As Suwamilah',
    },
    {
      city_id: 1379,
      region_id: 2,
      name_ar: 'الصدية',
      name_en: 'As Sadiyah',
    },
    {
      city_id: 1380,
      region_id: 2,
      name_ar: 'سوق الجمعة',
      name_en: "Suq Al Jum'ah",
    },
    {
      city_id: 1381,
      region_id: 12,
      name_ar: 'مسعدة',
      name_en: "Masa'dah",
    },
    {
      city_id: 1382,
      region_id: 12,
      name_ar: 'ظهي',
      name_en: 'Dhaha',
    },
    {
      city_id: 1383,
      region_id: 2,
      name_ar: 'سلم الزواهرة',
      name_en: 'Salam Az Zuwahirah',
    },
    {
      city_id: 1384,
      region_id: 2,
      name_ar: 'أم الشوك',
      name_en: 'Umm Ash Shawk',
    },
    {
      city_id: 1385,
      region_id: 12,
      name_ar: 'الحريقة',
      name_en: 'Al Hariqah',
    },
    {
      city_id: 1386,
      region_id: 2,
      name_ar: 'الحبقة',
      name_en: 'Al Habqah',
    },
    {
      city_id: 1387,
      region_id: 12,
      name_ar: 'كرش',
      name_en: 'Karsh',
    },
    {
      city_id: 1388,
      region_id: 2,
      name_ar: 'مفتاح',
      name_en: 'Miftah',
    },
    {
      city_id: 1389,
      region_id: 2,
      name_ar: 'الكردم',
      name_en: 'Al Kurdum',
    },
    {
      city_id: 1390,
      region_id: 2,
      name_ar: 'الليث',
      name_en: 'Al Lith',
    },
    {
      city_id: 1391,
      region_id: 2,
      name_ar: 'الجائزة',
      name_en: 'Al Jaizah',
    },
    {
      city_id: 1392,
      region_id: 2,
      name_ar: 'قاعس',
      name_en: "Qa'is",
    },
    {
      city_id: 1393,
      region_id: 2,
      name_ar: 'المضحاة',
      name_en: 'Al Madhah',
    },
    {
      city_id: 1394,
      region_id: 2,
      name_ar: 'الميثاء',
      name_en: 'Al Maytha',
    },
    {
      city_id: 1395,
      region_id: 2,
      name_ar: 'الغرات',
      name_en: 'Al Ghurrat',
    },
    {
      city_id: 1396,
      region_id: 2,
      name_ar: 'صلعة',
      name_en: "Sala'ah",
    },
    {
      city_id: 1397,
      region_id: 2,
      name_ar: 'ضبيعة',
      name_en: "Dubay'ah",
    },
    {
      city_id: 1398,
      region_id: 2,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 1399,
      region_id: 2,
      name_ar: 'الحلي',
      name_en: 'Al Hulay',
    },
    {
      city_id: 1400,
      region_id: 12,
      name_ar: 'القري',
      name_en: 'Al Quray',
    },
    {
      city_id: 1401,
      region_id: 2,
      name_ar: 'الرنيفة',
      name_en: 'Ar Runayfah',
    },
    {
      city_id: 1402,
      region_id: 2,
      name_ar: 'سوق اللصفة',
      name_en: 'Suq Al Lusafah',
    },
    {
      city_id: 1403,
      region_id: 2,
      name_ar: 'عيون حارة',
      name_en: 'Uyun Harrah',
    },
    {
      city_id: 1404,
      region_id: 2,
      name_ar: 'السوحة',
      name_en: 'As Siwahah',
    },
    {
      city_id: 1405,
      region_id: 2,
      name_ar: 'ربوع العين',
      name_en: "Rubu' Al Ayn",
    },
    {
      city_id: 1406,
      region_id: 2,
      name_ar: 'المجيرمة',
      name_en: 'Al Mijayrimah',
    },
    {
      city_id: 1407,
      region_id: 2,
      name_ar: 'السعدية',
      name_en: "As Sa'diyah",
    },
    {
      city_id: 1408,
      region_id: 2,
      name_ar: 'سوق ذرى',
      name_en: 'Suq Dhara',
    },
    {
      city_id: 1409,
      region_id: 2,
      name_ar: 'سوق ترعا',
      name_en: "Suq Tara'a",
    },
    {
      city_id: 1410,
      region_id: 2,
      name_ar: 'الرفغ',
      name_en: 'Ar Rafugh',
    },
    {
      city_id: 1411,
      region_id: 2,
      name_ar: 'قرضة',
      name_en: 'Qaradah',
    },
    {
      city_id: 1412,
      region_id: 2,
      name_ar: 'البرد',
      name_en: 'Al Barid',
    },
    {
      city_id: 1413,
      region_id: 2,
      name_ar: 'سوق بني يزيد',
      name_en: 'Suq Bani Yazid',
    },
    {
      city_id: 1414,
      region_id: 2,
      name_ar: 'حقال',
      name_en: 'Haqal',
    },
    {
      city_id: 1415,
      region_id: 2,
      name_ar: 'الساعي',
      name_en: "As Sa'i",
    },
    {
      city_id: 1416,
      region_id: 2,
      name_ar: 'ذنباء',
      name_en: 'Dhanaba',
    },
    {
      city_id: 1417,
      region_id: 2,
      name_ar: 'المقسبة',
      name_en: 'Al Maqsabah',
    },
    {
      city_id: 1418,
      region_id: 2,
      name_ar: 'شراقب',
      name_en: 'Sharaqib',
    },
    {
      city_id: 1419,
      region_id: 2,
      name_ar: 'شيباي',
      name_en: 'Shaybay',
    },
    {
      city_id: 1420,
      region_id: 2,
      name_ar: 'قفيلان',
      name_en: 'Qufaylan',
    },
    {
      city_id: 1421,
      region_id: 2,
      name_ar: 'الصالف',
      name_en: 'As Salif',
    },
    {
      city_id: 1422,
      region_id: 2,
      name_ar: 'صبح',
      name_en: 'Sabah',
    },
    {
      city_id: 1423,
      region_id: 2,
      name_ar: 'الغالة',
      name_en: 'Al Ghalah',
    },
    {
      city_id: 1424,
      region_id: 2,
      name_ar: 'غميقة',
      name_en: 'Ghumayqah',
    },
    {
      city_id: 1425,
      region_id: 12,
      name_ar: 'الحجرة',
      name_en: 'Al Hajrah',
    },
    {
      city_id: 1426,
      region_id: 2,
      name_ar: 'لملم',
      name_en: 'Lamlam',
    },
    {
      city_id: 1427,
      region_id: 12,
      name_ar: 'الجرين',
      name_en: 'Al Jarin',
    },
    {
      city_id: 1428,
      region_id: 2,
      name_ar: 'الشاقة / الصهوة',
      name_en: 'Al Shaqqah (As Shwah)',
    },
    {
      city_id: 1429,
      region_id: 6,
      name_ar: 'السفح',
      name_en: 'As Safah',
    },
    {
      city_id: 1430,
      region_id: 6,
      name_ar: 'حزم مفتاح',
      name_en: 'Hazam Miftah',
    },
    {
      city_id: 1431,
      region_id: 6,
      name_ar: 'جاش',
      name_en: 'Jash',
    },
    {
      city_id: 1432,
      region_id: 6,
      name_ar: 'روضة جاش',
      name_en: 'Rawdat Jash',
    },
    {
      city_id: 1433,
      region_id: 6,
      name_ar: 'الحرفين',
      name_en: 'Al Harfayn',
    },
    {
      city_id: 1434,
      region_id: 6,
      name_ar: 'لاعس',
      name_en: "La'is",
    },
    {
      city_id: 1435,
      region_id: 6,
      name_ar: 'مريغان',
      name_en: 'Murayghan',
    },
    {
      city_id: 1436,
      region_id: 6,
      name_ar: 'الدريب',
      name_en: 'Ad Dirayb',
    },
    {
      city_id: 1437,
      region_id: 6,
      name_ar: 'الجازع',
      name_en: "Al Jazi'",
    },
    {
      city_id: 1438,
      region_id: 6,
      name_ar: 'الفاخرية',
      name_en: 'Al Fakhriyah',
    },
    {
      city_id: 1439,
      region_id: 6,
      name_ar: 'كتنة',
      name_en: 'Kutnah',
    },
    {
      city_id: 1440,
      region_id: 6,
      name_ar: 'الجزيرة',
      name_en: 'Al Jazirah',
    },
    {
      city_id: 1441,
      region_id: 6,
      name_ar: 'الحنيك',
      name_en: 'Al Hanek',
    },
    {
      city_id: 1442,
      region_id: 6,
      name_ar: 'مريخة',
      name_en: 'Muraykhah',
    },
    {
      city_id: 1443,
      region_id: 6,
      name_ar: 'تثليث',
      name_en: 'Tathlith',
    },
    {
      city_id: 1444,
      region_id: 6,
      name_ar: 'ال سويدان',
      name_en: 'Al Suwaydan',
    },
    {
      city_id: 1445,
      region_id: 6,
      name_ar: 'ال عيفان',
      name_en: 'Al Ayfan',
    },
    {
      city_id: 1446,
      region_id: 6,
      name_ar: 'ال خميسة',
      name_en: 'Al Khumaysah',
    },
    {
      city_id: 1447,
      region_id: 6,
      name_ar: 'القيرة',
      name_en: 'Al Qirah',
    },
    {
      city_id: 1448,
      region_id: 6,
      name_ar: 'الحمضة',
      name_en: 'Al Hamdah',
    },
    {
      city_id: 1449,
      region_id: 6,
      name_ar: 'الزرق',
      name_en: 'Az Zurq',
    },
    {
      city_id: 1450,
      region_id: 6,
      name_ar: 'حبية',
      name_en: 'Habiyah',
    },
    {
      city_id: 1451,
      region_id: 6,
      name_ar: 'رغوان',
      name_en: 'Rughwan',
    },
    {
      city_id: 1452,
      region_id: 6,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 1453,
      region_id: 6,
      name_ar: 'الامواه',
      name_en: 'Al Amwah',
    },
    {
      city_id: 1454,
      region_id: 6,
      name_ar: 'الصبيخة',
      name_en: 'As Subaykhah',
    },
    {
      city_id: 1455,
      region_id: 12,
      name_ar: 'الإشتاء',
      name_en: 'Al Ishta',
    },
    {
      city_id: 1456,
      region_id: 12,
      name_ar: 'المكاتيم',
      name_en: 'Al Makatim',
    },
    {
      city_id: 1457,
      region_id: 12,
      name_ar: 'الحكمان',
      name_en: 'Al Hukman',
    },
    {
      city_id: 1458,
      region_id: 12,
      name_ar: 'الكلبة',
      name_en: 'Al Kalibah',
    },
    {
      city_id: 1459,
      region_id: 12,
      name_ar: 'الثراوين',
      name_en: 'Ath Tharawin',
    },
    {
      city_id: 1460,
      region_id: 12,
      name_ar: 'قريش الحسن',
      name_en: 'Quraysh Al Hasan',
    },
    {
      city_id: 1461,
      region_id: 12,
      name_ar: 'الضبيعات',
      name_en: "Ad Dubay'at",
    },
    {
      city_id: 1462,
      region_id: 12,
      name_ar: 'وادي بطحان',
      name_en: 'Wadi Bathan',
    },
    {
      city_id: 1463,
      region_id: 12,
      name_ar: 'الكرادسة',
      name_en: 'Al Karadisah',
    },
    {
      city_id: 1464,
      region_id: 12,
      name_ar: 'سبيحة العليا',
      name_en: "Sabihah Al 'Ulya",
    },
    {
      city_id: 1465,
      region_id: 12,
      name_ar: 'القرى',
      name_en: 'Al Qara',
    },
    {
      city_id: 1466,
      region_id: 12,
      name_ar: 'الاطاولة',
      name_en: 'Al Atawlah',
    },
    {
      city_id: 1467,
      region_id: 12,
      name_ar: 'بيدة',
      name_en: 'Baydah',
    },
    {
      city_id: 1468,
      region_id: 12,
      name_ar: 'القسمة',
      name_en: 'Al Qisamah',
    },
    {
      city_id: 1469,
      region_id: 12,
      name_ar: 'نخال',
      name_en: 'Nikhal',
    },
    {
      city_id: 1470,
      region_id: 6,
      name_ar: 'الخليج',
      name_en: 'Al Khalij',
    },
    {
      city_id: 1471,
      region_id: 6,
      name_ar: 'الصبيحي',
      name_en: 'As Sabihi',
    },
    {
      city_id: 1472,
      region_id: 6,
      name_ar: 'المدراء',
      name_en: 'Al Madra',
    },
    {
      city_id: 1473,
      region_id: 6,
      name_ar: 'الباقرة',
      name_en: 'Al Baqirah',
    },
    {
      city_id: 1474,
      region_id: 6,
      name_ar: 'نمران',
      name_en: 'Namran',
    },
    {
      city_id: 1475,
      region_id: 6,
      name_ar: 'شديق',
      name_en: 'Shudayq',
    },
    {
      city_id: 1476,
      region_id: 6,
      name_ar: 'الحرف',
      name_en: 'Al Harf',
    },
    {
      city_id: 1477,
      region_id: 6,
      name_ar: 'العطف',
      name_en: "Al 'Atf",
    },
    {
      city_id: 1478,
      region_id: 6,
      name_ar: 'الدحو',
      name_en: 'Ad Duhu',
    },
    {
      city_id: 1479,
      region_id: 6,
      name_ar: 'الحريرة',
      name_en: 'Al Hurayrah',
    },
    {
      city_id: 1480,
      region_id: 6,
      name_ar: 'الشقيقة',
      name_en: 'Ash Shaqiqah',
    },
    {
      city_id: 1481,
      region_id: 6,
      name_ar: 'النقيع',
      name_en: "An Naqi'",
    },
    {
      city_id: 1482,
      region_id: 6,
      name_ar: 'الرفائع',
      name_en: "Ar Rafai'",
    },
    {
      city_id: 1483,
      region_id: 6,
      name_ar: 'الجنينة',
      name_en: 'Al Junaynah',
    },
    {
      city_id: 1484,
      region_id: 6,
      name_ar: 'عيدان',
      name_en: 'Idan',
    },
    {
      city_id: 1485,
      region_id: 6,
      name_ar: 'أم الفروغ',
      name_en: 'Umm Al Furugh',
    },
    {
      city_id: 1486,
      region_id: 6,
      name_ar: 'عقيلية',
      name_en: "'Aqiliyah",
    },
    {
      city_id: 1487,
      region_id: 6,
      name_ar: 'بينة',
      name_en: 'Baynah',
    },
    {
      city_id: 1488,
      region_id: 6,
      name_ar: 'عرمان',
      name_en: "'Arman",
    },
    {
      city_id: 1489,
      region_id: 6,
      name_ar: 'القديحي',
      name_en: 'Al Qadihy',
    },
    {
      city_id: 1490,
      region_id: 6,
      name_ar: 'واعر',
      name_en: "Wa'ir",
    },
    {
      city_id: 1491,
      region_id: 6,
      name_ar: 'الشط',
      name_en: 'Ash Shatt',
    },
    {
      city_id: 1492,
      region_id: 6,
      name_ar: 'المروة',
      name_en: 'Al Marwah',
    },
    {
      city_id: 1493,
      region_id: 6,
      name_ar: 'المهامل',
      name_en: 'Al Mahamil',
    },
    {
      city_id: 1494,
      region_id: 6,
      name_ar: 'المجمعة',
      name_en: "Al Majma'ah",
    },
    {
      city_id: 1495,
      region_id: 6,
      name_ar: 'الحيفة',
      name_en: 'Al Hifah',
    },
    {
      city_id: 1496,
      region_id: 6,
      name_ar: 'مهر',
      name_en: 'Muhr',
    },
    {
      city_id: 1497,
      region_id: 6,
      name_ar: 'البهيم',
      name_en: 'Al Bahim',
    },
    {
      city_id: 1498,
      region_id: 6,
      name_ar: 'الغفرات',
      name_en: 'Al Ghafrat',
    },
    {
      city_id: 1499,
      region_id: 6,
      name_ar: 'المعدن',
      name_en: "Al Ma'din",
    },
    {
      city_id: 1500,
      region_id: 6,
      name_ar: 'القوز',
      name_en: 'Al Qawz',
    },
    {
      city_id: 1501,
      region_id: 6,
      name_ar: 'بادية',
      name_en: 'Badiyah',
    },
    {
      city_id: 1502,
      region_id: 6,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 1503,
      region_id: 6,
      name_ar: 'كتنة',
      name_en: 'Kutnah',
    },
    {
      city_id: 1504,
      region_id: 6,
      name_ar: 'مجحم',
      name_en: 'Mujahhim',
    },
    {
      city_id: 1505,
      region_id: 6,
      name_ar: 'الحجون',
      name_en: 'Al Hajun',
    },
    {
      city_id: 1506,
      region_id: 6,
      name_ar: 'صمخ',
      name_en: 'Samakh',
    },
    {
      city_id: 1507,
      region_id: 6,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 1508,
      region_id: 6,
      name_ar: 'الثنية',
      name_en: 'Ath Thaniyah',
    },
    {
      city_id: 1509,
      region_id: 6,
      name_ar: 'القوزية',
      name_en: 'Al Qawziyah',
    },
    {
      city_id: 1510,
      region_id: 6,
      name_ar: 'القوباء',
      name_en: 'Al Qawba',
    },
    {
      city_id: 1511,
      region_id: 6,
      name_ar: 'قطبة',
      name_en: 'Qutbah',
    },
    {
      city_id: 1512,
      region_id: 6,
      name_ar: 'دفرخرشان',
      name_en: 'Dafir Kharshan',
    },
    {
      city_id: 1513,
      region_id: 6,
      name_ar: 'جلال',
      name_en: 'Jalal',
    },
    {
      city_id: 1514,
      region_id: 6,
      name_ar: 'بيشة',
      name_en: 'Bishah',
    },
    {
      city_id: 1515,
      region_id: 6,
      name_ar: 'الديلمي',
      name_en: 'Ad Dailami',
    },
    {
      city_id: 1516,
      region_id: 6,
      name_ar: 'الرقيطاء',
      name_en: "Ar Ruqaita'",
    },
    {
      city_id: 1517,
      region_id: 6,
      name_ar: 'تبالة',
      name_en: 'Tabalah',
    },
    {
      city_id: 1518,
      region_id: 6,
      name_ar: 'الحازمي',
      name_en: 'Al Hazmi',
    },
    {
      city_id: 1519,
      region_id: 6,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 1520,
      region_id: 12,
      name_ar: 'الهضبة',
      name_en: 'Al Hadabah',
    },
    {
      city_id: 1521,
      region_id: 12,
      name_ar: 'الأبناء',
      name_en: 'Al Abna',
    },
    {
      city_id: 1522,
      region_id: 12,
      name_ar: 'بني جرة',
      name_en: 'Bani Jarrah',
    },
    {
      city_id: 1523,
      region_id: 12,
      name_ar: 'آل مرزوق',
      name_en: 'Al Marzuq',
    },
    {
      city_id: 1524,
      region_id: 12,
      name_ar: 'آل حميد',
      name_en: 'Al Himayd',
    },
    {
      city_id: 1525,
      region_id: 12,
      name_ar: 'جبر',
      name_en: 'Jabr',
    },
    {
      city_id: 1526,
      region_id: 12,
      name_ar: 'حوالة',
      name_en: 'Hawalah',
    },
    {
      city_id: 1527,
      region_id: 12,
      name_ar: 'الأزاهرة',
      name_en: 'Al Azahirah',
    },
    {
      city_id: 1528,
      region_id: 12,
      name_ar: 'دارالسوق',
      name_en: 'Dar As Suq',
    },
    {
      city_id: 1529,
      region_id: 12,
      name_ar: 'بني كبير',
      name_en: 'Bani Kabir',
    },
    {
      city_id: 1530,
      region_id: 12,
      name_ar: 'الحمران',
      name_en: 'Al Humran',
    },
    {
      city_id: 1531,
      region_id: 12,
      name_ar: 'بلجرشي',
      name_en: 'Biljurashi',
    },
    {
      city_id: 1533,
      region_id: 12,
      name_ar: 'بالشهم',
      name_en: 'Bal Shaham',
    },
    {
      city_id: 1534,
      region_id: 12,
      name_ar: 'بادية بني كبير',
      name_en: 'Badiyat Bani Kabir',
    },
    {
      city_id: 1535,
      region_id: 12,
      name_ar: 'بلعلاء',
      name_en: "Bal'ala",
    },
    {
      city_id: 1536,
      region_id: 12,
      name_ar: 'العفوص',
      name_en: "Al 'Ufus",
    },
    {
      city_id: 1537,
      region_id: 12,
      name_ar: 'خيرة',
      name_en: 'Khirah',
    },
    {
      city_id: 1538,
      region_id: 12,
      name_ar: 'قرن ظبي',
      name_en: 'Qarn Dhabi',
    },
    {
      city_id: 1539,
      region_id: 12,
      name_ar: 'شبرقة',
      name_en: 'Shibriqah',
    },
    {
      city_id: 1540,
      region_id: 12,
      name_ar: 'الغشامرة',
      name_en: 'Al Ghashamrah',
    },
    {
      city_id: 1541,
      region_id: 12,
      name_ar: 'العباس',
      name_en: "Al 'Abbas",
    },
    {
      city_id: 1542,
      region_id: 12,
      name_ar: 'الباحة',
      name_en: 'Bahah',
    },
    {
      city_id: 1543,
      region_id: 12,
      name_ar: 'رغدان',
      name_en: 'Raghdan',
    },
    {
      city_id: 1544,
      region_id: 12,
      name_ar: 'الظفير',
      name_en: 'Adh Dhafir',
    },
    {
      city_id: 1545,
      region_id: 12,
      name_ar: 'بني حسن',
      name_en: 'Bani Hasan',
    },
    {
      city_id: 1546,
      region_id: 12,
      name_ar: 'بيضان',
      name_en: 'Baydan',
    },
    {
      city_id: 1547,
      region_id: 12,
      name_ar: 'الصغرة',
      name_en: 'As Saghrah',
    },
    {
      city_id: 1548,
      region_id: 12,
      name_ar: 'بني ظبيان',
      name_en: 'Bani Dhabyan',
    },
    {
      city_id: 1549,
      region_id: 6,
      name_ar: 'الشقيق',
      name_en: 'Ash Shaqiq',
    },
    {
      city_id: 1550,
      region_id: 6,
      name_ar: 'البظاظة',
      name_en: 'Al Badhdhadhah',
    },
    {
      city_id: 1551,
      region_id: 6,
      name_ar: 'الحرجة',
      name_en: 'Al Harajah',
    },
    {
      city_id: 1552,
      region_id: 6,
      name_ar: 'آل يزيد',
      name_en: 'Al Yazid',
    },
    {
      city_id: 1553,
      region_id: 6,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 1554,
      region_id: 6,
      name_ar: 'الفرع',
      name_en: "Al Far'",
    },
    {
      city_id: 1555,
      region_id: 6,
      name_ar: 'البلس',
      name_en: 'Al Balas',
    },
    {
      city_id: 1556,
      region_id: 6,
      name_ar: 'العظة',
      name_en: "Al 'Idhah",
    },
    {
      city_id: 1557,
      region_id: 6,
      name_ar: 'الصرف',
      name_en: 'As Sarf',
    },
    {
      city_id: 1558,
      region_id: 6,
      name_ar: 'ثما',
      name_en: 'Thamma',
    },
    {
      city_id: 1559,
      region_id: 6,
      name_ar: 'العاسرة',
      name_en: "Al 'Asirah",
    },
    {
      city_id: 1560,
      region_id: 6,
      name_ar: 'حجاب',
      name_en: 'Hijab',
    },
    {
      city_id: 1561,
      region_id: 6,
      name_ar: 'الحصنة',
      name_en: 'Al Husanah',
    },
    {
      city_id: 1562,
      region_id: 6,
      name_ar: 'آل الزارية',
      name_en: 'Al Az Zariyah',
    },
    {
      city_id: 1563,
      region_id: 6,
      name_ar: 'الحصباء',
      name_en: 'Al Hasba',
    },
    {
      city_id: 1564,
      region_id: 6,
      name_ar: 'آل سلمة',
      name_en: 'Al Salamah',
    },
    {
      city_id: 1565,
      region_id: 6,
      name_ar: 'ال الشيخ',
      name_en: 'Al Ash Shaykh',
    },
    {
      city_id: 1566,
      region_id: 6,
      name_ar: 'البشائر',
      name_en: 'Al Bashair',
    },
    {
      city_id: 1567,
      region_id: 2,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 1568,
      region_id: 2,
      name_ar: 'أبو علي',
      name_en: "Abu 'Ali",
    },
    {
      city_id: 1569,
      region_id: 2,
      name_ar: 'العمودية',
      name_en: "Al 'Amudiyah",
    },
    {
      city_id: 1570,
      region_id: 6,
      name_ar: 'قرن بن ساهر',
      name_en: 'Qarn Bin Sahir',
    },
    {
      city_id: 1571,
      region_id: 2,
      name_ar: 'ثلوث عمارة',
      name_en: "Thuluth 'Imarah",
    },
    {
      city_id: 1572,
      region_id: 2,
      name_ar: 'حرف مبرة',
      name_en: 'Harf Mibrah',
    },
    {
      city_id: 1573,
      region_id: 2,
      name_ar: 'الفايجة',
      name_en: 'Al Fayijah',
    },
    {
      city_id: 1574,
      region_id: 2,
      name_ar: 'الحمضة',
      name_en: 'Al Hamdah',
    },
    {
      city_id: 1575,
      region_id: 2,
      name_ar: 'ناخسة',
      name_en: 'Nakhusah',
    },
    {
      city_id: 1576,
      region_id: 2,
      name_ar: 'نمرة',
      name_en: 'Numarah',
    },
    {
      city_id: 1577,
      region_id: 2,
      name_ar: 'القزعة',
      name_en: "Al Qaza'ah",
    },
    {
      city_id: 1578,
      region_id: 2,
      name_ar: 'الصحنة',
      name_en: 'As Sahanah',
    },
    {
      city_id: 1579,
      region_id: 2,
      name_ar: 'المعقص',
      name_en: "Al Mu'aqas",
    },
    {
      city_id: 1580,
      region_id: 2,
      name_ar: 'المروة',
      name_en: 'Al Marwah',
    },
    {
      city_id: 1581,
      region_id: 2,
      name_ar: 'الصفي',
      name_en: 'As Sufay',
    },
    {
      city_id: 1582,
      region_id: 2,
      name_ar: 'الشغز',
      name_en: 'Ash Shaghaz',
    },
    {
      city_id: 1583,
      region_id: 2,
      name_ar: 'القضب',
      name_en: 'Al Qadab',
    },
    {
      city_id: 1584,
      region_id: 2,
      name_ar: 'العجالين',
      name_en: "Al 'Ajalin",
    },
    {
      city_id: 1585,
      region_id: 2,
      name_ar: 'الشعب',
      name_en: "Ash Shi'b",
    },
    {
      city_id: 1586,
      region_id: 2,
      name_ar: 'الصلب',
      name_en: 'As Sulb',
    },
    {
      city_id: 1587,
      region_id: 2,
      name_ar: 'الفريق',
      name_en: 'Al Fariq',
    },
    {
      city_id: 1588,
      region_id: 2,
      name_ar: 'السلامة',
      name_en: 'As Salamah',
    },
    {
      city_id: 1589,
      region_id: 2,
      name_ar: 'الصهوة',
      name_en: 'As Sahwah',
    },
    {
      city_id: 1590,
      region_id: 2,
      name_ar: 'عنيكر',
      name_en: "'Unaykir",
    },
    {
      city_id: 1591,
      region_id: 2,
      name_ar: 'الكدسة',
      name_en: 'Al Kidsah',
    },
    {
      city_id: 1592,
      region_id: 2,
      name_ar: 'عجلان',
      name_en: 'Ajlan',
    },
    {
      city_id: 1593,
      region_id: 2,
      name_ar: 'القحمان',
      name_en: 'Al Quhman',
    },
    {
      city_id: 1594,
      region_id: 2,
      name_ar: 'العرجاء',
      name_en: "Al 'Arja",
    },
    {
      city_id: 1595,
      region_id: 2,
      name_ar: 'البريم',
      name_en: 'Al Buraym',
    },
    {
      city_id: 1596,
      region_id: 2,
      name_ar: 'الفرشة',
      name_en: 'Al Farshah',
    },
    {
      city_id: 1597,
      region_id: 2,
      name_ar: 'الزعاترة',
      name_en: "Az Za'atirah",
    },
    {
      city_id: 1598,
      region_id: 2,
      name_ar: 'العماير',
      name_en: "Al 'Amayir",
    },
    {
      city_id: 1599,
      region_id: 2,
      name_ar: 'السمرة',
      name_en: 'As Samrah',
    },
    {
      city_id: 1600,
      region_id: 2,
      name_ar: 'شعب سامر',
      name_en: "Sha'ib Samir",
    },
    {
      city_id: 1601,
      region_id: 2,
      name_ar: 'السادة',
      name_en: 'As Sadah',
    },
    {
      city_id: 1602,
      region_id: 2,
      name_ar: 'القحب',
      name_en: 'Al Qahb',
    },
    {
      city_id: 1603,
      region_id: 2,
      name_ar: 'بلاقة',
      name_en: 'Ballaqah',
    },
    {
      city_id: 1604,
      region_id: 2,
      name_ar: 'زبيد',
      name_en: 'Zubayd',
    },
    {
      city_id: 1605,
      region_id: 2,
      name_ar: 'مخشوش',
      name_en: 'Makhshush',
    },
    {
      city_id: 1606,
      region_id: 2,
      name_ar: 'العينة',
      name_en: "Al 'Aynah",
    },
    {
      city_id: 1607,
      region_id: 2,
      name_ar: 'خبت السبت',
      name_en: 'Khabt As Sabt',
    },
    {
      city_id: 1608,
      region_id: 2,
      name_ar: 'الفاهمة',
      name_en: 'Al Fahimah',
    },
    {
      city_id: 1609,
      region_id: 2,
      name_ar: 'ثريبان',
      name_en: 'Thurayban',
    },
    {
      city_id: 1610,
      region_id: 2,
      name_ar: 'المبنى',
      name_en: 'Al Mabna',
    },
    {
      city_id: 1611,
      region_id: 6,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 1612,
      region_id: 2,
      name_ar: 'الحذيفة',
      name_en: 'Al Hudhayfah',
    },
    {
      city_id: 1613,
      region_id: 2,
      name_ar: 'ناخس',
      name_en: 'Nakhis',
    },
    {
      city_id: 1614,
      region_id: 2,
      name_ar: 'الزبيري',
      name_en: 'Az Zubayri',
    },
    {
      city_id: 1615,
      region_id: 2,
      name_ar: 'غراب',
      name_en: 'Ghurab',
    },
    {
      city_id: 1616,
      region_id: 2,
      name_ar: 'السر',
      name_en: 'As Sirr',
    },
    {
      city_id: 1617,
      region_id: 6,
      name_ar: 'آل عامر',
      name_en: "Al 'Amir",
    },
    {
      city_id: 1618,
      region_id: 2,
      name_ar: 'النبيعة',
      name_en: "An Nubay'ah",
    },
    {
      city_id: 1619,
      region_id: 2,
      name_ar: 'الحرش',
      name_en: 'Al Harsh',
    },
    {
      city_id: 1620,
      region_id: 2,
      name_ar: 'الحدباء',
      name_en: 'Al Hadba',
    },
    {
      city_id: 1621,
      region_id: 2,
      name_ar: 'الصدرة',
      name_en: 'As Sudarah',
    },
    {
      city_id: 1622,
      region_id: 6,
      name_ar: 'الهدارة',
      name_en: 'Al Haddarah',
    },
    {
      city_id: 1623,
      region_id: 2,
      name_ar: 'الشرى',
      name_en: 'Ash Shara',
    },
    {
      city_id: 1624,
      region_id: 2,
      name_ar: 'الأنفة',
      name_en: 'Al Anafah',
    },
    {
      city_id: 1625,
      region_id: 2,
      name_ar: 'القنفذة',
      name_en: 'Al Qunfidhah',
    },
    {
      city_id: 1626,
      region_id: 6,
      name_ar: 'بلقرن',
      name_en: 'Balqarn',
    },
    {
      city_id: 1627,
      region_id: 6,
      name_ar: 'سبت العلاية',
      name_en: "Sabt Al 'Alayah",
    },
    {
      city_id: 1628,
      region_id: 2,
      name_ar: 'القوز',
      name_en: 'Al Qawz',
    },
    {
      city_id: 1629,
      region_id: 2,
      name_ar: 'كياد',
      name_en: 'Kiyad',
    },
    {
      city_id: 1630,
      region_id: 2,
      name_ar: 'الصفة',
      name_en: 'As Suffah',
    },
    {
      city_id: 1631,
      region_id: 2,
      name_ar: 'المظيلف',
      name_en: 'Al Mudhaylif',
    },
    {
      city_id: 1632,
      region_id: 2,
      name_ar: 'الحبيل',
      name_en: 'Al Habil',
    },
    {
      city_id: 1633,
      region_id: 2,
      name_ar: 'صبياء',
      name_en: "Sabya'",
    },
    {
      city_id: 1634,
      region_id: 6,
      name_ar: 'باشوت',
      name_en: 'Bashut',
    },
    {
      city_id: 1635,
      region_id: 2,
      name_ar: 'معشوقة',
      name_en: "Ma'shuqah",
    },
    {
      city_id: 1636,
      region_id: 2,
      name_ar: 'دوقة',
      name_en: 'Dawqah',
    },
    {
      city_id: 1637,
      region_id: 2,
      name_ar: 'سبت شمران',
      name_en: 'Sabt Shumran',
    },
    {
      city_id: 1638,
      region_id: 2,
      name_ar: 'احد بني زيد',
      name_en: 'Ahd Bani Zayd',
    },
    {
      city_id: 1639,
      region_id: 2,
      name_ar: 'سبت الجارة',
      name_en: 'Sabt Al Jarah',
    },
    {
      city_id: 1640,
      region_id: 6,
      name_ar: 'قدرين',
      name_en: 'Qudrayn',
    },
    {
      city_id: 1641,
      region_id: 6,
      name_ar: 'آل سفيان',
      name_en: 'Al Sufyan',
    },
    {
      city_id: 1642,
      region_id: 6,
      name_ar: 'البدلة',
      name_en: 'Al Badlah',
    },
    {
      city_id: 1643,
      region_id: 6,
      name_ar: 'مملح',
      name_en: 'Mamlah',
    },
    {
      city_id: 1644,
      region_id: 6,
      name_ar: 'آل جودة',
      name_en: 'Al Jawdah',
    },
    {
      city_id: 1645,
      region_id: 6,
      name_ar: 'بني زهير',
      name_en: 'Bani Zuhayr',
    },
    {
      city_id: 1646,
      region_id: 6,
      name_ar: 'الحدباء',
      name_en: 'Al Hadba',
    },
    {
      city_id: 1647,
      region_id: 6,
      name_ar: 'آل إثيبة',
      name_en: 'Al Ithibah',
    },
    {
      city_id: 1648,
      region_id: 6,
      name_ar: 'تلاء',
      name_en: 'Tala',
    },
    {
      city_id: 1649,
      region_id: 6,
      name_ar: 'القيسة',
      name_en: 'Al Qaysah',
    },
    {
      city_id: 1650,
      region_id: 6,
      name_ar: 'آل فارس',
      name_en: 'Al Faris',
    },
    {
      city_id: 1651,
      region_id: 6,
      name_ar: 'الذفراء',
      name_en: 'Adh Dhafra',
    },
    {
      city_id: 1652,
      region_id: 6,
      name_ar: 'بطحة',
      name_en: 'Bathah',
    },
    {
      city_id: 1653,
      region_id: 6,
      name_ar: 'بني قيس',
      name_en: 'Bani Qays',
    },
    {
      city_id: 1654,
      region_id: 6,
      name_ar: 'القضوى',
      name_en: 'Al Qadwa',
    },
    {
      city_id: 1655,
      region_id: 6,
      name_ar: 'الحميطة',
      name_en: 'Al Humayyitah',
    },
    {
      city_id: 1656,
      region_id: 6,
      name_ar: 'الرشداء',
      name_en: 'Ar Rashda',
    },
    {
      city_id: 1657,
      region_id: 6,
      name_ar: 'لبوة',
      name_en: 'Labwah',
    },
    {
      city_id: 1658,
      region_id: 6,
      name_ar: 'آل حضينان',
      name_en: 'Al Hidaynan',
    },
    {
      city_id: 1659,
      region_id: 6,
      name_ar: 'المجرا',
      name_en: 'Al Mujra',
    },
    {
      city_id: 1660,
      region_id: 6,
      name_ar: 'الخرب',
      name_en: 'Al Kharb',
    },
    {
      city_id: 1661,
      region_id: 6,
      name_ar: 'آل صقر',
      name_en: 'Al Saqr',
    },
    {
      city_id: 1662,
      region_id: 6,
      name_ar: 'جليلة',
      name_en: 'Jilayyilah',
    },
    {
      city_id: 1663,
      region_id: 6,
      name_ar: 'طعمة',
      name_en: "Ti'mah",
    },
    {
      city_id: 1664,
      region_id: 6,
      name_ar: 'المعاملة',
      name_en: "Al Mu'amalah",
    },
    {
      city_id: 1665,
      region_id: 6,
      name_ar: 'الحيمة',
      name_en: 'Al Haymah',
    },
    {
      city_id: 1666,
      region_id: 6,
      name_ar: 'البطنة',
      name_en: 'Al Batnah',
    },
    {
      city_id: 1667,
      region_id: 6,
      name_ar: 'بيش',
      name_en: 'Bish',
    },
    {
      city_id: 1668,
      region_id: 6,
      name_ar: 'الحيفة',
      name_en: 'Al Hifah',
    },
    {
      city_id: 1669,
      region_id: 6,
      name_ar: 'الطريف الأعلى',
      name_en: "At Turayf Al A'la",
    },
    {
      city_id: 1670,
      region_id: 6,
      name_ar: 'المضة',
      name_en: 'Al Maddah',
    },
    {
      city_id: 1671,
      region_id: 6,
      name_ar: 'عرقة',
      name_en: "'Irqah",
    },
    {
      city_id: 1672,
      region_id: 6,
      name_ar: 'ثفرة',
      name_en: 'Thafrah',
    },
    {
      city_id: 1673,
      region_id: 6,
      name_ar: 'فرعة قواء',
      name_en: "Far'at Quwa",
    },
    {
      city_id: 1674,
      region_id: 6,
      name_ar: 'البرقاء',
      name_en: 'Al Barqa',
    },
    {
      city_id: 1675,
      region_id: 6,
      name_ar: 'فرسان',
      name_en: 'Farasan',
    },
    {
      city_id: 1676,
      region_id: 6,
      name_ar: 'لسس',
      name_en: 'Lasas',
    },
    {
      city_id: 1677,
      region_id: 6,
      name_ar: 'القوز',
      name_en: 'Al Qawz',
    },
    {
      city_id: 1678,
      region_id: 6,
      name_ar: 'الصفا الملبد',
      name_en: 'As Safa Al Mulabad',
    },
    {
      city_id: 1679,
      region_id: 6,
      name_ar: 'الثعيلبة',
      name_en: "Ath Thu'aylibah",
    },
    {
      city_id: 1680,
      region_id: 6,
      name_ar: 'الطلحة',
      name_en: 'At Talhah',
    },
    {
      city_id: 1681,
      region_id: 6,
      name_ar: 'بادية',
      name_en: 'Badiyah',
    },
    {
      city_id: 1682,
      region_id: 6,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 1683,
      region_id: 6,
      name_ar: 'البغث',
      name_en: 'Al Baghath',
    },
    {
      city_id: 1684,
      region_id: 6,
      name_ar: 'المريفق',
      name_en: 'Al Murayfiq',
    },
    {
      city_id: 1685,
      region_id: 6,
      name_ar: 'الحرفين',
      name_en: 'Al Harfayn',
    },
    {
      city_id: 1686,
      region_id: 6,
      name_ar: 'القضة',
      name_en: 'Al Qaddah',
    },
    {
      city_id: 1687,
      region_id: 6,
      name_ar: 'الحامض',
      name_en: 'Al Hamid',
    },
    {
      city_id: 1688,
      region_id: 6,
      name_ar: 'الطلاح',
      name_en: 'At Tilah',
    },
    {
      city_id: 1689,
      region_id: 6,
      name_ar: 'واسط',
      name_en: 'Wasit',
    },
    {
      city_id: 1690,
      region_id: 6,
      name_ar: 'خشم شاع',
      name_en: "Khashm Sha'",
    },
    {
      city_id: 1691,
      region_id: 6,
      name_ar: 'الخضاير',
      name_en: 'Al Khadayir',
    },
    {
      city_id: 1692,
      region_id: 6,
      name_ar: 'مشحذ',
      name_en: 'Mashhidh',
    },
    {
      city_id: 1693,
      region_id: 6,
      name_ar: 'بريم السليل',
      name_en: 'Buraym As Silil',
    },
    {
      city_id: 1694,
      region_id: 6,
      name_ar: 'البردان',
      name_en: 'Al Burdan',
    },
    {
      city_id: 1695,
      region_id: 6,
      name_ar: 'رغوة',
      name_en: 'Raghwah',
    },
    {
      city_id: 1696,
      region_id: 6,
      name_ar: 'آل سعيد',
      name_en: "Al Sa'id",
    },
    {
      city_id: 1697,
      region_id: 6,
      name_ar: 'الغول',
      name_en: 'Al Ghawl',
    },
    {
      city_id: 1698,
      region_id: 6,
      name_ar: 'آل الهايلة',
      name_en: 'Al Al Hayilah',
    },
    {
      city_id: 1699,
      region_id: 6,
      name_ar: 'آل جرمان',
      name_en: 'Al Jarman',
    },
    {
      city_id: 1700,
      region_id: 6,
      name_ar: 'عرقة',
      name_en: "'Irqah",
    },
    {
      city_id: 1701,
      region_id: 6,
      name_ar: 'الحواويش',
      name_en: 'Al Hawawish',
    },
    {
      city_id: 1702,
      region_id: 6,
      name_ar: 'المري',
      name_en: 'Al Marri',
    },
    {
      city_id: 1703,
      region_id: 6,
      name_ar: 'آل شويل',
      name_en: 'Al Shuwayl',
    },
    {
      city_id: 1704,
      region_id: 6,
      name_ar: 'كدمان',
      name_en: 'Kidman',
    },
    {
      city_id: 1705,
      region_id: 6,
      name_ar: 'الصفية',
      name_en: 'As Sufayyah',
    },
    {
      city_id: 1706,
      region_id: 6,
      name_ar: 'إبن نجم',
      name_en: 'Ibn Nijam',
    },
    {
      city_id: 1707,
      region_id: 6,
      name_ar: 'المثناة',
      name_en: 'Al Mathnah',
    },
    {
      city_id: 1708,
      region_id: 5,
      name_ar: 'هدباء',
      name_en: "Hadba'",
    },
    {
      city_id: 1709,
      region_id: 6,
      name_ar: 'وادي ابن هشبل',
      name_en: 'Wadi Ibn Hashbal',
    },
    {
      city_id: 1710,
      region_id: 6,
      name_ar: 'ال بي ثور',
      name_en: 'Al Bi Thawr',
    },
    {
      city_id: 1711,
      region_id: 6,
      name_ar: 'السلع',
      name_en: 'As Sila',
    },
    {
      city_id: 1712,
      region_id: 6,
      name_ar: 'المضة',
      name_en: 'Al Maddah',
    },
    {
      city_id: 1713,
      region_id: 6,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 1714,
      region_id: 6,
      name_ar: 'خيبر الجنوب',
      name_en: 'Khaybar Al Janub',
    },
    {
      city_id: 1715,
      region_id: 6,
      name_ar: 'الحثدة',
      name_en: 'Al Hithadah',
    },
    {
      city_id: 1716,
      region_id: 6,
      name_ar: 'الباعق',
      name_en: "Al Ba'iq",
    },
    {
      city_id: 1717,
      region_id: 6,
      name_ar: 'العرض',
      name_en: "Al 'Ard",
    },
    {
      city_id: 1718,
      region_id: 6,
      name_ar: 'أم ضحي',
      name_en: 'Umm Dahi',
    },
    {
      city_id: 1719,
      region_id: 6,
      name_ar: 'آل ثوبان',
      name_en: 'Al Thawban',
    },
    {
      city_id: 1720,
      region_id: 6,
      name_ar: 'الحيلة',
      name_en: 'Al Haylah',
    },
    {
      city_id: 1721,
      region_id: 6,
      name_ar: 'مراتخ',
      name_en: 'Muratikh',
    },
    {
      city_id: 1722,
      region_id: 6,
      name_ar: 'أم ظهور الرفود',
      name_en: 'Umm Dhuhur Ar Rafud',
    },
    {
      city_id: 1723,
      region_id: 6,
      name_ar: 'مجزوعة',
      name_en: "Majzu'ah",
    },
    {
      city_id: 1724,
      region_id: 6,
      name_ar: 'القرونة',
      name_en: 'Al Qurunah',
    },
    {
      city_id: 1725,
      region_id: 6,
      name_ar: 'الريع',
      name_en: "Ar Ri'",
    },
    {
      city_id: 1726,
      region_id: 6,
      name_ar: 'قنيفد',
      name_en: 'Qinayfid',
    },
    {
      city_id: 1727,
      region_id: 6,
      name_ar: 'المربع',
      name_en: "Al Murabba'",
    },
    {
      city_id: 1728,
      region_id: 6,
      name_ar: 'الفاهمة',
      name_en: 'Al Fahimah',
    },
    {
      city_id: 1729,
      region_id: 6,
      name_ar: 'آل محصن',
      name_en: 'Al Muhsun',
    },
    {
      city_id: 1730,
      region_id: 6,
      name_ar: 'النصب',
      name_en: 'An Nusub',
    },
    {
      city_id: 1731,
      region_id: 6,
      name_ar: 'المسلم',
      name_en: 'Al Muslim',
    },
    {
      city_id: 1732,
      region_id: 6,
      name_ar: 'الريش',
      name_en: 'Ar Raysh',
    },
    {
      city_id: 1733,
      region_id: 6,
      name_ar: 'الحاجب',
      name_en: 'Al Hajib',
    },
    {
      city_id: 1734,
      region_id: 6,
      name_ar: 'آل علين',
      name_en: "Al 'Illin",
    },
    {
      city_id: 1735,
      region_id: 6,
      name_ar: 'الحضن',
      name_en: 'Al Hadan',
    },
    {
      city_id: 1736,
      region_id: 6,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 1737,
      region_id: 6,
      name_ar: 'المكثر',
      name_en: 'Al Mukaththir',
    },
    {
      city_id: 1738,
      region_id: 6,
      name_ar: 'البارك',
      name_en: 'Al Barik',
    },
    {
      city_id: 1739,
      region_id: 6,
      name_ar: 'المغمر',
      name_en: 'Al Maghmar',
    },
    {
      city_id: 1740,
      region_id: 6,
      name_ar: 'العيدة',
      name_en: "Al 'Idah",
    },
    {
      city_id: 1741,
      region_id: 6,
      name_ar: 'الحجف',
      name_en: 'Al Hajf',
    },
    {
      city_id: 1742,
      region_id: 6,
      name_ar: 'المقاعد',
      name_en: "Al Maqa'id",
    },
    {
      city_id: 1743,
      region_id: 6,
      name_ar: 'شعب دريب',
      name_en: "Shi'b Durayb",
    },
    {
      city_id: 1744,
      region_id: 6,
      name_ar: 'الكديد',
      name_en: 'Al Kadid',
    },
    {
      city_id: 1745,
      region_id: 6,
      name_ar: 'السحر',
      name_en: 'As Sahar',
    },
    {
      city_id: 1746,
      region_id: 6,
      name_ar: 'إبن عبيدي',
      name_en: "Ibn 'Abidi",
    },
    {
      city_id: 1747,
      region_id: 6,
      name_ar: 'الجبهة',
      name_en: 'Al Jabhah',
    },
    {
      city_id: 1748,
      region_id: 6,
      name_ar: 'الحرة',
      name_en: 'Al Harrah',
    },
    {
      city_id: 1749,
      region_id: 6,
      name_ar: 'معصم',
      name_en: "Ma'sam",
    },
    {
      city_id: 1750,
      region_id: 6,
      name_ar: 'الحدبات',
      name_en: 'Al Hadabat',
    },
    {
      city_id: 1751,
      region_id: 6,
      name_ar: 'المعشور',
      name_en: "Al Ma'shur",
    },
    {
      city_id: 1752,
      region_id: 6,
      name_ar: 'المسلمة',
      name_en: 'Al Maslamah',
    },
    {
      city_id: 1753,
      region_id: 6,
      name_ar: 'بين الجبلين',
      name_en: 'Bayn Al Jablayn',
    },
    {
      city_id: 1754,
      region_id: 6,
      name_ar: 'الحشف',
      name_en: 'Al Hashaf',
    },
    {
      city_id: 1755,
      region_id: 6,
      name_ar: 'ريمان',
      name_en: 'Rayman',
    },
    {
      city_id: 1756,
      region_id: 6,
      name_ar: 'الخليف',
      name_en: 'Al Khalif',
    },
    {
      city_id: 1757,
      region_id: 6,
      name_ar: 'الراحة',
      name_en: 'Ar Rahah',
    },
    {
      city_id: 1758,
      region_id: 6,
      name_ar: 'العفارة',
      name_en: "Al 'Afarah",
    },
    {
      city_id: 1759,
      region_id: 6,
      name_ar: 'البجيلي',
      name_en: 'Al Bajili',
    },
    {
      city_id: 1760,
      region_id: 6,
      name_ar: 'العقل',
      name_en: "Al 'Aql",
    },
    {
      city_id: 1761,
      region_id: 6,
      name_ar: 'المجمعة',
      name_en: "Al Majma'ah",
    },
    {
      city_id: 1762,
      region_id: 6,
      name_ar: 'سيال',
      name_en: 'Sayyal',
    },
    {
      city_id: 1763,
      region_id: 6,
      name_ar: 'آل معلوي',
      name_en: "Al Ma'lawi",
    },
    {
      city_id: 1764,
      region_id: 6,
      name_ar: 'الخطيم',
      name_en: 'Al Khutaym',
    },
    {
      city_id: 1765,
      region_id: 6,
      name_ar: 'الفي',
      name_en: 'Al Fayy',
    },
    {
      city_id: 1766,
      region_id: 6,
      name_ar: 'الجردان',
      name_en: 'Al Jardan',
    },
    {
      city_id: 1767,
      region_id: 6,
      name_ar: 'خارف',
      name_en: 'Kharif',
    },
    {
      city_id: 1768,
      region_id: 6,
      name_ar: 'الشرف',
      name_en: 'Ash Sharaf',
    },
    {
      city_id: 1769,
      region_id: 6,
      name_ar: 'سارة',
      name_en: 'Sarah',
    },
    {
      city_id: 1770,
      region_id: 6,
      name_ar: 'سقامة',
      name_en: 'Siqamah',
    },
    {
      city_id: 1771,
      region_id: 6,
      name_ar: 'الخبارة',
      name_en: 'Al Khabarah',
    },
    {
      city_id: 1772,
      region_id: 2,
      name_ar: 'غديقة',
      name_en: 'Ghudayqah',
    },
    {
      city_id: 1773,
      region_id: 2,
      name_ar: 'الخرجة',
      name_en: 'Al Kharjah',
    },
    {
      city_id: 1774,
      region_id: 2,
      name_ar: 'الدبانية',
      name_en: 'Ad Dubaniyah',
    },
    {
      city_id: 1775,
      region_id: 2,
      name_ar: 'الصبخة',
      name_en: 'As Sabkhah',
    },
    {
      city_id: 1776,
      region_id: 2,
      name_ar: 'صعبة',
      name_en: "Sa'bah",
    },
    {
      city_id: 1777,
      region_id: 2,
      name_ar: 'صور بن شكوان',
      name_en: 'Sur Bin Shakwan',
    },
    {
      city_id: 1778,
      region_id: 2,
      name_ar: 'الخشاشة',
      name_en: 'Al Khashashah',
    },
    {
      city_id: 1779,
      region_id: 2,
      name_ar: 'العوصاء',
      name_en: "Al 'Awsa",
    },
    {
      city_id: 1780,
      region_id: 2,
      name_ar: 'الخشة',
      name_en: 'Al Khashah',
    },
    {
      city_id: 1781,
      region_id: 2,
      name_ar: 'لغب',
      name_en: 'Laghab',
    },
    {
      city_id: 1782,
      region_id: 2,
      name_ar: 'الرحى',
      name_en: 'Ar Raha',
    },
    {
      city_id: 1783,
      region_id: 2,
      name_ar: 'حريزة',
      name_en: 'Harizah',
    },
    {
      city_id: 1784,
      region_id: 2,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 1785,
      region_id: 2,
      name_ar: 'الحورة',
      name_en: 'Al Hawrah',
    },
    {
      city_id: 1786,
      region_id: 2,
      name_ar: 'رضوان',
      name_en: 'Radwan',
    },
    {
      city_id: 1787,
      region_id: 6,
      name_ar: 'حبث',
      name_en: 'Habath',
    },
    {
      city_id: 1788,
      region_id: 6,
      name_ar: 'آل ناهية',
      name_en: 'Al Nahiyah',
    },
    {
      city_id: 1789,
      region_id: 6,
      name_ar: 'آل الذيب',
      name_en: 'Al Adh Dhib',
    },
    {
      city_id: 1790,
      region_id: 6,
      name_ar: 'فتاح',
      name_en: 'Futah',
    },
    {
      city_id: 1791,
      region_id: 6,
      name_ar: 'مسلت',
      name_en: 'Maslat',
    },
    {
      city_id: 1792,
      region_id: 6,
      name_ar: 'آل شدادي',
      name_en: 'Al Shaddadi',
    },
    {
      city_id: 1793,
      region_id: 6,
      name_ar: 'آل عاصمي',
      name_en: "Al 'Asimi",
    },
    {
      city_id: 1794,
      region_id: 6,
      name_ar: 'ذي حصيمو',
      name_en: 'Dhi Husaymu',
    },
    {
      city_id: 1795,
      region_id: 6,
      name_ar: 'وسانب',
      name_en: 'Wusanib',
    },
    {
      city_id: 1796,
      region_id: 6,
      name_ar: 'الزهراء',
      name_en: 'Az Zaha',
    },
    {
      city_id: 1797,
      region_id: 6,
      name_ar: 'المسقوي',
      name_en: 'Al Masqawi',
    },
    {
      city_id: 1798,
      region_id: 6,
      name_ar: 'هدة',
      name_en: 'Hidah',
    },
    {
      city_id: 1799,
      region_id: 6,
      name_ar: 'الغال',
      name_en: 'Al Ghal',
    },
    {
      city_id: 1800,
      region_id: 6,
      name_ar: 'خميس مطير',
      name_en: 'Khamis Mutair',
    },
    {
      city_id: 1801,
      region_id: 6,
      name_ar: 'محايل',
      name_en: 'Muhayil',
    },
    {
      city_id: 1802,
      region_id: 6,
      name_ar: 'قنا البحر',
      name_en: 'Qana Al Bahr',
    },
    {
      city_id: 1803,
      region_id: 6,
      name_ar: 'خميس البحر',
      name_en: 'Khamis Al Bahr',
    },
    {
      city_id: 1804,
      region_id: 6,
      name_ar: 'قشيعة',
      name_en: "Qushay'ah",
    },
    {
      city_id: 1805,
      region_id: 6,
      name_ar: 'دالج',
      name_en: 'Dalaj',
    },
    {
      city_id: 1806,
      region_id: 6,
      name_ar: 'شط إبن جازعة',
      name_en: "Shatt Ibn Jazi'ah",
    },
    {
      city_id: 1807,
      region_id: 6,
      name_ar: 'منقش',
      name_en: 'Manqash',
    },
    {
      city_id: 1808,
      region_id: 6,
      name_ar: 'الدماغ',
      name_en: 'Ad Dimagh',
    },
    {
      city_id: 1809,
      region_id: 6,
      name_ar: 'ثلوث ريم',
      name_en: 'Thuluth Rim',
    },
    {
      city_id: 1810,
      region_id: 6,
      name_ar: 'الحضن',
      name_en: 'Al Hadan',
    },
    {
      city_id: 1811,
      region_id: 6,
      name_ar: 'الضاجع',
      name_en: "Ad Daji'",
    },
    {
      city_id: 1812,
      region_id: 6,
      name_ar: 'الرصعة',
      name_en: "Ar Ras'ah",
    },
    {
      city_id: 1813,
      region_id: 6,
      name_ar: 'سنومة',
      name_en: 'Sanumah',
    },
    {
      city_id: 1814,
      region_id: 6,
      name_ar: 'الجرف',
      name_en: 'Al Jarf',
    },
    {
      city_id: 1815,
      region_id: 6,
      name_ar: 'السرو',
      name_en: 'As Sarw',
    },
    {
      city_id: 1816,
      region_id: 6,
      name_ar: 'العشة',
      name_en: 'Al Ashah',
    },
    {
      city_id: 1817,
      region_id: 6,
      name_ar: 'آل مجم',
      name_en: 'Al Majam',
    },
    {
      city_id: 1818,
      region_id: 6,
      name_ar: 'الحصون',
      name_en: 'Al Husun',
    },
    {
      city_id: 1819,
      region_id: 6,
      name_ar: 'القارية',
      name_en: 'Al Qariyah',
    },
    {
      city_id: 1820,
      region_id: 6,
      name_ar: 'الذروة',
      name_en: 'Adh Dhurwah',
    },
    {
      city_id: 1821,
      region_id: 6,
      name_ar: 'الشعبين',
      name_en: "Ash Shi'bayn",
    },
    {
      city_id: 1822,
      region_id: 6,
      name_ar: 'آل عيسى',
      name_en: "Al 'Isa",
    },
    {
      city_id: 1823,
      region_id: 6,
      name_ar: 'مرجومة',
      name_en: 'Marjumah',
    },
    {
      city_id: 1824,
      region_id: 6,
      name_ar: 'بيغ',
      name_en: 'Bayyagh',
    },
    {
      city_id: 1825,
      region_id: 6,
      name_ar: 'الجزوة',
      name_en: 'Al Jazwah',
    },
    {
      city_id: 1826,
      region_id: 6,
      name_ar: 'فقوة',
      name_en: 'Faqwah',
    },
    {
      city_id: 1827,
      region_id: 6,
      name_ar: 'آل متعالي',
      name_en: "Al Mut'ali",
    },
    {
      city_id: 1828,
      region_id: 6,
      name_ar: 'دبلاء',
      name_en: 'Dabla',
    },
    {
      city_id: 1829,
      region_id: 6,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 1830,
      region_id: 6,
      name_ar: 'كلمة',
      name_en: 'Kalmah',
    },
    {
      city_id: 1831,
      region_id: 6,
      name_ar: 'آل مصم',
      name_en: 'Al Masam',
    },
    {
      city_id: 1832,
      region_id: 6,
      name_ar: 'يسراة',
      name_en: 'Yasrah',
    },
    {
      city_id: 1833,
      region_id: 6,
      name_ar: 'الأثل',
      name_en: 'Al Athl',
    },
    {
      city_id: 1834,
      region_id: 6,
      name_ar: 'لصناي',
      name_en: 'Lasnay',
    },
    {
      city_id: 1835,
      region_id: 6,
      name_ar: 'عكية',
      name_en: "'Ukyah",
    },
    {
      city_id: 1836,
      region_id: 6,
      name_ar: 'القرار',
      name_en: 'Al Qarar',
    },
    {
      city_id: 1837,
      region_id: 6,
      name_ar: 'الجرف',
      name_en: 'Al Jarf',
    },
    {
      city_id: 1838,
      region_id: 6,
      name_ar: 'فيشان',
      name_en: 'Fayshan',
    },
    {
      city_id: 1839,
      region_id: 6,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 1840,
      region_id: 6,
      name_ar: 'الراحة',
      name_en: 'Ar Rahah',
    },
    {
      city_id: 1841,
      region_id: 6,
      name_ar: 'اللصبة',
      name_en: 'Al Lasbah',
    },
    {
      city_id: 1842,
      region_id: 6,
      name_ar: 'جو جيبة',
      name_en: 'Jaww Jibah',
    },
    {
      city_id: 1843,
      region_id: 6,
      name_ar: 'لهد',
      name_en: 'Luhud',
    },
    {
      city_id: 1844,
      region_id: 6,
      name_ar: 'الهدانة',
      name_en: 'Al Hadanah',
    },
    {
      city_id: 1845,
      region_id: 6,
      name_ar: 'أبو ذراع',
      name_en: "Abu Dhira'",
    },
    {
      city_id: 1846,
      region_id: 6,
      name_ar: 'رجال المع',
      name_en: "Rijal Al Ma'",
    },
    {
      city_id: 1847,
      region_id: 6,
      name_ar: 'السودة',
      name_en: 'As Sudah',
    },
    {
      city_id: 1848,
      region_id: 6,
      name_ar: 'الحريضة',
      name_en: 'Al Huraydah',
    },
    {
      city_id: 1849,
      region_id: 6,
      name_ar: 'الخطوة',
      name_en: 'Al Khutwah',
    },
    {
      city_id: 1850,
      region_id: 6,
      name_ar: 'الحبيل',
      name_en: 'Al Habil',
    },
    {
      city_id: 1851,
      region_id: 6,
      name_ar: 'آل قفيع',
      name_en: "Al Qifay'",
    },
    {
      city_id: 1852,
      region_id: 6,
      name_ar: 'المضيق',
      name_en: 'Al Madiq',
    },
    {
      city_id: 1853,
      region_id: 6,
      name_ar: 'آل لغر',
      name_en: 'Al Laghir',
    },
    {
      city_id: 1854,
      region_id: 6,
      name_ar: 'الهضبة',
      name_en: 'Al Hadabah',
    },
    {
      city_id: 1855,
      region_id: 6,
      name_ar: 'آل دلهم',
      name_en: 'Al Dalham',
    },
    {
      city_id: 1856,
      region_id: 6,
      name_ar: 'عنقرة',
      name_en: "'Unqarah",
    },
    {
      city_id: 1857,
      region_id: 6,
      name_ar: 'آل لوط',
      name_en: 'Al Lut',
    },
    {
      city_id: 1858,
      region_id: 6,
      name_ar: 'آل مكر',
      name_en: 'Al Makir',
    },
    {
      city_id: 1859,
      region_id: 6,
      name_ar: 'آل عرق',
      name_en: "Al 'Irq",
    },
    {
      city_id: 1860,
      region_id: 6,
      name_ar: 'سرايم',
      name_en: 'Sarayim',
    },
    {
      city_id: 1861,
      region_id: 6,
      name_ar: 'لزمة',
      name_en: 'Lazmah',
    },
    {
      city_id: 1862,
      region_id: 6,
      name_ar: 'الدرب',
      name_en: 'Ad Darb',
    },
    {
      city_id: 1863,
      region_id: 6,
      name_ar: 'بني تميم',
      name_en: 'Bani Tamim',
    },
    {
      city_id: 1864,
      region_id: 6,
      name_ar: 'القرحاء',
      name_en: 'Al Qarha',
    },
    {
      city_id: 1865,
      region_id: 6,
      name_ar: 'آل عمرة',
      name_en: "Al 'Amrah",
    },
    {
      city_id: 1866,
      region_id: 6,
      name_ar: 'آل الداحس',
      name_en: 'Al Ad Dahis',
    },
    {
      city_id: 1867,
      region_id: 6,
      name_ar: 'آل زهير',
      name_en: 'Al Zuhayr',
    },
    {
      city_id: 1868,
      region_id: 6,
      name_ar: 'عراب',
      name_en: "'Irab",
    },
    {
      city_id: 1869,
      region_id: 6,
      name_ar: 'آل مشهور',
      name_en: 'Al Mashhur',
    },
    {
      city_id: 1870,
      region_id: 6,
      name_ar: 'آل عامر',
      name_en: "Al 'Amir",
    },
    {
      city_id: 1871,
      region_id: 6,
      name_ar: 'آل حديلة',
      name_en: 'Al Hidaylah',
    },
    {
      city_id: 1872,
      region_id: 6,
      name_ar: 'آل حلامي',
      name_en: 'Al Hilami',
    },
    {
      city_id: 1873,
      region_id: 6,
      name_ar: 'صفحان',
      name_en: 'Safhan',
    },
    {
      city_id: 1874,
      region_id: 5,
      name_ar: 'سامودة',
      name_en: 'Samudah',
    },
    {
      city_id: 1875,
      region_id: 2,
      name_ar: 'الغبة',
      name_en: 'Al Ghubbah',
    },
    {
      city_id: 1876,
      region_id: 2,
      name_ar: 'المحاميد',
      name_en: 'Al Mahamid',
    },
    {
      city_id: 1877,
      region_id: 2,
      name_ar: 'مسنى الازيار',
      name_en: "Masna Al 'Azyar",
    },
    {
      city_id: 1878,
      region_id: 2,
      name_ar: 'الحذيفة القالة',
      name_en: 'Al Hudhayfah Al Qalah',
    },
    {
      city_id: 1879,
      region_id: 2,
      name_ar: 'ثول',
      name_en: 'Thuwal',
    },
    {
      city_id: 1880,
      region_id: 2,
      name_ar: 'ام السلم',
      name_en: 'Umm As Salam',
    },
    {
      city_id: 1881,
      region_id: 3,
      name_ar: 'غضن علياء',
      name_en: 'Ghadn Aliya',
    },
    {
      city_id: 1882,
      region_id: 2,
      name_ar: 'بحرة المجاهدين',
      name_en: 'Bahrah Al Mujahidin',
    },
    {
      city_id: 1883,
      region_id: 2,
      name_ar: 'بحرة القديمة',
      name_en: 'Bahrah Al Qadimah',
    },
    {
      city_id: 1884,
      region_id: 2,
      name_ar: 'حداء الجديدة',
      name_en: "Hada' Al Jadidah",
    },
    {
      city_id: 1885,
      region_id: 2,
      name_ar: 'ذهبان',
      name_en: 'Dhahban',
    },
    {
      city_id: 1886,
      region_id: 2,
      name_ar: 'الغولاء',
      name_en: 'Al Ghawla',
    },
    {
      city_id: 1887,
      region_id: 2,
      name_ar: 'العوجانية',
      name_en: "Al 'Awjaniyah",
    },
    {
      city_id: 1888,
      region_id: 2,
      name_ar: 'الصفحة',
      name_en: 'As Safhah',
    },
    {
      city_id: 1889,
      region_id: 2,
      name_ar: 'القضيمة',
      name_en: 'Al Qadimah',
    },
    {
      city_id: 1890,
      region_id: 2,
      name_ar: 'ميناء القضيمة',
      name_en: 'Al Qadimah Port',
    },
    {
      city_id: 1891,
      region_id: 2,
      name_ar: 'المواليد',
      name_en: 'Al Mawalid',
    },
    {
      city_id: 1892,
      region_id: 12,
      name_ar: 'دغماء',
      name_en: 'Daghma',
    },
    {
      city_id: 1893,
      region_id: 2,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 1894,
      region_id: 2,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 1895,
      region_id: 12,
      name_ar: 'القهب',
      name_en: 'Al Qahab',
    },
    {
      city_id: 1896,
      region_id: 2,
      name_ar: 'الجحصة',
      name_en: 'Al Jahasah',
    },
    {
      city_id: 1897,
      region_id: 12,
      name_ar: 'الدقم',
      name_en: 'Ad Duqum',
    },
    {
      city_id: 1898,
      region_id: 12,
      name_ar: 'الشهومة',
      name_en: 'Ash Shuhumah',
    },
    {
      city_id: 1899,
      region_id: 2,
      name_ar: 'عوية',
      name_en: "'Uwayyah",
    },
    {
      city_id: 1900,
      region_id: 12,
      name_ar: 'سيحان',
      name_en: 'Sayhan',
    },
    {
      city_id: 1901,
      region_id: 12,
      name_ar: 'الحوية',
      name_en: 'Al Hawiyah',
    },
    {
      city_id: 1902,
      region_id: 12,
      name_ar: 'خميس',
      name_en: 'Humays',
    },
    {
      city_id: 1903,
      region_id: 12,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 1904,
      region_id: 2,
      name_ar: 'الاحلاف',
      name_en: 'Al Ahlaf',
    },
    {
      city_id: 1905,
      region_id: 2,
      name_ar: 'المويه القديم',
      name_en: 'Al Muwayh Al Qadim',
    },
    {
      city_id: 1906,
      region_id: 2,
      name_ar: 'البحرة',
      name_en: 'Al Baharah',
    },
    {
      city_id: 1907,
      region_id: 2,
      name_ar: 'البادرية',
      name_en: 'Al Badiriyah',
    },
    {
      city_id: 1908,
      region_id: 2,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 1909,
      region_id: 2,
      name_ar: 'الذويب',
      name_en: 'Adh Dhuwayb',
    },
    {
      city_id: 1910,
      region_id: 2,
      name_ar: 'مروان',
      name_en: 'Marwan',
    },
    {
      city_id: 1911,
      region_id: 2,
      name_ar: 'القرارة',
      name_en: 'Al Qararah',
    },
    {
      city_id: 1912,
      region_id: 2,
      name_ar: 'أم الدوم',
      name_en: 'Umm Ad Dawm',
    },
    {
      city_id: 1913,
      region_id: 2,
      name_ar: 'دغيبجة',
      name_en: 'Dughaybjah',
    },
    {
      city_id: 1914,
      region_id: 2,
      name_ar: 'الرميدة',
      name_en: 'Ar Rumaydah',
    },
    {
      city_id: 1915,
      region_id: 2,
      name_ar: 'خد الحاج',
      name_en: 'Khadd Al Haj',
    },
    {
      city_id: 1916,
      region_id: 2,
      name_ar: 'شويحط',
      name_en: 'Shuwayhit',
    },
    {
      city_id: 1917,
      region_id: 2,
      name_ar: 'وطيلح',
      name_en: 'Witaylih',
    },
    {
      city_id: 1918,
      region_id: 7,
      name_ar: 'العنبة',
      name_en: "Al 'Anabah",
    },
    {
      city_id: 1919,
      region_id: 2,
      name_ar: 'الخضر',
      name_en: 'Al Khudr',
    },
    {
      city_id: 1920,
      region_id: 2,
      name_ar: 'الودية',
      name_en: 'Al Wudiyah',
    },
    {
      city_id: 1921,
      region_id: 2,
      name_ar: 'الصالحية',
      name_en: 'As Salhiyah',
    },
    {
      city_id: 1922,
      region_id: 2,
      name_ar: 'المحاني',
      name_en: 'Al Mahani',
    },
    {
      city_id: 1923,
      region_id: 2,
      name_ar: 'الدار البيضاء',
      name_en: 'Ad Dar Al Bayda',
    },
    {
      city_id: 1924,
      region_id: 2,
      name_ar: 'النمور',
      name_en: 'An Namur',
    },
    {
      city_id: 1925,
      region_id: 2,
      name_ar: 'المبيرز',
      name_en: 'Al Mubayriz',
    },
    {
      city_id: 1926,
      region_id: 2,
      name_ar: 'ملح',
      name_en: 'Malah',
    },
    {
      city_id: 1927,
      region_id: 2,
      name_ar: 'الغديرين',
      name_en: 'Al Ghadirayn',
    },
    {
      city_id: 1928,
      region_id: 7,
      name_ar: 'الهامة',
      name_en: 'Al Hamah',
    },
    {
      city_id: 1929,
      region_id: 7,
      name_ar: 'بئر ابو العجاج',
      name_en: "Bir Abu Al 'Ajjaj",
    },
    {
      city_id: 1930,
      region_id: 7,
      name_ar: 'بئر السادي',
      name_en: 'Bir As Sadi',
    },
    {
      city_id: 1931,
      region_id: 7,
      name_ar: 'قلعة الاذنم',
      name_en: "Qal'at Al Adhnum",
    },
    {
      city_id: 1932,
      region_id: 7,
      name_ar: 'سلوى',
      name_en: 'Salwa',
    },
    {
      city_id: 1933,
      region_id: 7,
      name_ar: 'وادي ابو طينة',
      name_en: 'Wadi Abu Tinah',
    },
    {
      city_id: 1934,
      region_id: 7,
      name_ar: 'الصورة',
      name_en: 'As Surah',
    },
    {
      city_id: 1935,
      region_id: 7,
      name_ar: 'تريم',
      name_en: 'Tiraym',
    },
    {
      city_id: 1936,
      region_id: 7,
      name_ar: 'بئر فحيمان',
      name_en: 'Bir Fhayman',
    },
    {
      city_id: 1937,
      region_id: 7,
      name_ar: 'قاصرة',
      name_en: 'Qasrah',
    },
    {
      city_id: 1938,
      region_id: 7,
      name_ar: 'هداج',
      name_en: 'Haddaj',
    },
    {
      city_id: 1939,
      region_id: 7,
      name_ar: 'الحواويط',
      name_en: 'Al Hawawit',
    },
    {
      city_id: 1940,
      region_id: 7,
      name_ar: 'جبة',
      name_en: 'Jubbah',
    },
    {
      city_id: 1941,
      region_id: 7,
      name_ar: 'المهارش',
      name_en: 'Al Mharish',
    },
    {
      city_id: 1942,
      region_id: 7,
      name_ar: 'ضوجي',
      name_en: 'Duji',
    },
    {
      city_id: 1943,
      region_id: 7,
      name_ar: 'المرير',
      name_en: 'Al Murayr',
    },
    {
      city_id: 1944,
      region_id: 7,
      name_ar: 'شواق',
      name_en: 'Shuwaq',
    },
    {
      city_id: 1945,
      region_id: 7,
      name_ar: 'آمدان',
      name_en: 'Amdan',
    },
    {
      city_id: 1946,
      region_id: 7,
      name_ar: 'رواقا',
      name_en: 'Ruwaqa',
    },
    {
      city_id: 1947,
      region_id: 7,
      name_ar: 'ضبا',
      name_en: 'Duba',
    },
    {
      city_id: 1948,
      region_id: 7,
      name_ar: 'الخريبة',
      name_en: 'Al Khuraybah',
    },
    {
      city_id: 1949,
      region_id: 7,
      name_ar: 'صر',
      name_en: 'Surr',
    },
    {
      city_id: 1950,
      region_id: 7,
      name_ar: 'صدر',
      name_en: 'Sadr',
    },
    {
      city_id: 1951,
      region_id: 7,
      name_ar: 'نابع داما',
      name_en: "Nabi' Dama",
    },
    {
      city_id: 1952,
      region_id: 7,
      name_ar: 'قبقاب',
      name_en: 'Qabqab',
    },
    {
      city_id: 1953,
      region_id: 7,
      name_ar: 'شرماء',
      name_en: 'Sharma',
    },
    {
      city_id: 1954,
      region_id: 7,
      name_ar: 'شغب',
      name_en: 'Shaghab',
    },
    {
      city_id: 1955,
      region_id: 7,
      name_ar: 'المويلح',
      name_en: 'Al Muwaylih',
    },
    {
      city_id: 1956,
      region_id: 7,
      name_ar: 'الديسة',
      name_en: 'Ad Disah',
    },
    {
      city_id: 1957,
      region_id: 4,
      name_ar: 'السالمية',
      name_en: 'As Salmiyah',
    },
    {
      city_id: 1958,
      region_id: 4,
      name_ar: 'المريديسية',
      name_en: 'Al Muraydisiyah',
    },
    {
      city_id: 1959,
      region_id: 4,
      name_ar: 'الصفراء',
      name_en: 'As Safra',
    },
    {
      city_id: 1960,
      region_id: 4,
      name_ar: 'خب روضان',
      name_en: 'Khabb Rawdan',
    },
    {
      city_id: 1961,
      region_id: 4,
      name_ar: 'المنتزة',
      name_en: 'Al Muntazah',
    },
    {
      city_id: 1962,
      region_id: 4,
      name_ar: 'مشرفة',
      name_en: 'Mushrifah',
    },
    {
      city_id: 1963,
      region_id: 8,
      name_ar: 'فيد',
      name_en: 'Fayd',
    },
    {
      city_id: 1964,
      region_id: 8,
      name_ar: 'طابة',
      name_en: 'Tabah',
    },
    {
      city_id: 1965,
      region_id: 8,
      name_ar: 'الكهفة',
      name_en: 'Al Kahafah',
    },
    {
      city_id: 1966,
      region_id: 8,
      name_ar: 'عقلة بن داني',
      name_en: 'Uqlat Ibn Dani',
    },
    {
      city_id: 1967,
      region_id: 8,
      name_ar: 'عقلة اللبن',
      name_en: 'Uqlat Al Laban',
    },
    {
      city_id: 1968,
      region_id: 8,
      name_ar: 'الصفراء',
      name_en: 'As Safra',
    },
    {
      city_id: 1969,
      region_id: 8,
      name_ar: 'البير',
      name_en: 'Al Bir',
    },
    {
      city_id: 1970,
      region_id: 8,
      name_ar: 'عقلة ابن كليب',
      name_en: 'Uqlat Ibn Kulayb',
    },
    {
      city_id: 1971,
      region_id: 8,
      name_ar: 'عريجاء',
      name_en: "Uraija'",
    },
    {
      city_id: 1972,
      region_id: 8,
      name_ar: 'المطرفية',
      name_en: 'Al Matrafiyah',
    },
    {
      city_id: 1973,
      region_id: 8,
      name_ar: 'المضيح',
      name_en: 'Al Mudayyih',
    },
    {
      city_id: 1974,
      region_id: 8,
      name_ar: 'غمرة',
      name_en: 'Ghamrah',
    },
    {
      city_id: 1975,
      region_id: 8,
      name_ar: 'النعي',
      name_en: "An Ni'ayy",
    },
    {
      city_id: 1976,
      region_id: 8,
      name_ar: 'الجحفة',
      name_en: 'Al Juhfah',
    },
    {
      city_id: 1977,
      region_id: 8,
      name_ar: 'العش',
      name_en: "Al 'Ishsh",
    },
    {
      city_id: 1978,
      region_id: 8,
      name_ar: 'السبعان',
      name_en: "As Sab'an",
    },
    {
      city_id: 1979,
      region_id: 4,
      name_ar: 'قصر العبد الله',
      name_en: "Qasr Al 'Abdallah",
    },
    {
      city_id: 1980,
      region_id: 4,
      name_ar: 'مزارع الحفيرات',
      name_en: "Mazari' Al Hufayrat",
    },
    {
      city_id: 1981,
      region_id: 4,
      name_ar: 'الشنانة',
      name_en: 'Ash Shananah',
    },
    {
      city_id: 1982,
      region_id: 4,
      name_ar: 'السمراء',
      name_en: 'As Samra',
    },
    {
      city_id: 1983,
      region_id: 4,
      name_ar: 'العطشان',
      name_en: "Al 'Atshan",
    },
    {
      city_id: 1984,
      region_id: 4,
      name_ar: 'السيق',
      name_en: 'As Siq',
    },
    {
      city_id: 1985,
      region_id: 4,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 1986,
      region_id: 4,
      name_ar: 'الاسياح',
      name_en: 'Al Asyah',
    },
    {
      city_id: 1987,
      region_id: 4,
      name_ar: 'التنومة',
      name_en: 'At Tannumah',
    },
    {
      city_id: 1988,
      region_id: 4,
      name_ar: 'البرود',
      name_en: 'Al Burud',
    },
    {
      city_id: 1989,
      region_id: 4,
      name_ar: 'خصيبة',
      name_en: 'Khusayyibah',
    },
    {
      city_id: 1990,
      region_id: 4,
      name_ar: 'طريف الاسياح',
      name_en: 'Turayf Al Asyah',
    },
    {
      city_id: 1991,
      region_id: 4,
      name_ar: 'البندرية',
      name_en: 'Al Bandariyah',
    },
    {
      city_id: 1992,
      region_id: 4,
      name_ar: 'حنيظل',
      name_en: 'Hunaydhil',
    },
    {
      city_id: 1993,
      region_id: 4,
      name_ar: 'الجعلة',
      name_en: "Al Ja'alah",
    },
    {
      city_id: 1994,
      region_id: 4,
      name_ar: 'ابا الورود',
      name_en: 'Aba Al Wurood',
    },
    {
      city_id: 1995,
      region_id: 4,
      name_ar: 'ضيدة',
      name_en: 'Didah',
    },
    {
      city_id: 1996,
      region_id: 4,
      name_ar: 'النمرية',
      name_en: 'An Nimriyah',
    },
    {
      city_id: 1997,
      region_id: 4,
      name_ar: 'المطيوي الشمالي',
      name_en: 'Al Mutaywi Ash Shamali',
    },
    {
      city_id: 1998,
      region_id: 4,
      name_ar: 'المحلاني',
      name_en: 'Al Mahalani',
    },
    {
      city_id: 1999,
      region_id: 4,
      name_ar: 'عيون الجواء',
      name_en: "Uyun Al Jawa'",
    },
    {
      city_id: 2000,
      region_id: 4,
      name_ar: 'أوثال',
      name_en: 'Uthal',
    },
    {
      city_id: 2001,
      region_id: 4,
      name_ar: 'غاف الجواء',
      name_en: 'Ghaf Al Jiwa',
    },
    {
      city_id: 2002,
      region_id: 4,
      name_ar: 'روض الجواء',
      name_en: 'Rawd Al Jiwa',
    },
    {
      city_id: 2003,
      region_id: 5,
      name_ar: 'الشهارين',
      name_en: 'Ash Shaharin',
    },
    {
      city_id: 2005,
      region_id: 5,
      name_ar: 'أم العراد',
      name_en: "Umm Al 'Arad",
    },
    {
      city_id: 2006,
      region_id: 5,
      name_ar: 'العبيلة',
      name_en: "Al 'Ubaylah",
    },
    {
      city_id: 2007,
      region_id: 5,
      name_ar: 'شبيطة',
      name_en: 'Shubaytah',
    },
    {
      city_id: 2008,
      region_id: 5,
      name_ar: 'شوالة',
      name_en: 'Shawwalah',
    },
    {
      city_id: 2009,
      region_id: 5,
      name_ar: 'مركز الشيبة',
      name_en: 'Markaz Ash Shaybah',
    },
    {
      city_id: 2010,
      region_id: 5,
      name_ar: 'مركز الخيران',
      name_en: 'Markaz Al Khiran',
    },
    {
      city_id: 2011,
      region_id: 5,
      name_ar: 'هجرة الطويلة',
      name_en: 'Hijrat At Tawilah',
    },
    {
      city_id: 2012,
      region_id: 5,
      name_ar: 'عجائب',
      name_en: 'Ajaib',
    },
    {
      city_id: 2013,
      region_id: 5,
      name_ar: 'الطربيل',
      name_en: 'At Tarbil',
    },
    {
      city_id: 2014,
      region_id: 5,
      name_ar: 'التهيمية',
      name_en: 'At Tuhaymiyah',
    },
    {
      city_id: 2015,
      region_id: 5,
      name_ar: 'المقدام',
      name_en: 'Al Miqdam',
    },
    {
      city_id: 2016,
      region_id: 5,
      name_ar: 'السبايرة',
      name_en: 'As Sabayirah',
    },
    {
      city_id: 2017,
      region_id: 5,
      name_ar: 'السباط',
      name_en: 'As Sabat',
    },
    {
      city_id: 2018,
      region_id: 5,
      name_ar: 'الجرن',
      name_en: 'Al Jarn',
    },
    {
      city_id: 2019,
      region_id: 5,
      name_ar: 'برقاء  الركبان',
      name_en: 'Barqa Al Rukban',
    },
    {
      city_id: 2021,
      region_id: 7,
      name_ar: 'عمرات',
      name_en: "'Amrat",
    },
    {
      city_id: 2022,
      region_id: 5,
      name_ar: 'بطحاء',
      name_en: 'Batha',
    },
    {
      city_id: 2023,
      region_id: 5,
      name_ar: 'العديد',
      name_en: "Al 'Adid",
    },
    {
      city_id: 2024,
      region_id: 5,
      name_ar: 'عردة',
      name_en: "'Ardah",
    },
    {
      city_id: 2025,
      region_id: 5,
      name_ar: 'ذعبلوتن',
      name_en: "Dha'blutin",
    },
    {
      city_id: 2026,
      region_id: 5,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 2027,
      region_id: 5,
      name_ar: 'نعلة شدقم',
      name_en: "Na'lat Shadqam",
    },
    {
      city_id: 2028,
      region_id: 5,
      name_ar: 'الخويس',
      name_en: 'Al Khuways',
    },
    {
      city_id: 2029,
      region_id: 5,
      name_ar: 'شدقم',
      name_en: 'Shadqam',
    },
    {
      city_id: 2030,
      region_id: 5,
      name_ar: 'ابرقية',
      name_en: 'Abraqiyah',
    },
    {
      city_id: 2031,
      region_id: 5,
      name_ar: 'الحفاير',
      name_en: 'Al Hafayer',
    },
    {
      city_id: 2032,
      region_id: 5,
      name_ar: 'ابن سرحان',
      name_en: 'Ibn Sirhan',
    },
    {
      city_id: 2033,
      region_id: 5,
      name_ar: 'المرضف',
      name_en: 'Al Muraddaf',
    },
    {
      city_id: 2034,
      region_id: 5,
      name_ar: 'الحوية',
      name_en: 'Al Hawiyah',
    },
    {
      city_id: 2035,
      region_id: 5,
      name_ar: 'العضيلية',
      name_en: 'Al Udayliyah',
    },
    {
      city_id: 2037,
      region_id: 5,
      name_ar: 'حرض',
      name_en: 'Harad',
    },
    {
      city_id: 2038,
      region_id: 5,
      name_ar: 'العيون',
      name_en: 'Al Uyun',
    },
    {
      city_id: 2039,
      region_id: 2,
      name_ar: 'الحائط',
      name_en: 'Al Hait',
    },
    {
      city_id: 2040,
      region_id: 2,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 2041,
      region_id: 2,
      name_ar: 'غزال',
      name_en: 'Ghazal',
    },
    {
      city_id: 2042,
      region_id: 2,
      name_ar: 'السلامة',
      name_en: 'As Salamah',
    },
    {
      city_id: 2044,
      region_id: 2,
      name_ar: 'المويه الجديد',
      name_en: 'Al Muwayh Al Jadid',
    },
    {
      city_id: 2045,
      region_id: 2,
      name_ar: 'عبال',
      name_en: "'Abal",
    },
    {
      city_id: 2046,
      region_id: 2,
      name_ar: 'الجعلان',
      name_en: "Al Ji'lan",
    },
    {
      city_id: 2047,
      region_id: 2,
      name_ar: 'حلباء',
      name_en: 'Halaba',
    },
    {
      city_id: 2048,
      region_id: 2,
      name_ar: 'حداد',
      name_en: 'Haddad',
    },
    {
      city_id: 2049,
      region_id: 2,
      name_ar: 'السيل الصغير',
      name_en: 'As Sayl As Saghir',
    },
    {
      city_id: 2050,
      region_id: 2,
      name_ar: 'ريحة',
      name_en: 'Rayhah',
    },
    {
      city_id: 2051,
      region_id: 2,
      name_ar: 'العرفاء',
      name_en: "Al 'Arfa",
    },
    {
      city_id: 2052,
      region_id: 2,
      name_ar: 'الرحبة',
      name_en: 'Ar Rahbah',
    },
    {
      city_id: 2053,
      region_id: 2,
      name_ar: 'القهيب',
      name_en: 'Al Quhayb',
    },
    {
      city_id: 2054,
      region_id: 2,
      name_ar: 'القران',
      name_en: 'Al Qaran',
    },
    {
      city_id: 2055,
      region_id: 2,
      name_ar: 'الحوية',
      name_en: 'Al Hawiyah',
    },
    {
      city_id: 2056,
      region_id: 2,
      name_ar: 'السيل الكبير',
      name_en: 'As Sayl Al Kabir',
    },
    {
      city_id: 2057,
      region_id: 2,
      name_ar: 'السوارفية',
      name_en: 'As Sawarqiyah',
    },
    {
      city_id: 2058,
      region_id: 2,
      name_ar: 'عشيرة الجديدة',
      name_en: "'Ushayrah Al Jadidah",
    },
    {
      city_id: 2059,
      region_id: 2,
      name_ar: 'العطيف',
      name_en: "Al 'Atif",
    },
    {
      city_id: 2060,
      region_id: 2,
      name_ar: 'القرشيات',
      name_en: 'Al Qurrashiyat',
    },
    {
      city_id: 2061,
      region_id: 2,
      name_ar: 'عشيرة',
      name_en: "'Ushayrah",
    },
    {
      city_id: 2062,
      region_id: 2,
      name_ar: 'السايلة',
      name_en: 'As Sayilah',
    },
    {
      city_id: 2063,
      region_id: 2,
      name_ar: 'الفراع',
      name_en: "Al Fira'",
    },
    {
      city_id: 2064,
      region_id: 2,
      name_ar: 'النجمة',
      name_en: 'An Najmah',
    },
    {
      city_id: 2065,
      region_id: 2,
      name_ar: 'العصمان',
      name_en: "Al 'Usman",
    },
    {
      city_id: 2066,
      region_id: 2,
      name_ar: 'العاصد',
      name_en: "Al 'Asid",
    },
    {
      city_id: 2067,
      region_id: 2,
      name_ar: 'اليعاسيب',
      name_en: "Al Ya'asib",
    },
    {
      city_id: 2068,
      region_id: 2,
      name_ar: 'الشبان',
      name_en: 'Ash Shubban',
    },
    {
      city_id: 2069,
      region_id: 2,
      name_ar: 'العنع',
      name_en: "Al Qana'",
    },
    {
      city_id: 2070,
      region_id: 2,
      name_ar: 'الطوال',
      name_en: 'At Tuwal',
    },
    {
      city_id: 2071,
      region_id: 2,
      name_ar: 'الفارعة',
      name_en: "Al Fari'ah",
    },
    {
      city_id: 2072,
      region_id: 2,
      name_ar: 'المريفق',
      name_en: 'Al Murayfiq',
    },
    {
      city_id: 2073,
      region_id: 2,
      name_ar: 'السد',
      name_en: 'As Sadd',
    },
    {
      city_id: 2074,
      region_id: 2,
      name_ar: 'مشرق',
      name_en: 'Mishriq',
    },
    {
      city_id: 2075,
      region_id: 2,
      name_ar: 'البورة',
      name_en: 'Al Burah',
    },
    {
      city_id: 2076,
      region_id: 2,
      name_ar: 'الشرى',
      name_en: 'Ash Shara',
    },
    {
      city_id: 2077,
      region_id: 2,
      name_ar: 'ريشان',
      name_en: 'Rishan',
    },
    {
      city_id: 2078,
      region_id: 2,
      name_ar: 'المثان',
      name_en: 'Mithan',
    },
    {
      city_id: 2079,
      region_id: 2,
      name_ar: 'المناضح',
      name_en: 'Al Manadih',
    },
    {
      city_id: 2080,
      region_id: 2,
      name_ar: 'العمار',
      name_en: "Al 'Amar",
    },
    {
      city_id: 2081,
      region_id: 2,
      name_ar: 'المجرد',
      name_en: 'Al Majrad',
    },
    {
      city_id: 2082,
      region_id: 2,
      name_ar: 'آل عطى',
      name_en: "Al 'Ata",
    },
    {
      city_id: 2083,
      region_id: 2,
      name_ar: 'السلاقا',
      name_en: 'As Salaqa',
    },
    {
      city_id: 2084,
      region_id: 2,
      name_ar: 'الدار الحمراء',
      name_en: 'Al Dar Al Hamra',
    },
    {
      city_id: 2085,
      region_id: 2,
      name_ar: 'مران',
      name_en: 'Marran',
    },
    {
      city_id: 2086,
      region_id: 2,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 2087,
      region_id: 2,
      name_ar: 'شقصان',
      name_en: 'Shaqasan',
    },
    {
      city_id: 2088,
      region_id: 2,
      name_ar: 'مظللة',
      name_en: 'Mudallilah',
    },
    {
      city_id: 2089,
      region_id: 2,
      name_ar: 'ام النخلة',
      name_en: 'Umm An Nakhlah',
    },
    {
      city_id: 2090,
      region_id: 2,
      name_ar: 'الذيابات',
      name_en: 'Adh Dhiyabat',
    },
    {
      city_id: 2091,
      region_id: 2,
      name_ar: 'غزايل',
      name_en: 'Ghazayil',
    },
    {
      city_id: 2092,
      region_id: 2,
      name_ar: 'قيا',
      name_en: 'Qiya',
    },
    {
      city_id: 2093,
      region_id: 2,
      name_ar: 'بوا',
      name_en: 'Buwa',
    },
    {
      city_id: 2094,
      region_id: 2,
      name_ar: 'عشيرة مكتن',
      name_en: "'Ishayra Maktan",
    },
    {
      city_id: 2095,
      region_id: 2,
      name_ar: 'الحفائر',
      name_en: 'Al Hafair',
    },
    {
      city_id: 2096,
      region_id: 2,
      name_ar: 'رمحة',
      name_en: 'Rumhah',
    },
    {
      city_id: 2097,
      region_id: 2,
      name_ar: 'السلمة',
      name_en: 'As Salamah',
    },
    {
      city_id: 2098,
      region_id: 2,
      name_ar: 'حضن',
      name_en: 'Hadan',
    },
    {
      city_id: 2099,
      region_id: 2,
      name_ar: 'بئر عن',
      name_en: "Bir 'Inn",
    },
    {
      city_id: 2100,
      region_id: 2,
      name_ar: 'الثابتية',
      name_en: 'Ath Thabitiyah',
    },
    {
      city_id: 2101,
      region_id: 2,
      name_ar: 'جليل',
      name_en: 'Julayyil',
    },
    {
      city_id: 2102,
      region_id: 2,
      name_ar: 'صلبة',
      name_en: 'Sulubah',
    },
    {
      city_id: 2103,
      region_id: 2,
      name_ar: 'الاخاضر',
      name_en: 'Al Akhadir',
    },
    {
      city_id: 2104,
      region_id: 2,
      name_ar: 'الخرائق',
      name_en: 'Al Kharaiq',
    },
    {
      city_id: 2105,
      region_id: 2,
      name_ar: 'كلاخ',
      name_en: 'Kulakh',
    },
    {
      city_id: 2106,
      region_id: 2,
      name_ar: 'زبيدة',
      name_en: 'Zubaidah',
    },
    {
      city_id: 2107,
      region_id: 2,
      name_ar: 'زعفران',
      name_en: "Za'faran",
    },
    {
      city_id: 2108,
      region_id: 2,
      name_ar: 'مرفوض',
      name_en: 'Marfud',
    },
    {
      city_id: 2109,
      region_id: 2,
      name_ar: 'القضيفة',
      name_en: 'Al Qudaifah',
    },
    {
      city_id: 2110,
      region_id: 2,
      name_ar: 'بسل',
      name_en: 'Bisil',
    },
    {
      city_id: 2111,
      region_id: 2,
      name_ar: 'السديرة',
      name_en: 'As Sudayrah',
    },
    {
      city_id: 2112,
      region_id: 2,
      name_ar: 'المعقر',
      name_en: "Al Ma'qir",
    },
    {
      city_id: 2113,
      region_id: 2,
      name_ar: 'الطفلان',
      name_en: 'At Tuflan',
    },
    {
      city_id: 2114,
      region_id: 2,
      name_ar: 'الصفاة',
      name_en: 'As Sufah',
    },
    {
      city_id: 2115,
      region_id: 2,
      name_ar: 'الحلقة',
      name_en: 'Al Halqah',
    },
    {
      city_id: 2116,
      region_id: 2,
      name_ar: 'الجدير',
      name_en: 'Al Jadir',
    },
    {
      city_id: 2117,
      region_id: 2,
      name_ar: 'الخليف',
      name_en: 'Al Khalif',
    },
    {
      city_id: 2118,
      region_id: 2,
      name_ar: 'الحوطة',
      name_en: 'Al Hawtah',
    },
    {
      city_id: 2119,
      region_id: 2,
      name_ar: 'جويرة',
      name_en: 'Juwairah',
    },
    {
      city_id: 2120,
      region_id: 2,
      name_ar: 'الطفيحاء',
      name_en: 'At Tufayha',
    },
    {
      city_id: 2121,
      region_id: 2,
      name_ar: 'جوش',
      name_en: 'Jush',
    },
    {
      city_id: 2122,
      region_id: 2,
      name_ar: 'القحوم',
      name_en: 'Al Qahum',
    },
    {
      city_id: 2123,
      region_id: 2,
      name_ar: 'الغريب',
      name_en: 'Al Gharayyib',
    },
    {
      city_id: 2124,
      region_id: 2,
      name_ar: 'الحمراء',
      name_en: 'Al Hamra',
    },
    {
      city_id: 2125,
      region_id: 2,
      name_ar: 'القصر',
      name_en: 'Al Qasr',
    },
    {
      city_id: 2126,
      region_id: 2,
      name_ar: 'اللبة',
      name_en: 'Al Libbah',
    },
    {
      city_id: 2127,
      region_id: 2,
      name_ar: 'القريع',
      name_en: "Al Qari'",
    },
    {
      city_id: 2128,
      region_id: 2,
      name_ar: 'الشعاعيب',
      name_en: "Ash Sha'a'ib",
    },
    {
      city_id: 2129,
      region_id: 2,
      name_ar: 'ميسان',
      name_en: 'Maysan',
    },
    {
      city_id: 2130,
      region_id: 2,
      name_ar: 'قها',
      name_en: 'Qaha',
    },
    {
      city_id: 2131,
      region_id: 12,
      name_ar: 'برحرح',
      name_en: 'Barahrah',
    },
    {
      city_id: 2132,
      region_id: 2,
      name_ar: 'الصور',
      name_en: 'As Sur',
    },
    {
      city_id: 2133,
      region_id: 2,
      name_ar: 'ابو راكة',
      name_en: 'Abu Rakah',
    },
    {
      city_id: 2134,
      region_id: 2,
      name_ar: 'الفريع',
      name_en: "Al Furay'",
    },
    {
      city_id: 2135,
      region_id: 2,
      name_ar: 'الوطاة',
      name_en: 'Al Watah',
    },
    {
      city_id: 2136,
      region_id: 2,
      name_ar: 'القويعية',
      name_en: "Al Quway'iyah",
    },
    {
      city_id: 2137,
      region_id: 2,
      name_ar: 'إبن غنام',
      name_en: 'Ibn Ghannam',
    },
    {
      city_id: 2138,
      region_id: 2,
      name_ar: 'ذواد',
      name_en: 'Dhawwad',
    },
    {
      city_id: 2139,
      region_id: 2,
      name_ar: 'المبرك',
      name_en: 'Al Mabrak',
    },
    {
      city_id: 2140,
      region_id: 2,
      name_ar: 'العلاوة',
      name_en: "Al 'Ilawah",
    },
    {
      city_id: 2141,
      region_id: 2,
      name_ar: 'الحائرية',
      name_en: 'Al Hairiyah',
    },
    {
      city_id: 2142,
      region_id: 2,
      name_ar: 'دعيمر',
      name_en: "Du'aymir",
    },
    {
      city_id: 2143,
      region_id: 2,
      name_ar: 'وساعد',
      name_en: "Wusa'ad",
    },
    {
      city_id: 2144,
      region_id: 2,
      name_ar: 'المدرة',
      name_en: 'Al Midarah',
    },
    {
      city_id: 2145,
      region_id: 2,
      name_ar: 'الديرة',
      name_en: 'Ad Dayyirah',
    },
    {
      city_id: 2146,
      region_id: 2,
      name_ar: 'الخالدية',
      name_en: 'Al Khalidiyah',
    },
    {
      city_id: 2147,
      region_id: 2,
      name_ar: 'الغرابة',
      name_en: 'Al Ghurabah',
    },
    {
      city_id: 2148,
      region_id: 2,
      name_ar: 'العلبة',
      name_en: "Al 'Ilabah",
    },
    {
      city_id: 2149,
      region_id: 2,
      name_ar: 'العابسية',
      name_en: "Al 'Abisiyah",
    },
    {
      city_id: 2150,
      region_id: 2,
      name_ar: 'العرقين',
      name_en: "Al 'Irqayn",
    },
    {
      city_id: 2151,
      region_id: 2,
      name_ar: 'الغرمول',
      name_en: 'Al Gharmul',
    },
    {
      city_id: 2152,
      region_id: 2,
      name_ar: 'العصلة',
      name_en: "Al 'Isalah",
    },
    {
      city_id: 2153,
      region_id: 2,
      name_ar: 'زلاقة',
      name_en: 'Zallaqah',
    },
    {
      city_id: 2154,
      region_id: 2,
      name_ar: 'الحشفة',
      name_en: 'Al Hashafah',
    },
    {
      city_id: 2155,
      region_id: 2,
      name_ar: 'ابو مظلة',
      name_en: 'Abu Madhallah',
    },
    {
      city_id: 2156,
      region_id: 2,
      name_ar: 'تربه',
      name_en: 'Turbah',
    },
    {
      city_id: 2157,
      region_id: 2,
      name_ar: 'الحشرج',
      name_en: 'Al Hashraj',
    },
    {
      city_id: 2158,
      region_id: 2,
      name_ar: 'شعر',
      name_en: "Shi'r",
    },
    {
      city_id: 2159,
      region_id: 5,
      name_ar: 'الربيعية',
      name_en: "Ar Rabi'iyah",
    },
    {
      city_id: 2160,
      region_id: 7,
      name_ar: 'بئر ابن رشدان',
      name_en: 'Bir Ibn Rashdan',
    },
    {
      city_id: 2161,
      region_id: 5,
      name_ar: 'القديح',
      name_en: 'Al Qudayh',
    },
    {
      city_id: 2162,
      region_id: 5,
      name_ar: 'الخويلدية',
      name_en: 'Al Khuwildiyah',
    },
    {
      city_id: 2163,
      region_id: 5,
      name_ar: 'الدريدي',
      name_en: 'Ad Duraidy',
    },
    {
      city_id: 2164,
      region_id: 5,
      name_ar: 'الخترشية',
      name_en: 'Al Khatrashiyah',
    },
    {
      city_id: 2165,
      region_id: 5,
      name_ar: 'ابو معن',
      name_en: "Abu Ma'an",
    },
    {
      city_id: 2166,
      region_id: 5,
      name_ar: 'ام الساهك',
      name_en: 'Umm As Sahik',
    },
    {
      city_id: 2167,
      region_id: 5,
      name_ar: 'صفوى',
      name_en: 'Safwa',
    },
    {
      city_id: 2168,
      region_id: 5,
      name_ar: 'حزم ام الساهك',
      name_en: 'Hazam Umm As Sahik',
    },
    {
      city_id: 2169,
      region_id: 3,
      name_ar: 'الفرش',
      name_en: 'Al Farsh',
    },
    {
      city_id: 2170,
      region_id: 5,
      name_ar: 'الجش',
      name_en: 'Al Jish',
    },
    {
      city_id: 2171,
      region_id: 5,
      name_ar: 'عنك',
      name_en: "'Inak",
    },
    {
      city_id: 2172,
      region_id: 5,
      name_ar: 'حلة محيش',
      name_en: 'Hulat Muhish',
    },
    {
      city_id: 2173,
      region_id: 5,
      name_ar: 'التوبي',
      name_en: 'At Tubi',
    },
    {
      city_id: 2174,
      region_id: 5,
      name_ar: 'الملاحة',
      name_en: 'Al Malahah',
    },
    {
      city_id: 2175,
      region_id: 3,
      name_ar: 'العصيلي',
      name_en: "Al 'Usayli",
    },
    {
      city_id: 2176,
      region_id: 5,
      name_ar: 'دارين',
      name_en: 'Darin',
    },
    {
      city_id: 2177,
      region_id: 5,
      name_ar: 'العوامية',
      name_en: "Al 'Awwamiyah",
    },
    {
      city_id: 2178,
      region_id: 5,
      name_ar: 'النابية',
      name_en: 'An Nabiyah',
    },
    {
      city_id: 2179,
      region_id: 5,
      name_ar: 'الزور',
      name_en: 'Az Zawr',
    },
    {
      city_id: 2180,
      region_id: 5,
      name_ar: 'الفريع',
      name_en: "Al Furay'",
    },
    {
      city_id: 2182,
      region_id: 2,
      name_ar: 'شرائع المجاهدين',
      name_en: "Sharai' Al Mujahidin",
    },
    {
      city_id: 2183,
      region_id: 2,
      name_ar: 'التنعيم',
      name_en: "At Tan'im",
    },
    {
      city_id: 2184,
      region_id: 7,
      name_ar: 'الشرف',
      name_en: 'Ash Sharaf',
    },
    {
      city_id: 2185,
      region_id: 2,
      name_ar: 'الشرائع',
      name_en: "Ash Sharai'",
    },
    {
      city_id: 2186,
      region_id: 2,
      name_ar: 'الريجة',
      name_en: 'Ar Rayjah',
    },
    {
      city_id: 2187,
      region_id: 2,
      name_ar: 'قابل عيفان',
      name_en: "Qabil 'Ifan",
    },
    {
      city_id: 2188,
      region_id: 2,
      name_ar: 'بني عمير',
      name_en: 'Bani Umayr',
    },
    {
      city_id: 2189,
      region_id: 2,
      name_ar: 'البجيدي',
      name_en: 'Al Bijaydi',
    },
    {
      city_id: 2190,
      region_id: 2,
      name_ar: 'قرى الصدر',
      name_en: 'Qura As Sadr',
    },
    {
      city_id: 2191,
      region_id: 2,
      name_ar: 'المضيق',
      name_en: 'Al Madiq',
    },
    {
      city_id: 2192,
      region_id: 2,
      name_ar: 'المجاريش',
      name_en: 'Al Majarish',
    },
    {
      city_id: 2193,
      region_id: 2,
      name_ar: 'الحسن',
      name_en: 'Al Hasan',
    },
    {
      city_id: 2194,
      region_id: 2,
      name_ar: 'الشدقاء',
      name_en: 'Ash Shadqa',
    },
    {
      city_id: 2195,
      region_id: 2,
      name_ar: 'الريقة',
      name_en: 'Ar Riqah',
    },
    {
      city_id: 2196,
      region_id: 2,
      name_ar: 'الزيمة',
      name_en: 'Az Zaymah',
    },
    {
      city_id: 2197,
      region_id: 2,
      name_ar: 'الشميسي',
      name_en: 'Ash Shumaysi',
    },
    {
      city_id: 2198,
      region_id: 2,
      name_ar: 'ام حبيتر',
      name_en: 'Umm Hubaytir',
    },
    {
      city_id: 2199,
      region_id: 2,
      name_ar: 'الصرف',
      name_en: 'As Sarf',
    },
    {
      city_id: 2200,
      region_id: 2,
      name_ar: 'شداد',
      name_en: 'Shadad',
    },
    {
      city_id: 2201,
      region_id: 2,
      name_ar: 'ام الزلة',
      name_en: 'Umm Al Zillah',
    },
    {
      city_id: 2202,
      region_id: 2,
      name_ar: 'بني دعد',
      name_en: "Bani Da'ad",
    },
    {
      city_id: 2203,
      region_id: 2,
      name_ar: 'ام الراكة',
      name_en: 'Umm Ar Rakah',
    },
    {
      city_id: 2204,
      region_id: 2,
      name_ar: 'الشعيبة',
      name_en: "Ash Shu'aybah",
    },
    {
      city_id: 2205,
      region_id: 2,
      name_ar: 'الدحيلة',
      name_en: 'Ad Duhaylah',
    },
    {
      city_id: 2206,
      region_id: 2,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 2207,
      region_id: 2,
      name_ar: 'جعرانة',
      name_en: "Ju'ranah",
    },
    {
      city_id: 2208,
      region_id: 9,
      name_ar: 'طريف',
      name_en: 'Turaif',
    },
    {
      city_id: 2209,
      region_id: 9,
      name_ar: 'الجراني',
      name_en: 'Al Jirani',
    },
    {
      city_id: 2210,
      region_id: 13,
      name_ar: 'الحماد',
      name_en: 'Al Hamad',
    },
    {
      city_id: 2211,
      region_id: 9,
      name_ar: 'نعيج',
      name_en: "Nu'ayj",
    },
    {
      city_id: 2212,
      region_id: 9,
      name_ar: 'قاع الحنو',
      name_en: "Qa' Al Hinu",
    },
    {
      city_id: 2213,
      region_id: 9,
      name_ar: 'عرعر',
      name_en: 'Arar',
    },
    {
      city_id: 2214,
      region_id: 9,
      name_ar: 'مركز الصحن',
      name_en: 'Markaz As Sahan',
    },
    {
      city_id: 2215,
      region_id: 9,
      name_ar: 'العويقلية',
      name_en: "Al 'Uwayqiliyah",
    },
    {
      city_id: 2216,
      region_id: 9,
      name_ar: 'أبا الرواث',
      name_en: 'Aba Ar Rawath',
    },
    {
      city_id: 2217,
      region_id: 9,
      name_ar: 'الكاسب',
      name_en: 'Al Kasib',
    },
    {
      city_id: 2218,
      region_id: 9,
      name_ar: 'أم خنصر',
      name_en: 'Umm Khunsur',
    },
    {
      city_id: 2219,
      region_id: 9,
      name_ar: 'حزم الجلاميد',
      name_en: 'Hazam Al Jalamid',
    },
    {
      city_id: 2220,
      region_id: 9,
      name_ar: 'الدويد',
      name_en: 'Ad Duwayd',
    },
    {
      city_id: 2221,
      region_id: 9,
      name_ar: 'جديدة عرعر',
      name_en: "Jadidat 'Ar'ar",
    },
    {
      city_id: 2222,
      region_id: 13,
      name_ar: 'كاف',
      name_en: 'Kaf',
    },
    {
      city_id: 2223,
      region_id: 13,
      name_ar: 'القرقر',
      name_en: 'Al Qarqar',
    },
    {
      city_id: 2224,
      region_id: 13,
      name_ar: 'إثرة',
      name_en: 'Ithrah',
    },
    {
      city_id: 2225,
      region_id: 13,
      name_ar: 'غطي',
      name_en: 'Ghutayy',
    },
    {
      city_id: 2226,
      region_id: 13,
      name_ar: 'القريات',
      name_en: 'Al Qurayyat',
    },
    {
      city_id: 2227,
      region_id: 13,
      name_ar: 'عين الحواسي',
      name_en: "'Ayn Al Hawasi",
    },
    {
      city_id: 2228,
      region_id: 13,
      name_ar: 'قليب خضر',
      name_en: 'Qulayyib Khudr',
    },
    {
      city_id: 2229,
      region_id: 13,
      name_ar: 'العيساوية',
      name_en: 'Al Isawiyah',
    },
    {
      city_id: 2230,
      region_id: 13,
      name_ar: 'الحديثة',
      name_en: 'Al Hadithah',
    },
    {
      city_id: 2231,
      region_id: 13,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 2232,
      region_id: 13,
      name_ar: 'الشقيق',
      name_en: 'Ash Shaqiq',
    },
    {
      city_id: 2233,
      region_id: 13,
      name_ar: 'الحوي',
      name_en: 'Al Hawi',
    },
    {
      city_id: 2234,
      region_id: 13,
      name_ar: 'صوير',
      name_en: 'Suwayr',
    },
    {
      city_id: 2235,
      region_id: 13,
      name_ar: 'حدرج',
      name_en: 'Hidrij',
    },
    {
      city_id: 2236,
      region_id: 13,
      name_ar: 'ام طليحة',
      name_en: 'Umm Tulayhah',
    },
    {
      city_id: 2237,
      region_id: 13,
      name_ar: 'سكاكا',
      name_en: 'Sakaka',
    },
    {
      city_id: 2238,
      region_id: 13,
      name_ar: 'اللقايط',
      name_en: 'Al Laqayit',
    },
    {
      city_id: 2239,
      region_id: 13,
      name_ar: 'صبيحة',
      name_en: 'Subayhah',
    },
    {
      city_id: 2240,
      region_id: 13,
      name_ar: 'طبرجل',
      name_en: 'Tubarjal',
    },
    {
      city_id: 2241,
      region_id: 13,
      name_ar: 'الفياض',
      name_en: 'Al Fiyad',
    },
    {
      city_id: 2242,
      region_id: 13,
      name_ar: 'قارا',
      name_en: 'Qara',
    },
    {
      city_id: 2243,
      region_id: 13,
      name_ar: 'الثنية',
      name_en: 'Ath Thaniyah',
    },
    {
      city_id: 2244,
      region_id: 13,
      name_ar: 'ام نخيلة',
      name_en: 'Umm Nakhilah',
    },
    {
      city_id: 2245,
      region_id: 13,
      name_ar: 'المرير',
      name_en: 'Al Murayr',
    },
    {
      city_id: 2246,
      region_id: 13,
      name_ar: 'زلوم',
      name_en: 'Zallum',
    },
    {
      city_id: 2247,
      region_id: 13,
      name_ar: 'النبك ابو قصر',
      name_en: 'An Nabk Abu Qasr',
    },
    {
      city_id: 2248,
      region_id: 13,
      name_ar: 'طلعة عمار',
      name_en: "Tal'at 'Ammar",
    },
    {
      city_id: 2249,
      region_id: 13,
      name_ar: 'عذفاء',
      name_en: "'Adhfa",
    },
    {
      city_id: 2250,
      region_id: 13,
      name_ar: 'خوعاء',
      name_en: "Khaw'a'",
    },
    {
      city_id: 2251,
      region_id: 13,
      name_ar: 'الشويحطية',
      name_en: 'Ash Shuwayhitiyah',
    },
    {
      city_id: 2252,
      region_id: 9,
      name_ar: 'مركز أم الحيران',
      name_en: 'Markaz Umm Al Hiran',
    },
    {
      city_id: 2253,
      region_id: 5,
      name_ar: 'مركز سماح الجديد',
      name_en: 'Markaz Samah Al Jadid',
    },
    {
      city_id: 2254,
      region_id: 5,
      name_ar: 'مركز الظهرة',
      name_en: 'Markaz Adh Dhahrah',
    },
    {
      city_id: 2255,
      region_id: 9,
      name_ar: 'إبن سوقي',
      name_en: 'Ibn Suqi',
    },
    {
      city_id: 2256,
      region_id: 9,
      name_ar: 'رفحاء',
      name_en: "Rafha'",
    },
    {
      city_id: 2257,
      region_id: 9,
      name_ar: 'نصاب',
      name_en: 'Nisab',
    },
    {
      city_id: 2258,
      region_id: 5,
      name_ar: 'سماح',
      name_en: 'Samah',
    },
    {
      city_id: 2259,
      region_id: 9,
      name_ar: 'لوقة',
      name_en: 'Lawqah',
    },
    {
      city_id: 2260,
      region_id: 9,
      name_ar: 'ام رضمة',
      name_en: 'Umm Rudmah',
    },
    {
      city_id: 2261,
      region_id: 5,
      name_ar: 'ابن لغيصم',
      name_en: 'Ibn Lughaisim',
    },
    {
      city_id: 2262,
      region_id: 9,
      name_ar: 'روضة هباس',
      name_en: 'Rawdat Habbas',
    },
    {
      city_id: 2263,
      region_id: 9,
      name_ar: 'التمياط',
      name_en: 'Timiat',
    },
    {
      city_id: 2264,
      region_id: 9,
      name_ar: 'إبن شريم',
      name_en: 'Ibn Shuraym',
    },
    {
      city_id: 2265,
      region_id: 9,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 2266,
      region_id: 9,
      name_ar: 'لينة',
      name_en: 'Linah',
    },
    {
      city_id: 2267,
      region_id: 9,
      name_ar: 'المركوز',
      name_en: 'Al Markuz',
    },
    {
      city_id: 2268,
      region_id: 13,
      name_ar: 'دومة الجندل',
      name_en: 'Dawmat Al Jandal',
    },
    {
      city_id: 2269,
      region_id: 13,
      name_ar: 'ميقوع',
      name_en: "Mayqu'",
    },
    {
      city_id: 2270,
      region_id: 13,
      name_ar: 'الأضارع',
      name_en: "Al Adari'",
    },
    {
      city_id: 2271,
      region_id: 13,
      name_ar: 'صفان',
      name_en: 'Safan',
    },
    {
      city_id: 2272,
      region_id: 13,
      name_ar: 'الرديفة',
      name_en: 'Ar Radifah',
    },
    {
      city_id: 2273,
      region_id: 13,
      name_ar: 'ابو عجرم',
      name_en: "Abu 'Ajram",
    },
    {
      city_id: 2274,
      region_id: 13,
      name_ar: 'الطوير',
      name_en: 'At Tuwayr',
    },
    {
      city_id: 2275,
      region_id: 8,
      name_ar: 'صديان',
      name_en: 'Sadyan',
    },
    {
      city_id: 2276,
      region_id: 8,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 2277,
      region_id: 8,
      name_ar: 'البير',
      name_en: 'Al Bir',
    },
    {
      city_id: 2278,
      region_id: 8,
      name_ar: 'البويطن',
      name_en: 'Al Buwaytin',
    },
    {
      city_id: 2279,
      region_id: 8,
      name_ar: 'بدائع العش',
      name_en: "Badai' Al 'Ishsh",
    },
    {
      city_id: 2280,
      region_id: 8,
      name_ar: 'صحي',
      name_en: 'Sahayy',
    },
    {
      city_id: 2281,
      region_id: 8,
      name_ar: 'الوبيرية',
      name_en: 'Al Wubayriyah',
    },
    {
      city_id: 2282,
      region_id: 8,
      name_ar: 'قصيريات',
      name_en: 'Qusayriyat',
    },
    {
      city_id: 2283,
      region_id: 8,
      name_ar: 'سعيدان',
      name_en: "Su'aydan",
    },
    {
      city_id: 2284,
      region_id: 8,
      name_ar: 'جفيفاء',
      name_en: 'Jufayfa',
    },
    {
      city_id: 2285,
      region_id: 8,
      name_ar: 'الغمياء',
      name_en: 'Al Ghamya',
    },
    {
      city_id: 2286,
      region_id: 8,
      name_ar: 'الفرحانية',
      name_en: 'Al Farhaniyah',
    },
    {
      city_id: 2287,
      region_id: 8,
      name_ar: 'المليح',
      name_en: 'Al Mulayh',
    },
    {
      city_id: 2288,
      region_id: 8,
      name_ar: 'حفيرة الشقيق',
      name_en: 'Hafirat Ash Shaqayq',
    },
    {
      city_id: 2289,
      region_id: 8,
      name_ar: 'جرمة',
      name_en: 'Jurumah',
    },
    {
      city_id: 2290,
      region_id: 8,
      name_ar: 'السفن',
      name_en: 'As Sufun',
    },
    {
      city_id: 2291,
      region_id: 8,
      name_ar: 'منيفة القاعد',
      name_en: "Munifah Al Qa'id",
    },
    {
      city_id: 2292,
      region_id: 8,
      name_ar: 'القاعد',
      name_en: "Al Qa'id",
    },
    {
      city_id: 2293,
      region_id: 8,
      name_ar: 'الطوال',
      name_en: 'At Tuwal',
    },
    {
      city_id: 2294,
      region_id: 8,
      name_ar: 'الشعفة',
      name_en: "Ash Sha'afah",
    },
    {
      city_id: 2295,
      region_id: 8,
      name_ar: 'الجلف',
      name_en: 'Al Jilf',
    },
    {
      city_id: 2296,
      region_id: 8,
      name_ar: 'الهرير',
      name_en: 'Al Hurayr',
    },
    {
      city_id: 2297,
      region_id: 8,
      name_ar: 'طوية',
      name_en: 'Tuwayyah',
    },
    {
      city_id: 2298,
      region_id: 8,
      name_ar: 'البدع',
      name_en: "Al Bada'",
    },
    {
      city_id: 2299,
      region_id: 8,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 2300,
      region_id: 8,
      name_ar: 'الرويع',
      name_en: "Ar Ruway'",
    },
    {
      city_id: 2301,
      region_id: 8,
      name_ar: 'العضدي',
      name_en: "Al 'Iddi",
    },
    {
      city_id: 2302,
      region_id: 8,
      name_ar: 'المرمى',
      name_en: 'Al Marma',
    },
    {
      city_id: 2303,
      region_id: 8,
      name_ar: 'المندسة الشرقية',
      name_en: 'Al Mindassah Ash Sharqiyah',
    },
    {
      city_id: 2304,
      region_id: 8,
      name_ar: 'التيم',
      name_en: 'At Tim',
    },
    {
      city_id: 2305,
      region_id: 8,
      name_ar: 'القليبين',
      name_en: 'Al Qulaybayn',
    },
    {
      city_id: 2306,
      region_id: 8,
      name_ar: 'رينبة',
      name_en: 'Raynibah',
    },
    {
      city_id: 2307,
      region_id: 8,
      name_ar: 'المكظم',
      name_en: 'Al Makdham',
    },
    {
      city_id: 2308,
      region_id: 8,
      name_ar: 'الحميراء',
      name_en: 'Al Humayra',
    },
    {
      city_id: 2309,
      region_id: 8,
      name_ar: 'الغار',
      name_en: 'Al Ghar',
    },
    {
      city_id: 2310,
      region_id: 8,
      name_ar: 'المعيقلات',
      name_en: "Al Mu'ayqilat",
    },
    {
      city_id: 2311,
      region_id: 8,
      name_ar: 'المويكر',
      name_en: 'Al Muwaykir',
    },
    {
      city_id: 2312,
      region_id: 8,
      name_ar: 'المكظم',
      name_en: 'Al Makdham',
    },
    {
      city_id: 2313,
      region_id: 8,
      name_ar: 'الدارة',
      name_en: 'Ad Darah',
    },
    {
      city_id: 2314,
      region_id: 8,
      name_ar: 'الشبيكة',
      name_en: 'Ash Shubaykah',
    },
    {
      city_id: 2315,
      region_id: 8,
      name_ar: 'المبعوثة',
      name_en: "Al Mab'uthah",
    },
    {
      city_id: 2316,
      region_id: 8,
      name_ar: 'الجفر',
      name_en: 'Al Jafr',
    },
    {
      city_id: 2317,
      region_id: 8,
      name_ar: 'المعترضة',
      name_en: "Al Mu'taridah",
    },
    {
      city_id: 2318,
      region_id: 8,
      name_ar: 'اللقيطه',
      name_en: 'Al Liqitah',
    },
    {
      city_id: 2319,
      region_id: 8,
      name_ar: 'منشار',
      name_en: 'Minshar',
    },
    {
      city_id: 2320,
      region_id: 8,
      name_ar: 'سهلة بدنة',
      name_en: 'Sahlat Badanah',
    },
    {
      city_id: 2321,
      region_id: 8,
      name_ar: 'بدائع مريفق',
      name_en: "Badai' Murayfiq",
    },
    {
      city_id: 2322,
      region_id: 8,
      name_ar: 'السليل',
      name_en: 'As Sulayyil',
    },
    {
      city_id: 2323,
      region_id: 8,
      name_ar: 'الصليعاء',
      name_en: "As Sulay'a",
    },
    {
      city_id: 2324,
      region_id: 8,
      name_ar: 'الصبيحية',
      name_en: 'As Subayhiyah',
    },
    {
      city_id: 2325,
      region_id: 8,
      name_ar: 'الأثلة',
      name_en: 'Al Athlah',
    },
    {
      city_id: 2326,
      region_id: 8,
      name_ar: 'حويان',
      name_en: 'Huwayyan',
    },
    {
      city_id: 2327,
      region_id: 8,
      name_ar: 'المبيدع',
      name_en: "Al Mubaydi'",
    },
    {
      city_id: 2328,
      region_id: 8,
      name_ar: 'المبدع',
      name_en: "Al Mabda'",
    },
    {
      city_id: 2329,
      region_id: 7,
      name_ar: 'كلوة',
      name_en: 'Kilwah',
    },
    {
      city_id: 2331,
      region_id: 8,
      name_ar: 'العمائر',
      name_en: "Al 'Amair",
    },
    {
      city_id: 2332,
      region_id: 8,
      name_ar: 'الشملي',
      name_en: 'Ash Shamli',
    },
    {
      city_id: 2333,
      region_id: 8,
      name_ar: 'المسلسل',
      name_en: 'Al Musalsal',
    },
    {
      city_id: 2334,
      region_id: 8,
      name_ar: 'سبطر',
      name_en: 'Sibutar',
    },
    {
      city_id: 2336,
      region_id: 8,
      name_ar: 'غرمول العويد',
      name_en: 'Ghurmul Al Uwayd',
    },
    {
      city_id: 2337,
      region_id: 8,
      name_ar: 'الحامرية',
      name_en: 'Al Hamiriyah',
    },
    {
      city_id: 2338,
      region_id: 8,
      name_ar: 'الخبة',
      name_en: 'Al Khubbah',
    },
    {
      city_id: 2339,
      region_id: 8,
      name_ar: 'الحطي',
      name_en: 'Al Hati',
    },
    {
      city_id: 2340,
      region_id: 8,
      name_ar: 'الصنينا',
      name_en: "As Sunayna'",
    },
    {
      city_id: 2341,
      region_id: 8,
      name_ar: 'دليهان',
      name_en: 'Dulayhan',
    },
    {
      city_id: 2342,
      region_id: 8,
      name_ar: 'الشقيق',
      name_en: 'Ash Shaqiq',
    },
    {
      city_id: 2343,
      region_id: 8,
      name_ar: 'ابا الحيران',
      name_en: 'Aba Al Hiran',
    },
    {
      city_id: 2344,
      region_id: 8,
      name_ar: 'قليب الاطرم',
      name_en: 'Qalib Al Atram',
    },
    {
      city_id: 2345,
      region_id: 8,
      name_ar: 'بيضاء نثيل',
      name_en: "Bayda' Nathil",
    },
    {
      city_id: 2346,
      region_id: 8,
      name_ar: 'قناء',
      name_en: "Qina'",
    },
    {
      city_id: 2347,
      region_id: 8,
      name_ar: 'المشيطية',
      name_en: 'Al Mushaytiyah',
    },
    {
      city_id: 2348,
      region_id: 8,
      name_ar: 'التربية',
      name_en: 'At Turbiyah',
    },
    {
      city_id: 2349,
      region_id: 8,
      name_ar: 'الخطة',
      name_en: 'Al Khuttah',
    },
    {
      city_id: 2350,
      region_id: 8,
      name_ar: 'جبة',
      name_en: 'Jubbah',
    },
    {
      city_id: 2351,
      region_id: 8,
      name_ar: 'موقق',
      name_en: 'Mawqaq',
    },
    {
      city_id: 2352,
      region_id: 8,
      name_ar: 'عقلة بن جبرين',
      name_en: 'Uqlat Bin Jabrin',
    },
    {
      city_id: 2353,
      region_id: 8,
      name_ar: 'ام القلبان',
      name_en: 'Umm Al Qulban',
    },
    {
      city_id: 2354,
      region_id: 8,
      name_ar: 'مزارع السحل',
      name_en: "Mazari' As Sihhal",
    },
    {
      city_id: 2355,
      region_id: 8,
      name_ar: 'مزارع الرغلية',
      name_en: 'Mazari Ar Righliyah',
    },
    {
      city_id: 2356,
      region_id: 8,
      name_ar: 'الطليحة',
      name_en: 'At Tulayhah',
    },
    {
      city_id: 2357,
      region_id: 8,
      name_ar: 'مزارع الصفيا',
      name_en: "Mazari' As Safya",
    },
    {
      city_id: 2358,
      region_id: 8,
      name_ar: 'البدائع',
      name_en: "Al Badai'",
    },
    {
      city_id: 2359,
      region_id: 8,
      name_ar: 'الهمجة',
      name_en: 'Al Hamjah',
    },
    {
      city_id: 2360,
      region_id: 8,
      name_ar: 'المهينية',
      name_en: 'Al Mihayniyah',
    },
    {
      city_id: 2361,
      region_id: 8,
      name_ar: 'الجابرية',
      name_en: 'Al Jabiriyah',
    },
    {
      city_id: 2362,
      region_id: 8,
      name_ar: 'الوعيلي',
      name_en: "Al Wu'ayli",
    },
    {
      city_id: 2363,
      region_id: 8,
      name_ar: 'مزارع الرخيصية',
      name_en: "Mazari' Ar Rikhaysiyah",
    },
    {
      city_id: 2364,
      region_id: 8,
      name_ar: 'مزارع بيط',
      name_en: "Mazari' Bayt",
    },
    {
      city_id: 2365,
      region_id: 8,
      name_ar: 'الجثياثة',
      name_en: 'Al Jithyathah',
    },
    {
      city_id: 2366,
      region_id: 8,
      name_ar: 'القصعاء',
      name_en: "Al Qas'a",
    },
    {
      city_id: 2367,
      region_id: 8,
      name_ar: 'اللويبدة',
      name_en: 'Al Luwaybidah',
    },
    {
      city_id: 2368,
      region_id: 8,
      name_ar: 'الجثامية',
      name_en: 'Al Jithamiyyah',
    },
    {
      city_id: 2369,
      region_id: 8,
      name_ar: 'السويفلة',
      name_en: 'As Suwayfilah',
    },
    {
      city_id: 2370,
      region_id: 8,
      name_ar: 'بقعاء',
      name_en: "Baq'a",
    },
    {
      city_id: 2371,
      region_id: 8,
      name_ar: 'الاجفر',
      name_en: 'Al Ajfar',
    },
    {
      city_id: 2372,
      region_id: 8,
      name_ar: 'تربه',
      name_en: 'Turbah',
    },
    {
      city_id: 2373,
      region_id: 8,
      name_ar: 'ضبيعة',
      name_en: "Dubay'ah",
    },
    {
      city_id: 2374,
      region_id: 8,
      name_ar: 'الشيحية',
      name_en: 'Ash Shihiyah',
    },
    {
      city_id: 2375,
      region_id: 8,
      name_ar: 'الشعيبة',
      name_en: "Ash Shu'aybah",
    },
    {
      city_id: 2376,
      region_id: 8,
      name_ar: 'بقعاء الشرقية',
      name_en: "Baq'a' Ash Sharqiyah",
    },
    {
      city_id: 2377,
      region_id: 8,
      name_ar: 'الثنيان',
      name_en: 'Ath Thinayyan',
    },
    {
      city_id: 2378,
      region_id: 8,
      name_ar: 'الجديدة',
      name_en: 'Al Jadidah',
    },
    {
      city_id: 2379,
      region_id: 8,
      name_ar: 'الشعلانية',
      name_en: "Ash Sha'laniyah",
    },
    {
      city_id: 2380,
      region_id: 8,
      name_ar: 'طلحاء',
      name_en: "Talha'",
    },
    {
      city_id: 2381,
      region_id: 7,
      name_ar: 'العمود',
      name_en: "Al 'Amud",
    },
    {
      city_id: 2382,
      region_id: 7,
      name_ar: 'الحميرة',
      name_en: 'Al Humayrah',
    },
    {
      city_id: 2383,
      region_id: 7,
      name_ar: 'الغال',
      name_en: 'Al Ghal',
    },
    {
      city_id: 2384,
      region_id: 7,
      name_ar: 'السجدة',
      name_en: 'Al Sajdah',
    },
    {
      city_id: 2385,
      region_id: 7,
      name_ar: 'الحشا',
      name_en: 'Al Hasha',
    },
    {
      city_id: 2386,
      region_id: 7,
      name_ar: 'عينونة',
      name_en: "'Aynunah",
    },
    {
      city_id: 2387,
      region_id: 7,
      name_ar: 'بئر عودة',
      name_en: "Bir 'Audah",
    },
    {
      city_id: 2388,
      region_id: 7,
      name_ar: 'الجدة',
      name_en: 'Al Jiddah',
    },
    {
      city_id: 2389,
      region_id: 4,
      name_ar: 'مطربة',
      name_en: 'Mutribah',
    },
    {
      city_id: 2390,
      region_id: 4,
      name_ar: 'الطرفية الغربية',
      name_en: 'At Turfiyah Al Gharbiyah',
    },
    {
      city_id: 2391,
      region_id: 4,
      name_ar: 'البديعة',
      name_en: "Al Badi'ah",
    },
    {
      city_id: 2392,
      region_id: 4,
      name_ar: 'عريفجان ساحوق',
      name_en: "'Urayfjan Sahuq",
    },
    {
      city_id: 2393,
      region_id: 4,
      name_ar: 'الصليبي',
      name_en: 'As Sulaybi',
    },
    {
      city_id: 2394,
      region_id: 4,
      name_ar: 'الزهيرية',
      name_en: 'Az Zihayriyah',
    },
    {
      city_id: 2395,
      region_id: 4,
      name_ar: 'روضة قرادان',
      name_en: 'Rawdat Qiradan',
    },
    {
      city_id: 2396,
      region_id: 4,
      name_ar: 'أبانات',
      name_en: 'Abanat',
    },
    {
      city_id: 2397,
      region_id: 4,
      name_ar: 'الحنينية',
      name_en: 'Al Hinayniyah',
    },
    {
      city_id: 2398,
      region_id: 4,
      name_ar: 'خضراء',
      name_en: 'Khadra',
    },
    {
      city_id: 2399,
      region_id: 4,
      name_ar: 'ضليع رشيد',
      name_en: "Dulay' Rashid",
    },
    {
      city_id: 2400,
      region_id: 4,
      name_ar: 'الخطيم',
      name_en: 'Al Khutaym',
    },
    {
      city_id: 2401,
      region_id: 4,
      name_ar: 'عطا',
      name_en: "'Ata",
    },
    {
      city_id: 2402,
      region_id: 4,
      name_ar: 'عطي',
      name_en: "'Utayy",
    },
    {
      city_id: 2403,
      region_id: 4,
      name_ar: 'الهمجة',
      name_en: 'Al Hamjah',
    },
    {
      city_id: 2404,
      region_id: 4,
      name_ar: 'رفائع اللهيب',
      name_en: "Ruf'ai' Al Luhayb",
    },
    {
      city_id: 2405,
      region_id: 4,
      name_ar: 'الجرذاوية',
      name_en: 'Al Jardhawiyah',
    },
    {
      city_id: 2406,
      region_id: 4,
      name_ar: 'الركنة',
      name_en: 'Ar Ruknah',
    },
    {
      city_id: 2407,
      region_id: 4,
      name_ar: 'البتراء',
      name_en: "Al Batra'",
    },
    {
      city_id: 2408,
      region_id: 4,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 2409,
      region_id: 4,
      name_ar: 'الحوطة',
      name_en: 'Al Hawtah',
    },
    {
      city_id: 2410,
      region_id: 4,
      name_ar: 'العبيل',
      name_en: "Al 'Ubayl",
    },
    {
      city_id: 2411,
      region_id: 4,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 2412,
      region_id: 4,
      name_ar: 'نفجة',
      name_en: 'Nafjah',
    },
    {
      city_id: 2413,
      region_id: 4,
      name_ar: 'الغيدانية',
      name_en: 'Al Ghaydaniyah',
    },
    {
      city_id: 2414,
      region_id: 4,
      name_ar: 'العبلة',
      name_en: "Al 'Abla",
    },
    {
      city_id: 2415,
      region_id: 4,
      name_ar: 'مزارع الجراوة',
      name_en: "Mazari' Al Jirawah",
    },
    {
      city_id: 2416,
      region_id: 4,
      name_ar: 'السميراء',
      name_en: 'As Sumayra',
    },
    {
      city_id: 2417,
      region_id: 4,
      name_ar: 'الرضيمة',
      name_en: 'Al Rudaymah',
    },
    {
      city_id: 2418,
      region_id: 4,
      name_ar: 'القوعي',
      name_en: "Al Qaw'i",
    },
    {
      city_id: 2419,
      region_id: 4,
      name_ar: 'مزارع العقل',
      name_en: "Mazari' Al 'Aql",
    },
    {
      city_id: 2420,
      region_id: 4,
      name_ar: 'دخنة',
      name_en: 'Dukhnah',
    },
    {
      city_id: 2421,
      region_id: 4,
      name_ar: 'الرس',
      name_en: 'Ar Rass',
    },
    {
      city_id: 2422,
      region_id: 4,
      name_ar: 'الشنانة',
      name_en: 'Ash Shananah',
    },
    {
      city_id: 2423,
      region_id: 4,
      name_ar: 'الشورقية',
      name_en: 'Ash Shawraqiyah',
    },
    {
      city_id: 2424,
      region_id: 4,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 2425,
      region_id: 4,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 2426,
      region_id: 4,
      name_ar: 'الطلعة',
      name_en: "At Tal'ah",
    },
    {
      city_id: 2427,
      region_id: 4,
      name_ar: 'مظيفيرة',
      name_en: 'Mudhayfirah',
    },
    {
      city_id: 2428,
      region_id: 4,
      name_ar: 'الوشحاء',
      name_en: 'Al Washha',
    },
    {
      city_id: 2429,
      region_id: 4,
      name_ar: 'الهيشة',
      name_en: 'Al Hishah',
    },
    {
      city_id: 2430,
      region_id: 4,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 2431,
      region_id: 4,
      name_ar: 'العدان',
      name_en: "Al 'Adan",
    },
    {
      city_id: 2432,
      region_id: 4,
      name_ar: 'عين العقيلي',
      name_en: "'Ayn Al 'Uqayli",
    },
    {
      city_id: 2433,
      region_id: 4,
      name_ar: 'روضة اللواف',
      name_en: 'Rawdat Al Lawwaf',
    },
    {
      city_id: 2434,
      region_id: 4,
      name_ar: 'العكرشية',
      name_en: "Al 'Ikrashiyah",
    },
    {
      city_id: 2435,
      region_id: 4,
      name_ar: 'زهلولة',
      name_en: 'Zihlulah',
    },
    {
      city_id: 2436,
      region_id: 4,
      name_ar: 'الرحيمية',
      name_en: 'Ar Ruhaymiyah',
    },
    {
      city_id: 2437,
      region_id: 4,
      name_ar: 'الوسطى',
      name_en: 'Al Wista',
    },
    {
      city_id: 2438,
      region_id: 4,
      name_ar: 'المفيض',
      name_en: 'Al Mafid',
    },
    {
      city_id: 2439,
      region_id: 4,
      name_ar: 'المحروقة',
      name_en: 'Al Mahruqah',
    },
    {
      city_id: 2440,
      region_id: 4,
      name_ar: 'مزارع خريمان',
      name_en: "Mazari' Khurayman",
    },
    {
      city_id: 2441,
      region_id: 4,
      name_ar: 'الربقية',
      name_en: 'Ar Ribqiyah',
    },
    {
      city_id: 2442,
      region_id: 4,
      name_ar: 'ام طليحة',
      name_en: 'Umm Tulayhah',
    },
    {
      city_id: 2443,
      region_id: 4,
      name_ar: 'فردة',
      name_en: 'Fardah',
    },
    {
      city_id: 2444,
      region_id: 4,
      name_ar: 'الناصرية',
      name_en: 'An Nasiriyah',
    },
    {
      city_id: 2445,
      region_id: 4,
      name_ar: 'الخرماء الجنوبية',
      name_en: 'Al Kharma Al Janubiyah',
    },
    {
      city_id: 2446,
      region_id: 4,
      name_ar: 'النعايم',
      name_en: "An Na'ayim",
    },
    {
      city_id: 2447,
      region_id: 4,
      name_ar: 'خريمان',
      name_en: 'Khurayman',
    },
    {
      city_id: 2448,
      region_id: 4,
      name_ar: 'المذنب',
      name_en: 'Al Midhnab',
    },
    {
      city_id: 2449,
      region_id: 4,
      name_ar: 'علباء',
      name_en: "'Alba",
    },
    {
      city_id: 2450,
      region_id: 4,
      name_ar: 'روضة الحسو',
      name_en: 'Rawdat Al Hisu',
    },
    {
      city_id: 2451,
      region_id: 4,
      name_ar: 'المربع',
      name_en: "Al Murabba'",
    },
    {
      city_id: 2452,
      region_id: 4,
      name_ar: 'المحلاوية',
      name_en: 'Al Muhallawiyah',
    },
    {
      city_id: 2453,
      region_id: 4,
      name_ar: 'مزارع الحزم',
      name_en: "Mazari' Al Hazm",
    },
    {
      city_id: 2454,
      region_id: 4,
      name_ar: 'ظليم',
      name_en: 'Dhulyim',
    },
    {
      city_id: 2455,
      region_id: 4,
      name_ar: 'الباطن',
      name_en: 'Al Batin',
    },
    {
      city_id: 2456,
      region_id: 4,
      name_ar: 'المرغلة',
      name_en: 'Al Murghalah',
    },
    {
      city_id: 2457,
      region_id: 4,
      name_ar: 'رغابية',
      name_en: 'Raghabiyah',
    },
    {
      city_id: 2458,
      region_id: 4,
      name_ar: 'الطعمية',
      name_en: "At Tu'miyah",
    },
    {
      city_id: 2459,
      region_id: 4,
      name_ar: 'العداين',
      name_en: "Al 'Adayin",
    },
    {
      city_id: 2460,
      region_id: 4,
      name_ar: 'البويطن',
      name_en: 'Al Buwaytin',
    },
    {
      city_id: 2461,
      region_id: 4,
      name_ar: 'الرقة',
      name_en: 'Ar Riqa',
    },
    {
      city_id: 2462,
      region_id: 4,
      name_ar: 'العبلة',
      name_en: "Al 'Abla",
    },
    {
      city_id: 2463,
      region_id: 4,
      name_ar: 'الروغاني',
      name_en: 'Ar Rawghany',
    },
    {
      city_id: 2464,
      region_id: 5,
      name_ar: 'الخفجي',
      name_en: 'Al Khafji',
    },
    {
      city_id: 2465,
      region_id: 4,
      name_ar: 'العوشزية',
      name_en: 'Al Awshaziyah',
    },
    {
      city_id: 2466,
      region_id: 4,
      name_ar: 'وادى أبو على',
      name_en: "Wadi Abu 'Ali",
    },
    {
      city_id: 2467,
      region_id: 4,
      name_ar: 'رياض الخبراء',
      name_en: 'Riyad Al Khabra',
    },
    {
      city_id: 2468,
      region_id: 4,
      name_ar: 'شعب الحمر',
      name_en: "Shi'b Al Hamar",
    },
    {
      city_id: 2469,
      region_id: 4,
      name_ar: 'صبيح',
      name_en: 'Subayh',
    },
    {
      city_id: 2470,
      region_id: 4,
      name_ar: 'نبيها',
      name_en: 'Nubayha',
    },
    {
      city_id: 2471,
      region_id: 4,
      name_ar: 'الشبيبة',
      name_en: 'Ash Shibibiyah',
    },
    {
      city_id: 2472,
      region_id: 4,
      name_ar: 'البدائع أم تلعة',
      name_en: "Al Bada'i Umm Tal'ah",
    },
    {
      city_id: 2473,
      region_id: 4,
      name_ar: 'البدائع العليا',
      name_en: "Al Badai Al 'Ulya",
    },
    {
      city_id: 2474,
      region_id: 4,
      name_ar: 'العبيلة',
      name_en: "Al 'Ubaylah",
    },
    {
      city_id: 2475,
      region_id: 4,
      name_ar: 'مزرعة عبلا',
      name_en: "Mazra'at At 'Abla",
    },
    {
      city_id: 2476,
      region_id: 4,
      name_ar: 'عبل عبلا',
      name_en: "'Abal 'Abla",
    },
    {
      city_id: 2477,
      region_id: 4,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 2478,
      region_id: 4,
      name_ar: 'العبدلية',
      name_en: "Al 'Abdaliyah",
    },
    {
      city_id: 2479,
      region_id: 4,
      name_ar: 'الحجناوي',
      name_en: 'Al Hajnawi',
    },
    {
      city_id: 2480,
      region_id: 4,
      name_ar: 'الدحلة',
      name_en: 'Ad Dahlah',
    },
    {
      city_id: 2481,
      region_id: 4,
      name_ar: 'البدائع',
      name_en: "Al Badai'",
    },
    {
      city_id: 2482,
      region_id: 4,
      name_ar: 'دهيماء',
      name_en: 'Duhayma',
    },
    {
      city_id: 2483,
      region_id: 4,
      name_ar: 'الابرق',
      name_en: 'Al Abraq',
    },
    {
      city_id: 2484,
      region_id: 4,
      name_ar: 'العبيد',
      name_en: "Al 'Ubayd",
    },
    {
      city_id: 2485,
      region_id: 4,
      name_ar: 'النفيد',
      name_en: 'An Nufayyid',
    },
    {
      city_id: 2486,
      region_id: 4,
      name_ar: 'الخبراء',
      name_en: "Al Khabra'",
    },
    {
      city_id: 2487,
      region_id: 8,
      name_ar: 'المحوى',
      name_en: 'Al Mahawa',
    },
    {
      city_id: 2488,
      region_id: 4,
      name_ar: 'فيضة ذيبان',
      name_en: 'Faydat Dhiban',
    },
    {
      city_id: 2489,
      region_id: 4,
      name_ar: 'مشرف',
      name_en: 'Mushrif',
    },
    {
      city_id: 2490,
      region_id: 4,
      name_ar: 'العابر',
      name_en: "Al 'Abir",
    },
    {
      city_id: 2491,
      region_id: 4,
      name_ar: 'مزارع الحقباء',
      name_en: 'Mazari Al Haqba',
    },
    {
      city_id: 2492,
      region_id: 4,
      name_ar: 'الحجبة',
      name_en: 'Al Hajbah',
    },
    {
      city_id: 2493,
      region_id: 4,
      name_ar: 'الظبة',
      name_en: 'Adh Dhabah',
    },
    {
      city_id: 2494,
      region_id: 4,
      name_ar: 'الدعثة',
      name_en: 'Ad Daathah',
    },
    {
      city_id: 2495,
      region_id: 4,
      name_ar: 'الضبة',
      name_en: 'Ad Dabbah',
    },
    {
      city_id: 2496,
      region_id: 4,
      name_ar: 'الحنية',
      name_en: 'Al Haniyah',
    },
    {
      city_id: 2497,
      region_id: 4,
      name_ar: 'ابو خشبة',
      name_en: 'Abu Khashabah',
    },
    {
      city_id: 2498,
      region_id: 4,
      name_ar: 'أم الخراسع',
      name_en: 'Umm Al Kharasi',
    },
    {
      city_id: 2499,
      region_id: 4,
      name_ar: 'الثامرية',
      name_en: 'Ath Thamiriyah',
    },
    {
      city_id: 2500,
      region_id: 4,
      name_ar: 'الداث',
      name_en: 'Ad Dath',
    },
    {
      city_id: 2501,
      region_id: 4,
      name_ar: 'مهدومة',
      name_en: 'Mahdumah',
    },
    {
      city_id: 2502,
      region_id: 4,
      name_ar: 'مزارع أم أرطى',
      name_en: "Mazari' Umm Arta",
    },
    {
      city_id: 2503,
      region_id: 4,
      name_ar: 'الجرارية',
      name_en: 'Al Jarrariyah',
    },
    {
      city_id: 2504,
      region_id: 4,
      name_ar: 'خربشاء',
      name_en: 'Kharbasha',
    },
    {
      city_id: 2505,
      region_id: 4,
      name_ar: 'الظاهرية',
      name_en: 'Adh Dhahiriyah',
    },
    {
      city_id: 2506,
      region_id: 4,
      name_ar: 'الظاهرية الجنوبية',
      name_en: 'Adh Dhahiriyah Al Janubiyah',
    },
    {
      city_id: 2507,
      region_id: 4,
      name_ar: 'الناعمة',
      name_en: "An Na'imah",
    },
    {
      city_id: 2508,
      region_id: 4,
      name_ar: 'الوطاة',
      name_en: 'Al Watah',
    },
    {
      city_id: 2509,
      region_id: 4,
      name_ar: 'الغضياء والسلمات',
      name_en: 'Al Ghadya And Salmat',
    },
    {
      city_id: 2510,
      region_id: 4,
      name_ar: 'مزارع ثويدج',
      name_en: "Mazari' Thuwaydij",
    },
    {
      city_id: 2511,
      region_id: 4,
      name_ar: 'الجرير',
      name_en: 'Al Jarir',
    },
    {
      city_id: 2512,
      region_id: 4,
      name_ar: 'القيصومة',
      name_en: 'Al Qaysumah',
    },
    {
      city_id: 2513,
      region_id: 6,
      name_ar: 'الكنهبلة',
      name_en: 'Al Kanahbilah',
    },
    {
      city_id: 2514,
      region_id: 6,
      name_ar: 'السهوة',
      name_en: 'As Sahwah',
    },
    {
      city_id: 2515,
      region_id: 6,
      name_ar: 'يانف',
      name_en: 'Yanif',
    },
    {
      city_id: 2516,
      region_id: 6,
      name_ar: 'حلباء',
      name_en: 'Halaba',
    },
    {
      city_id: 2517,
      region_id: 6,
      name_ar: 'العدوة',
      name_en: "Al 'Idwah",
    },
    {
      city_id: 2518,
      region_id: 6,
      name_ar: 'الطلحة',
      name_en: 'At Talhah',
    },
    {
      city_id: 2519,
      region_id: 6,
      name_ar: 'النماص',
      name_en: 'An Namas',
    },
    {
      city_id: 2520,
      region_id: 11,
      name_ar: 'حمراء نثيل / ابو شداد',
      name_en: 'Hamrah Nithil (Abu Shadad)',
    },
    {
      city_id: 2521,
      region_id: 11,
      name_ar: 'خجيم',
      name_en: 'Khajim',
    },
    {
      city_id: 2522,
      region_id: 11,
      name_ar: 'يدمة',
      name_en: 'Yadamah',
    },
    {
      city_id: 2523,
      region_id: 11,
      name_ar: 'ابا الرخم',
      name_en: 'Aba Ar Rakham',
    },
    {
      city_id: 2524,
      region_id: 11,
      name_ar: 'مركز الوجيد',
      name_en: 'Markaz Al Wujid',
    },
    {
      city_id: 2525,
      region_id: 11,
      name_ar: 'ظلماء',
      name_en: 'Dhalma',
    },
    {
      city_id: 2526,
      region_id: 6,
      name_ar: 'تباشعة',
      name_en: "Tubash'ah",
    },
    {
      city_id: 2527,
      region_id: 6,
      name_ar: 'أم خيسة',
      name_en: 'Umm Khisah',
    },
    {
      city_id: 2528,
      region_id: 6,
      name_ar: 'صلحلح',
      name_en: 'Salahlah',
    },
    {
      city_id: 2529,
      region_id: 6,
      name_ar: 'آل خضاري',
      name_en: 'Al Khidari',
    },
    {
      city_id: 2530,
      region_id: 8,
      name_ar: 'النقرة',
      name_en: 'An Nuqrah',
    },
    {
      city_id: 2531,
      region_id: 8,
      name_ar: 'كمب الضلوع',
      name_en: "Kamp Ad Dulu'",
    },
    {
      city_id: 2532,
      region_id: 8,
      name_ar: 'الحفير',
      name_en: 'Al Hufayr',
    },
    {
      city_id: 2533,
      region_id: 8,
      name_ar: 'الودي',
      name_en: 'Al Wuday',
    },
    {
      city_id: 2534,
      region_id: 8,
      name_ar: 'قصر العشروات',
      name_en: 'Qasr Al Ishruwat',
    },
    {
      city_id: 2535,
      region_id: 8,
      name_ar: 'سراء',
      name_en: "Sara'",
    },
    {
      city_id: 2536,
      region_id: 8,
      name_ar: 'فيضة بن سويلم',
      name_en: 'Faydat Ibn Suwaylim',
    },
    {
      city_id: 2537,
      region_id: 8,
      name_ar: 'النيصية',
      name_en: 'An Nisiyah',
    },
    {
      city_id: 2538,
      region_id: 8,
      name_ar: 'مريفق',
      name_en: 'Murayfiq',
    },
    {
      city_id: 2539,
      region_id: 8,
      name_ar: 'قفار',
      name_en: 'Qufar',
    },
    {
      city_id: 2540,
      region_id: 8,
      name_ar: 'توارين',
      name_en: 'Tuwarin',
    },
    {
      city_id: 2541,
      region_id: 8,
      name_ar: 'عقدة',
      name_en: "'Iqdah",
    },
    {
      city_id: 2542,
      region_id: 8,
      name_ar: 'المحفر',
      name_en: 'Al Mahaffar',
    },
    {
      city_id: 2543,
      region_id: 8,
      name_ar: 'المختلف',
      name_en: 'Al Mukhtalif',
    },
    {
      city_id: 2544,
      region_id: 8,
      name_ar: 'الصهوة',
      name_en: 'As Sahwah',
    },
    {
      city_id: 2545,
      region_id: 8,
      name_ar: 'اللويمي',
      name_en: 'Al Luwaymi',
    },
    {
      city_id: 2546,
      region_id: 8,
      name_ar: 'المستجدة',
      name_en: 'Al Mustajiddah',
    },
    {
      city_id: 2547,
      region_id: 8,
      name_ar: 'العجاجة',
      name_en: "Al 'Ajajah",
    },
    {
      city_id: 2548,
      region_id: 8,
      name_ar: 'الرقب',
      name_en: 'Ar Raqab',
    },
    {
      city_id: 2549,
      region_id: 8,
      name_ar: 'النمارة',
      name_en: 'An Nimarah',
    },
    {
      city_id: 2550,
      region_id: 8,
      name_ar: 'العيثمة',
      name_en: "Al 'Aythamah",
    },
    {
      city_id: 2551,
      region_id: 8,
      name_ar: 'لبدة',
      name_en: 'Libdah',
    },
    {
      city_id: 2552,
      region_id: 8,
      name_ar: 'ضرغط',
      name_en: 'Darghat',
    },
    {
      city_id: 2553,
      region_id: 8,
      name_ar: 'ضريغط',
      name_en: 'Duraighat',
    },
    {
      city_id: 2554,
      region_id: 8,
      name_ar: 'الحميمة',
      name_en: 'Al Humaimah',
    },
    {
      city_id: 2555,
      region_id: 8,
      name_ar: 'بدائع اول',
      name_en: "Badai' Awl",
    },
    {
      city_id: 2556,
      region_id: 8,
      name_ar: 'الثمامية',
      name_en: 'Ath Thumamiyah',
    },
    {
      city_id: 2557,
      region_id: 8,
      name_ar: 'الحليفة العليا',
      name_en: "Al Hulayfah Al 'Ulya",
    },
    {
      city_id: 2558,
      region_id: 8,
      name_ar: 'مرحب الشمالي',
      name_en: 'Marhab Ash Shamali',
    },
    {
      city_id: 2559,
      region_id: 8,
      name_ar: 'العرادية',
      name_en: "Al 'Aradiyah",
    },
    {
      city_id: 2560,
      region_id: 8,
      name_ar: 'فيضة اثقب',
      name_en: 'Faydat Athqab',
    },
    {
      city_id: 2561,
      region_id: 8,
      name_ar: 'روض ابن هادي',
      name_en: 'Rawd Ibn Hadi',
    },
    {
      city_id: 2562,
      region_id: 8,
      name_ar: 'الوسعة',
      name_en: "Al Was'ah",
    },
    {
      city_id: 2563,
      region_id: 8,
      name_ar: 'الشويمس',
      name_en: 'Ash Shiwaymis',
    },
    {
      city_id: 2564,
      region_id: 8,
      name_ar: 'البرقة',
      name_en: 'Al Barqah',
    },
    {
      city_id: 2565,
      region_id: 8,
      name_ar: 'الدابية',
      name_en: 'Ad Dabiyah',
    },
    {
      city_id: 2566,
      region_id: 8,
      name_ar: 'الحويط',
      name_en: 'Al Huwayyit',
    },
    {
      city_id: 2567,
      region_id: 8,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 2568,
      region_id: 8,
      name_ar: 'عمائر المرير',
      name_en: "Ama'ir Al Murayr",
    },
    {
      city_id: 2569,
      region_id: 8,
      name_ar: 'بدع ابن خلف',
      name_en: "Bid' Ibn Khalaf",
    },
    {
      city_id: 2570,
      region_id: 8,
      name_ar: 'المرير',
      name_en: 'Al Murayr',
    },
    {
      city_id: 2571,
      region_id: 8,
      name_ar: 'ام روشن',
      name_en: 'Umm Rawshan',
    },
    {
      city_id: 2572,
      region_id: 8,
      name_ar: 'صفيط',
      name_en: 'Sufayt',
    },
    {
      city_id: 2573,
      region_id: 8,
      name_ar: 'النحيتية',
      name_en: 'An Nuhaytiyah',
    },
    {
      city_id: 2574,
      region_id: 8,
      name_ar: 'مبرز',
      name_en: 'Mubaraz',
    },
    {
      city_id: 2575,
      region_id: 8,
      name_ar: 'المعرش',
      name_en: "Al Ma'arrash",
    },
    {
      city_id: 2576,
      region_id: 8,
      name_ar: 'العوشزية',
      name_en: 'Al Awshaziyah',
    },
    {
      city_id: 2577,
      region_id: 8,
      name_ar: 'وسيطاء الحفن',
      name_en: "Wusayta' Al Hafan",
    },
    {
      city_id: 2578,
      region_id: 8,
      name_ar: 'البعايث',
      name_en: "Al Ba'ayith",
    },
    {
      city_id: 2579,
      region_id: 8,
      name_ar: 'الزغيبية',
      name_en: 'Az Zughaibiyah',
    },
    {
      city_id: 2580,
      region_id: 8,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 2581,
      region_id: 8,
      name_ar: 'بدائع الصغوي',
      name_en: "Badai' As Sighwa",
    },
    {
      city_id: 2582,
      region_id: 8,
      name_ar: 'الصغوى',
      name_en: 'As Sighawa',
    },
    {
      city_id: 2583,
      region_id: 4,
      name_ar: 'فيضة يكلب',
      name_en: 'Faydat Yaklib',
    },
    {
      city_id: 2584,
      region_id: 4,
      name_ar: 'مزارع القرائن',
      name_en: "Mazari' Al Qarain",
    },
    {
      city_id: 2585,
      region_id: 4,
      name_ar: 'النويبات',
      name_en: 'An Nuwaybat',
    },
    {
      city_id: 2586,
      region_id: 4,
      name_ar: 'مزرعة مشرفة',
      name_en: "Mazra'at Mushrifah",
    },
    {
      city_id: 2587,
      region_id: 4,
      name_ar: 'المخرم',
      name_en: 'Al Makhram',
    },
    {
      city_id: 2588,
      region_id: 4,
      name_ar: 'الطراق',
      name_en: 'At Tiraq',
    },
    {
      city_id: 2589,
      region_id: 4,
      name_ar: 'الصلبية',
      name_en: 'As Sulubiyah',
    },
    {
      city_id: 2590,
      region_id: 5,
      name_ar: 'راس تنورة',
      name_en: 'Ras Tannurah',
    },
    {
      city_id: 2591,
      region_id: 5,
      name_ar: 'رحيمة',
      name_en: 'Rahimah',
    },
    {
      city_id: 2592,
      region_id: 8,
      name_ar: 'بدائع الصفران',
      name_en: "Badai' As Sufran",
    },
    {
      city_id: 2593,
      region_id: 8,
      name_ar: 'حمر القعساء',
      name_en: "Humr Al Qa'asa",
    },
    {
      city_id: 2594,
      region_id: 8,
      name_ar: 'حريد',
      name_en: 'Hurayd',
    },
    {
      city_id: 2595,
      region_id: 8,
      name_ar: 'الديماسة',
      name_en: 'Ad Dimasah',
    },
    {
      city_id: 2596,
      region_id: 8,
      name_ar: 'القعساء',
      name_en: "Al Qa'asa",
    },
    {
      city_id: 2597,
      region_id: 8,
      name_ar: 'وتدة',
      name_en: 'Wutidah',
    },
    {
      city_id: 2598,
      region_id: 8,
      name_ar: 'عقيلة اللبن',
      name_en: 'Uqaylat Al Laban',
    },
    {
      city_id: 2599,
      region_id: 8,
      name_ar: 'البلازية',
      name_en: 'Al Ballaziyah',
    },
    {
      city_id: 2600,
      region_id: 8,
      name_ar: 'خنقة الرماحي',
      name_en: 'Khanqat Ar Rimahi',
    },
    {
      city_id: 2601,
      region_id: 8,
      name_ar: 'غسل',
      name_en: 'Ghisil',
    },
    {
      city_id: 2602,
      region_id: 8,
      name_ar: 'سميراء',
      name_en: "Simira'",
    },
    {
      city_id: 2603,
      region_id: 8,
      name_ar: 'الرفايع',
      name_en: "Ar Rufayi'",
    },
    {
      city_id: 2604,
      region_id: 8,
      name_ar: 'قصير متروك',
      name_en: 'Qusayr Matruk',
    },
    {
      city_id: 2605,
      region_id: 8,
      name_ar: 'كتيفة',
      name_en: 'Kutaifah',
    },
    {
      city_id: 2606,
      region_id: 8,
      name_ar: 'وسيط',
      name_en: 'Wusayt',
    },
    {
      city_id: 2607,
      region_id: 4,
      name_ar: 'نفجة',
      name_en: 'Nafjah',
    },
    {
      city_id: 2608,
      region_id: 4,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 2609,
      region_id: 4,
      name_ar: 'المعدا',
      name_en: "Al Ma'ada",
    },
    {
      city_id: 2610,
      region_id: 4,
      name_ar: 'مزارع الشيحية',
      name_en: 'Mazari Ash Shihiyah',
    },
    {
      city_id: 2611,
      region_id: 4,
      name_ar: 'الشيحية',
      name_en: 'Ash Shihiyah',
    },
    {
      city_id: 2612,
      region_id: 4,
      name_ar: 'أم قدة',
      name_en: 'Umm Qiddah',
    },
    {
      city_id: 2613,
      region_id: 4,
      name_ar: 'قليب خنيفسة',
      name_en: 'Qalib Khunayfisah',
    },
    {
      city_id: 2614,
      region_id: 4,
      name_ar: 'مزرعة الغبية',
      name_en: "Mazra'at Al Ghibyah",
    },
    {
      city_id: 2615,
      region_id: 4,
      name_ar: 'أم العراد',
      name_en: "Umm Al 'Arad",
    },
    {
      city_id: 2616,
      region_id: 4,
      name_ar: 'الثابتية',
      name_en: 'Ath Thabitiyah',
    },
    {
      city_id: 2617,
      region_id: 4,
      name_ar: 'بقيعاء الشمالية',
      name_en: "Buqay'a Ash Shamaliyah",
    },
    {
      city_id: 2618,
      region_id: 4,
      name_ar: 'مزارع الرفائع',
      name_en: "Mazari' Ar Rufai'",
    },
    {
      city_id: 2619,
      region_id: 4,
      name_ar: 'عسيلة',
      name_en: "'Usaylah",
    },
    {
      city_id: 2620,
      region_id: 4,
      name_ar: 'النجبة',
      name_en: 'An Nijibah',
    },
    {
      city_id: 2621,
      region_id: 4,
      name_ar: 'قليب الرجيمية',
      name_en: 'Qalib Ar Rujaymiyah',
    },
    {
      city_id: 2622,
      region_id: 4,
      name_ar: 'عقدة',
      name_en: "'Iqdah",
    },
    {
      city_id: 2623,
      region_id: 4,
      name_ar: 'أم خطوط',
      name_en: 'Umm Khutut',
    },
    {
      city_id: 2624,
      region_id: 4,
      name_ar: 'مزارع الوسيطاء',
      name_en: "Mazari' Al Wusayta",
    },
    {
      city_id: 2625,
      region_id: 8,
      name_ar: 'المكحول',
      name_en: 'Al Makhul',
    },
    {
      city_id: 2626,
      region_id: 4,
      name_ar: 'كحلة',
      name_en: 'Kahlah',
    },
    {
      city_id: 2627,
      region_id: 4,
      name_ar: 'الأرطاوي الشمالي',
      name_en: 'Al Artawi Ash Shamali',
    },
    {
      city_id: 2628,
      region_id: 4,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 2629,
      region_id: 4,
      name_ar: 'الهلالية',
      name_en: 'Al Hilaliyah',
    },
    {
      city_id: 2630,
      region_id: 4,
      name_ar: 'البكيرية',
      name_en: 'Al Bukayriyah',
    },
    {
      city_id: 2631,
      region_id: 4,
      name_ar: 'الفويلق',
      name_en: 'Al Fuwayliq',
    },
    {
      city_id: 2632,
      region_id: 4,
      name_ar: 'مشاش جرود',
      name_en: 'Mishash Jurud',
    },
    {
      city_id: 2633,
      region_id: 4,
      name_ar: 'الضلفعة',
      name_en: "Ad Dalfa'ah",
    },
    {
      city_id: 2634,
      region_id: 4,
      name_ar: 'الطوال',
      name_en: 'At Tuwal',
    },
    {
      city_id: 2635,
      region_id: 4,
      name_ar: 'العليا',
      name_en: "Al 'Ulayya",
    },
    {
      city_id: 2636,
      region_id: 4,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 2637,
      region_id: 4,
      name_ar: 'الشباكية',
      name_en: 'Ash Shabbakiyah',
    },
    {
      city_id: 2638,
      region_id: 8,
      name_ar: 'الجابرية',
      name_en: 'Al Jabiriyah',
    },
    {
      city_id: 2639,
      region_id: 5,
      name_ar: 'المراح',
      name_en: 'Al Marah',
    },
    {
      city_id: 2640,
      region_id: 5,
      name_ar: 'جودة',
      name_en: 'Judah',
    },
    {
      city_id: 2641,
      region_id: 5,
      name_ar: 'سلوى',
      name_en: 'Salwa',
    },
    {
      city_id: 2642,
      region_id: 5,
      name_ar: 'العقير',
      name_en: 'Al Uqayr',
    },
    {
      city_id: 2643,
      region_id: 5,
      name_ar: 'يبرين',
      name_en: 'Yabrin / Jabrin',
    },
    {
      city_id: 2644,
      region_id: 5,
      name_ar: 'البطالية',
      name_en: 'Al Bataliyah',
    },
    {
      city_id: 2645,
      region_id: 5,
      name_ar: 'الشقيق',
      name_en: 'Ash Shaqiq',
    },
    {
      city_id: 2646,
      region_id: 5,
      name_ar: 'القرين',
      name_en: 'Al Qurayn',
    },
    {
      city_id: 2647,
      region_id: 5,
      name_ar: 'الوزية',
      name_en: 'Al Wizyah',
    },
    {
      city_id: 2648,
      region_id: 5,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 2649,
      region_id: 5,
      name_ar: 'الخرس',
      name_en: 'Al Khars',
    },
    {
      city_id: 2650,
      region_id: 5,
      name_ar: 'الغويبة',
      name_en: 'Al Ghuwaybah',
    },
    {
      city_id: 2651,
      region_id: 5,
      name_ar: 'مريطبة',
      name_en: 'Muraytibah',
    },
    {
      city_id: 2652,
      region_id: 5,
      name_ar: 'سوده',
      name_en: 'Sudah',
    },
    {
      city_id: 2653,
      region_id: 5,
      name_ar: 'العمران',
      name_en: 'Al Umran',
    },
    {
      city_id: 2654,
      region_id: 6,
      name_ar: 'آل مجادب',
      name_en: 'Al Mijadib',
    },
    {
      city_id: 2655,
      region_id: 6,
      name_ar: 'السبت',
      name_en: 'As Sabt',
    },
    {
      city_id: 2656,
      region_id: 6,
      name_ar: 'آل الصعدي',
      name_en: "Al As Sa'di",
    },
    {
      city_id: 2657,
      region_id: 6,
      name_ar: 'سدوان',
      name_en: 'Sadwan',
    },
    {
      city_id: 2658,
      region_id: 6,
      name_ar: 'الشعف',
      name_en: "Ash Sha'af",
    },
    {
      city_id: 2659,
      region_id: 6,
      name_ar: 'لربوعة',
      name_en: "Larbu'ah",
    },
    {
      city_id: 2660,
      region_id: 6,
      name_ar: 'قريش',
      name_en: 'Qiraysh',
    },
    {
      city_id: 2661,
      region_id: 6,
      name_ar: 'آل دحمان',
      name_en: 'Al Dahman',
    },
    {
      city_id: 2662,
      region_id: 6,
      name_ar: 'الجفر',
      name_en: 'Al Jafr',
    },
    {
      city_id: 2663,
      region_id: 6,
      name_ar: 'النماص',
      name_en: 'An Namas',
    },
    {
      city_id: 2664,
      region_id: 6,
      name_ar: 'شوحطة',
      name_en: 'Shuhitah',
    },
    {
      city_id: 2665,
      region_id: 6,
      name_ar: 'الرهوة',
      name_en: 'Ar Rahwah',
    },
    {
      city_id: 2666,
      region_id: 6,
      name_ar: 'قاها',
      name_en: 'Qaha',
    },
    {
      city_id: 2667,
      region_id: 6,
      name_ar: 'آل عزة',
      name_en: "Al 'Azzah",
    },
    {
      city_id: 2668,
      region_id: 6,
      name_ar: 'بيحان',
      name_en: 'Bihan',
    },
    {
      city_id: 2669,
      region_id: 6,
      name_ar: 'قرآنية',
      name_en: 'Quraniyah',
    },
    {
      city_id: 2670,
      region_id: 6,
      name_ar: 'العطوف',
      name_en: "Al 'Utuf",
    },
    {
      city_id: 2671,
      region_id: 4,
      name_ar: 'طلحة',
      name_en: 'Talhah',
    },
    {
      city_id: 2672,
      region_id: 8,
      name_ar: 'القصير',
      name_en: 'Al Qusayr',
    },
    {
      city_id: 2673,
      region_id: 8,
      name_ar: 'المهاش',
      name_en: 'Al Mahash',
    },
    {
      city_id: 2674,
      region_id: 8,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 2675,
      region_id: 8,
      name_ar: 'بدائع قني',
      name_en: "Badai' Qunay",
    },
    {
      city_id: 2676,
      region_id: 8,
      name_ar: 'مغيضة',
      name_en: 'Mughaydah',
    },
    {
      city_id: 2677,
      region_id: 8,
      name_ar: 'بطيحان',
      name_en: 'Butayhan',
    },
    {
      city_id: 2678,
      region_id: 8,
      name_ar: 'القحصية',
      name_en: 'Al Qahsiyah',
    },
    {
      city_id: 2679,
      region_id: 8,
      name_ar: 'الدوادمي',
      name_en: 'Ad Duwadimi',
    },
    {
      city_id: 2680,
      region_id: 8,
      name_ar: 'البنانة',
      name_en: 'Al Bananah',
    },
    {
      city_id: 2681,
      region_id: 8,
      name_ar: 'الركية',
      name_en: 'Ar Rukayyah',
    },
    {
      city_id: 2682,
      region_id: 8,
      name_ar: 'مرموثة العلم',
      name_en: "Marmuthat Al 'Alam",
    },
    {
      city_id: 2683,
      region_id: 8,
      name_ar: 'نخلة',
      name_en: 'Nakhlah',
    },
    {
      city_id: 2684,
      region_id: 8,
      name_ar: 'النميرة',
      name_en: 'An Numayyirah',
    },
    {
      city_id: 2685,
      region_id: 8,
      name_ar: 'قصيباء',
      name_en: 'Qusayba',
    },
    {
      city_id: 2686,
      region_id: 8,
      name_ar: 'ملحاء',
      name_en: 'Malha',
    },
    {
      city_id: 2687,
      region_id: 8,
      name_ar: 'الطرفاوي',
      name_en: 'At Tarfawi',
    },
    {
      city_id: 2688,
      region_id: 8,
      name_ar: 'الشقة',
      name_en: 'Ash Shiqqah',
    },
    {
      city_id: 2689,
      region_id: 8,
      name_ar: 'شعيلا المصع',
      name_en: 'Shuayla Al Masae',
    },
    {
      city_id: 2690,
      region_id: 8,
      name_ar: 'بدائع الفقي',
      name_en: "Badai' Al Fuqayy",
    },
    {
      city_id: 2691,
      region_id: 8,
      name_ar: 'أم هشيم',
      name_en: 'Umm Hashim',
    },
    {
      city_id: 2692,
      region_id: 8,
      name_ar: 'خفيج أم هشيم',
      name_en: 'Khufayj Umm Hashim',
    },
    {
      city_id: 2693,
      region_id: 8,
      name_ar: 'الباحة',
      name_en: 'Al Baha',
    },
    {
      city_id: 2694,
      region_id: 8,
      name_ar: 'العمائر',
      name_en: "Al 'Amair",
    },
    {
      city_id: 2695,
      region_id: 8,
      name_ar: 'الصور',
      name_en: 'As Sur',
    },
    {
      city_id: 2696,
      region_id: 8,
      name_ar: 'قرية عشيرة',
      name_en: "Qaryat 'Ishayrah",
    },
    {
      city_id: 2697,
      region_id: 8,
      name_ar: 'عقلة المخروق',
      name_en: 'Uqlat Al Makhruq',
    },
    {
      city_id: 2698,
      region_id: 8,
      name_ar: 'الدغيبجة',
      name_en: 'Al Dughaibjah',
    },
    {
      city_id: 2699,
      region_id: 8,
      name_ar: 'مصدة',
      name_en: 'Masadah',
    },
    {
      city_id: 2700,
      region_id: 8,
      name_ar: 'أبلة',
      name_en: 'Ablah',
    },
    {
      city_id: 2701,
      region_id: 8,
      name_ar: 'القصيلة',
      name_en: 'Al Qusailah',
    },
    {
      city_id: 2702,
      region_id: 8,
      name_ar: 'السليمي',
      name_en: 'As Sulaymi',
    },
    {
      city_id: 2703,
      region_id: 8,
      name_ar: 'الحماد',
      name_en: 'Al Hamad',
    },
    {
      city_id: 2704,
      region_id: 8,
      name_ar: 'الوهيبيات',
      name_en: 'Al Wuhaybiyat',
    },
    {
      city_id: 2705,
      region_id: 8,
      name_ar: 'أرينبة',
      name_en: 'Uraynibah',
    },
    {
      city_id: 2706,
      region_id: 8,
      name_ar: 'فرتاج',
      name_en: 'Firtaj',
    },
    {
      city_id: 2707,
      region_id: 8,
      name_ar: 'الشبرية الصفراء',
      name_en: 'Ash Shibriyah As Safra',
    },
    {
      city_id: 2708,
      region_id: 8,
      name_ar: 'الشبرية الحمراء',
      name_en: 'Ash Shibriyah Al Hamra',
    },
    {
      city_id: 2709,
      region_id: 8,
      name_ar: 'الوسيطي',
      name_en: 'Al Wusayti',
    },
    {
      city_id: 2710,
      region_id: 8,
      name_ar: 'الصخنة',
      name_en: 'As Sikhinah',
    },
    {
      city_id: 2711,
      region_id: 8,
      name_ar: 'أفيعية',
      name_en: "Afay'iyah",
    },
    {
      city_id: 2712,
      region_id: 8,
      name_ar: 'الهمجة',
      name_en: 'Al Hamjah',
    },
    {
      city_id: 2713,
      region_id: 8,
      name_ar: 'المعاذب',
      name_en: "Al Ma'adhib",
    },
    {
      city_id: 2714,
      region_id: 8,
      name_ar: 'الهدية',
      name_en: 'Al Hudayyah',
    },
    {
      city_id: 2715,
      region_id: 8,
      name_ar: 'الغزالة',
      name_en: 'Al Ghazalah',
    },
    {
      city_id: 2716,
      region_id: 8,
      name_ar: 'الغريسة',
      name_en: 'Al Gharisah',
    },
    {
      city_id: 2717,
      region_id: 8,
      name_ar: 'المجيصة',
      name_en: 'Al Mijayssah',
    },
    {
      city_id: 2718,
      region_id: 8,
      name_ar: 'البركة',
      name_en: 'Al Birkah',
    },
    {
      city_id: 2719,
      region_id: 8,
      name_ar: 'طلوح',
      name_en: 'Tuluh',
    },
    {
      city_id: 2720,
      region_id: 8,
      name_ar: 'الحليفة السفلى',
      name_en: 'Al Hulayfah As Suflah',
    },
    {
      city_id: 2721,
      region_id: 8,
      name_ar: 'الحائط',
      name_en: 'Al Hait',
    },
    {
      city_id: 2722,
      region_id: 8,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 2723,
      region_id: 8,
      name_ar: 'قاع حجلا',
      name_en: "Qa' Hajla",
    },
    {
      city_id: 2724,
      region_id: 8,
      name_ar: 'الخفج',
      name_en: 'Al Khufj',
    },
    {
      city_id: 2725,
      region_id: 8,
      name_ar: 'الفقي',
      name_en: 'Al Fuqay',
    },
    {
      city_id: 2726,
      region_id: 8,
      name_ar: 'النبوان',
      name_en: 'An Nabuwan',
    },
    {
      city_id: 2727,
      region_id: 8,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 2728,
      region_id: 8,
      name_ar: 'العوشري',
      name_en: 'Al Awshari',
    },
    {
      city_id: 2729,
      region_id: 8,
      name_ar: 'مرغان',
      name_en: 'Marghan',
    },
    {
      city_id: 2730,
      region_id: 8,
      name_ar: 'الهويدي',
      name_en: 'Al Huwaidi',
    },
    {
      city_id: 2731,
      region_id: 8,
      name_ar: 'القصيصة',
      name_en: 'Al Qusaysah',
    },
    {
      city_id: 2732,
      region_id: 8,
      name_ar: 'المسجد',
      name_en: 'Al Masjid',
    },
    {
      city_id: 2733,
      region_id: 8,
      name_ar: 'سقف',
      name_en: 'Saqf',
    },
    {
      city_id: 2734,
      region_id: 8,
      name_ar: 'بدع ابن حويط',
      name_en: "Bid' Bin Huwayyit",
    },
    {
      city_id: 2735,
      region_id: 8,
      name_ar: 'بدائع الصداعية',
      name_en: "Badai' As Suda'iyah",
    },
    {
      city_id: 2736,
      region_id: 8,
      name_ar: 'ريع البكر',
      name_en: "Ri' Al Bakr",
    },
    {
      city_id: 2737,
      region_id: 5,
      name_ar: 'الكلابية',
      name_en: 'Al Kilabiyah',
    },
    {
      city_id: 2738,
      region_id: 5,
      name_ar: 'المنيزلة',
      name_en: 'Al Munaizlah',
    },
    {
      city_id: 2739,
      region_id: 5,
      name_ar: 'القارة',
      name_en: 'Al Qarah',
    },
    {
      city_id: 2740,
      region_id: 5,
      name_ar: 'المنصورة',
      name_en: 'Al Mansurah',
    },
    {
      city_id: 2741,
      region_id: 5,
      name_ar: 'المزاوي',
      name_en: 'Al Mazawi',
    },
    {
      city_id: 2742,
      region_id: 5,
      name_ar: 'الحوطة',
      name_en: 'Al Hawtah',
    },
    {
      city_id: 2743,
      region_id: 5,
      name_ar: 'المركز',
      name_en: 'Al Markaz',
    },
    {
      city_id: 2744,
      region_id: 5,
      name_ar: 'الفضول',
      name_en: 'Al Fudul',
    },
    {
      city_id: 2745,
      region_id: 5,
      name_ar: 'الرميلة',
      name_en: 'Ar Rumaylah',
    },
    {
      city_id: 2746,
      region_id: 5,
      name_ar: 'الجشة',
      name_en: 'Al Jishshah',
    },
    {
      city_id: 2747,
      region_id: 5,
      name_ar: 'الشعبة',
      name_en: "Ash Shu'abah",
    },
    {
      city_id: 2748,
      region_id: 5,
      name_ar: 'المبرز',
      name_en: 'Al Mubarraz',
    },
    {
      city_id: 2749,
      region_id: 5,
      name_ar: 'الجليجلة',
      name_en: 'Al Julijlah',
    },
    {
      city_id: 2750,
      region_id: 5,
      name_ar: 'المطيرفي',
      name_en: 'Al Mutairifi',
    },
    {
      city_id: 2752,
      region_id: 5,
      name_ar: 'بني معن',
      name_en: "Bani Mu'n",
    },
    {
      city_id: 2754,
      region_id: 5,
      name_ar: 'الخن',
      name_en: 'Al Khinn',
    },
    {
      city_id: 2755,
      region_id: 5,
      name_ar: 'ام اثلة',
      name_en: 'Umm Athlah',
    },
    {
      city_id: 2756,
      region_id: 5,
      name_ar: 'خريص',
      name_en: 'Khurays',
    },
    {
      city_id: 2757,
      region_id: 5,
      name_ar: 'عريعرة',
      name_en: "Uray'irah",
    },
    {
      city_id: 2758,
      region_id: 5,
      name_ar: 'متالع / ام تالع',
      name_en: "Matali' / Umm Tali'",
    },
    {
      city_id: 2759,
      region_id: 5,
      name_ar: 'التويثير',
      name_en: 'Al Tuwaythir',
    },
    {
      city_id: 2760,
      region_id: 5,
      name_ar: 'الدالوة',
      name_en: 'Ad Dalwah',
    },
    {
      city_id: 2761,
      region_id: 5,
      name_ar: 'الشهادين',
      name_en: 'Ash Shahadin',
    },
    {
      city_id: 2762,
      region_id: 5,
      name_ar: 'الحليلة',
      name_en: 'Al Halilah',
    },
    {
      city_id: 2763,
      region_id: 5,
      name_ar: 'الطرف',
      name_en: 'At Taraf',
    },
    {
      city_id: 2764,
      region_id: 5,
      name_ar: 'الجفر',
      name_en: 'Al Jafr',
    },
    {
      city_id: 2765,
      region_id: 5,
      name_ar: 'الحني',
      name_en: 'Al Hunayy',
    },
    {
      city_id: 2766,
      region_id: 5,
      name_ar: 'القصيبي',
      name_en: 'Al Qusaybi',
    },
    {
      city_id: 2767,
      region_id: 5,
      name_ar: 'الحبل',
      name_en: 'Al Habl',
    },
    {
      city_id: 2768,
      region_id: 5,
      name_ar: 'الحرملية',
      name_en: 'Al Harmaliyah',
    },
    {
      city_id: 2769,
      region_id: 5,
      name_ar: 'نباك',
      name_en: 'Nibak',
    },
    {
      city_id: 2770,
      region_id: 4,
      name_ar: 'البرجسية',
      name_en: 'Al Barjasiyah',
    },
    {
      city_id: 2771,
      region_id: 4,
      name_ar: 'مزرعة الزرقاء',
      name_en: "Mazra'at Az Zarqa",
    },
    {
      city_id: 2772,
      region_id: 4,
      name_ar: 'مزارع أم ظهيرة',
      name_en: "Mazrai' Umm Dhuhayrah",
    },
    {
      city_id: 2773,
      region_id: 4,
      name_ar: 'النبتى رية',
      name_en: 'An Nabta Rayyah',
    },
    {
      city_id: 2774,
      region_id: 4,
      name_ar: 'الرويضة',
      name_en: 'Ar Ruwaydah',
    },
    {
      city_id: 2775,
      region_id: 4,
      name_ar: 'الركية',
      name_en: 'Ar Rukayyah',
    },
    {
      city_id: 2776,
      region_id: 4,
      name_ar: 'ام طليحة',
      name_en: 'Umm Tulayhah',
    },
    {
      city_id: 2777,
      region_id: 4,
      name_ar: 'الشماسية',
      name_en: 'Ash Shimasiyah',
    },
    {
      city_id: 2778,
      region_id: 4,
      name_ar: 'الربيعية',
      name_en: "Ar Rabi'iyah",
    },
    {
      city_id: 2779,
      region_id: 4,
      name_ar: 'العويقلية',
      name_en: "Al 'Uwayqiliyah",
    },
    {
      city_id: 2780,
      region_id: 4,
      name_ar: 'النبقية',
      name_en: 'An Nubqiyah',
    },
    {
      city_id: 2781,
      region_id: 4,
      name_ar: 'أم حزم',
      name_en: 'Umm Hazm',
    },
    {
      city_id: 2782,
      region_id: 7,
      name_ar: 'الخشة',
      name_en: 'Al Khashah',
    },
    {
      city_id: 2783,
      region_id: 4,
      name_ar: 'القرين',
      name_en: 'Al Qurayn',
    },
    {
      city_id: 2784,
      region_id: 4,
      name_ar: 'الهبيرية',
      name_en: 'Al Hubayriyah',
    },
    {
      city_id: 2785,
      region_id: 4,
      name_ar: 'البدع',
      name_en: "Al Bada'",
    },
    {
      city_id: 2786,
      region_id: 4,
      name_ar: 'ساق',
      name_en: 'Saq',
    },
    {
      city_id: 2787,
      region_id: 4,
      name_ar: 'الشقران',
      name_en: 'Ash Shuqran',
    },
    {
      city_id: 2788,
      region_id: 4,
      name_ar: 'البكرة',
      name_en: 'Al Bakrah',
    },
    {
      city_id: 2789,
      region_id: 4,
      name_ar: 'دوبح',
      name_en: 'Dawbah',
    },
    {
      city_id: 2790,
      region_id: 4,
      name_ar: 'الفيضة',
      name_en: 'Al Faydah',
    },
    {
      city_id: 2791,
      region_id: 4,
      name_ar: 'طوال جبيرة',
      name_en: 'Tuwal Jubayrah',
    },
    {
      city_id: 2792,
      region_id: 4,
      name_ar: 'جبيرة',
      name_en: 'Jubayrah',
    },
    {
      city_id: 2793,
      region_id: 4,
      name_ar: 'الرحيمية',
      name_en: 'Ar Ruhaymiyah',
    },
    {
      city_id: 2794,
      region_id: 2,
      name_ar: 'الجمدة',
      name_en: 'Al Jimdah',
    },
    {
      city_id: 2795,
      region_id: 2,
      name_ar: 'ام الفروغ',
      name_en: 'Umm Al Furugh',
    },
    {
      city_id: 2796,
      region_id: 2,
      name_ar: 'الفجانة',
      name_en: 'Al Fajjanah',
    },
    {
      city_id: 2797,
      region_id: 2,
      name_ar: 'الحجرة',
      name_en: 'Al Hajrah',
    },
    {
      city_id: 2798,
      region_id: 2,
      name_ar: 'الجريف',
      name_en: 'Al Jurayf',
    },
    {
      city_id: 2799,
      region_id: 2,
      name_ar: 'الحجيرة',
      name_en: 'Al Hujayrah',
    },
    {
      city_id: 2800,
      region_id: 2,
      name_ar: 'رنية',
      name_en: 'Ranyah',
    },
    {
      city_id: 2801,
      region_id: 2,
      name_ar: 'العمائر',
      name_en: "Al 'Amair",
    },
    {
      city_id: 2802,
      region_id: 2,
      name_ar: 'القوز',
      name_en: 'Al Qawz',
    },
    {
      city_id: 2803,
      region_id: 2,
      name_ar: 'الابرق',
      name_en: 'Al Abraq',
    },
    {
      city_id: 2804,
      region_id: 2,
      name_ar: 'كويكب',
      name_en: 'Kuwaykib',
    },
    {
      city_id: 2805,
      region_id: 2,
      name_ar: 'الضرم',
      name_en: 'Ad Duram',
    },
    {
      city_id: 2806,
      region_id: 2,
      name_ar: 'العويلة',
      name_en: "Al 'Uwaylah",
    },
    {
      city_id: 2807,
      region_id: 2,
      name_ar: 'الاملح',
      name_en: 'Al Amlah',
    },
    {
      city_id: 2808,
      region_id: 2,
      name_ar: 'تربة الخيالة',
      name_en: 'Turabat Al Khiyalah',
    },
    {
      city_id: 2809,
      region_id: 12,
      name_ar: 'بني فروة',
      name_en: 'Bani Farwah',
    },
    {
      city_id: 2810,
      region_id: 12,
      name_ar: 'خدعة',
      name_en: "Khid'ah",
    },
    {
      city_id: 2811,
      region_id: 12,
      name_ar: 'الحضيري',
      name_en: 'Al Hudayri',
    },
    {
      city_id: 2812,
      region_id: 12,
      name_ar: 'النعرة',
      name_en: "An Na'arah",
    },
    {
      city_id: 2813,
      region_id: 12,
      name_ar: 'الثودة',
      name_en: 'Ath Thudah',
    },
    {
      city_id: 2814,
      region_id: 6,
      name_ar: 'جعبة',
      name_en: "Ji'abah",
    },
    {
      city_id: 2815,
      region_id: 12,
      name_ar: 'بني سار',
      name_en: 'Bani Sar',
    },
    {
      city_id: 2816,
      region_id: 12,
      name_ar: 'الربوة',
      name_en: 'Ar Rabwah',
    },
    {
      city_id: 2817,
      region_id: 12,
      name_ar: 'الحشرج',
      name_en: 'Al Hashraj',
    },
    {
      city_id: 2818,
      region_id: 6,
      name_ar: 'مرازيق',
      name_en: 'Maraziq',
    },
    {
      city_id: 2819,
      region_id: 12,
      name_ar: 'العقيق',
      name_en: 'Al Aqiq',
    },
    {
      city_id: 2820,
      region_id: 12,
      name_ar: 'المطرية',
      name_en: 'Al Mutariyah',
    },
    {
      city_id: 2821,
      region_id: 12,
      name_ar: 'معشوقة',
      name_en: "Ma'shuqah",
    },
    {
      city_id: 2822,
      region_id: 12,
      name_ar: 'وراخ',
      name_en: 'Wurakh',
    },
    {
      city_id: 2823,
      region_id: 12,
      name_ar: 'جرب',
      name_en: 'Jarab',
    },
    {
      city_id: 2824,
      region_id: 12,
      name_ar: 'كرا الحائط',
      name_en: 'Kara Al Hait',
    },
    {
      city_id: 2825,
      region_id: 12,
      name_ar: 'النصباء',
      name_en: 'An Nasba',
    },
    {
      city_id: 2826,
      region_id: 12,
      name_ar: 'بالحكم',
      name_en: 'Balhakam',
    },
    {
      city_id: 2827,
      region_id: 12,
      name_ar: 'آل صقاعة',
      name_en: "Al Suqa'ah",
    },
    {
      city_id: 2828,
      region_id: 12,
      name_ar: 'المغثة',
      name_en: 'Al Maghthah',
    },
    {
      city_id: 2829,
      region_id: 12,
      name_ar: 'حظوة',
      name_en: 'Hadhwah',
    },
    {
      city_id: 2830,
      region_id: 12,
      name_ar: 'العقب',
      name_en: "Al 'Aqib",
    },
    {
      city_id: 2831,
      region_id: 12,
      name_ar: 'عمضان',
      name_en: "'Amdan",
    },
    {
      city_id: 2832,
      region_id: 12,
      name_ar: 'آل حبيبة',
      name_en: 'Al Habibah',
    },
    {
      city_id: 2833,
      region_id: 12,
      name_ar: 'مراوة',
      name_en: 'Marawah',
    },
    {
      city_id: 2834,
      region_id: 12,
      name_ar: 'الصفح',
      name_en: 'As Safh',
    },
    {
      city_id: 2835,
      region_id: 12,
      name_ar: 'المندق',
      name_en: 'Al Mandaq',
    },
    {
      city_id: 2836,
      region_id: 12,
      name_ar: 'بالخزمر',
      name_en: 'Bal Khazmar',
    },
    {
      city_id: 2837,
      region_id: 12,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 2838,
      region_id: 12,
      name_ar: 'ال نعمة',
      name_en: "Al Na'mah",
    },
    {
      city_id: 2839,
      region_id: 12,
      name_ar: 'دوس',
      name_en: 'Daws',
    },
    {
      city_id: 2840,
      region_id: 12,
      name_ar: 'الطرف',
      name_en: 'At Taraf',
    },
    {
      city_id: 2841,
      region_id: 12,
      name_ar: 'الخليف',
      name_en: 'Al Khalif',
    },
    {
      city_id: 2842,
      region_id: 12,
      name_ar: 'أبو غبار',
      name_en: 'Abu Ghubar',
    },
    {
      city_id: 2843,
      region_id: 4,
      name_ar: 'مرغان',
      name_en: 'Marghan',
    },
    {
      city_id: 2844,
      region_id: 4,
      name_ar: 'الإفيهد',
      name_en: 'Al Ifayhid',
    },
    {
      city_id: 2845,
      region_id: 4,
      name_ar: 'الحمادة',
      name_en: 'Al Hamadah',
    },
    {
      city_id: 2846,
      region_id: 4,
      name_ar: 'أشقر النومانيات',
      name_en: 'Ashqar An Numaniyat',
    },
    {
      city_id: 2847,
      region_id: 4,
      name_ar: 'النومانية',
      name_en: 'An Numaniyah',
    },
    {
      city_id: 2848,
      region_id: 4,
      name_ar: 'العمودة الجنوبية',
      name_en: "Al 'Amudah Al Janubiyah",
    },
    {
      city_id: 2849,
      region_id: 4,
      name_ar: 'العمودة الشمالية',
      name_en: "Al 'Amudah Ash Shamaliyah",
    },
    {
      city_id: 2850,
      region_id: 4,
      name_ar: 'الجرير',
      name_en: 'Al Jarir',
    },
    {
      city_id: 2851,
      region_id: 4,
      name_ar: 'الأشيعل',
      name_en: "Al Ishay'il",
    },
    {
      city_id: 2852,
      region_id: 4,
      name_ar: 'القهيبية',
      name_en: 'Al Qihabiyah',
    },
    {
      city_id: 2853,
      region_id: 4,
      name_ar: 'الشعيفانية',
      name_en: "Ash Shi'faniyah",
    },
    {
      city_id: 2854,
      region_id: 4,
      name_ar: 'المشاش',
      name_en: 'Al Mishash',
    },
    {
      city_id: 2855,
      region_id: 4,
      name_ar: 'دريميحة',
      name_en: 'Diraymihah',
    },
    {
      city_id: 2856,
      region_id: 4,
      name_ar: 'المصك',
      name_en: 'Al Masak',
    },
    {
      city_id: 2857,
      region_id: 4,
      name_ar: 'قريطة',
      name_en: 'Quraytah',
    },
    {
      city_id: 2858,
      region_id: 4,
      name_ar: 'مزارع الوسيطاء',
      name_en: "Mazari' Al Wusayta",
    },
    {
      city_id: 2859,
      region_id: 4,
      name_ar: 'قصر البطاح',
      name_en: 'Qasr Al Battah',
    },
    {
      city_id: 2860,
      region_id: 4,
      name_ar: 'السيح',
      name_en: 'As Seeh',
    },
    {
      city_id: 2861,
      region_id: 4,
      name_ar: 'الحملية',
      name_en: 'Al Himliyah',
    },
    {
      city_id: 2862,
      region_id: 4,
      name_ar: 'الصويتية',
      name_en: 'As Suwaytiyah',
    },
    {
      city_id: 2863,
      region_id: 4,
      name_ar: 'الأسامر',
      name_en: 'Al Asamir',
    },
    {
      city_id: 2864,
      region_id: 4,
      name_ar: 'الأساس',
      name_en: 'Al Asas',
    },
    {
      city_id: 2865,
      region_id: 4,
      name_ar: 'أبو نخلة',
      name_en: 'Abu Nakhlah',
    },
    {
      city_id: 2866,
      region_id: 4,
      name_ar: 'الشبيكية',
      name_en: 'Ash Shubaykiyah',
    },
    {
      city_id: 2867,
      region_id: 4,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 2868,
      region_id: 4,
      name_ar: 'جديد',
      name_en: 'Jadid',
    },
    {
      city_id: 2869,
      region_id: 4,
      name_ar: 'الطرفاوي',
      name_en: 'At Tarfawi',
    },
    {
      city_id: 2870,
      region_id: 4,
      name_ar: 'الخشيبي',
      name_en: 'Al Khishaybi',
    },
    {
      city_id: 2871,
      region_id: 4,
      name_ar: 'الحجازية',
      name_en: 'Al Hajjaziyah',
    },
    {
      city_id: 2872,
      region_id: 11,
      name_ar: 'زور العقارب',
      name_en: "Zur Al 'Aqarib",
    },
    {
      city_id: 2873,
      region_id: 11,
      name_ar: 'الغافية',
      name_en: 'Al Ghafiyah',
    },
    {
      city_id: 2874,
      region_id: 11,
      name_ar: 'صفاه',
      name_en: 'Sufah',
    },
    {
      city_id: 2875,
      region_id: 11,
      name_ar: 'العضبات',
      name_en: "Al 'Adabat",
    },
    {
      city_id: 2876,
      region_id: 11,
      name_ar: 'قطان',
      name_en: 'Qatan',
    },
    {
      city_id: 2877,
      region_id: 11,
      name_ar: 'ثار',
      name_en: 'Thar',
    },
    {
      city_id: 2878,
      region_id: 11,
      name_ar: 'حمى',
      name_en: 'Hima',
    },
    {
      city_id: 2879,
      region_id: 6,
      name_ar: 'الشرفات',
      name_en: 'Ash Shurafat',
    },
    {
      city_id: 2880,
      region_id: 6,
      name_ar: 'الرعي',
      name_en: "Ar Ra'y",
    },
    {
      city_id: 2881,
      region_id: 6,
      name_ar: 'الطرق',
      name_en: 'At Tirq',
    },
    {
      city_id: 2882,
      region_id: 6,
      name_ar: 'الجرمة',
      name_en: 'Al Jirmah',
    },
    {
      city_id: 2883,
      region_id: 6,
      name_ar: 'الحشافة',
      name_en: 'Al Hashafah',
    },
    {
      city_id: 2884,
      region_id: 6,
      name_ar: 'حمضة',
      name_en: 'Hamadah',
    },
    {
      city_id: 2885,
      region_id: 6,
      name_ar: 'المطعن',
      name_en: "Al Mat'an",
    },
    {
      city_id: 2886,
      region_id: 6,
      name_ar: 'المنجحة',
      name_en: 'Al Manjahah',
    },
    {
      city_id: 2887,
      region_id: 6,
      name_ar: 'الفيض',
      name_en: 'Al Fayd',
    },
    {
      city_id: 2888,
      region_id: 6,
      name_ar: 'غالب',
      name_en: 'Ghalib',
    },
    {
      city_id: 2889,
      region_id: 6,
      name_ar: 'سعيدة',
      name_en: "Sa'idah",
    },
    {
      city_id: 2890,
      region_id: 6,
      name_ar: 'الأجرع الشمالي',
      name_en: "Al Ajra' Ash Shamali",
    },
    {
      city_id: 2891,
      region_id: 6,
      name_ar: 'ذهبان',
      name_en: 'Dhahban',
    },
    {
      city_id: 2892,
      region_id: 6,
      name_ar: 'دبساء',
      name_en: 'Dabsa',
    },
    {
      city_id: 2893,
      region_id: 6,
      name_ar: 'البرك',
      name_en: 'Al Birk',
    },
    {
      city_id: 2894,
      region_id: 6,
      name_ar: 'القحمة',
      name_en: 'Al Qahmah',
    },
    {
      city_id: 2895,
      region_id: 6,
      name_ar: 'عمق',
      name_en: "'Amaq",
    },
    {
      city_id: 2896,
      region_id: 6,
      name_ar: 'الحظيرة',
      name_en: 'Al Hadhirah',
    },
    {
      city_id: 2897,
      region_id: 6,
      name_ar: 'آل حامد',
      name_en: 'Al Hamid',
    },
    {
      city_id: 2898,
      region_id: 6,
      name_ar: 'آل يوسف',
      name_en: 'Al Yusuf',
    },
    {
      city_id: 2899,
      region_id: 6,
      name_ar: 'البدلة',
      name_en: 'Al Badlah',
    },
    {
      city_id: 2900,
      region_id: 6,
      name_ar: 'لولاة',
      name_en: 'Lawlah',
    },
    {
      city_id: 2901,
      region_id: 6,
      name_ar: 'نجد قرض',
      name_en: 'Najd Qarad',
    },
    {
      city_id: 2902,
      region_id: 6,
      name_ar: 'جمان',
      name_en: 'Jumman',
    },
    {
      city_id: 2903,
      region_id: 6,
      name_ar: 'كثم',
      name_en: 'Kathm',
    },
    {
      city_id: 2904,
      region_id: 6,
      name_ar: 'السلامة',
      name_en: 'As Salamah',
    },
    {
      city_id: 2905,
      region_id: 6,
      name_ar: 'المثناة',
      name_en: 'Al Mathnah',
    },
    {
      city_id: 2906,
      region_id: 6,
      name_ar: 'الهضبة',
      name_en: 'Al Hadabah',
    },
    {
      city_id: 2907,
      region_id: 6,
      name_ar: 'لعصان',
      name_en: "Li'san",
    },
    {
      city_id: 2908,
      region_id: 6,
      name_ar: 'المحالة',
      name_en: 'Al Mahalah',
    },
    {
      city_id: 2909,
      region_id: 6,
      name_ar: 'الشرف',
      name_en: 'Ash Sharaf',
    },
    {
      city_id: 2910,
      region_id: 6,
      name_ar: 'سر آل السريع',
      name_en: "Sirr Al As Siri'",
    },
    {
      city_id: 2911,
      region_id: 6,
      name_ar: 'العبب',
      name_en: "Al 'Abab",
    },
    {
      city_id: 2912,
      region_id: 6,
      name_ar: 'الدارة',
      name_en: 'Ad Darah',
    },
    {
      city_id: 2913,
      region_id: 6,
      name_ar: 'الجرجر',
      name_en: 'Al Jarjar',
    },
    {
      city_id: 2914,
      region_id: 6,
      name_ar: 'عدوان',
      name_en: "'Adwan",
    },
    {
      city_id: 2915,
      region_id: 6,
      name_ar: 'العين',
      name_en: "Al 'Ayn",
    },
    {
      city_id: 2916,
      region_id: 6,
      name_ar: 'الجعجاع',
      name_en: "Al Ja'ja'",
    },
    {
      city_id: 2917,
      region_id: 6,
      name_ar: 'الموسطة',
      name_en: 'Al Mawsatah',
    },
    {
      city_id: 2918,
      region_id: 6,
      name_ar: 'آل جمعة',
      name_en: "Al Jum'ah",
    },
    {
      city_id: 2919,
      region_id: 6,
      name_ar: 'العزيزة',
      name_en: "Al 'Azizah",
    },
    {
      city_id: 2920,
      region_id: 6,
      name_ar: 'الهرير الغربي',
      name_en: 'Al Hurayr Al Gharbi',
    },
    {
      city_id: 2921,
      region_id: 6,
      name_ar: 'إيتارة',
      name_en: 'Itarah',
    },
    {
      city_id: 2922,
      region_id: 6,
      name_ar: 'أبو نخلة',
      name_en: 'Abu Nakhlah',
    },
    {
      city_id: 2923,
      region_id: 6,
      name_ar: 'القرح',
      name_en: 'Al Qurh',
    },
    {
      city_id: 2924,
      region_id: 6,
      name_ar: 'العرف',
      name_en: "Al 'Araf",
    },
    {
      city_id: 2925,
      region_id: 6,
      name_ar: 'أبو جرشة',
      name_en: 'Abu Jarshah',
    },
    {
      city_id: 2926,
      region_id: 6,
      name_ar: 'الخشم',
      name_en: 'Al Khashm',
    },
    {
      city_id: 2927,
      region_id: 6,
      name_ar: 'روغ العاند',
      name_en: "Rugh Al 'Anid",
    },
    {
      city_id: 2928,
      region_id: 6,
      name_ar: 'آل بالفلاح',
      name_en: 'Al Balfalah',
    },
    {
      city_id: 2929,
      region_id: 6,
      name_ar: 'حجلا',
      name_en: 'Hajla',
    },
    {
      city_id: 2930,
      region_id: 6,
      name_ar: 'العطفة',
      name_en: "Al 'Utfah",
    },
    {
      city_id: 2931,
      region_id: 6,
      name_ar: 'تانة',
      name_en: 'Tanah',
    },
    {
      city_id: 2932,
      region_id: 6,
      name_ar: 'آل الشيخ',
      name_en: 'Al Ash Shaykh',
    },
    {
      city_id: 2933,
      region_id: 6,
      name_ar: 'حبيب',
      name_en: 'Hubayyib',
    },
    {
      city_id: 2934,
      region_id: 6,
      name_ar: 'رغفة',
      name_en: 'Rughfah',
    },
    {
      city_id: 2935,
      region_id: 6,
      name_ar: 'عضاضة',
      name_en: "'Adadah",
    },
    {
      city_id: 2936,
      region_id: 6,
      name_ar: 'آل سرحان',
      name_en: 'Al Sirhan',
    },
    {
      city_id: 2937,
      region_id: 6,
      name_ar: 'القعرة',
      name_en: "Al Qa'arah",
    },
    {
      city_id: 2938,
      region_id: 6,
      name_ar: 'آل يزيد',
      name_en: 'Al Yazid',
    },
    {
      city_id: 2939,
      region_id: 6,
      name_ar: 'المجمع',
      name_en: "Al Majma'",
    },
    {
      city_id: 2940,
      region_id: 6,
      name_ar: 'الصيحاني',
      name_en: 'As Sayhani',
    },
    {
      city_id: 2941,
      region_id: 6,
      name_ar: 'البهيمة',
      name_en: 'Al Bihaymah',
    },
    {
      city_id: 2942,
      region_id: 6,
      name_ar: 'القرعاء',
      name_en: "Al Qar'a",
    },
    {
      city_id: 2943,
      region_id: 6,
      name_ar: 'الجر الصغير',
      name_en: 'Al Jarr As Saghir',
    },
    {
      city_id: 2944,
      region_id: 6,
      name_ar: 'الشعف',
      name_en: "Ash Sha'af",
    },
    {
      city_id: 2945,
      region_id: 6,
      name_ar: 'تمنية',
      name_en: 'Tamniyah',
    },
    {
      city_id: 2946,
      region_id: 6,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 2947,
      region_id: 6,
      name_ar: 'آل علي',
      name_en: "Al 'Ali",
    },
    {
      city_id: 2948,
      region_id: 6,
      name_ar: 'عين الذيبة',
      name_en: "'Ayn Adh Dhibah",
    },
    {
      city_id: 2949,
      region_id: 6,
      name_ar: 'البارك',
      name_en: 'Al Barik',
    },
    {
      city_id: 2950,
      region_id: 6,
      name_ar: 'قرضة',
      name_en: 'Qaradah',
    },
    {
      city_id: 2951,
      region_id: 6,
      name_ar: 'آل زيدي',
      name_en: 'Al Zaydi',
    },
    {
      city_id: 2952,
      region_id: 6,
      name_ar: 'حصن الجرين',
      name_en: 'Husn Al Jarin',
    },
    {
      city_id: 2953,
      region_id: 6,
      name_ar: 'آل عاصم',
      name_en: "Al 'Asim",
    },
    {
      city_id: 2954,
      region_id: 6,
      name_ar: 'الجنفور',
      name_en: 'Al Janfur',
    },
    {
      city_id: 2955,
      region_id: 6,
      name_ar: 'عرايس',
      name_en: "'Arayis",
    },
    {
      city_id: 2956,
      region_id: 6,
      name_ar: 'عمام',
      name_en: "'Umam",
    },
    {
      city_id: 2957,
      region_id: 6,
      name_ar: 'رهوة الغيناء',
      name_en: 'Rahwah Al Ghina',
    },
    {
      city_id: 2958,
      region_id: 6,
      name_ar: 'شوحط',
      name_en: 'Shuhat',
    },
    {
      city_id: 2959,
      region_id: 6,
      name_ar: 'منادرالعين',
      name_en: "Manadir Al 'Ayn",
    },
    {
      city_id: 2960,
      region_id: 6,
      name_ar: 'بني رزام',
      name_en: 'Bani Rizam',
    },
    {
      city_id: 2961,
      region_id: 6,
      name_ar: 'الملاحة',
      name_en: 'Al Malahah',
    },
    {
      city_id: 2962,
      region_id: 6,
      name_ar: 'الطلحة',
      name_en: 'At Talhah',
    },
    {
      city_id: 2963,
      region_id: 6,
      name_ar: 'شرمة',
      name_en: 'Sharmah',
    },
    {
      city_id: 2964,
      region_id: 6,
      name_ar: 'الحنق',
      name_en: 'Al Hanaq',
    },
    {
      city_id: 2965,
      region_id: 6,
      name_ar: 'القرين',
      name_en: 'Al Qurayn',
    },
    {
      city_id: 2966,
      region_id: 6,
      name_ar: 'آل مجمل',
      name_en: 'Al Mujammal',
    },
    {
      city_id: 2967,
      region_id: 7,
      name_ar: 'بجدة',
      name_en: 'Bajdah',
    },
    {
      city_id: 2968,
      region_id: 6,
      name_ar: 'طبب',
      name_en: 'Tabab',
    },
    {
      city_id: 2969,
      region_id: 6,
      name_ar: 'الشطاط',
      name_en: 'Al Shutat',
    },
    {
      city_id: 2970,
      region_id: 6,
      name_ar: 'العبيدية',
      name_en: "Al 'Abidiyah",
    },
    {
      city_id: 2971,
      region_id: 6,
      name_ar: 'السبت',
      name_en: 'As Sabt',
    },
    {
      city_id: 2972,
      region_id: 6,
      name_ar: 'حمالة',
      name_en: 'Himalah',
    },
    {
      city_id: 2973,
      region_id: 6,
      name_ar: 'العقيق',
      name_en: 'Al Aqiq',
    },
    {
      city_id: 2974,
      region_id: 6,
      name_ar: 'الوسط',
      name_en: 'Al Wasat',
    },
    {
      city_id: 2975,
      region_id: 6,
      name_ar: 'آل بسام',
      name_en: 'Al Bassam',
    },
    {
      city_id: 2976,
      region_id: 6,
      name_ar: 'آل دكين',
      name_en: 'Al Dukayn',
    },
    {
      city_id: 2977,
      region_id: 6,
      name_ar: 'آل مهدي',
      name_en: 'Al Mahadi',
    },
    {
      city_id: 2978,
      region_id: 6,
      name_ar: 'المصياد',
      name_en: 'Al Misyad',
    },
    {
      city_id: 2979,
      region_id: 6,
      name_ar: 'الجزعة',
      name_en: "Al Juz'ah",
    },
    {
      city_id: 2980,
      region_id: 6,
      name_ar: 'آل مشدود',
      name_en: 'Al Mashdud',
    },
    {
      city_id: 2981,
      region_id: 6,
      name_ar: 'الجهمة',
      name_en: 'Al Jihmah',
    },
    {
      city_id: 2982,
      region_id: 6,
      name_ar: 'العسران',
      name_en: "Al 'Usran",
    },
    {
      city_id: 2983,
      region_id: 6,
      name_ar: 'آل بحبيب',
      name_en: 'Al Bihabib',
    },
    {
      city_id: 2984,
      region_id: 6,
      name_ar: 'آل قوشع',
      name_en: "Al Qawsha'",
    },
    {
      city_id: 2985,
      region_id: 6,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 2986,
      region_id: 4,
      name_ar: 'الدليمية',
      name_en: 'Ad Dilaymiyah',
    },
    {
      city_id: 2987,
      region_id: 4,
      name_ar: 'الفوارة',
      name_en: 'Al Fawwarah',
    },
    {
      city_id: 2988,
      region_id: 4,
      name_ar: 'عقلة الصقور',
      name_en: 'Uqlat As Suqur',
    },
    {
      city_id: 2989,
      region_id: 4,
      name_ar: 'الذيبية',
      name_en: 'Adh Dhibiyah',
    },
    {
      city_id: 2990,
      region_id: 4,
      name_ar: 'قصر ابن عقيل',
      name_en: "Qasr Ibn 'Uqayyil",
    },
    {
      city_id: 2991,
      region_id: 4,
      name_ar: 'قطن',
      name_en: 'Qitan',
    },
    {
      city_id: 2992,
      region_id: 4,
      name_ar: 'البصيري',
      name_en: 'Al Basiri',
    },
    {
      city_id: 2993,
      region_id: 4,
      name_ar: 'الخريشاء',
      name_en: 'Al Khuraysha',
    },
    {
      city_id: 2994,
      region_id: 4,
      name_ar: 'الدحلة',
      name_en: 'Ad Dahlah',
    },
    {
      city_id: 2995,
      region_id: 4,
      name_ar: 'النقرة',
      name_en: 'An Nuqrah',
    },
    {
      city_id: 2996,
      region_id: 4,
      name_ar: 'امباري',
      name_en: 'Embary',
    },
    {
      city_id: 2997,
      region_id: 4,
      name_ar: 'الجفن',
      name_en: 'Al Jifn',
    },
    {
      city_id: 2998,
      region_id: 4,
      name_ar: 'ديم',
      name_en: 'Daym',
    },
    {
      city_id: 2999,
      region_id: 4,
      name_ar: 'الهميلية',
      name_en: 'Al Humayliyah',
    },
    {
      city_id: 3000,
      region_id: 4,
      name_ar: 'عماير سعيدة',
      name_en: "Amayir Si'idah",
    },
    {
      city_id: 3001,
      region_id: 4,
      name_ar: 'ذوقان الركايا',
      name_en: 'Dhawqan Ar Rakaya',
    },
    {
      city_id: 3002,
      region_id: 4,
      name_ar: 'الضاحي',
      name_en: 'Ad Dahi',
    },
    {
      city_id: 3003,
      region_id: 4,
      name_ar: 'الوهلان',
      name_en: 'Al Wahalan',
    },
    {
      city_id: 3004,
      region_id: 4,
      name_ar: 'العونية',
      name_en: "Al 'Awniyah",
    },
    {
      city_id: 3005,
      region_id: 4,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 3006,
      region_id: 4,
      name_ar: 'الأشرفية',
      name_en: 'Al Ashrafiyah',
    },
    {
      city_id: 3007,
      region_id: 4,
      name_ar: 'مزرعة البسامية',
      name_en: "Mazra'at Al Bassamiyah",
    },
    {
      city_id: 3008,
      region_id: 4,
      name_ar: 'الضلعة',
      name_en: "Ad Dal'ah",
    },
    {
      city_id: 3009,
      region_id: 4,
      name_ar: 'غدفاء',
      name_en: 'Ghadfa',
    },
    {
      city_id: 3010,
      region_id: 4,
      name_ar: 'الغريبية',
      name_en: 'Al Ghuraybiyah',
    },
    {
      city_id: 3011,
      region_id: 4,
      name_ar: 'الفيحاء',
      name_en: 'Al Fayha',
    },
    {
      city_id: 3012,
      region_id: 4,
      name_ar: 'أم غويفة',
      name_en: 'Umm Ghuwayfah',
    },
    {
      city_id: 3013,
      region_id: 4,
      name_ar: 'الجناح',
      name_en: 'Al Janah',
    },
    {
      city_id: 3014,
      region_id: 4,
      name_ar: 'الحزم',
      name_en: 'Al Hazm',
    },
    {
      city_id: 3015,
      region_id: 4,
      name_ar: 'رياض قناء',
      name_en: 'Riyad Qana',
    },
    {
      city_id: 3016,
      region_id: 4,
      name_ar: 'المندسة',
      name_en: 'Al Mundassah',
    },
    {
      city_id: 3017,
      region_id: 4,
      name_ar: 'رفائع الحميمة',
      name_en: "Ruf'ai' Al Humaymah",
    },
    {
      city_id: 3018,
      region_id: 4,
      name_ar: 'مظيفر',
      name_en: 'Mudhayfir',
    },
    {
      city_id: 3019,
      region_id: 4,
      name_ar: 'العمودة',
      name_en: "Al 'Amudah",
    },
    {
      city_id: 3020,
      region_id: 4,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 3021,
      region_id: 4,
      name_ar: 'ابو طلح',
      name_en: 'Abu Talh',
    },
    {
      city_id: 3022,
      region_id: 4,
      name_ar: 'مهيضة',
      name_en: 'Mihayyidah',
    },
    {
      city_id: 3023,
      region_id: 4,
      name_ar: 'وادي النخيل',
      name_en: 'Wadi Al Nakhil',
    },
    {
      city_id: 3024,
      region_id: 4,
      name_ar: 'فياضة',
      name_en: 'Fayyadah',
    },
    {
      city_id: 3025,
      region_id: 4,
      name_ar: 'كحلة',
      name_en: 'Kahlah',
    },
    {
      city_id: 3026,
      region_id: 4,
      name_ar: 'رفائع الحجرة',
      name_en: "Ruf'ai' Al Hajrah",
    },
    {
      city_id: 3027,
      region_id: 4,
      name_ar: 'مشاش ركيان',
      name_en: 'Mishash Rukayyan',
    },
    {
      city_id: 3028,
      region_id: 4,
      name_ar: 'أبو عرداء',
      name_en: "Abu 'Arda",
    },
    {
      city_id: 3029,
      region_id: 4,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 3030,
      region_id: 4,
      name_ar: 'النبهانية',
      name_en: 'An Nabhaniyah',
    },
    {
      city_id: 3031,
      region_id: 4,
      name_ar: 'ثادج',
      name_en: 'Thadij',
    },
    {
      city_id: 3032,
      region_id: 4,
      name_ar: 'ربيق',
      name_en: 'Rubayq',
    },
    {
      city_id: 3033,
      region_id: 4,
      name_ar: 'سامودة',
      name_en: 'Samudah',
    },
    {
      city_id: 3034,
      region_id: 4,
      name_ar: 'الملقاء',
      name_en: 'Al Malqa',
    },
    {
      city_id: 3035,
      region_id: 4,
      name_ar: 'الخرماء',
      name_en: 'Al Kharma',
    },
    {
      city_id: 3036,
      region_id: 4,
      name_ar: 'الخرماءالشمالية',
      name_en: 'Al Kharma Ash Shamaliyah',
    },
    {
      city_id: 3037,
      region_id: 4,
      name_ar: 'الحيسونية',
      name_en: 'Al Haysuniyah',
    },
    {
      city_id: 3038,
      region_id: 4,
      name_ar: 'القعير',
      name_en: "Al Qu'ayyir",
    },
    {
      city_id: 3039,
      region_id: 4,
      name_ar: 'العمار',
      name_en: "Al 'Amar",
    },
    {
      city_id: 3040,
      region_id: 3,
      name_ar: 'قريضة',
      name_en: 'Quraydah',
    },
    {
      city_id: 3041,
      region_id: 3,
      name_ar: 'الجصة',
      name_en: 'Al Jissah',
    },
    {
      city_id: 3042,
      region_id: 3,
      name_ar: 'ابيار علي',
      name_en: 'Abyar Ali',
    },
    {
      city_id: 3043,
      region_id: 3,
      name_ar: 'أبو كبير',
      name_en: 'Abu Kabir',
    },
    {
      city_id: 3044,
      region_id: 3,
      name_ar: 'الدويخلة',
      name_en: 'Ad Duwaykhilah',
    },
    {
      city_id: 3045,
      region_id: 3,
      name_ar: 'الوعيرة',
      name_en: "Al Wu'ayrah",
    },
    {
      city_id: 3046,
      region_id: 4,
      name_ar: 'ام جرفان',
      name_en: 'Umm Jarfan',
    },
    {
      city_id: 3047,
      region_id: 3,
      name_ar: 'العيون',
      name_en: 'Al Uyun',
    },
    {
      city_id: 3048,
      region_id: 3,
      name_ar: 'الخليل',
      name_en: 'Al Khulayl',
    },
    {
      city_id: 3049,
      region_id: 3,
      name_ar: 'نعامين',
      name_en: "Na'amin",
    },
    {
      city_id: 3050,
      region_id: 3,
      name_ar: 'مليحة',
      name_en: 'Mulayhah',
    },
    {
      city_id: 3051,
      region_id: 3,
      name_ar: 'العاقول',
      name_en: "Al 'Aqul",
    },
    {
      city_id: 3052,
      region_id: 3,
      name_ar: 'الحار السفلى',
      name_en: 'Al Har As Sufla',
    },
    {
      city_id: 3053,
      region_id: 3,
      name_ar: 'مفرحات',
      name_en: 'Mufarrihat',
    },
    {
      city_id: 3054,
      region_id: 3,
      name_ar: 'القطيعاء',
      name_en: "Al Qutay'a",
    },
    {
      city_id: 3055,
      region_id: 3,
      name_ar: 'الفرع',
      name_en: "Al Far'",
    },
    {
      city_id: 3056,
      region_id: 3,
      name_ar: 'الفقارة',
      name_en: 'Al Fuqarah',
    },
    {
      city_id: 3057,
      region_id: 3,
      name_ar: 'أرباق',
      name_en: 'Arbaq',
    },
    {
      city_id: 3058,
      region_id: 3,
      name_ar: 'المليليح',
      name_en: 'Al Mulaylih',
    },
    {
      city_id: 3059,
      region_id: 3,
      name_ar: 'المغرة',
      name_en: 'Al Magharah',
    },
    {
      city_id: 3060,
      region_id: 3,
      name_ar: 'أبو نمصات',
      name_en: 'Abu Numasat',
    },
    {
      city_id: 3061,
      region_id: 3,
      name_ar: 'دثير',
      name_en: 'Dithir',
    },
    {
      city_id: 3062,
      region_id: 3,
      name_ar: 'العقيلة',
      name_en: "Al 'Uqaylah",
    },
    {
      city_id: 3063,
      region_id: 3,
      name_ar: 'العوينة',
      name_en: "Al 'Uwaynah",
    },
    {
      city_id: 3064,
      region_id: 3,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 3065,
      region_id: 3,
      name_ar: 'الثعلة',
      name_en: "Ath Tha'alah",
    },
    {
      city_id: 3066,
      region_id: 3,
      name_ar: 'الحار العليا',
      name_en: "Al Har Al 'Ulya",
    },
    {
      city_id: 3067,
      region_id: 3,
      name_ar: 'المضيبعة',
      name_en: "Al Mudaybi'ah",
    },
    {
      city_id: 3068,
      region_id: 3,
      name_ar: 'السوسي',
      name_en: 'As Susi',
    },
    {
      city_id: 3069,
      region_id: 3,
      name_ar: 'الشلايل',
      name_en: 'Ash Shalayil',
    },
    {
      city_id: 3070,
      region_id: 3,
      name_ar: 'أويدك',
      name_en: 'Uwaydik',
    },
    {
      city_id: 3071,
      region_id: 3,
      name_ar: 'الغريض',
      name_en: 'Al Gharid',
    },
    {
      city_id: 3072,
      region_id: 3,
      name_ar: 'الصيفي',
      name_en: 'As Sayfi',
    },
    {
      city_id: 3073,
      region_id: 3,
      name_ar: 'الغصن',
      name_en: 'Al Ghusan',
    },
    {
      city_id: 3074,
      region_id: 3,
      name_ar: 'الظمو',
      name_en: 'Adh Dhamu',
    },
    {
      city_id: 3075,
      region_id: 3,
      name_ar: 'الطرقية',
      name_en: 'Al Tarqiyah',
    },
    {
      city_id: 3076,
      region_id: 3,
      name_ar: 'أم العيال',
      name_en: "Umm Al 'Iyal",
    },
    {
      city_id: 3077,
      region_id: 3,
      name_ar: 'الملبنة',
      name_en: 'Al Malbanah',
    },
    {
      city_id: 3078,
      region_id: 3,
      name_ar: 'حنذ',
      name_en: 'Hanadh',
    },
    {
      city_id: 3079,
      region_id: 3,
      name_ar: 'الهندية',
      name_en: 'Al Hindiyah',
    },
    {
      city_id: 3080,
      region_id: 3,
      name_ar: 'المرير',
      name_en: 'Al Murayr',
    },
    {
      city_id: 3081,
      region_id: 3,
      name_ar: 'الدحو',
      name_en: 'Ad Duhu',
    },
    {
      city_id: 3082,
      region_id: 3,
      name_ar: 'النقيعاء',
      name_en: "An Nuqay'a",
    },
    {
      city_id: 3083,
      region_id: 3,
      name_ar: 'المشينية',
      name_en: 'Al Mushayniyah',
    },
    {
      city_id: 3084,
      region_id: 3,
      name_ar: 'القافلة',
      name_en: 'Al Qafilah',
    },
    {
      city_id: 3085,
      region_id: 3,
      name_ar: 'اللثامة',
      name_en: 'Al Luthamah',
    },
    {
      city_id: 3086,
      region_id: 3,
      name_ar: 'الملحة',
      name_en: 'Al Malhah',
    },
    {
      city_id: 3087,
      region_id: 3,
      name_ar: 'العيزاء',
      name_en: "Al 'Ayza",
    },
    {
      city_id: 3088,
      region_id: 3,
      name_ar: 'النقرة',
      name_en: 'An Nuqrah',
    },
    {
      city_id: 3089,
      region_id: 3,
      name_ar: 'البردية',
      name_en: 'Al Bardiyah',
    },
    {
      city_id: 3090,
      region_id: 3,
      name_ar: 'العشيرة',
      name_en: "Al 'Ushayrah",
    },
    {
      city_id: 3091,
      region_id: 3,
      name_ar: 'القصيبة',
      name_en: 'Al Qisayyibah',
    },
    {
      city_id: 3092,
      region_id: 3,
      name_ar: 'الشوامين',
      name_en: 'Ash Shuwamin',
    },
    {
      city_id: 3093,
      region_id: 3,
      name_ar: 'الشفية',
      name_en: 'Ash Shufayyah',
    },
    {
      city_id: 3094,
      region_id: 3,
      name_ar: 'تيتد',
      name_en: 'Taytad',
    },
    {
      city_id: 3095,
      region_id: 3,
      name_ar: 'البييرات',
      name_en: 'Al Buyayrat',
    },
    {
      city_id: 3096,
      region_id: 3,
      name_ar: 'هرمة',
      name_en: 'Hurumah',
    },
    {
      city_id: 3097,
      region_id: 3,
      name_ar: 'العثيا',
      name_en: "Al 'Uthayya",
    },
    {
      city_id: 3098,
      region_id: 7,
      name_ar: 'شقري',
      name_en: 'Shiqri',
    },
    {
      city_id: 3099,
      region_id: 3,
      name_ar: 'شجوى',
      name_en: 'Shajwa',
    },
    {
      city_id: 3100,
      region_id: 3,
      name_ar: 'البوير',
      name_en: 'Al Buwair',
    },
    {
      city_id: 3101,
      region_id: 3,
      name_ar: 'الشقرة',
      name_en: 'Ash Shuqrah',
    },
    {
      city_id: 3102,
      region_id: 3,
      name_ar: 'الصويدرة',
      name_en: 'As Suwaydirah',
    },
    {
      city_id: 3103,
      region_id: 3,
      name_ar: 'الفريش',
      name_en: 'Al Furaysh',
    },
    {
      city_id: 3104,
      region_id: 3,
      name_ar: 'الريان',
      name_en: 'Ar Rayyan',
    },
    {
      city_id: 3105,
      region_id: 3,
      name_ar: 'المليليح',
      name_en: 'Al Mulaylih',
    },
    {
      city_id: 3106,
      region_id: 3,
      name_ar: 'الرصيعة',
      name_en: "Ar Rasi'ah",
    },
    {
      city_id: 3107,
      region_id: 3,
      name_ar: 'ابيار الماشي',
      name_en: 'Abyar Al Mashi',
    },
    {
      city_id: 3108,
      region_id: 3,
      name_ar: 'رواوة',
      name_en: 'Ruwawah',
    },
    {
      city_id: 3109,
      region_id: 3,
      name_ar: 'الجفر',
      name_en: 'Al Jafr',
    },
    {
      city_id: 3110,
      region_id: 3,
      name_ar: 'ابو ضباع',
      name_en: "Abu Diba'",
    },
    {
      city_id: 3111,
      region_id: 3,
      name_ar: 'المضيق',
      name_en: 'Al Madiq',
    },
    {
      city_id: 3112,
      region_id: 3,
      name_ar: 'الاكحل',
      name_en: 'Al Akhal',
    },
    {
      city_id: 3113,
      region_id: 3,
      name_ar: 'اليتمة',
      name_en: 'Al Yitimah',
    },
    {
      city_id: 3114,
      region_id: 3,
      name_ar: 'الفقير',
      name_en: 'Al Faqir',
    },
    {
      city_id: 3115,
      region_id: 3,
      name_ar: 'المندسة',
      name_en: 'Al Mundassah',
    },
    {
      city_id: 3116,
      region_id: 3,
      name_ar: 'بواط',
      name_en: 'Buwat',
    },
    {
      city_id: 3117,
      region_id: 3,
      name_ar: 'العصيلب',
      name_en: "Al 'Usaylib",
    },
    {
      city_id: 3118,
      region_id: 3,
      name_ar: 'الجفدور',
      name_en: 'Al Jifdur',
    },
    {
      city_id: 3119,
      region_id: 4,
      name_ar: 'مسكة',
      name_en: 'Miskah',
    },
    {
      city_id: 3120,
      region_id: 4,
      name_ar: 'أم المحاش',
      name_en: 'Umm Al Mahash',
    },
    {
      city_id: 3121,
      region_id: 4,
      name_ar: 'عيدة',
      name_en: "'Idah",
    },
    {
      city_id: 3122,
      region_id: 4,
      name_ar: 'الرفائع',
      name_en: "Ar Rafai'",
    },
    {
      city_id: 3123,
      region_id: 4,
      name_ar: 'صميعر',
      name_en: 'Sumayir',
    },
    {
      city_id: 3124,
      region_id: 1,
      name_ar: 'فيضة سلام',
      name_en: 'Faydat Salam',
    },
    {
      city_id: 3125,
      region_id: 4,
      name_ar: 'طفيلة',
      name_en: 'Tufaylah',
    },
    {
      city_id: 3126,
      region_id: 4,
      name_ar: 'حليوة',
      name_en: 'Hulaywah',
    },
    {
      city_id: 3127,
      region_id: 4,
      name_ar: 'هرمول',
      name_en: 'Hurmul',
    },
    {
      city_id: 3128,
      region_id: 1,
      name_ar: 'روضة عسعس',
      name_en: "Rawdat 'As'as",
    },
    {
      city_id: 3129,
      region_id: 4,
      name_ar: 'المطيوي',
      name_en: 'Al Mutaywi',
    },
    {
      city_id: 3130,
      region_id: 4,
      name_ar: 'بدائع هويشلة',
      name_en: "Badai' Huwayshilah",
    },
    {
      city_id: 3131,
      region_id: 1,
      name_ar: 'الكفية',
      name_en: 'Al Kuffiyah',
    },
    {
      city_id: 3132,
      region_id: 4,
      name_ar: 'بقيعاء الجنوبية',
      name_en: "Buqay'a Al Janubiyah",
    },
    {
      city_id: 3133,
      region_id: 4,
      name_ar: 'الرفايع النجج',
      name_en: "Ar Rufayi' Al Najh",
    },
    {
      city_id: 3134,
      region_id: 4,
      name_ar: 'الظاهرية',
      name_en: 'Adh Dhahiriyah',
    },
    {
      city_id: 3135,
      region_id: 4,
      name_ar: 'المندسة',
      name_en: 'Al Mundassah',
    },
    {
      city_id: 3136,
      region_id: 4,
      name_ar: 'العقلة',
      name_en: "Al 'Uqlah",
    },
    {
      city_id: 3137,
      region_id: 4,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 3138,
      region_id: 1,
      name_ar: 'الضبعية',
      name_en: "Ad Dubay'ah",
    },
    {
      city_id: 3139,
      region_id: 4,
      name_ar: 'بدائع الضبعية',
      name_en: "Badai Ad Dib'iyah",
    },
    {
      city_id: 3140,
      region_id: 4,
      name_ar: 'جفرة الجديدة',
      name_en: 'Jafrat Al Jadidah',
    },
    {
      city_id: 3141,
      region_id: 4,
      name_ar: 'جفرة',
      name_en: 'Jafrah',
    },
    {
      city_id: 3142,
      region_id: 4,
      name_ar: 'الحمادة',
      name_en: 'Al Hamadah',
    },
    {
      city_id: 3143,
      region_id: 4,
      name_ar: 'بدائع مشرفة',
      name_en: "Badai' Mushrifah",
    },
    {
      city_id: 3144,
      region_id: 4,
      name_ar: 'نجخ الجنوبي',
      name_en: 'Najkh Al Janubi',
    },
    {
      city_id: 3145,
      region_id: 4,
      name_ar: 'نجخ الشمالي',
      name_en: 'Najkh Al Shamali',
    },
    {
      city_id: 3146,
      region_id: 4,
      name_ar: 'القويعية',
      name_en: "Al Quway'iyah",
    },
    {
      city_id: 3147,
      region_id: 4,
      name_ar: 'صعينين',
      name_en: "Su'aynin",
    },
    {
      city_id: 3148,
      region_id: 4,
      name_ar: 'ليم',
      name_en: 'Laym',
    },
    {
      city_id: 3149,
      region_id: 4,
      name_ar: 'السليسية',
      name_en: 'As Sulaysiyah',
    },
    {
      city_id: 3150,
      region_id: 1,
      name_ar: 'فيضة نومان',
      name_en: 'Faydat Noman',
    },
    {
      city_id: 3151,
      region_id: 4,
      name_ar: 'ضرية',
      name_en: 'Dariyah',
    },
    {
      city_id: 3152,
      region_id: 4,
      name_ar: 'الصمعورية',
      name_en: "As Sum'uriyah",
    },
    {
      city_id: 3153,
      region_id: 4,
      name_ar: 'سلام',
      name_en: 'Salam',
    },
    {
      city_id: 3154,
      region_id: 4,
      name_ar: 'العاقر',
      name_en: "Al 'Aqir",
    },
    {
      city_id: 3155,
      region_id: 4,
      name_ar: 'بدائع ريمان',
      name_en: "Badai' Riman",
    },
    {
      city_id: 3156,
      region_id: 4,
      name_ar: 'بدائع الضبطان',
      name_en: "Badai' Ad Dubtan",
    },
    {
      city_id: 3157,
      region_id: 4,
      name_ar: 'البعجاء',
      name_en: "Al Ba'ja",
    },
    {
      city_id: 3158,
      region_id: 1,
      name_ar: 'الحريق',
      name_en: 'Al Hariq',
    },
    {
      city_id: 3159,
      region_id: 1,
      name_ar: 'نعام',
      name_en: "Na'am",
    },
    {
      city_id: 3160,
      region_id: 1,
      name_ar: 'حوطة الحلوة',
      name_en: 'Hawtat Al Hilwah',
    },
    {
      city_id: 3161,
      region_id: 1,
      name_ar: 'حوطة بني تميم',
      name_en: 'Hawtat Bani Tamim',
    },
    {
      city_id: 3162,
      region_id: 1,
      name_ar: 'اسفل الباطن',
      name_en: 'Asfal Al Batin',
    },
    {
      city_id: 3163,
      region_id: 1,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 3164,
      region_id: 1,
      name_ar: 'المثعب',
      name_en: "Al Mith'ab",
    },
    {
      city_id: 3165,
      region_id: 1,
      name_ar: 'الحلوة',
      name_en: 'Al Hilwah',
    },
    {
      city_id: 3166,
      region_id: 1,
      name_ar: 'الحيطان',
      name_en: 'Al Haytan',
    },
    {
      city_id: 3167,
      region_id: 1,
      name_ar: 'وادي البرك',
      name_en: 'Wadi Al Bark',
    },
    {
      city_id: 3168,
      region_id: 1,
      name_ar: 'الشكرة',
      name_en: 'Ash Shukrah',
    },
    {
      city_id: 3169,
      region_id: 1,
      name_ar: 'الفيصلية',
      name_en: 'Al Faysaliyah',
    },
    {
      city_id: 3170,
      region_id: 1,
      name_ar: 'النايفية',
      name_en: 'An Naifiyah',
    },
    {
      city_id: 3171,
      region_id: 1,
      name_ar: 'القطين',
      name_en: 'Al Qatin',
    },
    {
      city_id: 3172,
      region_id: 1,
      name_ar: 'الخرفة',
      name_en: 'Al Kharfah',
    },
    {
      city_id: 3173,
      region_id: 1,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 3174,
      region_id: 1,
      name_ar: 'ليلى',
      name_en: 'Layla',
    },
    {
      city_id: 3175,
      region_id: 1,
      name_ar: 'سويدانا',
      name_en: 'Swidana',
    },
    {
      city_id: 3176,
      region_id: 1,
      name_ar: 'مروان',
      name_en: 'Marwan',
    },
    {
      city_id: 3177,
      region_id: 1,
      name_ar: 'واسط',
      name_en: 'Wasit',
    },
    {
      city_id: 3178,
      region_id: 1,
      name_ar: 'الحمر',
      name_en: 'Al Hamr',
    },
    {
      city_id: 3179,
      region_id: 1,
      name_ar: 'البديع الجنوبى',
      name_en: "Al Badi' Al Janubi",
    },
    {
      city_id: 3180,
      region_id: 1,
      name_ar: 'البديع الشمالي',
      name_en: "Al Badi' Ash Shamali",
    },
    {
      city_id: 3181,
      region_id: 1,
      name_ar: 'الهدار',
      name_en: 'Al Haddar',
    },
    {
      city_id: 3182,
      region_id: 1,
      name_ar: 'ساقي الناهض',
      name_en: 'Saqi An Nahid',
    },
    {
      city_id: 3183,
      region_id: 1,
      name_ar: 'الغيل',
      name_en: 'Al Ghayl',
    },
    {
      city_id: 3184,
      region_id: 1,
      name_ar: 'ستارة',
      name_en: 'Sitarah',
    },
    {
      city_id: 3185,
      region_id: 1,
      name_ar: 'حراضة',
      name_en: 'Haradah',
    },
    {
      city_id: 3186,
      region_id: 2,
      name_ar: 'الفرعة',
      name_en: "Al Far'ah",
    },
    {
      city_id: 3187,
      region_id: 2,
      name_ar: 'العثيثي',
      name_en: "Al 'Uthaythi",
    },
    {
      city_id: 3188,
      region_id: 2,
      name_ar: 'ا لحفائر',
      name_en: "Al Hafa'ir",
    },
    {
      city_id: 3189,
      region_id: 2,
      name_ar: 'السلم',
      name_en: 'As Salam',
    },
    {
      city_id: 3190,
      region_id: 2,
      name_ar: 'القاعية',
      name_en: "Al Qa'iyah",
    },
    {
      city_id: 3191,
      region_id: 2,
      name_ar: 'القوامة',
      name_en: 'Al Quwamah',
    },
    {
      city_id: 3192,
      region_id: 2,
      name_ar: 'الغافة',
      name_en: 'Al Ghafah',
    },
    {
      city_id: 3193,
      region_id: 2,
      name_ar: 'الحدة',
      name_en: 'Al Hiddah',
    },
    {
      city_id: 3194,
      region_id: 2,
      name_ar: 'المغراء',
      name_en: 'Al Maghara',
    },
    {
      city_id: 3195,
      region_id: 2,
      name_ar: 'العفيرية',
      name_en: "Al 'Ufayriyah",
    },
    {
      city_id: 3196,
      region_id: 2,
      name_ar: 'أبو ملوح',
      name_en: 'Abu Miluh',
    },
    {
      city_id: 3197,
      region_id: 2,
      name_ar: 'النغر',
      name_en: 'An Naghar',
    },
    {
      city_id: 3198,
      region_id: 2,
      name_ar: 'السدر',
      name_en: 'As Sadr',
    },
    {
      city_id: 3199,
      region_id: 2,
      name_ar: 'الجر ثمية',
      name_en: 'Al Jarr Thamiyyah',
    },
    {
      city_id: 3200,
      region_id: 2,
      name_ar: 'الحجف',
      name_en: 'Al Hajf',
    },
    {
      city_id: 3201,
      region_id: 12,
      name_ar: 'إبن غوف',
      name_en: 'Ibn Ghuf',
    },
    {
      city_id: 3202,
      region_id: 12,
      name_ar: 'الميفا',
      name_en: 'Al Mifa',
    },
    {
      city_id: 3203,
      region_id: 12,
      name_ar: 'القرن',
      name_en: 'Al Qarn',
    },
    {
      city_id: 3204,
      region_id: 12,
      name_ar: 'الامرة',
      name_en: 'Al Umrah',
    },
    {
      city_id: 3205,
      region_id: 12,
      name_ar: 'النجيل',
      name_en: 'An Najil',
    },
    {
      city_id: 3206,
      region_id: 12,
      name_ar: 'الطيف',
      name_en: 'At Tayf',
    },
    {
      city_id: 3207,
      region_id: 12,
      name_ar: 'السمعة',
      name_en: "As Sama'ah",
    },
    {
      city_id: 3208,
      region_id: 12,
      name_ar: 'نابر',
      name_en: 'Nabir',
    },
    {
      city_id: 3209,
      region_id: 12,
      name_ar: 'الضاحي',
      name_en: 'Ad Dahi',
    },
    {
      city_id: 3210,
      region_id: 12,
      name_ar: 'الزرعة',
      name_en: "Az Zira'ah",
    },
    {
      city_id: 3211,
      region_id: 12,
      name_ar: 'الاشيرة',
      name_en: 'Al Ishayrah',
    },
    {
      city_id: 3212,
      region_id: 12,
      name_ar: 'الصور',
      name_en: 'As Sur',
    },
    {
      city_id: 3213,
      region_id: 12,
      name_ar: 'فرعة العطاردة',
      name_en: "Fara't Al 'Atardah",
    },
    {
      city_id: 3214,
      region_id: 12,
      name_ar: 'القهب',
      name_en: 'Al Qahab',
    },
    {
      city_id: 3215,
      region_id: 12,
      name_ar: 'الحصحص',
      name_en: 'Al Hashas',
    },
    {
      city_id: 3216,
      region_id: 12,
      name_ar: 'قراما',
      name_en: 'Qurama',
    },
    {
      city_id: 3217,
      region_id: 12,
      name_ar: 'العياش',
      name_en: "Al 'Ayyash",
    },
    {
      city_id: 3218,
      region_id: 12,
      name_ar: 'آل سعيد',
      name_en: "Al Sa'id",
    },
    {
      city_id: 3219,
      region_id: 12,
      name_ar: 'الغصنة',
      name_en: 'Al Ghasanah',
    },
    {
      city_id: 3220,
      region_id: 12,
      name_ar: 'قلوة',
      name_en: 'Qilwah',
    },
    {
      city_id: 3221,
      region_id: 12,
      name_ar: 'الشعراء',
      name_en: "Ash Sha'ra'",
    },
    {
      city_id: 3222,
      region_id: 12,
      name_ar: 'نيرا',
      name_en: 'Nira',
    },
    {
      city_id: 3223,
      region_id: 12,
      name_ar: 'الجوة',
      name_en: 'Al Jawwah',
    },
    {
      city_id: 3224,
      region_id: 12,
      name_ar: 'الفرع',
      name_en: "Al Far'",
    },
    {
      city_id: 3225,
      region_id: 12,
      name_ar: 'الهتافرة',
      name_en: 'Al Hatafirah',
    },
    {
      city_id: 3226,
      region_id: 2,
      name_ar: 'القريع',
      name_en: "Al Qari'",
    },
    {
      city_id: 3227,
      region_id: 12,
      name_ar: 'المسودة',
      name_en: 'Al Maswadah',
    },
    {
      city_id: 3228,
      region_id: 12,
      name_ar: 'الحشو',
      name_en: 'Al Hashu',
    },
    {
      city_id: 3229,
      region_id: 2,
      name_ar: 'فرعة بني سهيم',
      name_en: "Fara't Bani Suhaym",
    },
    {
      city_id: 3230,
      region_id: 2,
      name_ar: 'العقدة',
      name_en: "Al 'Uqdah",
    },
    {
      city_id: 3231,
      region_id: 2,
      name_ar: 'العواصية',
      name_en: "Al 'Awasiyah",
    },
    {
      city_id: 3232,
      region_id: 12,
      name_ar: 'القصيباء',
      name_en: 'Al Qusayba',
    },
    {
      city_id: 3233,
      region_id: 12,
      name_ar: 'الرهوة',
      name_en: 'Ar Rahwah',
    },
    {
      city_id: 3234,
      region_id: 12,
      name_ar: 'الحصن',
      name_en: 'Al Husn',
    },
    {
      city_id: 3235,
      region_id: 12,
      name_ar: 'غامد الزناد',
      name_en: 'Ghamid Az Zinad',
    },
    {
      city_id: 3236,
      region_id: 12,
      name_ar: 'المخواة',
      name_en: 'Al Mukhwah',
    },
    {
      city_id: 3237,
      region_id: 12,
      name_ar: 'نصبة',
      name_en: 'Nusubah',
    },
    {
      city_id: 3238,
      region_id: 12,
      name_ar: 'ناوان',
      name_en: 'Nawan',
    },
    {
      city_id: 3239,
      region_id: 6,
      name_ar: 'العرق',
      name_en: 'Al Arq',
    },
    {
      city_id: 3240,
      region_id: 6,
      name_ar: 'كعب',
      name_en: "Ka'b",
    },
    {
      city_id: 3241,
      region_id: 6,
      name_ar: 'الخاضرة',
      name_en: 'Al Khadirah',
    },
    {
      city_id: 3242,
      region_id: 6,
      name_ar: 'القرن الأبيض',
      name_en: 'Al Qarn Al Abyad',
    },
    {
      city_id: 3243,
      region_id: 6,
      name_ar: 'الدحض',
      name_en: 'Ad Dahid',
    },
    {
      city_id: 3244,
      region_id: 6,
      name_ar: 'الظهارة',
      name_en: 'Adh Dhaharah',
    },
    {
      city_id: 3245,
      region_id: 6,
      name_ar: 'مخدرة',
      name_en: 'Makhdarah',
    },
    {
      city_id: 3246,
      region_id: 6,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 3247,
      region_id: 6,
      name_ar: 'القرية',
      name_en: 'Al Qurayyah',
    },
    {
      city_id: 3248,
      region_id: 6,
      name_ar: 'بني مليح',
      name_en: 'Bani Mulayh',
    },
    {
      city_id: 3249,
      region_id: 6,
      name_ar: 'وادي الغيل',
      name_en: 'Wadi Al Ghayl',
    },
    {
      city_id: 3250,
      region_id: 6,
      name_ar: 'مليح',
      name_en: 'Mulayh',
    },
    {
      city_id: 3251,
      region_id: 6,
      name_ar: 'ربوع السرو',
      name_en: "Rubu' As Sarw",
    },
    {
      city_id: 3252,
      region_id: 6,
      name_ar: 'آل بو خشيف',
      name_en: 'Al Bukhishayf',
    },
    {
      city_id: 3253,
      region_id: 6,
      name_ar: 'بهوان',
      name_en: 'Bahwan',
    },
    {
      city_id: 3254,
      region_id: 6,
      name_ar: 'الضاربين',
      name_en: 'Ad Daribayn',
    },
    {
      city_id: 3255,
      region_id: 6,
      name_ar: 'آل مخلد',
      name_en: 'Al Makhlad',
    },
    {
      city_id: 3256,
      region_id: 6,
      name_ar: 'آل عباس',
      name_en: "Al 'Abbas",
    },
    {
      city_id: 3257,
      region_id: 6,
      name_ar: 'آل مضرة',
      name_en: 'Al Midrah',
    },
    {
      city_id: 3258,
      region_id: 6,
      name_ar: 'غاشرة',
      name_en: 'Ghashirah',
    },
    {
      city_id: 3259,
      region_id: 6,
      name_ar: 'ذبوب',
      name_en: 'Dhabub',
    },
    {
      city_id: 3260,
      region_id: 6,
      name_ar: 'مجالد',
      name_en: 'Mijalid',
    },
    {
      city_id: 3261,
      region_id: 6,
      name_ar: 'آل سخيطة',
      name_en: 'Al Sakhaytah',
    },
    {
      city_id: 3262,
      region_id: 6,
      name_ar: 'عبالة',
      name_en: "'Aballah",
    },
    {
      city_id: 3263,
      region_id: 6,
      name_ar: 'آل عبيد',
      name_en: "Al 'Ubayd",
    },
    {
      city_id: 3264,
      region_id: 6,
      name_ar: 'آل مشاعر',
      name_en: "Al Misha'ir",
    },
    {
      city_id: 3265,
      region_id: 6,
      name_ar: 'مسفرة',
      name_en: 'Misfirah',
    },
    {
      city_id: 3266,
      region_id: 6,
      name_ar: 'آل فرش',
      name_en: 'Al Farsh',
    },
    {
      city_id: 3267,
      region_id: 6,
      name_ar: 'الحفاة',
      name_en: 'Al Hufah',
    },
    {
      city_id: 3268,
      region_id: 6,
      name_ar: 'الصرة',
      name_en: 'As Surrah',
    },
    {
      city_id: 3269,
      region_id: 6,
      name_ar: 'حوراء',
      name_en: 'Hawra',
    },
    {
      city_id: 3270,
      region_id: 6,
      name_ar: 'القرن والجهيفة',
      name_en: 'Al Qarn Wal Juhaifah',
    },
    {
      city_id: 3271,
      region_id: 6,
      name_ar: 'كبدة',
      name_en: 'Kubadah',
    },
    {
      city_id: 3272,
      region_id: 6,
      name_ar: 'العينين',
      name_en: "Al 'Aynayn",
    },
    {
      city_id: 3273,
      region_id: 6,
      name_ar: 'تنومة',
      name_en: 'Tanumah',
    },
    {
      city_id: 3274,
      region_id: 6,
      name_ar: 'الواديين',
      name_en: 'Al Wadiyayn',
    },
    {
      city_id: 3275,
      region_id: 6,
      name_ar: 'بللسمر',
      name_en: 'Billasmar',
    },
    {
      city_id: 3276,
      region_id: 6,
      name_ar: 'آل لجم',
      name_en: 'Al Lajam',
    },
    {
      city_id: 3277,
      region_id: 6,
      name_ar: 'الاثنين',
      name_en: 'Al Ithnayn',
    },
    {
      city_id: 3278,
      region_id: 6,
      name_ar: 'آل غلفق',
      name_en: 'Al Ghalfaq',
    },
    {
      city_id: 3279,
      region_id: 6,
      name_ar: 'سحيبان',
      name_en: 'Suhayban',
    },
    {
      city_id: 3280,
      region_id: 6,
      name_ar: 'العمارة',
      name_en: "Al 'Ammarah",
    },
    {
      city_id: 3281,
      region_id: 6,
      name_ar: 'قيان',
      name_en: 'Qiyan',
    },
    {
      city_id: 3282,
      region_id: 6,
      name_ar: 'الغرس',
      name_en: 'Al Ghars',
    },
    {
      city_id: 3283,
      region_id: 6,
      name_ar: 'الحكيمة',
      name_en: 'Al Hakimah',
    },
    {
      city_id: 3284,
      region_id: 6,
      name_ar: 'الرقوة',
      name_en: 'Ar Raqwah',
    },
    {
      city_id: 3285,
      region_id: 6,
      name_ar: 'آل الجلدة',
      name_en: 'Al Al Jildah',
    },
    {
      city_id: 3286,
      region_id: 6,
      name_ar: 'مشروفة',
      name_en: 'Mashrufah',
    },
    {
      city_id: 3287,
      region_id: 6,
      name_ar: 'لاهمة',
      name_en: 'Lahmah',
    },
    {
      city_id: 3288,
      region_id: 6,
      name_ar: 'آل فهيد',
      name_en: 'Al Fuhayd',
    },
    {
      city_id: 3289,
      region_id: 6,
      name_ar: 'الحدباء',
      name_en: 'Al Hadba',
    },
    {
      city_id: 3290,
      region_id: 6,
      name_ar: 'آل صنيج',
      name_en: 'Al Sunayj',
    },
    {
      city_id: 3291,
      region_id: 6,
      name_ar: 'آل عرفان',
      name_en: "Al 'Urfan",
    },
    {
      city_id: 3292,
      region_id: 6,
      name_ar: 'آل كشيم',
      name_en: 'Al Kushaym',
    },
    {
      city_id: 3293,
      region_id: 6,
      name_ar: 'الحذيان',
      name_en: 'Al Hadhyan',
    },
    {
      city_id: 3294,
      region_id: 6,
      name_ar: 'العرفان',
      name_en: "Al 'Urfan",
    },
    {
      city_id: 3295,
      region_id: 6,
      name_ar: 'حافظ',
      name_en: 'Hafidh',
    },
    {
      city_id: 3296,
      region_id: 6,
      name_ar: 'العلوبي',
      name_en: "Al 'Ulubi",
    },
    {
      city_id: 3297,
      region_id: 6,
      name_ar: 'الرمضة',
      name_en: 'Ar Rumadah',
    },
    {
      city_id: 3298,
      region_id: 6,
      name_ar: 'العرين',
      name_en: "Al 'Arin",
    },
    {
      city_id: 3299,
      region_id: 6,
      name_ar: 'فرعة طريب',
      name_en: 'Turaib',
    },
    {
      city_id: 3300,
      region_id: 6,
      name_ar: 'الزهرة',
      name_en: 'Az Zahrah',
    },
    {
      city_id: 3301,
      region_id: 6,
      name_ar: 'آل معدي',
      name_en: "Al Ma'addi",
    },
    {
      city_id: 3302,
      region_id: 6,
      name_ar: 'الغلقة',
      name_en: 'Al Ghiliqah',
    },
    {
      city_id: 3303,
      region_id: 6,
      name_ar: 'الجلدة',
      name_en: 'Al Jildah',
    },
    {
      city_id: 3304,
      region_id: 6,
      name_ar: 'آل قنبة',
      name_en: 'Al Qunbah',
    },
    {
      city_id: 3305,
      region_id: 6,
      name_ar: 'الربعة',
      name_en: "Ar Riba'ah",
    },
    {
      city_id: 3306,
      region_id: 6,
      name_ar: 'الوهابة',
      name_en: 'Al Wahabah',
    },
    {
      city_id: 3307,
      region_id: 6,
      name_ar: 'آل سعيدة',
      name_en: "Al Sa'idah",
    },
    {
      city_id: 3308,
      region_id: 6,
      name_ar: 'آل كولت',
      name_en: 'Al Kulat',
    },
    {
      city_id: 3309,
      region_id: 6,
      name_ar: 'آل جلال',
      name_en: 'Al Jallal',
    },
    {
      city_id: 3310,
      region_id: 6,
      name_ar: 'آل محي',
      name_en: 'Al Mihi',
    },
    {
      city_id: 3311,
      region_id: 6,
      name_ar: 'الحدباء',
      name_en: 'Al Hadba',
    },
    {
      city_id: 3312,
      region_id: 6,
      name_ar: 'الحنجور',
      name_en: 'Al Hunjur',
    },
    {
      city_id: 3313,
      region_id: 6,
      name_ar: 'الحنو',
      name_en: 'Al Hinu',
    },
    {
      city_id: 3314,
      region_id: 6,
      name_ar: 'المنابية',
      name_en: 'Al Manabiyah',
    },
    {
      city_id: 3315,
      region_id: 6,
      name_ar: 'الزهيد',
      name_en: 'Az Zuhayd',
    },
    {
      city_id: 3316,
      region_id: 6,
      name_ar: 'المحزمة',
      name_en: 'Al Muhazmah',
    },
    {
      city_id: 3317,
      region_id: 6,
      name_ar: 'الدربين',
      name_en: 'Ad Darbayn',
    },
    {
      city_id: 3318,
      region_id: 10,
      name_ar: 'الشعاقة',
      name_en: "Ash Sha'aqah",
    },
    {
      city_id: 3319,
      region_id: 6,
      name_ar: 'عدن',
      name_en: "'Adan",
    },
    {
      city_id: 3320,
      region_id: 6,
      name_ar: 'الغول',
      name_en: 'Al Ghawl',
    },
    {
      city_id: 3321,
      region_id: 6,
      name_ar: 'درامة',
      name_en: 'Diramah',
    },
    {
      city_id: 3322,
      region_id: 6,
      name_ar: 'آل الخلف',
      name_en: 'Al Al Khalaf',
    },
    {
      city_id: 3323,
      region_id: 6,
      name_ar: 'آل مانع',
      name_en: "Al Mani'",
    },
    {
      city_id: 3324,
      region_id: 6,
      name_ar: 'نقعة',
      name_en: "Naq'ah",
    },
    {
      city_id: 3325,
      region_id: 6,
      name_ar: 'آل غبران',
      name_en: 'Al Ghubran',
    },
    {
      city_id: 3326,
      region_id: 6,
      name_ar: 'الفقاعيس',
      name_en: "Al Fuqa'is",
    },
    {
      city_id: 3327,
      region_id: 6,
      name_ar: 'الحريقة',
      name_en: 'Al Hariqah',
    },
    {
      city_id: 3328,
      region_id: 6,
      name_ar: 'سراة عبيدة',
      name_en: 'Sarat Abidah',
    },
    {
      city_id: 3329,
      region_id: 6,
      name_ar: 'العطفة',
      name_en: "Al 'Utfah",
    },
    {
      city_id: 3330,
      region_id: 6,
      name_ar: 'الفرشة',
      name_en: 'Al Farshah',
    },
    {
      city_id: 3331,
      region_id: 6,
      name_ar: 'الربوعة',
      name_en: 'Ar Rabuah',
    },
    {
      city_id: 3332,
      region_id: 6,
      name_ar: 'الجوة',
      name_en: 'Al Jawwah',
    },
    {
      city_id: 3333,
      region_id: 6,
      name_ar: 'عرضين',
      name_en: 'Ardayn',
    },
    {
      city_id: 3334,
      region_id: 11,
      name_ar: 'ام الوهط',
      name_en: 'Umm Al Whaht',
    },
    {
      city_id: 3335,
      region_id: 11,
      name_ar: 'المنخلي',
      name_en: 'Al Minkhali',
    },
    {
      city_id: 3336,
      region_id: 11,
      name_ar: 'خد قيس',
      name_en: 'Khad Qays',
    },
    {
      city_id: 3337,
      region_id: 11,
      name_ar: 'الحمضة',
      name_en: 'Al Hamdah',
    },
    {
      city_id: 3338,
      region_id: 11,
      name_ar: 'تريمة',
      name_en: 'Tirimah',
    },
    {
      city_id: 3339,
      region_id: 11,
      name_ar: 'الظاهرة',
      name_en: 'Adh Dhahirah',
    },
    {
      city_id: 3340,
      region_id: 11,
      name_ar: 'رضية',
      name_en: 'Rudayyah',
    },
    {
      city_id: 3341,
      region_id: 11,
      name_ar: 'حزامة',
      name_en: 'Hazamah',
    },
    {
      city_id: 3342,
      region_id: 11,
      name_ar: 'بدر الجنوب',
      name_en: 'Badr Al Janub',
    },
    {
      city_id: 3343,
      region_id: 11,
      name_ar: 'الحرشف',
      name_en: 'Al Harshaf',
    },
    {
      city_id: 3344,
      region_id: 11,
      name_ar: 'هدادة',
      name_en: 'Hadadah',
    },
    {
      city_id: 3345,
      region_id: 11,
      name_ar: 'الخانق',
      name_en: 'Al Khaniq',
    },
    {
      city_id: 3346,
      region_id: 11,
      name_ar: 'الجفة',
      name_en: 'Al Jifah',
    },
    {
      city_id: 3347,
      region_id: 11,
      name_ar: 'شرورة',
      name_en: 'Sharurah',
    },
    {
      city_id: 3348,
      region_id: 11,
      name_ar: 'شقة الكناور',
      name_en: 'Shiqqat Al Kanawir',
    },
    {
      city_id: 3349,
      region_id: 11,
      name_ar: 'التماني',
      name_en: 'At Tamani',
    },
    {
      city_id: 3350,
      region_id: 11,
      name_ar: 'الوديعة',
      name_en: "Al Wadi'ah",
    },
    {
      city_id: 3351,
      region_id: 6,
      name_ar: 'المجمع',
      name_en: "Al Majma'",
    },
    {
      city_id: 3352,
      region_id: 6,
      name_ar: 'شيبة مسورة',
      name_en: 'Shaybat Miswarah',
    },
    {
      city_id: 3353,
      region_id: 6,
      name_ar: 'آل ثابت',
      name_en: 'Al Thabit',
    },
    {
      city_id: 3354,
      region_id: 6,
      name_ar: 'الجربة',
      name_en: 'Al Jirbah',
    },
    {
      city_id: 3355,
      region_id: 6,
      name_ar: 'الغيل',
      name_en: 'Al Ghayl',
    },
    {
      city_id: 3356,
      region_id: 6,
      name_ar: 'المجزعة',
      name_en: "Al Majza'ah",
    },
    {
      city_id: 3357,
      region_id: 6,
      name_ar: 'آل عليا',
      name_en: "Al 'Alya",
    },
    {
      city_id: 3358,
      region_id: 6,
      name_ar: 'آل بيضاء',
      name_en: 'Al Bayda',
    },
    {
      city_id: 3359,
      region_id: 6,
      name_ar: 'آل معمر',
      name_en: "Al Mu'ammar",
    },
    {
      city_id: 3360,
      region_id: 6,
      name_ar: 'الملحة',
      name_en: 'Al Malhah',
    },
    {
      city_id: 3361,
      region_id: 6,
      name_ar: 'الحاجر',
      name_en: 'Al Hajir',
    },
    {
      city_id: 3362,
      region_id: 6,
      name_ar: 'علب',
      name_en: 'Alb',
    },
    {
      city_id: 3363,
      region_id: 6,
      name_ar: 'الملطاء',
      name_en: 'Al Malta',
    },
    {
      city_id: 3364,
      region_id: 6,
      name_ar: 'الرهوة',
      name_en: 'Ar Rahwah',
    },
    {
      city_id: 3365,
      region_id: 6,
      name_ar: 'المجازة',
      name_en: 'Al Mijazah',
    },
    {
      city_id: 3366,
      region_id: 6,
      name_ar: 'بن لكرم',
      name_en: 'Bin Lakram',
    },
    {
      city_id: 3367,
      region_id: 6,
      name_ar: 'السلاطين',
      name_en: 'As Salatin',
    },
    {
      city_id: 3368,
      region_id: 6,
      name_ar: 'المحجر',
      name_en: 'Al Mahjar',
    },
    {
      city_id: 3369,
      region_id: 6,
      name_ar: 'الوسط',
      name_en: 'Al Wasat',
    },
    {
      city_id: 3370,
      region_id: 6,
      name_ar: 'العقدة',
      name_en: "Al 'Uqdah",
    },
    {
      city_id: 3371,
      region_id: 6,
      name_ar: 'آل الأشعث',
      name_en: "Al Al Ash'ath",
    },
    {
      city_id: 3372,
      region_id: 6,
      name_ar: 'العنثري',
      name_en: "Al 'Anthari",
    },
    {
      city_id: 3373,
      region_id: 6,
      name_ar: 'آل عمران',
      name_en: "Al 'Imran",
    },
    {
      city_id: 3374,
      region_id: 6,
      name_ar: 'آل جحالي',
      name_en: 'Al Jihali',
    },
    {
      city_id: 3375,
      region_id: 6,
      name_ar: 'العرف',
      name_en: "Al 'Araf",
    },
    {
      city_id: 3376,
      region_id: 6,
      name_ar: 'دهر',
      name_en: 'Dihir',
    },
    {
      city_id: 3377,
      region_id: 6,
      name_ar: 'الحظيرة',
      name_en: 'Al Hadhirah',
    },
    {
      city_id: 3378,
      region_id: 6,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 3379,
      region_id: 6,
      name_ar: 'علاف',
      name_en: "'Allaf",
    },
    {
      city_id: 3380,
      region_id: 6,
      name_ar: 'آل يعلى',
      name_en: "Al Ya'la",
    },
    {
      city_id: 3381,
      region_id: 6,
      name_ar: 'ظهران الجنوب',
      name_en: 'Dhahran Al Janub',
    },
    {
      city_id: 3382,
      region_id: 6,
      name_ar: 'الطلحة',
      name_en: 'At Talhah',
    },
    {
      city_id: 3383,
      region_id: 6,
      name_ar: 'الحرجة',
      name_en: 'Al Harajah',
    },
    {
      city_id: 3384,
      region_id: 6,
      name_ar: 'الكولة',
      name_en: 'Al Kulah',
    },
    {
      city_id: 3385,
      region_id: 6,
      name_ar: 'القصب',
      name_en: 'Al Qasab',
    },
    {
      city_id: 3386,
      region_id: 6,
      name_ar: 'حمران',
      name_en: 'Humran',
    },
    {
      city_id: 3387,
      region_id: 6,
      name_ar: 'آل لهوة',
      name_en: 'Al Lahwah',
    },
    {
      city_id: 3388,
      region_id: 11,
      name_ar: 'الهويمل',
      name_en: 'Al Huwaymil',
    },
    {
      city_id: 3389,
      region_id: 11,
      name_ar: 'بئر خباش',
      name_en: 'Bir Khubash',
    },
    {
      city_id: 3390,
      region_id: 11,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 3391,
      region_id: 11,
      name_ar: 'قابل منيف',
      name_en: 'Qabil Minif',
    },
    {
      city_id: 3392,
      region_id: 11,
      name_ar: 'الهويد',
      name_en: 'Al Hawid',
    },
    {
      city_id: 3393,
      region_id: 11,
      name_ar: 'الحجف',
      name_en: 'Al Hajf',
    },
    {
      city_id: 3394,
      region_id: 11,
      name_ar: 'الدواس',
      name_en: 'Ad Dawas',
    },
    {
      city_id: 3395,
      region_id: 11,
      name_ar: 'لاهومة',
      name_en: 'Lahumah',
    },
    {
      city_id: 3396,
      region_id: 11,
      name_ar: 'حبونا',
      name_en: 'Hubuna',
    },
    {
      city_id: 3397,
      region_id: 11,
      name_ar: 'الحصينية',
      name_en: 'Al Husayniyah',
    },
    {
      city_id: 3398,
      region_id: 10,
      name_ar: 'قرار',
      name_en: 'Qarar',
    },
    {
      city_id: 3399,
      region_id: 10,
      name_ar: 'الكدارة',
      name_en: 'Al Kadarah',
    },
    {
      city_id: 3400,
      region_id: 10,
      name_ar: 'الهجنبة',
      name_en: 'Al Hajanbah',
    },
    {
      city_id: 3401,
      region_id: 10,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 3402,
      region_id: 10,
      name_ar: 'الدرب',
      name_en: 'Ad Darb',
    },
    {
      city_id: 3403,
      region_id: 10,
      name_ar: 'ابو السداد',
      name_en: 'Abu As Sadad',
    },
    {
      city_id: 3404,
      region_id: 10,
      name_ar: 'الشقيق',
      name_en: 'Ash Shaqiq',
    },
    {
      city_id: 3405,
      region_id: 10,
      name_ar: 'الحروف',
      name_en: 'Al Huruf',
    },
    {
      city_id: 3406,
      region_id: 10,
      name_ar: 'عتود',
      name_en: "'Itwad",
    },
    {
      city_id: 3407,
      region_id: 10,
      name_ar: 'رملان',
      name_en: 'Ramlan',
    },
    {
      city_id: 3408,
      region_id: 10,
      name_ar: 'المنجارة',
      name_en: 'Al Minjarah',
    },
    {
      city_id: 3409,
      region_id: 10,
      name_ar: 'الدولة',
      name_en: 'Ad Dawlah',
    },
    {
      city_id: 3410,
      region_id: 10,
      name_ar: 'مسلية',
      name_en: 'Misliyah',
    },
    {
      city_id: 3411,
      region_id: 10,
      name_ar: 'الفطيحة',
      name_en: 'Al Fatihah',
    },
    {
      city_id: 3412,
      region_id: 11,
      name_ar: 'الفيصلية',
      name_en: 'Al Faysaliyah',
    },
    {
      city_id: 3413,
      region_id: 11,
      name_ar: 'معمر ريمان',
      name_en: "Ma'mar Riman",
    },
    {
      city_id: 3414,
      region_id: 11,
      name_ar: 'دوح',
      name_en: 'Dawwah',
    },
    {
      city_id: 3415,
      region_id: 11,
      name_ar: 'النقعاء',
      name_en: "An Naq'a",
    },
    {
      city_id: 3416,
      region_id: 11,
      name_ar: 'نقعاء حتروش',
      name_en: "An Naq'a Hatrush",
    },
    {
      city_id: 3417,
      region_id: 11,
      name_ar: 'نجران',
      name_en: 'Najran',
    },
    {
      city_id: 3418,
      region_id: 11,
      name_ar: 'بئر عسكر',
      name_en: "Bi'r 'Askar",
    },
    {
      city_id: 3419,
      region_id: 11,
      name_ar: 'نهوقة',
      name_en: 'Nahuqah',
    },
    {
      city_id: 3420,
      region_id: 11,
      name_ar: 'ال شهي',
      name_en: 'Al Shahi',
    },
    {
      city_id: 3421,
      region_id: 11,
      name_ar: 'رجلاء',
      name_en: "Rijla'",
    },
    {
      city_id: 3422,
      region_id: 11,
      name_ar: 'آل سوار',
      name_en: 'Alsuwar',
    },
    {
      city_id: 3423,
      region_id: 11,
      name_ar: 'المجمع',
      name_en: "Al Majma'",
    },
    {
      city_id: 3424,
      region_id: 11,
      name_ar: 'البرك',
      name_en: 'Al Birk',
    },
    {
      city_id: 3425,
      region_id: 11,
      name_ar: 'العريسة',
      name_en: "Al 'Arayyisah",
    },
    {
      city_id: 3426,
      region_id: 10,
      name_ar: 'رخية',
      name_en: 'Rakhyah',
    },
    {
      city_id: 3427,
      region_id: 10,
      name_ar: 'الريث',
      name_en: 'Ar Rayth',
    },
    {
      city_id: 3428,
      region_id: 10,
      name_ar: 'الرهوة',
      name_en: 'Ar Rahwah',
    },
    {
      city_id: 3429,
      region_id: 10,
      name_ar: 'عثوان',
      name_en: "'Athwan",
    },
    {
      city_id: 3430,
      region_id: 10,
      name_ar: 'القعقاع',
      name_en: "Al Qa'qa'",
    },
    {
      city_id: 3431,
      region_id: 10,
      name_ar: 'الشملاة',
      name_en: 'Ash Shamlah',
    },
    {
      city_id: 3432,
      region_id: 10,
      name_ar: 'السهية',
      name_en: 'As Suhayyah',
    },
    {
      city_id: 3433,
      region_id: 10,
      name_ar: 'عراب',
      name_en: "'Irab",
    },
    {
      city_id: 3434,
      region_id: 10,
      name_ar: 'الخطوة',
      name_en: 'Al Khutwah',
    },
    {
      city_id: 3435,
      region_id: 10,
      name_ar: 'الحناية',
      name_en: 'Al Hinnayah',
    },
    {
      city_id: 3436,
      region_id: 10,
      name_ar: 'النفيعة',
      name_en: "An Nafi'ah",
    },
    {
      city_id: 3437,
      region_id: 10,
      name_ar: 'زوزاء',
      name_en: 'Zawza',
    },
    {
      city_id: 3438,
      region_id: 10,
      name_ar: 'الركبة',
      name_en: 'Ar Rukbah',
    },
    {
      city_id: 3439,
      region_id: 10,
      name_ar: 'العزة',
      name_en: "Al 'Izzah",
    },
    {
      city_id: 3440,
      region_id: 10,
      name_ar: 'خويعمة',
      name_en: "Khuway'imah",
    },
    {
      city_id: 3441,
      region_id: 10,
      name_ar: 'الدخرة',
      name_en: 'Ad Dikarah',
    },
    {
      city_id: 3442,
      region_id: 10,
      name_ar: 'الخشعة',
      name_en: 'Al Khashah',
    },
    {
      city_id: 3443,
      region_id: 10,
      name_ar: 'الكوابسة',
      name_en: 'Al Kawabisar',
    },
    {
      city_id: 3444,
      region_id: 10,
      name_ar: 'الهيجاء',
      name_en: 'Al Hayja',
    },
    {
      city_id: 3445,
      region_id: 10,
      name_ar: 'اليمانية',
      name_en: 'Al Yamaniyah',
    },
    {
      city_id: 3446,
      region_id: 10,
      name_ar: 'فيفاء',
      name_en: 'Fayfa',
    },
    {
      city_id: 3447,
      region_id: 10,
      name_ar: 'الدائر',
      name_en: 'Ad Dair',
    },
    {
      city_id: 3448,
      region_id: 10,
      name_ar: 'عيبان',
      name_en: "'Aiban",
    },
    {
      city_id: 3449,
      region_id: 10,
      name_ar: 'الطرف',
      name_en: 'At Taraf',
    },
    {
      city_id: 3450,
      region_id: 10,
      name_ar: 'ظاهرة الصفا',
      name_en: 'Dhahirat As Safa',
    },
    {
      city_id: 3451,
      region_id: 10,
      name_ar: 'القاع',
      name_en: "Al Qa'",
    },
    {
      city_id: 3452,
      region_id: 10,
      name_ar: 'القتب',
      name_en: 'Al Qatab',
    },
    {
      city_id: 3453,
      region_id: 10,
      name_ar: 'الهياج',
      name_en: 'Al Hiyaj',
    },
    {
      city_id: 3454,
      region_id: 10,
      name_ar: 'العقدة',
      name_en: "Al 'Uqdah",
    },
    {
      city_id: 3455,
      region_id: 10,
      name_ar: 'العيدابي',
      name_en: "Al 'Aydabi",
    },
    {
      city_id: 3456,
      region_id: 10,
      name_ar: 'مقزع',
      name_en: "Muqza'",
    },
    {
      city_id: 3457,
      region_id: 10,
      name_ar: 'هروب',
      name_en: 'Harub',
    },
    {
      city_id: 3458,
      region_id: 10,
      name_ar: 'الشريعة',
      name_en: "Ash Shari'ah",
    },
    {
      city_id: 3459,
      region_id: 10,
      name_ar: 'الحجروف',
      name_en: 'Al Hajruf',
    },
    {
      city_id: 3460,
      region_id: 10,
      name_ar: 'نورة',
      name_en: 'Nurah',
    },
    {
      city_id: 3461,
      region_id: 10,
      name_ar: 'الأثلة',
      name_en: 'Al Athlah',
    },
    {
      city_id: 3462,
      region_id: 10,
      name_ar: 'بيش',
      name_en: 'Bish',
    },
    {
      city_id: 3463,
      region_id: 10,
      name_ar: 'الحضن',
      name_en: 'Al Hadan',
    },
    {
      city_id: 3464,
      region_id: 10,
      name_ar: 'قوز الجعافره',
      name_en: 'Qouz Al Jaafrah',
    },
    {
      city_id: 3465,
      region_id: 10,
      name_ar: 'الحقو',
      name_en: 'Al Haqu',
    },
    {
      city_id: 3466,
      region_id: 10,
      name_ar: 'القوام',
      name_en: 'Al Quwam',
    },
    {
      city_id: 3467,
      region_id: 10,
      name_ar: 'المطعن',
      name_en: "Al Mat'an",
    },
    {
      city_id: 3468,
      region_id: 10,
      name_ar: 'العداية',
      name_en: "Al 'Addayah",
    },
    {
      city_id: 3469,
      region_id: 10,
      name_ar: 'الباحر',
      name_en: 'Al Bahir',
    },
    {
      city_id: 3470,
      region_id: 10,
      name_ar: 'المعترض',
      name_en: "Al Mu'tarid",
    },
    {
      city_id: 3471,
      region_id: 10,
      name_ar: 'نخلان',
      name_en: 'Nakhlan',
    },
    {
      city_id: 3472,
      region_id: 10,
      name_ar: 'الغرا',
      name_en: 'Al Ghara',
    },
    {
      city_id: 3473,
      region_id: 10,
      name_ar: 'العبادلة',
      name_en: "Al 'Abadilah",
    },
    {
      city_id: 3474,
      region_id: 10,
      name_ar: 'العريش',
      name_en: "Al 'Arish",
    },
    {
      city_id: 3475,
      region_id: 10,
      name_ar: 'مشلحة',
      name_en: 'Mushallahah',
    },
    {
      city_id: 3476,
      region_id: 10,
      name_ar: 'الرايغة',
      name_en: 'Ar Rayighah',
    },
    {
      city_id: 3477,
      region_id: 10,
      name_ar: 'الملحاة',
      name_en: 'Al Malha',
    },
    {
      city_id: 3478,
      region_id: 10,
      name_ar: 'الدهناء',
      name_en: 'Ad Dahna',
    },
    {
      city_id: 3479,
      region_id: 10,
      name_ar: 'صبيا',
      name_en: 'Sabya',
    },
    {
      city_id: 3480,
      region_id: 10,
      name_ar: 'قائم الدش',
      name_en: "Qa'im Ad Dashsh",
    },
    {
      city_id: 3481,
      region_id: 10,
      name_ar: 'صبيا الجديدة',
      name_en: 'Sabya Al Jadidah',
    },
    {
      city_id: 3482,
      region_id: 10,
      name_ar: 'الكدمي',
      name_en: 'Al Kudmi',
    },
    {
      city_id: 3483,
      region_id: 10,
      name_ar: 'العشة',
      name_en: 'Al Ashah',
    },
    {
      city_id: 3484,
      region_id: 10,
      name_ar: 'السلام العليا',
      name_en: "As Salam Al 'Ulya",
    },
    {
      city_id: 3485,
      region_id: 10,
      name_ar: 'ابو القعايد',
      name_en: "Abu Alqa'ayid",
    },
    {
      city_id: 3486,
      region_id: 10,
      name_ar: 'ابو السلع',
      name_en: "Abu As Sala'",
    },
    {
      city_id: 3487,
      region_id: 10,
      name_ar: 'العالية',
      name_en: "Al 'Aliyah",
    },
    {
      city_id: 3488,
      region_id: 10,
      name_ar: 'غوان',
      name_en: 'Ghawwan',
    },
    {
      city_id: 3489,
      region_id: 10,
      name_ar: 'الحسيني',
      name_en: 'Al Husayni',
    },
    {
      city_id: 3490,
      region_id: 10,
      name_ar: 'الظبية',
      name_en: 'Al Dhabyah',
    },
    {
      city_id: 3491,
      region_id: 10,
      name_ar: 'الجمالة',
      name_en: 'Al Jammalah',
    },
    {
      city_id: 3492,
      region_id: 10,
      name_ar: 'السبخة',
      name_en: 'Al Sabakhah',
    },
    {
      city_id: 3493,
      region_id: 10,
      name_ar: 'الفقرة نجران',
      name_en: 'Al Faqrah Najran',
    },
    {
      city_id: 3494,
      region_id: 10,
      name_ar: 'خبت سعيدة',
      name_en: "Khabt Sa'idah",
    },
    {
      city_id: 3495,
      region_id: 10,
      name_ar: 'الحمي',
      name_en: 'Al Hima',
    },
    {
      city_id: 3496,
      region_id: 10,
      name_ar: 'السليل',
      name_en: 'As Sulayyil',
    },
    {
      city_id: 3497,
      region_id: 10,
      name_ar: 'الجهو',
      name_en: 'Al Jahw',
    },
    {
      city_id: 3498,
      region_id: 10,
      name_ar: 'القمري',
      name_en: 'Al Qamri',
    },
    {
      city_id: 3499,
      region_id: 10,
      name_ar: 'ضمد',
      name_en: 'Damad',
    },
    {
      city_id: 3500,
      region_id: 10,
      name_ar: 'الخضيرة',
      name_en: 'Al Khudayrah',
    },
    {
      city_id: 3501,
      region_id: 10,
      name_ar: 'الشقيري',
      name_en: 'Alshuqayri',
    },
    {
      city_id: 3502,
      region_id: 10,
      name_ar: 'زبارة رشيد',
      name_en: 'Zabarat Rashid',
    },
    {
      city_id: 3503,
      region_id: 10,
      name_ar: 'قنبورة',
      name_en: 'Qanbura',
    },
    {
      city_id: 3504,
      region_id: 10,
      name_ar: 'بحرة',
      name_en: 'Bahrah',
    },
    {
      city_id: 3505,
      region_id: 10,
      name_ar: 'القائم',
      name_en: 'Al Qaim',
    },
    {
      city_id: 3506,
      region_id: 10,
      name_ar: 'المحصام',
      name_en: 'Al Muhsam',
    },
    {
      city_id: 3507,
      region_id: 10,
      name_ar: 'الحجاجة',
      name_en: 'Al Hajajah',
    },
    {
      city_id: 3508,
      region_id: 10,
      name_ar: 'الجربة',
      name_en: 'Al Jirbah',
    },
    {
      city_id: 3509,
      region_id: 10,
      name_ar: 'السادلية',
      name_en: 'As Sadiliyah',
    },
    {
      city_id: 3510,
      region_id: 10,
      name_ar: 'مريخية',
      name_en: 'Muraykhiyah',
    },
    {
      city_id: 3511,
      region_id: 10,
      name_ar: 'العروس',
      name_en: "Al 'Arus",
    },
    {
      city_id: 3512,
      region_id: 10,
      name_ar: 'الخزنة',
      name_en: 'Al Khuznah',
    },
    {
      city_id: 3513,
      region_id: 10,
      name_ar: 'العسيلة',
      name_en: "Al 'Usaylah",
    },
    {
      city_id: 3514,
      region_id: 10,
      name_ar: 'سد ملاكي',
      name_en: 'Sadd Malaki',
    },
    {
      city_id: 3515,
      region_id: 10,
      name_ar: 'الكتيفة',
      name_en: 'Al Kutayfah',
    },
    {
      city_id: 3516,
      region_id: 10,
      name_ar: 'السيبة',
      name_en: 'As Syabah',
    },
    {
      city_id: 3517,
      region_id: 10,
      name_ar: 'المرزوق',
      name_en: 'Al Marzuq',
    },
    {
      city_id: 3518,
      region_id: 10,
      name_ar: 'الحريقة',
      name_en: 'Al Hariqah',
    },
    {
      city_id: 3519,
      region_id: 10,
      name_ar: 'الرد',
      name_en: 'Ar Radd',
    },
    {
      city_id: 3520,
      region_id: 10,
      name_ar: 'دودة',
      name_en: 'Dudah',
    },
    {
      city_id: 3521,
      region_id: 10,
      name_ar: 'الدوشية',
      name_en: 'Ad Dawshiyah',
    },
    {
      city_id: 3522,
      region_id: 10,
      name_ar: 'أم الحجل',
      name_en: 'Umm Al Hajal',
    },
    {
      city_id: 3523,
      region_id: 10,
      name_ar: 'المقيدر',
      name_en: 'Al Muqaydir',
    },
    {
      city_id: 3524,
      region_id: 10,
      name_ar: 'السلامة',
      name_en: 'As Salamah',
    },
    {
      city_id: 3525,
      region_id: 10,
      name_ar: 'ابو عريش',
      name_en: 'Abu Arish',
    },
    {
      city_id: 3526,
      region_id: 10,
      name_ar: 'الحميرة',
      name_en: 'Al Humayrah',
    },
    {
      city_id: 3527,
      region_id: 10,
      name_ar: 'فلس',
      name_en: 'Falas',
    },
    {
      city_id: 3528,
      region_id: 10,
      name_ar: 'حاكمة',
      name_en: 'Hakimah',
    },
    {
      city_id: 3529,
      region_id: 10,
      name_ar: 'الصيابة',
      name_en: 'As Sayyabah',
    },
    {
      city_id: 3530,
      region_id: 10,
      name_ar: 'العباسية',
      name_en: "Al 'Abbasiyah",
    },
    {
      city_id: 3531,
      region_id: 10,
      name_ar: 'الكروس',
      name_en: 'Al Kurus',
    },
    {
      city_id: 3532,
      region_id: 10,
      name_ar: 'المبيت',
      name_en: 'Al Mubayyat',
    },
    {
      city_id: 3533,
      region_id: 10,
      name_ar: 'السرداح',
      name_en: 'As Sirdah',
    },
    {
      city_id: 3534,
      region_id: 10,
      name_ar: 'المعزاب',
      name_en: "Al Mi'zab",
    },
    {
      city_id: 3535,
      region_id: 10,
      name_ar: 'السودي',
      name_en: 'As Sawadi',
    },
    {
      city_id: 3536,
      region_id: 10,
      name_ar: 'الحلحلة',
      name_en: 'Al Halhalah',
    },
    {
      city_id: 3537,
      region_id: 10,
      name_ar: 'وعلان',
      name_en: "Wa'lan",
    },
    {
      city_id: 3538,
      region_id: 10,
      name_ar: 'البيسري',
      name_en: 'Al Baysuri',
    },
    {
      city_id: 3539,
      region_id: 10,
      name_ar: 'ديحمة',
      name_en: 'Dayhamah',
    },
    {
      city_id: 3540,
      region_id: 10,
      name_ar: 'الخلايف',
      name_en: 'Al Khalayif',
    },
    {
      city_id: 3541,
      region_id: 10,
      name_ar: 'الشنامرة',
      name_en: 'Al Shanamirah',
    },
    {
      city_id: 3542,
      region_id: 10,
      name_ar: 'صامطة',
      name_en: 'Samtah',
    },
    {
      city_id: 3543,
      region_id: 10,
      name_ar: 'الجرادية',
      name_en: 'Al Juradiyah',
    },
    {
      city_id: 3544,
      region_id: 10,
      name_ar: 'ابو حجر الاسفل',
      name_en: 'Abu Hajar Al Asfal',
    },
    {
      city_id: 3545,
      region_id: 10,
      name_ar: 'الدغارير',
      name_en: 'Ad Dagharir',
    },
    {
      city_id: 3546,
      region_id: 10,
      name_ar: 'حاكمة',
      name_en: 'Hakimah',
    },
    {
      city_id: 3547,
      region_id: 10,
      name_ar: 'حاكمة الدغارير',
      name_en: 'Hakimah Ad Dagharir',
    },
    {
      city_id: 3548,
      region_id: 10,
      name_ar: 'القفل',
      name_en: 'Al Qufl',
    },
    {
      city_id: 3549,
      region_id: 10,
      name_ar: 'السر',
      name_en: 'As Sirr',
    },
    {
      city_id: 3550,
      region_id: 10,
      name_ar: 'اللقية',
      name_en: 'Al Laqyah',
    },
    {
      city_id: 3551,
      region_id: 10,
      name_ar: 'ابو حجر الأعلى',
      name_en: "Abu Hajar Al A'la",
    },
    {
      city_id: 3552,
      region_id: 10,
      name_ar: 'النجامية',
      name_en: 'An Nujamiyah',
    },
    {
      city_id: 3553,
      region_id: 10,
      name_ar: 'شعب الذئب',
      name_en: "Shi'b Adh Dhib",
    },
    {
      city_id: 3554,
      region_id: 10,
      name_ar: 'الخوجرة',
      name_en: 'Al Khawjarah',
    },
    {
      city_id: 3555,
      region_id: 10,
      name_ar: 'الطوال',
      name_en: 'At Tuwal',
    },
    {
      city_id: 3556,
      region_id: 10,
      name_ar: 'المجنة',
      name_en: 'Al Mijannah',
    },
    {
      city_id: 3558,
      region_id: 10,
      name_ar: 'الجاظع',
      name_en: "Al Jadhi'",
    },
    {
      city_id: 3559,
      region_id: 10,
      name_ar: 'الركوبة',
      name_en: 'Ar Rukubah',
    },
    {
      city_id: 3560,
      region_id: 10,
      name_ar: 'مجعر',
      name_en: "Muj'ur",
    },
    {
      city_id: 3561,
      region_id: 10,
      name_ar: 'الموسم',
      name_en: 'Al Muwassam',
    },
    {
      city_id: 3562,
      region_id: 11,
      name_ar: 'الخرخير',
      name_en: 'Al Kharkhir',
    },
    {
      city_id: 3563,
      region_id: 10,
      name_ar: 'أبو طوق',
      name_en: 'Abu Tawq',
    },
    {
      city_id: 3564,
      region_id: 10,
      name_ar: 'العقيل',
      name_en: "Al 'Uqail",
    },
    {
      city_id: 3565,
      region_id: 10,
      name_ar: 'ختب',
      name_en: 'Khatb',
    },
    {
      city_id: 3566,
      region_id: 10,
      name_ar: 'صير',
      name_en: 'Sir',
    },
    {
      city_id: 3567,
      region_id: 10,
      name_ar: 'القصار',
      name_en: 'Al Qsar',
    },
    {
      city_id: 3568,
      region_id: 10,
      name_ar: 'المحرق',
      name_en: 'Al Muharq',
    },
    {
      city_id: 3569,
      region_id: 10,
      name_ar: 'جنابه',
      name_en: 'Gnaba',
    },
    {
      city_id: 3570,
      region_id: 10,
      name_ar: 'الميناء الجديد',
      name_en: 'New Port',
    },
    {
      city_id: 3571,
      region_id: 10,
      name_ar: 'فرسان',
      name_en: 'Farasan',
    },
    {
      city_id: 3572,
      region_id: 3,
      name_ar: 'أم صمدين',
      name_en: 'Umm Sumdayn',
    },
    {
      city_id: 3573,
      region_id: 3,
      name_ar: 'المشران',
      name_en: 'Al Mishran',
    },
    {
      city_id: 3581,
      region_id: 7,
      name_ar: 'بئر بن هرماس',
      name_en: 'Bir Ibn Hermas',
    },
    {
      city_id: 3582,
      region_id: 5,
      name_ar: 'الحليسية',
      name_en: 'Al Hulaysiyah',
    },
    {
      city_id: 3583,
      region_id: 5,
      name_ar: 'راس ام قصبة',
      name_en: 'Ras Umm Qusbah',
    },
    {
      city_id: 3584,
      region_id: 10,
      name_ar: 'خبت البقر',
      name_en: 'Khabt Al Baqar',
    },
    {
      city_id: 3585,
      region_id: 10,
      name_ar: 'الحنبة',
      name_en: 'Al Hanabah',
    },
    {
      city_id: 3586,
      region_id: 10,
      name_ar: 'عرق',
      name_en: 'Irq',
    },
    {
      city_id: 3587,
      region_id: 10,
      name_ar: 'أبو العشرة',
      name_en: "Abu Al 'Usharah",
    },
    {
      city_id: 3588,
      region_id: 10,
      name_ar: 'جرار',
      name_en: 'Jarrar',
    },
    {
      city_id: 3589,
      region_id: 10,
      name_ar: 'العيشة',
      name_en: "Al 'Ayshah",
    },
    {
      city_id: 3590,
      region_id: 10,
      name_ar: 'جبل عبادل',
      name_en: "Jabal 'Abadil",
    },
    {
      city_id: 3591,
      region_id: 10,
      name_ar: 'البحثة',
      name_en: 'Al Bihthah',
    },
    {
      city_id: 3592,
      region_id: 10,
      name_ar: 'المجازيع',
      name_en: 'Al Mijazi',
    },
    {
      city_id: 3593,
      region_id: 10,
      name_ar: 'المسكية',
      name_en: 'Al Maskiyah',
    },
    {
      city_id: 3594,
      region_id: 10,
      name_ar: 'حمية',
      name_en: 'Hamayah',
    },
    {
      city_id: 3595,
      region_id: 10,
      name_ar: 'السحار',
      name_en: 'As Sahhar',
    },
    {
      city_id: 3596,
      region_id: 10,
      name_ar: 'القصبة',
      name_en: 'Al Qasabah',
    },
    {
      city_id: 3597,
      region_id: 10,
      name_ar: 'العارضة',
      name_en: "Al 'Aridah",
    },
    {
      city_id: 3598,
      region_id: 5,
      name_ar: 'سنابس',
      name_en: 'Sanabis',
    },
    {
      city_id: 3599,
      region_id: 10,
      name_ar: 'قيس',
      name_en: 'Qays',
    },
    {
      city_id: 3600,
      region_id: 10,
      name_ar: 'الوحلة',
      name_en: 'Al Wahlah',
    },
    {
      city_id: 3601,
      region_id: 10,
      name_ar: 'العشوة',
      name_en: "Al 'Ishwah",
    },
    {
      city_id: 3602,
      region_id: 10,
      name_ar: 'الفقهاء',
      name_en: 'Al Fuqaha',
    },
    {
      city_id: 3603,
      region_id: 10,
      name_ar: 'دحيقة',
      name_en: 'Duhayqah',
    },
    {
      city_id: 3604,
      region_id: 10,
      name_ar: 'الشواجرة',
      name_en: 'Ash Shawajirah',
    },
    {
      city_id: 3605,
      region_id: 10,
      name_ar: 'بخشة',
      name_en: 'Bakhshah',
    },
    {
      city_id: 3606,
      region_id: 10,
      name_ar: 'الاساملة',
      name_en: 'Al Asamilah',
    },
    {
      city_id: 3607,
      region_id: 10,
      name_ar: 'الخلفة',
      name_en: 'Al Khalfah',
    },
    {
      city_id: 3608,
      region_id: 10,
      name_ar: 'جاضع المحاضين',
      name_en: "Jadi' Al Mahadin",
    },
    {
      city_id: 3609,
      region_id: 10,
      name_ar: 'الخمس',
      name_en: 'Al Khums',
    },
    {
      city_id: 3610,
      region_id: 10,
      name_ar: 'مستورة',
      name_en: 'Masturah',
    },
    {
      city_id: 3611,
      region_id: 10,
      name_ar: 'السداد',
      name_en: 'As Sudad',
    },
    {
      city_id: 3612,
      region_id: 10,
      name_ar: 'الزهبين',
      name_en: 'Az Zahabayn',
    },
    {
      city_id: 3613,
      region_id: 10,
      name_ar: 'صنبة',
      name_en: 'Sanbah',
    },
    {
      city_id: 3614,
      region_id: 7,
      name_ar: 'البدع',
      name_en: "Al Bada'",
    },
    {
      city_id: 3615,
      region_id: 10,
      name_ar: 'المعبوج',
      name_en: "Al Ma'buj",
    },
    {
      city_id: 3616,
      region_id: 10,
      name_ar: 'الخصاوية',
      name_en: 'Al Khasawiyah',
    },
    {
      city_id: 3617,
      region_id: 10,
      name_ar: 'المضاية',
      name_en: 'Al Madayah',
    },
    {
      city_id: 3618,
      region_id: 10,
      name_ar: 'البديع والقرفي',
      name_en: "Al Badi' Wal Qarafi",
    },
    {
      city_id: 3619,
      region_id: 10,
      name_ar: 'مزهرة',
      name_en: 'Mizhirah',
    },
    {
      city_id: 3620,
      region_id: 10,
      name_ar: 'الكربوص',
      name_en: 'Al Karbus',
    },
    {
      city_id: 3621,
      region_id: 10,
      name_ar: 'الواصلي',
      name_en: 'Al Wasili',
    },
    {
      city_id: 3622,
      region_id: 10,
      name_ar: 'الريان',
      name_en: 'Ar Rayyan',
    },
    {
      city_id: 3623,
      region_id: 10,
      name_ar: 'قامزة',
      name_en: 'Qamizah',
    },
    {
      city_id: 3624,
      region_id: 10,
      name_ar: 'شهرين',
      name_en: 'Shahrayn',
    },
    {
      city_id: 3625,
      region_id: 10,
      name_ar: 'المروة',
      name_en: 'Al Marwah',
    },
    {
      city_id: 3626,
      region_id: 10,
      name_ar: 'الظهر',
      name_en: 'Adh Dhahar',
    },
    {
      city_id: 3627,
      region_id: 10,
      name_ar: 'العقم',
      name_en: "Al 'Aqum",
    },
    {
      city_id: 3628,
      region_id: 10,
      name_ar: 'الصبخاية',
      name_en: 'As Subkhayah',
    },
    {
      city_id: 3629,
      region_id: 10,
      name_ar: 'جلاح',
      name_en: 'Jilah',
    },
    {
      city_id: 3630,
      region_id: 10,
      name_ar: 'الراحة',
      name_en: 'Ar Rahah',
    },
    {
      city_id: 3631,
      region_id: 10,
      name_ar: 'المهدف',
      name_en: 'Al Mihdaf',
    },
    {
      city_id: 3632,
      region_id: 10,
      name_ar: 'مخشوش',
      name_en: 'Makhshush',
    },
    {
      city_id: 3633,
      region_id: 10,
      name_ar: 'الخوبة',
      name_en: 'Al Khawbah',
    },
    {
      city_id: 3634,
      region_id: 10,
      name_ar: 'القحمة',
      name_en: 'Al Qahmah',
    },
    {
      city_id: 3635,
      region_id: 10,
      name_ar: 'الخشل',
      name_en: 'Al Khashal',
    },
    {
      city_id: 3636,
      region_id: 10,
      name_ar: 'الجابري',
      name_en: 'Al Jabiri',
    },
    {
      city_id: 3637,
      region_id: 10,
      name_ar: 'جحا',
      name_en: 'Juha',
    },
    {
      city_id: 3638,
      region_id: 10,
      name_ar: 'السر',
      name_en: 'As Sirr',
    },
    {
      city_id: 3639,
      region_id: 10,
      name_ar: 'الهجنبة',
      name_en: 'Al Hajanbah',
    },
    {
      city_id: 3640,
      region_id: 10,
      name_ar: 'الشطيفية',
      name_en: 'Ash Shutayfiyah',
    },
    {
      city_id: 3641,
      region_id: 10,
      name_ar: 'الحصامة',
      name_en: 'Al Hassamah',
    },
    {
      city_id: 3642,
      region_id: 10,
      name_ar: 'الخضراء الشمالية',
      name_en: 'Al Khadra Ash Shamaliyah',
    },
    {
      city_id: 3643,
      region_id: 10,
      name_ar: 'السويدية',
      name_en: 'Al Suwaydiyah',
    },
    {
      city_id: 3644,
      region_id: 10,
      name_ar: 'رعشة',
      name_en: 'Rashah',
    },
    {
      city_id: 3645,
      region_id: 10,
      name_ar: 'المجامة',
      name_en: 'Al Mujammah',
    },
    {
      city_id: 3646,
      region_id: 10,
      name_ar: 'المخشلية',
      name_en: 'Al Mukhashshaliyah',
    },
    {
      city_id: 3647,
      region_id: 10,
      name_ar: 'الحامضة',
      name_en: 'Al Hamidah',
    },
    {
      city_id: 3648,
      region_id: 10,
      name_ar: 'الجعدية',
      name_en: "Al Ja'diyah",
    },
    {
      city_id: 3649,
      region_id: 10,
      name_ar: 'المحامل',
      name_en: 'Al Mahamil',
    },
    {
      city_id: 3650,
      region_id: 10,
      name_ar: 'الحصمة',
      name_en: 'Al Hasamah',
    },
    {
      city_id: 3651,
      region_id: 10,
      name_ar: 'حاكمة',
      name_en: 'Hakimah',
    },
    {
      city_id: 3652,
      region_id: 10,
      name_ar: 'احد المسارحة',
      name_en: 'Ahad Al Musarihah',
    },
    {
      city_id: 3653,
      region_id: 10,
      name_ar: 'الحجفار',
      name_en: 'Al Hijfar',
    },
    {
      city_id: 3654,
      region_id: 10,
      name_ar: 'الطاهرية',
      name_en: 'At Tahiriyah',
    },
    {
      city_id: 3655,
      region_id: 10,
      name_ar: 'الحضرور',
      name_en: 'Al Hadrur',
    },
    {
      city_id: 3656,
      region_id: 10,
      name_ar: 'الدريعية',
      name_en: "Ad Duray'iyah",
    },
    {
      city_id: 3657,
      region_id: 10,
      name_ar: 'البدوي',
      name_en: 'Al Badawi',
    },
    {
      city_id: 3658,
      region_id: 10,
      name_ar: 'الهلية',
      name_en: 'Al Halliyah',
    },
    {
      city_id: 3659,
      region_id: 10,
      name_ar: 'الحنيني',
      name_en: 'Al Hanini',
    },
    {
      city_id: 3660,
      region_id: 10,
      name_ar: 'قمعة',
      name_en: "Qama'ah",
    },
    {
      city_id: 3661,
      region_id: 10,
      name_ar: 'المنجارة',
      name_en: 'Al Minjarah',
    },
    {
      city_id: 3662,
      region_id: 10,
      name_ar: 'المقرقم',
      name_en: 'Al Maqarqam',
    },
    {
      city_id: 3663,
      region_id: 10,
      name_ar: 'المحطة',
      name_en: 'Al Mahattah',
    },
    {
      city_id: 3664,
      region_id: 10,
      name_ar: 'الحقلة',
      name_en: 'Al Haqlah',
    },
    {
      city_id: 3665,
      region_id: 10,
      name_ar: 'أبو الرديف',
      name_en: 'Abu Ar Radif',
    },
    {
      city_id: 3666,
      region_id: 2,
      name_ar: 'مدينة الملك عبدالله الاقتصادية',
      name_en: 'King Abdullah Economic City',
    },
    {
      city_id: 3667,
      region_id: 10,
      name_ar: 'الخارش',
      name_en: 'Al Kharish',
    },
    {
      city_id: 3668,
      region_id: 10,
      name_ar: 'السهي',
      name_en: 'As Sahi',
    },
    {
      city_id: 3669,
      region_id: 10,
      name_ar: 'رمادا',
      name_en: 'Ramada',
    },
    {
      city_id: 3670,
      region_id: 10,
      name_ar: 'روان',
      name_en: 'Rawan',
    },
    {
      city_id: 3671,
      region_id: 5,
      name_ar: 'هجرة شهيل',
      name_en: 'Hijrat Shuhayl',
    },
    {
      city_id: 3672,
      region_id: 1,
      name_ar: 'البرزة',
      name_en: 'Al Barzah',
    },
    {
      city_id: 3673,
      region_id: 1,
      name_ar: 'أم سدرة',
      name_en: 'Umm Sidrah',
    },
    {
      city_id: 3674,
      region_id: 1,
      name_ar: 'مشذوبة',
      name_en: 'Mashdhubah',
    },
    {
      city_id: 3675,
      region_id: 1,
      name_ar: 'المفيجر',
      name_en: 'Al Mufaijir',
    },
    {
      city_id: 3676,
      region_id: 6,
      name_ar: 'وادي الحياة',
      name_en: 'Wadi Al Hayat',
    },
    {
      city_id: 3677,
      region_id: 5,
      name_ar: 'الاحساء',
      name_en: 'Al Ahsa',
    },
    {
      city_id: 3678,
      region_id: 6,
      name_ar: 'تندحة',
      name_en: 'Tendaha',
    },
    {
      city_id: 3679,
      region_id: 9,
      name_ar: 'السليمانية',
      name_en: 'As Sulaimaniyah',
    },
    {
      city_id: 3680,
      region_id: 6,
      name_ar: 'الواديين',
      name_en: 'Al Wadiyayn',
    },
    {
      city_id: 3681,
      region_id: 6,
      name_ar: 'الفيض',
      name_en: 'Al Fayd',
    },
    {
      city_id: 3682,
      region_id: 6,
      name_ar: 'ختبة',
      name_en: 'Khatbah',
    },
    {
      city_id: 3683,
      region_id: 1,
      name_ar: 'مشرفة',
      name_en: 'Mushrifah',
    },
    {
      city_id: 3684,
      region_id: 2,
      name_ar: 'اضم',
      name_en: 'Adham',
    },
    {
      city_id: 3685,
      region_id: 8,
      name_ar: 'الخلصة',
      name_en: 'Al Khlusah',
    },
    {
      city_id: 3686,
      region_id: 5,
      name_ar: 'السيح',
      name_en: 'As Seeh',
    },
    {
      city_id: 3687,
      region_id: 6,
      name_ar: 'حسوة',
      name_en: 'Hiswah',
    },
    {
      city_id: 3688,
      region_id: 6,
      name_ar: 'الفرعين',
      name_en: 'Al Faraain',
    },
    {
      city_id: 3689,
      region_id: 6,
      name_ar: 'بللحمر',
      name_en: 'Billahmar',
    },
    {
      city_id: 3690,
      region_id: 6,
      name_ar: 'بحر ابو سكينة',
      name_en: 'Bahr Abu Sukaynah',
    },
    {
      city_id: 3691,
      region_id: 1,
      name_ar: 'العمق',
      name_en: 'Al Umaq',
    },
    {
      city_id: 3692,
      region_id: 6,
      name_ar: 'يعرى',
      name_en: 'Yaara',
    },
    {
      city_id: 3693,
      region_id: 6,
      name_ar: 'العيش',
      name_en: 'Al Aysh',
    },
    {
      city_id: 3694,
      region_id: 7,
      name_ar: 'جزيرة صنافير',
      name_en: 'Sanafir Island',
    },
    {
      city_id: 3695,
      region_id: 7,
      name_ar: 'جزيرة تيران',
      name_en: 'Tiran Island',
    },
    {
      city_id: 3696,
      region_id: 3,
      name_ar: 'سليلة جهينة',
      name_en: 'Salilat Juhaynah',
    },
    {
      city_id: 3697,
      region_id: 6,
      name_ar: 'المسقي',
      name_en: 'Al Masqi',
    },
    {
      city_id: 3698,
      region_id: 1,
      name_ar: 'الصفوية',
      name_en: 'As Safwiyah',
    },
    {
      city_id: 3699,
      region_id: 10,
      name_ar: 'آل علي',
      name_en: "Al 'Ali",
    },
    {
      city_id: 3700,
      region_id: 5,
      name_ar: 'جواثا',
      name_en: 'Jawatha',
    },
    {
      city_id: 3701,
      region_id: 13,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 3702,
      region_id: 5,
      name_ar: 'رأس الخير',
      name_en: 'Ras Al Khair',
    },
    {
      city_id: 3703,
      region_id: 3,
      name_ar: 'البريكة',
      name_en: 'Al Buraykah',
    },
    {
      city_id: 3704,
      region_id: 3,
      name_ar: 'منطقة الصناعات الخفيفة',
      name_en: 'Light Industrial Zone',
    },
    {
      city_id: 3705,
      region_id: 8,
      name_ar: 'شعيب الصقعاء',
      name_en: "Shuaib As Saqa'a",
    },
    {
      city_id: 3706,
      region_id: 8,
      name_ar: 'مشاش ابن جازي',
      name_en: 'Mashash Ibn Jazi',
    },
    {
      city_id: 3707,
      region_id: 8,
      name_ar: 'فيضة السمار',
      name_en: 'Faydat As Sammar',
    },
    {
      city_id: 3708,
      region_id: 7,
      name_ar: 'ذرفي',
      name_en: 'Dharfi',
    },
    {
      city_id: 3709,
      region_id: 11,
      name_ar: 'المشعلية',
      name_en: 'Al Mishaliyah',
    },
    {
      city_id: 3710,
      region_id: 8,
      name_ar: 'الخوي',
      name_en: 'Al Khawi',
    },
    {
      city_id: 3711,
      region_id: 6,
      name_ar: 'آل رحمة',
      name_en: 'Al Rahmah',
    },
    {
      city_id: 3712,
      region_id: 2,
      name_ar: 'السدين',
      name_en: 'As Saddain',
    },
    {
      city_id: 3753,
      region_id: 1,
      name_ar: 'الوسيطاء',
      name_en: 'Al Wusayta',
    },
    {
      city_id: 3755,
      region_id: 1,
      name_ar: 'الضبيعة',
      name_en: 'Al Dubayah',
    },
    {
      city_id: 3771,
      region_id: 1,
      name_ar: 'العزيزيه',
      name_en: 'Al Aziziyah',
    },
    {
      city_id: 3788,
      region_id: 1,
      name_ar: 'شراف',
      name_en: 'Sharaf',
    },
    {
      city_id: 3794,
      region_id: 1,
      name_ar: 'الحبابيه',
      name_en: 'Al Hababiyah',
    },
    {
      city_id: 3813,
      region_id: 1,
      name_ar: 'المغمي',
      name_en: 'Al Maghmi',
    },
    {
      city_id: 3874,
      region_id: 1,
      name_ar: 'الطرفية',
      name_en: 'Al Tarafiyyah',
    },
    {
      city_id: 3901,
      region_id: 1,
      name_ar: 'السدريه',
      name_en: 'As Sadryah',
    },
    {
      city_id: 3902,
      region_id: 1,
      name_ar: 'الهويديه',
      name_en: 'Al Huwaydyah',
    },
    {
      city_id: 3903,
      region_id: 1,
      name_ar: 'العناديه',
      name_en: 'Al Enadyah',
    },
    {
      city_id: 3905,
      region_id: 1,
      name_ar: 'نعام',
      name_en: "Na'am",
    },
    {
      city_id: 3945,
      region_id: 1,
      name_ar: 'ابو سدر',
      name_en: 'Abu Sidr',
    },
    {
      city_id: 3946,
      region_id: 1,
      name_ar: 'مشرفة الكراشمه',
      name_en: 'Mushrifah Al Krashmah',
    },
    {
      city_id: 3996,
      region_id: 1,
      name_ar: 'المطاوي',
      name_en: 'Al Mutawi',
    },
    {
      city_id: 4019,
      region_id: 1,
      name_ar: 'ابو عشر',
      name_en: 'Abu Ashr',
    },
    {
      city_id: 4021,
      region_id: 4,
      name_ar: 'الناشرية',
      name_en: 'Al Nashiriyah',
    },
    {
      city_id: 4028,
      region_id: 1,
      name_ar: 'الحيد',
      name_en: 'Al Hayd',
    },
    {
      city_id: 4050,
      region_id: 1,
      name_ar: 'خريمان',
      name_en: 'Khuraiman',
    },
    {
      city_id: 4080,
      region_id: 1,
      name_ar: 'الراجحية',
      name_en: 'Al Rajhiyah',
    },
    {
      city_id: 4088,
      region_id: 1,
      name_ar: 'السيح',
      name_en: 'As Seeh',
    },
    {
      city_id: 4092,
      region_id: 1,
      name_ar: 'الصالحية',
      name_en: 'As Salhiyah',
    },
    {
      city_id: 4118,
      region_id: 1,
      name_ar: 'برزان',
      name_en: 'Barzan',
    },
    {
      city_id: 4129,
      region_id: 1,
      name_ar: 'حنيدر',
      name_en: 'Hunaidir',
    },
    {
      city_id: 4131,
      region_id: 1,
      name_ar: 'الجنوبية بسدير',
      name_en: 'Southern Sudair',
    },
    {
      city_id: 4396,
      region_id: 1,
      name_ar: 'الوسيطا',
      name_en: 'Al Wasita',
    },
    {
      city_id: 4574,
      region_id: 1,
      name_ar: 'الجلة الجنوبي',
      name_en: 'Jellah Al Janubi',
    },
    {
      city_id: 4594,
      region_id: 1,
      name_ar: 'دهو',
      name_en: 'Daho',
    },
    {
      city_id: 4604,
      region_id: 1,
      name_ar: 'السلام',
      name_en: 'As Salam',
    },
    {
      city_id: 4620,
      region_id: 1,
      name_ar: 'قمران',
      name_en: 'Qamran',
    },
    {
      city_id: 4621,
      region_id: 1,
      name_ar: 'ام سليم',
      name_en: 'Umm Sulaim',
    },
    {
      city_id: 4632,
      region_id: 1,
      name_ar: 'صيحة',
      name_en: 'Sayha',
    },
    {
      city_id: 4636,
      region_id: 1,
      name_ar: 'بلثقاء',
      name_en: 'Balthaqa',
    },
    {
      city_id: 4643,
      region_id: 1,
      name_ar: 'الجبيلة',
      name_en: 'Al Jubailah',
    },
    {
      city_id: 4644,
      region_id: 1,
      name_ar: 'الفريشة',
      name_en: 'Al Farishah',
    },
    {
      city_id: 4670,
      region_id: 1,
      name_ar: 'الصغو',
      name_en: 'Al Saghu',
    },
    {
      city_id: 4678,
      region_id: 1,
      name_ar: 'وسيلة',
      name_en: 'Wasilah',
    },
    {
      city_id: 4680,
      region_id: 1,
      name_ar: 'العجلية',
      name_en: 'Al Ajiliyyah',
    },
    {
      city_id: 4684,
      region_id: 1,
      name_ar: 'الفرشة',
      name_en: 'Al Farshah',
    },
    {
      city_id: 4729,
      region_id: 1,
      name_ar: 'الجوى',
      name_en: 'Al Jawa',
    },
    {
      city_id: 4748,
      region_id: 1,
      name_ar: 'ام شيح',
      name_en: 'Umm Sheih',
    },
    {
      city_id: 4765,
      region_id: 1,
      name_ar: 'الحيانية',
      name_en: 'Al Hayaniyah',
    },
    {
      city_id: 4785,
      region_id: 1,
      name_ar: 'بيضاء نثيل',
      name_en: 'Baydaa Nathil',
    },
    {
      city_id: 4790,
      region_id: 1,
      name_ar: 'النباه',
      name_en: 'Al Nabah',
    },
    {
      city_id: 4807,
      region_id: 1,
      name_ar: 'صالحية كف',
      name_en: 'Salhiyat Kaf',
    },
    {
      city_id: 4815,
      region_id: 1,
      name_ar: 'المصيفقيه',
      name_en: 'Al Musayfiqih',
    },
    {
      city_id: 4816,
      region_id: 1,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 4837,
      region_id: 1,
      name_ar: 'الباينيه',
      name_en: 'Al Baynih',
    },
    {
      city_id: 4841,
      region_id: 1,
      name_ar: 'الروضة',
      name_en: 'Ar Rawdah',
    },
    {
      city_id: 4848,
      region_id: 1,
      name_ar: 'النزهة',
      name_en: 'An Nuzhah',
    },
    {
      city_id: 4865,
      region_id: 1,
      name_ar: 'الجامعية',
      name_en: 'Al Jamieyah',
    },
    {
      city_id: 4873,
      region_id: 1,
      name_ar: 'رفايع المزينى',
      name_en: "Ruf'ai' Al Muzaini",
    },
    {
      city_id: 4885,
      region_id: 1,
      name_ar: 'بشاير',
      name_en: 'Bashayir',
    },
    {
      city_id: 4910,
      region_id: 1,
      name_ar: 'دارة المردمة',
      name_en: 'Darat Al Mardamah',
    },
    {
      city_id: 4947,
      region_id: 1,
      name_ar: 'الجافورة',
      name_en: 'Al Jaforah',
    },
    {
      city_id: 4972,
      region_id: 1,
      name_ar: 'الحفيرة',
      name_en: 'Al Hufayrah',
    },
    {
      city_id: 4997,
      region_id: 1,
      name_ar: 'القرينه',
      name_en: 'Al Qurainah',
    },
    {
      city_id: 5001,
      region_id: 1,
      name_ar: 'غيانه وحرقان',
      name_en: 'Ghianah Wahurqan',
    },
    {
      city_id: 5022,
      region_id: 1,
      name_ar: 'العبدليه',
      name_en: 'Al Abdaliyah',
    },
    {
      city_id: 5080,
      region_id: 2,
      name_ar: 'الغبان',
      name_en: 'Al Ghobban',
    },
    {
      city_id: 5164,
      region_id: 2,
      name_ar: 'الصدر',
      name_en: 'As Sadr',
    },
    {
      city_id: 5181,
      region_id: 2,
      name_ar: 'المربع',
      name_en: "Al Murabba'",
    },
    {
      city_id: 5571,
      region_id: 2,
      name_ar: 'اعلى وادي عرضة',
      name_en: 'Aala Wadi Ardah',
    },
    {
      city_id: 5608,
      region_id: 2,
      name_ar: 'ام سدرة (البدايع)',
      name_en: 'Umm Sidrah (Al Badayie)',
    },
    {
      city_id: 5642,
      region_id: 2,
      name_ar: 'قهوة العبد',
      name_en: 'Qahwat Al Abd',
    },
    {
      city_id: 5730,
      region_id: 2,
      name_ar: 'العقيق',
      name_en: 'Al Aqiq',
    },
    {
      city_id: 5731,
      region_id: 2,
      name_ar: 'دغبج',
      name_en: 'Dughbaj',
    },
    {
      city_id: 5877,
      region_id: 2,
      name_ar: 'البيضين',
      name_en: 'Al Baydain',
    },
    {
      city_id: 5879,
      region_id: 2,
      name_ar: 'الخضراء',
      name_en: 'Al Khadraa',
    },
    {
      city_id: 5959,
      region_id: 2,
      name_ar: 'الوطاة',
      name_en: 'Al Watah',
    },
    {
      city_id: 6045,
      region_id: 2,
      name_ar: 'منذر البحيرة',
      name_en: 'Mundhir Al Buhayrah',
    },
    {
      city_id: 6051,
      region_id: 2,
      name_ar: 'حرف المروة',
      name_en: 'Harf Al Marwah',
    },
    {
      city_id: 6052,
      region_id: 2,
      name_ar: 'فريدة الصلة',
      name_en: 'Faridat Al Silah',
    },
    {
      city_id: 6137,
      region_id: 2,
      name_ar: 'الطراحية',
      name_en: 'Al Tarahiyyah',
    },
    {
      city_id: 6138,
      region_id: 2,
      name_ar: 'المساعيد',
      name_en: 'Al Masaid',
    },
    {
      city_id: 6175,
      region_id: 2,
      name_ar: 'الفلحة',
      name_en: 'Al Falhah',
    },
    {
      city_id: 6302,
      region_id: 2,
      name_ar: 'ضهياء',
      name_en: 'Dahyaa',
    },
    {
      city_id: 6778,
      region_id: 2,
      name_ar: 'حفار',
      name_en: 'Haffar',
    },
    {
      city_id: 6907,
      region_id: 2,
      name_ar: 'الصغو',
      name_en: 'Al Saghu',
    },
    {
      city_id: 7035,
      region_id: 2,
      name_ar: 'غران',
      name_en: 'Gharan',
    },
    {
      city_id: 7642,
      region_id: 2,
      name_ar: 'الركنة',
      name_en: 'Al Raknah',
    },
    {
      city_id: 7680,
      region_id: 2,
      name_ar: 'الشباشبة',
      name_en: 'Al Shabashibah',
    },
    {
      city_id: 8238,
      region_id: 2,
      name_ar: 'الغريسة',
      name_en: 'Al Gharisah',
    },
    {
      city_id: 8653,
      region_id: 2,
      name_ar: 'المطحنه',
      name_en: 'Al Mathanah',
    },
    {
      city_id: 8654,
      region_id: 2,
      name_ar: 'الجناح',
      name_en: 'Al Janah',
    },
    {
      city_id: 8749,
      region_id: 2,
      name_ar: 'مشرف',
      name_en: 'Mushrif',
    },
    {
      city_id: 9176,
      region_id: 2,
      name_ar: 'دقم الذنيب',
      name_en: 'Daqm Al Dhanib',
    },
    {
      city_id: 9594,
      region_id: 3,
      name_ar: 'الرذايا',
      name_en: 'Al Radhaya',
    },
    {
      city_id: 9605,
      region_id: 3,
      name_ar: 'ينبع الصناعية',
      name_en: 'Yanbu Al Sinaiyah',
    },
    {
      city_id: 9666,
      region_id: 3,
      name_ar: 'تلعة نزة',
      name_en: "Tal'ah Nazah",
    },
    {
      city_id: 9812,
      region_id: 3,
      name_ar: 'العذيب',
      name_en: 'Al Adhib',
    },
    {
      city_id: 9814,
      region_id: 3,
      name_ar: 'قراقر',
      name_en: 'Qaraqir',
    },
    {
      city_id: 9906,
      region_id: 3,
      name_ar: 'آبار الطويرفة',
      name_en: 'Abar Al Tuwairqiyyah',
    },
    {
      city_id: 9936,
      region_id: 3,
      name_ar: 'الروزه',
      name_en: 'Al Roozah',
    },
    {
      city_id: 10064,
      region_id: 3,
      name_ar: 'السريحية',
      name_en: 'Al Suraihiyah',
    },
    {
      city_id: 10351,
      region_id: 3,
      name_ar: 'رحبة الحسنى',
      name_en: 'Rahbat Al Husna',
    },
    {
      city_id: 10364,
      region_id: 3,
      name_ar: 'الرفيعة',
      name_en: "Ar Rafi'ah",
    },
    {
      city_id: 10366,
      region_id: 3,
      name_ar: 'المجرذية',
      name_en: 'Al Majradiyah',
    },
    {
      city_id: 10367,
      region_id: 3,
      name_ar: 'ضويل',
      name_en: 'Duwail',
    },
    {
      city_id: 10437,
      region_id: 3,
      name_ar: 'صبحا الجحفه',
      name_en: 'Sabha Al Jahfah',
    },
    {
      city_id: 10444,
      region_id: 3,
      name_ar: 'بدائع الحناحنه',
      name_en: "Badai' Al Hanahanah",
    },
    {
      city_id: 10445,
      region_id: 3,
      name_ar: 'بدائع العوض',
      name_en: "Badai' Al Awd",
    },
    {
      city_id: 10456,
      region_id: 3,
      name_ar: 'بطحى',
      name_en: 'Batha',
    },
    {
      city_id: 10475,
      region_id: 3,
      name_ar: 'ملح',
      name_en: 'Malah',
    },
    {
      city_id: 10706,
      region_id: 4,
      name_ar: 'الجديدات',
      name_en: 'Al Jadidat',
    },
    {
      city_id: 10723,
      region_id: 4,
      name_ar: 'مبهل',
      name_en: 'Mabhal',
    },
    {
      city_id: 10724,
      region_id: 4,
      name_ar: 'الحجره',
      name_en: 'Al Hiijrah',
    },
    {
      city_id: 10742,
      region_id: 4,
      name_ar: 'العرداء',
      name_en: 'Al Ardaa',
    },
    {
      city_id: 10772,
      region_id: 4,
      name_ar: 'حريفيات درعه',
      name_en: 'Harifiyat Dareih',
    },
    {
      city_id: 10784,
      region_id: 4,
      name_ar: 'المكيلي',
      name_en: 'Al Makili',
    },
    {
      city_id: 10808,
      region_id: 4,
      name_ar: 'الاحمدية',
      name_en: 'Al Ahmadiyyah',
    },
    {
      city_id: 10809,
      region_id: 4,
      name_ar: 'عين ابن فهيد',
      name_en: "'Ayn  Ibn Fuhayd",
    },
    {
      city_id: 10824,
      region_id: 4,
      name_ar: 'تلعة رشيد',
      name_en: "Tal'ah Rashid",
    },
    {
      city_id: 10826,
      region_id: 4,
      name_ar: 'كفت',
      name_en: 'Kofat',
    },
    {
      city_id: 10827,
      region_id: 4,
      name_ar: 'النويصفة',
      name_en: 'Al Nuwaisifah',
    },
    {
      city_id: 10828,
      region_id: 4,
      name_ar: 'الصحاف',
      name_en: 'As Sahaf',
    },
    {
      city_id: 10829,
      region_id: 4,
      name_ar: 'مشرفة عمودان',
      name_en: 'Mushrifah Amudan',
    },
    {
      city_id: 10831,
      region_id: 4,
      name_ar: 'الاخيضر',
      name_en: 'Al Ukhaydir',
    },
    {
      city_id: 10832,
      region_id: 4,
      name_ar: 'فيضة السلمات',
      name_en: 'Faydat Al Salmat',
    },
    {
      city_id: 10833,
      region_id: 4,
      name_ar: 'الخريشاء',
      name_en: 'Al Khuraysha',
    },
    {
      city_id: 10835,
      region_id: 4,
      name_ar: 'العازمية',
      name_en: "Al 'Azimiyah",
    },
    {
      city_id: 10842,
      region_id: 4,
      name_ar: 'ابو قليبات',
      name_en: 'Abu Qaleebat',
    },
    {
      city_id: 10843,
      region_id: 4,
      name_ar: 'الجحفة',
      name_en: 'Al Juhfah',
    },
    {
      city_id: 10845,
      region_id: 4,
      name_ar: 'قراضية',
      name_en: 'Qaradiyyah',
    },
    {
      city_id: 10849,
      region_id: 4,
      name_ar: 'الخنقة',
      name_en: 'Al Khanqah',
    },
    {
      city_id: 10850,
      region_id: 4,
      name_ar: 'ضمينة',
      name_en: 'Daminah',
    },
    {
      city_id: 10852,
      region_id: 4,
      name_ar: 'الديرية',
      name_en: 'Ad Dirirah',
    },
    {
      city_id: 10853,
      region_id: 4,
      name_ar: 'ملحة',
      name_en: 'Malhah',
    },
    {
      city_id: 10867,
      region_id: 4,
      name_ar: 'اباالحياص',
      name_en: 'Aba Al Hayas',
    },
    {
      city_id: 10868,
      region_id: 4,
      name_ar: 'ابو طرفاء',
      name_en: 'Abu Tarfa',
    },
    {
      city_id: 10870,
      region_id: 4,
      name_ar: 'اللغفية',
      name_en: 'Al Laghfiyyah',
    },
    {
      city_id: 10872,
      region_id: 4,
      name_ar: 'المرموثة',
      name_en: 'Al Marmuthah',
    },
    {
      city_id: 10873,
      region_id: 4,
      name_ar: 'المرموثة الشمالية',
      name_en: 'Al Marmuthah Ash Shamaliyah',
    },
    {
      city_id: 10879,
      region_id: 4,
      name_ar: 'مازونة',
      name_en: 'Mazunah',
    },
    {
      city_id: 10880,
      region_id: 4,
      name_ar: 'الصلبيه',
      name_en: 'Al Salbiyah',
    },
    {
      city_id: 10889,
      region_id: 4,
      name_ar: 'الكدادية',
      name_en: 'Al Kadadiyah',
    },
    {
      city_id: 10908,
      region_id: 4,
      name_ar: 'ابو حمادة',
      name_en: 'Abu Hamadah',
    },
    {
      city_id: 10917,
      region_id: 4,
      name_ar: 'شقران الحاجر',
      name_en: 'Ash Shuqran Al Hajr',
    },
    {
      city_id: 10919,
      region_id: 4,
      name_ar: 'الرمثية',
      name_en: 'Al Ramthiyyah',
    },
    {
      city_id: 10934,
      region_id: 4,
      name_ar: 'ام سلم',
      name_en: 'Umm Salam',
    },
    {
      city_id: 10939,
      region_id: 4,
      name_ar: 'الفايزية',
      name_en: 'Al Fayziyah',
    },
    {
      city_id: 10953,
      region_id: 4,
      name_ar: 'فيضة الريشية',
      name_en: 'Faydat Al Resheyah',
    },
    {
      city_id: 11040,
      region_id: 5,
      name_ar: 'هجرة فضيلة',
      name_en: 'Hijrat Fudailah',
    },
    {
      city_id: 11046,
      region_id: 5,
      name_ar: 'السالمية',
      name_en: 'As Salmiyah',
    },
    {
      city_id: 11050,
      region_id: 5,
      name_ar: 'مدينة الملك خالد العسكرية',
      name_en: 'King Khalid Military City',
    },
    {
      city_id: 11051,
      region_id: 5,
      name_ar: 'ابو طوطاحه البرازي',
      name_en: 'Abu Tutahah Al Barazi',
    },
    {
      city_id: 11054,
      region_id: 5,
      name_ar: 'الرقعى',
      name_en: "Ar Ruq'i",
    },
    {
      city_id: 11055,
      region_id: 5,
      name_ar: 'الذيبية',
      name_en: 'Adh Dhibiyah',
    },
    {
      city_id: 11061,
      region_id: 5,
      name_ar: 'معرج السوبان',
      name_en: 'Ma’raj Al Suban',
    },
    {
      city_id: 11062,
      region_id: 5,
      name_ar: 'خبيراء',
      name_en: 'Khubaira',
    },
    {
      city_id: 11075,
      region_id: 5,
      name_ar: 'ام الحمام',
      name_en: 'Umm Al Hamam',
    },
    {
      city_id: 11090,
      region_id: 5,
      name_ar: 'تناقيب',
      name_en: 'Tanajib',
    },
    {
      city_id: 11094,
      region_id: 5,
      name_ar: 'غزلان',
      name_en: 'Ghazlan',
    },
    {
      city_id: 11099,
      region_id: 5,
      name_ar: 'الثمامة',
      name_en: 'Al Thumamah',
    },
    {
      city_id: 11113,
      region_id: 5,
      name_ar: 'الجوية',
      name_en: 'Al Jawiyah',
    },
    {
      city_id: 11123,
      region_id: 5,
      name_ar: 'دميغ',
      name_en: 'Damigh',
    },
    {
      city_id: 11124,
      region_id: 5,
      name_ar: 'الراجحة',
      name_en: 'Al Rajihah',
    },
    {
      city_id: 11127,
      region_id: 5,
      name_ar: 'الفردانية',
      name_en: 'Al Fardaniyah',
    },
    {
      city_id: 11128,
      region_id: 5,
      name_ar: 'خور الذيابة',
      name_en: 'Khawr Al Dhiyabah',
    },
    {
      city_id: 11129,
      region_id: 5,
      name_ar: 'الجيشية',
      name_en: 'Al Jaishiyah',
    },
    {
      city_id: 11130,
      region_id: 5,
      name_ar: 'انقير',
      name_en: 'Annaqeer',
    },
    {
      city_id: 11151,
      region_id: 5,
      name_ar: 'مليحة',
      name_en: 'Mulayhah',
    },
    {
      city_id: 11192,
      region_id: 6,
      name_ar: 'المسارة',
      name_en: 'Al Masarah',
    },
    {
      city_id: 11193,
      region_id: 6,
      name_ar: 'البادي',
      name_en: 'Al Badi',
    },
    {
      city_id: 11194,
      region_id: 6,
      name_ar: 'السحراء',
      name_en: 'Al Sahraa',
    },
    {
      city_id: 11198,
      region_id: 6,
      name_ar: 'عين بن مصافح',
      name_en: "'Ayn  Bin Musafih",
    },
    {
      city_id: 11215,
      region_id: 6,
      name_ar: 'خيمة',
      name_en: 'Khaymah',
    },
    {
      city_id: 11222,
      region_id: 6,
      name_ar: 'سر آل سرحي',
      name_en: 'Sir Al Sirhi',
    },
    {
      city_id: 11223,
      region_id: 6,
      name_ar: 'الشبارقة',
      name_en: 'Al Shabariqah',
    },
    {
      city_id: 11224,
      region_id: 6,
      name_ar: 'ال مجثل',
      name_en: 'Al Mijthil',
    },
    {
      city_id: 11225,
      region_id: 6,
      name_ar: 'سر الشبارقة',
      name_en: 'Sir Al Shabariqah',
    },
    {
      city_id: 11227,
      region_id: 6,
      name_ar: 'آل العلاء ولجوة وسر البدلة',
      name_en: 'Al Al Aalaa & Lajwah & Sir Al Badlah',
    },
    {
      city_id: 11228,
      region_id: 6,
      name_ar: 'ال يزيديين',
      name_en: 'Al Yazidiyyin',
    },
    {
      city_id: 11229,
      region_id: 6,
      name_ar: 'المخض',
      name_en: 'Al Makhad',
    },
    {
      city_id: 11231,
      region_id: 6,
      name_ar: 'الملحاء',
      name_en: 'Al Malhaa',
    },
    {
      city_id: 11232,
      region_id: 6,
      name_ar: 'الملصة',
      name_en: 'Al Malasah',
    },
    {
      city_id: 11238,
      region_id: 6,
      name_ar: 'العرق (طرف العرق)',
      name_en: 'Al Irq (Taraf Al Irq)',
    },
    {
      city_id: 11239,
      region_id: 6,
      name_ar: 'الأقواز',
      name_en: 'Al Aqwaz',
    },
    {
      city_id: 11240,
      region_id: 6,
      name_ar: 'الباطنة',
      name_en: 'Al Batinah',
    },
    {
      city_id: 11241,
      region_id: 6,
      name_ar: 'آل أم سعيد',
      name_en: 'Al Umm Said',
    },
    {
      city_id: 11295,
      region_id: 6,
      name_ar: 'شرف العوص',
      name_en: 'Sharaf Al Aws',
    },
    {
      city_id: 11296,
      region_id: 6,
      name_ar: 'المشرق وحربة والمعجز',
      name_en: 'Al Mashriq & Harbah & Al Majiz',
    },
    {
      city_id: 11297,
      region_id: 6,
      name_ar: 'عين الجوفاء',
      name_en: 'Ayn Al Jawfaa',
    },
    {
      city_id: 11298,
      region_id: 6,
      name_ar: 'محصان والداخلة',
      name_en: 'Mahsan & Al Dakhilah',
    },
    {
      city_id: 11300,
      region_id: 6,
      name_ar: 'راس السر والجامة',
      name_en: 'Ras Al Sir & Al Jamah',
    },
    {
      city_id: 11301,
      region_id: 6,
      name_ar: 'السوق وبارك الجهارية',
      name_en: 'Al Suq & Barik Al Jahariyyah',
    },
    {
      city_id: 11302,
      region_id: 6,
      name_ar: 'الروح والصعيد',
      name_en: 'Al Ruh & Al Said',
    },
    {
      city_id: 11304,
      region_id: 6,
      name_ar: 'مقهب السقا',
      name_en: 'Maqhab Al Saqa',
    },
    {
      city_id: 11305,
      region_id: 6,
      name_ar: 'السقا',
      name_en: 'Al Saqa',
    },
    {
      city_id: 11308,
      region_id: 6,
      name_ar: 'المسراب والفرع',
      name_en: 'Al Misrab & Al Fara',
    },
    {
      city_id: 11309,
      region_id: 6,
      name_ar: 'قعوه السقا',
      name_en: 'Qawah Al Saqqa',
    },
    {
      city_id: 11310,
      region_id: 6,
      name_ar: 'شط الخضراء',
      name_en: 'Shat Al Khadraa',
    },
    {
      city_id: 11311,
      region_id: 6,
      name_ar: 'الحبوة',
      name_en: 'Al Habwah',
    },
    {
      city_id: 11312,
      region_id: 6,
      name_ar: 'المقضي وآل اسماعيل',
      name_en: 'Al Maqdi & Al Ismail',
    },
    {
      city_id: 11314,
      region_id: 6,
      name_ar: 'قرية شيخة السياحية',
      name_en: 'Qaryat Shaikhah Al Siyahiyyah',
    },
    {
      city_id: 11315,
      region_id: 6,
      name_ar: 'سر البدلة والحبوة',
      name_en: 'Siar Al Badlah & Al Habwah',
    },
    {
      city_id: 11318,
      region_id: 6,
      name_ar: 'الشط الاعلى والاسفل',
      name_en: 'Al Shat Al Aala & Al Asfal',
    },
    {
      city_id: 11320,
      region_id: 6,
      name_ar: 'ال مرزن',
      name_en: 'Al Marzan',
    },
    {
      city_id: 11321,
      region_id: 6,
      name_ar: 'الحوزة والخارجة',
      name_en: 'Al Hawzah & Al Kharijah',
    },
    {
      city_id: 11322,
      region_id: 6,
      name_ar: 'الضحية (العزيزة)',
      name_en: 'Al Dahiyyah (Al Azizah)',
    },
    {
      city_id: 11323,
      region_id: 6,
      name_ar: 'المقافي',
      name_en: 'Al Maqafi',
    },
    {
      city_id: 11324,
      region_id: 6,
      name_ar: 'القدة ( ال تمام)',
      name_en: 'Al Qaddah (Al Tamam)',
    },
    {
      city_id: 11326,
      region_id: 6,
      name_ar: 'قدة ال امشيبة',
      name_en: 'Qaddat Al Amshiyah',
    },
    {
      city_id: 11327,
      region_id: 6,
      name_ar: 'قدة ال ابو فايدة',
      name_en: 'Qaddat Al Abu Faydah',
    },
    {
      city_id: 11330,
      region_id: 6,
      name_ar: 'ال سكران',
      name_en: 'Al Sakran',
    },
    {
      city_id: 11331,
      region_id: 6,
      name_ar: 'راس الفية',
      name_en: 'Ras Al Fiyyah',
    },
    {
      city_id: 11332,
      region_id: 6,
      name_ar: 'ذنب السر',
      name_en: 'Dhanab Al Sar',
    },
    {
      city_id: 11333,
      region_id: 6,
      name_ar: 'ال يتيم',
      name_en: 'Al Yatim',
    },
    {
      city_id: 11334,
      region_id: 6,
      name_ar: 'ذنب القائمة',
      name_en: 'Dhanab Al Qaimah',
    },
    {
      city_id: 11335,
      region_id: 6,
      name_ar: 'راعية البيبان',
      name_en: 'Rayat Al Boban',
    },
    {
      city_id: 11336,
      region_id: 6,
      name_ar: 'المجاز',
      name_en: 'Al Majaz',
    },
    {
      city_id: 11337,
      region_id: 6,
      name_ar: 'رهمه المهلل',
      name_en: 'Rahmah Al Muhalhal',
    },
    {
      city_id: 11338,
      region_id: 6,
      name_ar: 'النغره والمرازه',
      name_en: 'Al Naghrah & Al Marzah',
    },
    {
      city_id: 11339,
      region_id: 6,
      name_ar: 'البوخه',
      name_en: 'Al Bukhah',
    },
    {
      city_id: 11340,
      region_id: 6,
      name_ar: 'قعوة ال فرحان والعثراء',
      name_en: 'Qaawat Al Farhan & Al Athraa',
    },
    {
      city_id: 11341,
      region_id: 6,
      name_ar: 'سر آل عبيد والسقايف',
      name_en: 'Sar Al Ubaid & Al Saqayif',
    },
    {
      city_id: 11342,
      region_id: 6,
      name_ar: 'الشارقة',
      name_en: 'Al Shariqah',
    },
    {
      city_id: 11343,
      region_id: 6,
      name_ar: 'آل محرق',
      name_en: 'Al Mahraq',
    },
    {
      city_id: 11344,
      region_id: 6,
      name_ar: 'جال ال عامر',
      name_en: 'Jal Al Amir',
    },
    {
      city_id: 11345,
      region_id: 6,
      name_ar: 'ال امحاج',
      name_en: 'Al Imhaj',
    },
    {
      city_id: 11346,
      region_id: 6,
      name_ar: 'ال القبيعي',
      name_en: 'Al Al Qabyai',
    },
    {
      city_id: 11347,
      region_id: 6,
      name_ar: 'حقبهة ريدة',
      name_en: 'Haqbat Raidah',
    },
    {
      city_id: 11348,
      region_id: 6,
      name_ar: 'جديان',
      name_en: 'Jadyan',
    },
    {
      city_id: 11350,
      region_id: 6,
      name_ar: 'وادي الظهار',
      name_en: 'Wadi Al Dhahar',
    },
    {
      city_id: 11353,
      region_id: 6,
      name_ar: 'مسلمة وشط خبيب',
      name_en: 'Maslamah & Shat Khabib',
    },
    {
      city_id: 11354,
      region_id: 6,
      name_ar: 'حبيل ال يسعد',
      name_en: 'Hubail Al Yasad',
    },
    {
      city_id: 11355,
      region_id: 6,
      name_ar: 'عرفة ام رقو',
      name_en: 'Arafah Umm Raqu',
    },
    {
      city_id: 11357,
      region_id: 6,
      name_ar: 'الفطيحة',
      name_en: 'Al Fatihah',
    },
    {
      city_id: 11358,
      region_id: 6,
      name_ar: 'الحدباء',
      name_en: 'Al Hadbaa',
    },
    {
      city_id: 11359,
      region_id: 6,
      name_ar: 'الدوخة',
      name_en: 'Al Dawkhah',
    },
    {
      city_id: 11360,
      region_id: 6,
      name_ar: 'الغمرة',
      name_en: 'Al Ghamrah',
    },
    {
      city_id: 11361,
      region_id: 6,
      name_ar: 'الخلفاء',
      name_en: 'Al Khalfaa',
    },
    {
      city_id: 11362,
      region_id: 6,
      name_ar: 'الحاجرة',
      name_en: 'Al Hajirah',
    },
    {
      city_id: 11363,
      region_id: 6,
      name_ar: 'ال مجاهر والجامة',
      name_en: 'Al Mujahid & Al Jamah',
    },
    {
      city_id: 11365,
      region_id: 6,
      name_ar: 'قورة',
      name_en: 'Qawrah',
    },
    {
      city_id: 11366,
      region_id: 6,
      name_ar: 'موجمة',
      name_en: 'Mawjamah',
    },
    {
      city_id: 11367,
      region_id: 6,
      name_ar: 'ليتوي',
      name_en: 'Litwi',
    },
    {
      city_id: 11368,
      region_id: 6,
      name_ar: 'وادي حبو والطرقة',
      name_en: 'Wadi Habu & Al Tarafah',
    },
    {
      city_id: 11369,
      region_id: 6,
      name_ar: 'المحرث',
      name_en: 'Al Mahrath',
    },
    {
      city_id: 11370,
      region_id: 6,
      name_ar: 'محورو',
      name_en: 'Am Huru',
    },
    {
      city_id: 11372,
      region_id: 6,
      name_ar: 'الرايه وذنب الراية',
      name_en: 'Al Rayah & Dhanab Al Rayah',
    },
    {
      city_id: 11373,
      region_id: 6,
      name_ar: 'مسقام والنوابي',
      name_en: 'Masqam & Al Nawabi',
    },
    {
      city_id: 11374,
      region_id: 6,
      name_ar: 'الغمدة',
      name_en: 'Al Ghamdah',
    },
    {
      city_id: 11375,
      region_id: 6,
      name_ar: 'ذنب احمرين والسرب',
      name_en: 'Dhnab Ahmarain & Al Sarb',
    },
    {
      city_id: 11376,
      region_id: 6,
      name_ar: 'القضى',
      name_en: 'Al Qada',
    },
    {
      city_id: 11377,
      region_id: 6,
      name_ar: 'الحرملة',
      name_en: 'Al Harmalah',
    },
    {
      city_id: 11378,
      region_id: 6,
      name_ar: 'حبيل ال تمام',
      name_en: 'Hubail Al Tamam',
    },
    {
      city_id: 11379,
      region_id: 6,
      name_ar: 'حبيل محزاة ال تمام',
      name_en: 'Hubail Mahzat Al Tamam',
    },
    {
      city_id: 11380,
      region_id: 6,
      name_ar: 'عنقة ال محاج',
      name_en: 'Anqat Al Mahaj',
    },
    {
      city_id: 11381,
      region_id: 6,
      name_ar: 'لثبة',
      name_en: 'Lathbah',
    },
    {
      city_id: 11389,
      region_id: 6,
      name_ar: 'الجهايف (آل مبرة)',
      name_en: 'Al Jahayif (Al Mabarrah)',
    },
    {
      city_id: 11565,
      region_id: 6,
      name_ar: 'مندرمربة',
      name_en: 'Mandir Marabah',
    },
    {
      city_id: 11641,
      region_id: 6,
      name_ar: 'مصاولي',
      name_en: 'Masawly',
    },
    {
      city_id: 11645,
      region_id: 6,
      name_ar: 'تيهان',
      name_en: 'Tihan',
    },
    {
      city_id: 11899,
      region_id: 6,
      name_ar: 'صدر وائلة (المجمع)',
      name_en: 'Sadr Wailah (Al Majma)',
    },
    {
      city_id: 11900,
      region_id: 6,
      name_ar: 'رثمة وائلة',
      name_en: 'Rathmat Wailah',
    },
    {
      city_id: 12005,
      region_id: 6,
      name_ar: 'روضة بن نورة',
      name_en: 'Rawdat Bin Nourah',
    },
    {
      city_id: 12199,
      region_id: 6,
      name_ar: 'مخطط ال شبوه',
      name_en: 'Al Shabwah Subdivision',
    },
    {
      city_id: 12351,
      region_id: 6,
      name_ar: 'الشقيريه',
      name_en: 'Al Shuqayriyah',
    },
    {
      city_id: 12700,
      region_id: 6,
      name_ar: 'آل الشيخين وآل جرار',
      name_en: 'Al Al Shaikhain & Al Jarar',
    },
    {
      city_id: 12701,
      region_id: 6,
      name_ar: 'نمران',
      name_en: 'Namran',
    },
    {
      city_id: 12702,
      region_id: 6,
      name_ar: 'ال الصناع',
      name_en: 'Al Al Sunnaa',
    },
    {
      city_id: 12703,
      region_id: 6,
      name_ar: 'الرسلية',
      name_en: 'Al Rasilah',
    },
    {
      city_id: 12704,
      region_id: 6,
      name_ar: 'ال غزالة',
      name_en: 'Al Ghazalah',
    },
    {
      city_id: 12705,
      region_id: 6,
      name_ar: 'ال شيبان',
      name_en: 'Al Shiban',
    },
    {
      city_id: 12706,
      region_id: 6,
      name_ar: 'فارعة',
      name_en: 'Farah',
    },
    {
      city_id: 12707,
      region_id: 6,
      name_ar: 'مشنية وال بسام',
      name_en: 'Mashniyyah & Al Bassam',
    },
    {
      city_id: 12708,
      region_id: 6,
      name_ar: 'ال السحيم',
      name_en: 'Al Al Sahim',
    },
    {
      city_id: 12709,
      region_id: 6,
      name_ar: 'الجعادبة',
      name_en: 'Al Jaadibah',
    },
    {
      city_id: 12711,
      region_id: 6,
      name_ar: 'الصفح الاجرد',
      name_en: 'Al Safh Al Ajrad',
    },
    {
      city_id: 12713,
      region_id: 6,
      name_ar: 'القريضة',
      name_en: 'Al Quraydah',
    },
    {
      city_id: 12715,
      region_id: 6,
      name_ar: 'النقبة',
      name_en: 'Al Naqbah',
    },
    {
      city_id: 12716,
      region_id: 6,
      name_ar: 'الطوف',
      name_en: 'Al Tawf',
    },
    {
      city_id: 12717,
      region_id: 6,
      name_ar: 'ال طلحة',
      name_en: 'Al Talhah',
    },
    {
      city_id: 12718,
      region_id: 6,
      name_ar: 'ال سملة',
      name_en: 'Al Samalah',
    },
    {
      city_id: 12719,
      region_id: 6,
      name_ar: 'ال مفرج',
      name_en: 'Al Mufrih',
    },
    {
      city_id: 12720,
      region_id: 6,
      name_ar: 'ال روضان',
      name_en: 'Al Rawdan',
    },
    {
      city_id: 12721,
      region_id: 6,
      name_ar: 'ذات العلب',
      name_en: 'Dhat Al Ulab',
    },
    {
      city_id: 12722,
      region_id: 6,
      name_ar: 'ال غثران والخيشة',
      name_en: 'Al Ghathran & Al Al Khishah',
    },
    {
      city_id: 12723,
      region_id: 6,
      name_ar: 'الرزة',
      name_en: 'Al Razzah',
    },
    {
      city_id: 12724,
      region_id: 6,
      name_ar: 'حبقة',
      name_en: 'Habqah',
    },
    {
      city_id: 12725,
      region_id: 6,
      name_ar: 'الشعبة والمندي والمنزل (ال حسيكة)',
      name_en: 'Al Shuabah & Al Mandi & Al Manzil (Al Husaykah)',
    },
    {
      city_id: 12726,
      region_id: 6,
      name_ar: 'ال حطام',
      name_en: 'Al Hatam',
    },
    {
      city_id: 12728,
      region_id: 6,
      name_ar: 'ال الشاعر',
      name_en: 'Al Al Shair',
    },
    {
      city_id: 12729,
      region_id: 6,
      name_ar: 'ال لهيفة',
      name_en: 'Al Lahifah',
    },
    {
      city_id: 12730,
      region_id: 6,
      name_ar: 'ال عطيفة',
      name_en: 'Al Atifah',
    },
    {
      city_id: 12731,
      region_id: 6,
      name_ar: 'ال محفوظ',
      name_en: 'Al Mahfudh',
    },
    {
      city_id: 12732,
      region_id: 6,
      name_ar: 'ال الاصفاء',
      name_en: 'Al Al Asfaa',
    },
    {
      city_id: 12733,
      region_id: 6,
      name_ar: 'ال جعيد',
      name_en: 'Al Juaiddah',
    },
    {
      city_id: 12734,
      region_id: 6,
      name_ar: 'ساقين',
      name_en: 'Saqain',
    },
    {
      city_id: 12738,
      region_id: 6,
      name_ar: 'رهو المركب',
      name_en: 'Rahu Al Markib',
    },
    {
      city_id: 12739,
      region_id: 6,
      name_ar: 'المربعة',
      name_en: 'Al Marbaah',
    },
    {
      city_id: 12740,
      region_id: 6,
      name_ar: 'المروة',
      name_en: 'Al Marwah',
    },
    {
      city_id: 12741,
      region_id: 6,
      name_ar: 'رهو الخرق',
      name_en: 'Rahu Al Kharq',
    },
    {
      city_id: 12743,
      region_id: 6,
      name_ar: 'المنقلع',
      name_en: 'Al Munqala',
    },
    {
      city_id: 12744,
      region_id: 6,
      name_ar: 'خارف',
      name_en: 'Kharif',
    },
    {
      city_id: 12747,
      region_id: 6,
      name_ar: 'الحينة',
      name_en: 'Al Hinah',
    },
    {
      city_id: 12748,
      region_id: 6,
      name_ar: 'الاثبات',
      name_en: 'Al Athbat',
    },
    {
      city_id: 12749,
      region_id: 6,
      name_ar: 'المليبات',
      name_en: 'Al Malibat',
    },
    {
      city_id: 12753,
      region_id: 6,
      name_ar: 'المحظر',
      name_en: 'Al Mahdhar',
    },
    {
      city_id: 12755,
      region_id: 6,
      name_ar: 'الامرح',
      name_en: 'Al Amrah',
    },
    {
      city_id: 12756,
      region_id: 6,
      name_ar: 'ال صره',
      name_en: 'Al Surrah',
    },
    {
      city_id: 12758,
      region_id: 6,
      name_ar: 'لفت',
      name_en: 'Lafat',
    },
    {
      city_id: 12759,
      region_id: 6,
      name_ar: 'ال جاهل ( ال عارف )',
      name_en: 'Al Jahil (Al Arif)',
    },
    {
      city_id: 12760,
      region_id: 6,
      name_ar: 'الجحور',
      name_en: 'Al Juhur',
    },
    {
      city_id: 12761,
      region_id: 6,
      name_ar: 'القرن وال عمار وال شميلة',
      name_en: 'Al Qarn & Al Ammar & Al Shamilah',
    },
    {
      city_id: 12762,
      region_id: 6,
      name_ar: 'ذي عتيم',
      name_en: 'Dhi Atim',
    },
    {
      city_id: 12763,
      region_id: 6,
      name_ar: 'ال الطبق',
      name_en: 'Al Al Tabaq',
    },
    {
      city_id: 12764,
      region_id: 6,
      name_ar: 'ال ساحة والنجفة',
      name_en: 'Al Sahah & Al Najfah',
    },
    {
      city_id: 12765,
      region_id: 6,
      name_ar: 'الفرشة',
      name_en: 'Al Farshah',
    },
    {
      city_id: 12766,
      region_id: 6,
      name_ar: 'ال حلة (لتيدة)',
      name_en: 'Al Hallah (Al Tidah)',
    },
    {
      city_id: 12767,
      region_id: 6,
      name_ar: 'ال خزيم',
      name_en: 'Al Khuzaym',
    },
    {
      city_id: 12768,
      region_id: 6,
      name_ar: 'وادي العين وال سلطان',
      name_en: 'Wadi Al Ayn & Al Sultan',
    },
    {
      city_id: 12769,
      region_id: 6,
      name_ar: 'ال خريم',
      name_en: 'Al Khuraim',
    },
    {
      city_id: 12770,
      region_id: 6,
      name_ar: 'ال سودان والقرن',
      name_en: 'Al Sudan & Al Qarn',
    },
    {
      city_id: 12771,
      region_id: 6,
      name_ar: 'الفرعة',
      name_en: 'Al Faah',
    },
    {
      city_id: 12772,
      region_id: 6,
      name_ar: 'حلة الفقير',
      name_en: 'Hallat Al Faqir',
    },
    {
      city_id: 12774,
      region_id: 6,
      name_ar: 'حلة شعبة الجمل',
      name_en: 'Hallat Shuabat Al Jamal',
    },
    {
      city_id: 12775,
      region_id: 6,
      name_ar: 'حلة شعبة السدرة',
      name_en: 'Hallat Shuabat Al Sidrah',
    },
    {
      city_id: 12776,
      region_id: 6,
      name_ar: 'محلة وضاعة',
      name_en: 'Mahallat Wadaah',
    },
    {
      city_id: 12777,
      region_id: 6,
      name_ar: 'الوفيزة (حلة شرفية)',
      name_en: 'Al Wafizah (Hallat Sharafiyyah)',
    },
    {
      city_id: 12778,
      region_id: 6,
      name_ar: 'الولية (حلة شرفية)',
      name_en: 'Al Waliyyah (Hallat Sharafiyyah)',
    },
    {
      city_id: 12779,
      region_id: 6,
      name_ar: 'عكنان (حلة شرفية)',
      name_en: 'Aknan (Hallat Sharafiyyah)',
    },
    {
      city_id: 12780,
      region_id: 6,
      name_ar: 'بومدر (حلة شرفية)',
      name_en: 'Bumadar (Hallat Sharafiyyah)',
    },
    {
      city_id: 12781,
      region_id: 6,
      name_ar: 'شبارق (حلة شرفية)',
      name_en: 'Shabariq (Hallat Sharafiyyah)',
    },
    {
      city_id: 12782,
      region_id: 6,
      name_ar: 'الهداة (حلة شرفية)',
      name_en: 'Al Hadah (Hallat Sharafiyyah)',
    },
    {
      city_id: 12783,
      region_id: 6,
      name_ar: 'الموبل (حلة شرفية)',
      name_en: 'Al Mawbil (Hallat Sharafiyyah)',
    },
    {
      city_id: 12784,
      region_id: 6,
      name_ar: 'جرادة حلة شرفية)',
      name_en: 'Jaradah (Hallat Sharafiyyah)',
    },
    {
      city_id: 12785,
      region_id: 6,
      name_ar: 'حظا (حلة شرفية)',
      name_en: 'Hadha (Hallat Sharafiyyah)',
    },
    {
      city_id: 12834,
      region_id: 6,
      name_ar: 'شعب اخيم',
      name_en: 'Shiab Akhim',
    },
    {
      city_id: 12892,
      region_id: 6,
      name_ar: 'حبيل الامير',
      name_en: 'Hubail Al Amir',
    },
    {
      city_id: 12914,
      region_id: 6,
      name_ar: 'القاد الاعلى',
      name_en: 'Al Qad Al Aala',
    },
    {
      city_id: 12915,
      region_id: 6,
      name_ar: 'المصبح',
      name_en: 'Al Musbah',
    },
    {
      city_id: 12919,
      region_id: 6,
      name_ar: 'ترقش',
      name_en: 'Turqesh',
    },
    {
      city_id: 13050,
      region_id: 6,
      name_ar: 'قرن هادي',
      name_en: 'Qarn Hadi',
    },
    {
      city_id: 13660,
      region_id: 6,
      name_ar: 'المليحة',
      name_en: 'Al Milayhah',
    },
    {
      city_id: 13867,
      region_id: 6,
      name_ar: 'زهرة بني بشر',
      name_en: 'Zahrah Bani Bishr',
    },
    {
      city_id: 13989,
      region_id: 6,
      name_ar: 'آل قبره والقوزيه',
      name_en: 'Al Qibrah Al Quoziyah',
    },
    {
      city_id: 14136,
      region_id: 6,
      name_ar: 'لقع',
      name_en: 'Laqa',
    },
    {
      city_id: 14137,
      region_id: 6,
      name_ar: 'الاصدار',
      name_en: 'Al Asdar',
    },
    {
      city_id: 14138,
      region_id: 6,
      name_ar: 'ذات مساك',
      name_en: 'Dhat Masak',
    },
    {
      city_id: 14139,
      region_id: 6,
      name_ar: 'ظهرة ال صالح',
      name_en: 'Dhahrat Al Salih',
    },
    {
      city_id: 14141,
      region_id: 6,
      name_ar: 'ال خيرة',
      name_en: 'Al Khayrah',
    },
    {
      city_id: 14143,
      region_id: 6,
      name_ar: 'الشقة',
      name_en: 'Al Shaqqah',
    },
    {
      city_id: 14147,
      region_id: 6,
      name_ar: 'وسالع',
      name_en: 'Wasalya',
    },
    {
      city_id: 14148,
      region_id: 6,
      name_ar: 'محجان',
      name_en: 'Mahjan',
    },
    {
      city_id: 14150,
      region_id: 6,
      name_ar: 'مندر العوص',
      name_en: 'Mandar Al Us',
    },
    {
      city_id: 14151,
      region_id: 6,
      name_ar: 'ضاحية',
      name_en: 'Sahiyah',
    },
    {
      city_id: 14152,
      region_id: 6,
      name_ar: 'حبيل الاحلاج',
      name_en: 'Hubail Al Ahlaj',
    },
    {
      city_id: 14153,
      region_id: 6,
      name_ar: 'ظهرة المصدمة',
      name_en: 'Dhahrat Al Masdamah',
    },
    {
      city_id: 14154,
      region_id: 6,
      name_ar: 'الحقو',
      name_en: 'Al Haqu',
    },
    {
      city_id: 14155,
      region_id: 6,
      name_ar: 'جزعة العوص',
      name_en: 'Jazaat Al Us',
    },
    {
      city_id: 14156,
      region_id: 6,
      name_ar: 'غنمة',
      name_en: 'Ghanamah',
    },
    {
      city_id: 14157,
      region_id: 6,
      name_ar: 'ال مشابع',
      name_en: 'Al Mashaya',
    },
    {
      city_id: 14158,
      region_id: 6,
      name_ar: 'ال مبيوع',
      name_en: 'Al Mabyu',
    },
    {
      city_id: 14159,
      region_id: 6,
      name_ar: 'عثالف',
      name_en: 'Athalif',
    },
    {
      city_id: 14160,
      region_id: 6,
      name_ar: 'قوة',
      name_en: 'Quwwah',
    },
    {
      city_id: 14163,
      region_id: 6,
      name_ar: 'العثربة',
      name_en: 'Al Athriyyah',
    },
    {
      city_id: 14164,
      region_id: 6,
      name_ar: 'ثعابة',
      name_en: 'Thaabah',
    },
    {
      city_id: 14165,
      region_id: 6,
      name_ar: 'ظهر النواشرة',
      name_en: 'Dhahr Al Nawashirah',
    },
    {
      city_id: 14166,
      region_id: 6,
      name_ar: 'عمقة (ال علايم)',
      name_en: 'Aqmah (Al Alayim)',
    },
    {
      city_id: 14167,
      region_id: 6,
      name_ar: 'الممر',
      name_en: 'Al Mamar',
    },
    {
      city_id: 14169,
      region_id: 6,
      name_ar: 'لحج بن زافلة',
      name_en: 'Lahaj Ibn Zafilah',
    },
    {
      city_id: 14170,
      region_id: 6,
      name_ar: 'وادي ظرافة',
      name_en: 'Wadi Dharafah',
    },
    {
      city_id: 14171,
      region_id: 6,
      name_ar: 'يدامان',
      name_en: 'Yadaman',
    },
    {
      city_id: 14174,
      region_id: 6,
      name_ar: 'وادي كريم',
      name_en: 'Wadi Karim',
    },
    {
      city_id: 14175,
      region_id: 6,
      name_ar: 'وطن الرديف',
      name_en: 'Watan Al Radif',
    },
    {
      city_id: 14177,
      region_id: 6,
      name_ar: 'ثفقي',
      name_en: 'Thaqfi',
    },
    {
      city_id: 14179,
      region_id: 6,
      name_ar: 'الذرة',
      name_en: 'Al Dhurah',
    },
    {
      city_id: 14180,
      region_id: 6,
      name_ar: 'خلف مجوف',
      name_en: 'Khalaf Majuf',
    },
    {
      city_id: 14185,
      region_id: 6,
      name_ar: 'سمنان',
      name_en: 'Samnan',
    },
    {
      city_id: 14186,
      region_id: 6,
      name_ar: 'الجوز الاسفل',
      name_en: 'Al Jawz Al Asfal',
    },
    {
      city_id: 14187,
      region_id: 6,
      name_ar: 'الجوز الاعلى',
      name_en: 'Al Jawz Al Aala',
    },
    {
      city_id: 14188,
      region_id: 6,
      name_ar: 'وجه العرق',
      name_en: 'Wajh Al Irq',
    },
    {
      city_id: 14190,
      region_id: 6,
      name_ar: 'الصعداي',
      name_en: 'Al Saday',
    },
    {
      city_id: 14191,
      region_id: 6,
      name_ar: 'الشافية',
      name_en: 'Al Shaqibah',
    },
    {
      city_id: 14192,
      region_id: 6,
      name_ar: 'الخو',
      name_en: 'Al Khaw',
    },
    {
      city_id: 14193,
      region_id: 6,
      name_ar: 'المعقم',
      name_en: 'Al Maqam',
    },
    {
      city_id: 14194,
      region_id: 6,
      name_ar: 'ذات الحلي',
      name_en: 'Dhat Al Hali',
    },
    {
      city_id: 14195,
      region_id: 6,
      name_ar: 'ظهرة ال بريد',
      name_en: 'Dhahrat Al Barid',
    },
    {
      city_id: 14196,
      region_id: 6,
      name_ar: 'معينة',
      name_en: 'Mainah',
    },
    {
      city_id: 14197,
      region_id: 6,
      name_ar: 'ملتقى الصدرين',
      name_en: 'Multaqa Al Sadrain',
    },
    {
      city_id: 14198,
      region_id: 6,
      name_ar: 'ظهران',
      name_en: 'Dhahran',
    },
    {
      city_id: 14199,
      region_id: 6,
      name_ar: 'معتق ال هبة',
      name_en: 'Mataq Al Hibah',
    },
    {
      city_id: 14200,
      region_id: 6,
      name_ar: 'الجرف القديم',
      name_en: 'Al Jurf Al Qadim',
    },
    {
      city_id: 14201,
      region_id: 6,
      name_ar: 'عرضة',
      name_en: 'Ardah',
    },
    {
      city_id: 14202,
      region_id: 6,
      name_ar: 'الجرف الجديد (منقش)',
      name_en: 'Al Jurf Al Jadid (Manwash)',
    },
    {
      city_id: 14203,
      region_id: 6,
      name_ar: 'ظاهر القماش',
      name_en: 'Dhahir Al Qammash',
    },
    {
      city_id: 14204,
      region_id: 6,
      name_ar: 'اسفل فو',
      name_en: 'Asfal Faw',
    },
    {
      city_id: 14206,
      region_id: 6,
      name_ar: 'طوقن',
      name_en: 'Tawqan',
    },
    {
      city_id: 14207,
      region_id: 6,
      name_ar: 'القحام',
      name_en: 'Al Qaham',
    },
    {
      city_id: 14208,
      region_id: 6,
      name_ar: 'الرهي',
      name_en: 'Al Raha',
    },
    {
      city_id: 14210,
      region_id: 6,
      name_ar: 'المعاملات',
      name_en: 'Al Muamalat',
    },
    {
      city_id: 14211,
      region_id: 6,
      name_ar: 'ميمن',
      name_en: 'Mayman',
    },
    {
      city_id: 14213,
      region_id: 6,
      name_ar: 'ظهرة ال مهدي',
      name_en: 'Dhahrat Al Mahdi',
    },
    {
      city_id: 14214,
      region_id: 6,
      name_ar: 'ظهرة ال حسن',
      name_en: 'Dhahrat Al Hassan',
    },
    {
      city_id: 14216,
      region_id: 6,
      name_ar: 'بتيلة ال مداوي',
      name_en: 'Batilah Al Mudawi',
    },
    {
      city_id: 14217,
      region_id: 6,
      name_ar: 'جزعة الرقاق',
      name_en: 'Jazah Al Riqaq',
    },
    {
      city_id: 14218,
      region_id: 6,
      name_ar: 'لبخ',
      name_en: 'Labakh',
    },
    {
      city_id: 14219,
      region_id: 6,
      name_ar: 'جزعة المشط',
      name_en: 'Jazat Al Masht',
    },
    {
      city_id: 14220,
      region_id: 6,
      name_ar: 'وطن عانين',
      name_en: 'Watan Anin',
    },
    {
      city_id: 14223,
      region_id: 6,
      name_ar: 'شط المعد',
      name_en: 'Shat Al Maad',
    },
    {
      city_id: 14224,
      region_id: 6,
      name_ar: 'الرقعة',
      name_en: 'Al Raqah',
    },
    {
      city_id: 14225,
      region_id: 6,
      name_ar: 'جابو',
      name_en: 'Jabu',
    },
    {
      city_id: 14226,
      region_id: 6,
      name_ar: 'دريما',
      name_en: 'Darima',
    },
    {
      city_id: 14228,
      region_id: 6,
      name_ar: 'صران',
      name_en: 'Sarran',
    },
    {
      city_id: 14229,
      region_id: 6,
      name_ar: 'ظهرة سنومة',
      name_en: 'Dhahrat Sannumah',
    },
    {
      city_id: 14232,
      region_id: 6,
      name_ar: 'جرف السلع',
      name_en: 'Jarf Al Salaa',
    },
    {
      city_id: 14233,
      region_id: 6,
      name_ar: 'الربوع',
      name_en: 'Al Rabua',
    },
    {
      city_id: 14234,
      region_id: 6,
      name_ar: 'بئر ملحة',
      name_en: 'Bir Malhah',
    },
    {
      city_id: 14235,
      region_id: 6,
      name_ar: 'ضبيان',
      name_en: 'Dabyan',
    },
    {
      city_id: 14236,
      region_id: 6,
      name_ar: 'المنافش',
      name_en: 'Al Manafish',
    },
    {
      city_id: 14237,
      region_id: 6,
      name_ar: 'مقحومة',
      name_en: 'Maqhumah',
    },
    {
      city_id: 14238,
      region_id: 6,
      name_ar: 'ضدحاء',
      name_en: 'Dadhaa',
    },
    {
      city_id: 14239,
      region_id: 6,
      name_ar: 'ظهران المشوزة (الخانق)',
      name_en: 'Dhahran Al Mashuzah (Al Khaniq)',
    },
    {
      city_id: 14241,
      region_id: 6,
      name_ar: 'وجه العسف',
      name_en: 'Wajh Al Asaf',
    },
    {
      city_id: 14242,
      region_id: 6,
      name_ar: 'الاحقاب',
      name_en: 'Al Ahqab',
    },
    {
      city_id: 14245,
      region_id: 6,
      name_ar: 'الشرار',
      name_en: 'Al Sharar',
    },
    {
      city_id: 14246,
      region_id: 6,
      name_ar: 'الاذناب',
      name_en: 'Al Adhnab',
    },
    {
      city_id: 14247,
      region_id: 6,
      name_ar: 'الصدعة',
      name_en: 'Al Sadah',
    },
    {
      city_id: 14248,
      region_id: 6,
      name_ar: 'الراتخ',
      name_en: 'Al Ratikh',
    },
    {
      city_id: 14249,
      region_id: 6,
      name_ar: 'مدر',
      name_en: 'Madar',
    },
    {
      city_id: 14250,
      region_id: 6,
      name_ar: 'الكرى (العشة)',
      name_en: 'Al Kara (Al Ashah)',
    },
    {
      city_id: 14251,
      region_id: 6,
      name_ar: 'ضحيان',
      name_en: 'Dahyan',
    },
    {
      city_id: 14253,
      region_id: 6,
      name_ar: 'امصلحاء',
      name_en: 'Amsalhaa',
    },
    {
      city_id: 14254,
      region_id: 6,
      name_ar: 'معتق محالي',
      name_en: 'Mataq Mahali',
    },
    {
      city_id: 14256,
      region_id: 6,
      name_ar: 'سمعي (الجندلة)',
      name_en: 'Sama (Al Jandalah)',
    },
    {
      city_id: 14257,
      region_id: 6,
      name_ar: 'الرجوع',
      name_en: 'Al Rajua',
    },
    {
      city_id: 14259,
      region_id: 6,
      name_ar: 'الحرد',
      name_en: 'Al Hardah',
    },
    {
      city_id: 14261,
      region_id: 6,
      name_ar: 'جزعة وسانب',
      name_en: 'Jazat Wasanib',
    },
    {
      city_id: 14262,
      region_id: 6,
      name_ar: 'باحان',
      name_en: 'Bahan',
    },
    {
      city_id: 14265,
      region_id: 6,
      name_ar: 'ظهرة قهدة',
      name_en: 'Dhahart Qahdah',
    },
    {
      city_id: 14266,
      region_id: 6,
      name_ar: 'الميمن',
      name_en: 'Al Maymanah',
    },
    {
      city_id: 14267,
      region_id: 6,
      name_ar: 'وطن وسانب',
      name_en: 'Watan Wasanib',
    },
    {
      city_id: 14272,
      region_id: 6,
      name_ar: 'الحثران',
      name_en: 'Al Hathran',
    },
    {
      city_id: 14273,
      region_id: 6,
      name_ar: 'الحصياء',
      name_en: 'Al Hasyaa',
    },
    {
      city_id: 14306,
      region_id: 6,
      name_ar: 'نخر الودن الاعلى',
      name_en: 'Nakhr Al Wadan Al Aala',
    },
    {
      city_id: 14378,
      region_id: 6,
      name_ar: 'ذا الترايم',
      name_en: 'Dha Al Tarayim',
    },
    {
      city_id: 14386,
      region_id: 6,
      name_ar: 'الخطل',
      name_en: 'Al Khatl',
    },
    {
      city_id: 14403,
      region_id: 6,
      name_ar: 'وطن ال مضواح',
      name_en: 'Watan Al Midwah',
    },
    {
      city_id: 14404,
      region_id: 6,
      name_ar: 'ضحى اللواء',
      name_en: 'Duha Al Luwaa',
    },
    {
      city_id: 14406,
      region_id: 6,
      name_ar: 'وطن الذروة',
      name_en: 'Watan Al Dharwah',
    },
    {
      city_id: 14407,
      region_id: 6,
      name_ar: 'الموفا',
      name_en: 'Al Mawfa',
    },
    {
      city_id: 14409,
      region_id: 6,
      name_ar: 'الركس (الجن)',
      name_en: 'Al Riks (Al Jin)',
    },
    {
      city_id: 14410,
      region_id: 6,
      name_ar: 'الضجاجة',
      name_en: 'Al Dajajah',
    },
    {
      city_id: 14411,
      region_id: 6,
      name_ar: 'قاعة غمرة',
      name_en: 'Qaat Ghamrah',
    },
    {
      city_id: 14415,
      region_id: 6,
      name_ar: 'المواهب',
      name_en: 'Al Mawahib',
    },
    {
      city_id: 14419,
      region_id: 6,
      name_ar: 'قارية ال قديمة',
      name_en: 'Qarya',
    },
    {
      city_id: 14420,
      region_id: 6,
      name_ar: 'البزخ',
      name_en: 'Al Bazakh',
    },
    {
      city_id: 14421,
      region_id: 6,
      name_ar: 'المجزاع',
      name_en: 'Al Majzaa',
    },
    {
      city_id: 14422,
      region_id: 6,
      name_ar: 'ذي تالق',
      name_en: 'Dhi Taliq',
    },
    {
      city_id: 14423,
      region_id: 6,
      name_ar: 'موياح',
      name_en: 'Muyah',
    },
    {
      city_id: 14424,
      region_id: 6,
      name_ar: 'حرمات',
      name_en: 'Haramat',
    },
    {
      city_id: 14425,
      region_id: 6,
      name_ar: 'السحاية',
      name_en: 'Al Sahayah',
    },
    {
      city_id: 14426,
      region_id: 6,
      name_ar: 'الغزول',
      name_en: 'Al Ghazul',
    },
    {
      city_id: 14427,
      region_id: 6,
      name_ar: 'ذم سنون',
      name_en: 'Dham Sanun',
    },
    {
      city_id: 14429,
      region_id: 6,
      name_ar: 'قارية غمرة',
      name_en: 'Qaryat Ghamrah',
    },
    {
      city_id: 14430,
      region_id: 6,
      name_ar: 'قارية عوال علي',
      name_en: 'Qaryat Awal Ali',
    },
    {
      city_id: 14431,
      region_id: 6,
      name_ar: 'الجنبة',
      name_en: 'Al Janbah',
    },
    {
      city_id: 14434,
      region_id: 6,
      name_ar: 'القفلاء',
      name_en: 'Al Qaflaa',
    },
    {
      city_id: 14435,
      region_id: 6,
      name_ar: 'القعاليل',
      name_en: 'Al Qaalil',
    },
    {
      city_id: 14437,
      region_id: 6,
      name_ar: 'ال حرمات',
      name_en: 'Al Haramat',
    },
    {
      city_id: 14438,
      region_id: 6,
      name_ar: 'الجلل',
      name_en: 'Al Jalal',
    },
    {
      city_id: 14440,
      region_id: 6,
      name_ar: 'جنب نجد',
      name_en: 'Janb Najd',
    },
    {
      city_id: 14441,
      region_id: 6,
      name_ar: 'قويد اللحج',
      name_en: 'Quwaid Al Lahaj',
    },
    {
      city_id: 14443,
      region_id: 6,
      name_ar: 'اللفج',
      name_en: 'Al Lafj',
    },
    {
      city_id: 14446,
      region_id: 6,
      name_ar: 'لحج غلق',
      name_en: 'Lahaj Ghalaq',
    },
    {
      city_id: 14447,
      region_id: 6,
      name_ar: 'الكهفين',
      name_en: 'Al Kahfain',
    },
    {
      city_id: 14448,
      region_id: 6,
      name_ar: 'شعاب جوهر',
      name_en: 'Shiab Jawhar',
    },
    {
      city_id: 14449,
      region_id: 6,
      name_ar: 'السيالة',
      name_en: 'Al Sayalah',
    },
    {
      city_id: 14451,
      region_id: 6,
      name_ar: 'هجوم',
      name_en: 'Hajum',
    },
    {
      city_id: 14452,
      region_id: 6,
      name_ar: 'العانزة',
      name_en: 'Al Anizah',
    },
    {
      city_id: 14454,
      region_id: 6,
      name_ar: 'جو غمرة',
      name_en: 'Jaw Ghamrah',
    },
    {
      city_id: 14455,
      region_id: 6,
      name_ar: 'لحج العين',
      name_en: 'Lahaj Al Ayn',
    },
    {
      city_id: 14456,
      region_id: 6,
      name_ar: 'شط الصليف',
      name_en: 'Shat Al Salif',
    },
    {
      city_id: 14458,
      region_id: 6,
      name_ar: 'الرفدة',
      name_en: 'Al Rafdah',
    },
    {
      city_id: 14459,
      region_id: 6,
      name_ar: 'هتاين',
      name_en: 'Hatayin',
    },
    {
      city_id: 14460,
      region_id: 6,
      name_ar: 'القاربة',
      name_en: 'Al Qaribah',
    },
    {
      city_id: 14461,
      region_id: 6,
      name_ar: 'سرحة',
      name_en: 'Sarhah',
    },
    {
      city_id: 14462,
      region_id: 6,
      name_ar: 'المصلى (شعول)',
      name_en: 'Al Musalla (Shaul)',
    },
    {
      city_id: 14463,
      region_id: 6,
      name_ar: 'شفا الغراب',
      name_en: 'Shafa Al Ghurab',
    },
    {
      city_id: 14464,
      region_id: 6,
      name_ar: 'محضان',
      name_en: 'Mahdan',
    },
    {
      city_id: 14466,
      region_id: 6,
      name_ar: 'ضحى شوقب',
      name_en: 'Duha Shawqab',
    },
    {
      city_id: 14467,
      region_id: 6,
      name_ar: 'الشاخص',
      name_en: 'Al Shakhis',
    },
    {
      city_id: 14468,
      region_id: 6,
      name_ar: 'البير ال زياد',
      name_en: 'Al Bir Al Ziyad',
    },
    {
      city_id: 14470,
      region_id: 6,
      name_ar: 'الصرفة',
      name_en: 'Al Sarfah',
    },
    {
      city_id: 14474,
      region_id: 6,
      name_ar: 'الدارة والعانز',
      name_en: 'Al Darah & Al Aniz',
    },
    {
      city_id: 14476,
      region_id: 6,
      name_ar: 'الغبة',
      name_en: 'Al Ghubbah',
    },
    {
      city_id: 14478,
      region_id: 6,
      name_ar: 'الشرقي',
      name_en: 'Al Sharqi',
    },
    {
      city_id: 14479,
      region_id: 6,
      name_ar: 'القرعاء',
      name_en: 'Al Qaraa',
    },
    {
      city_id: 14482,
      region_id: 6,
      name_ar: 'لغبة',
      name_en: 'Laghbah',
    },
    {
      city_id: 14486,
      region_id: 6,
      name_ar: 'بلقة ال مغرم',
      name_en: 'Balqat Al Maghram',
    },
    {
      city_id: 14490,
      region_id: 6,
      name_ar: 'بلقة المعملة',
      name_en: 'Balqat Al Maamalah',
    },
    {
      city_id: 14494,
      region_id: 6,
      name_ar: 'غمرة ال مروح',
      name_en: 'Ghamrat Al Mirwih',
    },
    {
      city_id: 14497,
      region_id: 6,
      name_ar: 'ركس المروة والنصب',
      name_en: 'Rika Al Marwah & Al Nasab',
    },
    {
      city_id: 14498,
      region_id: 6,
      name_ar: 'الوجية',
      name_en: 'Al Wajih',
    },
    {
      city_id: 14499,
      region_id: 6,
      name_ar: 'المسور',
      name_en: 'Al Masur',
    },
    {
      city_id: 14500,
      region_id: 6,
      name_ar: 'آل عقيلة',
      name_en: 'Al Aqilah',
    },
    {
      city_id: 14501,
      region_id: 6,
      name_ar: 'الفالة',
      name_en: 'Al Qalah',
    },
    {
      city_id: 14502,
      region_id: 6,
      name_ar: 'ذم حجم',
      name_en: 'Dham Hajam',
    },
    {
      city_id: 14504,
      region_id: 6,
      name_ar: 'شناكة',
      name_en: 'Shanakah',
    },
    {
      city_id: 14505,
      region_id: 6,
      name_ar: 'ملحج',
      name_en: 'Malhaj',
    },
    {
      city_id: 14506,
      region_id: 6,
      name_ar: 'الخورمة',
      name_en: 'Al Khawramah',
    },
    {
      city_id: 14507,
      region_id: 6,
      name_ar: 'خرار',
      name_en: 'Kharar',
    },
    {
      city_id: 14508,
      region_id: 6,
      name_ar: 'الصليل',
      name_en: 'Al Salil',
    },
    {
      city_id: 14510,
      region_id: 6,
      name_ar: 'عطف المسكتة',
      name_en: 'Atf Al Maskatah',
    },
    {
      city_id: 14511,
      region_id: 6,
      name_ar: 'خميس كسان',
      name_en: 'Khamis Kasan',
    },
    {
      city_id: 14512,
      region_id: 6,
      name_ar: 'عدا لرس',
      name_en: 'Ada Laras',
    },
    {
      city_id: 14515,
      region_id: 6,
      name_ar: 'وطن الرجال',
      name_en: 'Watan Al Rijal',
    },
    {
      city_id: 14518,
      region_id: 6,
      name_ar: 'مكشف',
      name_en: 'Makshaf',
    },
    {
      city_id: 14519,
      region_id: 6,
      name_ar: 'الشفا',
      name_en: 'Al Shafa',
    },
    {
      city_id: 14533,
      region_id: 6,
      name_ar: 'الظهري',
      name_en: 'Al Dhahri',
    },
    {
      city_id: 14534,
      region_id: 6,
      name_ar: 'القتب',
      name_en: 'Al Qatab',
    },
    {
      city_id: 14535,
      region_id: 6,
      name_ar: 'الاطراف',
      name_en: 'Al Atraf',
    },
    {
      city_id: 14545,
      region_id: 6,
      name_ar: 'الصفا',
      name_en: 'Al Safa',
    },
    {
      city_id: 14546,
      region_id: 6,
      name_ar: 'امقفيل والمهلل',
      name_en: 'Amaqfil & Al Muhalhal',
    },
    {
      city_id: 14547,
      region_id: 6,
      name_ar: 'حلا',
      name_en: 'Hala',
    },
    {
      city_id: 14548,
      region_id: 6,
      name_ar: 'امقضى والفصايل',
      name_en: 'Amaqda & Al Fasayil',
    },
    {
      city_id: 14549,
      region_id: 6,
      name_ar: 'هبا',
      name_en: 'Haba',
    },
    {
      city_id: 14550,
      region_id: 6,
      name_ar: 'الركس والمليجة والعارضن',
      name_en: 'Al Riks & Al Malijah & Al Arid',
    },
    {
      city_id: 14551,
      region_id: 6,
      name_ar: 'امهذوب',
      name_en: 'Imhadhwab',
    },
    {
      city_id: 14552,
      region_id: 6,
      name_ar: 'لحج الماء',
      name_en: 'Lahaj Al Maa',
    },
    {
      city_id: 14554,
      region_id: 6,
      name_ar: 'البيح',
      name_en: 'Al Bih',
    },
    {
      city_id: 14555,
      region_id: 6,
      name_ar: 'ذنب سنام',
      name_en: 'Dhanab Sanam',
    },
    {
      city_id: 14558,
      region_id: 6,
      name_ar: 'جلة شصعة',
      name_en: 'Jallat Shasah',
    },
    {
      city_id: 14559,
      region_id: 6,
      name_ar: 'العودة (ال سعيد)',
      name_en: 'Al Awdah (Al Said)',
    },
    {
      city_id: 14560,
      region_id: 6,
      name_ar: 'أريمن',
      name_en: 'Aryaman',
    },
    {
      city_id: 14561,
      region_id: 6,
      name_ar: 'النحرة',
      name_en: 'Al Nahrah',
    },
    {
      city_id: 14567,
      region_id: 6,
      name_ar: 'العقصة',
      name_en: 'Al Aqsah',
    },
    {
      city_id: 14568,
      region_id: 6,
      name_ar: 'قرية الجرف',
      name_en: 'Qaryat Al Jurf',
    },
    {
      city_id: 14570,
      region_id: 6,
      name_ar: 'وطن ال سالم',
      name_en: 'Watan Al Salim',
    },
    {
      city_id: 14571,
      region_id: 6,
      name_ar: 'ذنب الوقيع',
      name_en: 'Dhanab Al Waqya',
    },
    {
      city_id: 14572,
      region_id: 6,
      name_ar: 'المقراء',
      name_en: 'Al Maqraa',
    },
    {
      city_id: 14573,
      region_id: 6,
      name_ar: 'سرال حجيل',
      name_en: 'Saral Hajil',
    },
    {
      city_id: 14574,
      region_id: 6,
      name_ar: 'سلل',
      name_en: 'Salal',
    },
    {
      city_id: 14575,
      region_id: 6,
      name_ar: 'شرف الزعابل',
      name_en: 'Sharaf Al Zaabil',
    },
    {
      city_id: 14576,
      region_id: 6,
      name_ar: 'المكبة',
      name_en: 'Al Makabbah',
    },
    {
      city_id: 14577,
      region_id: 6,
      name_ar: 'الاجوال',
      name_en: 'Al Ajwal',
    },
    {
      city_id: 14578,
      region_id: 6,
      name_ar: 'المغردة',
      name_en: 'Al Mugharifah',
    },
    {
      city_id: 14580,
      region_id: 6,
      name_ar: 'قرية ال مشقي',
      name_en: 'Qaryat Al Masgqi',
    },
    {
      city_id: 14581,
      region_id: 6,
      name_ar: 'الحدمة',
      name_en: 'Al Hadmah',
    },
    {
      city_id: 14582,
      region_id: 6,
      name_ar: 'المشبله',
      name_en: 'Al Mashbalah',
    },
    {
      city_id: 14584,
      region_id: 6,
      name_ar: 'راجح (قارية بنى جونة)',
      name_en: 'Rajih (Qaryat Bani Junah)',
    },
    {
      city_id: 14585,
      region_id: 6,
      name_ar: 'سرال محروس',
      name_en: 'Saral Mahrus',
    },
    {
      city_id: 14587,
      region_id: 6,
      name_ar: 'قرا اسفل الجر',
      name_en: 'Qara Asfal Al Jar',
    },
    {
      city_id: 14588,
      region_id: 6,
      name_ar: 'السعدان',
      name_en: 'Al Saadan',
    },
    {
      city_id: 14589,
      region_id: 6,
      name_ar: 'المخلفة',
      name_en: 'Al Makhlafah',
    },
    {
      city_id: 14590,
      region_id: 6,
      name_ar: 'الحذرور',
      name_en: 'Al Hadhrur',
    },
    {
      city_id: 14592,
      region_id: 6,
      name_ar: 'ال جرو',
      name_en: 'Al Jaru',
    },
    {
      city_id: 14593,
      region_id: 6,
      name_ar: 'حرف ال عائض جابر',
      name_en: 'Harf Al Ayid Jabir',
    },
    {
      city_id: 14594,
      region_id: 6,
      name_ar: 'حرف ال مسروي',
      name_en: 'Harf Al Masrawi',
    },
    {
      city_id: 14596,
      region_id: 6,
      name_ar: 'مسكتة الحجلة',
      name_en: 'Maskatat Al Jahlah',
    },
    {
      city_id: 14597,
      region_id: 6,
      name_ar: 'نوخان',
      name_en: 'Nukhan',
    },
    {
      city_id: 14598,
      region_id: 6,
      name_ar: 'جمرة',
      name_en: 'Jamrah',
    },
    {
      city_id: 14599,
      region_id: 6,
      name_ar: 'حضن العرة',
      name_en: 'Hudun Al Arrah',
    },
    {
      city_id: 14600,
      region_id: 6,
      name_ar: 'حضن ال فحمان',
      name_en: 'Hudun Al Fahman',
    },
    {
      city_id: 14601,
      region_id: 6,
      name_ar: 'ال جديع',
      name_en: 'Al Jadya',
    },
    {
      city_id: 14602,
      region_id: 6,
      name_ar: 'البادية',
      name_en: 'Al Badiyah',
    },
    {
      city_id: 14603,
      region_id: 6,
      name_ar: 'ال سعيد',
      name_en: 'Al Said',
    },
    {
      city_id: 14604,
      region_id: 6,
      name_ar: 'المسلب والحرف',
      name_en: 'Al Maslab & Al Harf',
    },
    {
      city_id: 14605,
      region_id: 6,
      name_ar: 'ذم سودة',
      name_en: 'Dham Sawdah',
    },
    {
      city_id: 14606,
      region_id: 6,
      name_ar: 'لباخ',
      name_en: 'Labakh',
    },
    {
      city_id: 14607,
      region_id: 6,
      name_ar: 'المعشرة',
      name_en: 'Al Masharah',
    },
    {
      city_id: 14608,
      region_id: 6,
      name_ar: 'ام السعي',
      name_en: 'Umm Al Sai',
    },
    {
      city_id: 14609,
      region_id: 6,
      name_ar: 'مندر ذهبان',
      name_en: 'Mandar Dhahaban',
    },
    {
      city_id: 14610,
      region_id: 6,
      name_ar: 'المسابلة',
      name_en: 'Al Masabilah',
    },
    {
      city_id: 14611,
      region_id: 6,
      name_ar: 'الرديف',
      name_en: 'Al Radif (Al Atfah)',
    },
    {
      city_id: 14612,
      region_id: 6,
      name_ar: 'الرقي',
      name_en: 'Al Raqi',
    },
    {
      city_id: 14613,
      region_id: 6,
      name_ar: 'حورة',
      name_en: 'Hawrah',
    },
    {
      city_id: 14615,
      region_id: 6,
      name_ar: 'الصفيح',
      name_en: 'Al Safih',
    },
    {
      city_id: 14617,
      region_id: 6,
      name_ar: 'الغبيرة',
      name_en: 'Al Ghabirah',
    },
    {
      city_id: 14618,
      region_id: 6,
      name_ar: 'ام لصوب',
      name_en: 'Umm Lasub',
    },
    {
      city_id: 14620,
      region_id: 6,
      name_ar: 'ذنب الغليل',
      name_en: 'Dhanab Al Ghalil',
    },
    {
      city_id: 14621,
      region_id: 6,
      name_ar: 'الغيناء',
      name_en: 'Al Ghaynaa',
    },
    {
      city_id: 14622,
      region_id: 6,
      name_ar: 'سرو المرار',
      name_en: 'Saru Al Marar',
    },
    {
      city_id: 14623,
      region_id: 6,
      name_ar: 'الضاجعة',
      name_en: 'Al Dajiah',
    },
    {
      city_id: 14624,
      region_id: 6,
      name_ar: 'وجه السرو',
      name_en: 'Wajh Al Saru',
    },
    {
      city_id: 14626,
      region_id: 6,
      name_ar: 'حيد الصدر',
      name_en: 'Hayd Al Sadr',
    },
    {
      city_id: 14627,
      region_id: 6,
      name_ar: 'حيد الحلل',
      name_en: 'Hayd Al Halal',
    },
    {
      city_id: 14628,
      region_id: 6,
      name_ar: 'حيد الخلوة',
      name_en: 'Hayd Al Khalwah',
    },
    {
      city_id: 14629,
      region_id: 6,
      name_ar: 'مويركة (ام ويركة)',
      name_en: 'Muwairkah (Umm Wiraikah)',
    },
    {
      city_id: 14630,
      region_id: 6,
      name_ar: 'قرية الصحبة والقارية',
      name_en: 'Qaryat Al Sahbah & Al Qariyyah',
    },
    {
      city_id: 14631,
      region_id: 6,
      name_ar: 'الجرين',
      name_en: 'Al Jarain',
    },
    {
      city_id: 14634,
      region_id: 6,
      name_ar: 'المكبشة',
      name_en: 'Al Makbashah',
    },
    {
      city_id: 14635,
      region_id: 6,
      name_ar: 'ال الرويعي',
      name_en: 'Al Al Ruwayi',
    },
    {
      city_id: 14639,
      region_id: 6,
      name_ar: 'السرو الاسفل',
      name_en: 'Al Saru Al Asfal',
    },
    {
      city_id: 14640,
      region_id: 6,
      name_ar: 'سرو المسلمة (الأعلى)',
      name_en: 'Saru Al Maslamah (Al Aala)',
    },
    {
      city_id: 14644,
      region_id: 6,
      name_ar: 'راس الجبل',
      name_en: 'Ras Al Jabal',
    },
    {
      city_id: 14645,
      region_id: 6,
      name_ar: 'الشرقية',
      name_en: 'Al Sharqiyyah',
    },
    {
      city_id: 14646,
      region_id: 6,
      name_ar: 'المقعر',
      name_en: 'Al Maqar',
    },
    {
      city_id: 14647,
      region_id: 6,
      name_ar: 'جلة المشمطة',
      name_en: 'Jallat Al Mashmatah',
    },
    {
      city_id: 14648,
      region_id: 6,
      name_ar: 'جلة المحرقة',
      name_en: 'Jallat Al Mahraqah',
    },
    {
      city_id: 14649,
      region_id: 6,
      name_ar: 'قوعان',
      name_en: 'Qawan',
    },
    {
      city_id: 14650,
      region_id: 6,
      name_ar: 'الرحوب',
      name_en: 'Al Rahub',
    },
    {
      city_id: 14651,
      region_id: 6,
      name_ar: 'صدر المغلبة بحسوة',
      name_en: 'Sadr Al Maghlabah In Haswah',
    },
    {
      city_id: 14652,
      region_id: 6,
      name_ar: 'شوكان الاعلى والاسفل',
      name_en: 'Shawkan Al Aala & Al Asfal',
    },
    {
      city_id: 14653,
      region_id: 6,
      name_ar: 'ذهبان (صمع)',
      name_en: 'Dhabah (Sama)',
    },
    {
      city_id: 14654,
      region_id: 6,
      name_ar: 'ال ابو علبة',
      name_en: 'Al Abu Ulbah',
    },
    {
      city_id: 14655,
      region_id: 6,
      name_ar: 'نشام (نهامن)',
      name_en: 'Nasham (Nahamin)',
    },
    {
      city_id: 14656,
      region_id: 6,
      name_ar: 'الفصيلة (العسامة)',
      name_en: 'Al Fasilah (Al Asamah)',
    },
    {
      city_id: 14657,
      region_id: 6,
      name_ar: 'الحقبة',
      name_en: 'Al Haqbah',
    },
    {
      city_id: 14658,
      region_id: 6,
      name_ar: 'المصاغة',
      name_en: 'Al Masaghah',
    },
    {
      city_id: 14662,
      region_id: 6,
      name_ar: 'صرة',
      name_en: 'Surrah',
    },
    {
      city_id: 14663,
      region_id: 6,
      name_ar: 'السرحاني',
      name_en: 'Al Sarhani',
    },
    {
      city_id: 14666,
      region_id: 6,
      name_ar: 'سلعا',
      name_en: 'Salaa',
    },
    {
      city_id: 14668,
      region_id: 6,
      name_ar: 'ظلمة',
      name_en: 'Dhalmah',
    },
    {
      city_id: 14689,
      region_id: 6,
      name_ar: 'روق',
      name_en: 'Rawq',
    },
    {
      city_id: 14690,
      region_id: 6,
      name_ar: 'معلين',
      name_en: 'Malayn',
    },
    {
      city_id: 14691,
      region_id: 6,
      name_ar: 'ال مريع',
      name_en: 'Al Mrayia',
    },
    {
      city_id: 14693,
      region_id: 6,
      name_ar: 'ال سالم',
      name_en: 'Al Salim',
    },
    {
      city_id: 14694,
      region_id: 6,
      name_ar: 'ال شار',
      name_en: 'Al Shar',
    },
    {
      city_id: 14695,
      region_id: 6,
      name_ar: 'ال عواض',
      name_en: 'Al Awadhah',
    },
    {
      city_id: 14696,
      region_id: 6,
      name_ar: 'الحرف الاعلى (اللواء)',
      name_en: 'Al Harf Al Aala (Al Liwaa)',
    },
    {
      city_id: 14697,
      region_id: 6,
      name_ar: 'الشروة',
      name_en: 'Al Sharwah',
    },
    {
      city_id: 14698,
      region_id: 6,
      name_ar: 'المحدثة',
      name_en: 'Al Mahdathah',
    },
    {
      city_id: 14699,
      region_id: 6,
      name_ar: 'السدرة',
      name_en: 'Al Sidrah',
    },
    {
      city_id: 14700,
      region_id: 6,
      name_ar: 'حرف المكثرة',
      name_en: 'Hafr Al Maktharah',
    },
    {
      city_id: 14701,
      region_id: 6,
      name_ar: 'باحة روق',
      name_en: 'Bahat Rawq',
    },
    {
      city_id: 14703,
      region_id: 6,
      name_ar: 'شرا روق',
      name_en: 'Shara Rawq',
    },
    {
      city_id: 14704,
      region_id: 6,
      name_ar: 'قرية ال عامر',
      name_en: 'Qaryat Al Amir',
    },
    {
      city_id: 14705,
      region_id: 6,
      name_ar: 'حرف المصلحة',
      name_en: 'Harfa Al Maslah',
    },
    {
      city_id: 14706,
      region_id: 6,
      name_ar: 'الصطة',
      name_en: 'Al Sattah',
    },
    {
      city_id: 14712,
      region_id: 6,
      name_ar: 'سرو المخارمة (المخارمة)',
      name_en: 'Sau Al Makharimah (Al Makharimah)',
    },
    {
      city_id: 14714,
      region_id: 6,
      name_ar: 'قذيلى',
      name_en: 'Qadhili',
    },
    {
      city_id: 14715,
      region_id: 6,
      name_ar: 'ال غرامة',
      name_en: 'Al Gharamah',
    },
    {
      city_id: 14716,
      region_id: 6,
      name_ar: 'قرية ال الشعبي',
      name_en: 'Qaryat Al Al Shiabi',
    },
    {
      city_id: 14717,
      region_id: 6,
      name_ar: 'ال عراف',
      name_en: 'Al Arraf',
    },
    {
      city_id: 14718,
      region_id: 6,
      name_ar: 'طنف (ال خودة)',
      name_en: 'Tanaf (Al Khawdah)',
    },
    {
      city_id: 14719,
      region_id: 6,
      name_ar: 'العصادة',
      name_en: 'Al Asadah',
    },
    {
      city_id: 14720,
      region_id: 6,
      name_ar: 'مندريرة',
      name_en: 'Mandarirah',
    },
    {
      city_id: 14722,
      region_id: 6,
      name_ar: 'قارية بني جونة',
      name_en: 'Qariyat Bani Junah',
    },
    {
      city_id: 14723,
      region_id: 6,
      name_ar: 'صدريرة',
      name_en: 'Sadrirah',
    },
    {
      city_id: 14725,
      region_id: 6,
      name_ar: 'قراه',
      name_en: 'Qarah',
    },
    {
      city_id: 14726,
      region_id: 6,
      name_ar: 'الصدر والغليل',
      name_en: 'Al Sadr & Al Ghalil',
    },
    {
      city_id: 15001,
      region_id: 6,
      name_ar: 'ال السواد',
      name_en: 'Al Asawad',
    },
    {
      city_id: 15400,
      region_id: 6,
      name_ar: 'طلالا',
      name_en: 'Talala',
    },
    {
      city_id: 15498,
      region_id: 6,
      name_ar: 'عميم ال سلمة',
      name_en: 'Amim Al Salamah',
    },
    {
      city_id: 15504,
      region_id: 6,
      name_ar: 'المنتزه',
      name_en: 'Al Muntazah',
    },
    {
      city_id: 15505,
      region_id: 6,
      name_ar: 'ال بيسالم',
      name_en: 'Al Bisalim',
    },
    {
      city_id: 15507,
      region_id: 6,
      name_ar: 'ال رايح',
      name_en: 'Al Rayih',
    },
    {
      city_id: 15508,
      region_id: 6,
      name_ar: 'الشعبان',
      name_en: 'Al Shaaban',
    },
    {
      city_id: 15509,
      region_id: 6,
      name_ar: 'حارة السلام',
      name_en: 'Harat Al Salam',
    },
    {
      city_id: 15512,
      region_id: 6,
      name_ar: 'معراة',
      name_en: 'Maarah',
    },
    {
      city_id: 15514,
      region_id: 6,
      name_ar: 'عرق مناع (ال مجمل)',
      name_en: 'Irq Mannaa (Al Majmal)',
    },
    {
      city_id: 15516,
      region_id: 6,
      name_ar: 'المرتفع (ال مجمل)',
      name_en: 'Al Murtafa (Al Majmal)',
    },
    {
      city_id: 15534,
      region_id: 6,
      name_ar: 'مركز شعف بلقرن',
      name_en: 'Shiaf Balqarin Center',
    },
    {
      city_id: 15547,
      region_id: 6,
      name_ar: 'الاقرع',
      name_en: 'Al Aqra',
    },
    {
      city_id: 15607,
      region_id: 6,
      name_ar: 'العقيق',
      name_en: 'Al Aqiq',
    },
    {
      city_id: 16197,
      region_id: 6,
      name_ar: 'خاط',
      name_en: 'Khat',
    },
    {
      city_id: 16303,
      region_id: 6,
      name_ar: 'آل خجيم',
      name_en: 'Al Khujaim',
    },
    {
      city_id: 16787,
      region_id: 6,
      name_ar: 'غوافل',
      name_en: 'Ghavafil',
    },
    {
      city_id: 16815,
      region_id: 6,
      name_ar: 'المقلي',
      name_en: 'Al Maqli',
    },
    {
      city_id: 16819,
      region_id: 6,
      name_ar: 'شعب ام سد',
      name_en: 'Shiab Umm Sad',
    },
    {
      city_id: 17411,
      region_id: 8,
      name_ar: 'العريدة',
      name_en: 'Al Areedah',
    },
    {
      city_id: 17417,
      region_id: 8,
      name_ar: 'القرينين',
      name_en: 'Alqarinin',
    },
    {
      city_id: 17420,
      region_id: 8,
      name_ar: 'بدائع  الجرعاء',
      name_en: "Badai' Al Jara",
    },
    {
      city_id: 17431,
      region_id: 8,
      name_ar: 'الضربة',
      name_en: 'Al Darbah',
    },
    {
      city_id: 17444,
      region_id: 8,
      name_ar: 'رجامة',
      name_en: 'Rijamah',
    },
    {
      city_id: 17456,
      region_id: 8,
      name_ar: 'الزبيرة',
      name_en: 'Az Zubayrah',
    },
    {
      city_id: 17457,
      region_id: 8,
      name_ar: 'الخوير',
      name_en: 'Al Khuwair',
    },
    {
      city_id: 17459,
      region_id: 8,
      name_ar: 'ام رقتين',
      name_en: 'Umm Raqtain',
    },
    {
      city_id: 17465,
      region_id: 8,
      name_ar: 'الحيانية',
      name_en: 'Al Hayaniyah',
    },
    {
      city_id: 17467,
      region_id: 8,
      name_ar: 'الجبيلي',
      name_en: 'Al Jabayli',
    },
    {
      city_id: 17475,
      region_id: 8,
      name_ar: 'ام ساروت',
      name_en: 'Umm Sarut',
    },
    {
      city_id: 17476,
      region_id: 8,
      name_ar: 'الناصرية',
      name_en: 'An Nasiriyah',
    },
    {
      city_id: 17477,
      region_id: 8,
      name_ar: 'الفويلق 2',
      name_en: 'Al Fuwayliq 2',
    },
    {
      city_id: 17478,
      region_id: 8,
      name_ar: 'الفويلق 1',
      name_en: 'Al Fuwayliq 1',
    },
    {
      city_id: 17482,
      region_id: 8,
      name_ar: 'ابو مزحامة',
      name_en: 'Abu Mazahah',
    },
    {
      city_id: 17488,
      region_id: 8,
      name_ar: 'السناف',
      name_en: 'Al Sanaf',
    },
    {
      city_id: 17491,
      region_id: 8,
      name_ar: 'بدائع العليا',
      name_en: "Badai' Aleulya",
    },
    {
      city_id: 17492,
      region_id: 8,
      name_ar: 'بدائع المحمي',
      name_en: "Badai' Al Mahmi",
    },
    {
      city_id: 17493,
      region_id: 8,
      name_ar: 'ريع المحمي',
      name_en: "Ri' Al Mahmi",
    },
    {
      city_id: 17501,
      region_id: 8,
      name_ar: 'بدائع الصباحة',
      name_en: 'Badaya Al Sabahah',
    },
    {
      city_id: 17514,
      region_id: 8,
      name_ar: 'المنصورية',
      name_en: 'Al Mansuriyah',
    },
    {
      city_id: 17522,
      region_id: 8,
      name_ar: 'اللويش',
      name_en: 'Al Luwaish',
    },
    {
      city_id: 17569,
      region_id: 8,
      name_ar: 'الغفيلية',
      name_en: 'Al Ghafiliyyah',
    },
    {
      city_id: 17573,
      region_id: 8,
      name_ar: 'الرضفين',
      name_en: 'Al Radhfain',
    },
    {
      city_id: 17574,
      region_id: 8,
      name_ar: 'الشارف',
      name_en: 'Al Shaarif',
    },
    {
      city_id: 17577,
      region_id: 8,
      name_ar: 'روض العبد',
      name_en: 'Rawd Al Abd',
    },
    {
      city_id: 17581,
      region_id: 8,
      name_ar: 'السعدة',
      name_en: 'Al Saadah',
    },
    {
      city_id: 17608,
      region_id: 8,
      name_ar: 'النظيم واللجاوي',
      name_en: 'An Nadhim And Lajawi',
    },
    {
      city_id: 17613,
      region_id: 8,
      name_ar: 'الغانمية',
      name_en: 'Al Ganimiyah',
    },
    {
      city_id: 17614,
      region_id: 8,
      name_ar: 'الحمودية',
      name_en: 'Al Hamudiyah',
    },
    {
      city_id: 17638,
      region_id: 8,
      name_ar: 'الصخيرة الصبان',
      name_en: 'Al Sakhirah Al Sabban',
    },
    {
      city_id: 17639,
      region_id: 8,
      name_ar: 'حمه',
      name_en: 'Hama',
    },
    {
      city_id: 17647,
      region_id: 8,
      name_ar: 'ثويليل',
      name_en: 'Thuwayleel',
    },
    {
      city_id: 17648,
      region_id: 8,
      name_ar: 'أبو سويسيات',
      name_en: 'Abu Swaisiyat',
    },
    {
      city_id: 17652,
      region_id: 8,
      name_ar: 'القوينية',
      name_en: 'Al Quwainiyah',
    },
    {
      city_id: 17662,
      region_id: 8,
      name_ar: 'الدحوه',
      name_en: 'Ad Dihwah',
    },
    {
      city_id: 17667,
      region_id: 8,
      name_ar: 'الشعيلاء',
      name_en: 'Al Shuayla',
    },
    {
      city_id: 17679,
      region_id: 8,
      name_ar: 'هجرة المخروق',
      name_en: 'Hijrat Al Makhruq',
    },
    {
      city_id: 17695,
      region_id: 8,
      name_ar: 'عدوة اثقب',
      name_en: 'Adwat Athqab',
    },
    {
      city_id: 17698,
      region_id: 8,
      name_ar: 'الشق',
      name_en: 'Al Shaq',
    },
    {
      city_id: 17704,
      region_id: 8,
      name_ar: 'بدائع الشق',
      name_en: "Badai' Al Shaq",
    },
    {
      city_id: 17708,
      region_id: 8,
      name_ar: 'الصفراء',
      name_en: 'As Safra',
    },
    {
      city_id: 17717,
      region_id: 8,
      name_ar: 'المزكا',
      name_en: 'Al Mazka',
    },
    {
      city_id: 17718,
      region_id: 8,
      name_ar: 'كحلة',
      name_en: 'Kahlah',
    },
    {
      city_id: 17728,
      region_id: 8,
      name_ar: 'الملوي',
      name_en: 'Al Malvi',
    },
    {
      city_id: 17740,
      region_id: 8,
      name_ar: 'اللبانه',
      name_en: 'Al Labana',
    },
    {
      city_id: 17743,
      region_id: 8,
      name_ar: 'وسعة الرس',
      name_en: 'Wasat Al Rass',
    },
    {
      city_id: 17744,
      region_id: 8,
      name_ar: 'الطنيب',
      name_en: 'Al Tuneib',
    },
    {
      city_id: 17750,
      region_id: 8,
      name_ar: 'البحره',
      name_en: 'Al Bahra',
    },
    {
      city_id: 17761,
      region_id: 8,
      name_ar: 'ام شعاري',
      name_en: "Umm Sha'arei",
    },
    {
      city_id: 17762,
      region_id: 8,
      name_ar: 'اللخين عبيد',
      name_en: 'Al Lakheen Ubaid',
    },
    {
      city_id: 17777,
      region_id: 8,
      name_ar: 'المناخ',
      name_en: 'Al Manakh',
    },
    {
      city_id: 17778,
      region_id: 8,
      name_ar: 'المجصة',
      name_en: 'Al Majasah',
    },
    {
      city_id: 17780,
      region_id: 8,
      name_ar: 'البوييضة',
      name_en: 'Al Buwaiyidah',
    },
    {
      city_id: 17781,
      region_id: 8,
      name_ar: 'ابو دويحات',
      name_en: 'Abu Duyhat',
    },
    {
      city_id: 17782,
      region_id: 8,
      name_ar: 'عيال عبيد',
      name_en: 'Eyal Obeid',
    },
    {
      city_id: 17788,
      region_id: 8,
      name_ar: 'الملاح',
      name_en: 'Al Mallah',
    },
    {
      city_id: 17791,
      region_id: 8,
      name_ar: 'المغار',
      name_en: 'Al Maghar',
    },
    {
      city_id: 17795,
      region_id: 8,
      name_ar: 'ام عماير',
      name_en: 'Umm Amayir',
    },
    {
      city_id: 17802,
      region_id: 8,
      name_ar: 'مران',
      name_en: 'Marran',
    },
    {
      city_id: 17827,
      region_id: 8,
      name_ar: 'الناصفة',
      name_en: 'An Nasifah',
    },
    {
      city_id: 17841,
      region_id: 8,
      name_ar: 'فرشة نجب',
      name_en: 'Farshat Najab',
    },
    {
      city_id: 17849,
      region_id: 8,
      name_ar: 'الدفينة',
      name_en: 'Al Dufaynah',
    },
    {
      city_id: 17853,
      region_id: 8,
      name_ar: 'فيضة المسعار',
      name_en: "Faydat Al Masa'r",
    },
    {
      city_id: 17861,
      region_id: 8,
      name_ar: 'الذكري',
      name_en: 'Al Dhikra',
    },
    {
      city_id: 17862,
      region_id: 9,
      name_ar: 'الشاظي مناحي بن بكر',
      name_en: 'Al Shadhi Manahi Ibn Bakr',
    },
    {
      city_id: 17863,
      region_id: 9,
      name_ar: 'الديدب',
      name_en: 'Al Didab',
    },
    {
      city_id: 17879,
      region_id: 9,
      name_ar: 'زبالا',
      name_en: 'Zabala',
    },
    {
      city_id: 17880,
      region_id: 9,
      name_ar: 'العجرمية',
      name_en: 'Al Ajramiyyah',
    },
    {
      city_id: 17888,
      region_id: 9,
      name_ar: 'ابن عائش',
      name_en: 'Ibn Aesh',
    },
    {
      city_id: 17924,
      region_id: 10,
      name_ar: 'النعامية',
      name_en: 'Al Naeamia',
    },
    {
      city_id: 17925,
      region_id: 10,
      name_ar: 'الهجاريه',
      name_en: 'Al Hajariah',
    },
    {
      city_id: 17926,
      region_id: 10,
      name_ar: 'الخواجيه',
      name_en: 'Al Khawajih',
    },
    {
      city_id: 17927,
      region_id: 10,
      name_ar: 'السوالمه',
      name_en: 'Al Sawalimah',
    },
    {
      city_id: 17928,
      region_id: 10,
      name_ar: 'الباطنه',
      name_en: 'Al Batinah',
    },
    {
      city_id: 17929,
      region_id: 10,
      name_ar: 'الشمه',
      name_en: 'Al Shamuh',
    },
    {
      city_id: 17930,
      region_id: 10,
      name_ar: 'الرافعي',
      name_en: 'Al Rafie',
    },
    {
      city_id: 17931,
      region_id: 10,
      name_ar: 'الطراشه',
      name_en: 'Alt Arashah',
    },
    {
      city_id: 17932,
      region_id: 10,
      name_ar: 'الفقرة',
      name_en: 'Al Faqrah',
    },
    {
      city_id: 17933,
      region_id: 10,
      name_ar: 'ابو الكرش',
      name_en: 'Abu Alkarsh',
    },
    {
      city_id: 17934,
      region_id: 10,
      name_ar: 'العرجين',
      name_en: 'Al Arjeen',
    },
    {
      city_id: 17935,
      region_id: 10,
      name_ar: 'الحواية',
      name_en: 'Al Hiwaya',
    },
    {
      city_id: 17936,
      region_id: 10,
      name_ar: 'العمارده',
      name_en: 'Al Amaridah',
    },
    {
      city_id: 17937,
      region_id: 10,
      name_ar: 'القصاده',
      name_en: 'Al Qasadah',
    },
    {
      city_id: 17938,
      region_id: 10,
      name_ar: 'السروريه',
      name_en: 'Al Saruriyah',
    },
    {
      city_id: 17939,
      region_id: 10,
      name_ar: 'النجابه و الجحاجح',
      name_en: 'Al Nijabah Wal Jahajih',
    },
    {
      city_id: 17940,
      region_id: 10,
      name_ar: 'عبدالله العيافي (العيافي)',
      name_en: 'Abdullah Al Eyafi (Al Eyafi)',
    },
    {
      city_id: 17941,
      region_id: 10,
      name_ar: 'الجعاونه',
      name_en: 'Al Jaeawunah',
    },
    {
      city_id: 17942,
      region_id: 10,
      name_ar: 'حله محسن  المحاسنة',
      name_en: 'Halah Muhsin Al Muhasana',
    },
    {
      city_id: 17943,
      region_id: 10,
      name_ar: 'الدواحشه',
      name_en: 'Al Dawahishah',
    },
    {
      city_id: 17944,
      region_id: 10,
      name_ar: 'تيهان (حلة تيهان)',
      name_en: 'Tyhan (Hulat Tyhan)',
    },
    {
      city_id: 17945,
      region_id: 10,
      name_ar: 'الخواره',
      name_en: 'Al Khawarah',
    },
    {
      city_id: 17946,
      region_id: 10,
      name_ar: 'الشواهية',
      name_en: 'Al Shawahiyah',
    },
    {
      city_id: 17947,
      region_id: 10,
      name_ar: 'الشعاره',
      name_en: 'Al Shiarah',
    },
    {
      city_id: 17948,
      region_id: 10,
      name_ar: 'القواعة',
      name_en: 'Al Qawaea',
    },
    {
      city_id: 17991,
      region_id: 10,
      name_ar: 'الشاخر',
      name_en: 'Al Shaakhir',
    },
    {
      city_id: 17992,
      region_id: 10,
      name_ar: 'ام الشباقاء',
      name_en: 'Umm Al Shibaqa',
    },
    {
      city_id: 17993,
      region_id: 10,
      name_ar: 'اللخبصية',
      name_en: 'Al Lakhabsia',
    },
    {
      city_id: 17994,
      region_id: 10,
      name_ar: 'الصامخ',
      name_en: 'Al Samikh',
    },
    {
      city_id: 17995,
      region_id: 10,
      name_ar: 'الجاره القديمه',
      name_en: 'Al Jarah Al Qadiumah',
    },
    {
      city_id: 17996,
      region_id: 10,
      name_ar: 'الزينيه',
      name_en: 'Al Ziniyah',
    },
    {
      city_id: 17997,
      region_id: 10,
      name_ar: 'حلة علي بن موسى',
      name_en: 'Hulat Ali Bin Musa',
    },
    {
      city_id: 17998,
      region_id: 10,
      name_ar: 'ام القحفه',
      name_en: 'Umm Al Qahfah',
    },
    {
      city_id: 17999,
      region_id: 10,
      name_ar: 'حلة المكي',
      name_en: 'Hulat Al Makki',
    },
    {
      city_id: 18000,
      region_id: 10,
      name_ar: 'حلة العرشي',
      name_en: 'Hulat Al Earshi',
    },
    {
      city_id: 18001,
      region_id: 10,
      name_ar: 'قبه',
      name_en: 'Qibah',
    },
    {
      city_id: 18002,
      region_id: 10,
      name_ar: 'الخضراء',
      name_en: 'Al Khadra',
    },
    {
      city_id: 18003,
      region_id: 10,
      name_ar: 'حله عطيه',
      name_en: 'Halah Attieh',
    },
    {
      city_id: 18004,
      region_id: 10,
      name_ar: 'حله ابو مرعي',
      name_en: 'Halah Abu Marei',
    },
    {
      city_id: 18005,
      region_id: 10,
      name_ar: 'الدحيقي',
      name_en: 'Al Daheeqi',
    },
    {
      city_id: 18006,
      region_id: 10,
      name_ar: 'الحشيبرية',
      name_en: 'Al Hashibiria',
    },
    {
      city_id: 18008,
      region_id: 10,
      name_ar: 'الرونة',
      name_en: 'Ar Runah',
    },
    {
      city_id: 18009,
      region_id: 10,
      name_ar: 'حله محمد عيسى',
      name_en: 'Halah Muhamad Eisa',
    },
    {
      city_id: 18010,
      region_id: 10,
      name_ar: 'حله العقيبي',
      name_en: 'Halah Aleaqibiu',
    },
    {
      city_id: 18011,
      region_id: 10,
      name_ar: 'حله القميري',
      name_en: 'Halah Al Qamiri',
    },
    {
      city_id: 18012,
      region_id: 10,
      name_ar: 'حله مشاري',
      name_en: 'Halah Mishary',
    },
    {
      city_id: 18013,
      region_id: 10,
      name_ar: 'حله الصهلولي',
      name_en: 'Halah Al Sahluli',
    },
    {
      city_id: 18014,
      region_id: 10,
      name_ar: 'حله الحو تين',
      name_en: 'Halah Alhawa Tyn',
    },
    {
      city_id: 18015,
      region_id: 10,
      name_ar: 'حله بن علوان',
      name_en: 'Halah Bin Alwaan',
    },
    {
      city_id: 18016,
      region_id: 10,
      name_ar: 'حله الحكمى',
      name_en: 'Halah Al Hukmaa',
    },
    {
      city_id: 18017,
      region_id: 10,
      name_ar: 'حله عبدالله قبع',
      name_en: 'Halah Abdullah Qabea',
    },
    {
      city_id: 18018,
      region_id: 10,
      name_ar: 'حله مروعي بن شامي',
      name_en: 'Halah Maruei Bin Shamy',
    },
    {
      city_id: 18019,
      region_id: 10,
      name_ar: 'حله الكلابيه',
      name_en: 'Halah Al Kalabih',
    },
    {
      city_id: 18020,
      region_id: 10,
      name_ar: 'ابو دنقور',
      name_en: 'Abu Danqur',
    },
    {
      city_id: 18021,
      region_id: 10,
      name_ar: 'الجديين',
      name_en: 'Al Jidiayn',
    },
    {
      city_id: 18022,
      region_id: 10,
      name_ar: 'زبارة الجديين',
      name_en: 'Zubarat Al Jidyeen',
    },
    {
      city_id: 18023,
      region_id: 10,
      name_ar: 'شيبان',
      name_en: 'Shayban',
    },
    {
      city_id: 18025,
      region_id: 10,
      name_ar: 'قرية الجوابره',
      name_en: 'Qaryat Al Jawabirih',
    },
    {
      city_id: 18026,
      region_id: 10,
      name_ar: 'الشواجرة والجرابية',
      name_en: 'Al Shawajirat Wal Jarabiyah',
    },
    {
      city_id: 18027,
      region_id: 10,
      name_ar: 'الزيني',
      name_en: 'Al Zayni',
    },
    {
      city_id: 18028,
      region_id: 10,
      name_ar: 'المحاصيه',
      name_en: 'Al Mahasih',
    },
    {
      city_id: 18029,
      region_id: 10,
      name_ar: 'جميما',
      name_en: 'Jameema',
    },
    {
      city_id: 18030,
      region_id: 10,
      name_ar: 'حنقفه',
      name_en: 'Hanaqfah',
    },
    {
      city_id: 18031,
      region_id: 10,
      name_ar: 'الحوامضه جحيش',
      name_en: 'Al Hawamidah Jahish',
    },
    {
      city_id: 18032,
      region_id: 10,
      name_ar: 'الزقله',
      name_en: 'Al Zaqlah',
    },
    {
      city_id: 18038,
      region_id: 10,
      name_ar: 'الفوارسة',
      name_en: 'Al Fawarisah',
    },
    {
      city_id: 18042,
      region_id: 10,
      name_ar: 'السلامة السفلى',
      name_en: 'Al Salamah Al Sufla',
    },
    {
      city_id: 18043,
      region_id: 10,
      name_ar: 'شهده',
      name_en: 'Shahdah',
    },
    {
      city_id: 18044,
      region_id: 10,
      name_ar: 'الكلبة',
      name_en: 'Al Kalibah',
    },
    {
      city_id: 18045,
      region_id: 10,
      name_ar: 'الفصاله والدغاريه',
      name_en: 'Al Fisalah Wal Dagharih',
    },
    {
      city_id: 18046,
      region_id: 10,
      name_ar: 'الشقافه والمصاوفة',
      name_en: 'Al Shiqafah Wal Musawafa',
    },
    {
      city_id: 18047,
      region_id: 10,
      name_ar: 'المدرك',
      name_en: 'Al Madrak',
    },
    {
      city_id: 18048,
      region_id: 10,
      name_ar: 'الشعافه',
      name_en: 'Al Shaeafah',
    },
    {
      city_id: 18049,
      region_id: 10,
      name_ar: 'ابوالمقره',
      name_en: 'Abu Al Maqrah',
    },
    {
      city_id: 18052,
      region_id: 10,
      name_ar: 'العواجمه',
      name_en: 'Al Awajimah',
    },
    {
      city_id: 18053,
      region_id: 10,
      name_ar: 'وجه الحسن',
      name_en: 'Wajah Alhasan',
    },
    {
      city_id: 18054,
      region_id: 10,
      name_ar: 'العرضه',
      name_en: 'Alearduh',
    },
    {
      city_id: 18055,
      region_id: 10,
      name_ar: 'البطيح',
      name_en: 'Albatih',
    },
    {
      city_id: 18056,
      region_id: 10,
      name_ar: 'السداده',
      name_en: 'Alsidaduh',
    },
    {
      city_id: 18057,
      region_id: 10,
      name_ar: 'النزلة',
      name_en: 'Al Nazlah',
    },
    {
      city_id: 18058,
      region_id: 10,
      name_ar: 'عوانه',
      name_en: 'Eawanah',
    },
    {
      city_id: 18059,
      region_id: 10,
      name_ar: 'العبده',
      name_en: 'Aleabduh',
    },
    {
      city_id: 18060,
      region_id: 10,
      name_ar: 'ابو الطيور',
      name_en: "'Abu Altuyur",
    },
    {
      city_id: 18061,
      region_id: 10,
      name_ar: 'ام العرش',
      name_en: 'Umm Alearsh',
    },
    {
      city_id: 18062,
      region_id: 10,
      name_ar: 'منسيه',
      name_en: 'Mansih',
    },
    {
      city_id: 18063,
      region_id: 10,
      name_ar: 'الصنيف',
      name_en: 'Alsanif',
    },
    {
      city_id: 18064,
      region_id: 10,
      name_ar: 'الحمضيه',
      name_en: 'Al Hamdiyah',
    },
    {
      city_id: 18065,
      region_id: 10,
      name_ar: 'الحرف',
      name_en: 'Al Harf',
    },
    {
      city_id: 18066,
      region_id: 10,
      name_ar: 'المجديره',
      name_en: 'Almajdiruh',
    },
    {
      city_id: 18067,
      region_id: 10,
      name_ar: 'حلة ابو حبيبه',
      name_en: "Hulat 'Abu Habibuh",
    },
    {
      city_id: 18068,
      region_id: 10,
      name_ar: 'الخوالده',
      name_en: 'Alkhawaliduh',
    },
    {
      city_id: 18069,
      region_id: 10,
      name_ar: 'الزيديه',
      name_en: 'Alzaydiuh',
    },
    {
      city_id: 18070,
      region_id: 10,
      name_ar: 'الحجرين',
      name_en: 'Alhajarayn',
    },
    {
      city_id: 18071,
      region_id: 10,
      name_ar: 'جريبه',
      name_en: 'Jaribuh',
    },
    {
      city_id: 18072,
      region_id: 10,
      name_ar: 'زربه',
      name_en: 'Zarabah',
    },
    {
      city_id: 18073,
      region_id: 10,
      name_ar: 'الهدوى',
      name_en: 'Alhadwaa',
    },
    {
      city_id: 18074,
      region_id: 10,
      name_ar: 'الحقاويه',
      name_en: 'Alhaqawih',
    },
    {
      city_id: 18075,
      region_id: 10,
      name_ar: 'العسيلة',
      name_en: "Al 'Usaylah",
    },
    {
      city_id: 18076,
      region_id: 10,
      name_ar: 'الرجيع',
      name_en: 'Alrajie',
    },
    {
      city_id: 18077,
      region_id: 10,
      name_ar: 'حلة ابراهيم عيسى',
      name_en: "Hulat 'Iibrahim Eisaa",
    },
    {
      city_id: 18078,
      region_id: 10,
      name_ar: 'الكومه',
      name_en: 'Alkawmuh',
    },
    {
      city_id: 18115,
      region_id: 10,
      name_ar: 'الحسينية',
      name_en: 'Al Husayniyyah',
    },
    {
      city_id: 18191,
      region_id: 10,
      name_ar: 'الخشابية',
      name_en: 'Al Khashabiyah',
    },
    {
      city_id: 18202,
      region_id: 10,
      name_ar: 'المشايخ',
      name_en: 'Al Mashayikh',
    },
    {
      city_id: 18210,
      region_id: 10,
      name_ar: 'الشابطه',
      name_en: 'Al Shabitah',
    },
    {
      city_id: 18217,
      region_id: 10,
      name_ar: 'الدهالية',
      name_en: 'Al Dahaliyyah',
    },
    {
      city_id: 18221,
      region_id: 10,
      name_ar: 'المعاشية',
      name_en: 'Al Maashiyyah',
    },
    {
      city_id: 18245,
      region_id: 10,
      name_ar: 'ابو لهب',
      name_en: 'Abu Lahab',
    },
    {
      city_id: 18285,
      region_id: 10,
      name_ar: 'سلام بني واصل',
      name_en: 'Salam Bani Wasal',
    },
    {
      city_id: 18289,
      region_id: 10,
      name_ar: 'خضيرة عياش',
      name_en: 'Khudayrat Ayash',
    },
    {
      city_id: 18298,
      region_id: 10,
      name_ar: 'المواسة',
      name_en: 'Al Mawasah',
    },
    {
      city_id: 18305,
      region_id: 10,
      name_ar: 'الكواملة',
      name_en: 'Al Kawamilah',
    },
    {
      city_id: 18320,
      region_id: 10,
      name_ar: 'خضير',
      name_en: 'Khudair',
    },
    {
      city_id: 18326,
      region_id: 10,
      name_ar: 'رديس الشرقية',
      name_en: 'Radis Al Sharqiyyah',
    },
    {
      city_id: 18436,
      region_id: 10,
      name_ar: 'الواسط',
      name_en: 'Al Wasit',
    },
    {
      city_id: 18447,
      region_id: 10,
      name_ar: 'المقدرة',
      name_en: 'Al Maqdarah',
    },
    {
      city_id: 18578,
      region_id: 10,
      name_ar: 'الركبة',
      name_en: 'Al Rukbah',
    },
    {
      city_id: 18634,
      region_id: 10,
      name_ar: 'الكمب رديس',
      name_en: 'Al Kamb Radis',
    },
    {
      city_id: 19913,
      region_id: 10,
      name_ar: 'عذيبه',
      name_en: 'Udhaibah',
    },
    {
      city_id: 19920,
      region_id: 10,
      name_ar: 'الجروف',
      name_en: 'Al Joroof',
    },
    {
      city_id: 19922,
      region_id: 10,
      name_ar: 'الحوراني',
      name_en: 'Al Hurani',
    },
    {
      city_id: 19924,
      region_id: 10,
      name_ar: 'الغفقيه',
      name_en: 'Al Ghafqiyah',
    },
    {
      city_id: 19927,
      region_id: 10,
      name_ar: 'الشعفوليه',
      name_en: 'Ash Shuafuliyah',
    },
    {
      city_id: 19931,
      region_id: 10,
      name_ar: 'الظبرة',
      name_en: 'Al Dhabrah',
    },
    {
      city_id: 19963,
      region_id: 10,
      name_ar: 'المحازره',
      name_en: 'Al Mahazirah',
    },
    {
      city_id: 19966,
      region_id: 10,
      name_ar: 'العقدة',
      name_en: "Al 'Uqdah",
    },
    {
      city_id: 19983,
      region_id: 10,
      name_ar: 'الخضراء',
      name_en: 'Al Khadraa',
    },
    {
      city_id: 19989,
      region_id: 10,
      name_ar: 'المرابي',
      name_en: 'Al Marabi',
    },
    {
      city_id: 20463,
      region_id: 10,
      name_ar: 'حجن',
      name_en: 'Hajan',
    },
    {
      city_id: 21068,
      region_id: 10,
      name_ar: 'سمرة الجد',
      name_en: 'Samrat Al Jed',
    },
    {
      city_id: 21167,
      region_id: 10,
      name_ar: 'منشبة',
      name_en: 'Manshabah',
    },
    {
      city_id: 21168,
      region_id: 10,
      name_ar: 'العرق',
      name_en: 'Al Arq',
    },
    {
      city_id: 21172,
      region_id: 10,
      name_ar: 'المباركة',
      name_en: 'Al Mubarakah',
    },
    {
      city_id: 21175,
      region_id: 10,
      name_ar: 'العكرة',
      name_en: 'Alekarah',
    },
    {
      city_id: 21176,
      region_id: 10,
      name_ar: 'الشمهانية العليا',
      name_en: 'Al Shamhaniyyah Al Ulya',
    },
    {
      city_id: 21177,
      region_id: 10,
      name_ar: 'الشمهانية السفلى',
      name_en: 'Al Shamhaniyyah Al Sufla',
    },
    {
      city_id: 21946,
      region_id: 12,
      name_ar: 'المي الأحمر',
      name_en: 'Al May Al Ahmar',
    },
    {
      city_id: 21948,
      region_id: 12,
      name_ar: 'الغبر الاسفل',
      name_en: 'Al Ghabr Al Asfal',
    },
    {
      city_id: 21978,
      region_id: 12,
      name_ar: 'المرباة',
      name_en: 'Al Marbah',
    },
    {
      city_id: 21981,
      region_id: 12,
      name_ar: 'الفرح',
      name_en: 'Al Farah',
    },
    {
      city_id: 21982,
      region_id: 12,
      name_ar: 'القمع',
      name_en: 'Al Qama',
    },
    {
      city_id: 21984,
      region_id: 12,
      name_ar: 'قذانه',
      name_en: 'Al Qadhanah',
    },
    {
      city_id: 22073,
      region_id: 12,
      name_ar: 'الفصيلة وال مسعود',
      name_en: 'Al Fasilah And Al Masud',
    },
    {
      city_id: 22075,
      region_id: 12,
      name_ar: 'الخاجة',
      name_en: 'Al Khajah',
    },
    {
      city_id: 22076,
      region_id: 12,
      name_ar: 'الحدبان',
      name_en: 'Al Hadban',
    },
    {
      city_id: 22181,
      region_id: 12,
      name_ar: 'القضايا',
      name_en: 'Al Qadaya',
    },
    {
      city_id: 22785,
      region_id: 12,
      name_ar: 'ال سلمان',
      name_en: 'Al Salman',
    },
    {
      city_id: 22799,
      region_id: 12,
      name_ar: 'الضحوات',
      name_en: 'Al-Dahwat',
    },
    {
      city_id: 22804,
      region_id: 12,
      name_ar: 'سبيحة السفلى',
      name_en: 'Sabihah Al Sufla',
    },
    {
      city_id: 22816,
      region_id: 12,
      name_ar: 'الحبشه',
      name_en: 'Al Habashah',
    },
    {
      city_id: 22909,
      region_id: 12,
      name_ar: 'الفقهاء',
      name_en: 'Al Fuqaha',
    },
    {
      city_id: 22916,
      region_id: 12,
      name_ar: 'الغرباء والاثمة',
      name_en: 'Al Gharaba Wal Athimmah',
    },
    {
      city_id: 22920,
      region_id: 12,
      name_ar: 'الصدر',
      name_en: 'As Sadr',
    },
    {
      city_id: 22921,
      region_id: 12,
      name_ar: 'الشريق',
      name_en: 'Al Shareeq',
    },
    {
      city_id: 23385,
      region_id: 13,
      name_ar: 'ابورواث',
      name_en: 'Aburawas',
    },
    {
      city_id: 23386,
      region_id: 13,
      name_ar: 'هديب',
      name_en: 'Hedeb',
    },
    {
      city_id: 23388,
      region_id: 13,
      name_ar: 'الحرة',
      name_en: 'Al Harrah',
    },
    {
      city_id: 23408,
      region_id: 13,
      name_ar: 'أطناب',
      name_en: 'Athnab',
    },
    {
      city_id: 23416,
      region_id: 13,
      name_ar: 'بسيطاء',
      name_en: 'Basithah',
    },
    {
      city_id: 23419,
      region_id: 8,
      name_ar: 'شفا البين',
      name_en: 'Shafa Albayn',
    },
    {
      city_id: 23420,
      region_id: 4,
      name_ar: 'ابرقية',
      name_en: 'Abraqiyah',
    },
    {
      city_id: 23633,
      region_id: 6,
      name_ar: 'بني عمرو',
      name_en: 'Bani Amru',
    },
    {
      city_id: 23640,
      region_id: 2,
      name_ar: 'العرضية الجنوبية',
      name_en: 'Al Ardhiyah Al Janubiyah',
    },
    {
      city_id: 23641,
      region_id: 2,
      name_ar: 'العرضيات',
      name_en: 'Al Ardhiyat',
    },
    {
      city_id: 23642,
      region_id: 2,
      name_ar: 'العرضية الشمالية',
      name_en: 'Al Ardhiyah Al Shimaliyah',
    },
    {
      city_id: 23660,
      region_id: 2,
      name_ar: 'بطاط',
      name_en: 'Batat',
    },
    {
      city_id: 23694,
      region_id: 1,
      name_ar: 'الجوي',
      name_en: 'Al Juwy',
    },
    {
      city_id: 23695,
      region_id: 8,
      name_ar: 'السياييل',
      name_en: 'Al Sayayil',
    },
  ],
  districts: [
    {
      district_id: 10100003001,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العمل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10100003002,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النموذجية',
      name_en: 'Al Namudhajiyah Dist.',
    },
    {
      district_id: 10100003003,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الجرادية',
      name_en: 'Al Jarradiyah Dist.',
    },
    {
      district_id: 10100003004,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'Al Sinaiyah Dist.',
    },
    {
      district_id: 10100003005,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي منفوحة الجديدة',
      name_en: 'Manfuha Al Jadidah Dist.',
    },
    {
      district_id: 10100003006,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفاخرية',
      name_en: 'Al Fakhiriyah Dist.',
    },
    {
      district_id: 10100003007,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Al Dirah Dist.',
    },
    {
      district_id: 10100003008,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ام الحمام الشرقي',
      name_en: 'East Umm Al Hamam Dist.',
    },
    {
      district_id: 10100003009,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الشرفية',
      name_en: 'Al Sharafiyah Dist.',
    },
    {
      district_id: 10100003010,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10100003011,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المعذر الشمالي',
      name_en: 'North Mathar Dist.',
    },
    {
      district_id: 10100003012,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ام الحمام الغربي',
      name_en: 'West Umm Al Hamam Dist.',
    },
    {
      district_id: 10100003013,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 10100003014,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي لبن',
      name_en: 'Laban Dist.',
    },
    {
      district_id: 10100003015,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10100003016,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الشهداء',
      name_en: 'Al Shohda Dist.',
    },
    {
      district_id: 10100003017,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100003018,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السويدي',
      name_en: 'Al Suwaidi Dist.',
    },
    {
      district_id: 10100003019,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10100003020,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي عتيقة',
      name_en: 'Utayqah Dist.',
    },
    {
      district_id: 10100003021,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المربع',
      name_en: 'Al Murabba Dist.',
    },
    {
      district_id: 10100003022,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفلاح',
      name_en: 'Al Falah Dist.',
    },
    {
      district_id: 10100003023,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الندى',
      name_en: 'Al Nada Dist.',
    },
    {
      district_id: 10100003024,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المرسلات',
      name_en: 'Al Mursalat Dist.',
    },
    {
      district_id: 10100003025,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzha Dist.',
    },
    {
      district_id: 10100003026,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Woroud Dist.',
    },
    {
      district_id: 10100003027,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10100003028,
      city_id: 3,
      region_id: 1,
      name_ar: 'المدينة الصناعية الثانية',
      name_en: '2nd Industrial City',
    },
    {
      district_id: 10100003029,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100003030,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المنصورة',
      name_en: 'Al Mansurah Dist.',
    },
    {
      district_id: 10100003031,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي غبيرة',
      name_en: 'Ghobairah Dist.',
    },
    {
      district_id: 10100003032,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farooq Dist.',
    },
    {
      district_id: 10100003033,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100003034,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100003035,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazeerah Dist.',
    },
    {
      district_id: 10100003036,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السعادة',
      name_en: 'Al Saadah Dist.',
    },
    {
      district_id: 10100003037,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الناصرية',
      name_en: 'Al Nasiriyah Dist.',
    },
    {
      district_id: 10100003038,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المناخ',
      name_en: 'Al Manakh Dist.',
    },
    {
      district_id: 10100003039,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدفاع',
      name_en: 'Al Difaa Dist.',
    },
    {
      district_id: 10100003040,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النور',
      name_en: 'Al Noor Dist.',
    },
    {
      district_id: 10100003041,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10100003042,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10100003043,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي صلاح الدين',
      name_en: 'Salahuddin Dist.',
    },
    {
      district_id: 10100003044,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 10100003045,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الوزارات',
      name_en: 'Al Wizarat Dist.',
    },
    {
      district_id: 10100003046,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي سكيرينة',
      name_en: 'Skirinah Dist.',
    },
    {
      district_id: 10100003047,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10100003048,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي جرير',
      name_en: 'Jareer Dist.',
    },
    {
      district_id: 10100003049,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المعذر',
      name_en: 'Al Mathar Dist.',
    },
    {
      district_id: 10100003050,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10100003051,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملز',
      name_en: 'Al Malaz Dist.',
    },
    {
      district_id: 10100003052,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي منفوحة',
      name_en: 'Manfuhah Dist.',
    },
    {
      district_id: 10100003053,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي عليشة',
      name_en: 'Olaishah Dist.',
    },
    {
      district_id: 10100003054,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10100003055,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khaleej Dist.',
    },
    {
      district_id: 10100003056,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الضباط',
      name_en: 'Al Dhubbat Dist.',
    },
    {
      district_id: 10100003057,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السويدي الغربي',
      name_en: 'West Suwaidi Dist.',
    },
    {
      district_id: 10100003058,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ديراب',
      name_en: 'Dirab Dist.',
    },
    {
      district_id: 10100003059,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي احد',
      name_en: 'Ohod Dist.',
    },
    {
      district_id: 10100003060,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي نمار',
      name_en: 'Namar Dist.',
    },
    {
      district_id: 10100003061,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الشفا',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 10100003062,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10100003063,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السليمانية',
      name_en: 'Al Sulaimaniyah Dist.',
    },
    {
      district_id: 10100003064,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10100003065,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي عكاظ',
      name_en: 'Okaz Dist.',
    },
    {
      district_id: 10100003066,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي شبرا',
      name_en: 'Shubra Dist.',
    },
    {
      district_id: 10100003067,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الزهرة',
      name_en: 'Al Zahrah Dist.',
    },
    {
      district_id: 10100003068,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي صياح',
      name_en: 'Siyah Dist.',
    },
    {
      district_id: 10100003069,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 10100003070,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10100003071,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 10100003072,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المصانع',
      name_en: 'Al Masani Dist.',
    },
    {
      district_id: 10100003073,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10100003074,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10100003075,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10100003076,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدريهمية',
      name_en: 'Al Duraihemiyah Dist.',
    },
    {
      district_id: 10100003077,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10100003078,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10100003079,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10100003080,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النسيم الشرقي',
      name_en: 'East Naseem Dist.',
    },
    {
      district_id: 10100003081,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10100003082,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10100003083,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النفل',
      name_en: 'Al Nafel Dist.',
    },
    {
      district_id: 10100003084,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المصيف',
      name_en: 'Al Maseef Dist.',
    },
    {
      district_id: 10100003085,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي التعاون',
      name_en: 'Al Taawun Dist.',
    },
    {
      district_id: 10100003086,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الازدهار',
      name_en: 'Al Ezdihar Dist.',
    },
    {
      district_id: 10100003087,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100003088,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10100003089,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الروابي',
      name_en: 'Al Rawabi Dist.',
    },
    {
      district_id: 10100003090,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الريان',
      name_en: 'Al Rayan Dist.',
    },
    {
      district_id: 10100003091,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ظهرة البديعة',
      name_en: 'Dhahrat Al Badeah Dist.',
    },
    {
      district_id: 10100003092,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النظيم',
      name_en: 'Al Nadheem Dist.',
    },
    {
      district_id: 10100003093,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرماية',
      name_en: 'Al Rimayah Dist.',
    },
    {
      district_id: 10100003094,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي البرية',
      name_en: 'Al Bariyah Dist.',
    },
    {
      district_id: 10100003095,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي طيبة',
      name_en: 'Taybah Dist.',
    },
    {
      district_id: 10100003096,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المنصورية',
      name_en: 'Mansuriyah Dist.',
    },
    {
      district_id: 10100003097,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ضاحية نمار',
      name_en: 'Dahiyat Namar Dist.',
    },
    {
      district_id: 10100003098,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المصفاة',
      name_en: 'Al Misfat Dist.',
    },
    {
      district_id: 10100003099,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السفارات',
      name_en: 'Assafarat Dist.',
    },
    {
      district_id: 10100003100,
      city_id: 3,
      region_id: 1,
      name_ar: 'خشم العان',
      name_en: 'Khashm Al An',
    },
    {
      district_id: 10100003101,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10100003102,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي طويق',
      name_en: 'Tuwaiq Dist.',
    },
    {
      district_id: 10100003103,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العوالي',
      name_en: 'Al Awaly Dist.',
    },
    {
      district_id: 10100003104,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10100003105,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المغرزات',
      name_en: 'Al Mughrazat Dist.',
    },
    {
      district_id: 10100003106,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السلي',
      name_en: 'Al Sulay Dist.',
    },
    {
      district_id: 10100003107,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqeeq Dist.',
    },
    {
      district_id: 10100003108,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakheel Dist.',
    },
    {
      district_id: 10100003109,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadeer Dist.',
    },
    {
      district_id: 10100003110,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100003111,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العود',
      name_en: 'Al Oud Dist.',
    },
    {
      district_id: 10100003112,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ثليم',
      name_en: 'Thulaim Dist.',
    },
    {
      district_id: 10100003113,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الشميسي',
      name_en: 'Al Shumaisi Dist.',
    },
    {
      district_id: 10100003114,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الوشام',
      name_en: 'Al Wisham Dist.',
    },
    {
      district_id: 10100003115,
      city_id: 3,
      region_id: 1,
      name_ar: 'منتزه سلام',
      name_en: 'Salam Park',
    },
    {
      district_id: 10100003116,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدوبية',
      name_en: 'Al Dubiyah Dist.',
    },
    {
      district_id: 10100003117,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي معكال',
      name_en: 'Meakal Dist.',
    },
    {
      district_id: 10100003118,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي جبرة',
      name_en: 'Jabrah Dist.',
    },
    {
      district_id: 10100003119,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي القرى',
      name_en: 'Al Qura Dist.',
    },
    {
      district_id: 10100003120,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المرقب',
      name_en: 'Al Marqab Dist.',
    },
    {
      district_id: 10100003121,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفوطة',
      name_en: 'Al Futah Dist.',
    },
    {
      district_id: 10100003122,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ام سليم',
      name_en: 'Umm Saleem Dist.',
    },
    {
      district_id: 10100003123,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الصحافة',
      name_en: 'Al Sahafah Dist.',
    },
    {
      district_id: 10100003125,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرائد',
      name_en: 'Al Raed Dist.',
    },
    {
      district_id: 10100003126,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العريجاء الغربي',
      name_en: 'West Oraija Dist.',
    },
    {
      district_id: 10100003127,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العريجاء',
      name_en: 'Al Uraija Dist.',
    },
    {
      district_id: 10100003128,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العريجاء الوسطى',
      name_en: 'Middle Oraija Dist.',
    },
    {
      district_id: 10100003129,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10100003130,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدار البيضاء',
      name_en: 'Al Dar Al Baida Dist.',
    },
    {
      district_id: 10100003131,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي البطيحا',
      name_en: 'Al Butaiha Dist.',
    },
    {
      district_id: 10100003132,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الزهراء',
      name_en: 'Al Zahra Dist.',
    },
    {
      district_id: 10100003133,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10100003134,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المؤتمرات',
      name_en: 'Al Mutamarat Dist.',
    },
    {
      district_id: 10100003135,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الوسيطاء',
      name_en: 'Al Wusayta Dist.',
    },
    {
      district_id: 10100003136,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الجنادرية',
      name_en: 'Al Janadriyah Dist.',
    },
    {
      district_id: 10100003137,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي اشبيلية',
      name_en: 'Ishbiliyah Dist.',
    },
    {
      district_id: 10100003138,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المعيزلة',
      name_en: 'Al Maizalah Dist.',
    },
    {
      district_id: 10100003139,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10100003140,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المونسية',
      name_en: 'Al Munisiyah Dist.',
    },
    {
      district_id: 10100003141,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10100003142,
      city_id: 3,
      region_id: 1,
      name_ar: 'عرقة',
      name_en: 'Irqah',
    },
    {
      district_id: 10100003143,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ظهرة لبن',
      name_en: 'Dhahrat Laban Dist.',
    },
    {
      district_id: 10100003144,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي حطين',
      name_en: 'Hiteen Dist.',
    },
    {
      district_id: 10100003145,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الملقا',
      name_en: 'Al Malqa Dist.',
    },
    {
      district_id: 10100003146,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي القيروان',
      name_en: 'Al Qairawan Dist.',
    },
    {
      district_id: 10100003147,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmeen Dist.',
    },
    {
      district_id: 10100003148,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العارض',
      name_en: 'Al Arid Dist.',
    },
    {
      district_id: 10100003149,
      city_id: 3,
      region_id: 1,
      name_ar: 'مطار الملك خالد',
      name_en: 'King Khalid International Airport',
    },
    {
      district_id: 10100003150,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النرجس',
      name_en: 'Al Narjis Dist.',
    },
    {
      district_id: 10100003151,
      city_id: 3,
      region_id: 1,
      name_ar: 'جامعة الامام محمد بن سعود الاسلامية',
      name_en: 'Imam Muhammed Bin Saud Islamic University',
    },
    {
      district_id: 10100003152,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي بنبان',
      name_en: 'Banban Dist.',
    },
    {
      district_id: 10100003153,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرمال',
      name_en: 'Al Rimal Dist.',
    },
    {
      district_id: 10100003155,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10100003156,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدحو',
      name_en: 'Al Dahou Dist.',
    },
    {
      district_id: 10100003157,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العماجية',
      name_en: 'Al Ammajiyah Dist.',
    },
    {
      district_id: 10100003158,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي هيت',
      name_en: 'Hyt Dist.',
    },
    {
      district_id: 10100003159,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الحائر',
      name_en: 'Al Haer Dist.',
    },
    {
      district_id: 10100003161,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي ام الشعال',
      name_en: 'Umm Al Shaal Dist.',
    },
    {
      district_id: 10100003162,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الغنامية',
      name_en: 'Al Ghannamiyah Dist.',
    },
    {
      district_id: 10100003163,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي عريض',
      name_en: 'Oraid Dist.',
    },
    {
      district_id: 10100003164,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10100003165,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المهدية',
      name_en: 'Al Mahdiyah Dist.',
    },
    {
      district_id: 10100003166,
      city_id: 3,
      region_id: 1,
      name_ar: 'جامعة الملك سعود',
      name_en: 'King Saud University',
    },
    {
      district_id: 10100003167,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النسيم الغربي',
      name_en: 'West Naseem Dist.',
    },
    {
      district_id: 10100003168,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المشاعل',
      name_en: 'Al Mishael Dist.',
    },
    {
      district_id: 10100003169,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الندوة',
      name_en: 'Al Nadwah Dist.',
    },
    {
      district_id: 10100003171,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرابية',
      name_en: 'Al Rabiyah Dist.',
    },
    {
      district_id: 10100003172,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي وادي لبن',
      name_en: 'Wady Laban Dist.',
    },
    {
      district_id: 10100003174,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السدرة',
      name_en: 'Al Sidrah Dist.',
    },
    {
      district_id: 10100003175,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي التضامن',
      name_en: 'Al Tadamun Dist.',
    },
    {
      district_id: 10100003176,
      city_id: 3,
      region_id: 1,
      name_ar: 'مدينة الملك عبدالله للطاقة',
      name_en: 'King Abdullah City For Energy',
    },
    {
      district_id: 10100003177,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10100003178,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10100003179,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المجد',
      name_en: 'Al Majd Dist.',
    },
    {
      district_id: 10100003180,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الدانة',
      name_en: 'Al Danah Dist.',
    },
    {
      district_id: 10100003182,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الرسالة',
      name_en: 'Al Risalah Dist.',
    },
    {
      district_id: 10100003183,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الخير',
      name_en: 'Al Khair Dist.',
    },
    {
      district_id: 10100003184,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الفرسان',
      name_en: 'Al Fursan Dist.',
    },
    {
      district_id: 10100003185,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الشعلة',
      name_en: 'Al Sholah Dist.',
    },
    {
      district_id: 10100003186,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الراية',
      name_en: 'Al Rayah Dist.',
    },
    {
      district_id: 10100003187,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الزهور',
      name_en: 'Al Zahour Dist.',
    },
    {
      district_id: 10100003188,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الزاهر',
      name_en: 'Al Zaher Dist.',
    },
    {
      district_id: 10100003189,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المرجان',
      name_en: 'Al Marjan Dist.',
    },
    {
      district_id: 10100003190,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي البيان',
      name_en: 'Al Bayan Dist.',
    },
    {
      district_id: 10100003191,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي العلا',
      name_en: 'Al Ula Dist.',
    },
    {
      district_id: 10100003192,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي المشرق',
      name_en: 'Al Mashriq Dist.',
    },
    {
      district_id: 10100003193,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي النخبة',
      name_en: 'Al Nakhbah Dist.',
    },
    {
      district_id: 10100003194,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي السحاب',
      name_en: 'Al Sahab Dist.',
    },
    {
      district_id: 10100003195,
      city_id: 3,
      region_id: 1,
      name_ar: 'حي الوسام',
      name_en: 'Al Wasam Dist.',
    },
    {
      district_id: 10100024001,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي المرقب',
      name_en: 'Al Marqab Dist.',
    },
    {
      district_id: 10100024002,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10100024003,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10100024004,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100024005,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الملك سلمان',
      name_en: 'King Salman Dist.',
    },
    {
      district_id: 10100024006,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 10100024007,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100024008,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10100024009,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10100024011,
      city_id: 24,
      region_id: 1,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10100024012,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي الجامعيين',
      name_en: 'Al Jamiyin Dist.',
    },
    {
      district_id: 10100024013,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100024014,
      city_id: 24,
      region_id: 1,
      name_ar: 'حي المستقبل',
      name_en: 'Al Mustaqbal Dist.',
    },
    {
      district_id: 10100153002,
      city_id: 153,
      region_id: 1,
      name_ar: 'حي البصيرة',
      name_en: 'Al Basirah Dist.',
    },
    {
      district_id: 10100153003,
      city_id: 153,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100153004,
      city_id: 153,
      region_id: 1,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10100162001,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي البلد القديمة',
      name_en: 'Al Balad Al Qadimah Dist.',
    },
    {
      district_id: 10100162002,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100162003,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي السمرية',
      name_en: 'Al Samriyah Dist.',
    },
    {
      district_id: 10100162004,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي القري',
      name_en: 'Al Qari Dist.',
    },
    {
      district_id: 10100162005,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 10100162006,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10100162007,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي المرقب',
      name_en: 'Al Marqab Dist.',
    },
    {
      district_id: 10100162008,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي شراف',
      name_en: 'Shiraf Dist.',
    },
    {
      district_id: 10100162009,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي النموذجية',
      name_en: 'An Namudhajiyah Dist.',
    },
    {
      district_id: 10100162010,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الجزر',
      name_en: 'Al Jazur Dist.',
    },
    {
      district_id: 10100162011,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الصناعية الجديدة',
      name_en: 'New Industrial Dist.',
    },
    {
      district_id: 10100162012,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الشعيبة',
      name_en: 'Al Shuaibah Dist.',
    },
    {
      district_id: 10100162013,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الوسطة',
      name_en: 'Al Wusta Dist.',
    },
    {
      district_id: 10100162014,
      city_id: 162,
      region_id: 1,
      name_ar: 'حي الشمالية',
      name_en: 'Al Shamalia Dist.',
    },
    {
      district_id: 10100165001,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100165002,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الشبيلية',
      name_en: 'Ash Shibilyah Dist.',
    },
    {
      district_id: 10100165003,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100165004,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الربعة',
      name_en: 'Ar Rabah Dist.',
    },
    {
      district_id: 10100165005,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الدوائر الحكومية',
      name_en: 'Goverment Departments Dist.',
    },
    {
      district_id: 10100165006,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10100165007,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10100165008,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10100165009,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10100165010,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10100165011,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الحلة الطالعية',
      name_en: 'Al Halah At Taliyah Dist.',
    },
    {
      district_id: 10100165012,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10100165013,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10100165014,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100165015,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10100165016,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100165017,
      city_id: 165,
      region_id: 1,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10100167001,
      city_id: 167,
      region_id: 1,
      name_ar: 'مدينة سدير للصناعة والأعمال',
      name_en: 'Sudair Industrial And Business City',
    },
    {
      district_id: 10100167002,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10100167003,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الملك سعود',
      name_en: 'King Saud Dist.',
    },
    {
      district_id: 10100167004,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100167005,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100167006,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100167007,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10100167008,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي منتزه',
      name_en: 'Muntazah Dist.',
    },
    {
      district_id: 10100167010,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100167011,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10100167012,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100167013,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100167014,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10100167015,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 10100167016,
      city_id: 167,
      region_id: 1,
      name_ar: 'حي صناعية تمير',
      name_en: 'Tumair Industrial Dist.',
    },
    {
      district_id: 10100168001,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 10100168002,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10100168003,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الروضه',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100168004,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي السعاده',
      name_en: 'Al Saadah Dist.',
    },
    {
      district_id: 10100168005,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي النهضه',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10100168006,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10100168007,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100168008,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100168009,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10100168010,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10100168011,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10100168012,
      city_id: 168,
      region_id: 1,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10100270001,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي حطين',
      name_en: 'Hiteen Dist.',
    },
    {
      district_id: 10100270002,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10100270003,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100270004,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100270005,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10100270006,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100270007,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100270008,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10100270009,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الريان',
      name_en: 'Ar Riyan Dist.',
    },
    {
      district_id: 10100270010,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100270011,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي مرخ',
      name_en: 'Margh  Dist.',
    },
    {
      district_id: 10100270012,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100270013,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10100270014,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي احد',
      name_en: 'Uhd Dist.',
    },
    {
      district_id: 10100270015,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي طويق',
      name_en: 'Tuwik Dist.',
    },
    {
      district_id: 10100270016,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouq Dist.',
    },
    {
      district_id: 10100270017,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الصديق',
      name_en: 'As Sediq Dist.',
    },
    {
      district_id: 10100270018,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmok Dist.',
    },
    {
      district_id: 10100270020,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10100270021,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي علقة',
      name_en: 'Alkah Dist.',
    },
    {
      district_id: 10100270022,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الدرعية',
      name_en: 'Al Deriyah Dist.',
    },
    {
      district_id: 10100270023,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي المنتزة',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10100270024,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100270025,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي سمنان',
      name_en: 'Semnan Dist.',
    },
    {
      district_id: 10100270026,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10100270027,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي السيح',
      name_en: 'As Sieh Dist.',
    },
    {
      district_id: 10100270028,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي عريعرة',
      name_en: 'Urierah Dist.',
    },
    {
      district_id: 10100270029,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10100270030,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10100270031,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10100270032,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 10100270033,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10100270034,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي اشبيليا',
      name_en: 'Ishbiliya Dist.',
    },
    {
      district_id: 10100270035,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10100270036,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100270037,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10100270038,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 10100270039,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 10100270040,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10100270041,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي النفل',
      name_en: 'An Naql Dist.',
    },
    {
      district_id: 10100270042,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10100270043,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الفردوس',
      name_en: 'Al Fardus Dist.',
    },
    {
      district_id: 10100270044,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10100270045,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10100270046,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 10100270047,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الفروسية',
      name_en: 'Al Frosyah Dist.',
    },
    {
      district_id: 10100270048,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي السيح',
      name_en: 'As Sieh Dist.',
    },
    {
      district_id: 10100270049,
      city_id: 270,
      region_id: 1,
      name_ar: 'الجوى',
      name_en: 'Al Jawa',
    },
    {
      district_id: 10100270050,
      city_id: 270,
      region_id: 1,
      name_ar: 'حي الملك سلمان',
      name_en: 'King Salman Dist.',
    },
    {
      district_id: 10100306001,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10100306002,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10100306003,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100306004,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10100306005,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي المصيف',
      name_en: 'Al Masiaf Dist.',
    },
    {
      district_id: 10100306006,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100306007,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10100306008,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10100306009,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10100306010,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100306011,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي النفل',
      name_en: 'An Naql Dist.',
    },
    {
      district_id: 10100306012,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10100306013,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10100306014,
      city_id: 306,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100418001,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي شمال الاذاعة',
      name_en: 'Al Idhaah North Dist.',
    },
    {
      district_id: 10100418002,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي المعارض',
      name_en: 'Al Maarid Dist.',
    },
    {
      district_id: 10100418003,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الاذاعة',
      name_en: 'Al Idhaah Dist.',
    },
    {
      district_id: 10100418004,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100418005,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الملز',
      name_en: 'Al Malaz Dist.',
    },
    {
      district_id: 10100418006,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الاصفر',
      name_en: 'Al Asfar Dist.',
    },
    {
      district_id: 10100418007,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الحجاج',
      name_en: 'Al Hujjaj Dist.',
    },
    {
      district_id: 10100418008,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي المهنيين',
      name_en: 'Al Mihaniyin Dist.',
    },
    {
      district_id: 10100418009,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100418010,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal  Dist.',
    },
    {
      district_id: 10100418011,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10100418012,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي العود',
      name_en: 'Al Uud Dist.',
    },
    {
      district_id: 10100418013,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي المنفوحة',
      name_en: 'Al Manfuhah Dist.',
    },
    {
      district_id: 10100418014,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100418015,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10100418016,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي الملك خالد',
      name_en: 'King Khalid  Dist.',
    },
    {
      district_id: 10100418017,
      city_id: 418,
      region_id: 1,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaymaniyah Dist.',
    },
    {
      district_id: 10100418018,
      city_id: 418,
      region_id: 1,
      name_ar: 'الجامعية',
      name_en: 'Al Jamieyah',
    },
    {
      district_id: 10100443001,
      city_id: 443,
      region_id: 1,
      name_ar: 'حي الديرة القديمة',
      name_en: 'Ad Dirah Al Qadimah Dist.',
    },
    {
      district_id: 10100443002,
      city_id: 443,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100443003,
      city_id: 443,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100443004,
      city_id: 443,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100443005,
      city_id: 443,
      region_id: 1,
      name_ar: 'حي الجنادرية',
      name_en: 'Al Janadriyah Dist.',
    },
    {
      district_id: 10100500001,
      city_id: 500,
      region_id: 1,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10100500002,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي غسلة',
      name_en: 'Gaslah Dist.',
    },
    {
      district_id: 10100500003,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الوقف',
      name_en: 'Al Wakf Dist.',
    },
    {
      district_id: 10100500004,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10100500005,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100500006,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100500007,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10100500008,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الوشم',
      name_en: 'Al Washm Dist.',
    },
    {
      district_id: 10100500009,
      city_id: 500,
      region_id: 1,
      name_ar: 'حي الرحبة',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10100574001,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100574002,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100574003,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100574004,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10100574005,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100574006,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10100574007,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100574008,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الشرفية',
      name_en: 'Ash Sharafiyah Dist.',
    },
    {
      district_id: 10100574009,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100574010,
      city_id: 574,
      region_id: 1,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10100669001,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي مشرفة',
      name_en: 'Mishrifah Dist.',
    },
    {
      district_id: 10100669002,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10100669003,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي حطين',
      name_en: 'Hiteen Dist.',
    },
    {
      district_id: 10100669004,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100669005,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الحرمين',
      name_en: 'Al Haramain Dist.',
    },
    {
      district_id: 10100669006,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10100669007,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij  Dist.',
    },
    {
      district_id: 10100669008,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10100669009,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100669010,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100669011,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10100669012,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10100669013,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100669014,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10100669015,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100669016,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100669017,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100669018,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10100669019,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي السنبلة',
      name_en: 'As Sunbulah Dist.',
    },
    {
      district_id: 10100669020,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي الدرع',
      name_en: 'Ad Dar Dist.',
    },
    {
      district_id: 10100669021,
      city_id: 669,
      region_id: 1,
      name_ar: 'منطقة الاستراحات',
      name_en: 'Rest House Area',
    },
    {
      district_id: 10100669022,
      city_id: 669,
      region_id: 1,
      name_ar: 'حي مصده',
      name_en: 'Musaddah Dist.',
    },
    {
      district_id: 10100684001,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي الشمالي',
      name_en: 'Al Shamali Dist.',
    },
    {
      district_id: 10100684002,
      city_id: 684,
      region_id: 1,
      name_ar: 'حى الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10100684003,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 10100684004,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10100684005,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100684006,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100684007,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10100684008,
      city_id: 684,
      region_id: 1,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10100701001,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'Al Sinaiyah Dist.',
    },
    {
      district_id: 10100701002,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي فيضة الفوازين',
      name_en: 'Faydat Al Fawazin Dist.',
    },
    {
      district_id: 10100701003,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي التسعين',
      name_en: 'Al Teseen Dist.',
    },
    {
      district_id: 10100701004,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الذيابية',
      name_en: 'Al Diyabiyah Dist.',
    },
    {
      district_id: 10100701005,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي برزان',
      name_en: 'Barzan Dist.',
    },
    {
      district_id: 10100701006,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100701007,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي العقيشية',
      name_en: 'Al Aqishiyah Dist.',
    },
    {
      district_id: 10100701008,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10100701009,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100701010,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي المسلخ',
      name_en: 'Al Maslakh Dist.',
    },
    {
      district_id: 10100701011,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي المثوية',
      name_en: 'Al Mathawiyah Dist.',
    },
    {
      district_id: 10100701012,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي التضامن',
      name_en: 'Al Tadamun Dist.',
    },
    {
      district_id: 10100701013,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي مركز الشرارة',
      name_en: 'Markaz Al Shararh Dist.',
    },
    {
      district_id: 10100701014,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10100701015,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10100701016,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي مشرفة',
      name_en: 'Mishrifah Dist.',
    },
    {
      district_id: 10100701017,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10100701018,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي صقرة',
      name_en: 'Saqrah Dist.',
    },
    {
      district_id: 10100701019,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي صقرة الشمالي',
      name_en: 'Saqrah Northern Dist.',
    },
    {
      district_id: 10100701020,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الرديفة',
      name_en: 'Al Radifah Dist.',
    },
    {
      district_id: 10100701021,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 10100701022,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الزعيبة',
      name_en: 'Al Zuaibah Dist.',
    },
    {
      district_id: 10100701023,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الامير سلطان',
      name_en: 'Prince Sultan Dist.',
    },
    {
      district_id: 10100701024,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100701025,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي صحارى',
      name_en: 'Sahara Dist.',
    },
    {
      district_id: 10100701026,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي العلوات',
      name_en: 'Al Alawat Dist.',
    },
    {
      district_id: 10100701027,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي الشتينية',
      name_en: 'Al Shatiniyyah',
    },
    {
      district_id: 10100701028,
      city_id: 701,
      region_id: 1,
      name_ar: 'حي القسيمة',
      name_en: 'Al Qassima Dist.',
    },
    {
      district_id: 10100795001,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10100795002,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10100795003,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي السد',
      name_en: 'As Sad Dist.',
    },
    {
      district_id: 10100795004,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10100795005,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100795006,
      city_id: 795,
      region_id: 1,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10100828001,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الملقاه',
      name_en: 'Al Malqah Dist.',
    },
    {
      district_id: 10100828002,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100828003,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الثليما',
      name_en: 'Al Thilaima Dist.',
    },
    {
      district_id: 10100828004,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي السلمانية',
      name_en: 'Al Salmaniyah Dist.',
    },
    {
      district_id: 10100828005,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الملك',
      name_en: 'Al Malik Dist.',
    },
    {
      district_id: 10100828006,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الدرعية',
      name_en: 'Al Diriyah Dist.',
    },
    {
      district_id: 10100828007,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100828008,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي العاصمة',
      name_en: 'Al Asemah Dist.',
    },
    {
      district_id: 10100828010,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الطريف',
      name_en: 'Al Turaif Dist.',
    },
    {
      district_id: 10100828011,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الروقية',
      name_en: 'Al Rooqiyah Dist.',
    },
    {
      district_id: 10100828012,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي السرحية',
      name_en: 'Al Sarhiyah Dist.',
    },
    {
      district_id: 10100828013,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي الشهداء',
      name_en: 'Al Shohda Dist.',
    },
    {
      district_id: 10100828014,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي ظهرة العودة غرب',
      name_en: 'West Dhahrat Al Oudah Dist.',
    },
    {
      district_id: 10100828015,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي البليدا',
      name_en: 'Al Blaida Dist.',
    },
    {
      district_id: 10100828016,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي العلب',
      name_en: 'Al Olab Dist.',
    },
    {
      district_id: 10100828017,
      city_id: 828,
      region_id: 1,
      name_ar: 'سلطانة',
      name_en: 'Sultanah',
    },
    {
      district_id: 10100828018,
      city_id: 828,
      region_id: 1,
      name_ar: 'حي ظهرة العودة شرق',
      name_en: 'East Dhahrat Al Oudah Dist.',
    },
    {
      district_id: 10100830001,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي العين',
      name_en: 'Al Ain Dist.',
    },
    {
      district_id: 10100830002,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي سلام',
      name_en: 'Salam Dist.',
    },
    {
      district_id: 10100830003,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي العدل',
      name_en: 'Al Adel Dist.',
    },
    {
      district_id: 10100830004,
      city_id: 830,
      region_id: 1,
      name_ar: 'الكلية الحربية',
      name_en: 'Military College',
    },
    {
      district_id: 10100830005,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي عقرباء',
      name_en: 'Aqraba Dist.',
    },
    {
      district_id: 10100830006,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10100830007,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10100830008,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي الوصيل',
      name_en: 'Al Waseel Dist.',
    },
    {
      district_id: 10100830009,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10100830010,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي هجرة بوضة',
      name_en: 'Bawdah Dist.',
    },
    {
      district_id: 10100830011,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي نجد',
      name_en: 'Najd Dist.',
    },
    {
      district_id: 10100830012,
      city_id: 830,
      region_id: 1,
      name_ar: 'حي الامير سلطان',
      name_en: 'Prince Sultan Dist.',
    },
    {
      district_id: 10100839001,
      city_id: 839,
      region_id: 1,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10100839002,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10100839003,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100839004,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100839005,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي الورود 1',
      name_en: 'Al Wurud 1 Dist.',
    },
    {
      district_id: 10100839006,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10100839007,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي المنتزة',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10100839008,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي البديع الغربي',
      name_en: 'Al Badi Al Gharbi Dist.',
    },
    {
      district_id: 10100839009,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي العنقري',
      name_en: 'Al Anqari Dist.',
    },
    {
      district_id: 10100839010,
      city_id: 839,
      region_id: 1,
      name_ar: 'حي البديع الشرقي',
      name_en: 'Al Badi Ash Sharqi Dist.',
    },
    {
      district_id: 10100880001,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي ابو سليم',
      name_en: 'Abu Salim Dist.',
    },
    {
      district_id: 10100880002,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10100880003,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10100880004,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100880005,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10100880006,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10100880007,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10100880008,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10100880009,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10100880010,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10100880011,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10100880012,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10100880013,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10100880014,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الجفونية',
      name_en: 'Al Jaffuniyyah Dist.',
    },
    {
      district_id: 10100880015,
      city_id: 880,
      region_id: 1,
      name_ar: 'حي الضريب',
      name_en: 'Ad Darib Dist.',
    },
    {
      district_id: 10100885001,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10100885002,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الشفاء',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 10100885003,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10100885004,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي البنفسج',
      name_en: 'Al Banafsaj Dist.',
    },
    {
      district_id: 10100885005,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10100885006,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10100885007,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي السليمانية',
      name_en: 'Al Sulaymaniyah Dist.',
    },
    {
      district_id: 10100885008,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10100885009,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الوفاء',
      name_en: 'Al Wafa Dist.',
    },
    {
      district_id: 10100885010,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10100885011,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10100885012,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzhah Dist.',
    },
    {
      district_id: 10100885013,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الدار البيضاء',
      name_en: 'Al Dar Al Baida Dist.',
    },
    {
      district_id: 10100885014,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الدوحة',
      name_en: 'Al Dohah Dist.',
    },
    {
      district_id: 10100885015,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الفرعة',
      name_en: 'Al Farah Dist.',
    },
    {
      district_id: 10100885016,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10100885017,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الشعيبة',
      name_en: 'Al Shuaibah Dist.',
    },
    {
      district_id: 10100885018,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي المريقب',
      name_en: 'Al Muraiqeb Dist.',
    },
    {
      district_id: 10100885019,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10100885020,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي الدهناء',
      name_en: 'Al Dahna Dist.',
    },
    {
      district_id: 10100885021,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي أبا العبلان2',
      name_en: 'Aba Al Ablan 2 Dist.',
    },
    {
      district_id: 10100885022,
      city_id: 885,
      region_id: 1,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10100885023,
      city_id: 885,
      region_id: 1,
      name_ar: 'حي أبا العبلان1',
      name_en: 'Aba Al Ablan 1 Dist.',
    },
    {
      district_id: 10100885024,
      city_id: 885,
      region_id: 1,
      name_ar: 'مخطط ج3',
      name_en: 'Subdivision Plan 3c',
    },
    {
      district_id: 10100885025,
      city_id: 885,
      region_id: 1,
      name_ar: 'مخطط ج1',
      name_en: 'Subdivision Plan 1c',
    },
    {
      district_id: 10100885026,
      city_id: 885,
      region_id: 1,
      name_ar: 'مخطط ج2',
      name_en: 'Subdivision Plan 2c',
    },
    {
      district_id: 10100885027,
      city_id: 885,
      region_id: 1,
      name_ar: 'مخطط ج4',
      name_en: 'Subdivision Plan 4c',
    },
    {
      district_id: 10100959001,
      city_id: 959,
      region_id: 1,
      name_ar: 'حي ام طلحة',
      name_en: 'Um Talha Dist.',
    },
    {
      district_id: 10100962001,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي نجد',
      name_en: 'Najd Dist.',
    },
    {
      district_id: 10100962002,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي ال هويمل',
      name_en: 'Al Hoymel Dist.',
    },
    {
      district_id: 10100962003,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الهفوف',
      name_en: 'Al Hufuf Dist.',
    },
    {
      district_id: 10100962004,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الوسيطا',
      name_en: 'Al Wusayta Dist.',
    },
    {
      district_id: 10100962005,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرين الاعلى',
      name_en: 'Ar Rayn Al Aala Dist.',
    },
    {
      district_id: 10100962006,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي المعيزيله',
      name_en: 'Al Muayzeleh Dist.',
    },
    {
      district_id: 10100962007,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي العمار',
      name_en: 'Al Amar Dist.',
    },
    {
      district_id: 10100962008,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي المعتلا',
      name_en: 'Al Maatala Dist.',
    },
    {
      district_id: 10100962009,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي قبيبان',
      name_en: 'Qubeiban Dist.',
    },
    {
      district_id: 10100962010,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10100962011,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي عشيرة',
      name_en: 'Ashayra Dist.',
    },
    {
      district_id: 10100962012,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الغريبية',
      name_en: 'Al Gharibiya Dist.',
    },
    {
      district_id: 10100962013,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10100962014,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10100962015,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي القميش',
      name_en: 'Al Qumish Dist.',
    },
    {
      district_id: 10100962016,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرفايع',
      name_en: 'Ar Rafayie Dist.',
    },
    {
      district_id: 10100962017,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرجع',
      name_en: 'Al Rajai Dist.',
    },
    {
      district_id: 10100962018,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الوسطى',
      name_en: 'Al Wusta Dist.',
    },
    {
      district_id: 10100962019,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي عبلية',
      name_en: 'Ableya Dist.',
    },
    {
      district_id: 10100962020,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي النور',
      name_en: 'An Noor Dist.',
    },
    {
      district_id: 10100962021,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10100962022,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الشعيفان',
      name_en: 'Al Shaeifan Dist.',
    },
    {
      district_id: 10100962023,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الريعانية',
      name_en: 'Al Rayaniyah Dist.',
    },
    {
      district_id: 10100962024,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الحنو',
      name_en: 'Al Hano Dist.',
    },
    {
      district_id: 10100962025,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرواما',
      name_en: 'Al Rawama Dist.',
    },
    {
      district_id: 10100962026,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي المثناه',
      name_en: 'Al Muthanna Dist.',
    },
    {
      district_id: 10100962027,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الفرعة',
      name_en: 'Al Fara Dist.',
    },
    {
      district_id: 10100962028,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الفردوس',
      name_en: 'Al Fardus Dist.',
    },
    {
      district_id: 10100962029,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرفاع',
      name_en: 'Ar Rifaa Dist.',
    },
    {
      district_id: 10100962030,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الناصفه',
      name_en: 'Al Nasfah Dist.',
    },
    {
      district_id: 10100962031,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10100962032,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي المعترضة',
      name_en: 'Al Mutarida Dist.',
    },
    {
      district_id: 10100962033,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي احد',
      name_en: 'Uhud Dist.',
    },
    {
      district_id: 10100962034,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي النقيعه',
      name_en: 'Al Naqiea Dist.',
    },
    {
      district_id: 10100962035,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي البدائع',
      name_en: 'Al Badayi Dist.',
    },
    {
      district_id: 10100962036,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10100962037,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10100962038,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الشموع',
      name_en: 'Al Shumua Dist.',
    },
    {
      district_id: 10100962039,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10100962040,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10100962041,
      city_id: 962,
      region_id: 1,
      name_ar: 'حي الرين القديم',
      name_en: 'Ar Rayn Al Qadim Dist.',
    },
    {
      district_id: 10100990001,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'Al Sinaiyah Dist.',
    },
    {
      district_id: 10100990002,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الحزيمية',
      name_en: 'Al Huzaymiyyah Dist.',
    },
    {
      district_id: 10100990003,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الصقورية',
      name_en: 'Al Suquriyyah Dist.',
    },
    {
      district_id: 10100990004,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10100990005,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي شخيب',
      name_en: 'Shukhib Dist.',
    },
    {
      district_id: 10100990006,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي طويق',
      name_en: 'Tuwik Dist.',
    },
    {
      district_id: 10100990007,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10100990008,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي غرناطه',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10100990009,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10100990010,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10100990011,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي نواره',
      name_en: 'Nawarah Dist.',
    },
    {
      district_id: 10100990012,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي وسيلة',
      name_en: 'Waselah Dist.',
    },
    {
      district_id: 10100990013,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي اللؤلؤة',
      name_en: 'Al Luluah Dist.',
    },
    {
      district_id: 10100990014,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الراشدية',
      name_en: 'Al Rashidiyah Dist.',
    },
    {
      district_id: 10100990015,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الغطغط',
      name_en: 'Al Ghtght Dist.',
    },
    {
      district_id: 10100990016,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الريف',
      name_en: 'Al Rif Dist.',
    },
    {
      district_id: 10100990018,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10100990019,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10100990020,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10100990021,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي مشيرفة',
      name_en: 'Al Musherfah Dist.',
    },
    {
      district_id: 10100990022,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10100990023,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10100990024,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10100990025,
      city_id: 990,
      region_id: 1,
      name_ar: 'حي الشلال',
      name_en: 'Al Shlal Dist.',
    },
    {
      district_id: 10101061001,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي المصانع',
      name_en: 'Al Masani Dist.',
    },
    {
      district_id: 10101061002,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي البركة',
      name_en: 'Al Barakah Dist.',
    },
    {
      district_id: 10101061003,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي المنتزة',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10101061004,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الصناعية الجديدة',
      name_en: 'New Industrial Dist.',
    },
    {
      district_id: 10101061005,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaymaniyah Dist.',
    },
    {
      district_id: 10101061006,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10101061007,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي البرج',
      name_en: 'Al Burj Dist.',
    },
    {
      district_id: 10101061008,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10101061009,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10101061010,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10101061011,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10101061012,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10101061013,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10101061014,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10101061015,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي مشرف',
      name_en: 'Mishrif Dist.',
    },
    {
      district_id: 10101061016,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الراشدية',
      name_en: 'Ar Rashidiyyah Dist.',
    },
    {
      district_id: 10101061017,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي المنيفية',
      name_en: 'Al Munyfiyah Dist.',
    },
    {
      district_id: 10101061018,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الناصفة',
      name_en: 'An Nasifah Dist.',
    },
    {
      district_id: 10101061019,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 10101061020,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي المنصورة',
      name_en: 'Al Mansurah Dist.',
    },
    {
      district_id: 10101061021,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10101061022,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10101061023,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10101061024,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10101061025,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10101061026,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الزاهر',
      name_en: 'Az Zahir Dist.',
    },
    {
      district_id: 10101061027,
      city_id: 1061,
      region_id: 1,
      name_ar: 'منطقة صناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10101061028,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي العالية',
      name_en: 'Al Aliyah Dist.',
    },
    {
      district_id: 10101061029,
      city_id: 1061,
      region_id: 1,
      name_ar: 'الهياثم',
      name_en: 'Al Hayathim',
    },
    {
      district_id: 10101061030,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي العدامة',
      name_en: 'Al Adamah Dist.',
    },
    {
      district_id: 10101061031,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي القطار',
      name_en: 'Al Qitar Dist.',
    },
    {
      district_id: 10101061032,
      city_id: 1061,
      region_id: 1,
      name_ar: 'المدينة الصناعية بالخرج',
      name_en: 'Al Kharj Industrial City',
    },
    {
      district_id: 10101061033,
      city_id: 1061,
      region_id: 1,
      name_ar: 'قاعدة الامير سلطان الجوية',
      name_en: 'Prince Sultan Air Base',
    },
    {
      district_id: 10101061034,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10101061035,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي جوهرة الخرج',
      name_en: 'Jawharat Al Kharj Dist.',
    },
    {
      district_id: 10101061036,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10101061037,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي السحاب',
      name_en: 'As Sahab Dist.',
    },
    {
      district_id: 10101061038,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10101061039,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الاخضر',
      name_en: 'Al Akhdar Dist.',
    },
    {
      district_id: 10101061040,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10101061041,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10101061042,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10101061043,
      city_id: 1061,
      region_id: 1,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10101073001,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10101073002,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10101073003,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaymaniyah Dist.',
    },
    {
      district_id: 10101073004,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي سدير',
      name_en: 'Sudayr Dist.',
    },
    {
      district_id: 10101073005,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي السعيدان',
      name_en: 'As Saydan Dist.',
    },
    {
      district_id: 10101073006,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي السماري',
      name_en: 'As Samari Dist.',
    },
    {
      district_id: 10101073007,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي العويمرية',
      name_en: 'Al Uwaymiriyah Dist.',
    },
    {
      district_id: 10101073008,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي الصحنة',
      name_en: 'Al Sahnah Dist.',
    },
    {
      district_id: 10101073009,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي العذراء',
      name_en: 'Al Adhra Dist.',
    },
    {
      district_id: 10101073010,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10101073011,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي الناصرية',
      name_en: 'An Nasriyah Dist.',
    },
    {
      district_id: 10101073012,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي زميقة',
      name_en: 'Zamiqa Dist.',
    },
    {
      district_id: 10101073013,
      city_id: 1073,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10101351001,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الخماسين الشمالي',
      name_en: 'Al Khamasin Al Shamali Dist.',
    },
    {
      district_id: 10101351002,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي اللدام الجنوبي',
      name_en: 'Al Ludam Al Janubi Dist.',
    },
    {
      district_id: 10101351003,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الحديدية',
      name_en: 'Al Hadidiyah Dist.',
    },
    {
      district_id: 10101351004,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي القيروان',
      name_en: 'Al Qayrawan Dist.',
    },
    {
      district_id: 10101351005,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10101351006,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10101351007,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي مقابل',
      name_en: 'Muqabil Dist.',
    },
    {
      district_id: 10101351008,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي صناعية الحديدية',
      name_en: 'Sinaiyat Al Hadidiyah Dist.',
    },
    {
      district_id: 10101351009,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي التقنية',
      name_en: 'Al Taqniyah Dist.',
    },
    {
      district_id: 10101351010,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الرويساء',
      name_en: 'Al Ruwaysa Dist.',
    },
    {
      district_id: 10101351011,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي اللدام الشمالي',
      name_en: 'Al Ludam Al Shamali Dist.',
    },
    {
      district_id: 10101351012,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي آل عويمر',
      name_en: 'Al Uwaimir Dist.',
    },
    {
      district_id: 10101351013,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الخماسين',
      name_en: 'Al Khamasin Dist.',
    },
    {
      district_id: 10101351014,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الولامين',
      name_en: 'Al Walamin Dist.',
    },
    {
      district_id: 10101351015,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي المعتلاء',
      name_en: 'Al Muatala Dist.',
    },
    {
      district_id: 10101351016,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي صناعية الشرفا',
      name_en: 'Al Shurafa Industrial Dist.',
    },
    {
      district_id: 10101351017,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الشرفا',
      name_en: 'Al Shurafa Dist.',
    },
    {
      district_id: 10101351018,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي ال عريمة',
      name_en: 'Al Uraimah Dist.',
    },
    {
      district_id: 10101351019,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الزويراء',
      name_en: 'Al Zuwayra Dist.',
    },
    {
      district_id: 10101351020,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10101351021,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzha Dist.',
    },
    {
      district_id: 10101351022,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10101351023,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي نزوى',
      name_en: 'Nazwa Dist.',
    },
    {
      district_id: 10101351024,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10101351025,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10101351026,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي ال تميم و الصالحية',
      name_en: 'Al Tamim Wal Salihiyyah Dist.',
    },
    {
      district_id: 10101351027,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي المدرسة العسكرية',
      name_en: 'Military School Dist.',
    },
    {
      district_id: 10101351028,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10101351029,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الحنابجة',
      name_en: 'Al Hanabijah Dist.',
    },
    {
      district_id: 10101351030,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10101351031,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي المعتلاء الجنوبي',
      name_en: 'Al Muatala Al Janubi Dist.',
    },
    {
      district_id: 10101351032,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي النويعمة',
      name_en: 'Al Nuwayemah Dist.',
    },
    {
      district_id: 10101351033,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي القويز',
      name_en: 'Al Quwaiz Dist.',
    },
    {
      district_id: 10101351034,
      city_id: 1351,
      region_id: 1,
      name_ar: 'الحي الغربي',
      name_en: 'Al Gharbi Dist.',
    },
    {
      district_id: 10101351035,
      city_id: 1351,
      region_id: 1,
      name_ar: 'حي المستشفي',
      name_en: 'Al Mustashfa Dist.',
    },
    {
      district_id: 10101361001,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10101361002,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10101361003,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10101361004,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10101361005,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 10101361006,
      city_id: 1361,
      region_id: 1,
      name_ar: 'حي الزهرة',
      name_en: 'Az Zahrah Dist.',
    },
    {
      district_id: 10103158001,
      city_id: 3158,
      region_id: 1,
      name_ar: 'حي المدينة الجديدة',
      name_en: 'Al Madinah Al Jadidah Dist.',
    },
    {
      district_id: 10103158002,
      city_id: 3158,
      region_id: 1,
      name_ar: 'حي الركية',
      name_en: 'Ar Rukiyah Dist.',
    },
    {
      district_id: 10103158003,
      city_id: 3158,
      region_id: 1,
      name_ar: 'حي الحزيمية',
      name_en: 'Al Huzaimiyah Dist.',
    },
    {
      district_id: 10103158004,
      city_id: 3158,
      region_id: 1,
      name_ar: 'وسط المدينة',
      name_en: 'City Center',
    },
    {
      district_id: 10103158005,
      city_id: 3158,
      region_id: 1,
      name_ar: 'حي العثمانية',
      name_en: 'Al Uthmaniyah Dist.',
    },
    {
      district_id: 10103158006,
      city_id: 3158,
      region_id: 1,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10103161001,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10103161002,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي خضار',
      name_en: 'Khudar Dist.',
    },
    {
      district_id: 10103161003,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الباطن',
      name_en: 'Al Batin Dist.',
    },
    {
      district_id: 10103161004,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10103161005,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الشعيب',
      name_en: 'Ash Shuaib Dist.',
    },
    {
      district_id: 10103161006,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي زياد',
      name_en: 'Ziyad Dist.',
    },
    {
      district_id: 10103161007,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الفرعة',
      name_en: 'Al Farah Dist.',
    },
    {
      district_id: 10103161008,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي التضامن',
      name_en: 'Al Tadamun Dist.',
    },
    {
      district_id: 10103161009,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10103161010,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي المجد',
      name_en: 'Al Majd  Dist.',
    },
    {
      district_id: 10103161011,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي المصيف',
      name_en: 'Al Masiaf Dist.',
    },
    {
      district_id: 10103161012,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الصدر',
      name_en: 'Al Sadr Dist.',
    },
    {
      district_id: 10103161013,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10103161014,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10103161015,
      city_id: 3161,
      region_id: 1,
      name_ar: 'حي الحلة',
      name_en: 'Al Hilah Dist.',
    },
    {
      district_id: 10103165001,
      city_id: 3165,
      region_id: 1,
      name_ar: 'الحلوة',
      name_en: 'Al Hilwah',
    },
    {
      district_id: 10103165002,
      city_id: 3165,
      region_id: 1,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10103174001,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10103174002,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي السحاب',
      name_en: 'As Sahab Dist.',
    },
    {
      district_id: 10103174003,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10103174004,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الدوائر',
      name_en: 'Ad Dawair Dist.',
    },
    {
      district_id: 10103174005,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10103174006,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10103174007,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الجفيدرية',
      name_en: 'Al Jufaidariyah Dist.',
    },
    {
      district_id: 10103174008,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي غصيبة',
      name_en: 'Ghusaiba Dist.',
    },
    {
      district_id: 10103174009,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي المبرز',
      name_en: 'Al Mubarraz Dist.',
    },
    {
      district_id: 10103174010,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي السعودية',
      name_en: 'As Saudia Dist.',
    },
    {
      district_id: 10103174011,
      city_id: 3174,
      region_id: 1,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10103174035,
      city_id: 3174,
      region_id: 1,
      name_ar: 'بدائع الهواملة',
      name_en: 'Badai Al Hawamilah',
    },
    {
      district_id: 10200005001,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج10',
      name_en: 'C10 Dist.',
    },
    {
      district_id: 10200005002,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ضاحية الاسكان',
      name_en: 'Dhahiyat Al Iskan  Dist.',
    },
    {
      district_id: 10200005003,
      city_id: 5,
      region_id: 2,
      name_ar: 'منطقة إمتداد ضاحية الإسكان',
      name_en: 'Dhahiyat Al Iskan Extension Area',
    },
    {
      district_id: 10200005004,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السيل الكبير',
      name_en: 'As Sail Al Kabeer  Dist.',
    },
    {
      district_id: 10200005005,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الهدا',
      name_en: 'Al Huda  Dist.',
    },
    {
      district_id: 10200005006,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa  Dist.',
    },
    {
      district_id: 10200005007,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج3',
      name_en: 'C3 Dist.',
    },
    {
      district_id: 10200005008,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج4',
      name_en: 'C4 Dist.',
    },
    {
      district_id: 10200005009,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج5',
      name_en: 'C5 Dist.',
    },
    {
      district_id: 10200005010,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج43',
      name_en: 'C43 Dist.',
    },
    {
      district_id: 10200005011,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج16',
      name_en: 'C16 Dist.',
    },
    {
      district_id: 10200005012,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج18',
      name_en: 'C18 Dist.',
    },
    {
      district_id: 10200005013,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج11',
      name_en: 'C11 Dist.',
    },
    {
      district_id: 10200005014,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج12',
      name_en: 'C12 Dist.',
    },
    {
      district_id: 10200005015,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج15',
      name_en: 'C15 Dist.',
    },
    {
      district_id: 10200005016,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج17',
      name_en: 'C17 Dist.',
    },
    {
      district_id: 10200005017,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج22',
      name_en: 'C22 Dist.',
    },
    {
      district_id: 10200005018,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج23',
      name_en: 'C23 Dist.',
    },
    {
      district_id: 10200005019,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج25',
      name_en: 'C25 Dist.',
    },
    {
      district_id: 10200005020,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج26',
      name_en: 'C26 Dist.',
    },
    {
      district_id: 10200005021,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج28',
      name_en: 'C28 Dist.',
    },
    {
      district_id: 10200005022,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج29',
      name_en: 'C29 Dist.',
    },
    {
      district_id: 10200005023,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي معشي',
      name_en: 'Maashi  Dist.',
    },
    {
      district_id: 10200005024,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القمرية',
      name_en: 'Al Qumariyyah  Dist.',
    },
    {
      district_id: 10200005025,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي عودة',
      name_en: 'Awdah  Dist.',
    },
    {
      district_id: 10200005026,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah  Dist.',
    },
    {
      district_id: 10200005027,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي قروى',
      name_en: 'Qurwa  Dist.',
    },
    {
      district_id: 10200005028,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الصيانة',
      name_en: 'Al Siana Dist.',
    },
    {
      district_id: 10200005029,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي سوق عكاظ',
      name_en: 'Suq Ukadh Dist.',
    },
    {
      district_id: 10200005030,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج21',
      name_en: 'C21 Dist.',
    },
    {
      district_id: 10200005031,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج44',
      name_en: 'C44 Dist.',
    },
    {
      district_id: 10200005032,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج27',
      name_en: 'C27 Dist.',
    },
    {
      district_id: 10200005033,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الشهداء الجنوبية',
      name_en: 'Ashuhada Aljanubiyyah  Dist.',
    },
    {
      district_id: 10200005034,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي شهار',
      name_en: 'Shihar  Dist.',
    },
    {
      district_id: 10200005035,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج20',
      name_en: 'C20 Dist.',
    },
    {
      district_id: 10200005036,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي نخب',
      name_en: 'Nakhab  Dist.',
    },
    {
      district_id: 10200005037,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي حوايا الشمالية',
      name_en: 'Huwaya Ash Shamaliyyah Dist.',
    },
    {
      district_id: 10200005038,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10200005039,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي مسرة 1',
      name_en: 'Masarah 1 Dist.',
    },
    {
      district_id: 10200005040,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10200005041,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10200005042,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10200005043,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي حوايا الجنوبية',
      name_en: 'Huwaya Al Janoubiyah  Dist.',
    },
    {
      district_id: 10200005044,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القطبية',
      name_en: 'Al Qutbiyyah  Dist.',
    },
    {
      district_id: 10200005045,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العارمية 2',
      name_en: 'Al Aarmiah 2 Dist.',
    },
    {
      district_id: 10200005046,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي المعترض',
      name_en: 'Al Mutarid  Dist.',
    },
    {
      district_id: 10200005047,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الرويدف',
      name_en: 'Al Ruwaidaf Dist.',
    },
    {
      district_id: 10200005048,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah  Dist.',
    },
    {
      district_id: 10200005049,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القميع',
      name_en: 'Qami Dist.',
    },
    {
      district_id: 10200005050,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي جبرة',
      name_en: 'Jabrah Dist.',
    },
    {
      district_id: 10200005051,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الجفيجف',
      name_en: 'Al Jafijif Dist.',
    },
    {
      district_id: 10200005052,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10200005053,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج1',
      name_en: 'C1 Dist.',
    },
    {
      district_id: 10200005054,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الحلقة الغربية',
      name_en: 'Al Halqah Al Gharbia Dist.',
    },
    {
      district_id: 10200005055,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج6',
      name_en: 'C6 Dist.',
    },
    {
      district_id: 10200005056,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي المثناه',
      name_en: 'Al Mathnah  Dist.',
    },
    {
      district_id: 10200005057,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الخضيرة',
      name_en: 'Hadera  Dist.',
    },
    {
      district_id: 10200005058,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج37',
      name_en: 'C37 Dist.',
    },
    {
      district_id: 10200005059,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج35',
      name_en: 'C35 Dist.',
    },
    {
      district_id: 10200005060,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الجال',
      name_en: 'Al Jal  Dist.',
    },
    {
      district_id: 10200005061,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السرور',
      name_en: 'As Sarur Dist.',
    },
    {
      district_id: 10200005062,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10200005063,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي المركزية',
      name_en: 'Al Markazia Dist.',
    },
    {
      district_id: 10200005064,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan  Dist.',
    },
    {
      district_id: 10200005065,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي شبرا',
      name_en: 'Shobra  Dist.',
    },
    {
      district_id: 10200005066,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الشرقية',
      name_en: 'Sharqia Dist.',
    },
    {
      district_id: 10200005067,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq  Dist.',
    },
    {
      district_id: 10200005068,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10200005069,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ريحه',
      name_en: 'Riha  Dist.',
    },
    {
      district_id: 10200005070,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الوشحاء',
      name_en: 'Al Washha Dist.',
    },
    {
      district_id: 10200005071,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الشهداء الشمالية',
      name_en: 'Ashuhada Alshamaliyyah  Dist.',
    },
    {
      district_id: 10200005072,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج9',
      name_en: 'C9 Dist.',
    },
    {
      district_id: 10200005073,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي قوات الدفاع الجوي',
      name_en: 'Air Defense Forces Dist.',
    },
    {
      district_id: 10200005074,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10200005075,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي المضباع',
      name_en: 'Al Mudhbah Dist.',
    },
    {
      district_id: 10200005076,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الواسط',
      name_en: 'Al Wasit  Dist.',
    },
    {
      district_id: 10200005077,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج33',
      name_en: 'C33 Dist.',
    },
    {
      district_id: 10200005078,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10200005079,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج8',
      name_en: 'C8 Dist.',
    },
    {
      district_id: 10200005080,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الضبط',
      name_en: 'Al Dhabt Dist.',
    },
    {
      district_id: 10200005081,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السويقة',
      name_en: 'Al Sawaiqa Dist.',
    },
    {
      district_id: 10200005082,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج36',
      name_en: 'C36 Dist.',
    },
    {
      district_id: 10200005083,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي مثمله',
      name_en: 'Masmla Dist.',
    },
    {
      district_id: 10200005084,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الحوية',
      name_en: 'Al Huwaya Dist.',
    },
    {
      district_id: 10200005085,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج40',
      name_en: 'C40 Dist.',
    },
    {
      district_id: 10200005086,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج41',
      name_en: 'C41 Dist.',
    },
    {
      district_id: 10200005087,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الاخباب',
      name_en: 'Akhbab Dist.',
    },
    {
      district_id: 10200005088,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القراحين',
      name_en: 'Alqrahin Dist.',
    },
    {
      district_id: 10200005089,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي السداد',
      name_en: 'As Sadad Dist.',
    },
    {
      district_id: 10200005090,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي أم العراد',
      name_en: 'Umm Alarad  Dist.',
    },
    {
      district_id: 10200005091,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الحدائق',
      name_en: 'Al Hadaek Dist.',
    },
    {
      district_id: 10200005092,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي أم السباع',
      name_en: 'Al Sabaa  Dist.',
    },
    {
      district_id: 10200005093,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي المعارض',
      name_en: 'Al Maarid  Dist.',
    },
    {
      district_id: 10200005094,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الردف',
      name_en: 'Ar Ruddaf Dist.',
    },
    {
      district_id: 10200005095,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج32',
      name_en: 'C32 Dist.',
    },
    {
      district_id: 10200005096,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج30',
      name_en: 'C30 Dist.',
    },
    {
      district_id: 10200005097,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج31',
      name_en: 'C31 Dist.',
    },
    {
      district_id: 10200005098,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج24',
      name_en: 'C24 Dist.',
    },
    {
      district_id: 10200005099,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الحلقة الشرقية',
      name_en: 'Al Halqah Asharqiyyah Dist.',
    },
    {
      district_id: 10200005100,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القيم الأسفل',
      name_en: 'Al Qayam Al Asfal Dist.',
    },
    {
      district_id: 10200005101,
      city_id: 5,
      region_id: 2,
      name_ar: 'المطار الدولي الجديد',
      name_en: 'New International Airport',
    },
    {
      district_id: 10200005102,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج14',
      name_en: 'C14 Dist.',
    },
    {
      district_id: 10200005103,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج13',
      name_en: 'C13 Dist.',
    },
    {
      district_id: 10200005104,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القيم الأعلى',
      name_en: 'Al Qayam Al Aala Dist.',
    },
    {
      district_id: 10200005105,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج7',
      name_en: 'C7 Dist.',
    },
    {
      district_id: 10200005106,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج19',
      name_en: 'C19 Dist.',
    },
    {
      district_id: 10200005107,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج34',
      name_en: 'C34 Dist.',
    },
    {
      district_id: 10200005108,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العرج الأسفل',
      name_en: 'Al Urj Al Asfal Dist.',
    },
    {
      district_id: 10200005109,
      city_id: 5,
      region_id: 2,
      name_ar: 'جامعة الطائف الجديدة',
      name_en: 'New Taif University',
    },
    {
      district_id: 10200005110,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي مسرة 2',
      name_en: 'Masarah 2 Dist.',
    },
    {
      district_id: 10200005111,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10200005112,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العرج الأعلى',
      name_en: 'Al Urj Al Aala Dist.',
    },
    {
      district_id: 10200005113,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج39',
      name_en: 'C39 Dist.',
    },
    {
      district_id: 10200005114,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي ج38',
      name_en: 'C38 Dist.',
    },
    {
      district_id: 10200005115,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي الغمير',
      name_en: 'Al Ghumayr Dist.',
    },
    {
      district_id: 10200005116,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي العارمية 1',
      name_en: 'Al Aarmiah 1 Dist.',
    },
    {
      district_id: 10200005117,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي القديرة',
      name_en: 'Al Qadirah Dist.',
    },
    {
      district_id: 10200005118,
      city_id: 5,
      region_id: 2,
      name_ar: 'حي سديرة',
      name_en: 'Sudayrah Dist.',
    },
    {
      district_id: 10200006001,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العدل',
      name_en: 'Al Adl Dist.',
    },
    {
      district_id: 10200006002,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10200006003,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المعابدة',
      name_en: 'Al Maabdah Dist.',
    },
    {
      district_id: 10200006004,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الخنساء',
      name_en: 'Al Khansa Dist.',
    },
    {
      district_id: 10200006005,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulimaniyah Dist.',
    },
    {
      district_id: 10200006006,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الجميزة',
      name_en: 'Al Jummayzah Dist.',
    },
    {
      district_id: 10200006007,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي جبل النور',
      name_en: 'Jabal An Nur Dist.',
    },
    {
      district_id: 10200006008,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الهنداوية',
      name_en: 'Al Hindawiyah Dist.',
    },
    {
      district_id: 10200006009,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الرصيفة',
      name_en: 'Ar Rusayfah Dist.',
    },
    {
      district_id: 10200006010,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي جرهم',
      name_en: 'Jarham Dist.',
    },
    {
      district_id: 10200006011,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10200006012,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المسفلة',
      name_en: 'Al Misfalah Dist.',
    },
    {
      district_id: 10200006013,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي التقوى',
      name_en: 'At Taqwa Dist.',
    },
    {
      district_id: 10200006014,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي كدي',
      name_en: 'Kudy Dist.',
    },
    {
      district_id: 10200006015,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي القرارة والنقا',
      name_en: 'Al Qararah And An Naqa Dist.',
    },
    {
      district_id: 10200006016,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الحرم',
      name_en: 'Al Haram Dist.',
    },
    {
      district_id: 10200006017,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي حارة الباب والشامية',
      name_en: 'Harat Al Bab And Ash Shamiyah Dist.',
    },
    {
      district_id: 10200006018,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي التيسير',
      name_en: 'At Taysir Dist.',
    },
    {
      district_id: 10200006019,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي جرول',
      name_en: 'Jarwal Dist.',
    },
    {
      district_id: 10200006020,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المشاعر',
      name_en: 'Al Mashair Dist.',
    },
    {
      district_id: 10200006021,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المرسلات',
      name_en: 'Al Mursalat Dist.',
    },
    {
      district_id: 10200006022,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10200006023,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10200006024,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10200006025,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10200006026,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الضيافة',
      name_en: 'Ad Diyafah Dist.',
    },
    {
      district_id: 10200006027,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي البيبان',
      name_en: 'Al Bibyan Dist.',
    },
    {
      district_id: 10200006028,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الحجون',
      name_en: 'Al Hujun Dist.',
    },
    {
      district_id: 10200006029,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الطندباوي',
      name_en: 'At Tandabawi Dist.',
    },
    {
      district_id: 10200006030,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الشبيكة',
      name_en: 'Ash Shubaikah Dist.',
    },
    {
      district_id: 10200006031,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الهجلة',
      name_en: 'Al Hajlah Dist.',
    },
    {
      district_id: 10200006032,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المنصور',
      name_en: 'Al Mansur Dist.',
    },
    {
      district_id: 10200006033,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الكعكية',
      name_en: 'Al Kakiyah Dist.',
    },
    {
      district_id: 10200006034,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الشوقية',
      name_en: 'Ash Shawqiyah Dist.',
    },
    {
      district_id: 10200006035,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الهجرة',
      name_en: 'Al Hijrah Dist.',
    },
    {
      district_id: 10200006036,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي بطحاء قريش',
      name_en: 'Batha Quraysh Dist.',
    },
    {
      district_id: 10200006037,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي شرائع المجاهدين',
      name_en: 'Sharai Al Mujahidin Dist.',
    },
    {
      district_id: 10200006038,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العوالي',
      name_en: 'Al Awali Dist.',
    },
    {
      district_id: 10200006039,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الشرائع',
      name_en: 'Asharai  Dist.',
    },
    {
      district_id: 10200006040,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الراشدية',
      name_en: 'Ar Rashidiyah Dist.',
    },
    {
      district_id: 10200006041,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الخضراء',
      name_en: 'Al Khadra Dist.',
    },
    {
      district_id: 10200006042,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10200006043,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العكيشية',
      name_en: 'Al Ukayshiyah Dist.',
    },
    {
      district_id: 10200006044,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي ولي العهد',
      name_en: 'Waly Al Ahd Dist.',
    },
    {
      district_id: 10200006045,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10200006046,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي ريع زاخر',
      name_en: 'Ray Zakhir Dist.',
    },
    {
      district_id: 10200006047,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي التنعيم',
      name_en: 'At Tanim Dist.',
    },
    {
      district_id: 10200006048,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الزاهر',
      name_en: 'Az Zahir Dist.',
    },
    {
      district_id: 10200006049,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي شعب عامر وشعب علي',
      name_en: 'Shaib Amir And Shaib Ali Dist.',
    },
    {
      district_id: 10200006050,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي اجياد',
      name_en: 'Ajyad Dist.',
    },
    {
      district_id: 10200006051,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الحمراء وام الجود',
      name_en: 'Al Hamra Umm Al Jud Dist.',
    },
    {
      district_id: 10200006052,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10200006053,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العتيبية',
      name_en: 'Al Utaybiyah Dist.',
    },
    {
      district_id: 10200006054,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10200006055,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العمرة الجديدة',
      name_en: 'Al Umrah Al Jadidah Dist.',
    },
    {
      district_id: 10200006056,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10200006057,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي البحيرات',
      name_en: 'Al Buhayrat Dist.',
    },
    {
      district_id: 10200006058,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي النوارية',
      name_en: 'An Nawwariyah Dist.',
    },
    {
      district_id: 10200006059,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10200006060,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي وادي جليل',
      name_en: 'Wadi Jalil Dist.',
    },
    {
      district_id: 10200006061,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي العسيلة',
      name_en: 'Al Usaylah Dist.',
    },
    {
      district_id: 10200006062,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي جعرانة',
      name_en: 'Jaranah Dist.',
    },
    {
      district_id: 10200006063,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي البيعة',
      name_en: 'Al Biyah Dist.',
    },
    {
      district_id: 10200006064,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي المغمس',
      name_en: 'Al Mughams Dist.',
    },
    {
      district_id: 10200006065,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي التروية',
      name_en: 'At Tarwiyah Dist.',
    },
    {
      district_id: 10200006066,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الشرائع',
      name_en: 'Ash Sharia Dist.',
    },
    {
      district_id: 10200006067,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10200006068,
      city_id: 6,
      region_id: 2,
      name_ar: 'حي الكوثر',
      name_en: 'Al Kawthar Dist.',
    },
    {
      district_id: 10200018001,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الزمرد',
      name_en: 'Az Zomorod Dist.',
    },
    {
      district_id: 10200018002,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي اللؤلؤ',
      name_en: 'Al Loaloa Dist.',
    },
    {
      district_id: 10200018003,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الياقوت',
      name_en: 'Al Yaqoot Dist.',
    },
    {
      district_id: 10200018004,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصوارى',
      name_en: 'As Swaryee Dist.',
    },
    {
      district_id: 10200018005,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الامواج',
      name_en: 'Al Amwaj Dist.',
    },
    {
      district_id: 10200018006,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشراع',
      name_en: 'Ash Sheraa Dist.',
    },
    {
      district_id: 10200018007,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفردوس',
      name_en: 'Al Ferdous Dist.',
    },
    {
      district_id: 10200018008,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفلاح',
      name_en: 'Al Falah Dist.',
    },
    {
      district_id: 10200018009,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الاصالة',
      name_en: 'Al Asalah Dist.',
    },
    {
      district_id: 10200018010,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي مريخ',
      name_en: 'Mraykh Dist.',
    },
    {
      district_id: 10200018011,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shrouk Dist.',
    },
    {
      district_id: 10200018012,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الامير فواز الشمالى',
      name_en: 'Al Amir Fawaz Ash Shamaly Dist.',
    },
    {
      district_id: 10200018013,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرياض',
      name_en: 'Ar Riyadh Dist.',
    },
    {
      district_id: 10200018014,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفروسية',
      name_en: 'Al Frosyah Dist.',
    },
    {
      district_id: 10200018015,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 10200018016,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10200018017,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الحمدانية',
      name_en: 'Al Hamadaniyah Dist.',
    },
    {
      district_id: 10200018018,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayaan Dist.',
    },
    {
      district_id: 10200018019,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي أم حبلين الغربية',
      name_en: 'Um Hableen Al Gharbiyyah Dist.',
    },
    {
      district_id: 10200018020,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي بريمان',
      name_en: 'Bryman Dist.',
    },
    {
      district_id: 10200018021,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10200018022,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الثعالبة',
      name_en: 'Ath Thaalibah Dist.',
    },
    {
      district_id: 10200018023,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10200018024,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouk Dist.',
    },
    {
      district_id: 10200018025,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العدل',
      name_en: 'Al Adel Dist.',
    },
    {
      district_id: 10200018026,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الهنداوية',
      name_en: 'Al Hindawiyah Dist.',
    },
    {
      district_id: 10200018027,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المحجر',
      name_en: 'Al Mahjar Dist.',
    },
    {
      district_id: 10200018028,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10200018029,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10200018030,
      city_id: 18,
      region_id: 2,
      name_ar: 'جامعة الملك عبدالعزيز',
      name_en: 'King Abdulaziz University',
    },
    {
      district_id: 10200018031,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10200018032,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البغدادية الشرقية',
      name_en: 'Al Baghdadiyah Ash Sharqiyah Dist.',
    },
    {
      district_id: 10200018033,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النزلة الشرقية',
      name_en: 'An Nazlah Ash Sharqiyah Dist.',
    },
    {
      district_id: 10200018034,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البوادي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 10200018035,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10200018036,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الثغر',
      name_en: 'Ath Thaghr Dist.',
    },
    {
      district_id: 10200018037,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشرفية',
      name_en: 'Ash Sharafiyah Dist.',
    },
    {
      district_id: 10200018038,
      city_id: 18,
      region_id: 2,
      name_ar: 'قاعدة الملك فيصل البحرية',
      name_en: 'King Faisal Naval Base',
    },
    {
      district_id: 10200018039,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shefaa Dist.',
    },
    {
      district_id: 10200018040,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السنابل',
      name_en: 'As Sanabel Dist.',
    },
    {
      district_id: 10200018041,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الهدى',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10200018042,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي التضامن',
      name_en: 'Al Tadamun Dist.',
    },
    {
      district_id: 10200018043,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الكرامة',
      name_en: 'Al Karamah Dist.',
    },
    {
      district_id: 10200018044,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرحمة',
      name_en: 'Ar Rahmah Dist.',
    },
    {
      district_id: 10200018045,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البركة',
      name_en: 'Al Barakah Dist.',
    },
    {
      district_id: 10200018046,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المسرة',
      name_en: 'Al Masarah Dist.',
    },
    {
      district_id: 10200018047,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المليساء',
      name_en: 'Al Moulysaa Dist.',
    },
    {
      district_id: 10200018048,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي القوزين',
      name_en: 'Al Qouzeen Dist.',
    },
    {
      district_id: 10200018049,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10200018050,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفضيلة',
      name_en: 'Al Fadeylah Dist.',
    },
    {
      district_id: 10200018051,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10200018052,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السروات',
      name_en: 'As Sarawat Dist.',
    },
    {
      district_id: 10200018053,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الخمرة',
      name_en: 'Al Khomrah Dist.',
    },
    {
      district_id: 10200018054,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الضاحية',
      name_en: 'Ad Dahiah Dist.',
    },
    {
      district_id: 10200018055,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي القرينية',
      name_en: 'Al Qryniah Dist.',
    },
    {
      district_id: 10200018056,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النزلة اليمانية',
      name_en: 'An Nazlah Al Yamaniyah Dist.',
    },
    {
      district_id: 10200018057,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10200018058,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي القريات',
      name_en: 'Al Quraiyat Dist.',
    },
    {
      district_id: 10200018059,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي غليل',
      name_en: 'Ghulail Dist.',
    },
    {
      district_id: 10200018060,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الكندرة',
      name_en: 'Al Kandarah Dist.',
    },
    {
      district_id: 10200018061,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العمارية',
      name_en: 'Al Ammariyah Dist.',
    },
    {
      district_id: 10200018062,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصحيفة',
      name_en: 'As Sahifah Dist.',
    },
    {
      district_id: 10200018063,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السبيل',
      name_en: 'As Sabil Dist.',
    },
    {
      district_id: 10200018064,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10200018065,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي بنى مالك',
      name_en: 'Bani Malik Dist.',
    },
    {
      district_id: 10200018066,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10200018067,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10200018068,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10200018069,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي مشرفة',
      name_en: 'Mishrifah Dist.',
    },
    {
      district_id: 10200018070,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10200018071,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10200018072,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي ابرق الرغامة',
      name_en: 'Abruq Ar Rughamah Dist.',
    },
    {
      district_id: 10200018073,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaymaniyah Dist.',
    },
    {
      district_id: 10200018074,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الساحل',
      name_en: 'As Sahil Dist.',
    },
    {
      district_id: 10200018075,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البشائر',
      name_en: 'Al Bashaer Dist.',
    },
    {
      district_id: 10200018076,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10200018077,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الكوثر',
      name_en: 'Al Kawthar Dist.',
    },
    {
      district_id: 10200018078,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي طيبة',
      name_en: 'Taibah Dist.',
    },
    {
      district_id: 10200018079,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النعيم',
      name_en: 'An Naim Dist.',
    },
    {
      district_id: 10200018080,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10200018081,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10200018082,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10200018083,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10200018084,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10200018085,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السامر',
      name_en: 'As Samir Dist.',
    },
    {
      district_id: 10200018086,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الاجواد',
      name_en: 'Al Ajwad Dist.',
    },
    {
      district_id: 10200018087,
      city_id: 18,
      region_id: 2,
      name_ar: 'مطار الملك عبدالعزيز الدولي',
      name_en: 'King Abdulaziz International Airport',
    },
    {
      district_id: 10200018088,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10200018089,
      city_id: 18,
      region_id: 2,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10200018090,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الامير عبدالمجيد',
      name_en: 'Prince Abdul Majeed Dist.',
    },
    {
      district_id: 10200018091,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الوزيريه',
      name_en: 'Al Waziriyah Dist.',
    },
    {
      district_id: 10200018092,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10200018093,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الامير فواز الجنوبى',
      name_en: 'Al Amir Fawaz Al Janouby Dist.',
    },
    {
      district_id: 10200018094,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المتنزهات',
      name_en: 'Al Mutanazahat Dist.',
    },
    {
      district_id: 10200018095,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10200018096,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10200018097,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفضل',
      name_en: 'Al Fadel Dist.',
    },
    {
      district_id: 10200018098,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي ام السلم',
      name_en: 'Um Asalam Dist.',
    },
    {
      district_id: 10200018100,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البغدادية الغربية',
      name_en: 'Al Baghdadiyah Al Gharbiyah Dist.',
    },
    {
      district_id: 10200018101,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي ابحر الشمالية',
      name_en: 'Abhur Ash Shamaliyah Dist.',
    },
    {
      district_id: 10200018102,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرويس',
      name_en: 'Ar Ruwais Dist.',
    },
    {
      district_id: 10200018103,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي بترومين',
      name_en: 'Petromin Dist.',
    },
    {
      district_id: 10200018104,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10200018105,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي ابحر الجنوبية',
      name_en: 'Abhur Al Janubiyah Dist.',
    },
    {
      district_id: 10200018106,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 10200018107,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السرورية',
      name_en: 'As Sororyah Dist.',
    },
    {
      district_id: 10200018108,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الاجاويد',
      name_en: 'Al Ajaweed Dist.',
    },
    {
      district_id: 10200018109,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 10200018110,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي مدائن الفهد',
      name_en: 'Madain Al Fahd Dist.',
    },
    {
      district_id: 10200018111,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10200018112,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10200018113,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10200018114,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي بحرة',
      name_en: 'Bahrah Dist.',
    },
    {
      district_id: 10200018115,
      city_id: 18,
      region_id: 2,
      name_ar: 'ميناء جدة الاسلامي',
      name_en: 'Jeddah Eslamic Seaport',
    },
    {
      district_id: 10200018116,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الوفاء',
      name_en: 'Al Wafa Dist.',
    },
    {
      district_id: 10200018117,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الحرازات',
      name_en: 'Al Harazat Dist.',
    },
    {
      district_id: 10200018118,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المحاميد',
      name_en: 'Al Mahamid Dist.',
    },
    {
      district_id: 10200018119,
      city_id: 18,
      region_id: 2,
      name_ar: 'المدينة الصناعية الثالثة',
      name_en: '3rd Industrial City',
    },
    {
      district_id: 10200018120,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفنار',
      name_en: 'Al Fanar Dist.',
    },
    {
      district_id: 10200018121,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي السهل',
      name_en: 'Al Sahl Dist.',
    },
    {
      district_id: 10200018122,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الدرة',
      name_en: 'Ad Durrah Dist.',
    },
    {
      district_id: 10200018123,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المنارات',
      name_en: 'Al Manarat Dist.',
    },
    {
      district_id: 10200018124,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10200018125,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الحجاز',
      name_en: 'Al Hijaz Dist.',
    },
    {
      district_id: 10200018126,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المزيرعة',
      name_en: 'Al Muzairah Dist.',
    },
    {
      district_id: 10200018127,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصفوة',
      name_en: 'Al Safwah Dist.',
    },
    {
      district_id: 10200018128,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المستقبل',
      name_en: 'Al Mustaqbal Dist.',
    },
    {
      district_id: 10200018129,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النجمة',
      name_en: 'An Najmah Dist.',
    },
    {
      district_id: 10200018130,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي القوس',
      name_en: 'Al Qus Dist.',
    },
    {
      district_id: 10200018131,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الحفنة',
      name_en: 'Al Hafnah Dist.',
    },
    {
      district_id: 10200018132,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10200018133,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشويضي',
      name_en: 'Al Shuwaidhi Dist.',
    },
    {
      district_id: 10200018134,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الشرقية',
      name_en: 'Al Sharqiyyah Dist.',
    },
    {
      district_id: 10200018135,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العشيرية',
      name_en: 'Al Ushayriyyah Dist.',
    },
    {
      district_id: 10200018136,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي أبو جعالة',
      name_en: 'Abu Jaalah Dist.',
    },
    {
      district_id: 10200018137,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي كتانة',
      name_en: 'Katanah Dist.',
    },
    {
      district_id: 10200018138,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي التلال',
      name_en: 'Al Talal Dist.',
    },
    {
      district_id: 10200018139,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرمال',
      name_en: 'Ar Rimal Dist.',
    },
    {
      district_id: 10200018140,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المرسي',
      name_en: 'Al Mursi Dist.',
    },
    {
      district_id: 10200018141,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10200018142,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10200018143,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البحيرات',
      name_en: 'Al Buhayrat Dist.',
    },
    {
      district_id: 10200018144,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الموج',
      name_en: 'Al Mawj Dist.',
    },
    {
      district_id: 10200018145,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الغربية',
      name_en: 'Al Gharbiyyah Dist.',
    },
    {
      district_id: 10200018146,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الزهور',
      name_en: 'Al Zahur Dist.',
    },
    {
      district_id: 10200018147,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الهزاعية',
      name_en: 'Al Hazaiyah Dist.',
    },
    {
      district_id: 10200018148,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العسلاء',
      name_en: 'Al Asla Dist.',
    },
    {
      district_id: 10200018149,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العسلاء',
      name_en: 'Al Asla Dist.',
    },
    {
      district_id: 10200018150,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي بلدة ذهبان',
      name_en: 'Dhahban Town Dist.',
    },
    {
      district_id: 10200018151,
      city_id: 18,
      region_id: 2,
      name_ar: 'حكومي',
      name_en: 'Governmental',
    },
    {
      district_id: 10200018152,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10200018153,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي النور',
      name_en: 'Al Nur Dist.',
    },
    {
      district_id: 10200018154,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي صناعي',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 10200018155,
      city_id: 18,
      region_id: 2,
      name_ar: 'حكومي',
      name_en: 'Governmental',
    },
    {
      district_id: 10200018156,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10200018157,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الفرقان',
      name_en: 'Al Furqan Dist.',
    },
    {
      district_id: 10200018158,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي طابة',
      name_en: 'Tabah Dist.',
    },
    {
      district_id: 10200018159,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرواسي',
      name_en: 'Al Rawasee Dist.',
    },
    {
      district_id: 10200018160,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي أم حبلين الشرقية',
      name_en: 'Um Hableen Al Sharqiyyah Dist.',
    },
    {
      district_id: 10200018161,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي قباء',
      name_en: 'Quba Dist.',
    },
    {
      district_id: 10200018162,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الرابية',
      name_en: 'Al Rabiyah Dist.',
    },
    {
      district_id: 10200018163,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العليا',
      name_en: "Al 'Ulayya Dist.",
    },
    {
      district_id: 10200018164,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي البدور',
      name_en: 'Al Budur Dist.',
    },
    {
      district_id: 10200018165,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المجامع',
      name_en: 'Al Majami Dist.',
    },
    {
      district_id: 10200018166,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي المرسلات',
      name_en: 'Al Mursalat Dist.',
    },
    {
      district_id: 10200018167,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الأثير',
      name_en: 'Al Athir Dist.',
    },
    {
      district_id: 10200018168,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي صناعي',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 10200018169,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي الصفحة',
      name_en: 'Al Safhah Dist.',
    },
    {
      district_id: 10200018170,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي اليسر',
      name_en: 'Al Yusr Dist.',
    },
    {
      district_id: 10200018171,
      city_id: 18,
      region_id: 2,
      name_ar: 'حي العبير',
      name_en: 'Al Abeer Dist.',
    },
    {
      district_id: 10200377002,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10200377003,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي انكاس',
      name_en: 'Inkas Dist.',
    },
    {
      district_id: 10200377005,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الصليب الشرقي',
      name_en: 'As Silayyib Ash Sharqi Dist.',
    },
    {
      district_id: 10200377006,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الصليب الغربي',
      name_en: 'As Silayyib Al Gharbi Dist.',
    },
    {
      district_id: 10200377007,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي المرجانية',
      name_en: 'Al Marjaniyah Dist.',
    },
    {
      district_id: 10200377008,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الجود',
      name_en: 'Al Jud Dist.',
    },
    {
      district_id: 10200377009,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الصبوح',
      name_en: 'As Sabuh Dist.',
    },
    {
      district_id: 10200377010,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الفرنيسية الشرقية',
      name_en: 'Al Farnisiyyah Ash Sharqiyyah Dist.',
    },
    {
      district_id: 10200377011,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي السوق',
      name_en: 'As Suq Dist.',
    },
    {
      district_id: 10200377012,
      city_id: 377,
      region_id: 2,
      name_ar: 'حي الصمد',
      name_en: 'As Samad Dist.',
    },
    {
      district_id: 10200377013,
      city_id: 377,
      region_id: 2,
      name_ar: 'المنطقة الصناعية المرحلة الثانية والثالثة',
      name_en: 'Industrial Area Phase 2 And 3',
    },
    {
      district_id: 10201105001,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10201105002,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي البرقاء',
      name_en: 'Al Barqa Dist.',
    },
    {
      district_id: 10201105003,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10201105004,
      city_id: 1105,
      region_id: 2,
      name_ar: 'المغاربة',
      name_en: 'Al Magharbah',
    },
    {
      district_id: 10201105005,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الدف',
      name_en: 'Ad Duf Dist.',
    },
    {
      district_id: 10201105006,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10201105007,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي فازع',
      name_en: 'Faza Dist.',
    },
    {
      district_id: 10201105008,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الفلج',
      name_en: 'Al Falaj Dist.',
    },
    {
      district_id: 10201105009,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الطلعة',
      name_en: 'At Talaah Dist.',
    },
    {
      district_id: 10201105010,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الشيوخ',
      name_en: 'Ash Shiyukh Dist.',
    },
    {
      district_id: 10201105011,
      city_id: 1105,
      region_id: 2,
      name_ar: 'الصدر',
      name_en: 'As Sadr',
    },
    {
      district_id: 10201105012,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي اللبدة',
      name_en: 'Al Lubdah Dist.',
    },
    {
      district_id: 10201105013,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الحميرات',
      name_en: 'Al Humayrat Dist.',
    },
    {
      district_id: 10201105014,
      city_id: 1105,
      region_id: 2,
      name_ar: 'حي الروايضة',
      name_en: 'Ar Ruwaydah Dist.',
    },
    {
      district_id: 10201105015,
      city_id: 1105,
      region_id: 2,
      name_ar: 'غران',
      name_en: 'Gharan',
    },
    {
      district_id: 10201248001,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10201248002,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الناصرية',
      name_en: 'An Nasriyah Dist.',
    },
    {
      district_id: 10201248003,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10201248004,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10201248005,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10201248006,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الحديقة',
      name_en: 'Al Hadiqah Dist.',
    },
    {
      district_id: 10201248007,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10201248008,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10201248009,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي السوق القديم',
      name_en: 'As Suq Al Qadim Dist.',
    },
    {
      district_id: 10201248010,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الحارثية',
      name_en: 'Al Harthiyah Dist.',
    },
    {
      district_id: 10201248011,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الهجرة',
      name_en: 'Al Hijrah Dist.',
    },
    {
      district_id: 10201248012,
      city_id: 1248,
      region_id: 2,
      name_ar: 'حي الدغمية',
      name_en: 'Ad Daghmiyah Dist.',
    },
    {
      district_id: 10201257001,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10201257002,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10201257003,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10201257004,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي النقابة',
      name_en: 'An Naqabah Dist.',
    },
    {
      district_id: 10201257005,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10201257006,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي أبو شعيب',
      name_en: 'Abu Shuayb Dist.',
    },
    {
      district_id: 10201257007,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10201257008,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10201257009,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10201257010,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي سدر',
      name_en: 'Sedr Dist.',
    },
    {
      district_id: 10201257011,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي غزيات',
      name_en: 'Ghaziyyat Dist.',
    },
    {
      district_id: 10201257012,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي أبو عروة',
      name_en: 'Abu Urwah Dist.',
    },
    {
      district_id: 10201257013,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي عين شمس',
      name_en: 'Ayn Shams Dist.',
    },
    {
      district_id: 10201257014,
      city_id: 1257,
      region_id: 2,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10201390001,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 7',
      name_en: 'No 7 Dist.',
    },
    {
      district_id: 10201390002,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي ج1',
      name_en: 'C 1 Dist.',
    },
    {
      district_id: 10201390003,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 3',
      name_en: 'No 3 Dist.',
    },
    {
      district_id: 10201390004,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 5',
      name_en: 'No 5 Dist.',
    },
    {
      district_id: 10201390005,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 4',
      name_en: 'No 4 Dist.',
    },
    {
      district_id: 10201390006,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 2',
      name_en: 'No 2 Dist.',
    },
    {
      district_id: 10201390007,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 9',
      name_en: 'No 9 Dist.',
    },
    {
      district_id: 10201390008,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 1',
      name_en: 'No 1 Dist.',
    },
    {
      district_id: 10201390009,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 8',
      name_en: 'No 8 Dist.',
    },
    {
      district_id: 10201390010,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 10',
      name_en: 'No10 Dist.',
    },
    {
      district_id: 10201390011,
      city_id: 1390,
      region_id: 2,
      name_ar: 'حي رقم 6',
      name_en: 'No 6 Dist.',
    },
    {
      district_id: 10201625001,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الشرقية',
      name_en: 'Ash Sharqiyyah Dist.',
    },
    {
      district_id: 10201625002,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10201625003,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الناعمية',
      name_en: 'An Naimiyah Dist.',
    },
    {
      district_id: 10201625004,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الازهار',
      name_en: 'Al Azhar Dist.',
    },
    {
      district_id: 10201625005,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الغربي',
      name_en: 'Al Gharbi Dist.',
    },
    {
      district_id: 10201625006,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الرتبة',
      name_en: 'Ar Ratibah Dist.',
    },
    {
      district_id: 10201625007,
      city_id: 1625,
      region_id: 2,
      name_ar: 'حي الشامية',
      name_en: 'Ash Shamiyah Dist.',
    },
    {
      district_id: 10201628001,
      city_id: 1628,
      region_id: 2,
      name_ar: 'حي القوز',
      name_en: 'Al Qawz Dist.',
    },
    {
      district_id: 10201879001,
      city_id: 1879,
      region_id: 2,
      name_ar: 'حي ثول',
      name_en: 'Thuwal Dist.',
    },
    {
      district_id: 10201879002,
      city_id: 1879,
      region_id: 2,
      name_ar: 'جامعة الملك عبدالله للعلوم والتقنية',
      name_en: 'King Abdullah University Of Science And Technology',
    },
    {
      district_id: 10202156001,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10202156002,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي منيف',
      name_en: 'Munif Dist.',
    },
    {
      district_id: 10202156003,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي العلاوة',
      name_en: 'Al Allawah Dist.',
    },
    {
      district_id: 10202156004,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10202156005,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي كرا السردي',
      name_en: 'Kara As Sardi Dist.',
    },
    {
      district_id: 10202156006,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10202156007,
      city_id: 2156,
      region_id: 2,
      name_ar: 'حي السوق',
      name_en: 'As Suq Dist.',
    },
    {
      district_id: 10202800001,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10202800002,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الجدر',
      name_en: 'Al Judr Dist.',
    },
    {
      district_id: 10202800003,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10202800004,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي ملهي',
      name_en: 'Milhi Dist.',
    },
    {
      district_id: 10202800005,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الأغر',
      name_en: 'Al Aghar Dist.',
    },
    {
      district_id: 10202800006,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي كويكب',
      name_en: 'Kuaykib Dist.',
    },
    {
      district_id: 10202800007,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الوزران',
      name_en: 'Al Waziran Dist.',
    },
    {
      district_id: 10202800008,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الدعيكة',
      name_en: 'Al Daika Dist.',
    },
    {
      district_id: 10202800009,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الجزء الشمالي',
      name_en: 'Al Juzu Al Shamali Dist.',
    },
    {
      district_id: 10202800010,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الملحة 1',
      name_en: 'Al Mulha 1 Dist.',
    },
    {
      district_id: 10202800011,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حى النطوف',
      name_en: 'Al Natouf Dist.',
    },
    {
      district_id: 10202800012,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي البروج',
      name_en: 'Al Burouj Dist.',
    },
    {
      district_id: 10202800013,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي السلم',
      name_en: 'Al Silm Dist.',
    },
    {
      district_id: 10202800014,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حى السلم القديم',
      name_en: 'Al Silm Al Qadim Dist.',
    },
    {
      district_id: 10202800015,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzha Dist.',
    },
    {
      district_id: 10202800016,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي السوق القديم',
      name_en: 'As Suq Al Qadim Dist.',
    },
    {
      district_id: 10202800017,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الجدر الغربي',
      name_en: 'Al Judr Al Gharbi Dist.',
    },
    {
      district_id: 10202800018,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الملحة 2',
      name_en: 'Al Mulha 2 Dist',
    },
    {
      district_id: 10202800019,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10202800020,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10202800021,
      city_id: 2800,
      region_id: 2,
      name_ar: 'حي الجدر الشرقي',
      name_en: 'Al Judr Al Sharqi Dist.',
    },
    {
      district_id: 10203666001,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي البيلسان',
      name_en: 'Bay La Sun Dist.',
    },
    {
      district_id: 10203666002,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي نورسان',
      name_en: 'Nursun Dist.',
    },
    {
      district_id: 10203666003,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي المروج',
      name_en: 'Murooj Dist.',
    },
    {
      district_id: 10203666004,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي المرجان',
      name_en: 'Murjan Dist.',
    },
    {
      district_id: 10203666005,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي جمان',
      name_en: 'Juman Dist.',
    },
    {
      district_id: 10203666006,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي ليماس',
      name_en: 'Limas Dist.',
    },
    {
      district_id: 10203666007,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي ريتان',
      name_en: 'Reetan Dist.',
    },
    {
      district_id: 10203666008,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 10203666009,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي المشرق',
      name_en: 'Mashreq Dist.',
    },
    {
      district_id: 10203666010,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الحجاز',
      name_en: 'Hejaz Dist.',
    },
    {
      district_id: 10203666011,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الدانة',
      name_en: 'Dana Dist.',
    },
    {
      district_id: 10203666012,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي ميسان',
      name_en: 'Maysan Dist.',
    },
    {
      district_id: 10203666013,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الإنتاج',
      name_en: 'Entaj Dist.',
    },
    {
      district_id: 10203666014,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي التنمية',
      name_en: 'Tanmiyah Dist.',
    },
    {
      district_id: 10203666015,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الحرير',
      name_en: 'Hareer Dist.',
    },
    {
      district_id: 10203666016,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي المستقبل',
      name_en: 'Mustaqbal Dist.',
    },
    {
      district_id: 10203666017,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الأفق',
      name_en: 'Ofoq Dist.',
    },
    {
      district_id: 10203666018,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي رسيل',
      name_en: 'Raseel Dist.',
    },
    {
      district_id: 10203666019,
      city_id: 3666,
      region_id: 2,
      name_ar: 'منطقة البحيرة المحمية',
      name_en: 'Lagoon Preserved Area',
    },
    {
      district_id: 10203666020,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي ميناء الملك عبد الله',
      name_en: 'King Abdullah Port Dist.',
    },
    {
      district_id: 10203666021,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الرواد',
      name_en: 'Ruwad Dist.',
    },
    {
      district_id: 10203666022,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي التالة',
      name_en: 'Talah Dist.',
    },
    {
      district_id: 10203666023,
      city_id: 3666,
      region_id: 2,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10223642001,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي الصبغان',
      name_en: 'Al Sabghan Dist.',
    },
    {
      district_id: 10223642002,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي غليل',
      name_en: 'Ghalil Dist.',
    },
    {
      district_id: 10223642003,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي نمره المخطط',
      name_en: 'Nimra Al Mukhatat Dist.',
    },
    {
      district_id: 10223642004,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي المبنى الشمالي',
      name_en: 'Al Mabna Al Shamali Dist.',
    },
    {
      district_id: 10223642005,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي البارك',
      name_en: 'Al Bark Dist.',
    },
    {
      district_id: 10223642006,
      city_id: 23642,
      region_id: 2,
      name_ar: 'مخطط المدارات',
      name_en: 'Al Madarat Subdivision Plan',
    },
    {
      district_id: 10223642007,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي بني سهيم',
      name_en: 'Bani Suhaym Dist.',
    },
    {
      district_id: 10223642008,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي الذنبه',
      name_en: 'Al Dhanbh Dist.',
    },
    {
      district_id: 10223642009,
      city_id: 23642,
      region_id: 2,
      name_ar: 'مخطط جنوب نمره',
      name_en: 'Janub Nimrah Dist.',
    },
    {
      district_id: 10223642010,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي المبنى الجنوبي',
      name_en: 'Al Mabna Al Janubi Dist.',
    },
    {
      district_id: 10223642011,
      city_id: 23642,
      region_id: 2,
      name_ar: 'حي المستشفي',
      name_en: 'Al Mustashfi Dist.',
    },
    {
      district_id: 10300014001,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي خاخ',
      name_en: 'Khakh Dist.',
    },
    {
      district_id: 10300014002,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي السكب',
      name_en: 'As Sakb Dist.',
    },
    {
      district_id: 10300014003,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الجصة',
      name_en: 'Al Jassah Dist.',
    },
    {
      district_id: 10300014004,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي رهط',
      name_en: 'Raht Dist.',
    },
    {
      district_id: 10300014005,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني بياضة',
      name_en: 'Bani Bayadah Dist.',
    },
    {
      district_id: 10300014006,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي نبلاء',
      name_en: 'Nubala Dist.',
    },
    {
      district_id: 10300014007,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الشهباء',
      name_en: 'Ash Shahba Dist.',
    },
    {
      district_id: 10300014008,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الرمانة',
      name_en: 'Ar Rumanah Dist.',
    },
    {
      district_id: 10300014009,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ابو كبير',
      name_en: 'Abu Kabir Dist.',
    },
    {
      district_id: 10300014010,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الغراء',
      name_en: 'Al Gharra Dist.',
    },
    {
      district_id: 10300014011,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي السد',
      name_en: 'As Sad Dist.',
    },
    {
      district_id: 10300014012,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الجابرة',
      name_en: 'Al Jabirah Dist.',
    },
    {
      district_id: 10300014013,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الرانوناء',
      name_en: 'Ar Ranuna Dist.',
    },
    {
      district_id: 10300014014,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الحديقة',
      name_en: 'Al Hadiqah Dist.',
    },
    {
      district_id: 10300014015,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ابو بريقاء',
      name_en: 'Abu Burayqa Dist.',
    },
    {
      district_id: 10300014016,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10300014017,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي القصواء',
      name_en: 'Al Qaswa Dist.',
    },
    {
      district_id: 10300014018,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العصبة',
      name_en: 'Al Usbah Dist.',
    },
    {
      district_id: 10300014019,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الخاتم',
      name_en: 'Al Khatim Dist.',
    },
    {
      district_id: 10300014020,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10300014021,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العهن',
      name_en: 'Al Ihn Dist.',
    },
    {
      district_id: 10300014022,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ذو الحليفة',
      name_en: 'Dhu Al Hulayfah Dist.',
    },
    {
      district_id: 10300014023,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي وادي مهزور',
      name_en: 'Wadi Mahzur Dist.',
    },
    {
      district_id: 10300014024,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الدويمة',
      name_en: 'Ad Duwaimah Dist.',
    },
    {
      district_id: 10300014025,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي وادي مذينب',
      name_en: 'Wadi Mudhainib Dist.',
    },
    {
      district_id: 10300014026,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي عين الخيف',
      name_en: 'Ayn Al Khif Dist.',
    },
    {
      district_id: 10300014027,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي عروة',
      name_en: 'Urwah Dist.',
    },
    {
      district_id: 10300014028,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الظاهرة',
      name_en: 'Al Dhahirah Dist.',
    },
    {
      district_id: 10300014029,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الشريبات',
      name_en: 'Ash Shuraybat Dist.',
    },
    {
      district_id: 10300014030,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي جماء أم خالد',
      name_en: 'Jima Um Khalid Dist.',
    },
    {
      district_id: 10300014031,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الجمعة',
      name_en: 'Al Jumah Dist.',
    },
    {
      district_id: 10300014032,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المغيسلة',
      name_en: 'Al Mughaisilah Dist.',
    },
    {
      district_id: 10300014033,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي قربان',
      name_en: 'Qurban Dist.',
    },
    {
      district_id: 10300014034,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الدفاع',
      name_en: 'Ad Difa Dist.',
    },
    {
      district_id: 10300014035,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي سكة الحديد',
      name_en: 'Sikkah Al Hadid Dist.',
    },
    {
      district_id: 10300014036,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى حرة الوبرة',
      name_en: 'Hura Al Wabra Dist.',
    },
    {
      district_id: 10300014037,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الاصيفرين',
      name_en: 'Al Usayfirin Dist.',
    },
    {
      district_id: 10300014038,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي النقاء',
      name_en: 'An Naqa Dist.',
    },
    {
      district_id: 10300014039,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني ظفر',
      name_en: 'Bani Dhafir Dist.',
    },
    {
      district_id: 10300014040,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10300014041,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10300014042,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الجماوات',
      name_en: 'Al Jamawat Dist.',
    },
    {
      district_id: 10300014043,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المناخة',
      name_en: 'Al Manakhah Dist.',
    },
    {
      district_id: 10300014044,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بضاعة',
      name_en: 'Badhaah Dist.',
    },
    {
      district_id: 10300014045,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي جشم',
      name_en: 'Josham Dist.',
    },
    {
      district_id: 10300014046,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الهدراء',
      name_en: 'Al Hadra Dist.',
    },
    {
      district_id: 10300014047,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي السيح',
      name_en: 'As Sih Dist.',
    },
    {
      district_id: 10300014048,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المصانع',
      name_en: 'Al Masani Dist.',
    },
    {
      district_id: 10300014049,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي القبلتين',
      name_en: 'Al Qiblatayn Dist.',
    },
    {
      district_id: 10300014050,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10300014052,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المبعوث',
      name_en: 'Al Mabuth Dist.',
    },
    {
      district_id: 10300014053,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10300014054,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني حارثة',
      name_en: 'Bani Harithah Dist.',
    },
    {
      district_id: 10300014055,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بئر عثمان',
      name_en: 'Bir Uthman Dist.',
    },
    {
      district_id: 10300014056,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي شظاة',
      name_en: 'Shidhat Dist.',
    },
    {
      district_id: 10300014057,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي طيبة',
      name_en: 'Taibah Dist.',
    },
    {
      district_id: 10300014058,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي التلعة',
      name_en: 'Al Talaah Dist.',
    },
    {
      district_id: 10300014059,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10300014061,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي البركة',
      name_en: 'Al Barakah Dist.',
    },
    {
      district_id: 10300014062,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العيون',
      name_en: 'Al Eyun Dist.',
    },
    {
      district_id: 10300014063,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الدار',
      name_en: 'Ad Dar Dist.',
    },
    {
      district_id: 10300014064,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10300014065,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الزهرة',
      name_en: 'Az Zahrah Dist.',
    },
    {
      district_id: 10300014066,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الحفيا',
      name_en: 'Al Hafya Dist.',
    },
    {
      district_id: 10300014067,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي البلقاء',
      name_en: 'Al Balqa Dist.',
    },
    {
      district_id: 10300014068,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي كتانة',
      name_en: 'Kittanah Dist.',
    },
    {
      district_id: 10300014069,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الصادقية',
      name_en: 'As Sadiqiyah Dist.',
    },
    {
      district_id: 10300014070,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الغابة',
      name_en: 'Al Ghabah Dist.',
    },
    {
      district_id: 10300014072,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي وعيرة',
      name_en: 'Wairah Dist.',
    },
    {
      district_id: 10300014073,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10300014074,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العريض',
      name_en: 'Al Ariydh Dist.',
    },
    {
      district_id: 10300014075,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العنابس',
      name_en: 'Al Anabis Dist.',
    },
    {
      district_id: 10300014076,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10300014077,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الراية',
      name_en: 'Ar Rayah Dist.',
    },
    {
      district_id: 10300014078,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي مسجد الدرع',
      name_en: 'Masjid Ad Dara Dist.',
    },
    {
      district_id: 10300014079,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي السقيا',
      name_en: 'As Suqya Dist.',
    },
    {
      district_id: 10300014082,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي جبل عير',
      name_en: 'Jabal Ayr Dist.',
    },
    {
      district_id: 10300014083,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الشافية',
      name_en: 'Ash Shafiyah Dist.',
    },
    {
      district_id: 10300014084,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10300014085,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي جبل احد',
      name_en: 'Jabal Uhud Dist.',
    },
    {
      district_id: 10300014086,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني خدرة',
      name_en: 'Bani Khidrah Dist.',
    },
    {
      district_id: 10300014087,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني عبدالاشهل',
      name_en: 'Bani Abdul Ashhal Dist.',
    },
    {
      district_id: 10300014088,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي بني معاوية',
      name_en: 'Bani Muawiyah Dist.',
    },
    {
      district_id: 10300014090,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ورقان',
      name_en: 'Warqan Dist.',
    },
    {
      district_id: 10300014091,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي شوران',
      name_en: 'Shuran Dist.',
    },
    {
      district_id: 10300014092,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الصناعية',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 10300014093,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الدويخلة',
      name_en: 'Ad Duwaikhilah Dist.',
    },
    {
      district_id: 10300014094,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي العاقول',
      name_en: 'Al Aqoul Dist.',
    },
    {
      district_id: 10300014095,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي وادي البطان',
      name_en: 'Wadi Al Battan Dist.',
    },
    {
      district_id: 10300014096,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المليليح',
      name_en: 'Al Mulaylih Dist.',
    },
    {
      district_id: 10300014097,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الاناهي',
      name_en: 'Al Anahi Dist.',
    },
    {
      district_id: 10300014098,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي وادي الحمض',
      name_en: 'Wadi  Al Hamdh Dist.',
    },
    {
      district_id: 10300014099,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى الخضراء',
      name_en: 'Al Khadhra Dist.',
    },
    {
      district_id: 10300014100,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ابومرخة',
      name_en: 'Abumarkha Dist.',
    },
    {
      district_id: 10300014101,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى الصهلوج',
      name_en: 'Al Sahluj Dist.',
    },
    {
      district_id: 10300014102,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المسبعة',
      name_en: 'Al Masbah Dist.',
    },
    {
      district_id: 10300014103,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الضميرية',
      name_en: 'Al Dhamiria Dist.',
    },
    {
      district_id: 10300014104,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ضعة',
      name_en: 'Dhaea Dist.',
    },
    {
      district_id: 10300014105,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ام السيوف',
      name_en: 'Um Al Seouf Dist.',
    },
    {
      district_id: 10300014106,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي غراب',
      name_en: 'Ghorab Dist.',
    },
    {
      district_id: 10300014107,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى حضوضاء',
      name_en: 'Hadhawda Dist.',
    },
    {
      district_id: 10300014108,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الشرائع',
      name_en: 'Al Sharayie Dist.',
    },
    {
      district_id: 10300014109,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى الصهوة',
      name_en: 'Al Sahwa Dist.',
    },
    {
      district_id: 10300014110,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المندسة',
      name_en: 'Al Mundasa Dist.',
    },
    {
      district_id: 10300014111,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى القبيبة',
      name_en: 'Qubeiba Dist.',
    },
    {
      district_id: 10300014112,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المزيين',
      name_en: 'Al Muzayiyn Dist.',
    },
    {
      district_id: 10300014113,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى بني النجار',
      name_en: 'Bani Al Najjar Dist.',
    },
    {
      district_id: 10300014114,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي حزرة الجنوب',
      name_en: 'Hazrat Al Janoub Dist.',
    },
    {
      district_id: 10300014115,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى ابو سدر',
      name_en: 'Abu Sidr Dist.',
    },
    {
      district_id: 10300014116,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى سد الغابة',
      name_en: 'Sad Al Ghaba Dist.',
    },
    {
      district_id: 10300014117,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى الحساء',
      name_en: 'Al Hisa Dist.',
    },
    {
      district_id: 10300014118,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي المدينة الصناعية',
      name_en: 'Industrial City Dist.',
    },
    {
      district_id: 10300014119,
      city_id: 14,
      region_id: 3,
      name_ar: 'حى المفرحات',
      name_en: 'Al Mafrahat Dist.',
    },
    {
      district_id: 10300014120,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الصويدرة',
      name_en: 'As Suwaydra Dist.',
    },
    {
      district_id: 10300014121,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي ابيار الماشي',
      name_en: 'Abyar Al Mashi Dist.',
    },
    {
      district_id: 10300014122,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الفريش',
      name_en: 'Al Furaysh Dist.',
    },
    {
      district_id: 10300014123,
      city_id: 14,
      region_id: 3,
      name_ar: 'حي الـنقـمى',
      name_en: 'An Ngma Dist.',
    },
    {
      district_id: 10300014124,
      city_id: 14,
      region_id: 3,
      name_ar: 'الحرم الشريف',
      name_en: 'Al Haram  Al Sharif',
    },
    {
      district_id: 10300199001,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10300199002,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي الصخيرات',
      name_en: 'As Sukhayrat Dist.',
    },
    {
      district_id: 10300199003,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي الرزيقية',
      name_en: 'Al Ruzayqiyah Dist.',
    },
    {
      district_id: 10300199004,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي المحاش',
      name_en: 'Al Mahash Dist.',
    },
    {
      district_id: 10300199005,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10300199006,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي صدر الغربية',
      name_en: 'Sadr Al Gharbaiyah Dist.',
    },
    {
      district_id: 10300199007,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي ساق',
      name_en: 'Saq Dist.',
    },
    {
      district_id: 10300199008,
      city_id: 199,
      region_id: 3,
      name_ar: 'حي الجديدة',
      name_en: 'Al Jadidah Dist.',
    },
    {
      district_id: 10300288001,
      city_id: 288,
      region_id: 3,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10300288002,
      city_id: 288,
      region_id: 3,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10300288003,
      city_id: 288,
      region_id: 3,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10300288004,
      city_id: 288,
      region_id: 3,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 10300288005,
      city_id: 288,
      region_id: 3,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10300288006,
      city_id: 288,
      region_id: 3,
      name_ar: 'حى الغزوة',
      name_en: 'Al Ghazwah Dist.',
    },
    {
      district_id: 10300360001,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي المنتزهات',
      name_en: 'Al Muntazahat Dist.',
    },
    {
      district_id: 10300360002,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الوادى',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10300360003,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10300360004,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10300360005,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي ج5',
      name_en: 'C5 Dist.',
    },
    {
      district_id: 10300360006,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الرياض',
      name_en: 'Ar Riyadh Dist.',
    },
    {
      district_id: 10300360007,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي اللجين',
      name_en: 'Al Lujain Dist.',
    },
    {
      district_id: 10300360008,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10300360009,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي ج3',
      name_en: 'C3 Dist.',
    },
    {
      district_id: 10300360010,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي ج4',
      name_en: 'C4 Dist.',
    },
    {
      district_id: 10300360011,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa  Dist.',
    },
    {
      district_id: 10300360012,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10300360013,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shuruq Dist.',
    },
    {
      district_id: 10300360014,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10300360015,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10300360016,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الروابى',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10300360017,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي السامر',
      name_en: 'As Samir Dist.',
    },
    {
      district_id: 10300360018,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10300360019,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي العلم',
      name_en: 'Al Alam Dist.',
    },
    {
      district_id: 10300360020,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي ج1',
      name_en: 'C1 Dist.',
    },
    {
      district_id: 10300360021,
      city_id: 360,
      region_id: 3,
      name_ar: 'حي الذهب',
      name_en: 'Al Dhahab Dist.',
    },
    {
      district_id: 10300483001,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الياقوت',
      name_en: 'Al Yaqut Dist.',
    },
    {
      district_id: 10300483002,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 10300483003,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي البقاع',
      name_en: 'Al Biqa Dist.',
    },
    {
      district_id: 10300483004,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10300483005,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النعيم',
      name_en: 'Al Naim Dist.',
    },
    {
      district_id: 10300483006,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 10300483007,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10300483008,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الدوحة',
      name_en: 'Al Doha Dist.',
    },
    {
      district_id: 10300483009,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الفلاح',
      name_en: 'Al Falah Dist.',
    },
    {
      district_id: 10300483010,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10300483011,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الرمال',
      name_en: 'Al Rimal Dist.',
    },
    {
      district_id: 10300483012,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10300483013,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10300483014,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10300483015,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzhah Dist.',
    },
    {
      district_id: 10300483016,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الجار',
      name_en: 'Al Jar Dist.',
    },
    {
      district_id: 10300483017,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الضباب',
      name_en: 'Al Dabab Dist.',
    },
    {
      district_id: 10300483018,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10300483019,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي اللؤلؤة',
      name_en: 'Al Luluah Dist.',
    },
    {
      district_id: 10300483020,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10300483021,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المرسي',
      name_en: 'Al Mursi Dist.',
    },
    {
      district_id: 10300483022,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الخليج',
      name_en: 'Al Khaleej Dist.',
    },
    {
      district_id: 10300483023,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10300483024,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي البندر',
      name_en: 'Al Bandar Dist.',
    },
    {
      district_id: 10300483025,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الامارة',
      name_en: 'Al Amarah Dist.',
    },
    {
      district_id: 10300483026,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الزهور',
      name_en: 'Al Zuhur Dist.',
    },
    {
      district_id: 10300483027,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الشعلة',
      name_en: 'Al Sholah Dist.',
    },
    {
      district_id: 10300483028,
      city_id: 483,
      region_id: 3,
      name_ar: 'الحرس الوطني',
      name_en: 'National Guard',
    },
    {
      district_id: 10300483029,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي التلال',
      name_en: 'Al Talal Dist.',
    },
    {
      district_id: 10300483030,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10300483031,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakheel Dist.',
    },
    {
      district_id: 10300483032,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10300483033,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10300483034,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي العصيلي',
      name_en: 'Al Usayli Dist.',
    },
    {
      district_id: 10300483035,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10300483036,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي السميري',
      name_en: 'Al Sumayri Dist.',
    },
    {
      district_id: 10300483037,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10300483038,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الفردوس',
      name_en: 'Al Ferdous Dist.',
    },
    {
      district_id: 10300483039,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي مشرفة',
      name_en: 'Mishrifah Dist.',
    },
    {
      district_id: 10300483040,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الجابرية',
      name_en: 'Al Jabriyah Dist.',
    },
    {
      district_id: 10300483041,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10300483042,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10300483043,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10300483044,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10300483045,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الحوراء',
      name_en: 'Al Hawra Dist.',
    },
    {
      district_id: 10300483046,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الصريف',
      name_en: 'Al Suraif Dist.',
    },
    {
      district_id: 10300483047,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي ينبع البلد',
      name_en: 'Yanbu Al Balad Dist.',
    },
    {
      district_id: 10300483048,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي البحيرة',
      name_en: 'Al Buhayrah Dist.',
    },
    {
      district_id: 10300483049,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الحدائق',
      name_en: 'Al Hadaek Dist.',
    },
    {
      district_id: 10300483050,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المجد',
      name_en: 'Al Majd Dist.',
    },
    {
      district_id: 10300483051,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10300483052,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10300483053,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الدانة',
      name_en: 'Al Dana Dist.',
    },
    {
      district_id: 10300483054,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الرابية',
      name_en: 'Al Rabiyah Dist.',
    },
    {
      district_id: 10300483055,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10300483056,
      city_id: 483,
      region_id: 3,
      name_ar: 'حى المطار',
      name_en: 'Airport',
    },
    {
      district_id: 10300483057,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الشفا',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 10300483058,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الروابي',
      name_en: 'Al Rawabi Dist.',
    },
    {
      district_id: 10300483059,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10300483060,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10300483061,
      city_id: 483,
      region_id: 3,
      name_ar: 'منطقة الايداع واعادة التصنيع',
      name_en: 'Bonded And Reexport Zone',
    },
    {
      district_id: 10300483062,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي العشيرة',
      name_en: 'Al Asheyrah Dist.',
    },
    {
      district_id: 10300483063,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي البثنة',
      name_en: 'Al Bathnah Dist.',
    },
    {
      district_id: 10300483064,
      city_id: 483,
      region_id: 3,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10300483065,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي النجيل',
      name_en: 'An Nujayl Dist.',
    },
    {
      district_id: 10300483066,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10300483067,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10300483068,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الريان',
      name_en: 'Al Rayyan Dist.',
    },
    {
      district_id: 10300483069,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي السويق',
      name_en: 'Al Sawaiq Dist.',
    },
    {
      district_id: 10300483070,
      city_id: 483,
      region_id: 3,
      name_ar: 'حى رضوى',
      name_en: 'Radwa Dist.',
    },
    {
      district_id: 10300483071,
      city_id: 483,
      region_id: 3,
      name_ar: 'حي الشاطئ',
      name_en: 'Al Shati Dist.',
    },
    {
      district_id: 10300777001,
      city_id: 777,
      region_id: 3,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10301053001,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10301053002,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي الغزوة',
      name_en: 'Al Ghazwah Dist.',
    },
    {
      district_id: 10301053003,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي العدوة',
      name_en: 'Al Adwah Dist.',
    },
    {
      district_id: 10301053004,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي الغزلاني',
      name_en: 'Al Ghuzlani Dist.',
    },
    {
      district_id: 10301053005,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي ادمان',
      name_en: 'Idman Dist.',
    },
    {
      district_id: 10301053006,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي الجديد',
      name_en: 'Al Jadid Dist.',
    },
    {
      district_id: 10301053007,
      city_id: 1053,
      region_id: 3,
      name_ar: 'حي الشهداء',
      name_en: 'Ash Shuhada Dist.',
    },
    {
      district_id: 10309605001,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الجابرية',
      name_en: 'Al Jabriyah Dist.',
    },
    {
      district_id: 10309605002,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10309605003,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الزهور',
      name_en: 'Al Zahur Dist.',
    },
    {
      district_id: 10309605004,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10309605005,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي المشيريف',
      name_en: 'Al Mushayrif Dist.',
    },
    {
      district_id: 10309605006,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي العيون',
      name_en: 'Al Oyoun Dist.',
    },
    {
      district_id: 10309605007,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10309605008,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10309605009,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10309605010,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10309605011,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي المهن',
      name_en: 'Al Mihan Dist.',
    },
    {
      district_id: 10309605012,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي النواة',
      name_en: 'Al Nawah Dist.',
    },
    {
      district_id: 10309605013,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي رضوى',
      name_en: 'Radwa Dist.',
    },
    {
      district_id: 10309605014,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي عين النوى',
      name_en: 'Ayn Al Nawa Dist.',
    },
    {
      district_id: 10309605015,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الفهد',
      name_en: 'Al Fahd Dist.',
    },
    {
      district_id: 10309605016,
      city_id: 9605,
      region_id: 3,
      name_ar: 'منطقة الصناعات الخفيفة',
      name_en: 'Light Industrial Park',
    },
    {
      district_id: 10309605017,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الصواري',
      name_en: 'Al Sawari Dist.',
    },
    {
      district_id: 10309605018,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي السميري',
      name_en: 'Al Sumayri Dist.',
    },
    {
      district_id: 10309605019,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الفيصل',
      name_en: 'Al Faisal Dist.',
    },
    {
      district_id: 10309605020,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الخالد',
      name_en: 'Al Khalid Dist.',
    },
    {
      district_id: 10309605021,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الجار',
      name_en: 'Al Jar Dist.',
    },
    {
      district_id: 10309605022,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي البثنة',
      name_en: 'Al Bathnah Dist.',
    },
    {
      district_id: 10309605023,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الصبح',
      name_en: 'Al Subh Dist.',
    },
    {
      district_id: 10309605024,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10309605025,
      city_id: 9605,
      region_id: 3,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10309605026,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10309605027,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10309605028,
      city_id: 9605,
      region_id: 3,
      name_ar: 'الواجهة البحرية',
      name_en: 'Sea Front',
    },
    {
      district_id: 10309605029,
      city_id: 9605,
      region_id: 3,
      name_ar: 'حي السلمانية',
      name_en: 'Al Salmaniyah Dist.',
    },
    {
      district_id: 10400011001,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmouk Dist.',
    },
    {
      district_id: 10400011002,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الرمال',
      name_en: 'Ar Rimal Dist.',
    },
    {
      district_id: 10400011003,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10400011004,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الخضر',
      name_en: 'Al Khudar Dist.',
    },
    {
      district_id: 10400011005,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي اللسيب',
      name_en: 'Al Lusayb Dist.',
    },
    {
      district_id: 10400011006,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النور',
      name_en: 'Al Nur Dist.',
    },
    {
      district_id: 10400011007,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي رواق',
      name_en: 'Rawaq Dist.',
    },
    {
      district_id: 10400011008,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي القصيعة',
      name_en: 'Al Qusayah Dist.',
    },
    {
      district_id: 10400011009,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10400011010,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10400011011,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي السالمية',
      name_en: 'As Salmiya Dist.',
    },
    {
      district_id: 10400011012,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الروابي',
      name_en: 'Al Rawabi Dist.',
    },
    {
      district_id: 10400011013,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي حويلان',
      name_en: 'Huwailan Dist.',
    },
    {
      district_id: 10400011014,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الصباخ',
      name_en: 'As Sabbakh Dist.',
    },
    {
      district_id: 10400011015,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي واسط',
      name_en: 'Wasit Dist.',
    },
    {
      district_id: 10400011016,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي خضيراء',
      name_en: 'Khudayra Dist.',
    },
    {
      district_id: 10400011017,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10400011018,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الجنوب',
      name_en: 'Al Janub Dist.',
    },
    {
      district_id: 10400011019,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10400011020,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الضاحي',
      name_en: 'Ad Dahi Dist.',
    },
    {
      district_id: 10400011021,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي خب البريدي',
      name_en: 'Khub Al Buraydi Dist.',
    },
    {
      district_id: 10400011022,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي السادة',
      name_en: 'As Sadah Dist.',
    },
    {
      district_id: 10400011023,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي العريمضي',
      name_en: 'Al Uraymdi Dist.',
    },
    {
      district_id: 10400011024,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الهلال',
      name_en: 'Al Hilal Dist.',
    },
    {
      district_id: 10400011025,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي العجيبة',
      name_en: 'Al Ujaybah Dist.',
    },
    {
      district_id: 10400011026,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10400011027,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10400011028,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الموطا',
      name_en: 'Al Mawta Dist.',
    },
    {
      district_id: 10400011029,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي التعليم',
      name_en: 'At Taalim Dist.',
    },
    {
      district_id: 10400011030,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الجردة',
      name_en: 'Al Jardah Dist.',
    },
    {
      district_id: 10400011031,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10400011032,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المريديسية',
      name_en: 'Al Muraydisiyyah Dist.',
    },
    {
      district_id: 10400011033,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الشروق',
      name_en: 'Al Shorouq Dist.',
    },
    {
      district_id: 10400011034,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي خب الثنيان',
      name_en: 'Khub Ath Thanyan Dist.',
    },
    {
      district_id: 10400011035,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الشماس',
      name_en: 'Ash Shammas Dist.',
    },
    {
      district_id: 10400011036,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10400011037,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10400011038,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الخبيب',
      name_en: 'Al Khabib Dist.',
    },
    {
      district_id: 10400011039,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المرقب',
      name_en: 'Al Marqab Dist.',
    },
    {
      district_id: 10400011040,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10400011041,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10400011042,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الزرقاء',
      name_en: 'Az Zarqa Dist.',
    },
    {
      district_id: 10400011043,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الحمر',
      name_en: 'Al Humar Dist.',
    },
    {
      district_id: 10400011044,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الشفق',
      name_en: 'Ash Shafaq Dist.',
    },
    {
      district_id: 10400011045,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي القاع البارد',
      name_en: 'Al Qaa Al Barid Dist.',
    },
    {
      district_id: 10400011046,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10400011047,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الوسيطا',
      name_en: 'Al Wusayta Dist.',
    },
    {
      district_id: 10400011048,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10400011049,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10400011050,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الصفراء',
      name_en: 'As Safra Dist.',
    },
    {
      district_id: 10400011051,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النقع',
      name_en: 'An Naqa Dist.',
    },
    {
      district_id: 10400011052,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الفايزية',
      name_en: 'Al Fayziyah Dist.',
    },
    {
      district_id: 10400011053,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10400011054,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الروضه',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10400011055,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النفل',
      name_en: 'An Nafl Dist.',
    },
    {
      district_id: 10400011056,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الإسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10400011057,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الأخضر',
      name_en: 'Al Akhdar Dist.',
    },
    {
      district_id: 10400011058,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الشقة',
      name_en: 'Ash Shiqah Dist.',
    },
    {
      district_id: 10400011059,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10400011060,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10400011061,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10400011062,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الافق',
      name_en: 'Al Ufuq Dist.',
    },
    {
      district_id: 10400011063,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10400011064,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10400011065,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10400011066,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10400011067,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10400011068,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouq Dist.',
    },
    {
      district_id: 10400011069,
      city_id: 11,
      region_id: 4,
      name_ar: 'حي التشليح',
      name_en: 'At Tashlih Dist.',
    },
    {
      district_id: 10400080001,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي حطين',
      name_en: 'Hiteen Dist.',
    },
    {
      district_id: 10400080002,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي سنام',
      name_en: 'Snam Dist.',
    },
    {
      district_id: 10400080003,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10400080004,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الدوحة',
      name_en: 'Ad Dawhah Dist.',
    },
    {
      district_id: 10400080005,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الحاجب',
      name_en: 'Al Hajeb Dist.',
    },
    {
      district_id: 10400080006,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الرمال',
      name_en: 'Ar Rimal Dist.',
    },
    {
      district_id: 10400080007,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الحفيرة',
      name_en: 'Al Hufayrah Dist.',
    },
    {
      district_id: 10400080008,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10400080009,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الملك خالد',
      name_en: 'King Khalid Dist.',
    },
    {
      district_id: 10400080010,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10400080011,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الرياضى',
      name_en: 'Ar Riyadi Dist.',
    },
    {
      district_id: 10400080012,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي القيروان',
      name_en: 'Al Qirawan Dist.',
    },
    {
      district_id: 10400080013,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10400080014,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10400080015,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10400080016,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10400080017,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10400080018,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulimaniyah Dist.',
    },
    {
      district_id: 10400080019,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الوسطى',
      name_en: 'Al Wusta Dist.',
    },
    {
      district_id: 10400080020,
      city_id: 80,
      region_id: 4,
      name_ar: 'المنطقة الصناعية الثانية',
      name_en: '2nd Industrial Area',
    },
    {
      district_id: 10400080021,
      city_id: 80,
      region_id: 4,
      name_ar: 'المنطقة الصناعية الاولى',
      name_en: '1st Industrial Area',
    },
    {
      district_id: 10400080022,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10400080023,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المستودعات',
      name_en: 'Warehouse Dist.',
    },
    {
      district_id: 10400080024,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الهداء',
      name_en: 'Al Hadaa Dist.',
    },
    {
      district_id: 10400080025,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10400080026,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المنتزة',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10400080027,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10400080028,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10400080029,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 10400080030,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي دمشية',
      name_en: 'Damshiah Dist.',
    },
    {
      district_id: 10400080031,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10400080032,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي العبسة',
      name_en: 'Al Absiah Dist.',
    },
    {
      district_id: 10400080033,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10400080034,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الوهلان',
      name_en: 'Al Wahlan Dist.',
    },
    {
      district_id: 10400080035,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي مليحة',
      name_en: 'Mlihah Dist.',
    },
    {
      district_id: 10400080036,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي مربيدة',
      name_en: 'Marbidah Dist.',
    },
    {
      district_id: 10400080037,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10400080038,
      city_id: 80,
      region_id: 4,
      name_ar: 'وسط المدينة',
      name_en: 'City Center',
    },
    {
      district_id: 10400080039,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10400080040,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الفاخرية',
      name_en: 'Al Fakhriyah Dist.',
    },
    {
      district_id: 10400080041,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي النقيلية',
      name_en: 'An Nuqailiah Dist.',
    },
    {
      district_id: 10400080042,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي السحيمية',
      name_en: 'As Suhaymiyyah Dist.',
    },
    {
      district_id: 10400080043,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 10400080044,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10400080045,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي اشبيلية',
      name_en: 'Ishbiliyah Dist.',
    },
    {
      district_id: 10400080046,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي العونية',
      name_en: 'Al Awniyah Dist.',
    },
    {
      district_id: 10400080047,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10400080048,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10400080050,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الجامعي',
      name_en: 'Al Jamie Dist.',
    },
    {
      district_id: 10400080051,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10400080052,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10400080053,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10400080054,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10400080055,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الاشرفية',
      name_en: 'Al Ashrafiyah Dist.',
    },
    {
      district_id: 10400080056,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10400080057,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الروغاني',
      name_en: 'Ar Rawghani Dist.',
    },
    {
      district_id: 10400080058,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10400080059,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الصفراء',
      name_en: 'As Safraa Dist.',
    },
    {
      district_id: 10400080060,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المباركية',
      name_en: 'Al Mubarkiah Dist.',
    },
    {
      district_id: 10400080061,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المدوية',
      name_en: 'Al Mudwiah Dist.',
    },
    {
      district_id: 10400080062,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10400080063,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي النظيم',
      name_en: 'An Nadheem Dist.',
    },
    {
      district_id: 10400080064,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي التحلية',
      name_en: 'At Tahliyah Dist.',
    },
    {
      district_id: 10400080065,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10400080066,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10400080067,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الوفاء',
      name_en: 'Al Wafa Dist.',
    },
    {
      district_id: 10400080068,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الزاهر',
      name_en: 'Az Zahir Dist.',
    },
    {
      district_id: 10400080069,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10400080070,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي سوق الماشية',
      name_en: 'Suq Al Mashiya Dist.',
    },
    {
      district_id: 10400080071,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الجلده',
      name_en: 'Al Jaldah Dist.',
    },
    {
      district_id: 10400080072,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 10400080073,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي ربوة الحاجب',
      name_en: 'Rabwat Al Hajeb Dist.',
    },
    {
      district_id: 10400080074,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الغضا',
      name_en: 'Al Kada Dist.',
    },
    {
      district_id: 10400080075,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الشعيب',
      name_en: 'Ash Shuaib Dist.',
    },
    {
      district_id: 10400080076,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي البويطن',
      name_en: 'Al Buaiten Dist.',
    },
    {
      district_id: 10400080077,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي النرجس',
      name_en: 'An Narjis Dist.',
    },
    {
      district_id: 10400080078,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي عكاظ',
      name_en: 'Uqaz Dist.',
    },
    {
      district_id: 10400080079,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي النفود',
      name_en: 'An Nofoad Dist.',
    },
    {
      district_id: 10400080080,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10400080081,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي القصور',
      name_en: 'Al Qusor Dist.',
    },
    {
      district_id: 10400080082,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الحديقة',
      name_en: 'Al Hadiqah Dist.',
    },
    {
      district_id: 10400080083,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10400080084,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10400080086,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الثقافة',
      name_en: 'Athaqafah Dist.',
    },
    {
      district_id: 10400080087,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10400080088,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الفخامة',
      name_en: 'Al Fakamah Dist.',
    },
    {
      district_id: 10400080089,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10400080090,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الزغيبة',
      name_en: 'Az Zugaibah Dist.',
    },
    {
      district_id: 10400080091,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10400080092,
      city_id: 80,
      region_id: 4,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10400955001,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10400955002,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10400955003,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10400955004,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الزهرة',
      name_en: 'Al Zahrah Dist.',
    },
    {
      district_id: 10400955005,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10400955006,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10400955007,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الازهار',
      name_en: 'Al Azhar Dist.',
    },
    {
      district_id: 10400955008,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzhah Dist.',
    },
    {
      district_id: 10400955009,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10400955010,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10400955011,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الاستراحات',
      name_en: 'Al Estrahat Dist.',
    },
    {
      district_id: 10400955012,
      city_id: 955,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10401986001,
      city_id: 1986,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10401986002,
      city_id: 1986,
      region_id: 4,
      name_ar: 'حي خصيبة',
      name_en: 'Khusayyibah Dist.',
    },
    {
      district_id: 10401986003,
      city_id: 1986,
      region_id: 4,
      name_ar: 'حي البرود',
      name_en: 'Al Burud Dist.',
    },
    {
      district_id: 10401986004,
      city_id: 1986,
      region_id: 4,
      name_ar: 'حي الفهيد',
      name_en: 'Al Fuhaid Dist.',
    },
    {
      district_id: 10401999001,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10401999002,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي المرقب',
      name_en: 'Al Marqab Dist.',
    },
    {
      district_id: 10401999003,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي التعليم',
      name_en: 'At Talim Dist.',
    },
    {
      district_id: 10401999004,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي الجردة',
      name_en: 'Al Jardah Dist.',
    },
    {
      district_id: 10401999005,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10401999006,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10401999007,
      city_id: 1999,
      region_id: 4,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10402421001,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10402421002,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10402421003,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 10402421004,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الاحتفالات',
      name_en: 'Al Ihtifalat Dist.',
    },
    {
      district_id: 10402421005,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402421006,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10402421007,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10402421008,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي التعليم',
      name_en: 'At Talim Dist.',
    },
    {
      district_id: 10402421009,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10402421010,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10402421011,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10402421012,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الجندل',
      name_en: 'Al Jandal Dist.',
    },
    {
      district_id: 10402421013,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي وادي الرمة',
      name_en: 'Wadi Ar Rimma Dist.',
    },
    {
      district_id: 10402421014,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الملك فيصل',
      name_en: 'King Faisal Dist.',
    },
    {
      district_id: 10402421015,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10402421016,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الزهرة',
      name_en: 'Az Zahrah Dist.',
    },
    {
      district_id: 10402421017,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الملك خالد',
      name_en: 'King Khalid Dist.',
    },
    {
      district_id: 10402421018,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 10402421019,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي قصر بن عقيل',
      name_en: 'Qasr Ibn Aqil Dist.',
    },
    {
      district_id: 10402421020,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الحوطة',
      name_en: 'Al Hutah Dist.',
    },
    {
      district_id: 10402421021,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي المدينة',
      name_en: 'Al Madinah Dist.',
    },
    {
      district_id: 10402421022,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10402421023,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10402421024,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الجريف',
      name_en: 'Al Juraif Dist.',
    },
    {
      district_id: 10402421025,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10402421026,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10402421027,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10402421028,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي بهجة',
      name_en: 'Bahjah Dist.',
    },
    {
      district_id: 10402421029,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10402421030,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الشنانة الشمالية',
      name_en: 'Al Shinanah Ash Shamaliyyah Dist.',
    },
    {
      district_id: 10402421031,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10402421032,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الالغربي',
      name_en: 'Al Gharbi Dist.',
    },
    {
      district_id: 10402421033,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan  Dist.',
    },
    {
      district_id: 10402421034,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10402421035,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي سوق الماشيه الجديد',
      name_en: 'Suq Mashiya Al Jadid Dist.',
    },
    {
      district_id: 10402421036,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10402421037,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10402421038,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10402421039,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10402421040,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10402421041,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10402421042,
      city_id: 2421,
      region_id: 4,
      name_ar: 'حي الشنانة الجنوبية',
      name_en: 'Al Shinanah Al Janubiyah Dist.',
    },
    {
      district_id: 10402448001,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10402448002,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10402448003,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10402448004,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الصفراء',
      name_en: 'As Safra Dist.',
    },
    {
      district_id: 10402448005,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10402448006,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الجديدة',
      name_en: 'Al Jadidah Dist.',
    },
    {
      district_id: 10402448007,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10402448008,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10402448009,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10402448010,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10402448011,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402448012,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10402448013,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10402448014,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10402448015,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي القفيفة',
      name_en: 'Al Qifayfah Dist.',
    },
    {
      district_id: 10402448016,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي ام عشيرة',
      name_en: 'Umm Ushayrah Dist.',
    },
    {
      district_id: 10402448017,
      city_id: 2448,
      region_id: 4,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10402467005,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10402467006,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10402467007,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10402467008,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10402467009,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10402467010,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي الافراح',
      name_en: 'Al Afrah Dist.',
    },
    {
      district_id: 10402467011,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10402467013,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10402467022,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي زبيدة',
      name_en: 'Zubaiydah Dist.',
    },
    {
      district_id: 10402467023,
      city_id: 2467,
      region_id: 4,
      name_ar: 'حي النفل',
      name_en: 'An Nafl Dist.',
    },
    {
      district_id: 10402481001,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي ام ثلعة',
      name_en: 'Umm Thalaah Dist.',
    },
    {
      district_id: 10402481002,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي العبيلة',
      name_en: 'Al Ubaylah Dist.',
    },
    {
      district_id: 10402481003,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي العثمانية',
      name_en: 'Al Uthmaniyah Dist.',
    },
    {
      district_id: 10402481004,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الوسطى',
      name_en: 'Al Wusta Dist.',
    },
    {
      district_id: 10402481005,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الشيرمي',
      name_en: 'Ash Shayrami Dist.',
    },
    {
      district_id: 10402481006,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي المستشفى',
      name_en: 'Al Mustashfa Dist.',
    },
    {
      district_id: 10402481007,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10402481008,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10402481009,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10402481010,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الرمة',
      name_en: 'Ar Rimmah Dist.',
    },
    {
      district_id: 10402481011,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402481012,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الاصفر',
      name_en: 'Al Asfar Dist.',
    },
    {
      district_id: 10402481013,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي رامات',
      name_en: 'Ramat Dist.',
    },
    {
      district_id: 10402481014,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10402481015,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي التعليم',
      name_en: 'At Taalim Dist.',
    },
    {
      district_id: 10402481016,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10402481017,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي العبدلية',
      name_en: 'Al Abdaliyah Dist.',
    },
    {
      district_id: 10402481018,
      city_id: 2481,
      region_id: 4,
      name_ar: 'حي الدحلة',
      name_en: 'Ad Dahlah Dist.',
    },
    {
      district_id: 10402486001,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي السحابين الشمالي',
      name_en: 'As Sahabin Ash Shamali Dist.',
    },
    {
      district_id: 10402486002,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الخبراء القديمة',
      name_en: 'Al Khabra Al Qadima Dist.',
    },
    {
      district_id: 10402486003,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10402486004,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10402486005,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10402486006,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10402486007,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي السحابين الجنوبي',
      name_en: 'As Sahabin Al Janubi Dist.',
    },
    {
      district_id: 10402486008,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10402486009,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10402486010,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الملاح',
      name_en: 'Al Mallah Dist.',
    },
    {
      district_id: 10402486011,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حى الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10402486012,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10402486013,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10402486014,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10402486015,
      city_id: 2486,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402629001,
      city_id: 2629,
      region_id: 4,
      name_ar: 'حي البيضاء',
      name_en: 'Al Baida Dist.',
    },
    {
      district_id: 10402629003,
      city_id: 2629,
      region_id: 4,
      name_ar: 'حي السلاح',
      name_en: 'As Silah Dist.',
    },
    {
      district_id: 10402630002,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الزهرة',
      name_en: 'Az Zahrah Dist.',
    },
    {
      district_id: 10402630003,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10402630004,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الهلالية',
      name_en: 'Al Halaliyah Dist.',
    },
    {
      district_id: 10402630005,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10402630006,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10402630007,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10402630008,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10402630009,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10402630010,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10402630011,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10402630012,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10402630013,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402630014,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10402630015,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الامل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10402630016,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الرياض',
      name_en: 'Ar Riyadh Dist.',
    },
    {
      district_id: 10402630017,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الديرة',
      name_en: 'Al Dirah Dist.',
    },
    {
      district_id: 10402630018,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي المجد',
      name_en: 'Al Majd Dist.',
    },
    {
      district_id: 10402630019,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10402630020,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10402630021,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10402630022,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي التقنية',
      name_en: 'Al Taqniyah Dist.',
    },
    {
      district_id: 10402630023,
      city_id: 2630,
      region_id: 4,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10402777001,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الخبيب',
      name_en: 'Al Khabib Dist.',
    },
    {
      district_id: 10402777002,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي المتحف',
      name_en: 'Al Mathaf Dist.',
    },
    {
      district_id: 10402777003,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10402777004,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10402777005,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الشماس',
      name_en: 'Al Shamas Dist.',
    },
    {
      district_id: 10402777006,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي البلاد',
      name_en: 'Al Bilad Dist.',
    },
    {
      district_id: 10402777008,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouq Dist.',
    },
    {
      district_id: 10402777009,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي برزة',
      name_en: 'Barzah Dist.',
    },
    {
      district_id: 10402777010,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الواسط',
      name_en: 'Al Wasit Dist.',
    },
    {
      district_id: 10402777011,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي البدع',
      name_en: 'Al Bada Dist.',
    },
    {
      district_id: 10402777012,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الجال',
      name_en: 'Al Jal Dist.',
    },
    {
      district_id: 10402777013,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10402777014,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الميدان',
      name_en: 'Al Midan Dist.',
    },
    {
      district_id: 10402777015,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10402777016,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10402777017,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10402777018,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10402777019,
      city_id: 2777,
      region_id: 4,
      name_ar: 'حي المتوكل',
      name_en: 'Al Mutawakil Dist.',
    },
    {
      district_id: 10402988001,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10402988002,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10402988003,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10402988004,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10402988005,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10402988006,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10402988007,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10402988008,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10402988009,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10402988010,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10402988011,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10402988012,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 10402988013,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10402988014,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 10402988015,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10402988016,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10402988017,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10402988018,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10402988019,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10402988020,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10402988021,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10402988022,
      city_id: 2988,
      region_id: 4,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10403030001,
      city_id: 3030,
      region_id: 4,
      name_ar: 'فيضة ابان الاحمر',
      name_en: 'Faidat Aban Alahmar',
    },
    {
      district_id: 10403030002,
      city_id: 3030,
      region_id: 4,
      name_ar: 'النويصفة',
      name_en: 'Al Nuwaisifah',
    },
    {
      district_id: 10403030003,
      city_id: 3030,
      region_id: 4,
      name_ar: 'مشرفة عمودان',
      name_en: 'Mishrifat Amudan',
    },
    {
      district_id: 10403030004,
      city_id: 3030,
      region_id: 4,
      name_ar: 'كفت',
      name_en: 'Kofat',
    },
    {
      district_id: 10403151001,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10403151002,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10403151003,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10403151004,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10403151005,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10403151006,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10403151007,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10403151008,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10403151009,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي ج1',
      name_en: 'C1 Dist.',
    },
    {
      district_id: 10403151010,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10403151011,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10403151012,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الرمثية',
      name_en: 'Ar Ramthyah Dist.',
    },
    {
      district_id: 10403151013,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الغربية',
      name_en: 'Al Gharbiyyah Dist.',
    },
    {
      district_id: 10403151014,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10403151015,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10403151016,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي حطين',
      name_en: 'Hiteen Dist.',
    },
    {
      district_id: 10403151017,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10403151018,
      city_id: 3151,
      region_id: 4,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10410872001,
      city_id: 10872,
      region_id: 4,
      name_ar: 'فيضة السلمات',
      name_en: 'Faidat Al Salamat',
    },
    {
      district_id: 10410872002,
      city_id: 10872,
      region_id: 4,
      name_ar: 'المرموثة الشمالية',
      name_en: 'Al Marmuthah Ash Shamaliyah',
    },
    {
      district_id: 10410872003,
      city_id: 10872,
      region_id: 4,
      name_ar: 'الخريشاء',
      name_en: 'Al Kharisha',
    },
    {
      district_id: 10410872004,
      city_id: 10872,
      region_id: 4,
      name_ar: 'المديري',
      name_en: 'Al Mudairy',
    },
    {
      district_id: 10500012001,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الزهرة',
      name_en: 'Az Zahrah Dist.',
    },
    {
      district_id: 10500012002,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المربدية',
      name_en: 'Al Marbidia Dist.',
    },
    {
      district_id: 10500012003,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10500012004,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10500012005,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الورود الثاني',
      name_en: 'Al Wurud 2nd Dist.',
    },
    {
      district_id: 10500012006,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المهندسين',
      name_en: 'Al Muhandisin Dist.',
    },
    {
      district_id: 10500012007,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي عين موسى',
      name_en: 'Ayn Musa Dist.',
    },
    {
      district_id: 10500012008,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الصقور',
      name_en: 'As Suqur Dist.',
    },
    {
      district_id: 10500012009,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500012010,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10500012011,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الثليثية',
      name_en: 'Al Thulaythiyah Dist.',
    },
    {
      district_id: 10500012012,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المعلمين',
      name_en: 'Al Muallimeen Dist.',
    },
    {
      district_id: 10500012013,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العاصمة',
      name_en: 'Al Asimah Dist.',
    },
    {
      district_id: 10500012014,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الأمراء',
      name_en: 'Al Omaraa Dist.',
    },
    {
      district_id: 10500012015,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10500012016,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العزيزية الثاني',
      name_en: 'Al Aziziyah 2nd Dist.',
    },
    {
      district_id: 10500012017,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحمراء الثالث',
      name_en: 'Al Hamra 3rd Dist.',
    },
    {
      district_id: 10500012018,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المرقاب',
      name_en: 'Al Mirqab Dist.',
    },
    {
      district_id: 10500012019,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10500012020,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحزام الأخضر',
      name_en: 'Al Hizam Al Akhdar Dist.',
    },
    {
      district_id: 10500012021,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الجامعيين',
      name_en: 'Al Jamiyin Dist.',
    },
    {
      district_id: 10500012022,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500012023,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500012024,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الصحافة',
      name_en: 'As Sahafah Dist.',
    },
    {
      district_id: 10500012026,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي ام خريسان',
      name_en: 'Um Khraisan Dist.',
    },
    {
      district_id: 10500012027,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10500012028,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحمراء الثاني',
      name_en: 'Al Hamra 2nd Dist.',
    },
    {
      district_id: 10500012029,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي البدرية',
      name_en: 'Al Badriyah Dist.',
    },
    {
      district_id: 10500012030,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السنيدية',
      name_en: 'As Sunaidah Dist.',
    },
    {
      district_id: 10500012031,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10500012032,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي لذه',
      name_en: 'Ladhah Dist.',
    },
    {
      district_id: 10500012033,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي مجمع الدوائر الحكومية الثاني',
      name_en: 'Government Departments Complex 2nd  Dist.',
    },
    {
      district_id: 10500012034,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المزروع الثاني',
      name_en: 'Al Mazrooa 2nd Dist.',
    },
    {
      district_id: 10500012035,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الورود الثالث',
      name_en: 'Al Wurud 3rd Dist.',
    },
    {
      district_id: 10500012036,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الربوه',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10500012037,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرويضة',
      name_en: 'Ar Ruwaida Dist.',
    },
    {
      district_id: 10500012038,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرفيعة',
      name_en: 'Ar Rafiah Dist.',
    },
    {
      district_id: 10500012039,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحوراء',
      name_en: 'Al Hawra Dist.',
    },
    {
      district_id: 10500012040,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرقيقة',
      name_en: 'Ar Ruqaiqah Dist.',
    },
    {
      district_id: 10500012041,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المزروع الاول',
      name_en: 'Al Mazrooa 1st Dist.',
    },
    {
      district_id: 10500012042,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي جوبا',
      name_en: 'Juba Dist.',
    },
    {
      district_id: 10500012043,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي البصيرة',
      name_en: 'Al Busairah Dist.',
    },
    {
      district_id: 10500012044,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المروج الجنوبي',
      name_en: 'Al Muruj Southern Dist.',
    },
    {
      district_id: 10500012045,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaimaniyah Dist.',
    },
    {
      district_id: 10500012046,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحمراء الأول',
      name_en: 'Al Hamra 1st Dist.',
    },
    {
      district_id: 10500012047,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الشهابية',
      name_en: 'Ash Shihabiyah Dist.',
    },
    {
      district_id: 10500012048,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العزيزية الأول',
      name_en: 'Al Aziziyah 1st Dist.',
    },
    {
      district_id: 10500012049,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي جامعة الملك فيصل الأول',
      name_en: 'King Faisal University 1st Dist.',
    },
    {
      district_id: 10500012050,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10500012051,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي هجر',
      name_en: 'Hajr Dist.',
    },
    {
      district_id: 10500012052,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي منسوبي التعليم الأول',
      name_en: 'Mansubi Al Taleem 1st Dist.',
    },
    {
      district_id: 10500012053,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي منيفة',
      name_en: 'Munifah Dist.',
    },
    {
      district_id: 10500012054,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي البندرية',
      name_en: 'Al Bandariyah Dist.',
    },
    {
      district_id: 10500012055,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العويمرية',
      name_en: 'Al Uwaimiriyah Dist.',
    },
    {
      district_id: 10500012056,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي النايفية',
      name_en: 'An Nayfiyah Dist.',
    },
    {
      district_id: 10500012057,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10500012058,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الدوحة',
      name_en: 'Al Doha Dist.',
    },
    {
      district_id: 10500012059,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المثلث',
      name_en: 'Al Muthalath Dist.',
    },
    {
      district_id: 10500012060,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10500012061,
      city_id: 12,
      region_id: 5,
      name_ar: 'منطقة الغويبة الزراعية',
      name_en: 'Alghawbia Agricultural Area',
    },
    {
      district_id: 10500012062,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الوسيطة',
      name_en: 'Al Wasitah Dist.',
    },
    {
      district_id: 10500012063,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السلمانية الثاني',
      name_en: 'Al Salmaniyah 2nd Dist.',
    },
    {
      district_id: 10500012064,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الشغيبية',
      name_en: 'Al Shagaibiyah Dist.',
    },
    {
      district_id: 10500012065,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي جامعة الملك فيصل الثالث',
      name_en: 'King Faisal University 3rd Dist.',
    },
    {
      district_id: 10500012066,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الإسكان الأول',
      name_en: 'Al Iskan 1st Dist.',
    },
    {
      district_id: 10500012067,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي صويدرة',
      name_en: 'As Suwaidirah Dist.',
    },
    {
      district_id: 10500012068,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10500012069,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السلمانية الرابع',
      name_en: 'Al Salmaniyah 4th Dist.',
    },
    {
      district_id: 10500012070,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي منسوبي التعليم الثاني',
      name_en: 'Mansubi Al Taleem 2nd Dist.',
    },
    {
      district_id: 10500012071,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الورود الأول',
      name_en: 'Al Wurud 1st Dist.',
    },
    {
      district_id: 10500012072,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الفيصلية الثاني',
      name_en: 'Al Faisaliyah 2nd Dist.',
    },
    {
      district_id: 10500012073,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الناصرية',
      name_en: 'An Nasriyah Dist.',
    },
    {
      district_id: 10500012074,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي اللويمي',
      name_en: 'Al Luwaymi Dist.',
    },
    {
      district_id: 10500012075,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الخدود',
      name_en: 'Al Khudud Dist.',
    },
    {
      district_id: 10500012076,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السلمانية الثالث',
      name_en: 'Al Salmaniyah 3rd Dist.',
    },
    {
      district_id: 10500012077,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المزروعية',
      name_en: 'Al Mazruiyah Dist.',
    },
    {
      district_id: 10500012078,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المجيدية',
      name_en: 'Al Majidiyah Dist.',
    },
    {
      district_id: 10500012079,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10500012080,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10500012081,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الدانة',
      name_en: 'Al Danah Dist.',
    },
    {
      district_id: 10500012082,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الفردوس',
      name_en: 'Al Fardus Dist.',
    },
    {
      district_id: 10500012083,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السيفة',
      name_en: 'As Saifa Dist.',
    },
    {
      district_id: 10500012084,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الحفيرة',
      name_en: 'Al Hufayrah Dist.',
    },
    {
      district_id: 10500012085,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي السلمانية الأول',
      name_en: 'Al Salmaniyah 1st Dist.',
    },
    {
      district_id: 10500012086,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10500012087,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المباركية',
      name_en: 'Al Mubarakiyah Dist.',
    },
    {
      district_id: 10500012088,
      city_id: 12,
      region_id: 5,
      name_ar: 'جامعة الامام محمد بن سعود الاسلامية',
      name_en: 'Imam Muhammed Ibn Saud Islamic University',
    },
    {
      district_id: 10500012089,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الفيصلية الأول',
      name_en: 'Al Faisaliyah 1st Dist.',
    },
    {
      district_id: 10500012090,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرفعة الجنوبية',
      name_en: 'Ar Refah Southern Dist.',
    },
    {
      district_id: 10500012091,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي جامعة الملك فيصل الثاني',
      name_en: 'King Faisal University 2nd Dist.',
    },
    {
      district_id: 10500012092,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الإسكان الثاني',
      name_en: 'Al Iskan 2nd Dist.',
    },
    {
      district_id: 10500012093,
      city_id: 12,
      region_id: 5,
      name_ar: 'المدينة الرياضية',
      name_en: 'Sports City',
    },
    {
      district_id: 10500012094,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي المروج الشمالي',
      name_en: 'Al Muruj Northern Dist.',
    },
    {
      district_id: 10500012095,
      city_id: 12,
      region_id: 5,
      name_ar: 'مطار الأحساء',
      name_en: 'Al Ahsa Airport',
    },
    {
      district_id: 10500012096,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الطالعية',
      name_en: 'Al Talayah Dist.',
    },
    {
      district_id: 10500012097,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي النعاثل',
      name_en: 'Al Naathel Dist.',
    },
    {
      district_id: 10500012098,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الكوت',
      name_en: 'Al Koot Dist.',
    },
    {
      district_id: 10500012099,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الرفعة الشمالية',
      name_en: 'Ar Refah Northern Dist.',
    },
    {
      district_id: 10500012100,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10500012101,
      city_id: 12,
      region_id: 5,
      name_ar: 'حي العسيلة',
      name_en: 'Al Aseelah Dist.',
    },
    {
      district_id: 10500013001,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الخالدية الشمالية',
      name_en: 'Al Khalidiyah Ash Shamaliyah Dist.',
    },
    {
      district_id: 10500013002,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الناصرية',
      name_en: 'An Nasriyah Dist.',
    },
    {
      district_id: 10500013003,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الصناعية الاولى',
      name_en: '1st Industrial Dist.',
    },
    {
      district_id: 10500013004,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الفنار',
      name_en: 'Al Fanar Dist.',
    },
    {
      district_id: 10500013005,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الأثير',
      name_en: 'Al Athir Dist.',
    },
    {
      district_id: 10500013006,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الجلوية',
      name_en: 'Al Jalawiyah Dist.',
    },
    {
      district_id: 10500013007,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10500013008,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي القزاز',
      name_en: 'Al Qazaz Dist.',
    },
    {
      district_id: 10500013009,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي البادية',
      name_en: 'Al Badiyah Dist.',
    },
    {
      district_id: 10500013010,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الدواسر',
      name_en: 'Ad Dawasir Dist.',
    },
    {
      district_id: 10500013011,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي العنود',
      name_en: 'Al Anud Dist.',
    },
    {
      district_id: 10500013012,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الشاطئ الغربي',
      name_en: 'Ash Shati Al Gharbi Dist.',
    },
    {
      district_id: 10500013013,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الضباب',
      name_en: 'Ad Dabab Dist.',
    },
    {
      district_id: 10500013014,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10500013015,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 10500013016,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10500013017,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10500013018,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المريكبات',
      name_en: 'Al Muraikabat Dist.',
    },
    {
      district_id: 10500013019,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10500013020,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي ضاحية الملك فهد',
      name_en: 'Dahiyat Al Malik Fahd Dist.',
    },
    {
      district_id: 10500013021,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي السيف',
      name_en: 'As Saif Dist.',
    },
    {
      district_id: 10500013022,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500013023,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي احد',
      name_en: 'Uhud Dist.',
    },
    {
      district_id: 10500013024,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10500013025,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10500013026,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500013027,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي طيبة',
      name_en: 'Taibah Dist.',
    },
    {
      district_id: 10500013028,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الجامعيين',
      name_en: 'Al Jamiyin Dist.',
    },
    {
      district_id: 10500013029,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10500013030,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10500013031,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500013032,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي العدامة',
      name_en: 'Al Adamah Dist.',
    },
    {
      district_id: 10500013033,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الامير محمد بن سعود',
      name_en: 'Prince Muhammed Ibn Saud Dist.',
    },
    {
      district_id: 10500013034,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الطبيشي',
      name_en: 'At Tubayshi Dist.',
    },
    {
      district_id: 10500013035,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المزروعية',
      name_en: 'Al Mazruiyah Dist.',
    },
    {
      district_id: 10500013036,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10500013037,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10500013038,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10500013039,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الفردوس',
      name_en: 'Al Firdaws Dist.',
    },
    {
      district_id: 10500013040,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي قصر الخليج',
      name_en: 'Qasr Al Khalij Dist.',
    },
    {
      district_id: 10500013041,
      city_id: 13,
      region_id: 5,
      name_ar: 'المدينة الصناعية الثانية',
      name_en: '2nd Industrial City',
    },
    {
      district_id: 10500013042,
      city_id: 13,
      region_id: 5,
      name_ar: 'مطار الملك فهد الدولي',
      name_en: 'King Fahd International Airport',
    },
    {
      district_id: 10500013043,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الخضرية',
      name_en: 'Al Kuthriah Dist.',
    },
    {
      district_id: 10500013044,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي مدينة العمال',
      name_en: 'Madinat Al Ummal Dist.',
    },
    {
      district_id: 10500013045,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي العمامرة',
      name_en: 'Al Amamrah Dist.',
    },
    {
      district_id: 10500013046,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي البديع',
      name_en: 'Al Badi Dist.',
    },
    {
      district_id: 10500013047,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الشاطئ الشرقي',
      name_en: 'Ash Shati Ash Sharqi Dist.',
    },
    {
      district_id: 10500013048,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10500013049,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10500013050,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الراكة الشمالية',
      name_en: 'Ar Rakah Ash Shamaliyah Dist.',
    },
    {
      district_id: 10500013051,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النورس',
      name_en: 'An Nawras Dist.',
    },
    {
      district_id: 10500013052,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10500013053,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الاتصالات',
      name_en: 'Al Itisalat Dist.',
    },
    {
      district_id: 10500013054,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي ابن خلدون',
      name_en: 'Ibn Khaldun Dist.',
    },
    {
      district_id: 10500013055,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي السوق',
      name_en: 'As Suq Dist.',
    },
    {
      district_id: 10500013056,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10500013057,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10500013058,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي البحيرة',
      name_en: 'Al Buhayrah Dist.',
    },
    {
      district_id: 10500013059,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10500013060,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10500013061,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الدانة',
      name_en: 'Ad Danah Dist.',
    },
    {
      district_id: 10500013062,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10500013063,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10500013064,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500013065,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10500013066,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10500013068,
      city_id: 13,
      region_id: 5,
      name_ar: 'مدينة الملك فيصل الجامعية',
      name_en: 'King Faisal University',
    },
    {
      district_id: 10500013069,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الحسام',
      name_en: 'Al Hussam Dist.',
    },
    {
      district_id: 10500013070,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي ميناء الملك عبدالعزيز',
      name_en: 'King Abdul Aziz Seaport Dist.',
    },
    {
      district_id: 10500013071,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10500013072,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الخالدية الجنوبية',
      name_en: 'Al Khalidiyah Al Janubiyah Dist.',
    },
    {
      district_id: 10500013073,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الشعلة',
      name_en: 'Ash Shulah Dist.',
    },
    {
      district_id: 10500013074,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الأنوار',
      name_en: 'Al Anwar Dist.',
    },
    {
      district_id: 10500013075,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الأمل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10500013076,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الشرق',
      name_en: 'Ash Sharq Dist.',
    },
    {
      district_id: 10500013077,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الهضبة',
      name_en: 'Al Hadabah Dist.',
    },
    {
      district_id: 10500013078,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10500013079,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الأمانة',
      name_en: 'Al Amanah Dist.',
    },
    {
      district_id: 10500013080,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الفرسان',
      name_en: 'Al Fursan Dist.',
    },
    {
      district_id: 10500013081,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي المها',
      name_en: 'Al Maha Dist.',
    },
    {
      district_id: 10500013082,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 10500013083,
      city_id: 13,
      region_id: 5,
      name_ar: 'المدينة الصناعية الثالثة',
      name_en: '3rd Industrial City',
    },
    {
      district_id: 10500013084,
      city_id: 13,
      region_id: 5,
      name_ar: 'مدينة الملك سلمان للطاقة',
      name_en: 'King Salman Energy Park',
    },
    {
      district_id: 10500013085,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الإسكان الجنوبي',
      name_en: 'Al Iskan Al Janubi Dist.',
    },
    {
      district_id: 10500013086,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10500013087,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الاسكان الشمالي',
      name_en: 'Al Iskan Northren Dist.',
    },
    {
      district_id: 10500013088,
      city_id: 13,
      region_id: 5,
      name_ar: 'حي الجود',
      name_en: 'Al Jud Dist.',
    },
    {
      district_id: 10500031001,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي التحلية',
      name_en: 'At Tahliyah Dist.',
    },
    {
      district_id: 10500031002,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي ابن سيناء',
      name_en: 'Ibn Sina Dist.',
    },
    {
      district_id: 10500031003,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الحزام الاخضر',
      name_en: 'Al Hizam Al Akhdar Dist.',
    },
    {
      district_id: 10500031004,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي صناعية الثقبة',
      name_en: 'Sinaiyah Ath Thuqbah Dist.',
    },
    {
      district_id: 10500031005,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10500031006,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الراكة الجنوبية',
      name_en: 'Ar Rakah Al Janubiyah Dist.',
    },
    {
      district_id: 10500031007,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الخبر الشمالية',
      name_en: 'Al Khubar Ash Shamaliyah Dist.',
    },
    {
      district_id: 10500031008,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي مدينة العمال',
      name_en: 'Madinat Al Ummal Dist.',
    },
    {
      district_id: 10500031009,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي العقربية',
      name_en: 'Al Aqrabiyah Dist.',
    },
    {
      district_id: 10500031010,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الخبر الجنوبية',
      name_en: 'Al Khubar Al Janubiyah Dist.',
    },
    {
      district_id: 10500031011,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10500031012,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmok Dist.',
    },
    {
      district_id: 10500031013,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10500031014,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10500031015,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الحزام الذهبي',
      name_en: 'Al Hizam Adh Dhahabi Dist.',
    },
    {
      district_id: 10500031016,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10500031017,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 10500031018,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي البندرية',
      name_en: 'Al Bandariyah Dist.',
    },
    {
      district_id: 10500031019,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10500031020,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الثقبة',
      name_en: 'Ath Thuqbah Dist.',
    },
    {
      district_id: 10500031021,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10500031022,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10500031023,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10500031024,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الجسر',
      name_en: 'Al Jisr Dist.',
    },
    {
      district_id: 10500031025,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10500031026,
      city_id: 31,
      region_id: 5,
      name_ar: 'المدينة الرياضية',
      name_en: 'Sports City',
    },
    {
      district_id: 10500031027,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي البحر',
      name_en: 'Al Bahar Dist.',
    },
    {
      district_id: 10500031028,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الكورنيش',
      name_en: 'Al Kurnaish Dist.',
    },
    {
      district_id: 10500031029,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي صناعية الفوازية',
      name_en: 'Sinaiyah Al Fawaziyah Dist.',
    },
    {
      district_id: 10500031030,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الساحل',
      name_en: 'As Sahil Dist.',
    },
    {
      district_id: 10500031031,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الخور',
      name_en: 'Al Khawr Dist.',
    },
    {
      district_id: 10500031032,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي اشبيليا',
      name_en: 'Ishbiliya Dist.',
    },
    {
      district_id: 10500031033,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي البحيرة',
      name_en: 'Al Buhayrah Dist.',
    },
    {
      district_id: 10500031034,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الشراع',
      name_en: 'Ash Sheraa Dist.',
    },
    {
      district_id: 10500031035,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الكوثر',
      name_en: 'Al Kawthar Dist.',
    },
    {
      district_id: 10500031036,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الرجاء',
      name_en: 'Ar Raja Dist.',
    },
    {
      district_id: 10500031037,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الصواري',
      name_en: 'As Sawari Dist.',
    },
    {
      district_id: 10500031038,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10500031039,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي المها',
      name_en: 'Al Maha Dist.',
    },
    {
      district_id: 10500031040,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الامواج',
      name_en: 'Al Amwaj Dist.',
    },
    {
      district_id: 10500031041,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي اللؤلؤ',
      name_en: 'Al Lulu Dist.',
    },
    {
      district_id: 10500031042,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 10500031043,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي السفن',
      name_en: 'As Sufun Dist.',
    },
    {
      district_id: 10500031044,
      city_id: 31,
      region_id: 5,
      name_ar: 'حي الصدفة',
      name_en: 'As Sadafah Dist.',
    },
    {
      district_id: 10500047001,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10500047002,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10500047003,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10500047004,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10500047005,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 10500047006,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10500047007,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10500047008,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي البلدية',
      name_en: 'Al Baladiyah Dist.',
    },
    {
      district_id: 10500047009,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500047010,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10500047011,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10500047012,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10500047013,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الباطن',
      name_en: 'Al Batin Dist.',
    },
    {
      district_id: 10500047014,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10500047015,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10500047016,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10500047017,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10500047018,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي فليج',
      name_en: 'Fulaij Dist.',
    },
    {
      district_id: 10500047019,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10500047020,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500047021,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulimaniyah Dist.',
    },
    {
      district_id: 10500047022,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10500047023,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10500047024,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10500047025,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10500047026,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 10500047027,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي النايفية',
      name_en: 'An Nayfiyah Dist.',
    },
    {
      district_id: 10500047028,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10500047029,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10500047030,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500047031,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10500047032,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500047033,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي ابو موسى الاشعري',
      name_en: 'Abu Musa Al Ashari Dist.',
    },
    {
      district_id: 10500047034,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10500047035,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الرائد',
      name_en: 'Ar Raid Dist.',
    },
    {
      district_id: 10500047036,
      city_id: 47,
      region_id: 5,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10500055001,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500055002,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10500055003,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10500055004,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10500055005,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10500055006,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10500055007,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10500055008,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الهداية',
      name_en: 'Al Hidaya Dist.',
    },
    {
      district_id: 10500055009,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500055010,
      city_id: 55,
      region_id: 5,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10500067003,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البديع',
      name_en: 'Al Badi Dist.',
    },
    {
      district_id: 10500067004,
      city_id: 67,
      region_id: 5,
      name_ar: 'منطقة المستودعات',
      name_en: 'Warehouse Area',
    },
    {
      district_id: 10500067005,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المجيدية',
      name_en: 'Al Majidiyah Dist.',
    },
    {
      district_id: 10500067006,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 10500067007,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10500067008,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500067009,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10500067010,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500067011,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500067012,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10500067013,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الرضا',
      name_en: 'Ar Rida Dist.',
    },
    {
      district_id: 10500067014,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الجارودية',
      name_en: 'Al Jarudiyah Dist.',
    },
    {
      district_id: 10500067015,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي حلة محيش',
      name_en: 'Halat Mahish Dist.',
    },
    {
      district_id: 10500067016,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الياقوت',
      name_en: 'Al Yaqut Dist.',
    },
    {
      district_id: 10500067017,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 10500067018,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الدبابية',
      name_en: 'Ad Dababiyyah Dist.',
    },
    {
      district_id: 10500067019,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الخامسة',
      name_en: 'Al Khamisah Dist.',
    },
    {
      district_id: 10500067020,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي ام الجزم',
      name_en: 'Umm Al Jazm Dist.',
    },
    {
      district_id: 10500067021,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي بديعة البصري',
      name_en: 'Badiah Al Basri Dist.',
    },
    {
      district_id: 10500067022,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المشتل',
      name_en: 'Al Mashtal Dist.',
    },
    {
      district_id: 10500067023,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الزمرد',
      name_en: 'Az Zomorod Dist.',
    },
    {
      district_id: 10500067024,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الناصرة',
      name_en: 'An Nasirah Dist.',
    },
    {
      district_id: 10500067025,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البحاري',
      name_en: 'Al Bahari Dist.',
    },
    {
      district_id: 10500067026,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي مياس',
      name_en: 'Mayyas Dist.',
    },
    {
      district_id: 10500067027,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الجواهر',
      name_en: 'Al Jawahir Dist.',
    },
    {
      district_id: 10500067028,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10500067029,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي التوبي',
      name_en: 'At Tawbi Dist.',
    },
    {
      district_id: 10500067030,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الجراري',
      name_en: 'Al Jarrari Dist.',
    },
    {
      district_id: 10500067031,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الشهباء',
      name_en: 'Ash Shahba Dist.',
    },
    {
      district_id: 10500067032,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي القديح',
      name_en: 'Al Qadih Dist.',
    },
    {
      district_id: 10500067033,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المدارس',
      name_en: 'Al Madaris Dist.',
    },
    {
      district_id: 10500067034,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الشماسيه',
      name_en: 'Ash Shamasiyyah Dist.',
    },
    {
      district_id: 10500067035,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي باب الساب',
      name_en: 'Bab As Sab Dist.',
    },
    {
      district_id: 10500067036,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الجميمة',
      name_en: 'Al Jumaimah Dist.',
    },
    {
      district_id: 10500067037,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المسعودية',
      name_en: 'Al Masudiyah Dist.',
    },
    {
      district_id: 10500067038,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الخويلدية',
      name_en: 'Al Khuwaildiyah Dist.',
    },
    {
      district_id: 10500067039,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي العوامية',
      name_en: 'Al Awamiyah Dist.',
    },
    {
      district_id: 10500067040,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الدويج',
      name_en: 'Ad Doij Dist.',
    },
    {
      district_id: 10500067041,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الرابعة',
      name_en: 'Ar Rabiah Dist.',
    },
    {
      district_id: 10500067042,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10500067043,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10500067044,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الشريعة',
      name_en: 'Ash Shariah Dist.',
    },
    {
      district_id: 10500067045,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الوسادة',
      name_en: 'Al Wasadah Dist.',
    },
    {
      district_id: 10500067046,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي القلعة',
      name_en: 'Al Qalah Dist.',
    },
    {
      district_id: 10500067047,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البوادي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 10500067048,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الحسين',
      name_en: 'Al Husain Dist.',
    },
    {
      district_id: 10500067049,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي المدني',
      name_en: 'Al Madani Dist.',
    },
    {
      district_id: 10500067050,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الثريا',
      name_en: 'Ath Thuraiya Dist.',
    },
    {
      district_id: 10500067051,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الريف',
      name_en: 'Ar Rif Dist.',
    },
    {
      district_id: 10500067052,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي باب الشمال',
      name_en: 'Bab Madinah Ash Shamal Dist.',
    },
    {
      district_id: 10500067053,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي البحر',
      name_en: 'Al Bahr Dist.',
    },
    {
      district_id: 10500067054,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الشويكة',
      name_en: 'Ash Shweikah Dist.',
    },
    {
      district_id: 10500067055,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الوديعه',
      name_en: 'Al Wadiah Dist.',
    },
    {
      district_id: 10500067056,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الكويكب',
      name_en: 'Kuwaikib Dist.',
    },
    {
      district_id: 10500067057,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي النرجس',
      name_en: 'An Narjis Dist.',
    },
    {
      district_id: 10500067058,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10500067059,
      city_id: 67,
      region_id: 5,
      name_ar: 'حي الزارة',
      name_en: 'Az Zarah Dist.',
    },
    {
      district_id: 10500089001,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الرفاع',
      name_en: 'Ar Rifaa Dist.',
    },
    {
      district_id: 10500089002,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10500089003,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500089004,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500089005,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10500089006,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500089007,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10500089008,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي قصر عالي',
      name_en: 'Qasr Aali Dist.',
    },
    {
      district_id: 10500089009,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10500089010,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10500089011,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10500089012,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500089013,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10500089014,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10500089015,
      city_id: 89,
      region_id: 5,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10500113001,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي المرقاب',
      name_en: 'Al Merqab Dist.',
    },
    {
      district_id: 10500113002,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي طيبة',
      name_en: 'Tebah Dist.',
    },
    {
      district_id: 10500113004,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10500113005,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الضباب',
      name_en: 'Al Dabab Dist.',
    },
    {
      district_id: 10500113006,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10500113007,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الطوية',
      name_en: 'Al Tawyah Dist.',
    },
    {
      district_id: 10500113008,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الدانة',
      name_en: 'Al Dana Dist.',
    },
    {
      district_id: 10500113009,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الصفاة',
      name_en: 'Al Safat Dist.',
    },
    {
      district_id: 10500113010,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي صناعية العريفي',
      name_en: 'Oraifi Industrial Dist.',
    },
    {
      district_id: 10500113011,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10500113012,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10500113013,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي التعاون',
      name_en: 'Al Taawun Dist.',
    },
    {
      district_id: 10500113014,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي ابو موسى الاشعري',
      name_en: 'Abu Musa Alashary Dist.',
    },
    {
      district_id: 10500113015,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawhara Dist.',
    },
    {
      district_id: 10500113016,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500113017,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10500113018,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10500113019,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي البحر',
      name_en: 'Al Bahar Dist.',
    },
    {
      district_id: 10500113020,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي اشبلية',
      name_en: 'Ishbiliyah Dist.',
    },
    {
      district_id: 10500113021,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10500113022,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10500113023,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10500113024,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Cordoba Dist.',
    },
    {
      district_id: 10500113025,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الحويلات',
      name_en: 'Al Huwaylat Dist.',
    },
    {
      district_id: 10500113026,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي اللؤلؤ',
      name_en: 'Al Lualu Dist.',
    },
    {
      district_id: 10500113027,
      city_id: 113,
      region_id: 5,
      name_ar: 'المنطقة الصناعية الاولى',
      name_en: '1st Industrial Area',
    },
    {
      district_id: 10500113028,
      city_id: 113,
      region_id: 5,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10500113029,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 10500113030,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الاحساء',
      name_en: 'Al Ahsa Dist.',
    },
    {
      district_id: 10500113031,
      city_id: 113,
      region_id: 5,
      name_ar: 'المنطقة الصناعية الثانية',
      name_en: '2nd Industrial Area',
    },
    {
      district_id: 10500113032,
      city_id: 113,
      region_id: 5,
      name_ar: 'المنطقة الصناعية الثالثة',
      name_en: '3rd Industrial Area',
    },
    {
      district_id: 10500113033,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي ينبع',
      name_en: 'Yanbu Dist.',
    },
    {
      district_id: 10500113034,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10500113035,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10500113036,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي نجد',
      name_en: 'Najd Dist.',
    },
    {
      district_id: 10500113037,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي دارين',
      name_en: 'Darin Dist.',
    },
    {
      district_id: 10500113038,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي سدير',
      name_en: 'Sudayr Dist.',
    },
    {
      district_id: 10500113039,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي مكة',
      name_en: 'Makkah Dist.',
    },
    {
      district_id: 10500113040,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الحجاز',
      name_en: 'Al Hijaz Dist.',
    },
    {
      district_id: 10500113041,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الفردوس',
      name_en: 'Al Firdaws Dist.',
    },
    {
      district_id: 10500113042,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10500113043,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouq Dist.',
    },
    {
      district_id: 10500113044,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10500113045,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي جلمودة',
      name_en: 'Jalmudah Dist.',
    },
    {
      district_id: 10500113046,
      city_id: 113,
      region_id: 5,
      name_ar: 'حي المطرفية',
      name_en: 'Al Matrafiyah Dist.',
    },
    {
      district_id: 10500115002,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500115003,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي التشاليح',
      name_en: 'At Tashalih Dist.',
    },
    {
      district_id: 10500115004,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10500115005,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10500115006,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي ج4',
      name_en: 'C4 Dist.',
    },
    {
      district_id: 10500115007,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10500115008,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10500115009,
      city_id: 115,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10500227001,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الدوحة الجنوبية',
      name_en: 'Ad Dawhah Al Janubiyah Dist.',
    },
    {
      district_id: 10500227002,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الدانة الشمالية',
      name_en: 'Ad Danah Ash Shamaliyah Dist.',
    },
    {
      district_id: 10500227003,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي القصور',
      name_en: 'Al Qusur Dist.',
    },
    {
      district_id: 10500227004,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الدوحة الشمالية',
      name_en: 'Ad Dawhah Ash Shamaliyah Dist.',
    },
    {
      district_id: 10500227005,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الدانة الجنوبية',
      name_en: 'Ad Danah Al Janubiyah Dist.',
    },
    {
      district_id: 10500227006,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10500227007,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي الحرس الوطني',
      name_en: 'Al Haras Al Watani Dist.',
    },
    {
      district_id: 10500227008,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي تهامة',
      name_en: 'Tihamah Dist.',
    },
    {
      district_id: 10500227009,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي القشلة',
      name_en: 'Al Qashlah Dist.',
    },
    {
      district_id: 10500227010,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي هجر',
      name_en: 'Hajr Dist.',
    },
    {
      district_id: 10500227011,
      city_id: 227,
      region_id: 5,
      name_ar: 'حي غرب الظهران',
      name_en: 'Gharb Adh Dhahran Dist.',
    },
    {
      district_id: 10500227012,
      city_id: 227,
      region_id: 5,
      name_ar: 'جامعة الملك فهد للبترول والمعادن',
      name_en: 'King Fahd University Of Petroleum And Minerals',
    },
    {
      district_id: 10500227013,
      city_id: 227,
      region_id: 5,
      name_ar: 'أجيال',
      name_en: 'Ajyal',
    },
    {
      district_id: 10500243001,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10500243002,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10500243003,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500243004,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10500243005,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10500243006,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10500243007,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500243008,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي أشبيلية',
      name_en: 'Ishbiliyah Dist.',
    },
    {
      district_id: 10500243009,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10500243010,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي المدينة',
      name_en: 'Al Madinah  Dist.',
    },
    {
      district_id: 10500243011,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10500243012,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي الفردوس',
      name_en: 'Al Fardus Dist.',
    },
    {
      district_id: 10500243013,
      city_id: 243,
      region_id: 5,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10500454001,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي النابية',
      name_en: 'An Nabiyah Dist.',
    },
    {
      district_id: 10500454002,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10500454003,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10500454004,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الفردوس',
      name_en: 'Al Firdaws Dist.',
    },
    {
      district_id: 10500454005,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10500454006,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10500454007,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الطف',
      name_en: 'At Tif Dist.',
    },
    {
      district_id: 10500454008,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الخصاب',
      name_en: 'Al Khisab Dist.',
    },
    {
      district_id: 10500454009,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 10500454010,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي النقى',
      name_en: 'An Naqa Dist.',
    },
    {
      district_id: 10500454011,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي المنتزة',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10500454012,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الكوثر',
      name_en: 'Al Kawthar Dist.',
    },
    {
      district_id: 10500454013,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10500454014,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10500454015,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10500454016,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 10500454017,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي غرب النابية',
      name_en: 'Gharb An Nabiyah Dist.',
    },
    {
      district_id: 10500454018,
      city_id: 454,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10500456003,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الزمرد',
      name_en: 'Az Zomorod Dist.',
    },
    {
      district_id: 10500456004,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الصدفة',
      name_en: 'As Sadafah Dist.',
    },
    {
      district_id: 10500456005,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي المناخ',
      name_en: 'Al Manakh Dist.',
    },
    {
      district_id: 10500456006,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي النرجس',
      name_en: 'An Narjis Dist.',
    },
    {
      district_id: 10500456007,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الربيعية',
      name_en: 'Ar Rabeiyah Dist.',
    },
    {
      district_id: 10500456008,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10500456009,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي اللؤلؤ',
      name_en: 'Al Lulu Dist.',
    },
    {
      district_id: 10500456010,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الجامعيين',
      name_en: 'Al Jamiyin Dist.',
    },
    {
      district_id: 10500456011,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي سنابس',
      name_en: 'Sanabis Dist.',
    },
    {
      district_id: 10500456012,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10500456013,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الزور',
      name_en: 'Az Zour Dist.',
    },
    {
      district_id: 10500456014,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10500456015,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الصواري',
      name_en: 'As Sawari Dist.',
    },
    {
      district_id: 10500456016,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10500456017,
      city_id: 456,
      region_id: 5,
      name_ar: 'حي الجبل',
      name_en: 'Al Jabal Dist.',
    },
    {
      district_id: 10502038001,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة المطيرفي',
      name_en: 'Al Mutayrifi',
    },
    {
      district_id: 10502038002,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة الشقيق الغربية',
      name_en: 'Ash Shaqiq Western',
    },
    {
      district_id: 10502038003,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة الوزية',
      name_en: 'Al Wazyah',
    },
    {
      district_id: 10502038004,
      city_id: 2038,
      region_id: 5,
      name_ar: 'الحي الرابع',
      name_en: 'Al Rabea Dist.',
    },
    {
      district_id: 10502038005,
      city_id: 2038,
      region_id: 5,
      name_ar: 'الحي الاول',
      name_en: 'Al Awal Dist.\r\nAl Awal Dist.',
    },
    {
      district_id: 10502038006,
      city_id: 2038,
      region_id: 5,
      name_ar: 'الحي الثاني',
      name_en: 'Al Thani Dist.',
    },
    {
      district_id: 10502038007,
      city_id: 2038,
      region_id: 5,
      name_ar: 'الحي الثالث',
      name_en: 'Al Thalith Dist.',
    },
    {
      district_id: 10502038008,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10502038009,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة القرين',
      name_en: 'Al Qaren',
    },
    {
      district_id: 10502038010,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي اشبيلية',
      name_en: 'Ishbiliyah Dist.',
    },
    {
      district_id: 10502038011,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة جليجلة',
      name_en: 'Julijlah',
    },
    {
      district_id: 10502038012,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10502038013,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10502038014,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الصفا الرابع',
      name_en: 'As Safa 4th Dist.',
    },
    {
      district_id: 10502038015,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 10502038016,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10502038017,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الصفا الثاني',
      name_en: 'As Safa 2nd Dist.',
    },
    {
      district_id: 10502038018,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الصفا الثالث',
      name_en: 'As Safa 3rd Dist.',
    },
    {
      district_id: 10502038019,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الصفا الاول',
      name_en: 'As Safa 1st Dist.',
    },
    {
      district_id: 10502038020,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة الجرن',
      name_en: 'Al Jarn',
    },
    {
      district_id: 10502038021,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10502038022,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 10502038023,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي ضاحية الامير سلطان الجنوبية',
      name_en: 'Dahiyat Prince Sultan Southern Dist.',
    },
    {
      district_id: 10502038024,
      city_id: 2038,
      region_id: 5,
      name_ar: 'مجمع الدوائر الحكومية',
      name_en: 'Government Departments Complex',
    },
    {
      district_id: 10502038025,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي العيون الشمالية',
      name_en: 'Al Oyon Southern Dist.',
    },
    {
      district_id: 10502038026,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة العوضية',
      name_en: 'Al Awadhyah',
    },
    {
      district_id: 10502038027,
      city_id: 2038,
      region_id: 5,
      name_ar: 'وسط العيون',
      name_en: 'Wassat Al Oyon',
    },
    {
      district_id: 10502038028,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة المراح',
      name_en: 'Al Marah',
    },
    {
      district_id: 10502038029,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي ضاحية الامير سلطان الشمالية',
      name_en: 'Dahiyat Prince Sultan Northern Dist.',
    },
    {
      district_id: 10502038030,
      city_id: 2038,
      region_id: 5,
      name_ar: 'حي العيون الجنوبية',
      name_en: 'Al Oyon Southern Dist.',
    },
    {
      district_id: 10502038031,
      city_id: 2038,
      region_id: 5,
      name_ar: 'بلدة الشقيق الشرقية',
      name_en: 'Ash Shaqiq Eastern',
    },
    {
      district_id: 10502167001,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 10502167002,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10502167003,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10502167004,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الاوجام',
      name_en: 'Al Aujam Dist.',
    },
    {
      district_id: 10502167005,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10502167006,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الريحان',
      name_en: 'Ar Raihan Dist.',
    },
    {
      district_id: 10502167007,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الدريدي',
      name_en: 'Ad Duraydi Dist.',
    },
    {
      district_id: 10502167008,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 10502167009,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الرويحة',
      name_en: 'Ar Ruwaihah Dist.',
    },
    {
      district_id: 10502167010,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 10502167011,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي ابو معن',
      name_en: 'Abu Main Dist.',
    },
    {
      district_id: 10502167012,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10502167013,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10502167014,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي البدور',
      name_en: 'Al Budur Dist.',
    },
    {
      district_id: 10502167015,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10502167016,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10502167017,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الخترشية',
      name_en: 'Al Khatrashiyah Dist.',
    },
    {
      district_id: 10502167018,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي ام الساهك',
      name_en: 'Umm As Sahik Dist.',
    },
    {
      district_id: 10502167019,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي العمل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10502167020,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 10502167021,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي حزم',
      name_en: 'Hazm Dist.',
    },
    {
      district_id: 10502167022,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10502167023,
      city_id: 2167,
      region_id: 5,
      name_ar: 'حي الشورى',
      name_en: 'Ash Shura Dist.',
    },
    {
      district_id: 10502170001,
      city_id: 2170,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10502170002,
      city_id: 2170,
      region_id: 5,
      name_ar: 'حي الراية',
      name_en: 'Ar Rayah Dist.',
    },
    {
      district_id: 10502170003,
      city_id: 2170,
      region_id: 5,
      name_ar: 'حي الرمال',
      name_en: 'Ar Rimal Dist.',
    },
    {
      district_id: 10502171001,
      city_id: 2171,
      region_id: 5,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq Dist.',
    },
    {
      district_id: 10502171002,
      city_id: 2171,
      region_id: 5,
      name_ar: 'حي البدر',
      name_en: 'Al Badr Dist.',
    },
    {
      district_id: 10502171003,
      city_id: 2171,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10502171004,
      city_id: 2171,
      region_id: 5,
      name_ar: 'حي المحار',
      name_en: 'Al Mahar Dist.',
    },
    {
      district_id: 10502171005,
      city_id: 2171,
      region_id: 5,
      name_ar: 'حي عنك',
      name_en: 'Anak Dist.',
    },
    {
      district_id: 10502176001,
      city_id: 2176,
      region_id: 5,
      name_ar: 'حي الساحل',
      name_en: 'As Sahil Dist.',
    },
    {
      district_id: 10502176002,
      city_id: 2176,
      region_id: 5,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 10502176003,
      city_id: 2176,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10502176004,
      city_id: 2176,
      region_id: 5,
      name_ar: 'حي دارين',
      name_en: 'Darin Dist.',
    },
    {
      district_id: 10502464001,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10502464002,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10502464003,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10502464004,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10502464005,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 10502464006,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10502464007,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10502464008,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10502464009,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10502464010,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10502464011,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الدوائر الحكومية',
      name_en: 'Goverment Departments Dist.',
    },
    {
      district_id: 10502464012,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10502464013,
      city_id: 2464,
      region_id: 5,
      name_ar: 'المنطقة الصناعية الاولى',
      name_en: '1st Industrial Area',
    },
    {
      district_id: 10502464014,
      city_id: 2464,
      region_id: 5,
      name_ar: 'المنطقة الصناعية الثانية',
      name_en: '2nd Industrial Area',
    },
    {
      district_id: 10502464015,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10502464016,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي اللؤلؤة',
      name_en: 'Al Luluah Dist.',
    },
    {
      district_id: 10502464017,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10502464018,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10502464019,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10502464020,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الامل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10502464021,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10502464022,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الامير نايف',
      name_en: 'Prince Naif Dist.',
    },
    {
      district_id: 10502464023,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10502464024,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي الامير فهد بن سلمان',
      name_en: 'Prince Fahd Ibn Salman Dist.',
    },
    {
      district_id: 10502464025,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10502464026,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي ارامكو',
      name_en: 'Aramco Dist.',
    },
    {
      district_id: 10502464027,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10502464028,
      city_id: 2464,
      region_id: 5,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10502590001,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10502590002,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي النعيم',
      name_en: 'An Naim Dist.',
    },
    {
      district_id: 10502590003,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10502590004,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10502590005,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10502590006,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10502590007,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10502590008,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الدانة',
      name_en: 'Ad Danah Dist.',
    },
    {
      district_id: 10502590009,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10502590010,
      city_id: 2590,
      region_id: 5,
      name_ar: 'حي نجمة',
      name_en: 'Najmah Dist.',
    },
    {
      district_id: 10502643001,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shuruq Dist.',
    },
    {
      district_id: 10502643002,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي يبرين',
      name_en: 'Yebrin Dist.',
    },
    {
      district_id: 10502643003,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10502643004,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي الدوائر الحكوميه',
      name_en: 'Government Departments Dist.',
    },
    {
      district_id: 10502643005,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10502643006,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10502643007,
      city_id: 2643,
      region_id: 5,
      name_ar: 'حي الاخاء',
      name_en: 'Al Akha Dist.',
    },
    {
      district_id: 10502653001,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة البطالية',
      name_en: 'Al Battaliyah',
    },
    {
      district_id: 10502653002,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة الجبيل',
      name_en: 'Al Jubail',
    },
    {
      district_id: 10502653003,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shuruq Dist.',
    },
    {
      district_id: 10502653004,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10502653005,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي التمور',
      name_en: 'At Tamor Dist.',
    },
    {
      district_id: 10502653006,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة القارة',
      name_en: 'Al Qarah',
    },
    {
      district_id: 10502653007,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10502653008,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة بني معن',
      name_en: 'Bani Maan',
    },
    {
      district_id: 10502653009,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي القارة',
      name_en: 'Al Qarah Dist.',
    },
    {
      district_id: 10502653010,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة الطريبيل',
      name_en: 'At Tarbil',
    },
    {
      district_id: 10502653011,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي التويثير',
      name_en: 'Al Tuwaythir Dist.',
    },
    {
      district_id: 10502653012,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية العمران الغربية',
      name_en: 'Al Umran Western',
    },
    {
      district_id: 10502653013,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية الحوطة',
      name_en: 'Al Hotah',
    },
    {
      district_id: 10502653014,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية السيايرة',
      name_en: 'As Syayrah',
    },
    {
      district_id: 10502653015,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة أبو ثور',
      name_en: 'Abu Ath Thoor',
    },
    {
      district_id: 10502653016,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10502653017,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي النرجس',
      name_en: 'An Narjis Dist.',
    },
    {
      district_id: 10502653018,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية الرميلة',
      name_en: 'Ar Rumaylah',
    },
    {
      district_id: 10502653019,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة غمسي',
      name_en: 'Gamsy',
    },
    {
      district_id: 10502653020,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية العرامية',
      name_en: 'Al Aramiyah',
    },
    {
      district_id: 10502653021,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي المحار',
      name_en: 'Al Mahar Dist.',
    },
    {
      district_id: 10502653022,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية العمران الشرقية',
      name_en: 'Al Umran Eastern',
    },
    {
      district_id: 10502653023,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة الصبايخ',
      name_en: 'As Sabaiykh',
    },
    {
      district_id: 10502653024,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq  Dist.',
    },
    {
      district_id: 10502653025,
      city_id: 2653,
      region_id: 5,
      name_ar: 'قرية العمران الجنوبي',
      name_en: 'Al Umran Southern',
    },
    {
      district_id: 10502653026,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة التويثير',
      name_en: 'Al Tuwaythir',
    },
    {
      district_id: 10502653027,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي الثريا',
      name_en: 'Ath Thuraiya Dist.',
    },
    {
      district_id: 10502653028,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10502653029,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة أبو الحصى',
      name_en: 'Abu Al Hasa',
    },
    {
      district_id: 10502653030,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة التهيمية',
      name_en: 'At Tahemah',
    },
    {
      district_id: 10502653031,
      city_id: 2653,
      region_id: 5,
      name_ar: 'حي البدور',
      name_en: 'Al Budur Dist.',
    },
    {
      district_id: 10502653032,
      city_id: 2653,
      region_id: 5,
      name_ar: 'بلدة الدالوة',
      name_en: 'Al Dalwah',
    },
    {
      district_id: 10502748001,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10502748002,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الفيصل',
      name_en: 'Al Faisal Dist.',
    },
    {
      district_id: 10502748003,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الضباب',
      name_en: 'Al Dabab Dist.',
    },
    {
      district_id: 10502748004,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الفنار',
      name_en: 'Al Fanar Dist.',
    },
    {
      district_id: 10502748005,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي أحد',
      name_en: 'Ahad Dist.',
    },
    {
      district_id: 10502748006,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن الثاني',
      name_en: 'Mahasin 2nd Dist.',
    },
    {
      district_id: 10502748007,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الراشدية الثاني',
      name_en: 'Ar Rashidyah 2nd Dist.',
    },
    {
      district_id: 10502748008,
      city_id: 2748,
      region_id: 5,
      name_ar: 'الحي الأكاديمي',
      name_en: 'Academic Quarter Dist.',
    },
    {
      district_id: 10502748009,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن ارامكو الخامس',
      name_en: 'Mahasin Aramco 5th  Dist.',
    },
    {
      district_id: 10502748010,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10502748011,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الراشدية الأول',
      name_en: 'Ar Rashidyah 1st Dist.',
    },
    {
      district_id: 10502748012,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي المطيرفي الغربي',
      name_en: 'Al Mutayrifi Al Garbi Dist.',
    },
    {
      district_id: 10502748013,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محيرس',
      name_en: 'Mahairis Dist.',
    },
    {
      district_id: 10502748014,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10502748015,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10502748016,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الأمانة',
      name_en: 'Al Amanah Dist.',
    },
    {
      district_id: 10502748017,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي عين نجم',
      name_en: 'Ayn Najm Dist.',
    },
    {
      district_id: 10502748018,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن الأول',
      name_en: 'Mahasin 1st Dist.',
    },
    {
      district_id: 10502748019,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الحزام الذهبي',
      name_en: 'Al Hizam Adh Dhahabi Dist.',
    },
    {
      district_id: 10502748020,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي مجمع الدوائر الحكومية الأول',
      name_en: 'Government Departments Complex 1st  Dist.',
    },
    {
      district_id: 10502748021,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي عين مرجان',
      name_en: 'Ayn Marjan Dist.',
    },
    {
      district_id: 10502748022,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الشروفية',
      name_en: 'Al Shurofiyah Dist.',
    },
    {
      district_id: 10502748023,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الديوان',
      name_en: 'Ad Daywan Dist.',
    },
    {
      district_id: 10502748024,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10502748025,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي السلام الأول',
      name_en: 'As Salam 1st Dist.',
    },
    {
      district_id: 10502748026,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن ارامكو الأول',
      name_en: 'Mahasin Aramco 1st Dist.',
    },
    {
      district_id: 10502748027,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10502748028,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الحزم الجنوبي',
      name_en: 'Al Hazm Southern Dist.',
    },
    {
      district_id: 10502748029,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي المسعودي',
      name_en: 'Al Masudi Dist.',
    },
    {
      district_id: 10502748030,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي أم سبعة',
      name_en: 'Umm Sabah Dist.',
    },
    {
      district_id: 10502748031,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10502748032,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10502748033,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الإتصالات',
      name_en: 'Al Itsalat Dist.',
    },
    {
      district_id: 10502748034,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10502748035,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي السلام الثاني',
      name_en: 'As Salam 2nd Dist.',
    },
    {
      district_id: 10502748036,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي المقابل',
      name_en: 'Al Muqabil Dist.',
    },
    {
      district_id: 10502748037,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الشعبة',
      name_en: 'Al Shuaba Dist.',
    },
    {
      district_id: 10502748038,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي السحيمية',
      name_en: 'As Suhaimiyah Dist.',
    },
    {
      district_id: 10502748039,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي بو سحبل الأول',
      name_en: 'Abu Sahbal 1st Dist.',
    },
    {
      district_id: 10502748040,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي وسط المبرز التاريخي',
      name_en: 'Historical Center Al Mubarraz  Dist.',
    },
    {
      district_id: 10502748041,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن ارامكو الثاني',
      name_en: 'Mahasin Aramco 2nd Dist.',
    },
    {
      district_id: 10502748042,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الراشدية الثالث',
      name_en: 'Ar Rashidyah 3rd Dist.',
    },
    {
      district_id: 10502748043,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي بو سحبل الثاني',
      name_en: 'Abu Sahbal 3rd Dist.',
    },
    {
      district_id: 10502748044,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن ارامكو الرابع',
      name_en: 'Mahasin Aramco 4th  Dist.',
    },
    {
      district_id: 10502748045,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10502748046,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الشراع الجنوبي',
      name_en: 'Ash Shara Southern Dist.',
    },
    {
      district_id: 10502748047,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الشراع الشمالي',
      name_en: 'Ash Shara Northern Dist.',
    },
    {
      district_id: 10502748048,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي مشرفة',
      name_en: 'Mishrifah Dist.',
    },
    {
      district_id: 10502748049,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10502748050,
      city_id: 2748,
      region_id: 5,
      name_ar: 'مدينة الملك عبدالله العسكرية',
      name_en: 'King Abdullah Military City',
    },
    {
      district_id: 10502748051,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي الحزم الشمالي',
      name_en: 'Al Hazm Northern Dist.',
    },
    {
      district_id: 10502748052,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي محاسن ارامكو الثالث',
      name_en: 'Mahasin Aramco 3rd Dist.',
    },
    {
      district_id: 10502748053,
      city_id: 2748,
      region_id: 5,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10502764002,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي قباء',
      name_en: 'Quba Dist.',
    },
    {
      district_id: 10502764003,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي المدينة المنورة',
      name_en: 'Al Madinah Al Munawwarah Dist.',
    },
    {
      district_id: 10502764004,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي مثلث الشرقية',
      name_en: 'Muthalath Asharqiyah Dist.',
    },
    {
      district_id: 10502764005,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة المنيزلة',
      name_en: 'Al Munaizlah',
    },
    {
      district_id: 10502764006,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة المنصورة',
      name_en: 'Al Mansurah',
    },
    {
      district_id: 10502764007,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 10502764008,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة الشهارين',
      name_en: 'Ash Shaharin',
    },
    {
      district_id: 10502764009,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة المركز',
      name_en: 'Al Markaz',
    },
    {
      district_id: 10502764010,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الاسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10502764011,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الرياض الثاني',
      name_en: 'Ar Riyadh 2nd Dist.',
    },
    {
      district_id: 10502764012,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة الفضول',
      name_en: 'Al Fudul',
    },
    {
      district_id: 10502764013,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة الطرف',
      name_en: 'At Tarf',
    },
    {
      district_id: 10502764014,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة العقار',
      name_en: 'Al Aqar',
    },
    {
      district_id: 10502764015,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة الجفر',
      name_en: 'Al Jafr',
    },
    {
      district_id: 10502764016,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10502764017,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الأول',
      name_en: 'Hajr 1st Dist.',
    },
    {
      district_id: 10502764018,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي مدينة الحجاج',
      name_en: 'Madina Al Hojaj Dist.',
    },
    {
      district_id: 10502764019,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الثاني',
      name_en: 'Hajr 2nd Dist.',
    },
    {
      district_id: 10502764020,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10502764021,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الثامن',
      name_en: 'Hajr 8th Dist.',
    },
    {
      district_id: 10502764022,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الحادي عشر',
      name_en: 'Hajr 11th Dist.',
    },
    {
      district_id: 10502764023,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الجبل',
      name_en: 'Al Jabal Dist.',
    },
    {
      district_id: 10502764024,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الركاز',
      name_en: 'Ar Rekaz Dist.',
    },
    {
      district_id: 10502764025,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة الجشة',
      name_en: 'Al Jishah',
    },
    {
      district_id: 10502764026,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الثالث',
      name_en: 'Hajr 3rd Dist.',
    },
    {
      district_id: 10502764027,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر التاسع',
      name_en: 'Hajr 9th Dist.',
    },
    {
      district_id: 10502764028,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10502764029,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي أحد',
      name_en: 'Ahad Dist.',
    },
    {
      district_id: 10502764030,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الرابع',
      name_en: 'Hajr 4th Dist.',
    },
    {
      district_id: 10502764031,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10502764032,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10502764033,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي المجصة',
      name_en: 'Al Magassah Dist.',
    },
    {
      district_id: 10502764034,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي النور',
      name_en: 'An Noor Dist.',
    },
    {
      district_id: 10502764035,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 10502764036,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر السادس',
      name_en: 'Hajr 6th Dist.',
    },
    {
      district_id: 10502764037,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الرياض الاول',
      name_en: 'Ar Riyadh 1st Dist.',
    },
    {
      district_id: 10502764038,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الواحة الطبية',
      name_en: 'Al Wahah Medical  Dist.',
    },
    {
      district_id: 10502764039,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10502764040,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakheel Dist.',
    },
    {
      district_id: 10502764041,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر السابع',
      name_en: 'Hajr 7th Dist.',
    },
    {
      district_id: 10502764042,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الخامس',
      name_en: 'Hajr 5th Dist.',
    },
    {
      district_id: 10502764043,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة المزاوي',
      name_en: 'Al Mazawi',
    },
    {
      district_id: 10502764044,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي برزان',
      name_en: 'Barzan Dist.',
    },
    {
      district_id: 10502764045,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الرياض الرابع',
      name_en: 'Ar Riyadh 4th Dist.',
    },
    {
      district_id: 10502764046,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الخيام',
      name_en: 'Al Khyam Dist.',
    },
    {
      district_id: 10502764047,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي العدوة',
      name_en: 'Al Adwah Dist.',
    },
    {
      district_id: 10502764048,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي المستشفى الجا',
      name_en: 'Al Ja Hospital Dist.',
    },
    {
      district_id: 10502764049,
      city_id: 2764,
      region_id: 5,
      name_ar: 'بلدة السباط',
      name_en: 'As Sabat',
    },
    {
      district_id: 10502764050,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10502764051,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10502764052,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر العاشر',
      name_en: 'Hajr 10th Dist.',
    },
    {
      district_id: 10502764053,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي السهلة',
      name_en: 'As Sahlah Dist.',
    },
    {
      district_id: 10502764054,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي نادي الفروسية',
      name_en: 'Nadi Al Forosiyah Dist.',
    },
    {
      district_id: 10502764055,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي ابوحريف',
      name_en: 'Abu Hareef Dist.',
    },
    {
      district_id: 10502764056,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الجفر الشمالي',
      name_en: 'Al Jafer Northern Dist.',
    },
    {
      district_id: 10502764057,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي الرياض الثالث',
      name_en: 'Ar Riyadh 3rd Dist.',
    },
    {
      district_id: 10502764058,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي المعامرة',
      name_en: 'Al Maamrah Dist.',
    },
    {
      district_id: 10502764059,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي هجر الثاني عشر',
      name_en: 'Hajr 12th Dist.',
    },
    {
      district_id: 10502764060,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي مدينة التمور',
      name_en: 'Madina At Tomor Dist.',
    },
    {
      district_id: 10502764061,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي مجمع الدوائر الحكومية',
      name_en: 'Government Departments Complex Dist.',
    },
    {
      district_id: 10502764096,
      city_id: 2764,
      region_id: 5,
      name_ar: 'حي المنيزله',
      name_en: 'Al Munaizilah Dist.',
    },
    {
      district_id: 10503700001,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الدوائر الحكومية',
      name_en: 'Goverment Departments Dist.',
    },
    {
      district_id: 10503700002,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي اسكان الكلابية',
      name_en: 'Al Kilabiyah Housing Dist.',
    },
    {
      district_id: 10503700003,
      city_id: 3700,
      region_id: 5,
      name_ar: 'بلدة الكلابية',
      name_en: 'Al Kilabiyah',
    },
    {
      district_id: 10503700004,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي جواثا',
      name_en: 'Juwatha Dist.',
    },
    {
      district_id: 10503700005,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الكلابية',
      name_en: 'Al Kilabiyah Dist.',
    },
    {
      district_id: 10503700006,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10503700007,
      city_id: 3700,
      region_id: 5,
      name_ar: 'بلدة الحليلة',
      name_en: 'Al Halilah',
    },
    {
      district_id: 10503700008,
      city_id: 3700,
      region_id: 5,
      name_ar: 'بلدة المقدام',
      name_en: 'Al Miqdam',
    },
    {
      district_id: 10503700009,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 10503700010,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الرفاع',
      name_en: 'Ar Rifaa Dist.',
    },
    {
      district_id: 10503700011,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10503700012,
      city_id: 3700,
      region_id: 5,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10511075001,
      city_id: 11075,
      region_id: 5,
      name_ar: 'حي الانوار',
      name_en: 'Al Anwar Dist.',
    },
    {
      district_id: 10511075002,
      city_id: 11075,
      region_id: 5,
      name_ar: 'حي ام الحمام',
      name_en: 'Um Al Hamam Dist.',
    },
    {
      district_id: 10600015001,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المنهل',
      name_en: 'Al Manhal Dist.',
    },
    {
      district_id: 10600015002,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي النصب',
      name_en: 'An Nasb Dist.',
    },
    {
      district_id: 10600015003,
      city_id: 15,
      region_id: 6,
      name_ar: 'وسط المدينة',
      name_en: 'City Center',
    },
    {
      district_id: 10600015004,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10600015005,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي ذرة',
      name_en: 'Dhurah Dist.',
    },
    {
      district_id: 10600015006,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي شمسان',
      name_en: 'Shamsan Dist.',
    },
    {
      district_id: 10600015007,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي القرى',
      name_en: 'Al Qura Dist.',
    },
    {
      district_id: 10600015008,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الوردتين',
      name_en: 'Al Wardatain Dist.',
    },
    {
      district_id: 10600015009,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10600015010,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10600015011,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10600015013,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الضباب',
      name_en: 'Ad Dabab Dist.',
    },
    {
      district_id: 10600015014,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الوصايف',
      name_en: 'Al Wasaif Dist.',
    },
    {
      district_id: 10600015015,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الشرفية',
      name_en: 'Ash Sharafiyah Dist.',
    },
    {
      district_id: 10600015016,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10600015017,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10600015018,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المفتاحة',
      name_en: 'Al Muftahah Dist.',
    },
    {
      district_id: 10600015019,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي السروات',
      name_en: 'As Sarawat Dist.',
    },
    {
      district_id: 10600015020,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10600015021,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي السد',
      name_en: 'As Sadd Dist.',
    },
    {
      district_id: 10600015022,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الخشع',
      name_en: 'Al Khasha Dist.',
    },
    {
      district_id: 10600015023,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي القابل',
      name_en: 'Al Qabil Dist.',
    },
    {
      district_id: 10600015024,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10600015025,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10600015026,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي العرين',
      name_en: 'Al Arin Dist.',
    },
    {
      district_id: 10600015027,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي البديع',
      name_en: 'Al Badi Dist.',
    },
    {
      district_id: 10600015028,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10600015029,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي ج 35',
      name_en: 'C 35 Dist.',
    },
    {
      district_id: 10600015030,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المقضى',
      name_en: 'Al Magda Dist.',
    },
    {
      district_id: 10600015031,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10600015032,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10600015033,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10600015034,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الفل',
      name_en: 'Al Fal Dist.',
    },
    {
      district_id: 10600015035,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10600015036,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المطار',
      name_en: 'Airport Dist.',
    },
    {
      district_id: 10600015037,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10600015038,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 10600015039,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10600015040,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي البحيره',
      name_en: 'Al Bhira Dist.',
    },
    {
      district_id: 10600015041,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي العقيق',
      name_en: 'Al Aqiq  Dist.',
    },
    {
      district_id: 10600015042,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10600015043,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10600015044,
      city_id: 15,
      region_id: 6,
      name_ar: 'حي المسك',
      name_en: 'Al Misk Dist.',
    },
    {
      district_id: 10600062001,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الراقي',
      name_en: 'Al Raqi Dist.',
    },
    {
      district_id: 10600062002,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الأسواق',
      name_en: 'Al Aswaq Dist.',
    },
    {
      district_id: 10600062003,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10600062004,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي السد',
      name_en: 'As Sad Dist.',
    },
    {
      district_id: 10600062005,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10600062006,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي طيب الاسم',
      name_en: 'Tayib Al Ism Dist.',
    },
    {
      district_id: 10600062007,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10600062008,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10600062009,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10600062010,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ضمك',
      name_en: 'Damak Dist.',
    },
    {
      district_id: 10600062011,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10600062012,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10600062013,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 10600062014,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10600062015,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي شباعة',
      name_en: 'Shubaah Dist.',
    },
    {
      district_id: 10600062016,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المعزاب',
      name_en: 'Al Mizab Dist.',
    },
    {
      district_id: 10600062017,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المثناة',
      name_en: 'Al Mathnah Dist.',
    },
    {
      district_id: 10600062018,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المعمورة',
      name_en: 'Al Mamurah Dist.',
    },
    {
      district_id: 10600062019,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10600062020,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي شكر',
      name_en: 'Shakar Dist.',
    },
    {
      district_id: 10600062021,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10600062022,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي عتود',
      name_en: 'Atud Dist.',
    },
    {
      district_id: 10600062023,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ال هميلة',
      name_en: 'Al Humaylah Dist.',
    },
    {
      district_id: 10600062024,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي العرق الجنوبي',
      name_en: 'Al Irq Al Janubi Dist.',
    },
    {
      district_id: 10600062025,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الصقور',
      name_en: 'As Suqur Dist.',
    },
    {
      district_id: 10600062026,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي البوادي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 10600062027,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10600062028,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ام سرور',
      name_en: 'Umm Surur Dist.',
    },
    {
      district_id: 10600062029,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الشرفية',
      name_en: 'Ash Sharafiyah Dist.',
    },
    {
      district_id: 10600062030,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10600062031,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي قمبر',
      name_en: 'Qambar Dist.',
    },
    {
      district_id: 10600062032,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10600062033,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي العرق الشمالي',
      name_en: 'Al Irq Ash Shamali Dist.',
    },
    {
      district_id: 10600062034,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الدوحة',
      name_en: 'Ad Dawhah Dist.',
    },
    {
      district_id: 10600062035,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10600062036,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي القافلة',
      name_en: 'Al Qafilah Dist.',
    },
    {
      district_id: 10600062037,
      city_id: 62,
      region_id: 6,
      name_ar: 'مخطط الموسى',
      name_en: 'Al Musa Subdivision Plan',
    },
    {
      district_id: 10600062038,
      city_id: 62,
      region_id: 6,
      name_ar: 'سكن القاعدة الجوية',
      name_en: 'Airforce Housing',
    },
    {
      district_id: 10600062039,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الرونه',
      name_en: 'Ar Runah Dist.',
    },
    {
      district_id: 10600062040,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10600062041,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ذلالة',
      name_en: 'Dhalalah Dist.',
    },
    {
      district_id: 10600062042,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الرصراص 2',
      name_en: 'Ar Rasras 2 Dist.',
    },
    {
      district_id: 10600062043,
      city_id: 62,
      region_id: 6,
      name_ar: 'سكن المدينة العسكرية',
      name_en: 'Military City Housing',
    },
    {
      district_id: 10600062044,
      city_id: 62,
      region_id: 6,
      name_ar: 'سكن مجموعة اللواء الرابع عشر',
      name_en: '14th Brigade Housing',
    },
    {
      district_id: 10600062046,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الجامعيين',
      name_en: 'Al Jamiyin Dist.',
    },
    {
      district_id: 10600062047,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الوسام',
      name_en: 'Al Wasam Dist.',
    },
    {
      district_id: 10600062048,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10600062049,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الحرابى',
      name_en: 'Al Harabi Dist.',
    },
    {
      district_id: 10600062050,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي شكر',
      name_en: 'Shokor Dist.',
    },
    {
      district_id: 10600062051,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10600062052,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الهرير الغربى',
      name_en: 'Al Harir Western Dist.',
    },
    {
      district_id: 10600062053,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المراث',
      name_en: 'Al Marath Dist.',
    },
    {
      district_id: 10600062054,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الظرفة',
      name_en: 'Al Dhurfah Dist.',
    },
    {
      district_id: 10600062055,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي تارة',
      name_en: 'Tara Dist.',
    },
    {
      district_id: 10600062056,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي النجاح',
      name_en: 'An Najah Dist.',
    },
    {
      district_id: 10600062057,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الجبل',
      name_en: 'Al Jabal Dist.',
    },
    {
      district_id: 10600062058,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي شمال التضامن',
      name_en: 'Shamal At Tadamun Dist.',
    },
    {
      district_id: 10600062059,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي نشوان',
      name_en: 'Nashwan Dist.',
    },
    {
      district_id: 10600062060,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10600062061,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10600062062,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الوفاء',
      name_en: 'Al Wafa Dist.',
    },
    {
      district_id: 10600062063,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10600062064,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي أحد',
      name_en: 'Uhud Dist.',
    },
    {
      district_id: 10600062065,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي شعب ذهبان',
      name_en: 'Shaab Dhahban Dist.',
    },
    {
      district_id: 10600062066,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ذهبان الغربى',
      name_en: 'Western Dhahban Dist.',
    },
    {
      district_id: 10600062067,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي ذهبان الشرقى',
      name_en: 'Eastern Dhahban Dist.',
    },
    {
      district_id: 10600062068,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الرصراص 1',
      name_en: 'Ar Rasras 1 Dist.',
    },
    {
      district_id: 10600062069,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الوقواق',
      name_en: 'Al Waqwaq Dist.',
    },
    {
      district_id: 10600062070,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الهرير الشرقى',
      name_en: 'Al Harir Al Sharqi Dist.',
    },
    {
      district_id: 10600062071,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي الوقبة',
      name_en: 'Al Waqba Dist.',
    },
    {
      district_id: 10600062072,
      city_id: 62,
      region_id: 6,
      name_ar: 'حي مصلوم',
      name_en: 'Masloum Dist.',
    },
    {
      district_id: 10600065001,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10600065002,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الزيتون',
      name_en: 'Az Zaytun Dist.',
    },
    {
      district_id: 10600065003,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10600065004,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10600065005,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10600065006,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي وسط البلد',
      name_en: 'Down Town Dist.',
    },
    {
      district_id: 10600065007,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10600065008,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10600065009,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي صفوان',
      name_en: 'Safwan Dist.',
    },
    {
      district_id: 10600065010,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10600065011,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10600065012,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10600065013,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الرونه',
      name_en: 'Ar Runah Dist.',
    },
    {
      district_id: 10600065014,
      city_id: 65,
      region_id: 6,
      name_ar: 'المدينة العسكرية',
      name_en: 'Military City',
    },
    {
      district_id: 10600065015,
      city_id: 65,
      region_id: 6,
      name_ar: 'حي الروضه 1',
      name_en: 'Ar Rawdah 1 Dist.',
    },
    {
      district_id: 10600256001,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي التعاون',
      name_en: 'Al Taawun Dist.',
    },
    {
      district_id: 10600256002,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzhah Dist.',
    },
    {
      district_id: 10600256003,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10600256004,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10600256005,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الفاخرية',
      name_en: 'Al Fakhriyah Dist.',
    },
    {
      district_id: 10600256006,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10600256007,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10600256008,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي المنهل',
      name_en: 'Al Manhal Dist.',
    },
    {
      district_id: 10600256009,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10600256010,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 10600256011,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10600256012,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10600256013,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'Al Sinaiyah Dist.',
    },
    {
      district_id: 10600256014,
      city_id: 256,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10601294001,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10601294002,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10601294003,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10601294004,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي التقدم',
      name_en: 'At Taqadom Dist.',
    },
    {
      district_id: 10601294005,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الشرق',
      name_en: 'Ash Sharq Dist.',
    },
    {
      district_id: 10601294006,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10601294007,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10601294008,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10601294009,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10601294010,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي العدالة',
      name_en: 'Al Adalah Dist.',
    },
    {
      district_id: 10601294011,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10601294012,
      city_id: 1294,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10601443001,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10601443002,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10601443003,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10601443004,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الوجه',
      name_en: 'Al Wajh Dist.',
    },
    {
      district_id: 10601443005,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الغزالة',
      name_en: 'Al Ghazalah Dist.',
    },
    {
      district_id: 10601443006,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الحجاز',
      name_en: 'Al Hijaz Dist.',
    },
    {
      district_id: 10601443007,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الفاخرية',
      name_en: 'Al Fakhriyah Dist.',
    },
    {
      district_id: 10601443008,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10601443009,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10601443010,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي المدينة',
      name_en: 'Al Madinah Dist.',
    },
    {
      district_id: 10601443011,
      city_id: 1443,
      region_id: 6,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10601443012,
      city_id: 1443,
      region_id: 6,
      name_ar: 'الطويفح',
      name_en: 'Al Tuwaifah',
    },
    {
      district_id: 10601443013,
      city_id: 1443,
      region_id: 6,
      name_ar: 'الجربوعية',
      name_en: 'Al Jarboeyyah',
    },
    {
      district_id: 10601443014,
      city_id: 1443,
      region_id: 6,
      name_ar: 'القوزية',
      name_en: 'Al Quoziyah',
    },
    {
      district_id: 10601443015,
      city_id: 1443,
      region_id: 6,
      name_ar: 'آل قير',
      name_en: 'Al Qeer',
    },
    {
      district_id: 10601514001,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الصواير',
      name_en: 'As Sawaer Dist.',
    },
    {
      district_id: 10601514002,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الرمال',
      name_en: 'Ar Rimal Dist.',
    },
    {
      district_id: 10601514003,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10601514004,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الخزان',
      name_en: 'Al Khazzan Dist.',
    },
    {
      district_id: 10601514005,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10601514006,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الفيحاء',
      name_en: 'Al Fayha Dist.',
    },
    {
      district_id: 10601514007,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10601514008,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الفهد',
      name_en: 'Al Fahd Dist.',
    },
    {
      district_id: 10601514009,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10601514010,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 10601514011,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الورود',
      name_en: 'Alwurud Dist.',
    },
    {
      district_id: 10601514012,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي التضامن',
      name_en: 'Al Tadamun Dist.',
    },
    {
      district_id: 10601514013,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10601514014,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10601514015,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10601514016,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10601514017,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10601514018,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي البوادي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 10601514019,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10601514020,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي المطار',
      name_en: 'Airport Dist.',
    },
    {
      district_id: 10601514021,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10601514022,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10601514023,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10601514024,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10601514025,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10601514026,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10601514027,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي الامير سلطان',
      name_en: 'Prince Sultan Dist.',
    },
    {
      district_id: 10601514028,
      city_id: 1514,
      region_id: 6,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10601627001,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10601627002,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10601627003,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 10601627004,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10601627005,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الخضراء',
      name_en: 'Al Khadra Dist.',
    },
    {
      district_id: 10601627006,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10601627007,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10601627008,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10601627009,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10601627010,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10601627011,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10601627012,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10601627013,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الشرق',
      name_en: 'Ash Sharq Dist.',
    },
    {
      district_id: 10601627014,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10601627015,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الصفح',
      name_en: 'Al Safh Dist.',
    },
    {
      district_id: 10601627016,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الجزيرة',
      name_en: 'Al Jazirah Dist.',
    },
    {
      district_id: 10601627017,
      city_id: 1627,
      region_id: 6,
      name_ar: 'حي الامل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10601801001,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10601801002,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 10601801003,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الجنادرية',
      name_en: 'Al Janadriyah Dist.',
    },
    {
      district_id: 10601801004,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10601801005,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الحيلة الشرقي',
      name_en: 'Al Haylah Ash Sharqi Dist.',
    },
    {
      district_id: 10601801006,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي المقاعد',
      name_en: 'Al Maqaiid Dist.',
    },
    {
      district_id: 10601801007,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الظهرة',
      name_en: 'Adh Dhahrah Dist.',
    },
    {
      district_id: 10601801008,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الشهد',
      name_en: 'Ash Shahd Dist.',
    },
    {
      district_id: 10601801009,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10601801010,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الحيلة الغربي',
      name_en: 'Al Haylah Al Gharbi Dist.',
    },
    {
      district_id: 10601801011,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10601801012,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10601801013,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي البصرة',
      name_en: 'Al Basrah Dist.',
    },
    {
      district_id: 10601801014,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الضرس',
      name_en: 'Ad Dirs Dist.',
    },
    {
      district_id: 10601801015,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي البديع',
      name_en: 'Al Badi Dist.',
    },
    {
      district_id: 10601801016,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10601801017,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10601801018,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10601801019,
      city_id: 1801,
      region_id: 6,
      name_ar: 'حي الوعد',
      name_en: 'Al Waad Dist.',
    },
    {
      district_id: 10601847001,
      city_id: 1847,
      region_id: 6,
      name_ar: 'حي الريحان',
      name_en: 'Ar Raihan Dist.',
    },
    {
      district_id: 10601847002,
      city_id: 1847,
      region_id: 6,
      name_ar: 'حي السودة',
      name_en: 'As Suda Dist.',
    },
    {
      district_id: 10601847003,
      city_id: 1847,
      region_id: 6,
      name_ar: 'حي المقضى',
      name_en: 'Al Magda Dist.',
    },
    {
      district_id: 10601847004,
      city_id: 1847,
      region_id: 6,
      name_ar: 'حي ج 34',
      name_en: 'C 34 Dist.',
    },
    {
      district_id: 10601847005,
      city_id: 1847,
      region_id: 6,
      name_ar: 'حي ج 35',
      name_en: 'C 35 Dist.',
    },
    {
      district_id: 10602519001,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي المطل',
      name_en: 'Al Matl Dist.',
    },
    {
      district_id: 10602519002,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الضباب',
      name_en: 'Ad Dabab Dist.',
    },
    {
      district_id: 10602519003,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10602519004,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي السروات',
      name_en: 'As Sarawat Dist.',
    },
    {
      district_id: 10602519005,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10602519006,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي السد',
      name_en: 'As Sad Dist.',
    },
    {
      district_id: 10602519007,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الصديق',
      name_en: 'As Sediq Dist.',
    },
    {
      district_id: 10602519008,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shuruq Dist.',
    },
    {
      district_id: 10602519009,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouq Dist.',
    },
    {
      district_id: 10602519010,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10602519011,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي بلال',
      name_en: 'Bilal Dist.',
    },
    {
      district_id: 10602519012,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10602519013,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10602519014,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10602519015,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10602519016,
      city_id: 2519,
      region_id: 6,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 10602968001,
      city_id: 2968,
      region_id: 6,
      name_ar: 'حي الريحان',
      name_en: 'Ar Raihan Dist.',
    },
    {
      district_id: 10603273001,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي الامل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 10603273002,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10603273003,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10603273004,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي الشلال',
      name_en: 'Al Shlal Dist.',
    },
    {
      district_id: 10603273005,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10603273006,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي الضباب',
      name_en: 'Ad Dabab Dist.',
    },
    {
      district_id: 10603273007,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10603273008,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي المستقبل',
      name_en: 'Al Mustaqbal Dist.',
    },
    {
      district_id: 10603273009,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي المصايف',
      name_en: 'Al Masyaf Dist.',
    },
    {
      district_id: 10603273010,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10603273011,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10603273012,
      city_id: 3273,
      region_id: 6,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10603275001,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10603275002,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10603275003,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 10603275004,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الربوة',
      name_en: 'Al Rabwah Dist.',
    },
    {
      district_id: 10603275005,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10603275006,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي السامر',
      name_en: 'As Samir Dist.',
    },
    {
      district_id: 10603275007,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10603275008,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الزيتون',
      name_en: 'Az Zaytun Dist.',
    },
    {
      district_id: 10603275009,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10603275010,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10603275011,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10603275012,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10603275013,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10603275014,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10603275015,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي السروات',
      name_en: 'As Sarawat Dist.',
    },
    {
      district_id: 10603275016,
      city_id: 3275,
      region_id: 6,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10603328001,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي الفهد',
      name_en: 'Al Fahd Dist.',
    },
    {
      district_id: 10603328002,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10603328003,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 10603328004,
      city_id: 3328,
      region_id: 6,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10603328005,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10603328006,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10603328007,
      city_id: 3328,
      region_id: 6,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10603381001,
      city_id: 3381,
      region_id: 6,
      name_ar: 'حي الاطلال',
      name_en: 'Al Atlal Dist.',
    },
    {
      district_id: 10603381002,
      city_id: 3381,
      region_id: 6,
      name_ar: 'حي الورود',
      name_en: 'Al Woroud Dist.',
    },
    {
      district_id: 10603381003,
      city_id: 3381,
      region_id: 6,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10603383001,
      city_id: 3383,
      region_id: 6,
      name_ar: 'حي الربوه',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10603678001,
      city_id: 3678,
      region_id: 6,
      name_ar: 'حي شمال تندحة',
      name_en: 'North Tandaha Dist.',
    },
    {
      district_id: 10603678002,
      city_id: 3678,
      region_id: 6,
      name_ar: 'حي وسط تندحة',
      name_en: 'Centeral Tandaha Dist.',
    },
    {
      district_id: 10603678003,
      city_id: 3678,
      region_id: 6,
      name_ar: 'حي جنوب تندحة',
      name_en: 'South Tandaha Dist.',
    },
    {
      district_id: 10603689001,
      city_id: 3689,
      region_id: 6,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10700001001,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الإسكان',
      name_en: 'Al Iskan Dist.',
    },
    {
      district_id: 10700001002,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي العين',
      name_en: 'Al Ain Dist.',
    },
    {
      district_id: 10700001003,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الشفا',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 10700001004,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 10700001005,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الاخضر',
      name_en: 'Al Akhdar Dist.',
    },
    {
      district_id: 10700001006,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي البوادي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 10700001007,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10700001008,
      city_id: 1,
      region_id: 7,
      name_ar: 'الأحياء الجنوبية',
      name_en: 'Southern Districts',
    },
    {
      district_id: 10700001009,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي النظيم',
      name_en: 'Al Nadheem Dist.',
    },
    {
      district_id: 10700001010,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي غرب الخالدية',
      name_en: 'Khalidiyah West Dist.',
    },
    {
      district_id: 10700001011,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي البساتين',
      name_en: 'Al Basatin Dist.',
    },
    {
      district_id: 10700001012,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10700001013,
      city_id: 1,
      region_id: 7,
      name_ar: 'البلدة القديمة',
      name_en: 'Down Town',
    },
    {
      district_id: 10700001014,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي السعادة',
      name_en: 'Al Saadah Dist.',
    },
    {
      district_id: 10700001015,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 10700001016,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الفيصلية الجنوبية',
      name_en: 'Faisaliyah South Dist.',
    },
    {
      district_id: 10700001017,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الفيصلية الشمالية',
      name_en: 'Faisaliyah North Dist.',
    },
    {
      district_id: 10700001018,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 10700001019,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdah Dist.',
    },
    {
      district_id: 10700001020,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المصيف الاول',
      name_en: 'Al Masif 1 Dist.',
    },
    {
      district_id: 10700001021,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المروج الثاني',
      name_en: 'Al Muruj 2 Dist.',
    },
    {
      district_id: 10700001022,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الريان',
      name_en: 'Al Rayan Dist.',
    },
    {
      district_id: 10700001023,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10700001024,
      city_id: 1,
      region_id: 7,
      name_ar: 'مخطط الراجحي',
      name_en: 'Al Rajhi Subdivision Plan',
    },
    {
      district_id: 10700001025,
      city_id: 1,
      region_id: 7,
      name_ar: 'المدينة الجامعية',
      name_en: 'University City',
    },
    {
      district_id: 10700001026,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الفلاح',
      name_en: 'Al Falah Dist.',
    },
    {
      district_id: 10700001027,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الزهراء',
      name_en: 'Al Zahra Dist.',
    },
    {
      district_id: 10700001028,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي رايس',
      name_en: 'Rise Dist.',
    },
    {
      district_id: 10700001029,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية و',
      name_en: 'Government Departments Area F',
    },
    {
      district_id: 10700001030,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية ب',
      name_en: 'Government Departments Area B',
    },
    {
      district_id: 10700001031,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية د',
      name_en: 'Government Departments Area D',
    },
    {
      district_id: 10700001032,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية أ',
      name_en: 'Government Departments Area A',
    },
    {
      district_id: 10700001033,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10700001034,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية ج',
      name_en: 'Government Departments Area C',
    },
    {
      district_id: 10700001035,
      city_id: 1,
      region_id: 7,
      name_ar: 'الدوائر الحكومية هـ',
      name_en: 'Government Departments Area E',
    },
    {
      district_id: 10700001036,
      city_id: 1,
      region_id: 7,
      name_ar: 'منطقة خاصة',
      name_en: 'Private Area',
    },
    {
      district_id: 10700001037,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الدفاع',
      name_en: 'Al Difa Dist.',
    },
    {
      district_id: 10700001041,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الندى',
      name_en: 'Al Nada Dist.',
    },
    {
      district_id: 10700001042,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الهجن',
      name_en: 'Al Hejin Dist.',
    },
    {
      district_id: 10700001043,
      city_id: 1,
      region_id: 7,
      name_ar: 'مطــار الامير سلطان بن عبدالعزيز الدولي',
      name_en: 'Prince Sultan Bin Abdulaziz International Airport',
    },
    {
      district_id: 10700001044,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي القادسية الثاني',
      name_en: 'Al Qadisiyah 2 Dist.',
    },
    {
      district_id: 10700001045,
      city_id: 1,
      region_id: 7,
      name_ar: 'حى طيبة',
      name_en: 'Taibah Dist.',
    },
    {
      district_id: 10700001046,
      city_id: 1,
      region_id: 7,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10700001047,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 10700001048,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي التعاون',
      name_en: 'Al Taawun Dist.',
    },
    {
      district_id: 10700001049,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي القادسية الاول',
      name_en: 'Al Qadisiyah 1 Dist.',
    },
    {
      district_id: 10700001050,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10700001051,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakheel Dist.',
    },
    {
      district_id: 10700001052,
      city_id: 1,
      region_id: 7,
      name_ar: 'الاستاد الرياضي',
      name_en: 'Sports Stadium',
    },
    {
      district_id: 10700001053,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10700001054,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي السليمانية',
      name_en: 'Al Sulimaniyah Dist.',
    },
    {
      district_id: 10700001055,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الاشرافية',
      name_en: 'Al Ishrafiyah Dist.',
    },
    {
      district_id: 10700001056,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10700001057,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10700001058,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المروج الثالث',
      name_en: 'Al Muruj 3 Dist.',
    },
    {
      district_id: 10700001059,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المروج الاول',
      name_en: 'Al Muruj 1 Dist.',
    },
    {
      district_id: 10700001060,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10700001061,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المهرجان أ',
      name_en: 'Al Mahrajan A Dist.',
    },
    {
      district_id: 10700001062,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي المهرجان ب',
      name_en: 'Al Mahrajan B Dist.',
    },
    {
      district_id: 10700001063,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10700001064,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي العزيزية القديمة',
      name_en: 'Al Aziziyah Al Qadimah Dist.',
    },
    {
      district_id: 10700001065,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10700001067,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي العزيزيه الجديده',
      name_en: 'Al Aziziyah Al Jadidah Dist.',
    },
    {
      district_id: 10700001068,
      city_id: 1,
      region_id: 7,
      name_ar: 'حي السلمان',
      name_en: 'As Salman Dist.',
    },
    {
      district_id: 10700036001,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الظهرة',
      name_en: 'Adh Dhahrah Dist.',
    },
    {
      district_id: 10700036002,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10700036003,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي السبخة',
      name_en: 'As Sabkhah Dist.',
    },
    {
      district_id: 10700036004,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي البلدة القديمة',
      name_en: 'Al Balda Al Qadimah Dist.',
    },
    {
      district_id: 10700036005,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 10700036006,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي المروج1',
      name_en: 'Al Muruj 1 Dist.',
    },
    {
      district_id: 10700036007,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10700036008,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10700036009,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي السلطانيه',
      name_en: 'Al Sultanih Dist.',
    },
    {
      district_id: 10700036010,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الوصل',
      name_en: 'Al Wasl Dist.',
    },
    {
      district_id: 10700036011,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي المروج 2',
      name_en: 'Al Muruj 2 Dist.',
    },
    {
      district_id: 10700036012,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10700036013,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي جنوب الحميضه',
      name_en: 'Al Humaydah South Dist.',
    },
    {
      district_id: 10700036014,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10700036015,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10700036016,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الحميضه',
      name_en: 'Al Humaydah Dist.',
    },
    {
      district_id: 10700036017,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي غرب الظهره',
      name_en: 'Al Dhahra West Dist.',
    },
    {
      district_id: 10700036018,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي ج1',
      name_en: 'C1 Dist.',
    },
    {
      district_id: 10700036019,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي ام عنم',
      name_en: 'Um Anam Dist.',
    },
    {
      district_id: 10700036020,
      city_id: 36,
      region_id: 7,
      name_ar: 'حي الجامعه',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10700074001,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10700074002,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10700074003,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10700074004,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10700074005,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي التجاري',
      name_en: 'At Tijari  Dist.',
    },
    {
      district_id: 10700074006,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي هداج',
      name_en: 'Haddaj Dist.',
    },
    {
      district_id: 10700074007,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي القاع',
      name_en: 'Al Qaa Dist.',
    },
    {
      district_id: 10700074008,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الفاو',
      name_en: 'Al Faw Dist.',
    },
    {
      district_id: 10700074009,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي عكاظ',
      name_en: 'Uqaz Dist.',
    },
    {
      district_id: 10700074010,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الخبراء',
      name_en: 'Al Khobara Dist.',
    },
    {
      district_id: 10700074011,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الشرق',
      name_en: 'Ash Sharq Dist.',
    },
    {
      district_id: 10700074012,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 10700074013,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي غرناطة',
      name_en: 'Ghirnatah Dist.',
    },
    {
      district_id: 10700074014,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10700074015,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي قريان',
      name_en: 'Qaryan Dist.',
    },
    {
      district_id: 10700074016,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10700074017,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10700074018,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10700074019,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10700074020,
      city_id: 74,
      region_id: 7,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10700233001,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 10700233002,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10700233003,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10700233004,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10700233005,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10700233006,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي الفريعة',
      name_en: 'Al Fareah Dist.',
    },
    {
      district_id: 10700233007,
      city_id: 233,
      region_id: 7,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10700323001,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي قرية الجبنون',
      name_en: 'Qaryat Al Jabnon Dist.',
    },
    {
      district_id: 10700323002,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10700323003,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي التحلية',
      name_en: 'At Tahliyyah Dist.',
    },
    {
      district_id: 10700323004,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 10700323005,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي الصيادية',
      name_en: 'As Sayyadih Dist.',
    },
    {
      district_id: 10700323006,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي البطحاء',
      name_en: 'Al Batha Dist.',
    },
    {
      district_id: 10700323007,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdhah Dist.',
    },
    {
      district_id: 10700323008,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي ابو شجرة',
      name_en: 'Abu Shajarah Dist.',
    },
    {
      district_id: 10700323009,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10700323010,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10700323011,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 10700323012,
      city_id: 323,
      region_id: 7,
      name_ar: 'حي الضويحي',
      name_en: 'Al Dawayhi Dist.',
    },
    {
      district_id: 10701947001,
      city_id: 1947,
      region_id: 7,
      name_ar: 'حي صمدة',
      name_en: 'Samdah Dist.',
    },
    {
      district_id: 10701947002,
      city_id: 1947,
      region_id: 7,
      name_ar: 'حي المزارع',
      name_en: 'Al Mazari Dist.',
    },
    {
      district_id: 10701947003,
      city_id: 1947,
      region_id: 7,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10701947004,
      city_id: 1947,
      region_id: 7,
      name_ar: 'حي البلد',
      name_en: 'Al Balad Dist.',
    },
    {
      district_id: 10701947005,
      city_id: 1947,
      region_id: 7,
      name_ar: 'حي المقيطع',
      name_en: 'Al Muqayti Dist.',
    },
    {
      district_id: 10800010001,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي اجا',
      name_en: 'Aja Dist.',
    },
    {
      district_id: 10800010002,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10800010003,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المزعبر',
      name_en: 'Al Muzaabar Dist.',
    },
    {
      district_id: 10800010004,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي برزان',
      name_en: 'Barzan Dist.',
    },
    {
      district_id: 10800010005,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المحطة',
      name_en: 'Al Mahattah Dist.',
    },
    {
      district_id: 10800010006,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي سماح',
      name_en: 'Samah Dist.',
    },
    {
      district_id: 10800010007,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي لبدة',
      name_en: 'Labdah Dist.',
    },
    {
      district_id: 10800010008,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الزبارة',
      name_en: 'Az Zibarah Dist.',
    },
    {
      district_id: 10800010009,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي السمراء',
      name_en: 'As Samra Dist.',
    },
    {
      district_id: 10800010010,
      city_id: 10,
      region_id: 8,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10800010011,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 10800010012,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 10800010013,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي مغيضة',
      name_en: 'Mughaydah Dist.',
    },
    {
      district_id: 10800010014,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي حدري البلاد',
      name_en: 'Hadri Al Bilad Dist.',
    },
    {
      district_id: 10800010015,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الخريمي',
      name_en: 'Al Khuraymi Dist.',
    },
    {
      district_id: 10800010016,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي السويفلة',
      name_en: 'As Suwayfilah Dist.',
    },
    {
      district_id: 10800010017,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي ج2',
      name_en: '2 C Dist.',
    },
    {
      district_id: 10800010018,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي البدنة',
      name_en: 'Al Badnah Dist.',
    },
    {
      district_id: 10800010019,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الوسيطاء',
      name_en: 'Al Wusayta Dist.',
    },
    {
      district_id: 10800010020,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي صبابة',
      name_en: 'Sababah Dist.',
    },
    {
      district_id: 10800010021,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10800010022,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي البحيرة',
      name_en: 'Al Buhayrah Dist.',
    },
    {
      district_id: 10800010023,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المدائن',
      name_en: 'Al Madaen Dist.',
    },
    {
      district_id: 10800010024,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي النقرة قفار',
      name_en: 'An Naqrah Qafar Dist.',
    },
    {
      district_id: 10800010025,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي النقرة',
      name_en: 'An Naqrah Dist.',
    },
    {
      district_id: 10800010026,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الخماشية',
      name_en: 'Al Khamashiyah Dist.',
    },
    {
      district_id: 10800010027,
      city_id: 10,
      region_id: 8,
      name_ar: 'مطار حائل الدولي',
      name_en: 'Hail International Airport',
    },
    {
      district_id: 10800010029,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي السويفلة',
      name_en: 'As Suwayfilah Dist.',
    },
    {
      district_id: 10800010031,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المنتزه الغربي',
      name_en: 'Al Muntazah Al Gharbi Dist.',
    },
    {
      district_id: 10800010032,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10800010033,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي المنتزه الشرقي',
      name_en: 'Al Muntazah Ash Sharqi Dist.',
    },
    {
      district_id: 10800010034,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي اللقيطة',
      name_en: 'Al Laqitah Dist.',
    },
    {
      district_id: 10800010035,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10800010036,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الرصف',
      name_en: 'Ar Rasf Dist.',
    },
    {
      district_id: 10800010037,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الشفا',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 10800010038,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي ضاحية الملك فهد',
      name_en: 'Dahiyat King Fahd Dist',
    },
    {
      district_id: 10800010039,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي البادية',
      name_en: 'Al Badiyah Dist.',
    },
    {
      district_id: 10800010040,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10800010041,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي صلاح الدين الشرقي',
      name_en: 'Salahuddin Al Sharqi Dist.',
    },
    {
      district_id: 10800010042,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي الطريفي',
      name_en: 'Al Turaifi Dist.',
    },
    {
      district_id: 10800010043,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي النيصية',
      name_en: 'An Nasiyah Dist.',
    },
    {
      district_id: 10800010044,
      city_id: 10,
      region_id: 8,
      name_ar: 'حي قفار',
      name_en: 'Qfaar Dist.',
    },
    {
      district_id: 10801228001,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10801228002,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10801228003,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10801228004,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10801228005,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10801228006,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 10801228007,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10801228008,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الملك عبدالله',
      name_en: 'King Abdullah Dist.',
    },
    {
      district_id: 10801228009,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الحمراء',
      name_en: 'Al Hamra Dist.',
    },
    {
      district_id: 10801228010,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي العليا',
      name_en: 'Al Olaya Dist.',
    },
    {
      district_id: 10801228011,
      city_id: 1228,
      region_id: 8,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10802332001,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي النخيل الغربي',
      name_en: 'Al Nakhil Al Gharbi Dist.',
    },
    {
      district_id: 10802332002,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 10802332003,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10802332004,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الجامعة الغربي',
      name_en: 'Al Jamiah Al Gharbi Dist.',
    },
    {
      district_id: 10802332005,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الحفيرة الغربي',
      name_en: 'Al Hafira Al Gharbi Dist.',
    },
    {
      district_id: 10802332006,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي حفيرة السبعة الشمالي',
      name_en: 'Hafira Al Sabaa Al Shamali Dist.',
    },
    {
      district_id: 10802332007,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الاستراحات الأوسط',
      name_en: 'Al Estrahat Al Awsat Dist.',
    },
    {
      district_id: 10802332008,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الريان الغربي',
      name_en: 'Al Rayan Al Gharbi Dist.',
    },
    {
      district_id: 10802332009,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الحفيرة الشرقي',
      name_en: 'Al Hafira Al Sharqi Dist.',
    },
    {
      district_id: 10802332010,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي حفيرة السبعة الغربي',
      name_en: 'Hafira Al Sabaa Al Gharbi Dist.',
    },
    {
      district_id: 10802332011,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي حفاير العهين الغربي',
      name_en: 'Hafair Al Eahin Al Gharbi Dist.',
    },
    {
      district_id: 10802332012,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي حفيرة السبعة الشرقي',
      name_en: 'Hafira Al Sabaa Al Shamali Dist.',
    },
    {
      district_id: 10802332013,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10802332014,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الرفيعة',
      name_en: 'Al Rafiea Dist.',
    },
    {
      district_id: 10802332015,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الريان الشرقي',
      name_en: 'Al Rayan Al Sharqi Dist.',
    },
    {
      district_id: 10802332016,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10802332017,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي البلدية',
      name_en: 'Al Baladiyah Dist.',
    },
    {
      district_id: 10802332018,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي التعاون',
      name_en: 'At Taawun Dist.',
    },
    {
      district_id: 10802332019,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الديرة',
      name_en: 'Ad Dirah Dist.',
    },
    {
      district_id: 10802332020,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الاستراحات الغربي',
      name_en: 'Al Estrahat Al Gharbi Dist.',
    },
    {
      district_id: 10802332021,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الجامعة الشرقي',
      name_en: 'Al Jamiah Al Sharqi Dist.',
    },
    {
      district_id: 10802332022,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الاستراحات الشرقي',
      name_en: 'Al Estrahat  Al Sharqi  Dist.',
    },
    {
      district_id: 10802332023,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي الجامعة الاوسط',
      name_en: 'Al Jamiah Al Awsat Dist.',
    },
    {
      district_id: 10802332024,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي النخيل الشرقي',
      name_en: 'Al Nakhil Al Sharqi Dist.',
    },
    {
      district_id: 10802332025,
      city_id: 2332,
      region_id: 8,
      name_ar: 'حي حفاير العهين الشرقي',
      name_en: 'Hafair Al Eahin Al Sharqi Dist.',
    },
    {
      district_id: 10802370001,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي بقعاء الشرقيه',
      name_en: 'Baqa Al Sharqia Dist.',
    },
    {
      district_id: 10802370002,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي صلاح الدين',
      name_en: 'Salahuddin Dist.',
    },
    {
      district_id: 10802370003,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي الظهيره',
      name_en: 'Al Dhahira Dist.',
    },
    {
      district_id: 10802370004,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي الحفل',
      name_en: 'Al Hafil Dist.',
    },
    {
      district_id: 10802370005,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج9',
      name_en: 'C9 Dist.',
    },
    {
      district_id: 10802370006,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج8',
      name_en: 'C8 Dist.',
    },
    {
      district_id: 10802370007,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج7',
      name_en: 'C7 Dist.',
    },
    {
      district_id: 10802370008,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج6',
      name_en: 'C6 Dist.',
    },
    {
      district_id: 10802370009,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج5',
      name_en: 'C5 Dist.',
    },
    {
      district_id: 10802370010,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج4',
      name_en: 'C4 Dist.',
    },
    {
      district_id: 10802370011,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج3',
      name_en: 'C3 Dist.',
    },
    {
      district_id: 10802370012,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج2',
      name_en: 'C2 Dist.',
    },
    {
      district_id: 10802370013,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج16',
      name_en: 'C16 Dist.',
    },
    {
      district_id: 10802370014,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج15',
      name_en: 'C15 Dist.',
    },
    {
      district_id: 10802370015,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج13',
      name_en: 'C13 Dist.',
    },
    {
      district_id: 10802370016,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج12',
      name_en: 'C12 Dist.',
    },
    {
      district_id: 10802370017,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج11',
      name_en: 'C11 Dist.',
    },
    {
      district_id: 10802370018,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي ج10',
      name_en: 'C10 Dist.',
    },
    {
      district_id: 10802370019,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي بقعاء القديمه',
      name_en: 'Baqa Al Qadima Dist.',
    },
    {
      district_id: 10802370020,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي بقعاء الشرقيه القديمه',
      name_en: 'Baqa Al Sharqia Al Qadima Dist.',
    },
    {
      district_id: 10802370021,
      city_id: 2370,
      region_id: 8,
      name_ar: 'حي الوسطى',
      name_en: 'Al Wusta Dist.',
    },
    {
      district_id: 10902208001,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10902208003,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي السلطانة',
      name_en: 'Al Sultanah Dist.',
    },
    {
      district_id: 10902208004,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10902208005,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10902208006,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10902208007,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10902208008,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي المساعدية',
      name_en: 'Al Misadiyah Dist.',
    },
    {
      district_id: 10902208009,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 10902208010,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10902208011,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 10902208012,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10902208013,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10902208014,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي المنتزه',
      name_en: 'Al Muntazah Dist.',
    },
    {
      district_id: 10902208015,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي اسكان قوى الأمن',
      name_en: 'Security Forces Housing Dist.',
    },
    {
      district_id: 10902208016,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10902208017,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الفيصلية شرق',
      name_en: 'East Al Faisaliyah Dist.',
    },
    {
      district_id: 10902208018,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الصناعي',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 10902208019,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الصناعية القديمة',
      name_en: 'Old Industrial Dist.',
    },
    {
      district_id: 10902208020,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الصناعية الجديدة',
      name_en: 'New Industrial Dist.',
    },
    {
      district_id: 10902208021,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 10902208022,
      city_id: 2208,
      region_id: 9,
      name_ar: 'حي الفيصلية غرب',
      name_en: 'West Al Faisaliyah Dist.',
    },
    {
      district_id: 10902213001,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي غرناطة الشرقي',
      name_en: 'Ghirnatah Eastern Dist.',
    },
    {
      district_id: 10902213002,
      city_id: 2213,
      region_id: 9,
      name_ar: 'أملاك الحرس الوطني',
      name_en: 'National Guard Property',
    },
    {
      district_id: 10902213003,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الشروق الاداري',
      name_en: 'Ash Shuruq Administrative Dist.',
    },
    {
      district_id: 10902213004,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي القدس الشرقي',
      name_en: 'Al Quds Eastern Dist.',
    },
    {
      district_id: 10902213005,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النخيل الشمالي',
      name_en: 'Al Nakheel Northern Dist.',
    },
    {
      district_id: 10902213006,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي ضاحية الملك فهد',
      name_en: 'Dahiyat King Fahd Dist',
    },
    {
      district_id: 10902213007,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المروج الشرقي',
      name_en: 'Al Muruj Eastern Dist.',
    },
    {
      district_id: 10902213008,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 10902213009,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي اشبيلية الشمالي',
      name_en: 'Ishbiliyah Northern Dist.',
    },
    {
      district_id: 10902213010,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الشامل',
      name_en: 'Ash Shamel Dist.',
    },
    {
      district_id: 10902213011,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الصناعية الاولى',
      name_en: '1st Industrial Dist.',
    },
    {
      district_id: 10902213012,
      city_id: 2213,
      region_id: 9,
      name_ar: 'مدينة الانعام',
      name_en: 'Al Anam City',
    },
    {
      district_id: 10902213013,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الصقًار',
      name_en: 'As Saqar Dist.',
    },
    {
      district_id: 10902213014,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي تشليح السيارات',
      name_en: 'Auto Shredder Dist.',
    },
    {
      district_id: 10902213015,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي البساتين الاداري الغربي',
      name_en: 'Al Basatin Administrative Western Dist.',
    },
    {
      district_id: 10902213016,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الشذا',
      name_en: 'Ash Shatha Dist.',
    },
    {
      district_id: 10902213017,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي تلال عرعر',
      name_en: 'Telal Arar Dist.',
    },
    {
      district_id: 10902213018,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 10902213019,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الرفاع',
      name_en: 'Ar Rifaa Dist.',
    },
    {
      district_id: 10902213020,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الفنار',
      name_en: 'Al Fanar Dist.',
    },
    {
      district_id: 10902213021,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الخليج الشرقي',
      name_en: 'Al Khaleej Eastern Dist.',
    },
    {
      district_id: 10902213022,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 10902213023,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المنصورية',
      name_en: 'Al Mansuriyah Dist.',
    },
    {
      district_id: 10902213024,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الارجوان الغربي',
      name_en: 'Al Orjwan Wesrtern Dist.',
    },
    {
      district_id: 10902213025,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 10902213026,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10902213027,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayaan Dist.',
    },
    {
      district_id: 10902213028,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النور',
      name_en: 'An Noor Dist.',
    },
    {
      district_id: 10902213029,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الأمانه الاداري',
      name_en: 'Al Amanah Dist.',
    },
    {
      district_id: 10902213030,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10902213031,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10902213032,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي بدنة',
      name_en: 'Badanah Dist.',
    },
    {
      district_id: 10902213033,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المطار',
      name_en: 'Al Matar Dist.',
    },
    {
      district_id: 10902213034,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي مشرف',
      name_en: 'Mshrif Dist.',
    },
    {
      district_id: 10902213035,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 10902213036,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10902213037,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10902213038,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المساعدية',
      name_en: 'Al Musaidiyah Dist.',
    },
    {
      district_id: 10902213039,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الوئام',
      name_en: 'Al Weaam Dist.',
    },
    {
      district_id: 10902213040,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النسيم',
      name_en: 'An Naseem Dist.',
    },
    {
      district_id: 10902213041,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المهندسين',
      name_en: 'Al Muhandisin Dist.',
    },
    {
      district_id: 10902213042,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي قرطبة',
      name_en: 'Qurtubah Dist.',
    },
    {
      district_id: 10902213043,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 10902213044,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 10902213045,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي النخيل الجنوبي',
      name_en: 'Al Nakheel Southern Dist.',
    },
    {
      district_id: 10902213046,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الجامعة',
      name_en: 'Al Jamiah Dist.',
    },
    {
      district_id: 10902213047,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي البساتين الاداري الشرقي',
      name_en: 'Al Basatin Administrative Eastern Dist.',
    },
    {
      district_id: 10902213048,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي البراعم',
      name_en: 'Al Baraam Dist.',
    },
    {
      district_id: 10902213049,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي معيلة',
      name_en: 'Maaelah Dist.',
    },
    {
      district_id: 10902213050,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المباركية',
      name_en: 'Al Mabarakiyah Dist.',
    },
    {
      district_id: 10902213051,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي هجرة ابن سعيد',
      name_en: 'Hijrat Bin Saeed Dist.',
    },
    {
      district_id: 10902213052,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الصناعية الثانية',
      name_en: '2nd Industrial Dist.',
    },
    {
      district_id: 10902213053,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الهلالي',
      name_en: 'Al Helali Dist.',
    },
    {
      district_id: 10902213054,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الفروسية',
      name_en: 'Al Frosyah Dist.',
    },
    {
      district_id: 10902213055,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي اشبيلية الجنوبي',
      name_en: 'Ishbiliyah Southern Dist.',
    },
    {
      district_id: 10902213056,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي القدس الغربي',
      name_en: 'Al Quds Wesrtern Dist.',
    },
    {
      district_id: 10902213057,
      city_id: 2213,
      region_id: 9,
      name_ar: 'محجوزات ارمكو',
      name_en: 'Aramco Property',
    },
    {
      district_id: 10902213058,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي التقنية',
      name_en: 'Al Taqniyah Dist.',
    },
    {
      district_id: 10902213059,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي ام الضيان',
      name_en: 'Um Al Dayan Dist.',
    },
    {
      district_id: 10902213060,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي غرناطة الغربي',
      name_en: 'Ghirnatah Wesrtern Dist.',
    },
    {
      district_id: 10902213061,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الارجوان الشرقي',
      name_en: 'Al Orjwan Eastern Dist.',
    },
    {
      district_id: 10902213062,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الخليج الغربي',
      name_en: 'Al Khaleej Wesrtern Dist.',
    },
    {
      district_id: 10902213063,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 10902213064,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 10902213065,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي الاصداف',
      name_en: 'Al Asdaf Dist.',
    },
    {
      district_id: 10902213066,
      city_id: 2213,
      region_id: 9,
      name_ar: 'حي المروج الغربي',
      name_en: 'Al Muruj Wesrtern Dist.',
    },
    {
      district_id: 10902215001,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 10902215002,
      city_id: 2215,
      region_id: 9,
      name_ar: 'مخطط المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 10902215003,
      city_id: 2215,
      region_id: 9,
      name_ar: 'مهاجع حرس الحدود',
      name_en: 'Border Guard Housing',
    },
    {
      district_id: 10902215004,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 10902215005,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي ج1',
      name_en: 'C1 Dist.',
    },
    {
      district_id: 10902215006,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي الأندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 10902215007,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10902215008,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 10902215009,
      city_id: 2215,
      region_id: 9,
      name_ar: 'مخطط  شرق مدينة العويقيلة',
      name_en: 'East Al Uwayqilah City Subdivision Plan',
    },
    {
      district_id: 10902215010,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10902215011,
      city_id: 2215,
      region_id: 9,
      name_ar: 'المربع الخدمي',
      name_en: 'Al Murabba Al Khadamy',
    },
    {
      district_id: 10902215012,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي ج4',
      name_en: 'C4 Dist.',
    },
    {
      district_id: 10902215013,
      city_id: 2215,
      region_id: 9,
      name_ar: 'ادارة التعليم',
      name_en: 'Education Administration',
    },
    {
      district_id: 10902215014,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 10902215015,
      city_id: 2215,
      region_id: 9,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10902256001,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي العمال',
      name_en: 'Al Ummal Dist.',
    },
    {
      district_id: 10902256003,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي المدينة',
      name_en: 'Al Madinah Dist.',
    },
    {
      district_id: 10902256004,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 10902256008,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي  الادارى',
      name_en: 'Al Idari Dist.',
    },
    {
      district_id: 10902256009,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 10902256011,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الملز',
      name_en: 'Al Malaz Dist.',
    },
    {
      district_id: 10902256012,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 10902256013,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 10902256014,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 10902256015,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الشمال',
      name_en: 'Ash Shamal Dist.',
    },
    {
      district_id: 10902256017,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الجميماء',
      name_en: 'Al Jumayma Dist.',
    },
    {
      district_id: 10902256018,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي المساعدية',
      name_en: 'Al Misadiyah Dist.',
    },
    {
      district_id: 10902256019,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 10902256020,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي القادسية',
      name_en: 'Al Qadisiyah Dist.',
    },
    {
      district_id: 10902256021,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 10902256022,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 10902256023,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 10902256024,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي النموذجية',
      name_en: 'An Namudhajiyah Dist.',
    },
    {
      district_id: 10902256025,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي التعاون',
      name_en: 'Al Taawun Dist.',
    },
    {
      district_id: 10902256026,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 10902256027,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي إسكان حرس الحدود',
      name_en: 'Border Guard Iskan Dist.',
    },
    {
      district_id: 10902256028,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الاسكان الخيرى',
      name_en: 'Al Iskan Al Khairy Dist.',
    },
    {
      district_id: 10902256029,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 10902256030,
      city_id: 2256,
      region_id: 9,
      name_ar: 'حي الجبهان',
      name_en: 'Al Jabhan Dist.',
    },
    {
      district_id: 10902256031,
      city_id: 2256,
      region_id: 9,
      name_ar: 'جامعة الحدود الشمالية',
      name_en: 'Northern Border University',
    },
    {
      district_id: 11000017001,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي القلعة',
      name_en: 'Al Qalah Dist.',
    },
    {
      district_id: 11000017002,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الاراك',
      name_en: 'Al Arak Dist.',
    },
    {
      district_id: 11000017004,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 11000017005,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي السويس',
      name_en: 'As Suways Dist.',
    },
    {
      district_id: 11000017006,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي البشائر',
      name_en: 'Al Bashaer Dist.',
    },
    {
      district_id: 11000017007,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي المطار',
      name_en: 'Airport Dist.',
    },
    {
      district_id: 11000017008,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 11000017009,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 11000017010,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الشاطئ',
      name_en: 'Ash Shati Dist.',
    },
    {
      district_id: 11000017011,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11000017012,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الشامية',
      name_en: 'Ash Shamiyah Dist.',
    },
    {
      district_id: 11000017013,
      city_id: 17,
      region_id: 10,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 11000017014,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 11000017015,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 11000017016,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي الجبل',
      name_en: 'Al Jabal Dist.',
    },
    {
      district_id: 11000017017,
      city_id: 17,
      region_id: 10,
      name_ar: 'حي مزهرة',
      name_en: 'Mizhirah Dist.',
    },
    {
      district_id: 11003479001,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayyan Dist.',
    },
    {
      district_id: 11003479002,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الحسيني',
      name_en: 'Al Hussaini Dist.',
    },
    {
      district_id: 11003479003,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الفلاح',
      name_en: 'Al Falah Dist.',
    },
    {
      district_id: 11003479004,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي حلة الأحوس',
      name_en: 'Hullat Al Ahwass Dist.',
    },
    {
      district_id: 11003479005,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي جخيرة',
      name_en: 'Jukhairah Dist.',
    },
    {
      district_id: 11003479006,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11003479007,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11003479008,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 11003479009,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 11003479010,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي السعادة',
      name_en: 'As Saadah Dist.',
    },
    {
      district_id: 11003479011,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي المجد',
      name_en: 'Al Majd Dist.',
    },
    {
      district_id: 11003479012,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحشيبرية',
      name_en: 'Al Hushaibriyah',
    },
    {
      district_id: 11003479013,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 11003479014,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11003479015,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 11003479016,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الفردوس',
      name_en: 'Al Fardus Dist.',
    },
    {
      district_id: 11003479017,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 11003479018,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 11003479019,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11003479020,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11003479021,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 11003479022,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 11003479023,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الرونة',
      name_en: 'Ar Rawnah',
    },
    {
      district_id: 11003479024,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الغراء',
      name_en: 'Al Gharraa',
    },
    {
      district_id: 11003479025,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي العريش',
      name_en: 'Al Arish Dist.',
    },
    {
      district_id: 11003479026,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي بوتيشة',
      name_en: 'Butishah Dist.',
    },
    {
      district_id: 11003479027,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي المعترض',
      name_en: 'Al Muutarid Dist.',
    },
    {
      district_id: 11003479028,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حي الظبية',
      name_en: 'Al Dhabyah Dist.',
    },
    {
      district_id: 11003479029,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الباحر',
      name_en: 'Al Bahir',
    },
    {
      district_id: 11003479030,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الفقره',
      name_en: 'Al Faqrah',
    },
    {
      district_id: 11003479031,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشواهية',
      name_en: 'Al Shawahiyah',
    },
    {
      district_id: 11003479032,
      city_id: 3479,
      region_id: 10,
      name_ar: 'شهده',
      name_en: 'Shahdah',
    },
    {
      district_id: 11003479033,
      city_id: 3479,
      region_id: 10,
      name_ar: 'عبدالله العيافي (العيافي)',
      name_en: 'Abdullah Al Eyafi (Al Eyafi)',
    },
    {
      district_id: 11003479034,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله محسنالمحاسنة',
      name_en: 'Halah Muhsin Al Muhasana',
    },
    {
      district_id: 11003479035,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الجعاونه',
      name_en: 'Al Jaeawunah',
    },
    {
      district_id: 11003479036,
      city_id: 3479,
      region_id: 10,
      name_ar: 'السروريه',
      name_en: 'Al Saruriyah',
    },
    {
      district_id: 11003479037,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله القميري',
      name_en: 'Halah Al Qamiri',
    },
    {
      district_id: 11003479038,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ابو دنقور',
      name_en: 'Abu Danqur',
    },
    {
      district_id: 11003479039,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ام القحفه',
      name_en: 'Umm Al Qahfah',
    },
    {
      district_id: 11003479040,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله مروعي بن شامي',
      name_en: 'Halah Maruei Bin Shamy',
    },
    {
      district_id: 11003479041,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العداية',
      name_en: 'Al Addayah',
    },
    {
      district_id: 11003479042,
      city_id: 3479,
      region_id: 10,
      name_ar: 'قبه',
      name_en: 'Qibah',
    },
    {
      district_id: 11003479043,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العواجمه',
      name_en: 'Aleawajimuh',
    },
    {
      district_id: 11003479044,
      city_id: 3479,
      region_id: 10,
      name_ar: 'النزلـــه',
      name_en: 'Alnzlh',
    },
    {
      district_id: 11003479045,
      city_id: 3479,
      region_id: 10,
      name_ar: 'البطيح',
      name_en: 'Albatih',
    },
    {
      district_id: 11003479046,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الكومه',
      name_en: 'Alkawmuh',
    },
    {
      district_id: 11003479047,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العشة',
      name_en: 'Al Ashah',
    },
    {
      district_id: 11003479048,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حنقفه',
      name_en: 'Hanaqfah',
    },
    {
      district_id: 11003479049,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ابو الطيور',
      name_en: 'Abu Altuyur',
    },
    {
      district_id: 11003479050,
      city_id: 3479,
      region_id: 10,
      name_ar: 'عوانه',
      name_en: 'Eawanah',
    },
    {
      district_id: 11003479051,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العرضه',
      name_en: 'Al Ardah',
    },
    {
      district_id: 11003479052,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حلة العرشي',
      name_en: 'Hulat Al Earshi',
    },
    {
      district_id: 11003479053,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العسيله',
      name_en: 'Aleasiluh',
    },
    {
      district_id: 11003479054,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحقاويه',
      name_en: 'Alhaqawih',
    },
    {
      district_id: 11003479055,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشعاره',
      name_en: 'Al Shiarah',
    },
    {
      district_id: 11003479056,
      city_id: 3479,
      region_id: 10,
      name_ar: 'القواعة',
      name_en: 'Al Qawaea',
    },
    {
      district_id: 11003479057,
      city_id: 3479,
      region_id: 10,
      name_ar: 'تيهان (حلة تيهان)',
      name_en: 'Tyhan (Hulat Tyhan)',
    },
    {
      district_id: 11003479058,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الدواحشه',
      name_en: 'Al Dawahishah',
    },
    {
      district_id: 11003479059,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الباطنه',
      name_en: 'Al Batinah',
    },
    {
      district_id: 11003479060,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشمه',
      name_en: 'Al Shamuh',
    },
    {
      district_id: 11003479061,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الطراشه',
      name_en: 'Alt Arashah',
    },
    {
      district_id: 11003479062,
      city_id: 3479,
      region_id: 10,
      name_ar: 'جريبه',
      name_en: 'Jaribuh',
    },
    {
      district_id: 11003479063,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الهدوى',
      name_en: 'Alhadwaa',
    },
    {
      district_id: 11003479065,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حلة ابو حبيبه',
      name_en: 'Hulat Abu Habibuh',
    },
    {
      district_id: 11003479066,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحرف',
      name_en: 'Alharaf',
    },
    {
      district_id: 11003479067,
      city_id: 3479,
      region_id: 10,
      name_ar: 'المجديره',
      name_en: 'Almajdiruh',
    },
    {
      district_id: 11003479068,
      city_id: 3479,
      region_id: 10,
      name_ar: 'السبخة',
      name_en: 'Al Sabakhah',
    },
    {
      district_id: 11003479069,
      city_id: 3479,
      region_id: 10,
      name_ar: 'منسيه',
      name_en: 'Mansih',
    },
    {
      district_id: 11003479071,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الخواره',
      name_en: 'Al Khawarah',
    },
    {
      district_id: 11003479072,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الهجاريه',
      name_en: 'Al Hajariah',
    },
    {
      district_id: 11003479073,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشواجرة والجرابية',
      name_en: 'Al Shawajirat Wal Jarabiyah',
    },
    {
      district_id: 11003479074,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الخواجيه',
      name_en: 'Al Khawajih',
    },
    {
      district_id: 11003479075,
      city_id: 3479,
      region_id: 10,
      name_ar: 'السوالمه',
      name_en: 'Al Sawalimah',
    },
    {
      district_id: 11003479076,
      city_id: 3479,
      region_id: 10,
      name_ar: 'النعامية',
      name_en: 'Al Naeamia',
    },
    {
      district_id: 11003479077,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الفقرة',
      name_en: 'Al Fiqarah',
    },
    {
      district_id: 11003479078,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الزيديه',
      name_en: 'Alzaydiuh',
    },
    {
      district_id: 11003479079,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الخوالده',
      name_en: 'Alkhawaliduh',
    },
    {
      district_id: 11003479080,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ام العرش',
      name_en: 'Umm Alearsh',
    },
    {
      district_id: 11003479081,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحمضيه',
      name_en: 'Alhimdih',
    },
    {
      district_id: 11003479082,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الصنيف',
      name_en: 'Alsanif',
    },
    {
      district_id: 11003479083,
      city_id: 3479,
      region_id: 10,
      name_ar: 'وجه الحسن',
      name_en: 'Wajah Alhasan',
    },
    {
      district_id: 11003479084,
      city_id: 3479,
      region_id: 10,
      name_ar: 'قوز الجعافره',
      name_en: 'Qouz Al Jaafrah',
    },
    {
      district_id: 11003479085,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العبده',
      name_en: 'Aleabduh',
    },
    {
      district_id: 11003479086,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حلة ابراهيم عيسى',
      name_en: 'Hulat Iibrahim Eisaa',
    },
    {
      district_id: 11003479087,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الرجيع',
      name_en: 'Al Rajie',
    },
    {
      district_id: 11003479089,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الأثلة',
      name_en: 'Al Athlah',
    },
    {
      district_id: 11003479090,
      city_id: 3479,
      region_id: 10,
      name_ar: 'زربه',
      name_en: 'Zarabah',
    },
    {
      district_id: 11003479091,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الجديين',
      name_en: 'Al Jidiayn',
    },
    {
      district_id: 11003479092,
      city_id: 3479,
      region_id: 10,
      name_ar: 'شيبان',
      name_en: 'Shayban',
    },
    {
      district_id: 11003479093,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحجرين',
      name_en: 'Alhajarayn',
    },
    {
      district_id: 11003479094,
      city_id: 3479,
      region_id: 10,
      name_ar: 'زباره الجديين',
      name_en: 'Zubarah Al Jidyeen',
    },
    {
      district_id: 11003479095,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله محمد عيسى',
      name_en: 'Halah Muhamad Eisa',
    },
    {
      district_id: 11003479096,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله العقيبي',
      name_en: 'Halah Aleaqibiu',
    },
    {
      district_id: 11003479097,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله الحو تين',
      name_en: 'Halah Alhawa Tyn',
    },
    {
      district_id: 11003479098,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله الصهلولي',
      name_en: 'Halah Al Sahluli',
    },
    {
      district_id: 11003479099,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله مشاري',
      name_en: 'Halah Mishary',
    },
    {
      district_id: 11003479100,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله الحكمى',
      name_en: 'Halah Al Hukmaa',
    },
    {
      district_id: 11003479101,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله عبدالله قبع',
      name_en: 'Halah Abdullah Qabea',
    },
    {
      district_id: 11003479102,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله الكلابيه',
      name_en: 'Halah Al Kalabih',
    },
    {
      district_id: 11003479103,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله بن علوان',
      name_en: 'Halah Bin Alwaan',
    },
    {
      district_id: 11003479104,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حلة المكي',
      name_en: 'Hulat Al Makki',
    },
    {
      district_id: 11003479105,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله ابو مرعي',
      name_en: 'Halah Abu Marei',
    },
    {
      district_id: 11003479106,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حله عطيه',
      name_en: 'Halah Attieh',
    },
    {
      district_id: 11003479107,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الخضراء',
      name_en: 'Al Khadraa',
    },
    {
      district_id: 11003479108,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الجمالة',
      name_en: 'Al Jammalah',
    },
    {
      district_id: 11003479109,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الدحيقي',
      name_en: 'Al Daheeqi',
    },
    {
      district_id: 11003479110,
      city_id: 3479,
      region_id: 10,
      name_ar: 'قرية الجوابره',
      name_en: 'Qaryat Al Jawabirih',
    },
    {
      district_id: 11003479111,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الزقله',
      name_en: 'Al Zaqlah',
    },
    {
      district_id: 11003479112,
      city_id: 3479,
      region_id: 10,
      name_ar: 'نورة',
      name_en: 'Nurah',
    },
    {
      district_id: 11003479113,
      city_id: 3479,
      region_id: 10,
      name_ar: 'جميما',
      name_en: 'Jameema',
    },
    {
      district_id: 11003479114,
      city_id: 3479,
      region_id: 10,
      name_ar: 'المحاصيه',
      name_en: 'Al Mahasih',
    },
    {
      district_id: 11003479115,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الجاره القديمه',
      name_en: 'Al Jarah Al Qadiumah',
    },
    {
      district_id: 11003479116,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الزينيه',
      name_en: 'Al Ziniyah',
    },
    {
      district_id: 11003479117,
      city_id: 3479,
      region_id: 10,
      name_ar: 'حلة علي بن موسى',
      name_en: 'Hulat Ali Bin Musa',
    },
    {
      district_id: 11003479118,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الصامخ',
      name_en: 'Al Samikh',
    },
    {
      district_id: 11003479119,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشقافه والمصاوفة',
      name_en: 'Al Shiqafah Wal Musawafa',
    },
    {
      district_id: 11003479120,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الكلبه',
      name_en: 'Al Kalabah',
    },
    {
      district_id: 11003479121,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الفصاله والدغاريه',
      name_en: 'Al Fisalah Wal Dagharih',
    },
    {
      district_id: 11003479122,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحوامضه جحيش',
      name_en: 'Al Hawamidah Jahish',
    },
    {
      district_id: 11003479123,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشعافه',
      name_en: 'Al Shaeafah',
    },
    {
      district_id: 11003479124,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ابوالمقره',
      name_en: 'Abu Al Maqrah',
    },
    {
      district_id: 11003479125,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ام الشباقاء',
      name_en: 'Umm Al Shibaqa',
    },
    {
      district_id: 11003479126,
      city_id: 3479,
      region_id: 10,
      name_ar: 'اللخبصية',
      name_en: 'Al Lakhabsia',
    },
    {
      district_id: 11003479127,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الشاخر',
      name_en: 'Al Shaakhir',
    },
    {
      district_id: 11003479128,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ابو السلع',
      name_en: 'Abu As Sala',
    },
    {
      district_id: 11003479129,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الزيني',
      name_en: 'Al Zayni',
    },
    {
      district_id: 11003479130,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العمارده',
      name_en: 'Al Amaridah',
    },
    {
      district_id: 11003479131,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الحواية',
      name_en: 'Al Hiwaya',
    },
    {
      district_id: 11003479132,
      city_id: 3479,
      region_id: 10,
      name_ar: 'القصاده',
      name_en: 'Al Qasadah',
    },
    {
      district_id: 11003479133,
      city_id: 3479,
      region_id: 10,
      name_ar: 'النجابه و الجحاجح',
      name_en: 'Al Nijabah Wal Jahajih',
    },
    {
      district_id: 11003479134,
      city_id: 3479,
      region_id: 10,
      name_ar: 'العرجين',
      name_en: 'Al Arjeen',
    },
    {
      district_id: 11003479135,
      city_id: 3479,
      region_id: 10,
      name_ar: 'ابو الكرش',
      name_en: 'Abu Alkarsh',
    },
    {
      district_id: 11003479136,
      city_id: 3479,
      region_id: 10,
      name_ar: 'السداده',
      name_en: 'Alsidaduh',
    },
    {
      district_id: 11003479137,
      city_id: 3479,
      region_id: 10,
      name_ar: 'الرافعي',
      name_en: 'Al Rafie',
    },
    {
      district_id: 11003525001,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي السادلية',
      name_en: 'As Sadiliyah Dist.',
    },
    {
      district_id: 11003525002,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud  Dist.',
    },
    {
      district_id: 11003525003,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 11003525004,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي القدس',
      name_en: 'Al Quds Dist.',
    },
    {
      district_id: 11003525005,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 11003525006,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 11003525007,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 11003525008,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11003525009,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 11003525010,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11003525011,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11003525012,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 11003525013,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 11003525014,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 11003525015,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 11003525016,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي العقدة',
      name_en: 'Al Uqdah Dist.',
    },
    {
      district_id: 11003525017,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي المريخية',
      name_en: 'Al Muraikhiyah Dist.',
    },
    {
      district_id: 11003525018,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي المهدج',
      name_en: 'Al Muhdaj Dist.',
    },
    {
      district_id: 11003525019,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي قويعية',
      name_en: 'Quwaiyyah Dist.',
    },
    {
      district_id: 11003525020,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي قامرة',
      name_en: 'Kamirah Dist.',
    },
    {
      district_id: 11003525021,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي حاكمة ابي عريش',
      name_en: 'Hakimat Abi Arish Dist.',
    },
    {
      district_id: 11003525022,
      city_id: 3525,
      region_id: 10,
      name_ar: 'حي الشاقة',
      name_en: 'Ash Shakah Dist.',
    },
    {
      district_id: 11003542001,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الركوبة',
      name_en: 'Ar Rakubah Dist.',
    },
    {
      district_id: 11003542002,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الجردية',
      name_en: 'Al Jurdiyyah Dist.',
    },
    {
      district_id: 11003542003,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الجرادية',
      name_en: 'Al Jaradiyah Dist.',
    },
    {
      district_id: 11003542004,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي صامطة',
      name_en: 'Samtah Dist.',
    },
    {
      district_id: 11003542005,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الطرشية',
      name_en: 'Al Turshiah Dist.',
    },
    {
      district_id: 11003542006,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي مجعر',
      name_en: 'Majaar Dist.',
    },
    {
      district_id: 11003542007,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الخضراء',
      name_en: 'Al Khadra Dist.',
    },
    {
      district_id: 11003542008,
      city_id: 3542,
      region_id: 10,
      name_ar: 'الواسط',
      name_en: 'Al Wasit',
    },
    {
      district_id: 11003542009,
      city_id: 3542,
      region_id: 10,
      name_ar: 'حي الزاوية',
      name_en: 'Al Zawiyah Dist.',
    },
    {
      district_id: 11003597001,
      city_id: 3597,
      region_id: 10,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11003597002,
      city_id: 3597,
      region_id: 10,
      name_ar: 'حي الروابى',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 11003597003,
      city_id: 3597,
      region_id: 10,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 11003652001,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الغصينية',
      name_en: 'Al Ghusainiah Dist.',
    },
    {
      district_id: 11003652002,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الواسط',
      name_en: 'Al Wasit Dist.',
    },
    {
      district_id: 11003652003,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي العمارية',
      name_en: 'Al Amariyah Dist.',
    },
    {
      district_id: 11003652004,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي القسوم',
      name_en: 'Al Qasum Dist.',
    },
    {
      district_id: 11003652005,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 11003652006,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي البياطرية',
      name_en: 'Al Bayatirah Dist.',
    },
    {
      district_id: 11003652007,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي خلب',
      name_en: 'Khalab Dist.',
    },
    {
      district_id: 11003652008,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11003652009,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي العز',
      name_en: 'Al Izz Dist.',
    },
    {
      district_id: 11003652010,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي البلدية',
      name_en: 'Al Baladiah Dist.',
    },
    {
      district_id: 11003652011,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الميزاب',
      name_en: 'Al Mizab  Dist.',
    },
    {
      district_id: 11003652012,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي المنزم',
      name_en: 'Al Manzam Dist.',
    },
    {
      district_id: 11003652013,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي المطلع',
      name_en: 'Al Mutalla Dist.',
    },
    {
      district_id: 11003652014,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الحصامة',
      name_en: 'Al Hasamah Dist.',
    },
    {
      district_id: 11003652015,
      city_id: 3652,
      region_id: 10,
      name_ar: 'المنطقة الصناعية',
      name_en: 'Industrial Area',
    },
    {
      district_id: 11003652016,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي حكامية العز',
      name_en: 'Hakimiat Al Iz Dist.',
    },
    {
      district_id: 11003652017,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي حاكمة الدغارير',
      name_en: 'Hakimat Al Dagharir Dist.',
    },
    {
      district_id: 11003652018,
      city_id: 3652,
      region_id: 10,
      name_ar: 'حي الدغارير',
      name_en: 'Al Dagharir Dist.',
    },
    {
      district_id: 11102522001,
      city_id: 2522,
      region_id: 11,
      name_ar: 'حي الصالحية',
      name_en: 'As Salihiyah Dist.',
    },
    {
      district_id: 11102522002,
      city_id: 2522,
      region_id: 11,
      name_ar: 'حي يدمة الثاني',
      name_en: 'Yadamah Al Thani Dist.',
    },
    {
      district_id: 11102522003,
      city_id: 2522,
      region_id: 11,
      name_ar: 'حي جنوب يدمة',
      name_en: 'Janub Yadamah Dist.',
    },
    {
      district_id: 11102522004,
      city_id: 2522,
      region_id: 11,
      name_ar: 'حي يدمة الاول',
      name_en: 'Yadamah Al Awal Dist.',
    },
    {
      district_id: 11102522005,
      city_id: 2522,
      region_id: 11,
      name_ar: 'حي الحزام الشرقي',
      name_en: 'Al Hizam Ash Sharqi Dist.',
    },
    {
      district_id: 11103347001,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11103347002,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي السوق',
      name_en: 'As Suq Dist.',
    },
    {
      district_id: 11103347003,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي جنوب المستشفى العسكري',
      name_en: 'Janub Al Mustashfa Al Askari Dist.',
    },
    {
      district_id: 11103347004,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 11103347005,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي المستشفى العسكري',
      name_en: 'Al Mustashfa Al Askari Dist.',
    },
    {
      district_id: 11103347006,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي الروضة الشمالية',
      name_en: 'Ar Rawdah Ash Shamaliyyah Dist.',
    },
    {
      district_id: 11103347007,
      city_id: 3347,
      region_id: 11,
      name_ar: 'مخطط ا',
      name_en: 'Subdivision Plan A',
    },
    {
      district_id: 11103347008,
      city_id: 3347,
      region_id: 11,
      name_ar: 'مخطط ب',
      name_en: 'Subdivision Plan B',
    },
    {
      district_id: 11103347009,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiiyah Dist.',
    },
    {
      district_id: 11103347010,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي الفهد',
      name_en: 'Al Fahd Dist.',
    },
    {
      district_id: 11103347011,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي الروضة الجنوبية',
      name_en: 'Ar Rawdah Al Janubiyyah Dist.',
    },
    {
      district_id: 11103347012,
      city_id: 3347,
      region_id: 11,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11103396001,
      city_id: 3396,
      region_id: 11,
      name_ar: 'حي حبونا',
      name_en: 'Hubuna Dist.',
    },
    {
      district_id: 11103417001,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي العان',
      name_en: 'Al Aan Dist.',
    },
    {
      district_id: 11103417002,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشرفة الجنوبية',
      name_en: 'South Shurfah Dist.',
    },
    {
      district_id: 11103417003,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الغويلا ب',
      name_en: 'Al Ghuwayla B',
    },
    {
      district_id: 11103417004,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي القابل',
      name_en: 'Al Qabil Dist.',
    },
    {
      district_id: 11103417005,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق رجلا',
      name_en: 'East Rajla Dist.',
    },
    {
      district_id: 11103417006,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشبهان',
      name_en: 'Al Shabhan Dist.',
    },
    {
      district_id: 11103417007,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي صهبان',
      name_en: 'Sahban Dist.',
    },
    {
      district_id: 11103417009,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شعب رير',
      name_en: 'Shiab Reer Dist.',
    },
    {
      district_id: 11103417010,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 11103417011,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المعامر',
      name_en: 'Al Maamir Dist.',
    },
    {
      district_id: 11103417012,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي زور الحارث',
      name_en: 'Zawr Al Harith Dist.',
    },
    {
      district_id: 11103417013,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي ريمان',
      name_en: 'Rayman Dist.',
    },
    {
      district_id: 11103417014,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 11103417015,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الامير مشعل',
      name_en: 'Prince Mishal Dist.',
    },
    {
      district_id: 11103417016,
      city_id: 3417,
      region_id: 11,
      name_ar: 'المطار',
      name_en: 'Airport',
    },
    {
      district_id: 11103417017,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الجربة',
      name_en: 'Al Jurbah Dist.',
    },
    {
      district_id: 11103417018,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الحصين',
      name_en: 'Al Husayn Dist.',
    },
    {
      district_id: 11103417019,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المركب',
      name_en: 'Al Markab Dist.',
    },
    {
      district_id: 11103417020,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي أبا السعود',
      name_en: 'Aba Al Saud Dist.',
    },
    {
      district_id: 11103417021,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي نهوقة',
      name_en: 'Nahuqah Dist.',
    },
    {
      district_id: 11103417022,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي صحيفات',
      name_en: 'Sahifat Dist.',
    },
    {
      district_id: 11103417023,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي بنى سلمان',
      name_en: 'Bani Salman Dist.',
    },
    {
      district_id: 11103417024,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي زور العماري',
      name_en: 'Zawr Al Ammari Dist.',
    },
    {
      district_id: 11103417025,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي تصلال',
      name_en: 'Thasallal Dist.',
    },
    {
      district_id: 11103417026,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي جنوب الجامعة',
      name_en: 'South Of University Dist.',
    },
    {
      district_id: 11103417027,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الخرعاء',
      name_en: 'Al Khuraa Dist.',
    },
    {
      district_id: 11103417028,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الصفا',
      name_en: 'As Safa Dist.',
    },
    {
      district_id: 11103417029,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي سقام',
      name_en: 'Suqam Dist.',
    },
    {
      district_id: 11103417030,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي أبا الخريـت',
      name_en: 'Aba Al Khareet Dist.',
    },
    {
      district_id: 11103417031,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي فواز',
      name_en: 'Fawaz Dist.',
    },
    {
      district_id: 11103417032,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الاثايبة',
      name_en: 'Al Athaybah Dist.',
    },
    {
      district_id: 11103417033,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي العريسة',
      name_en: 'Al Uraysah Dist.',
    },
    {
      district_id: 11103417034,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي صاغر',
      name_en: 'Saaghir Dist.',
    },
    {
      district_id: 11103417035,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المسماة',
      name_en: 'Al Masmat Dist.',
    },
    {
      district_id: 11103417036,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11103417037,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق المطار أ',
      name_en: 'East Airport A',
    },
    {
      district_id: 11103417038,
      city_id: 3417,
      region_id: 11,
      name_ar: 'جامعة نجران',
      name_en: 'Najran University',
    },
    {
      district_id: 11103417039,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الحضن',
      name_en: 'Al Hadn Dist.',
    },
    {
      district_id: 11103417040,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الموفجة',
      name_en: 'Al Mawfijah Dist.',
    },
    {
      district_id: 11103417041,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي غرب الدارة',
      name_en: 'Al Darah West Dist.',
    },
    {
      district_id: 11103417042,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي بئر أبين',
      name_en: 'Bir Abayn Dist.',
    },
    {
      district_id: 11103417043,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي برك',
      name_en: 'Barak Dist.',
    },
    {
      district_id: 11103417044,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الفهد',
      name_en: 'Al Fahd Dist.',
    },
    {
      district_id: 11103417045,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الحايرة',
      name_en: 'Al Hayirah Dist.',
    },
    {
      district_id: 11103417046,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الرويكبة',
      name_en: 'Al Ruwaikibah Dist.',
    },
    {
      district_id: 11103417047,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شعب بران',
      name_en: 'Shuab Bran Dist.',
    },
    {
      district_id: 11103417048,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي النهضة',
      name_en: 'Al Nahdah Dist.',
    },
    {
      district_id: 11103417049,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الغويلا د',
      name_en: 'Ghuwayla D Dist.',
    },
    {
      district_id: 11103417050,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المشعلية الشمالية',
      name_en: 'North Mishaliyah Dist.',
    },
    {
      district_id: 11103417051,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشعبة الغربية',
      name_en: 'West Shuabah Dist.',
    },
    {
      district_id: 11103417052,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي دحضة',
      name_en: 'Dahdah Dist.',
    },
    {
      district_id: 11103417053,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الغويلا أ',
      name_en: 'Ghuwayla A Dist.',
    },
    {
      district_id: 11103417054,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الابرقين',
      name_en: 'Al Abraqeen Dist.',
    },
    {
      district_id: 11103417055,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي ابا الرشاش',
      name_en: 'Aba Al Rashash Dist.',
    },
    {
      district_id: 11103417056,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي العريسة',
      name_en: 'Al Uraysah Dist.',
    },
    {
      district_id: 11103417057,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي سلوى',
      name_en: 'Salwa Dist.',
    },
    {
      district_id: 11103417058,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي زور وادعة',
      name_en: 'Zawr Wadiah Dist.',
    },
    {
      district_id: 11103417059,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي نهيقة',
      name_en: 'Nahiqah Dist.',
    },
    {
      district_id: 11103417060,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشرفة الغربية',
      name_en: 'West Shurfah Dist.',
    },
    {
      district_id: 11103417061,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الغويلا ج',
      name_en: 'Ghuwayla C Dist.',
    },
    {
      district_id: 11103417062,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الخضراء القديمة',
      name_en: 'Al Khadra Al Qadimah Dist.',
    },
    {
      district_id: 11103417063,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق المطار ج',
      name_en: 'East Airport C',
    },
    {
      district_id: 11103417064,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق المطار د',
      name_en: 'East Airport D',
    },
    {
      district_id: 11103417065,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق المطار ب',
      name_en: 'East Airport B',
    },
    {
      district_id: 11103417066,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي شرق الدارة',
      name_en: 'East Darah Dist.',
    },
    {
      district_id: 11103417067,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشعبة الشرقية',
      name_en: 'East Shuabah Dist.',
    },
    {
      district_id: 11103417068,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المشعلية الجنوبية',
      name_en: 'South Mishaliyah Dist.',
    },
    {
      district_id: 11103417069,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الـرمال',
      name_en: 'Al Rimal Dist.',
    },
    {
      district_id: 11103417070,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي رجلا الشماليـة',
      name_en: 'North Rajla Dist.',
    },
    {
      district_id: 11103417071,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الاخدود',
      name_en: 'Al Ukhdud Dist.',
    },
    {
      district_id: 11103417072,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي رجلا الجنوبية',
      name_en: 'South Rajla Dist.',
    },
    {
      district_id: 11103417073,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي الشرفة الشمالية',
      name_en: 'North Shurfah Dist.',
    },
    {
      district_id: 11103417074,
      city_id: 3417,
      region_id: 11,
      name_ar: 'حي المخيم',
      name_en: 'Al Mukheem Dist.',
    },
    {
      district_id: 11201531001,
      city_id: 1531,
      region_id: 12,
      name_ar: 'حي الشطيبة',
      name_en: 'Shateebah Dist.',
    },
    {
      district_id: 11201542001,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الشروق',
      name_en: 'Al Shorouk Dist.',
    },
    {
      district_id: 11201542002,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 11201542003,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي السلامة',
      name_en: 'As Salamah Dist.',
    },
    {
      district_id: 11201542004,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 11201542005,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الازهر',
      name_en: 'Al Azhar Dist.',
    },
    {
      district_id: 11201542006,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الضباب',
      name_en: 'Ad Dabab Dist.',
    },
    {
      district_id: 11201542007,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي شهبة',
      name_en: 'Shahbah Dist.',
    },
    {
      district_id: 11201542008,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11201542009,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11201542010,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 11201542011,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي البريدة',
      name_en: 'Al Buraydah Dist.',
    },
    {
      district_id: 11201542012,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي المرتفعات',
      name_en: 'Al Murtafat Dist.',
    },
    {
      district_id: 11201542013,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الروضة',
      name_en: 'Al Rawdha Dist.',
    },
    {
      district_id: 11201542014,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الباهر',
      name_en: 'Al Baher Dist.',
    },
    {
      district_id: 11201542015,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الحجاز',
      name_en: 'Al Hijaz Dist.',
    },
    {
      district_id: 11201542016,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي ج1',
      name_en: '1 C Dist.',
    },
    {
      district_id: 11201542017,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي ج2',
      name_en: '2 C Dist.',
    },
    {
      district_id: 11201542018,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي ج4',
      name_en: '4 C Dist.',
    },
    {
      district_id: 11201542019,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي ج5',
      name_en: '5 C Dist.',
    },
    {
      district_id: 11201542020,
      city_id: 1542,
      region_id: 12,
      name_ar: 'حي الطفير',
      name_en: 'At Tafeer Dist.',
    },
    {
      district_id: 11202819001,
      city_id: 2819,
      region_id: 12,
      name_ar: 'مخطط المطار',
      name_en: 'Al Matar Subdivision Plan',
    },
    {
      district_id: 11202819002,
      city_id: 2819,
      region_id: 12,
      name_ar: 'حي الغوث',
      name_en: 'Al Goth Dist.',
    },
    {
      district_id: 11302226001,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي حصيدة',
      name_en: 'Husaydah Dist.',
    },
    {
      district_id: 11302226003,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 11302226004,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11302226006,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 11302226012,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الشفا',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 11302226015,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 11302226016,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 11302226017,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الرحمة',
      name_en: 'Ar Rahmah Dist.',
    },
    {
      district_id: 11302226018,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302226019,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 11302226020,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah  Dist.',
    },
    {
      district_id: 11302226021,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي النرجس',
      name_en: 'An Narjis Dist.',
    },
    {
      district_id: 11302226022,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 11302226023,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي النفل',
      name_en: 'Al Nafl Dist.',
    },
    {
      district_id: 11302226024,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الريحان',
      name_en: 'Ar Raihan Dist.',
    },
    {
      district_id: 11302226025,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الدانة',
      name_en: 'Ad Danah Dist.',
    },
    {
      district_id: 11302226026,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 11302226027,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayan Dist.',
    },
    {
      district_id: 11302226028,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 11302226029,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الرسالة',
      name_en: 'Al Risalah Dist.',
    },
    {
      district_id: 11302226030,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 11302226031,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11302226032,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shuruq Dist.',
    },
    {
      district_id: 11302226033,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 11302226034,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 11302226035,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الرواد',
      name_en: 'Ar Ruwad Dist.',
    },
    {
      district_id: 11302226036,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 11302226037,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الفرسان',
      name_en: 'Al Fursan Dist.',
    },
    {
      district_id: 11302226038,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي البادية',
      name_en: 'Al Badiyah Dist.',
    },
    {
      district_id: 11302226039,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 11302226040,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الامير عبدالاله بن عبدالعزيز',
      name_en: 'Prince Abdulellah Bin Abdulaziz Dist.',
    },
    {
      district_id: 11302226041,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 11302226042,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 11302226043,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 11302226044,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 11302226045,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11302226046,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الامل',
      name_en: 'Al Amal Dist.',
    },
    {
      district_id: 11302226047,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الزيتون',
      name_en: 'Az Zaytun Dist.',
    },
    {
      district_id: 11302226048,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 11302226049,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الرفاع',
      name_en: 'Ar Rifaa Dist.',
    },
    {
      district_id: 11302226050,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 11302226051,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11302226052,
      city_id: 2226,
      region_id: 13,
      name_ar: 'حي الزمرد',
      name_en: 'Az Zumorud Dist.',
    },
    {
      district_id: 11302229001,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11302229002,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 11302229003,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 11302229004,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11302229005,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302229006,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah  Dist.',
    },
    {
      district_id: 11302229007,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 11302229008,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulimaniyah Dist.',
    },
    {
      district_id: 11302229009,
      city_id: 2229,
      region_id: 13,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 11302234001,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 11302234002,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الفرسان',
      name_en: 'Al Fursan Dist.',
    },
    {
      district_id: 11302234003,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11302234004,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11302234005,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 11302234006,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayaan Dist.',
    },
    {
      district_id: 11302234007,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 11302234008,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 11302234009,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 11302234010,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11302234011,
      city_id: 2234,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302237001,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302237002,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الشعلة',
      name_en: 'Ash Shulah Dist.',
    },
    {
      district_id: 11302237003,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الهدا',
      name_en: 'Al Hada Dist.',
    },
    {
      district_id: 11302237004,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 11302237005,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 11302237006,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي البديعة',
      name_en: 'Al Badiah Dist.',
    },
    {
      district_id: 11302237007,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 11302237008,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الزهور',
      name_en: 'Az Zuhur Dist.',
    },
    {
      district_id: 11302237009,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الرواد',
      name_en: 'Ruwad Dist.',
    },
    {
      district_id: 11302237010,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المنار',
      name_en: 'Al Manar Dist.',
    },
    {
      district_id: 11302237011,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي النور',
      name_en: 'An Nur Dist.',
    },
    {
      district_id: 11302237012,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 11302237013,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الزهراء',
      name_en: 'Az Zahra Dist.',
    },
    {
      district_id: 11302237014,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي السليمانية',
      name_en: 'As Sulaymaniyah Dist.',
    },
    {
      district_id: 11302237015,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الروضة',
      name_en: 'Ar Rawdah Dist.',
    },
    {
      district_id: 11302237016,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الندى',
      name_en: 'An Nada Dist.',
    },
    {
      district_id: 11302237017,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 11302237018,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 11302237019,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الزيتون',
      name_en: 'Az Zaytun Dist.',
    },
    {
      district_id: 11302237020,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 11302237021,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الازدهار',
      name_en: 'Al Izdihar Dist.',
    },
    {
      district_id: 11302237022,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 11302237023,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الصناعية',
      name_en: 'As Sinaiyah Dist.',
    },
    {
      district_id: 11302237024,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المجد',
      name_en: 'Al Majd Dist.',
    },
    {
      district_id: 11302237025,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الريان',
      name_en: 'Ar Rayaan Dist.',
    },
    {
      district_id: 11302237026,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11302237027,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي النهضة',
      name_en: 'An Nahdah Dist.',
    },
    {
      district_id: 11302237028,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي أحد',
      name_en: 'Ahad Dist.',
    },
    {
      district_id: 11302237029,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الفرسان',
      name_en: 'Al Fursan Dist.',
    },
    {
      district_id: 11302237030,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الرسالة',
      name_en: 'Al Risalah Dist.',
    },
    {
      district_id: 11302237031,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 11302237032,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 11302237033,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الشروق',
      name_en: 'Ash Shrouk Dist.',
    },
    {
      district_id: 11302237034,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 11302237035,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11302237036,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الفاروق',
      name_en: 'Al Farouk Dist.',
    },
    {
      district_id: 11302237037,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الياسمين',
      name_en: 'Al Yasmin Dist.',
    },
    {
      district_id: 11302237038,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الطوير',
      name_en: 'At Tuwair Dist.',
    },
    {
      district_id: 11302237039,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي سلطانة',
      name_en: 'Sultanah Dist.',
    },
    {
      district_id: 11302237040,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي السنابل',
      name_en: 'As Sanabel Dist.',
    },
    {
      district_id: 11302237041,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الضاحية',
      name_en: 'Ad Dahiah Dist.',
    },
    {
      district_id: 11302237042,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الشعيب',
      name_en: 'Ash Shuaib Dist.',
    },
    {
      district_id: 11302237043,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 11302237044,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11302237045,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المعاقلة',
      name_en: 'Al Muaqilah Dist.',
    },
    {
      district_id: 11302237046,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المطر',
      name_en: 'Al Matr Dist.',
    },
    {
      district_id: 11302237047,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الصديق',
      name_en: 'As Sediq Dist.',
    },
    {
      district_id: 11302237048,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 11302237049,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الخليج',
      name_en: 'Al Khalij Dist.',
    },
    {
      district_id: 11302237050,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الفتح',
      name_en: 'Al Fath Dist.',
    },
    {
      district_id: 11302237051,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 11302237052,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
    {
      district_id: 11302237053,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي السوق',
      name_en: 'As Suq Dist.',
    },
    {
      district_id: 11302237054,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الشلهوب',
      name_en: 'Ash Shalhub Dist.',
    },
    {
      district_id: 11302237055,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المحمدية',
      name_en: 'Al Muhammadiyah Dist.',
    },
    {
      district_id: 11302237056,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الزعفران',
      name_en: 'Al Zaafran Dist.',
    },
    {
      district_id: 11302237057,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي النفل',
      name_en: 'An Naql Dist.',
    },
    {
      district_id: 11302237058,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11302237059,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي اليرموك',
      name_en: 'Al Yarmuk Dist.',
    },
    {
      district_id: 11302237060,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الصفاء',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 11302237061,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الكوثر',
      name_en: 'Al Kawthar Dist.',
    },
    {
      district_id: 11302237062,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الضلع',
      name_en: 'Al Dil Dist.',
    },
    {
      district_id: 11302237063,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي بدر',
      name_en: 'Badr Dist.',
    },
    {
      district_id: 11302237064,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 11302237065,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الياقوت',
      name_en: 'Al Yaqoot Dist.',
    },
    {
      district_id: 11302237066,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي السنبلة',
      name_en: 'As Sunbulah Dist.',
    },
    {
      district_id: 11302237067,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي المرجان',
      name_en: 'Al Murjan Dist.',
    },
    {
      district_id: 11302237068,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الظلال',
      name_en: 'Adh Dhilal Dist.',
    },
    {
      district_id: 11302237069,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي اليمام',
      name_en: 'Al Yamam Dist.',
    },
    {
      district_id: 11302237070,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي اجياد',
      name_en: 'Ajyad Dist.',
    },
    {
      district_id: 11302237071,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الروابي',
      name_en: 'Ar Rawabi Dist.',
    },
    {
      district_id: 11302237072,
      city_id: 2237,
      region_id: 13,
      name_ar: 'حي الرابية',
      name_en: 'Ar Rabiyah Dist.',
    },
    {
      district_id: 11302240001,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الزيتون',
      name_en: 'Al Zaytun Dist.',
    },
    {
      district_id: 11302240002,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302240003,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 11302240004,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الرحمانية',
      name_en: 'Ar Rahmaniyah Dist.',
    },
    {
      district_id: 11302240005,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الخزامى',
      name_en: 'Al Khuzama Dist.',
    },
    {
      district_id: 11302240006,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي حدرج',
      name_en: 'Hadraj Dist.',
    },
    {
      district_id: 11302240007,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الربيع',
      name_en: 'Ar Rabie Dist.',
    },
    {
      district_id: 11302240008,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي النسيم',
      name_en: 'Al Naseem Dist.',
    },
    {
      district_id: 11302240009,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي البستان',
      name_en: 'Al Bustan Dist.',
    },
    {
      district_id: 11302240010,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الصفا',
      name_en: 'Al Safa Dist.',
    },
    {
      district_id: 11302240011,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الاندلس',
      name_en: 'Al Andalus Dist.',
    },
    {
      district_id: 11302240012,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 11302240013,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي النفل',
      name_en: 'Al Nafl Dist.',
    },
    {
      district_id: 11302240014,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي النخيل',
      name_en: 'Al Nakhil Dist.',
    },
    {
      district_id: 11302240015,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الحزم',
      name_en: 'Al Hazm Dist.',
    },
    {
      district_id: 11302240016,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الواحة',
      name_en: 'Al Wahah Dist.',
    },
    {
      district_id: 11302240017,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي السلام',
      name_en: 'Al Salam Dist.',
    },
    {
      district_id: 11302240018,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الشفاء',
      name_en: 'Al Shifa Dist.',
    },
    {
      district_id: 11302240019,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الورود',
      name_en: 'Al Wurud Dist.',
    },
    {
      district_id: 11302240020,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي المروة',
      name_en: 'Al Marwah Dist.',
    },
    {
      district_id: 11302240021,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي السمح',
      name_en: 'Al Samh Dist.',
    },
    {
      district_id: 11302240022,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي النزهة',
      name_en: 'Al Nuzhah Dist.',
    },
    {
      district_id: 11302240023,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الصالحية',
      name_en: 'As Salhiyah Dist.',
    },
    {
      district_id: 11302240024,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الروابي',
      name_en: 'Al Rawabi Dist.',
    },
    {
      district_id: 11302240025,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11302240026,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي العروبة',
      name_en: 'Al Urubah Dist.',
    },
    {
      district_id: 11302240027,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي المنتزة',
      name_en: 'Al Montazah Dist.',
    },
    {
      district_id: 11302240028,
      city_id: 2240,
      region_id: 13,
      name_ar: 'حي الرحاب',
      name_en: 'Al Rehab Dist.',
    },
    {
      district_id: 11302268001,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الغروب',
      name_en: 'Al Ghurub Dist.',
    },
    {
      district_id: 11302268002,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي التحكيم',
      name_en: 'At Tahkim Dist.',
    },
    {
      district_id: 11302268003,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي غرب دومة الجندل',
      name_en: 'West Dawmat Al Jandal Dist.',
    },
    {
      district_id: 11302268004,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الغربي',
      name_en: 'Al Gharbi Dist.',
    },
    {
      district_id: 11302268005,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الوادي',
      name_en: 'Al Wadi Dist.',
    },
    {
      district_id: 11302268006,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي البحيرات',
      name_en: 'Al Buhayrat Dist.',
    },
    {
      district_id: 11302268007,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الربوة',
      name_en: 'Ar Rabwah Dist.',
    },
    {
      district_id: 11302268008,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 11302268009,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي خذماء',
      name_en: 'Khazma Dist.',
    },
    {
      district_id: 11302268010,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي العزيزية',
      name_en: 'Al Aziziyah Dist.',
    },
    {
      district_id: 11302268011,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الفيصلية',
      name_en: 'Al Faisaliyah Dist.',
    },
    {
      district_id: 11302268012,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي سيل الدلهمية',
      name_en: 'Sayl Al Dalhimiyah Dist.',
    },
    {
      district_id: 11302268013,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الملك عبدالعزيز',
      name_en: 'King Abdulaziz Dist.',
    },
    {
      district_id: 11302268014,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي اليمامة',
      name_en: 'Al Yamamah Dist.',
    },
    {
      district_id: 11302268015,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الخالدية',
      name_en: 'Al Khalidiyah Dist.',
    },
    {
      district_id: 11302268016,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي المروج',
      name_en: 'Al Muruj Dist.',
    },
    {
      district_id: 11302268017,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الصفاه الاول',
      name_en: 'Al Safat 1 Dist.',
    },
    {
      district_id: 11302268018,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي أصفان',
      name_en: 'Asfan Dist.',
    },
    {
      district_id: 11302268021,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الرديفة والرافعية',
      name_en: 'Ar Radifah Wal Rafeiyah Dist.',
    },
    {
      district_id: 11302268022,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الدانة',
      name_en: 'Ad Danah Dist.',
    },
    {
      district_id: 11302268023,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي التلال',
      name_en: 'At Talal Dist.',
    },
    {
      district_id: 11302268024,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي البوداي',
      name_en: 'Al Bawadi Dist.',
    },
    {
      district_id: 11302268025,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الصناعي',
      name_en: 'Industrial Dist.',
    },
    {
      district_id: 11302268026,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي النزهة',
      name_en: 'An Nuzhah Dist.',
    },
    {
      district_id: 11302268027,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الشفاء',
      name_en: 'Ash Shifa Dist.',
    },
    {
      district_id: 11302268028,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الغدير',
      name_en: 'Al Ghadir Dist.',
    },
    {
      district_id: 11302268029,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الجوهرة',
      name_en: 'Al Jawharah Dist.',
    },
    {
      district_id: 11302268030,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي الصفاه الثاني',
      name_en: 'Al Safat 2 Dist.',
    },
    {
      district_id: 11302268031,
      city_id: 2268,
      region_id: 13,
      name_ar: 'حي المصيف',
      name_en: 'Al Masif Dist.',
    },
    {
      district_id: 11302270001,
      city_id: 2270,
      region_id: 13,
      name_ar: 'حي الملك فهد',
      name_en: 'King Fahd Dist.',
    },
    {
      district_id: 11302270002,
      city_id: 2270,
      region_id: 13,
      name_ar: 'حي السلام',
      name_en: 'As Salam Dist.',
    },
  ],
}

 