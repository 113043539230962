import React, { useState } from 'react'
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { colors } from './Styles'
import { useFetchCategoriesWithSubs } from './nav.hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useLocation, useNavigate } from 'react-router-dom'
const Navigators = () => {
  const [_, { language: lang }] = useTranslation()
  const { categoriesWithSubs } = useFetchCategoriesWithSubs()
  const [selectedItem, setSelectedItem] = useState()
  const { breakpoints } = useTheme()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleClose = () => {
    setSelectedItem()
  }
  const laptopScreen = useMediaQuery(breakpoints.down('xl'))
  const phoneScreen = useMediaQuery(breakpoints.down('lg'))
  const navigatorsWithSubSub = !laptopScreen
    ? categoriesWithSubs?.data.slice(0, 4)
    : phoneScreen
    ? categoriesWithSubs?.data.slice(0, 2)
    : categoriesWithSubs?.data
        .slice(0, 3)
        // .filter(
        //   (item) =>
        //     item.subCategories[0] &&
        //     item.subCategories.find((el) => el.subSubCategory[0])
        // )
        ?.slice(0, 4)
  return (
    <Box
      gap={{
        xl: '10px',
        lg: '5px',
        md: '3px',
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xl: '10px', lg: '5px', xs: '3px' },
        direction: lang === 'en' ? 'ltr' : 'rtl',
        mr: {
          lg: lang === 'ar' ? '40px' : undefined,
          md: lang === 'ar' ? '30px' : undefined,
          xs: lang === 'ar' ? '17px' : undefined,
        },
        ml: {
          lg: lang === 'en' ? '40px' : undefined,
          md: lang === 'en' ? '30px' : undefined,
          xs: lang === 'en' ? '17px' : undefined,
        },
      }}
    >
      {/* categoriesWithSubs.data */}
      {navigatorsWithSubSub.map((item) => (
        <Button
          onClick={() => {
            if (item.subCategories[0]) {
              setSelectedItem(item)
            }
            navigate(
              `/departments/${
                item.category._id
              }/${item.category.name_en.replace(/\s+/g, '-')}`
            )
          }}
          onMouseOver={() => {
            if (item.subCategories[0]) {
              setSelectedItem(item)
            } else {
              setSelectedItem()
            }
          }}
          sx={{
            color: pathname.includes(`/departments/${item.category._id}`)
              ? '#fff'
              : '#fff',
            position: 'relative !important',
            '&:before , &:after': {
              position: 'absolute',
              display: 'block',
              bottom: 0,
              content: `""`,
              height: '3px',
              width: 0,
              backgroundColor: '#A1875B',
            },
            '&:before': {
              left: '50%',
              direction: 'ltr',
              width: pathname.includes(`/departments/${item.category._id}`)
                ? '50%'
                : 0,
            },
            '&:after': {
              right: '50%',
              direction: 'rtl',
              width: pathname.includes(`/departments/${item.category._id}`)
                ? '50%'
                : 0,
            },

            '&:hover': {
              color: '#fff',
              '&:before , &:after': {
                width: '50%',
                transition: 'all 0.5s',
              },
            },
          }}
        >
          {item.category[`name_${lang}`]}
        </Button>
      ))}
      <Button
        onClick={() => {
          navigate(`/products/newest`)
        }}
        onMouseOver={() => {
          setSelectedItem()
        }}
        sx={{
          color: pathname.includes(`/products/newest`) ? '#fff' : '#fff',
          position: 'relative !important',
          '&:before , &:after': {
            position: 'absolute',
            display: 'block',
            bottom: 0,
            content: `""`,
            height: '3px',
            width: 0,
            backgroundColor: '#A1875B',
          },
          '&:before': {
            left: '50%',
            direction: 'ltr',
            width: 0,
            width: pathname.includes(`/products/newest`) ? '50%' : 0,
          },
          '&:after': {
            right: '50%',
            direction: 'rtl',
            width: 0,
            width: pathname.includes(`/products/newest`) ? '50%' : 0,
          },

          '&:hover': {
            color: '#fff',
            '&:before , &:after': {
              width: '50%',
              transition: 'all 0.5s',
            },
          },
        }}
      >
        {lang === 'en' ? 'Newest' : 'الأحدث'}
      </Button>
      <Button
        onClick={() => {
          navigate(`/products/sales`)
          localStorage.setItem('sortingBy', '-sales')
        }}
        onMouseOver={() => {
          setSelectedItem()
        }}
        sx={{
          color: pathname.includes(`/products/sales`) ? '#fff' : '#fff',
          position: 'relative !important',
          '&:before , &:after': {
            position: 'absolute',
            display: 'block',
            bottom: 0,
            content: `""`,
            height: '3px',
            width: 0,
            backgroundColor: '#A1875B',
          },
          '&:before': {
            left: '50%',
            direction: 'ltr',
            width: 0,
            width: pathname.includes(`/products/sales`) ? '50%' : 0,
          },
          '&:after': {
            right: '50%',
            direction: 'rtl',
            width: 0,
            width: pathname.includes(`/products/sales`) ? '50%' : 0,
          },

          '&:hover': {
            color: '#fff',
            '&:before , &:after': {
              width: '50%',
              transition: 'all 0.5s',
            },
          },
        }}
      >
        {lang === 'en' ? 'Most sellers' : 'الأكثر مبيعاً'}
      </Button>
      <Box
        sx={{
          width: '100% !important',
          top: '80px',
          left: lang === 'en' ? '0px !important' : undefined,
          right: lang === 'ar' ? '0px !important' : undefined,
          position: 'absolute',
          mt: '40px',
          bgcolor: `${colors.main}`,
          display: 'flex !important',
          alignItems: `flex-start`,
          justifyContent: 'flex-start',
          flexDirection: 'column',
          flexWrap: 'wrap',
          height: selectedItem ? '35vh' : 0,
          p: selectedItem ? '20px 0px' : 0,
          overflow: 'hidden',
          transition: 'height 0.5s',
          gap: '35px',
          zIndex: '10000 !important',
          direction: lang === 'en' ? 'ltr' : 'rtl',
        }}
        onMouseLeave={handleClose}
      >
        {selectedItem &&
          selectedItem.subCategories.map((el) => (
            <Stack>
              <MenuItem
                key={el.subCategory._id}
                sx={{
                  color: pathname.includes(el.subCategory._id)
                    ? '#fff'
                    : '#aaa',
                  width: '100px',
                  '&:hover': {
                    color: '#fff',
                  },
                }}
                onClick={() => {
                  navigate(
                    `/departments/${selectedItem.category._id}/${
                      el.subCategory._id
                    }/${el.subCategory.name_en.replace(/\s+/g, '-')}`
                  )
                  setSelectedItem()
                }}
              >
                {el.subCategory[`name_${lang}`]}
              </MenuItem>
              <Stack
                direction={'column'}
                justifyContent={'flex-start'}
                flexWrap={'wrap'}
                gap={'10px'}
                mt={'5px'}
                mr={lang === 'en' ? '0px' : '20px'}
                ml={lang === 'ar' ? '0px' : '20px'}
                sx={{
                  borderRight: lang === 'en' ? '0px' : '1px solid #fff',
                  borderLeft:
                    lang === 'en' ? '1px solid #fff' : '0px solid #fff',
                  padding: '0px 10px',
                }}
              >
                {el.subSubCategory.map((subSub) => (
                  <Typography
                    className="item_nav"
                    variant="body2"
                    onClick={() => {
                      navigate(
                        `/departments/${selectedItem.category._id}/${
                          el.subCategory._id
                        }/${subSub._id}/${subSub.name_en.replace(/\s+/g, '-')}`
                      )
                      setSelectedItem()
                    }}
                    sx={{
                      transitition: '1s all ease-in-out',
                      color: pathname.includes(subSub._id) ? '#fff' : '#868886',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#fff',
                      },
                      margin: '20px 0px',
                    }}
                  >
                    {subSub[`name_${lang}`]}
                  </Typography>
                ))}
                {el.subSubCategory?.length > 1 && (
                  <Typography
                    className="item_nav"
                    variant="body2"
                    onClick={() => {
                      navigate(
                        `/departments/${selectedItem.category._id}/${
                          el.subCategory._id
                        }/${el.subCategory.name_en.replace(/\s+/g, '-')}`
                      )
                      setSelectedItem()
                    }}
                    sx={{
                      transitition: '1s all ease-in-out',
                      color: pathname.includes(el.subCategory)
                        ? '#fff'
                        : '#868886',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#fff',
                      },
                      margin: '20px 0px',
                    }}
                  >
                    {lang === 'en'
                      ? 'Al Sub sub categories'
                      : 'جميع الفئات الفرعية الفرعية'}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ))}
      </Box>
    </Box>
  )
}

export default Navigators
