export const SavedProductsStyles = ({ lng }) => ({
  Box: {
    // mt: 2,
    mb: 5,
    minHeight: '70vh',
    mx: { xs: 1, sm: 3, lg: 10, md: 10 },
    direction: lng === 'en' ? 'ltr' : 'rtl',
    gap: 0,
  },
  Stack: {
    flexDirection: { lg: 'row', xs: 'column' },
    justifyContent: { xs: 'flex-start', md: 'space-around' },
    alignItems: 'center',
  },
  justifyContent: {
    alignItems: 'center',
    width: '100%',
  },

  StackDirection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
})

export const AllProductsGridStyles = {
  ContainerSpacing: {
    xs: 2,
    md: 3,
    lg: 4,
    xl: 5,
  },
  GridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
}
