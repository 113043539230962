import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper/modules'
import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Stack,
  Chip,
  Link,
  Typography,
  ButtonGroup,
  useMediaQuery,
  Dialog,
} from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useAddRatingMutation,
  useGetSingleProductQuery,
} from '../../../../redux/apis/ProductApis.js'
import './Modal.css'
import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from '../../../../constants/baseUrl.js'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../../../../redux/apis/cartApi'
import { toast } from 'react-toastify'
import Popover from '@mui/material/Popover'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
// import Banner from '../../HomePage/Banners/Banner'
import { Nooncolors } from '.././colors.jsx'
import Breadcrumbs from '../../../../components/BreadCrumbs/BreadCrumbs.jsx'
import { NavLink } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import '../NewCard/styles.css'

import { useTheme } from '@emotion/react'
import { FavoriteIconCard } from '.././NewCard/loveicon.jsx'
import { ProductModalAction } from '../../../../redux/slices/productModal/productModalSlice.jsx'
import AnimatedLoader from '../../../../components/animatedLoader/AnimatedLoader.jsx'
import Qualites from '../Qualities.jsx'
function calculateDiscountPercentage(priceBefore, priceAfter) {
  // Ensure both inputs are valid numbers
  if (typeof priceBefore !== 'number' || typeof priceAfter !== 'number') {
    return 'Invalid input. Please provide valid numbers.'
  }

  // Ensure that priceBefore is not zero to avoid division by zero
  if (priceBefore === 0) {
    return false
  }

  // Calculate the discount percentage
  const discount = priceBefore - priceAfter
  const discountPercentage = (discount / priceBefore) * 100

  // Return the result
  return discountPercentage
}
const BreadcrumbColors = {
  bgcolor: 'red',
  primary: 'white',
  secondary: 'white',
}
// ================================customBreadCumbs=============================

// ================================customBreadCumbs=============================
function MouseOverPopover({
  children,
  text,
  cartData,
  setCartData,
  key,
  attr,
  values,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setCartData((prev) => {
      const newQualities = prev?.qualities?.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      return {
        ...prev,
        qualities: [
          ...newQualities,
          {
            key_en: attr.key_en,
            key_ar: attr.key_ar,
            value_en: values.value_en,
            value_ar: values.value_ar,
            price: values?.price,
          },
        ],
      }
    })
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setCartData((prev) => ({
      ...prev,
      qualities: cartData?.qualitiesBefore,
    }))
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disablePortal
        disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}> {text}</Typography>
      </Popover>
    </div>
  )
}
//   ========================================Attr===============================

const Attrs = ({ colors, attr, setCartData, cartData }) => {
  const { attrAciveColors } = colors
  const [, { language: lng }] = useTranslation()
  const handleUpdateQualities = ({ attr, values }) => {
    setCartData((prev) => {
      const newQualities = prev.qualities.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      // qualitiesBefore
      const qualitiesBeforeHover = [
        ...newQualities,
        {
          key_en: attr.key_en,
          key_ar: attr.key_ar,
          value_en: values.value_en,
          value_ar: values.value_ar,
          price: values?.price,
        },
      ]

      return {
        ...prev,
        qualities: qualitiesBeforeHover,
        qualitiesBefore: qualitiesBeforeHover,
      }
    })
  }
  const isSelectedAtt = (val) =>
    val.value_en ===
    cartData?.qualities?.findLast((v) => v.key_en === attr.key_en)?.value_en

  return (
    <Box key={attr._id} dir="ltr" sx={{}}>
      <Typography
        sx={{
          color: colors.attrKeyColor,
          fontWeight: 'bold',
          textAlign: lng === 'en' ? 'left' : 'right',
          marginBottom: '15px',
        }}
      >
        {attr[`key_${lng === 'en' ? 'en' : 'ar'}`]}
      </Typography>
      <ButtonGroup
      // disabled={
      //   attr.key_en ===
      //   cartData.qualities.find((v) => v.key_en === attr.key_en)?.key_en
      // }
      >
        {attr?.values?.map((val) =>
          !val[`value_${lng === 'en' ? 'en' : 'ar'}`]?.startsWith('#') ? (
            <Button
              sx={{
                color: isSelectedAtt(val)
                  ? attrAciveColors.ActiveColor
                  : colors.attrValueColor,
                bgcolor: isSelectedAtt(val)
                  ? attrAciveColors.background
                  : `${colors.attrValueBgColor} !important`,
                margin: '0px 10px',
                border: '1px solid  !important',
                borderColor: `#5c5c5c !important`,
                borderRadius: '7px !important',
                padding: '3px 10px',
                fontSize: '11px !important',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              }}
              key={val.value_en}
              onClick={() =>
                handleUpdateQualities({
                  attr,
                  values: {
                    value_en: val.value_en,
                    value_ar: val.value_ar,
                    price: val?.price,
                  },
                })
              }
            >
              {val[`value_${lng === 'en' ? 'en' : 'ar'}`]}
            </Button>
          ) : (
            <Box
              sx={{
                border: '1px solid #fff',
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px',
                padding: '3px',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                background: `${val[`value_${lng === 'en' ? 'en' : 'ar'}`]}`,
                cursor: 'pointer',
                position: 'relative',
                '&::after': {
                  position: isSelectedAtt(val) ? 'absolute' : 'none',
                  height: '1px',
                  content: isSelectedAtt(val) ? "''" : 'unset',
                  width: '48px',
                  background: '#333',
                  transform: 'rotate(127deg)',
                  top: '4px',
                  left: '-6px',
                },
              }}
              key={val.value_en}
              onClick={() =>
                handleUpdateQualities({
                  attr,
                  values: {
                    value_en: val.value_en,
                    value_ar: val.value_ar,
                    price: val?.price,
                  },
                })
              }
            ></Box>
          )
        )}
      </ButtonGroup>
    </Box>
  )
}

//
// ========================================copyButton===========================================
const CopyButton = ({ colors }) => {
  const [, { language: lng }] = useTranslation()
  const [copied, setCopied] = useState(false)
  const product_url = window.location.href
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
      })
      .catch((error) => {
        setCopied(false)
        console.error('Error copying text to clipboard:', error)
      })
  }
  return (
    <Tooltip
      title={
        lng === 'en'
          ? copied
            ? 'Product link has been copied'
            : 'Product Link'
          : copied
          ? 'تم نسخ الرابط'
          : ' نسخ رابط المنتج'
      }
      sx={{
        color: colors.buttonColor,
        bgcolor: colors.buttonBgColor,
        '&:hover': { bgcolor: `${colors.buttonBgColor} !important` },
      }}
    >
      <IconButton onClick={() => handleCopy(product_url)}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )
}
// ========================================copyButton===========================================

// =========================================custom Payment Type =====================================
const MAP_TYPE = {
  cash: {
    ar: 'عند الاستلام',
    en: 'cash',
  },
  online: {
    ar: 'الدفع اونلاين',
    en: 'online',
  },
}
// =========================================custom Payment Type =====================================

function CustomPaymentType({ type, lang }) {
  return (
    <Stack direction={'row'} gap={2}>
      <Typography variant="h6">
        {lang === 'ar' ? MAP_TYPE[type].ar : MAP_TYPE[type].en}
      </Typography>

      <Typography variant="h6">
        {' :'} {lang === 'en' ? 'Payment Type' : 'الدفع'}
      </Typography>
    </Stack>
  )
}
// =========================================custom Payment Type =====================================

export default function ProductModal({ setOpen, open, item = {} }) {
  const [categoryId, Setcategoryid] = useState('')
  // const { data, isLoading, isError } = useGetSingleProductQuery(id)
  const [expanded, setExpanded] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  // qualities
  const [price, setPrice] = useState(0)

  const [quantity, setQuantity] = useState(0)
  const [check, setCheck] = useState(false)
  const [img, setImage] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [QuantityImages, setQuantityImages] = useState([])
  const [product, setProduct] = useState({ images: [] })
  const dispatch = useDispatch()
  const [selectedImage, setselectedImage] = useState('')
  const navigate = useNavigate()
  const [ModalItem, setModalItem] = useState({})

  const { currencyPrice, label } = useSelector((state) => state.currency)
  const theme = useTheme()
  const phoneScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const [cartIn, SetCartIn] = useState(false)
  const colors = Nooncolors
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { id } = useParams()
  const [, { language: lng }] = useTranslation()
  const {
    data: cartItems,
    error,
    isError: IsCartInErorr,
    isSuccess,
  } = useGetAllCartsQuery(undefined)
  const [cartData, setCartData] = useState({
    quantity: 1,
    qualities: item?.qualities || [],
    id: item?._id,
  })

  const [addToCart, { isLoading: cardLoad }] = useAddToCartMutation()
  const [rate] = useAddRatingMutation()
  const [DeleteCart, { isLoading: DeleteFromCartLoading }] =
    useDeleteFromCartMutation()
  const ProductModalActions = ProductModalAction
  const handleClose = () => {
    dispatch(ProductModalActions.closeModal())
    setselectedImage('')
    setCartData({
      quantity: 1,
      qualities: [],
      id: '',
    })
    setCheck(false)
  }

  useEffect(() => {
    if (Object.keys(item).length) {
      setModalItem(item)
    }
    window.addEventListener('popstate', handleClose) // Add event listener

    return () => window.removeEventListener('popstate', handleClose) // Remove on cleanup
  }, [item, open])

  // ===========================================quentityUpdate===================================================\\
  const updateQty = (method) => {
    method === '+'
      ? setCartData({ ...cartData, quantity: cartData.quantity + 1 })
      : cartData.quantity > 1 &&
        setCartData({ ...cartData, quantity: cartData.quantity - 1 })
  }
  // ===========================================quentityUpdate===================================================\\

  useEffect(() => {
    if (isSuccess) {
      const cards = [
        ...cartItems?.data?.cashItems?.items,
        ...cartItems?.data?.onlineItems?.items,
      ]
      const InCart = cards?.some((item) => item.product._id === ModalItem?._id)

      SetCartIn(InCart)
    }
  }, [ModalItem?._id, cartItems, location.pathname, cartData])

  const HandleAddToCart = (qu) => {
    if (product?.paymentType === 'both') {
      navigate(`/products/${item?._id}/${item?.title_en.replace(/\s/g, '-')}`)
      dispatch(ProductModalActions.closeModal())
      return
    }

    if (product?.qualities?.length) {
      if (check) {
        if (cartData.quantity <= quantity) {
          console.log(selectedItems, 'selectedItems')
          addToCart({
            quantity: cartData.quantity,
            id: product?._id,
            qualities: selectedItems?.map((item) => {
              const { child, ...itemS } = item
              const { color, ...iteme } = itemS
              return {
                ...iteme,
              }
            }),
            paymentType:
              product?.paymentType === 'both'
                ? paymentMethod
                : product?.paymentType,
          })
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
              setSelectedItems([])
              setCheck(false)
              setPrice(0)
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
        } else {
          toast.error(
            lng === 'en' ? 'Quantity is not avalible' : ' الكميه غير متوفره'
          )
        }
      } else {
        toast.error(
          lng === 'en'
            ? 'please Select qualities First'
            : 'من فضلك اختر المعيار اولا '
        )
      }
    } else {
      addToCart({
        quantity: cartData.quantity,
        id: product?._id,
        paymentType:
          product?.paymentType === 'both'
            ? paymentMethod
            : product?.paymentType,
      })
        .unwrap()
        .then((res) =>
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        )
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }

  const AddToCartFunc = (cartData) => {
    const qualitiesAfterDeletePrice = cartData?.qualities?.map((item) => {
       const {price,...other}=item;
       console.log(other,'others')
      return {...other}
    })
    HandleAddToCart(qualitiesAfterDeletePrice)
  }

  
  

  useEffect(() => {
    setProduct(item)
    setSelectedItems([])
    setQuantityImages(item?.images)
  }, [item])
  console.log(
    item,
    QuantityImages,
    'dassdasdasdasdasdasdasdasdasdasdasdasdasdasda'
  )
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={true}
      // fullScreen={true}
      sx={{
        '.css-1chwz6k-MuiPaper-root-MuiDialog-paper': {
          background: 'none',
          // backdropFilter: 'blur(10px)',
          boxShadow: 'none',
        },
      }}
    >
      <>
        <Button
          sx={{
            position: 'fixed',
            right: '28px',
            top: '7px',
            background: '#FFF',
            color: ' #333',
            display: 'flex',
            'justify-content': 'space-around',
            fontSize: '20px',
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Button>
        {Object.keys(ModalItem).length === 0 ? <AnimatedLoader /> : null}
        {open && Object.keys(ModalItem).length ? (
          <Grid
            container
            sx={{
              p: {
                xs: 1,
                sm: 2,
                md: 3,
              },
              width: '100% !important',
              direction: lng === 'en' ? 'rtl' : 'ltr',
              flexDirection: 'row-reverse',
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            {/* <CustomBreadcrumb
            data={{
              title_ar: data.data.title_ar,
              title_en: data.data.title_en,
            }}
            lng={lng}
          /> */}

            <Grid
              item
              xs={12}
              md={6}
              lg={lng === 'en' ? 4 : 3.5}
              sx={{
                textAlign: 'end',
                marginLeft: 'auto',
                position: 'sticky',
                top: '10px',
                height: 'fit-content',
                padding: '20px',
                background: { xs: '#fff', md: 'none' },
              }}
            >
              <Stack gap={2} alignItems={'end'}>
                <Typography
                  sx={{
                    color: colors.titleColor,
                    fontSize: { xs: '0.9rem', sm: '1.4rem' },
                    fontWeight: 'bold',
                  }}
                >
                  {ModalItem[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                </Typography>
                {/* <Rating
                  value={ModalItem?.rating}
                  sx={{ direction: 'ltr' }}
                  onChange={(_, newValue) => {
                    // rate({ productId: data.data._id, rating: newValue })
                    handleRating(item?._id, newValue)
                  }}
                /> */}
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  {ModalItem?.priceAfterDiscount &&
                  ModalItem?.priceAfterDiscount !==
                    ModalItem?.priceBeforeDiscount ? (
                    <Typography component={'p'}>
                      {calculateDiscountPercentage(
                        ModalItem?.priceBeforeDiscount,
                        ModalItem?.priceAfterDiscount
                      )}{' '}
                      %
                    </Typography>
                  ) : null}

                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: colors.priceBeforeColor,
                        textDecoration:
                          ModalItem.priceAfterDiscount > 0 &&
                          ModalItem?.priceAfterDiscount !==
                            ModalItem?.priceBeforeDiscount
                            ? 'line-through'
                            : 'none',
                        fontSize:
                          ModalItem.priceAfterDiscount > 0 &&
                          ModalItem?.priceAfterDiscount !==
                            ModalItem?.priceBeforeDiscount
                            ? '0.7rem'
                            : 'initial',
                      }}
                    >
                      {(
                        (ModalItem.priceBeforeDiscount + price) *
                        currencyPrice
                      ).toFixed(2)}{' '}
                      {currencySymbol}
                    </Typography>
                    {ModalItem.priceAfterDiscount > 0 &&
                      ModalItem?.priceAfterDiscount !==
                        ModalItem?.priceBeforeDiscount && (
                        <Typography sx={{ color: '#000' }}>
                          {(
                            (ModalItem.priceAfterDiscount + price) *
                            currencyPrice
                          ).toFixed(2)}{' '}
                          {currencySymbol}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
                {/* {ModalItem?.qualities?.map((quality) => (
                  <Attrs
                    key={quality._id}
                    colors={{
                      attrKeyColor: colors.attrKeyColor,
                      attrValueColor: colors.attrValueColor,
                      attrValueBgColor: colors.attrValueBgColor,
                      attrValueBorderColor: colors.attrValueBorderColor,
                      attrAciveColors: colors.attrAciveColors,
                    }}
                    attr={quality}
                    setCartData={setCartData}
                    cartData={cartData}
                  />
                ))} */}
                {/* {console.log(data?.productQuality, product, 'sss')} */}
                {product?.qualities?.length > 0 &&
                product?.qualities != undefined ? (
                  <Qualites
                    language={lng === 'ar'}
                    setQuantity={setQuantity}
                    check={check}
                    setCheck={setCheck}
                    item={product?.productQuality}
                    setImage={setImage}
                    setImages={setQuantityImages}
                    quantityImages={QuantityImages}
                    defaultImages={product?.images}
                    setPrice={setPrice}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    isModal={true}
                  />
                ) : null}
                

                <Stack
                  direction={{ xs: 'column', md: 'column' }}
                  sx={{
                    gap: 5,
                    justifyContent: 'start',
                    width: '100%',

                    borderTop: {
                      xs: '1px solid',
                      md: 'unset',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '13px', sm: '13px', md: '25px' },
                      fontWeight: 'bold',
                      marginTop: '10px',
                    }}
                    component={'p'}
                  >
                    {lng === 'en' ? 'Quantity' : 'الكميه'}
                  </Typography>
                  <Stack
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row-reverse',
                      alignItems: 'center',
                    }}
                  >
                    <Stack
                      direction={'row'}
                      sx={{
                        border: '1px solid black',
                        borderRadius: 1,
                        alignItems: 'center',
                        my: 1,
                        width: 'fit-content',
                        py: 0.2,
                        width: '40%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '6px',
                        padding: { xs: '3px', sm: '6px' },
                        color: '#000',
                        fontWeight: 'bold',
                        marginLeft: lng === 'en' ? 'unset' : 'auto',
                        fontSize: '20px',
                        height: { xs: '30px', sm: '35px', md: '27px ' },
                        width: { xs: '60%', sm: '65%', md: '35%' },
                        color: ` #333 !important`,
                        bgcolor: `#fff !important`,
                        border: '2px solid #5c5c5c',
                      }}
                    >
                      <Button
                        size="large"
                        onClick={() => updateQty('+')}
                        sx={{
                          color: 'black !important',
                          minWidth: 50,
                          fontSize: '20px !important',
                          fontWeight: '400 ',
                        }}
                      >
                        +
                      </Button>

                      <Typography
                        sx={{
                          margin: 'auto',
                        }}
                      >
                        {cartData.quantity}
                      </Typography>
                      <Button
                        size="large"
                        onClick={() => updateQty('-')}
                        disabled={cartData.quantity === 1}
                        sx={{
                          color: '#000 !important',
                          minWidth: 50,
                          fontSize: '20px !important',
                        }}
                      >
                        -
                      </Button>
                    </Stack>

                    <Stack>
                      <FavoriteIconCard lng={lng} data={ModalItem} />
                    </Stack>
                  </Stack>

                  <Stack direction={'row-reverse'} sx={{ gap: 0 }}>
                    {ModalItem?.quantity > 0 ? (
                      <Button
                        sx={{
                          marginLeft: 'auto',
                          fontWeight: '600',

                          height: '39px',
                          py: 1,
                          color: ` #333 !important`,
                          bgcolor: `#fff !important`,
                          border: '2px solid #5c5c5c',
                          fontSize: { xs: '11px', md: '12 px', xl: '14px' },
                          width: '100%',
                          padding: '13px 40px',
                          'border-radius': '0px',
                        }}
                        className={`btn btn-fill-middle`}
                        disabled={cardLoad || DeleteFromCartLoading}
                        onClick={() => AddToCartFunc(cartData)}
                      >
                        {cardLoad || DeleteFromCartLoading ? (
                          <>
                            <Typography>
                              {lng === 'en' ? 'loading' : 'جاري التحميل'}
                            </Typography>
                            <CircularProgress
                              size={20}
                              style={{ marginRight: '4px' }}
                              color="inherit"
                            />
                          </>
                        ) : (
                          <>
                            {/* {cartIn && !IsCartInErorr ? (
                              <>
                                {lng === 'en'
                                  ? 'Delete from Cart'
                                  : 'حذف من السله'}
                              </>
                            ) : (
                              <>
                                {lng === 'en'
                                  ? 'Add to cart'
                                  : 'أضف إلى سلة التسوق'}
                              </>
                            )} */}
                            {lng === 'en'
                              ? 'Add to cart'
                              : 'أضف إلى سلة التسوق'}
                          </>
                        )}
                      </Button>
                    ) : (
                      <p>
                        {lng==="en"?"out of stock":"المنتج غير متوفر"}
                      </p>
                    )}
                  </Stack>

                  <Typography
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClose()
                      navigate(
                        `/products/${item?._id}/${item?.title_en.replace(
                          /\s/g,
                          '-'
                        )}`
                      )
                    }}
                  >
                    {lng === 'en' ? 'Product Details' : 'عرض تفاصيل المنتج'}
                  </Typography>
                </Stack>

                {/* <CustomPaymentType type={data.data.paymentType} lang={lng} /> */}
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={8}
              sx={{ order: { xs: -1, md: 'unset' } }}
            >
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row-reverse',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    m: 1,
                    // minHeight: { xs: '100%', lg: '100vh' },
                    direction: 'ltr',
                    '--swiper-navigation-color': '#fff',
                    objectPosition: 'top',
                    height: '100%',
                  }}
                  id="i"
                >
                  {}

                  {selectedImage !== '' ? (
                    <Box
                      component={'img'}
                      sx={{
                        //  background: `url("${
                        //    imageBaseUrl + ModalItem?.images[0]
                        //  }") center center`,
                        //  backgroundSize: ' contain',
                        //  aspectRatio: 0.6,
                        //  backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                      }}
                      src={imageBaseUrl + selectedImage}
                    />
                  ) : (
                    <Box
                      component={'img'}
                      sx={{
                        // background: `url("${
                        //   imageBaseUrl + ModalItem?.images[0]
                        // }") center center`,
                        // backgroundSize: ' contain',
                        // aspectRatio: 0.6,
                        // backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                      }}
                      src={imageBaseUrl + ModalItem?.images[0]}
                    />
                  )}
                </Box>
                <Box
                  component={Swiper}
                  direction={phoneScreen ? 'horizontal' : 'vertical'}
                  onSwiper={setThumbsSwiper}
                  spaceBetween={5}
                  slidesPerView={'auto'}
                  sx={{
                    width: { xs: '100%', lg: '200px' },
                    m: 1,
                    height: { xs: '100px', lg: '80vh' },
                    direction: 'ltr',
                    '--swiper-navigation-color': '#000',
                    maxHeight: 'auto',
                    position: 'sticky',
                    '.swiper-slide': {
                      width: 'auto !important',
                      height: 'auto !important',
                    },
                    top: '0px',
                  }}
                  mousewheel={true}
                  modules={[Mousewheel]}
                  className="mySwiper"
                >
                  {QuantityImages?.length > 0
                    ? QuantityImages?.map((img) => (
                        <SwiperSlide
                          key={img}
                          style={{
                            width: 'auto !important',
                            height: 'auto !important',
                          }}
                        >
                          <Box
                            component={'img'}
                            sx={{
                              width: { xs: '100px', sm: '100px', md: '100%' },
                              height: {
                                xs: 'auto',
                                md: '200px',
                              },
                              aspectRatio: 0.6,
                              objectFit: {
                                xs: 'contain',
                                md: 'contain',
                              },
                            }}
                            onClick={() => setselectedImage(img)}
                            src={imageBaseUrl + img}
                          />
                        </SwiperSlide>
                      ))
                    : ModalItem?.images.map((img) => (
                        <SwiperSlide key={img}>
                          <Box
                            component={'img'}
                            sx={{
                              width: { xs: '100px', sm: '100px', md: '100%' },
                              height: {
                                xs: '100px',
                                md: '200px',
                              },
                              cursor: 'grab',

                              objectFit: 'contain',
                            }}
                            onClick={() => setselectedImage(img)}
                            src={imageBaseUrl + img}
                          />
                        </SwiperSlide>
                      ))}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        ) : null}
      </>
    </Dialog>
  )
}
