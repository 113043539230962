import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import {
  useCompleteWebhookMutation,
  useTamaraWebhookPaymentMutation,
} from '../../redux/apis/webhookApi'
import { ThanksStyle } from './ThanksStyle'
import AnimatedLoader from '../../components/animatedLoader/AnimatedLoader'

const ThanksPageTamara = () => {
  const param = new URLSearchParams(useLocation().search)
  const { type } = useParams()
   
   
  const [_, { language }] = useTranslation()
  const [tamaraWebhookPayment, { isLoading }] =
    useTamaraWebhookPaymentMutation()
  const [FinalOrder, { data, isError, isSucces }] = useCompleteWebhookMutation()
  const [success, setSuccess] = useState({
    successApis: true,
    id: '',
  })
  useEffect(() => {
    if (type === 'success' && param.get('paymentStatus') === 'approved') {
      tamaraWebhookPayment(param.get('orderId'))
        .then((res) => {
          FinalOrder(res?.data?.order?._id)
            .then((order) => {
               
              setSuccess((prev) => {
                return {
                  ...prev,
                  successApis: true,
                  id: res?.data?.order?._id,
                }
              })
            })
            .catch((err) => {
               

              setSuccess((prev) => {
                return {
                  ...prev,
                  successApis: false,
                  id: res?.data?.order?._id,
                }
              })
            })
           
          localStorage.removeItem('couponData')
        })
        .catch((err) => {
           
        })
    } else if (type === 'cancel') {
       
    } else if (type === 'fail') {
       
    }
  }, [param.get('payment_id'), type])

  return (
    <Stack
      height={'100vh'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <Box>
          {type === 'success' ? (
            <>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 'bold',
                  color: ThanksStyle.color?.titleColor || '#d55252',
                  textAlign: 'center',
                  fontSize: {
                    xs: '50px',
                    sm: '60px',
                    md: '70px',
                    lg: '80px',
                    xl: '100px',
                  },
                  my: 2,
                }}
              >
                {language === 'en' ? 'THANKS' : 'شكراً'}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: ThanksStyle.color?.titleColor || '#d55252',
                  textAlign: 'center',
                  fontSize: {
                    xs: '40px',
                    sm: '50px',
                    md: '60px',
                    lg: '70px',
                    xl: '80px',
                  },
                }}
              >
                {language === 'en' ? 'FOR THE ORDER' : 'علي الطلب'}
              </Typography>
            </>
          ) : (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                color: ThanksStyle.color?.titleColor || '#d55252',
                textAlign: 'center',
                fontSize: {
                  xs: '40px',
                  sm: '50px',
                  md: '60px',
                  lg: '70px',
                  xl: '80px',
                },
              }}
            >
              {language === 'en'
                ? 'SORRY YOUR PAYMENT FAILED'
                : 'عذراً فشلت عملية الدفع'}
            </Typography>
          )}

          <Stack justifyContent="center" gap="20px" mt="40px">
            <Button
              onClick={() => window.location.replace('/')}
              sx={{
                bgcolor:
                  `${ThanksStyle.color?.Btn?.bgColor}!important` ||
                  'black !important',
                color: ThanksStyle.color?.Btn?.textColor || '#fff',
                textTransform: 'capitalize',
                padding: '12px 20px',
                fontSize: '20px',
                borderRadius: '5px',
              }}
            >
              {language === 'en' ? 'Go To Home Page' : 'إذهب الي الرئيسية'}
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  )
  // return (
  //   <div>
  //     <h1>hello faker</h1>
  //   </div>
  // )
}

export default ThanksPageTamara
