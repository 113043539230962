import { appColors } from '../../../constants/colors'
const { primary, secondary, whiteColor } = appColors
export const colors = {
  filter: {
    buttonBg: secondary.main,
    buttonColor: whiteColor.main,
    checkBoxLabel: primary.secondary,
    borderColor: primary.main,
  },
}
export const CategoriesStyle = (lng) => ({
  Breadcrumbs: {
    bgcolor: 'transparent',
    primary: secondary.dark,
    secondary: primary.light,
  },
  Box: {
    my: 5,
    mx: { xs: 1, sm: 1, md: 7, lg: 15, xl: 36 },
    minHeight: '70vh',
    direction: lng === 'en' ? 'ltr' : 'rtl',
    gap: 0,
  },
  Stack: {
    flexDirection: { lg: 'row', xs: 'column' },
    justifyContent: { xs: 'flex-start', md: 'space-around' },
    alignItems: 'center',
  },

  Stack: {
    flexDirection: { lg: 'row', xs: 'column' },

    justifyContent: { xs: 'flex-start', md: 'space-around' },
    alignItems: 'center',
  },
  justifyContent: {
    alignItems: 'center',
    width: '100%',
  },
  StackDirection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  Typography: {
    mt: 5,
    cursor: 'pointer',
    width: '100%',
    zIndex: 1,
     py: 2,
    mb: 5,
    fontWeight: '600',
    fontSize: { xs: '2rem', lg: '2.3rem' },
    textTransform: 'capitalize',
  },
})
export const AllProductsGridStyle = {
  GridSpacing: {
    // xs: 0,
    // md: 0,
    // lg: 0,
    // xl: 0,
    // xs: 2,
    // md: 3,
    // lg: 4,
    // xl: 5,
  },
}
export const SearchStyle = () => ({
  Box: {
    width: '100%',
    borderRadius: '0.5rem',
    display: 'flex',
    border: `1px solid #333`,
    alignItems: 'center',
    padding: '0.5rem',
    justifyContent: 'space-between',
  },
  InputBox: {
    width: '100%',
    height: '100%',
    border: `none`,
    outline: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    zIndex: 2,
  },
})
export const filterMenuStyles = {
  menuButton: {
    fontSize: {
      md: '20px',
      xs: '15px',
    },
    fontWeight: 'bold',
    mx: '10px',
    color: `${colors.filter.buttonColor} !important`,
  },
  priceBtn: {
    // fontFamily: publicFontFamily,
    // fontSize: '19px',
    // fontWeight: 'bold',
    cursor: 'pointer',
    color: colors.filter.checkBoxLabel,
    textAlign: 'center',
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // gap: '10px',
  },
  checkBoxLabel: {
    color: colors.filter.checkBoxLabel,
    fontFamily: 'bold',
    fontSize: '19px',
    cursor: 'pointer',
    // border:
  },
  formPriceBtn: {
    mt: '10px',
    // fontWeight: 'bold',
    fontSize: '13px',
    // border: `1px solid ${colors.borderColor}`,
    bgcolor: `${colors.filter.buttonBg} !important`,
    color: `${colors.filter.buttonColor} !important`,
  },
}
