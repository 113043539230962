import { Box, CardMedia, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetAllCategoriesQuery } from '../../../../redux/apis/categoriesApi'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import { useGetFooterPrivaciesQuery } from '../../../../redux/apis/sectionsApi'
import TikTok from '../../../../assets2/brand-tiktok-svgrepo-com.svg'
import Insta from '../../../../assets2/instagram-svgrepo-com.svg'
import Snap from '../../../../assets2/snapchat-svgrepo-com.svg'
import XTwitter from '../../../../assets2/icons8-twitterx-50.png'
import { useGetAllSocialQuery } from '../../../../redux/apis/socialApi'
import { useEffect, useState } from 'react'
import PhoneIcon from '@mui/icons-material/Phone'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { init } from 'i18next'
import linkedin from '../../../../assets/svg/linkedin-svgrepo-com.svg'
import facebook from '../../../../assets/svg/facebook-svgrepo-com.svg'
import Phone from '../../../../assets/svg/phone-svgrepo-com.svg'
import mail from '../../../../assets/svg/email-1573-svgrepo-com.svg'
import whatsapp from '../../../../assets/svg/whatsapp-svgrepo-com.svg'
import paymentImg1 from '../../../../assets2/footer_payment_1.png'
import paymentImg2 from '../../../../assets2/footer_payment_2.png'
import paymentImg3 from '../../../../assets2/footer_payment_3.png'
import paymentImg4 from '../../../../assets2/footer_payment_4.png'
import paymentImg5 from '../../../../assets2/footer_payment_5.png'
import paymentImg6 from '../../../../assets2/footer_payment_6.png'
import Card from '../../../../pages/singleProductPages/noon1/NewCard'
import AnimatedLoader from '../../../animatedLoader/AnimatedLoader'
// =========================== staticLinks=============================
const links = [
  // {
  //   ar: 'الصفحة الرئسية',
  //   en: 'Home page',
  //   path: '/',
  // },
  {
    ar: 'عننا',
    en: 'About Us',
    path: '/aboutUs',
  },
  // {
  //   ar: 'جميع المنتجات',
  //   en: 'All products',
  //   path: '/departments',
  // },
  // {
  //   ar: 'جميع الأقسام الفرعية الفرعية',
  //   en: 'All sub sub categories',
  //   path: '/subSubCategories',
  // },
  {
    ar: 'تواصل معنا',
    en: 'Contact Us',
    path: '/contactUs',
  },
  {
    ar: 'المدونات',
    en: 'Blogs',
    path: '/blogs',
  },
]
// =========================== staticLinks=============================
function Map({ url, ...props }) {
  return (
    <div>
      <iframe
        title="map"
        src={url}
        width="100%"
        height="225"
        loading="lazy"
        {...props}
      ></iframe>
    </div>
  )
}

const FooterOptions = ({ language: lang }) => ({
  footerText:
    lang === 'en'
      ? '© 2024 Rights Reserved - DRESS CODE .'
      : '©2024 الحقوق محفوظة - DRESS CODE ',
  information: {
    address:
      'Jarir Marketing Company, King Khaled Street, Al Murabba District, Riyadh, KSA',
    email: 'jarir@gmail.com',
    phone: '+966500458116',
  },
  social: {
    color: '#fff',
    bgColor: '#5B0888',
    facebook: 'https://www.facebook.com',
    instagram: 'https://www.instagram.com',
    linkedin: 'https://www.linkedin.com',
  },
  map: {
    url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1854.8072173905196!2d39.1349908!3d21.6009683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db4ed164d9a9%3A0xb6a4ba7216165791!2sSarri%20technology!5e0!3m2!1sen!2seg!4v1695652130557!5m2!1sen!2seg',
  },
  bgColor: { primary: '#5B0888 ', secondary: '#bf9fea' },
  color: { primary: '#000', secondary: '#5B0888' },
  variant: 'form',
})

function CustomersService({ categories, color }) {
  const customerServiceData = [
    {
      key: 'mail',
      value: '',
      icon: mail,
    },
    {
      key: 'whatsapp',
      value: '',
      icon: whatsapp,
    },
    {
      key: 'mobile',
      value: '',
      icon: Phone,
    },
  ]
  const {
    i18n: { language },
  } = useTranslation()
  const { data, isLoading } = useGetAllSocialQuery()
  const [customerService, setCustomerService] = useState([])
  useEffect(() => {
    const filteredData = data?.data[0]?.socialMedia?.filter(
      (item) =>
        item.key === 'mail' || item.key === 'whatsapp' || item.key === 'mobile'
    )
    setCustomerService(
      filteredData?.map((item) => {
        return {
          key: item.key,
          value: item.value,
          icon: customerServiceData.find((i) => i.key === item.key)?.icon,
        }
      })
    )
  }, [data])

  return (
    <>
      <Stack
        width={{
          xl: 0.25,
          lg: 0.33,
          md: 0.5,
          xs: 1,
        }}
        direction={'row'}
        // alignItems={'center'}
        justifyContent={{
          md: 'center',
          xs: 'flex-start',
        }}
        mt={{
          md: 0,
          xs: '25px',
        }}
      >
        {customerService?.length > 0 ? (
          <Box>
            <div style={{}}>
              <Typography
                sx={{
                  color: color.primary,
                  fontSize: 24,
                  textAlign: { xs: 'inital' },
                  marginBottom: '25px',
                }}
              >
                {language === 'en' ? 'Customers Service' : 'خدمة العملاء'}
              </Typography>
            </div>
            <Typography
              sx={{
                color: '#000',
                fontSize: 18,
                marginBottom: '10px',
                textAlign: {
                  // md: 'center',
                  xs: 'initial',
                },
              }}
            >
              {language === 'en' ? 'Call us' : 'اتصل بنا'}
            </Typography>
            {customerService?.map((item) => (
              <Stack
                onClick={() => {
                  if (item.key === 'mail') {
                    window.open(`mailto:${item.value}`)
                    return
                  }
                  if (item.key === 'whatsapp') {
                    window.open(`https://wa.me/${item.value}`)
                    return
                  }
                }}
                direction="row"
                gap={2}
                width={'100%'}
                mb={'10px'}
                sx={{
                  justifyContent: { xs: 'flex-start' },
                  flexDirection: 'row',
                  gap: '5px',
                  cursor: 'pointer',
                }}
              >
                <CardMedia
                  src={item.icon}
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                    cursor: 'pointer',
                  }}
                />

                <Typography
                  sx={{
                    color: '#000',
                    textDecoration: 'none',
                  }}
                >
                  {item.key === 'mobile'
                    ? item.value
                    : item.key === 'whatsapp'
                    ? language === 'en'
                      ? 'Whatsapp'
                      : 'واتساب'
                    : language === 'en'
                    ? 'Email'
                    : 'البريد الإلكتروني'}
                </Typography>
              </Stack>
            ))}
          </Box>
        ) : null}
      </Stack>
    </>
  )
}

function ContentSection({ color, map, variant, children }) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={'row'}
      sx={{
        alignItems: 'center',
        justifyContent: {
          md: 'center',
          xs: 'flex-start',
        },
      }}
      width={{
        xl: 0.25,
        lg: 0.33,
        md: 0.45,
        xs: 1,
      }}
    >
      <Box>
        <div>
          <Typography
            sx={{
              color: color.primary,
              textAlign: { xs: 'start', md: 'start' },
              fontSize: 23,
            }}
          >
            {language === 'en' ? 'Information' : 'معلومات'}
          </Typography>
        </div>
        <Box gap={5} justifyContent={'center'}>
          {links.map((link) => (
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: {
                  // md: 'center',
                  xs: 'flex-start',
                },
              }}
            >
              <Box
                key={link.path}
                sx={{
                  color: color.primary,
                  textDecoration: 'none !important',
                  mb: '0 !important',
                  textAlign: 'center',
                  // fontWeight: '0 !important',
                }}
                component={Link}
                to={link.path}
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                {language === 'en' ? link.en : link.ar}
              </Box>
            </Stack>
          ))}
          <Policies />
          {children}
        </Box>

        {(variant === 'map' || variant === 'form-map') && map && (
          <Box sx={{ pt: 5 }}>
            <Map url={map.url} />
          </Box>
        )}
      </Box>
    </Stack>
  )
}
function SocialSection({ information, color, variant }) {
  const intialDat = [
    {
      key: 'mobile',
      value: '',
      icon: Phone,
    },
    {
      key: 'mail',
      value: '',
      icon: mail,
    },
    {
      key: 'whatsapp',
      value: '',
      icon: whatsapp,
    },
    {
      key: 'facebook',
      value: '',
      icon: facebook,
    },
    {
      key: 'instagram',
      value: '',
      icon: Insta,
    },
    {
      key: 'linkedin',
      value: '',
      icon: linkedin,
    },
    {
      key: 'snapchat',
      value: '',
      icon: Snap,
    },
    {
      key: 'twitter',
      value: '',
      icon: XTwitter,
    },
    { key: 'tiktok', value: '', icon: TikTok },
  ]
  const [socialMediaLinks, setSocialMediaLinks] = useState([])
  const { data, isLoading } = useGetAllSocialQuery()
  useEffect(() => {
    const filtredData = data?.data[0]?.socialMedia?.filter(
      (item) =>
        item.key !== 'mail' && item.key !== 'whatsapp' && item.key !== 'mobile'
    )
    setSocialMediaLinks(
      filtredData?.map((item) => {
        return {
          key: item.key,
          value: item.value,
          icon: intialDat.find((i) => i.key === item.key)?.icon,
        }
      })
    )
  }, [data])
  const {
    i18n: { language },
  } = useTranslation()
  if (isLoading) return <AnimatedLoader />

  return (
    <Stack
      direction={'row'}
      gap={2}
      sx={{
        justifyContent: {
          md: 'center',
          xs: 'flex-start',
        },
        // alignItems: 'center',
        textAlign: { xs: 'initial', md: 'start' },
        width: {
          xl: 0.25,
          lg: 0.33,
          md: 0.45,
          xs: 1,
        },
      }}
    >
      {socialMediaLinks?.length > 0 ? (
        <Box>
          <Box width="100%">
            <Typography
              sx={{
                color: color.primary,
                textAlign: { xs: 'start', md: 'start' },
                fontSize: 20,
                mt: { xs: '10px', md: 'initial' },
              }}
            >
              {language === 'en' ? 'Follow us' : 'تابعونا'}
            </Typography>
          </Box>
          <Stack
            direction={'row'}
            alignItems={'center'}
            mt={{ xs: 3, md: 5 }}
            gap={'20px'}
          >
            {socialMediaLinks?.map((item) => (
              <CardMedia
                src={item.icon}
                onClick={() => {
                  if (item.key === 'mobile') {
                    window.open(`tel:${item.value}`)
                    return
                  }
                  let url = item.value
                  if (!/^https?:\/\//i.test(item.value)) {
                    // If not, prepend with "http://"
                    url = 'https://' + url
                  }

                  window.open(url)
                }}
                component="img"
                sx={{
                  height: 20,
                  width: 20,
                  cursor: 'pointer',
                }}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Stack>
  )
}

function Policies() {
  const { data } = useGetFooterPrivaciesQuery()
  const navigate = useNavigate()
  const [, { language: lang }] = useTranslation()
  return (
    <>
      {data?.data.map((sec) => (
        <Typography
          key={sec?.type}
          onClick={() => navigate(`/policies/${sec?.type}`)}
          sx={{
            color: '#000 !important',
            cursor: 'pointer',

            textAlign: {
              xs: 'initial',
            },
          }}
        >
          {sec[`title_${lang}`]}
        </Typography>
      ))}
    </>
  )
}
function PaymentPhotos({ color }) {
  const [_, { language }] = useTranslation()
  return (
    <Box
      width={{
        xl: 0.25,
        lg: 1,
        md: 0.45,
        xs: 1,
      }}
      sx={{}}
      mt={{
        xl: 0,
        lg: '20px',
        md: 0,
        xs: '30px',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: {
            xl: 'flex-start',
            md: 'center',
            xs: 'flex-start',
          },
          gap: '5px',
        }}
      >
        {[paymentImg2, paymentImg3, paymentImg4, paymentImg5,paymentImg6].map((image) => (
          <CardMedia
            component="img"
            src={image}
            sx={{
              height: 35,
              width: 70,
              objectFit: 'contain',
            }}
          />
        ))}
      </Stack>
    </Box>
  )
}
function JarirTextEarea({ children }) {
  const {
    i18n: { language },
  } = useTranslation()

  const { bgColor, color, variant, map, information, footerText } =
    FooterOptions({ language })

  const { data, isLoading, isSuccess } = useGetAllCategoriesQuery()
  const { pathname } = useLocation()
  const categories = data?.data || []
  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        display:
          pathname === '/thankYou' ||
          pathname === '/sign-in' ||
          pathname === '/register' ||
          pathname === '/forgetPassword' ||
          pathname === '/notfound'
            ? 'none'
            : 'flex',
      }}
      dir={dir}
    >
      <Box
        bgcolor={'#E0E0E0'}
        width={'100%'}
        height={'100%'}
        pt={{
          md: 4,
          xs: '30px',
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            flexWrap: { xs: 'wrap', xl: 'nowrap' },
            width: 0.9,
            height: '100%',
            mx: 'auto',
          }}
        >
          <ContentSection
            color={color}
            map={map}
            variant={variant}
            children={children}
          />

          {isLoading && <AnimatedLoader />}
          {isSuccess && (
            <CustomersService color={color} categories={categories} />
          )}
          <SocialSection
            variant={variant}
            color={color}
            information={information}
          />
          <PaymentPhotos color={color} />
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: '#E0E0E0',
          width: '100%',
          minHeight: 60,
          display: 'grid',
          placeItems: 'flex-end',
        }}
      >
        <Typography
          sx={{
            color: '#000',
            fontSize: '12px',
            px: '30px',
            py: '50px',
          }}
        >
          {footerText}
        </Typography>
      </Box>
    </Stack>
  )
}

export default JarirTextEarea
