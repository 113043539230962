import { Box, CardMedia, Grid, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetMostNewiestProductsQuery } from '../../../redux/apis/ProductApis'
import { imageBaseUrl } from '../../../constants/baseUrl'
import { useNavigate } from 'react-router-dom'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const ProductCarousel = ({ isLoading, products }) => {
  const navigate = useNavigate()
  const [imgLoading, setImgLoading] = useState(true)
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component={Swiper}
        slidesPerView={1.5}
        spaceBetween={10}
        breakpoints={{
          600: { slidesPerView: 2.5, spaceBetween: 10 },
          900: { slidesPerView: 3, spaceBetween: 10 },
          1200: { slidesPerView: 4, spaceBetween: 15 },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        sx={{
          width: { xs: '100%', md: '100%' },
          position: 'static',
          mx: 'auto',
        }}
      >
        {products.map((item) => (
          <SwiperSlide key={item.id}>
            <Box>
              <Box
                className="product-item"
                onClick={() => {
                  navigate(
                    `/products/${item?._id}/${item?.title_en.replace(
                      /\s/g,
                      '-'
                    )}`
                  )
                }}
                sx={{
                  width: '100%',
                  position: 'relative',
                  aspectRatio: 0.6,
                  minHeight: { xs: '500px',md : "100%"},
                  my: { xs: 1, md: 0 },
                  cursor: 'pointer',
                  '&:hover': {
                    '& img': {
                      opacity: 0,
                    },
                    '& img:nth-child(1)': {
                      opacity: 1,
                      filter: 'brightness(0.6)',
                    },
                  },
                }}
              >
                {imgLoading && (
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'100%'}
                  />
                )}
                <CardMedia
                  component="img"
                  loading  = "lazy"
                  onLoad={() => setImgLoading(false)}
                  image={`${imageBaseUrl}${item?.images[0]}`}
                  alt={`${item?.title_en}`}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '90%',
                    transition: 'all 0.5s ease',
                    objectFit: 'contain',
                  }}
                />
                <CardMedia
                  component="img"
                  loading  = "lazy"
                  onLoad={() => setImgLoading(false)}
                  image={`${imageBaseUrl}${item?.images[1]}`}
                  alt={`${item?.title_en}`}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '90%',
                    transition: 'all 0.5s ease',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Box>
    </Box>
  )
}

const CollectionHover = () => {
  const navigate = useNavigate()
  const [_, { language: lang }] = useTranslation()
  const [collection, setCollection] = useState([])
  const [getMostNewiestProducts, { error, isLoading }] =
    useLazyGetMostNewiestProductsQuery()
  useEffect(() => {
    getMostNewiestProducts()
      .unwrap()
      .then((res) => {
        setCollection(res?.data?.slice(0, 4))
      })
      .catch((err) => {})
  }, [])

  return (
    <Box
      sx={{
        py: 2,
        my: 3,
      }}
    >
      {!isLoading && !error ? (
        <>
          <Box textAlign={'center'} my={3}>
            <Typography
               sx={{
                fontSize: { xs: '2rem', sm: '3rem', md: '2rem' },
      
                textAlign: 'center',
                mt: 2,
                mb: 2,
                'font-size': '2rem',
                'font-weight': 'bold',
               ' text-transform': 'uppercase',
              }}
            >
              {lang === 'en' ? 'Shop by look' : 'تسوق  المظهر'}
            </Typography>
          </Box>
          <ProductCarousel isLoading={isLoading} products={collection} />
        </>
      ) : (
        <Box textAlign={'center'}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '18px',
            }}
          >
            {/* {error?.data[`error_${lang}`]} */}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default CollectionHover
