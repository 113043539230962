import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'

export default function Rating({ setRating, totalRating, disable }) {
  const [hover, setHover] = useState(null)
  function colorFunc(currentRating) {
    if (totalRating === 0) {
      return '#e4e5e9'
    }
    // if (disable) {
    //   return '#ffc107'
    // }
    return currentRating <= ( totalRating) ? '#ffc107' : '#e4e5e9'
  }
  return (
    <div className="App">
      {[...Array(5)].map((star, index) => {
        const currentRating = index + 1
        return (
          <label key={index} style={{}}>
            <input
              key={star}
              type="radio"
              name="rating"
              value={totalRating}
              onChange={() => setRating(currentRating)}
              disabled={disable}
              sx={{
                cursor: disable ? 'default' : 'hover',
              }}
            />
            <span
              className="star"
              style={{
                color: colorFunc(currentRating),
                cursor: disable ? 'default' : 'hover',
              }}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            >
              &#9733;
            </span>
          </label>
        )
      })}
    </div>
  )
}
