import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs, Mousewheel } from 'swiper/modules'
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Chip,
  Link,
  Typography,
  ButtonGroup,
  useMediaQuery,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useParams } from 'react-router-dom'
import {
  useAddRatingMutation,
  useGetSingleProductQuery,
} from '../../../redux/apis/ProductApis.js'
import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from '../../../constants/baseUrl.js'
import 'react-medium-image-zoom/dist/styles.css'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../../../redux/apis/cartApi'
import { toast } from 'react-toastify'
import Popover from '@mui/material/Popover'

import { useSelector } from 'react-redux'
// import Banner from '../../HomePage/Banners/Banner'
import { Nooncolors } from './colors.jsx'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs.jsx'
import { NavLink } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Similarproduct from '../similarproduct/index.jsx'
import ProductComments from '../../../components/productComments/ProductComments.jsx'
import MostSelling from './MostSelling/MostSelling/index.jsx'
import { useTheme } from '@emotion/react'
import { FavoriteIconCard } from './NewCard/loveicon.jsx'
import { CustomZoomContent } from './CustomZoom.jsx'
import Zoom from 'react-medium-image-zoom'
import Qualites from './Qualities.jsx'
import ProductReviews from '../../../components/ReviewComponent/index.jsx'
import Rating from '../../../components/ReviewComponent/Rating.jsx'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader.jsx'

// import Banner from '../../../components/Banners/Banner.jsx'
// import { useGetSingleProductQuery } from '../src/APIs/ProductApis'
function calculateDiscountPercentage(priceBefore, priceAfter) {
  // Ensure both inputs are valid numbers
  if (typeof priceBefore !== 'number' || typeof priceAfter !== 'number') {
    return 'Invalid input. Please provide valid numbers.'
  }

  // Ensure that priceBefore is not zero to avoid division by zero
  if (priceBefore === 0) {
    return false
  }

  // Calculate the discount percentage
  const discount = priceBefore - priceAfter
  const discountPercentage = (discount / priceBefore) * 100

  // Return the result
  return discountPercentage
}
const BreadcrumbColors = {
  bgcolor: 'red',
  primary: 'white',
  secondary: 'white',
}
// ================================customBreadCumbs=============================
function CustomBreadcrumb({ data, lng }) {
  console.log(data)
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'end',
        pb: 2,
      }}
    >
      <Breadcrumbs
        colors={BreadcrumbColors}
        dir={lng === 'en' ? 'ltr' : 'rtl'}
        aria-label="breadcrumb"
      >
        <Link component={NavLink} underline="hover" color="inherit" to="/">
          {lng === 'en' ? 'Home' : 'الرئيسية'}
        </Link>
        {data}

        <Typography color="text.primary">
          {lng === 'en' ? data.title_en : data.title_ar}
        </Typography>
      </Breadcrumbs>
    </Grid>
  )
}
// ================================customBreadCumbs=============================
function MouseOverPopover({
  children,
  text,
  cartData,
  setCartData,
  key,
  attr,
  values,
}) {
  console.log(cartData)
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setCartData((prev) => {
      const newQualities = prev?.qualities?.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      return {
        ...prev,
        qualities: [
          ...newQualities,
          {
            key_en: attr.key_en,
            key_ar: attr.key_ar,
            value_en: values.value_en,
            value_ar: values.value_ar,
            price: values?.price,
          },
        ],
      }
    })

    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setCartData((prev) => ({
      ...prev,
      qualities: cartData.qualitiesBefore,
    }))
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disablePortal
        disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}> {text}</Typography>
      </Popover>
    </div>
  )
}

// ========================================copyButton===========================================
const CopyButton = ({ colors }) => {
  const [, { language: lng }] = useTranslation()
  const [copied, setCopied] = useState(false)
  const product_url = window.location.href
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
      })
      .catch((error) => {
        setCopied(false)
        console.error('Error copying text to clipboard:', error)
      })
  }
  return (
    <Tooltip
      title={
        lng === 'en'
          ? copied
            ? 'Product link has been copied'
            : 'Product Link'
          : copied
          ? 'تم نسخ الرابط'
          : ' نسخ رابط المنتج'
      }
      sx={{
        color: colors.buttonColor,
        bgcolor: colors.buttonBgColor,
        '&:hover': { bgcolor: `${colors.buttonBgColor} !important` },
        padding: '10px',
        height: '44px',
        width: '60px',
        margin: 'auto',
        borderRadius: '0px',
      }}
    >
      <IconButton onClick={() => handleCopy(product_url)}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )
}
// ========================================copyButton===========================================

// =========================================custom Payment Type =====================================
const MAP_TYPE = {
  cash: {
    ar: 'عند الاستلام',
    en: 'cash',
  },
  online: {
    ar: 'الدفع اونلاين',
    en: 'online',
  },
  both: {
    ar: 'الدفع عند الاستلام او اونلاين',
    en: 'online or cash',
  },
}
// =========================================custom Payment Type =====================================

function CustomPaymentType({ type, lang }) {
  return (
    <Stack direction={'row'} gap={2}>
      <Typography variant="h6">
        {lang === 'ar' ? MAP_TYPE[type]?.ar : MAP_TYPE[type]?.en}
      </Typography>

      <Typography variant="h6">
        {' :'} {lang === 'en' ? 'Payment Type' : 'الدفع'}
      </Typography>
    </Stack>
  )
}
// =
export const NoonSingleProduct = () => {
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const theme = useTheme()
  const phoneScreen = useMediaQuery(theme.breakpoints.down('md'))
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const [categoryId, Setcategoryid] = useState('')
  const [cartIn, SetCartIn] = useState(false)
  const colors = Nooncolors
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { id } = useParams()
  console.log(id, 'idididid')
  const { data, isLoading, isError } = useGetSingleProductQuery(id)
  console.log(isError, data, id, 'isError')
  const [, { language: lng }] = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState('')
  // qualities
  const [price, setPrice] = useState(0)

  const [quantity, setQuantity] = useState(0)
  const [check, setCheck] = useState(false)
  const [img, setImage] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [QuantityImages, setQuantityImages] = useState([])
  const [product, setProduct] = useState({ images: [] })

  // qualities
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const {
    data: cartItems,
    error,
    isError: IsCartInErorr,
    isSuccess,
  } = useGetAllCartsQuery(undefined)
  const [cartData, setCartData] = useState({
    quantity: 1,
    qualities: [],
    id: id,
  })
  const [addToCart, { isLoading: cardLoad }] = useAddToCartMutation()
  const [rate] = useAddRatingMutation()
  const [DeleteCart, { isLoading: DeleteFromCartLoading }] =
    useDeleteFromCartMutation()
  console.log(cartData.quantity, quantity, 'checkcheck')

  const HandleAddToCart = (qu) => {
    if (data?.data?.paymentType === 'both')
      if (paymentMethod === '') {
        toast?.error(
          lng === 'en'
            ? 'please Select a payment method First '
            : 'من فضلك اختر طريقه الدفع اولا'
        )
        return
      }
    console.log(cartData.quantity, quantity, 'quantityasddsdas')
    if (data?.qualities?.length) {
      if (check) {
        if (cartData.quantity <= quantity) {
          console.log(selectedItems, 'selectedItems')
          addToCart({
            quantity: cartData.quantity,
            id,
            qualities: selectedItems?.map((item) => {
              const { child, ...itemS } = item
              const { color, ...iteme } = itemS
              return {
                ...iteme,
              }
            }),
            paymentType:
              data?.data?.paymentType === 'both'
                ? paymentMethod
                : data?.data?.paymentType,
          })
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
              setSelectedItems([])
              setCheck(false)
              setPrice(0)
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
        } else {
          toast.error(
            lng === 'en' ? 'Quantity is not avalible' : ' الكميه غير متوفره'
          )
        }
      } else {
        toast.error(
          lng === 'en'
            ? 'please Select qualities First'
            : 'من فضلك اختر المعيار اولا '
        )
      }
    } else {
      addToCart({
        quantity: cartData.quantity,
        id,
        paymentType:
          data?.data?.paymentType === 'both'
            ? paymentMethod
            : data?.data?.paymentType,
      })
        .unwrap()
        .then((res) =>
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        )
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }

  const AddToCartFunc = (cartData) => {
    const qualitiesAfterDeletePrice = cartData?.qualities?.map((item) => {
      delete item.price
      return item
    })
    HandleAddToCart(qualitiesAfterDeletePrice)
  }

  const handleDelete = (key) => {
    const qualitiesAfterDelete = cartData.qualities.filter(
      (quality) => quality.key_en !== key
    )
    setCartData({
      ...cartData,
      qualities: qualitiesAfterDelete,
      qualitiesBefore: qualitiesAfterDelete,
    })
  }
  // ===========================================quentityUpdate===================================================\\
  const updateQty = (method) => {
    method === '+'
      ? setCartData({ ...cartData, quantity: cartData.quantity + 1 })
      : cartData.quantity > 1 &&
        setCartData({ ...cartData, quantity: cartData.quantity - 1 })
  }
  // ===========================================quentityUpdate===================================================\\

  const { currentUser } = useSelector((state) => state)

  const handleRating = (productId, rating) => {
    if (!currentUser) {
      toast.error(lng === 'en' ? 'Login first' : 'سجل دخول أولاً')
    } else {
      rate({ productId, rating })
        .unwrap()
        .then((res) =>
          toast.success(lng === 'en' ? res.success_en : res.success_ar)
        )
        .catch((e) =>
          toast.error(lng === 'en' ? e.data.error_en : e.data.error_ar)
        )
    }
  }

  useEffect(() => {
    !isError && !isLoading && Setcategoryid(data?.data?.category?.id)
    setProduct(data?.data)
    setSelectedItems([])

    setQuantityImages(data?.data?.images)
  }, [id, data])
  console.log(quantity, cartData?.quantity, 'quantitydsadsa')

  useEffect(() => {
    console.log(selectedItems,'')

    const initializeTamaraWidget = () => {
      if (window.TamaraProductWidget) {
        window.TamaraProductWidget.init({ lang: lng })
        // window.TamaraProductWidget.init({price: 200})
        window.TamaraProductWidget.render()
      }
    }

    // Waiting for 2s - Make sure Tamara's widget is installed
    const timerId = setTimeout(initializeTamaraWidget, 2000)
console.log('firstelsan')
    return () => clearTimeout(timerId) // Clear timeout on component unmount
  }, [lng,check,id])
  const checkDisableFunc = () => {
    if (cardLoad || data?.data?.quantity < cartData?.quantity) {
      return true
    } else if (data?.data?.qualities.length && quantity < cartData?.quantity) {
      return true
    }

    return false
  }
  return (
    <>
      {isLoading && !data && !isError && <AnimatedLoader />}
      {!isLoading && data && (
        <Grid
          container
          sx={{
            p: {
              xs: 1,
              sm: 2,
              md: 5,
            },
            direction: lng === 'en' ? 'rtl' : 'ltr',
            flexDirection: 'row-reverse',
            margin: 'auto',
            justifyContent: 'center',
            marginTop: { xs: '80px', md: '80px' },
          }}
        >
          {/* <CustomBreadcrumb
            data={{
              title_ar: data.data.title_ar,
              title_en: data.data.title_en,
            }}
            lng={lng}
          /> */}

          <Grid
            item
            xs={12}
            md={11}
            lg={lng === 'en' ? 4 : 3.5}
            sx={{
              textAlign: 'end',
              px: { sm: 0 },
              margin: '10px',
              position: {
                xs: 'block',
                lg: 'sticky',
                xl: 'sticky',
              },
              top: {
                xs: 'unset',
                xl: '0px',
              },
              height: 'fit-content',
              padding: '20px  ',
            }}
          >
            <Stack gap={2} alignItems={'end'}>
              <Typography
                sx={{
                  color: colors.titleColor,
                  fontSize: { xs: '0.9rem', sm: '1.4rem' },
                  fontWeight: 'bold',
                }}
              >
                {data.data[`title_${lng === 'en' ? 'en' : 'ar'}`]}
              </Typography>
              <Rating totalRating={data?.data?.rating} disable={true} />

              {/* <Rating
                precision={1}
                value={data.data.rating}
                sx={{
                  direction: lng === 'en' ? 'ltr !important' : 'rtl !important',
                  textAlign: 'right',
                  flexDirection: lng === 'en' ? 'row' : 'row',
                  '> *': {
                    direction:
                      lng === 'en' ? 'ltr !important' : 'rtl !important',
                  },
                }}
                onChange={(_, newValue) => {
                  // rate({ productId: data.data._id, rating: newValue })
                  handleRating(data.data._id, newValue)
                }}
              /> */}
              {/* <Typography
                sx={{
                  display: 'flex',
                  flexDirection: lng === 'ar' ? 'row-reverse' : 'row-reverse',
                  alignItems: 'flex-end',
                }}
              >
                {lng === 'en' ? 'shipping Price   ' : '  :سعر الشحن '}

                <span
                  style={{
                    margin: '0px 10px',
                  }}
                >
                  {data?.data?.shippingPrice ? (
                    <>
                      {`${currencySymbol}`}

                      <span>
                        {(data?.data?.shippingPrice * currencyPrice).toFixed(2)}
                      </span>
                    </>
                  ) : lng === 'en' ? (
                    'Free'
                  ) : (
                    'مجانا'
                  )}
                </span>
              </Typography> */}

              {/* <Typography
                sx={{
                  display: 'flex',
                  flexDirection: lng === 'ar' ? 'row-reverse' : 'row-reverse',
                  alignItems: 'flex-end',
                }}
              >
                {lng === 'en'
                  ? 'Receive from the Exhibition   '
                  : '  الاستلام من المعرض'}
              </Typography>
              </Typography> */}
              {
                console.log(price,'pricesss')
              }
              <div
                className="tamara-product-widget"
                data-lang={lng}
                data-price={
                  data?.data?.finalPrice +
                  (data.data.offer
                    ? price - (price * data.data.offer.percentage) / 100
                    : price || 0)
                }
                data-currency="SAR"
                data-country-code="SA"
                data-color-type="default"
                data-show-border="true"
                data-payment-type="installment"
                data-number-of-installments="4"
                data-disable-installment="false"
                data-disable-paylater="true"
              ></div>

              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: data?.data?.priceAfterDiscount
                    ? 'space-between'
                    : 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                {data?.data?.priceAfterDiscount ? (
                  <Typography component={'p'} sx={{}}>
                    {data.data?.offer?.percentage || '0'} %
                  </Typography>
                ) : null}

                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {data.data.priceAfterDiscount > 0 ? (
                    <Typography
                      sx={{
                        color: colors.priceBeforeColor,
                        textDecoration:
                          data?.data?.priceAfterDiscount > 0 ? 'none' : 'none',
                        fontSize:
                          data?.data?.priceAfterDiscount > 0
                            ? '0.7rem'
                            : 'initial',
                        fontSize: '10px',
                        marginTop: '2px',
                        margin: '0px 10px',
                        'text-decoration': 'line-through',
                      }}
                    >
                      {(
                        (data?.data?.priceBeforeDiscount + (price || 0)) *
                        currencyPrice
                      ).toFixed(2)}{' '}
                      {currencySymbol}
                    </Typography>
                  ) : null}

                  <Typography sx={{ color: colors.priceAfterColor }}>
                  
                    {(
                      (data?.data?.finalPrice +
                        (data.data.offer
                          ? price - (price * data.data.offer.percentage) / 100
                          : price || 0)) *
                      currencyPrice
                    ).toFixed(2)}{' '}
                    {currencySymbol}
                  </Typography>
                </Stack>
              </Stack>
              {data?.qualities?.length > 0 && data?.qualities != undefined ? (
                <Qualites
                  language={lng === 'ar'}
                  setQuantity={setQuantity}
                  check={check}
                  setCheck={setCheck}
                  item={data?.qualities}
                  pushIndex={0}
                  setImage={setImage}
                  setImages={setQuantityImages}
                  quantityImages={QuantityImages}
                  defaultImages={product?.images}
                  setPrice={setPrice}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  defaultITem={data?.qualities[0]}
                />
              ) : null}
              <Stack
                direction="row"
                justifyContent={'flex-end'}
                width={'100%'}
                spacing={2}
                height={cartData?.qualities?.length ? 50 : 0}
                sx={{
                  justifyContent: 'flex-end',
                }}
              >
                {cartData?.qualities?.map((chip) => {
                  console.log(
                    chip,
                    chip?.value_en,
                    chip?.value_en?.startsWith('#'),
                    'chip'
                  )
                  chip?.value_en?.startsWith('#')
                  return (
                    <>
                      {chip?.value_en?.startsWith('#') ? (
                        <Chip
                          //  label={`${lng === 'en'
                          //  ? 'color' : 'اللون'}`}
                          // key={chip?.key_en}
                          variant="outlined"
                          sx={{
                            margin: '10px !important',
                            padding: 1,
                            visibility: 'visible',
                            width: 'fit-content',
                            borderRadius: '0px !important',
                            border: `1px solid ${chip?.value_en}!important`,
                            color: '#fff !important',
                            height: '28px !important',
                            'svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root':
                              {
                                color: '#fff',
                                margin: '10px auto',
                                width: '20px',
                                height: '20px',
                                top: '-22px',
                                left: '-13px',
                              },
                            position: 'relative',
                            overflow: 'visible',
                            '.css-1jzq0dw-MuiChip-label': {
                              display: 'none',
                            },
                            background: `${chip?.value_en} !important`,
                            borderRadius: '7px !important',
                          }}
                          onDelete={() => handleDelete(chip?.key_en)}
                        ></Chip>
                      ) : (
                        <Chip
                          label={`${
                            lng === 'en' ? chip.value_en : chip.value_ar
                          }`}
                          key={chip?.key_en}
                          variant="outlined"
                          sx={{
                            margin: '10px !important',
                            padding: 1,
                            visibility: 'visible',
                            width: 'fit-content',
                            borderRadius: '0px !important',
                            border: '1px solid #000 !important',
                            color: '#000 !important',

                            'svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root':
                              {
                                color: 'black',
                              },
                            border: '1px solid  !important',
                            borderColor: `#5c5c5c !important`,
                            borderRadius: '7px !important',
                          }}
                          onDelete={() => handleDelete(chip?.key_en)}
                        />
                      )}
                    </>
                  )
                })}
              </Stack>

              <Typography
                sx={{
                  fontSize: { xs: '13px', sm: '13px', md: '20px' },
                }}
                component={'p'}
              >
                {lng === 'en' ? 'Quantity' : 'الكميه'}
              </Typography>
              <Stack
                direction={{ xs: 'column', md: 'column' }}
                sx={{ gap: 5, justifyContent: 'start', width: '100%' }}
              >
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    direction={'row'}
                    sx={{
                      border: '1px solid black',
                      borderRadius: 1,
                      alignItems: 'center',
                      my: 1,
                      width: 'fit-content',
                      py: 0.2,
                      width: '40%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '0px',
                      padding: { xs: '3px', sm: '6px' },
                      color: '#000',
                      fontWeight: 'bold',
                      marginLeft: lng === 'en' ? 'unset' : 'auto',
                      fontSize: '20px',
                      height: { xs: '30px', sm: '35px', md: '30px ' },
                      width: { xs: '60%', sm: '65%', md: '40%' },
                    }}
                  >
                    <Button
                      size="large"
                      onClick={() => updateQty('+')}
                      sx={{
                        color: 'black !important',
                        minWidth: 50,
                        fontSize: '20px !important',
                        fontWeight: '400 ',
                      }}
                    >
                      +
                    </Button>

                    <Typography
                      sx={{
                        margin: 'auto',
                      }}
                    >
                      {cartData?.quantity}
                    </Typography>
                    <Button
                      size="large"
                      onClick={() => updateQty('-')}
                      disabled={cartData.quantity === 1}
                      sx={{
                        color: '#000 !important',
                        minWidth: 50,
                        fontSize: '20px !important',
                      }}
                    >
                      -
                    </Button>
                  </Stack>

                  <Stack>
                    <FavoriteIconCard lng={lng} data={data?.data} />
                  </Stack>
                </Stack>

                {data?.data?.paymentType === 'both' ? (
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {lng === 'en' ? 'payment Method' : 'طريقه الدفع'}
                    </Typography>

                    <Stack
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: {
                          xs: lng === 'en' ? 'flex-end' : 'flex-end',
                        },

                        flexDirection: 'row',
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                        },
                        my: 2,
                      }}
                    >
                      <Button
                        sx={{
                          padding: '10px',
                          border: '1px solid #000',
                          color: '#000',
                          borderRadius: '0px',
                          margin: '0px 20px ',
                          fontWeight: 'bold',
                          color: paymentMethod === 'online' ? '#fff' : '#000',
                          bgcolor: paymentMethod === 'online' ? '#000' : '',
                          '&:hover': {
                            color: '#fff',
                            bgcolor: '#000',
                          },
                          width: {
                            xs: '49%',
                            sm: '49%',
                            md: '200px',
                          },
                          fontSize: {
                            xs: '13px',
                            sm: '14px',
                            md: '17px',
                          },
                          marginLeft: '20px',

                          textTransform: 'lowercase',
                        }}
                        onClick={() => setPaymentMethod('online')}
                      >
                        {lng === 'en' ? 'online' : '    اونلاين'}
                      </Button>
                      <Button
                        sx={{
                          padding: '10px',
                          border: '1px solid #000',
                          borderRadius: '0px',
                          margin: '0px 20px ',
                          marginLeft: lng === 'en' && '0px',
                          marginRight: '0px',
                          fontWeight: 'bold',
                          color: paymentMethod === 'cash' ? '#fff' : '#000',
                          bgcolor: paymentMethod === 'cash' ? '#000' : '#fff',
                          '&:hover': {
                            color: '#fff',
                            bgcolor: '#000',
                          },
                          width: {
                            xs: '49%',
                            sm: '49%',
                            md: '200px',
                          },
                          fontSize: {
                            xs: '13px',
                            sm: '14px',
                            md: '15px',
                          },
                          textTransform: 'lowercase',
                        }}
                        onClick={() => setPaymentMethod('cash')}
                      >
                        {lng === 'en' ? 'cash on delivery' : ' عند الاستلام'}
                      </Button>
                    </Stack>
                    <Typography
                      sx={{
                        color: 'red',
                      }}
                    >
                      {data?.data?.paymentType === 'both' &&
                      !cartIn &&
                      paymentMethod == ''
                        ? paymentMethod === ''
                          ? lng === 'en'
                            ? 'choose Payment Type'
                            : 'اختر نوع الدفع'
                          : ''
                        : ''}
                    </Typography>
                  </Stack>
                ) : null}
                <Typography
                  sx={{
                    flexDirection: 'row',
                  }}
                >
                  {data?.data?.quantity < 10 ? (
                    <>
                      {lng === 'en'
                        ? 'Aavailable  product Quantity'
                        : ' الكميه المتوفره من المنتج'}

                      <span
                        style={{
                          margin: '0px 10px',
                        }}
                      ></span>
                      {data?.data?.quantity}
                    </>
                  ) : null}
                </Typography>

                <Stack direction={'row-reverse'} sx={{ gap: 1 }}>
                  {data?.data?.quantity > 0 ? (
                    <Button
                      variant="contained"
                      sx={{
                        py: 1,
                        color: ` ${colors.buttonColor}`,
                        bgcolor: `${colors.buttonBgColor} !important`,
                        fontSize: { xs: '11px', md: '12px', xl: '14px' },
                        width: '100%',
                        padding: '13px 40px',
                        'border-radius': '0px',
                        height: '45px',
                      }}
                      disabled={checkDisableFunc()}
                      onClick={() => AddToCartFunc(cartData)}
                    >
                      {cardLoad || DeleteFromCartLoading ? (
                        <>
                          <Typography>
                            {lng === 'en' ? 'loading' : 'جاري التحميل'}
                          </Typography>
                          <CircularProgress
                            size={20}
                            style={{ marginRight: '4px' }}
                            color="inherit"
                          />
                        </>
                      ) : (
                        <>
                          {' '}
                          {lng === 'en'
                            ? 'Add to cart'
                            : 'أضف إلى سلة التسوق'}{' '}
                        </>
                      )}
                    </Button>
                  ) : (
                    <p>{lng === 'en' ? 'out Of Stock' : 'المنتج غير متوفر'}</p>
                  )}

                  <CopyButton
                    colors={{
                      buttonColor: colors.buttonColor,
                      buttonBgColor: colors.buttonBgColor,
                    }}
                  />
                </Stack>
              </Stack>

              <CustomPaymentType type={data.data.paymentType} lang={lng} />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  width: '100%',
                }}
              >
                <Accordion
                  expanded={expanded === 'desc'}
                  onChange={handleChange('desc')}
                  sx={{
                    width: '100%',
                    borderRadius: '0px !important',
                    borderBottom: '1px solid #000',
                    boxShadow: 'none',
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      justifyContent: 'center',
                      height: '20px',
                      padding: '0px',
                      display: 'flex !important',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      borderTop: '1px solid #000',
                      borderBottom: '1px solid #000',
                      borderRadius: '0px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '0px 10px',
                        color: '#000',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '30px',
                          color: '#000',
                          fontWeight: '100',
                        }}
                      >
                        +
                      </Typography>
                      {lng === 'en'
                        ? '  Description & Details '
                        : 'الوصف والتفاصيل'}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      height: '300px',
                      overflow: 'auto',
                    }}
                    className="accrd"
                  >
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      {/* <Typography
                sx={{
                  fontSize: { xs: '13px', sm: '13px', md: '20px' },
                }}
                component={'h3'}
              >
                {lng === 'en' ? 'Product Description' : 'التفاصيل'}
              </Typography> */}
                      <Box
                        sx={{
                          color: colors.descColor,

                          direction: lng === 'en' ? 'ltr' : 'rtl',
                          textAlign: lng === 'en' ? 'left' : 'right',

                          '& ul': {
                            textAlign: `${
                              lng === 'en' ? 'left' : 'right'
                            } !important`,
                          },
                          '& ol': {
                            textAlign: `${
                              lng === 'en' ? 'left' : 'right'
                            } !important`,
                          },

                          fontSize: { xs: '0.8rem', sm: '1.4rem' },
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            data.data[
                              `description_${lng === 'en' ? 'en' : 'ar'}`
                            ],
                        }}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>

              {/* <CustomPaymentType type={data.data.paymentType} lang={lng} /> */}
            </Stack>
          </Grid>
          <Grid item xs={12} md={11} lg={6} sx={{ order: { xs: -1, md: -1 } }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              position={'relative'}
            >
              <Box
                component={Swiper}
                sx={{
                  width: '100%',
                  m: 1,
                  height: {
                    md: '80vh',
                    xs: 'auto',
                  },
                  '--swiper-navigation-color': '#fff',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  width: {
                    xs: '90%',
                    md: '100%',
                  },
                  margin: 'auto',
                }}
                spaceBetween={10}
                // navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
                position={'relative'}
              >
                {console.log(QuantityImages, 'QuantityImagesQuantityImages')}
                {QuantityImages?.map((img) => (
                  <SwiperSlide key={img} style={{ position: 'relative' }}>
                    <Zoom ZoomContent={CustomZoomContent}>
                      <Box
                        component={'img'}
                        src={imageBaseUrl + img}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          objectPosition: 'center center',
                        }}
                      />
                    </Zoom>
                  </SwiperSlide>
                ))}
              </Box>
              <Box
                component={Swiper}
                direction={phoneScreen ? 'horizontal' : 'vertical'}
                onSwiper={setThumbsSwiper}
                spaceBetween={5}
                slidesPerView={'auto'}
                mousewheel
                modules={[Mousewheel]}
                // breakpoints={{
                // 1299: {
                //   slidesPerView: 3.6,
                // },
                // }}
                sx={{
                  width: { xs: '99%', md: '200px' },
                  m: 1,
                  height: { xs: '100%', md: '80vh' },
                  direction: 'ltr',
                  '--swiper-navigation-color': '#fff',
                  maxHeight: 'auto',
                  position: {
                    xs: 'block',
                    xl: 'sticky',
                  },
                  top: {
                    xs: 'unset',
                    xl: '0px',
                  },
                  paddingLeft: '10px',
                  marginLeft: '10px',
                  marginLeft: '10px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
                className="mySwiper"
              >
                <Box sx={{}}>
                  {QuantityImages?.map((img) => (
                    <SwiperSlide
                      key={img}
                      style={{ width: 'auto', height: 'auto' }}
                    >
                      <Box
                        component={'img'}
                        sx={{
                          width: { xs: '90px', md: '130px' },
                          height: {
                            xs: '100px',
                            md: '100%',
                          },
                          objectFit: 'contain',
                          cursor: 'pointer',
                          // border: '1px solid #333',
                        }}
                        src={imageBaseUrl + img}
                      />
                    </SwiperSlide>
                  ))}
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}

      {/* <ProductComments colors={colors.commentsColors} productId={id} />
      {!isError && !isLoading && (
        {/* )} */}
      <Similarproduct productId={data?.data?.id} id={categoryId} />
      <MostSelling productId={data?.data?._id} />
      <ProductReviews
        colors={colors.commentsColors}
        productId={id}
        product={data?.data}
      />
    </>
  )
}
