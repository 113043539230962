export const appColors = {
  rose: '#ffbbbb',
  primary: {
    main: '#28acb3',
    light: '#2b969c',
  },
  secondary: {
    main: '#363636',
    light: '#707070',
    dark: 'rgba(0, 0, 0, 0.5)',
  },
  whiteColor: {
    main: '#ffffff',
    dark: 'rgba(0, 0, 0, 0)',
  },
  rose: '#ffbcbc',
}

export const mainColors = {
  primary: '#000',
  secondary: '#9e8356',
  gray: '#6a6a6a',
  gray2: '#e0e0e0',
  white: '#fff',
}
