import React, { lazy, Suspense } from 'react'
// import Scrolling5 from '../../components/Home/Category/Scrolling5'
import AboutImgButton from '../../components/Home/About/AboutImgButton'
import heroVideo from '../../assets2/new_hero.mp4'
import Scrolling5 from '../../components/Home/Category/Scrolling5'
import HeroVideo5 from '../../components/Home/HeroSection/HeroVideo/HeroVideo5/HeroVideo5'
import Collection from '../../components/Home/CollectionCateg/Collection'
import CollectionHover from '../../components/Home/CollectionHover/CollectionHover'

const AnalyticsMetaTags = lazy(() =>
  import('../../components/analyticsMetaTags/AnalyticsMetaTags')
)
const Index = () => {
  return (
    <>
      <HeroVideo5 backgroundVideo={heroVideo} />
      <Collection />
      <Scrolling5 />
      <AboutImgButton />
      <CollectionHover />
      <Suspense fallback={<div>Loading...</div>}>
        <AnalyticsMetaTags />
      </Suspense>
    </>
  )
}

export default Index
