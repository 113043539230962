export const colors = {
  bgColor: '',
  titleColor: 'green',
  descColor: '',
  subTitleColor: '',
  buttonTextColor: '#fff',
  buttonBgColor: '#BF9FEA',
}

export default {
  gridContainer: {
    backgroundColor: colors.bgColor,
    // py: 5,
  },
  aboutSection: {
    typography: {
      fontSize: { xs: '27px', sm: '30px', lg: '40px' },
      fontWeight: 'bold',
      textAlign: 'center',
      color: colors.titleColor,
      textTransform: 'capitalize',
    },
    dangerously: {
      mt: 2,
      fontSize: { xs: '18px', md: '22px', lg: '25px' },
      color: colors.subTitleColor,
    },
    flexHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid black',
      py: { xs: 4, lg: 1 },
    },
  },
}
