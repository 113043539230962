import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import img1 from '../../../assets2/about1.png'
import img2 from '../../../assets2/about2.png'
import { colors } from './about3.style'
import { useGetSectionByTypeQuery } from '../../../redux/apis/sectionsApi'
import { imageBaseUrl } from '../../../constants/baseUrl'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader'

const Services = ({ colors }) => {
  const [, { language: lang }] = useTranslation()
  const data = [
    {
      title_en: 'Quality Assurance',
      title_ar: 'ضمان الجودة',
      desc_en: 'We guarantee the authenticity and quality of all our products.',
      desc_ar: 'نضمن أصالة وجودة جميع منتجاتنا',
    },
    {
      title_en: 'Competitive Pricing',
      title_ar: 'تسعير تنافسي',
      desc_en: 'We offer the best prices in the market.',
      desc_ar: 'نقدم أفضل الأسعار في السوق',
    },
    {
      title_en: 'Customer Service',
      title_ar: 'خدمة عملاء',
      desc_en: 'Our dedicated customer service team is here to help you',
      desc_ar: 'فريق خدمة العملاء المخصص لمساعدتك',
    },
  ]
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-between'}
      columnGap={5}
      rowGap={2}
      sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}
    >
      {data?.map((item, index) => (
        <Stack
          key={item?.title_en}
          // flex={1}
          direction={'column'}
          // alignItems={'center'}
          // justifyContent={'center'}
          gap={3}
          sx={{
            bgcolor: '#000',
            py: { xs: 2, lg: 6 },
            px: { xs: 2, lg: 6 },
          }}
        >
          <Box
            sx={{
              bgcolor: '#9e8356',
              width: { xs: '30px', md: '50px' },
              height: { xs: '30px', md: '50px' },
              mx: 'auto',
            }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '1.1rem', md: '1.5rem' },
                color: '#9e8356',
                textAlign: 'center',
              }}
            >
              {lang === 'en' ? item?.title_en : item?.title_ar}
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#9e8356',
                textAlign: 'center',
              }}
            >
              {lang === 'en' ? item?.desc_en : item?.desc_ar}
            </Typography>
          </Box>
        </Stack>
      ))}
    </Stack>
  )
}
const About3 = () => {
  const [, { language: lang }] = useTranslation()
  const imgs = ['', img1, img2]
  const { data, isLoading, error, isSuccess } =
    useGetSectionByTypeQuery('aboutus')

  const handleDesc = (desc, index) => {
    return (
      desc?.length > 0 &&
      desc
        .split(' ')
        .slice(
          Math.ceil(
            (data?.data[0].description_en.split(' ').length / imgs.length) *
              index
          ),
          Math.ceil(
            (data?.data[0].description_en.split(' ').length / imgs.length) *
              (index + 1)
          )
        )
        .join(' ')
    )
  }

  return (
    <>
      <Stack
        sx={{
          direction: lang === 'en' ? 'ltr' : 'rtl',
          minHeight: '100vh',
          mt: 5,
        }}
      >
        {isLoading && <AnimatedLoader/>}
        {error && (
          <Box
            sx={{
              width: '100%',
              height: '50vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography align='center' color='error' variant='h4'>
              {error?.data
                ? error?.data[`error_${lang}`]
                : lang === 'en'
                ? 'Something went wrong'
                : 'حدث خطأ ما'}
            </Typography>
          </Box>
        )}
        {isSuccess && !error && !isLoading && (
          <Box>
            {imgs?.map((img, index) => (
              <Stack
                direction={'column'}
                sx={{
                  bgcolor: index % 2 === 0 ? colors.bgColor1 : colors.bgColor2,
                }}
                key={index}
                gap={5}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: { xs: 500, lg: 600 },
                    background:
                      index === 0
                        ? `url(${imageBaseUrl}${data?.data[0].image})`
                        : `url(${img}) center center`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                <Box sx={{ width: 'auto', p: { xs: 1, md: 5 } }}>
                  <Box
                    sx={{
                      lineHeight: 2,
                      fontSize: { md: 20, lg: 25 },
                      color: colors.descColor,
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === 'en'
                          ? handleDesc(data?.data[0].description_en, index)
                          : handleDesc(data?.data[0].description_ar, index),
                    }}
                  />
                </Box>
              </Stack>
            ))}
          </Box>
        )}
      </Stack>
      <Box sx={{ px: { xs: 3, md: 0 }, bgcolor: '#000',display:"block" }}>
        <Services colors={colors} />
      </Box>
    </>
  )
}

export default About3
