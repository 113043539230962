import { useEffect, useState } from 'react'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis'
import { useSearchParams } from 'react-router-dom'

export const useSortProducts = (payload, query) => {
  const [products, setProducts] = useState([])
  const [params,setSearchParams] = useSearchParams();
  const [paginationData, setPaginationData] = useState({})
  const [getAllProducts, { isLoading, isError, error, loading }] =
    useLazyGetAllProductsQuery()
    const pageNumber = params.get('page') || 1
  useEffect(() => {
    
    if (payload || query) {
      params.set('sort',`-${payload}`)
      params.set('limit',24)
      params.set('page',pageNumber)
      getAllProducts(
        params.toString().concat(query ? `&${query}` : '')
      )
        .unwrap()
        .then(res => {
          setPaginationData(res.paginationResult)
          setProducts(res?.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [query, payload,pageNumber])
  return { sortedProducts: products, isLoading, isError, error, loading,paginationData,params,setSearchParams }
}
