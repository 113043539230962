import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Pagination,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis'
import { AllProductsGridStyles } from './subCategories.Styles.jsx'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './subCategories.Styles.jsx'
import { filterMenuStyles } from './subCategories.Styles.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import MetaTags from '../../../components/metaTags/MetaTags.jsx'
import FilerAttributesLarge from '../../../components/filerAttributesLarge/FilerAttributesLarge.jsx'
import Card from '../../singleProductPages/noon1/NewCard/index.jsx'
import SortByMenu from '../../../components/SortByMenu'
import { useLazyGetSubCategoryByIdQuery } from '../../../redux/apis/subcategoriesApi'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader.jsx'

// =========================productsGrid=======================================================
function AllProductsGrid({ cards }) {
  return (
    <Grid container spacing={AllProductsGridStyles.GridSpace} width={'100%'}>
      {cards?.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          key={index}
          sx={{ ...AllProductsGridStyles.GridItem, p: '5px' }}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
export const useFetchSubCategoryDetails = (subId) => {
  const [getSubCategoryById] = useLazyGetSubCategoryByIdQuery()
  const [state, setState] = useState()

  useEffect(() => {
    if (subId) {
      getSubCategoryById(subId)
        .unwrap()
        .then((res) => {
          setState(res.data)
        })
        .catch((err) => {})
    } else setState(undefined)
  }, [subId])
  return { subCategory: state }
}

// ==========================Hook===========================================
const useFetchProductsByCategoryIdAndSubId = (categoryId, subId, query) => {
  const [products, setProducts] = useState([])
  const [params, setSearchParams] = useSearchParams()
  const [paginationData, setPaginationData] = useState({})
  const [getAllProducts, { data, isLoading }] = useLazyGetAllProductsQuery()
  const pageNumber = params.get('page') || 1
  useEffect(() => {
    if (categoryId && subId) {
      params.set('category', categoryId)
      params.set('subCategory', subId)
      params.set('limit', 24)
      params.set('page', pageNumber)
      getAllProducts(
        // `category=${categoryId}&subCategory=${subId}&limit=3&page=${pageNumber}`
        params.toString().concat(query ? `&${query}` : '')
      )
        .unwrap()
        .then((res) => {
          setPaginationData(res.paginationResult)
          setProducts(res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [data, subId, categoryId, query, pageNumber])
  return {
    categoryAndSubProducts: products,
    isLoading,
    paginationData,
    setSearchParams,
    params,
  }
}
// ==========================Hook===========================================
// ======================================== Main Component=================================

export const SubCategoriePage = () => {
  const { categoryId, subId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  // sortBy
  const [sortBy, setSortBy] = useState('')
  const handleSortChange = (sortBy) => {
    setSortBy(sortBy)
  }

  const { subCategory } = useFetchSubCategoryDetails(subId)
  const {
    categoryAndSubProducts,
    isLoading: categAndSubProductsLoading,
    isError,
    error,
    paginationData,
    setSearchParams,
    params,
  } = useFetchProductsByCategoryIdAndSubId(
    categoryId,
    subId,
    `${filterQuery ? `&${query}` : query}${
      query ? `&${filterQuery}` : filterQuery
    }${
      sortBy ? `&sort=${sortBy}` : '' // Include the sort parameter if sortBy is defined
    }`
  )
  useEffect(() => {
    setData(categoryAndSubProducts)
    setLoading(categAndSubProductsLoading)
  }, [categoryAndSubProducts, categAndSubProductsLoading, query, subId])

  const { totalPages } = paginationData
  const handlPagination = (e, page) => {
    params.set('page', page)
    setSearchParams(params)
  }

  return (
    <Box
      sx={{
        direction: lng === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <MetaTags id={subId} />
      <Typography
        align={'center'}
        sx={{
          bgcolor: '#E0E0E0',
          py: 4,
          mb: 5,
          fontWeight: '600',
          fontSize: { xs: '2rem', lg: '2.6rem' },
          textTransform: 'capitalize',
        }}
      >
        {lng === 'en' ? subCategory?.name_en : subCategory?.name_ar}
      </Typography>

      <Stack
        mx={{ xs: 2, md: 5 }}
        my={5}
        direction={{ xs: 'column', md: 'column' }}
        alignItems={'flex-start'}
        spacing={4}
      >
        <>
          {/* stack of sort filterMenu in small screen */}
          <Box
            sx={{
              position: 'sticky',
              top: {
                md: '120px',
                xs: '90px',
              },
              zIndex: '2',
              py: '14px',
              width: 1,
              display: 'flex',
              bgcolor: '#fff',
              justifyContent: {
                md: 'flex-end',
                xs: 'space-between',
              },
            }}
          >
            <SortByMenu onSortChange={handleSortChange} />
            <Box
              sx={{
                display: {
                  md: 'none',
                  xs: 'block',
                },
              }}
            >
              <FilterAttributesMenu
                setFilterQuery={setFilterQuery}
                filterQuery={filterQuery}
              />
            </Box>
          </Box>
          <Stack
            width={'100%'}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
          >
            <Box
              flex={'0 0 17%'}
              sx={{
                display: {
                  md: 'block',
                  xs: 'none',
                },
                pt: '20px',
              }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: '175px',
                }}
              >
                <FilerAttributesLarge
                  setFilterQuery={setFilterQuery}
                  filterQuery={filterQuery}
                  colors={colors}
                  filterMenuStyles={filterMenuStyles}
                />
              </Box>
            </Box>
            <Box flex={'0 0 80%'}>
              {!loading ? (
                <AllProductsGrid
                  cards={data?.map((pro, index) => (
                    <Card item={pro} />
                  ))}
                />
              ) : (
                <AnimatedLoader />
              )}
            </Box>
          </Stack>
          {/* {totalPages > 1 && (
            <Box
              sx={{
                direction: 'ltr',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,
                width: '100%',
              }}
            >
              <Pagination
                count={totalPages}
                page={Number(params.get('page' || 1))}
                variant="outlined"
                shape="rounded"
                onChange={handlPagination}
                sx={{
                  '& button': {
                    width: '32px',
                    height: '32px',
                  },
                }}
              />
            </Box>
          )} */}
        </>

        {!loading && (data?.length < 1 || data?.length === undefined) && (
          <Typography
            color={'error'}
            sx={{
              minHeight: '40vh',
              width: '100%',
              fontSize: '2rem',
              textAlign: 'center',
            }}
          >
            {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            {/* {error?.data[`error_${lng}`]} */}
          </Typography>
        )}
        {/* not fo */}
      </Stack>
    </Box>
  )
}
// ======================================== Main Component=================================

function FilterAttributesMenu({ filterQuery, setFilterQuery }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lang }] = useTranslation()
  const { attributesData } = GenerateAttributeData()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    formik.resetForm()
    setExpanded(false)
  }
  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const manipulateQuery = () => {
    let query = ''
    let selAttsValues = selectedAtts
      .reduce((item, obj) => {
        return item.concat(obj.values)
      }, [])
      .map(({ value_en }) => value_en)
    selAttsValues.map((val) => (query += `attributes.values.value_en=${val}&`))
    if (priceState) {
      query += !query ? `` : '&'
      query += `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    }
    return query
  }

  const [selectedAtts, setSelectedAtts] = useState([])

  const [priceState, setPriceState] = useState()
  const hundleAddAtt = (attribute, selectedValue, event) => {
    const existedAtt = selectedAtts.find(
      (item) => item.key_en === attribute.key_en
    )
    const { checked, value } = event.target
    if (checked) {
      existedAtt
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === attribute.key_en
                ? {
                    key_en: attribute.key_en,
                    key_ar: attribute.key_ar,
                    values: [...existedAtt.values, { ...selectedValue }],
                  }
                : item
            )
          )
        : setSelectedAtts([
            ...selectedAtts,
            {
              key_en: attribute.key_en,
              value_en: attribute.key_ar,
              values: [selectedValue],
            },
          ])
    } else {
      existedAtt?.values?.length > 1
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === existedAtt.key_en
                ? {
                    ...existedAtt,
                    values: existedAtt?.values.filter(
                      (val) => val.value_en !== value
                    ),
                  }
                : item
            )
          )
        : setSelectedAtts(
            selectedAtts.filter((sel) => sel.key_en !== existedAtt?.key_en)
          )
    }
  }
  useEffect(() => {
    setFilterQuery(manipulateQuery())
  }, [selectedAtts, priceState])
  const formik = useFormik({
    initialValues: { from: '', to: '' },
    validationSchema: object({
      from: string().required(),
      to: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      setPriceState(values)
      let submitQueries = `priceBeforeDiscount[gte]=${values.from}&priceBeforeDiscount[lte]=${values.to}`
      setFilterQuery(query ? `${query}&${submitQueries}` : submitQueries)
      resetForm()
    },
  })
  const refetchAllProducts = () => {
    setFilterQuery('')
    setSelectedAtts([])
    setPriceState()
  }
  const clearPriceFilter = () => {
    let priceQueries = `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    setFilterQuery(filterQuery.replace(priceQueries, ''))
    setTimeout(() => {
      setPriceState()
    }, 240)
  }
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      sx={{
        mx: 'auto',

        gap: '10px',
      }}
    >
      <Box>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={
            <FilterAltOutlinedIcon
              sx={{
                color: `#333 !important`,
              }}
            />
          }
        >
          <Typography
            sx={{
              ...filterMenuStyles.menuButton,
              color: '#333',
            }}
          >
            {lang === 'en' ? 'filter by' : 'تصفية حسب'}
          </Typography>
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            maxHeight: 600,
            p: 0,
            direction: lang === 'en' ? 'ltr' : 'rtl',
          }}
        >
          {attributesData?.length > 0 &&
            attributesData?.map((attribute) => (
              <Accordion
                expanded={expanded === attribute.key_en}
                onChange={handleChange(attribute.key_en)}
                sx={{
                  m: '0 !important',
                }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {selectedAtts.includes((item) =>
                      item.key_en == attribute.key_en ? (
                        <CheckBoxIcon
                          sx={{
                            color: 'blue',
                            fontSize: '20px',
                          }}
                        />
                      ) : undefined
                    )}
                    <Typography sx={filterMenuStyles.checkBoxLabel}>
                      {attribute[`key_${lang}`]}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    overflow: 'hidden',
                  }}
                >
                  {attribute.values?.map((value) => (
                    <Stack sx={filterMenuStyles.checkBoxContainer}>
                      <input
                        type="checkbox"
                        id={value.value_en}
                        value={value.value_en}
                        checked={selectedAtts.find(
                          (item) =>
                            item.key_en === attribute.key_en &&
                            item.values.find(
                              ({ value_en }) => value_en === value.value_en
                            )
                        )}
                        onChange={(event) => {
                          hundleAddAtt(attribute, value, event)
                        }}
                      />
                      <Typography
                        component="label"
                        htmlFor={value.value_en}
                        sx={filterMenuStyles.checkBoxLabel}
                      >
                        {value[`value_${lang}`]}
                      </Typography>
                    </Stack>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          <Accordion
            expanded={expanded === 'price'}
            onChange={handleChange('price')}
            sx={{
              m: '0 !important',
            }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                justifyContent: 'center',
              }}
            >
              <Typography sx={filterMenuStyles.priceBtn}>
                {lang === 'en' ? 'Price' : 'السعر'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: 1,
                borderColor: 'divider',
                overflow: 'hidden',
              }}
            >
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 200,
                  }}
                >
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'From' : 'من'}
                    </Typography>
                    <InputBase
                      name="from"
                      type="number"
                      value={formik.values.from}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.from && formik.touched.from
                            ? 'red'
                            : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'To' : 'إلي'}
                    </Typography>
                    <InputBase
                      type="number"
                      name="to"
                      value={formik.values.to}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.to && formik.touched.to ? 'red' : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                </Stack>

                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Button
                    disabled={priceState}
                    type="submit"
                    sx={filterMenuStyles.formPriceBtn}
                  >
                    {lang === 'en' ? 'Search by price' : 'بحث بالسعر'}
                  </Button>
                  {priceState ? (
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={clearPriceFilter}
                    />
                  ) : null}
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Menu>
      </Box>
      {selectedAtts?.length > 0 || filterQuery ? (
        <RefreshIcon
          sx={{
            color: colors.filter.checkBoxLabel,
            cursor: 'pointer',
          }}
          onClick={refetchAllProducts}
        />
      ) : undefined}
    </Stack>
  )
}

const GenerateAttributeData = () => {
  const [getAllAttributes] = useLazyGetAllAttributesQuery()
  const [attributesData, setAtts] = useState()
  useEffect(() => {
    getAllAttributes()
      .unwrap()
      .then((res) => {
        setAtts(res.data)
      })
      .catch((err) => {
        setAtts()
      })
  }, [])
  return {
    attributesData,
  }
}
