import { Box, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './draft.style.css'
import draftToHtml from 'draftjs-to-html'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'

const DraftEditor = (props) => {
  const _contentState = ContentState.createFromText('Sample content state')
  /*   const raw = convertToRaw(_contentState); */ // RawDraftContentState JSON
  //   const [contentState, setContentState] = useState(raw);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [editorState_2, setEditorState_2] = useState(() =>
    EditorState.createEmpty()
  )
  const { palette } = useTheme()
  const [_, { language: lang }] = useTranslation()
  useEffect(() => {
    if (props?.edit) {
      const doc = new DOMParser().parseFromString(props?.value, 'text/html')
      const textContent = doc.body.textContent
      const contentState = ContentState.createFromText(textContent)
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }

    const doc = new DOMParser().parseFromString(props?.value, 'text/html')
    const textContent = doc.body.textContent
    const contentState = ContentState.createFromText(textContent)
    const newEditorState = EditorState.createWithContent(contentState)
    setEditorState_2(newEditorState)

  }, [props?.value])
  const htmlToPlainText = (html) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = html
    return tempElement.textContent || tempElement.innerText || ''
  }
  const convertToHtml = () => {
    const contentState = editorState.getCurrentContent()
    const contentRaw = convertToRaw(contentState)
    const html = draftToHtml(contentRaw)
    const doc = new DOMParser().parseFromString(props?.value, 'text/html')
    props?.handleChange(html)

    const plainText = htmlToPlainText(html)
    props.setHasValue(plainText)
    props?.set
  }
  return (
    <Stack
      component={Editor}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      wrapperClassName={`wrapper-class wrapper-class-${palette.mode}`}
      editorClassName="editor-class"
      toolbarClassName={`toolbar-class-${palette.mode}`}
      onChange={convertToHtml}
      touched={props.touched}
      value={props.value}
      field={props.field}
      error={props.error}
      onBlur={props.handleBlur}
      id={props?.id}
      placeholder={lang === 'en' ? 'Write here...' : 'أكتب هنا...'}

      //    toolbar={{
      //     options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
      //     // inline: { inDropdown: true },
      //     // list: { inDropdown: true },
      //     // textAlign: { inDropdown: true },
      //     // link: { inDropdown: true },
      //     // history: { inDropdown: true },
      // }}
    ></Stack>
  )
}

export default DraftEditor
