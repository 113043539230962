import {mainColors} from "../../../constants/colors"

export const Login3Colors = {
  iconColor: mainColors.gray,
  backgroundBtn: mainColors.primary,
  borderInputColor: mainColors.primary,
  placeholderColor: mainColors.gray,
  color: mainColors.gray,
  mainBgColor: "#ebebeb",
}
