export const MenuStyles = ({ lng }) => ({
  Menu: {
    maxHeight: 450,
   
    '.MuiMenu-paper': {
      borderRadius: '12px',
      width: 330,
      bgcolor: '#fff',
      boxShadow: '0 0 10px 0 rgb(0 0 0 / 20%)',
    },
    '.MuiMenu-paper::-webkit-scrollbar': {
      width: '8px',
      borderRadius: 4,
    },
    '.MuiMenu-paper::-webkit-scrollbar-track': {
      background: '#fff',
    },
    '.MuiMenu-paper::-webkit-scrollbar-thumb': {
      background: '#9e8356',
      borderRadius: 4,
    },
  },
  menuTitle: {
    textAlign: lng === 'ar' ? 'right' : 'left',
    fontWeight: 'bold',
    fontSize: '20px',
    p:"8px 25px",
   
  },
})
