import { mainColors } from "../../../constants/colors"
export const ContactNoon6Style = {
  Button: {
    color: 'white',
    backgroundColor: mainColors.primary,
  },
  color: {
    borderColor: mainColors.primary,
    textColor: mainColors.primary,
  },
}
