import { mainColors } from '../../../constants/colors'

export const CheckOutTomoor1Style = {
  color: {
    title: mainColors.primary,
    label: mainColors.primary,
  },
  button: {
    color: 'white',
    backgroundColor: mainColors.primary,
  },
}
