
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useAddToSavedProductMutation, useGetAllSavedProductsQuery } from '../../../../redux/apis/SavedProductApi'
const styles = styles
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'

export const FavoriteIconCard = ({
  data,
  lng,
  card = false
}) => {
  const { data: favPros, isSuccess, isError, isLoading } = useGetAllSavedProductsQuery(undefined)
  const [proInWishlist, setProInWishlist] = useState(false)
  const [toFavorite] = useAddToSavedProductMutation();
  useEffect(() => {
    if (isSuccess && !isError && !isLoading) {

      const proInFav = favPros?.data.favourite.find(
        (f) => f._id === data?._id
      )
      setProInWishlist(proInFav)
       
    }
  }, [favPros,data])
  //  
  return (
    <FavoriteIcon
      sx={{

        stroke: 'GrayText',
        fontSize: { xs: '1.4rem', lg: '2.3rem' },
        cursor: 'pointer',
        color: proInWishlist ? 'red' : 'transparent',
        // bgcolor:proInWishlist ? 'tomato' : 'transparent',
        strokeWidth: proInWishlist ? 0 : 1,
        marginLeft: card ? '20px !important' : '',
      }}
      onClick={(e) => {
        e.stopPropagation(),
          toFavorite(data?._id)
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
      }}
    />
  )
}
