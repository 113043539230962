import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
export function NavSubSubMenu({
  categoryItem,
  item,
  handleCloseSubMenu,
  handleDrawerToggle,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lng }] = useTranslation()
  const { pathname } = useLocation()
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const navigate = useNavigate()
  const { subCategory, category, subSubCategory } = item
  const HandleNavigate = (url) => {
    handleClose()
    navigate(url)
    if (handleCloseSubMenu) {
      handleCloseSubMenu()
    }
    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }
  return (
    <Stack
      direction={'row'}
      justifyContent={{
        md: 'center',
        xs: lng === 'en' ? 'flex-start' : 'flex-end',
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: pathname.includes(subCategory._id) ? '#000' : '#000',
          mb: 0,
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'3px'}>
          <ArrowDropDownIcon
            sx={{
              transform: open ? 'rotateX(180deg)' : 'rotatexX(90deg)',
            }}
          />
          <span title={subCategory[`name_${lng}`]}>
            {subCategory[`name_${lng === 'en' ? 'en' : 'ar'}`]}
          </span>
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {subSubCategory.map((subSub) => (
          <MenuItem
            key={subSub?._id}
            sx={{
              color: '#000',
              direction: lng === 'en' ? 'ltr' : 'rtl',
              justifyContent: 'flex-start',
              width: 1,
            }}
            onClick={() =>
              HandleNavigate(
                `/departments/${categoryItem?.id}/${subCategory?.id}/${
                  subSub?._id
                }/${subSub?.name_en.replace(/\s+/g, '-')}`
              )
            }
          >
            {subSub[`name_${lng}`]}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}
