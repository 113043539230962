import { Box, ButtonBase, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetAllCategoriesQuery } from '../../../redux/apis/categoriesApi'
import { imageBaseUrl } from '../../../constants/baseUrl'
import { useNavigate } from 'react-router-dom'
const Collection = () => {
  const [_, { language: lang }] = useTranslation()
  const navigaet = useNavigate()
  const [collection, setCollection] = useState([])
  const [getAllCategories, { error, isLoading ,isError,isSuccess}] =
    useLazyGetAllCategoriesQuery()
  useEffect(() => {
    getAllCategories()
      .unwrap()
      .then(res => {
        setCollection(res?.data?.slice(0, 3))
      })
      .catch(err => {})
  }, [])

  return (
    <Box
      sx={{
        py: 0,
        my: 3,
        mx: { xs: 1, md: 1 },
        minHeight:'600px'
        
      }}
    >
      {/* Title */}
      {!isLoading && !isError ? (
        <>
          <Box textAlign={'center'}>
            <Typography
              sx={{
                fontSize: { xs: '1.3rem', sm: '1.5', md: '2rem' },

                textAlign: 'center',
                mt: 7,
                mb: 2,
                'font-size': '2rem',
                'font-weight': 'bold',
               ' text-transform': 'uppercase',
              }}
            >
              {lang === 'en'
                ? 'Be part of the collection'
                : 'كن جزءاً من المجموعة'}
            </Typography>
          </Box>

          <Grid container my={3}sx={{
            justifyContent:'center'
          }}>
            {collection &&
              collection.map((item, index) => (
                <Grid
                
                  item
                  xs={12}
                  sm={6}
                  md={3.8}
                  key={index}
                  sx={{
                    // backgroundImage: `url(${imageBaseUrl}${item?.image})`,
                    // backgroundSize: 'contain',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'center top',
                    my: { xs: 1, md: 0 },
                    position: 'relative'
                   }}
                >
                  <Stack src={`${imageBaseUrl}${item?.image}`} component={'img'} sx={{

                    width:'100%',
                    maxHeight:'800px'
                  }}/>
                  {/* button and title */}
                  <Box textAlign={'center'} sx={{
                    position:'absolute',
                    top:'0px',
                    left:'0% ',
                    right:'50%',
                    width:'100%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column',
                  }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '1.5rem', md: '1.8rem' ,xl:'2.5rem'},
                        fontWeight: 'bold',
                        py: 2,
                      }}
                    >
                      {lang === 'en' ? item?.name_en : item?.name_ar}
                    </Typography>

                    <Box>
                      <ButtonBase
                        onClick={() => {
                          navigaet(
                            `/departments/${item?._id}/${item?.name_en.replace(
                              /\s/g,
                              '-'
                            )}`
                          )
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            py: {
                              xs:1,
                              lg:2
                            },
                            color: 'white',
                            backgroundColor: 'black',
                            px: {
                              xs:3,
                              lg:5
                              
                            },
                          }}
                        >
                          {lang === 'en' ? 'Shop now' : 'تسوق الآن'}
                        </Typography>
                      </ButtonBase>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Box textAlign={'center'}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '18px',
            }}
          >
            {/* {error?.data[`error_${lang}`]} */}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Collection
