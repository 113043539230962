import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import Rating from './Rating'
import DeleteIcon from '@mui/icons-material/Delete'
import { imageBaseUrl } from '../../constants/baseUrl'
import { useSelector } from 'react-redux'
import { useRemoveCommentFromProductByUserMutation } from '../../redux/apis/commentApi'
import { toast } from 'react-toastify'

export default function ReviewCard({ rev, user }) {
  const [, { language: lang }] = useTranslation()
  const { currentUser } = useSelector((state) => state)
  const [removeCommentFromProductByUser] =
    useRemoveCommentFromProductByUserMutation()
  const handleRemoveUserReview = () => {
    removeCommentFromProductByUser(rev._id)
      .unwrap()
      .then((res) => {
        toast.success(res[`success_${lang}`])
      })
      .catch((err) => {
        toast.error(err.data[`error_${lang}`])
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px',
        borderRadius: '5px',
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 'bold',

            fontSize: {
              lg: '18px',
              md: '16px',
              xs: '14px',
            },
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Avatar src={imageBaseUrl + rev.user.image} />
          {/* {lang === 'en' ? 'Overall Rating' : 'التقييم العام'} */}
        </Typography>

        <Stack>
          <Typography>
            <span>
              {rev.user.name
                ? rev.user.name
                : rev.user.registrationType === 'email'
                ? rev.user.email
                : rev.user.phone}
            </span>
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: {
                lg: '15px',
                md: '13px',
                xs: '11px',
              },
              color: '#a59d9d',
            }}
          >
            {new Date(rev?.createdAt).toDateString()}
          </Typography>
        </Stack>
      </Box>

      <Stack sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography
          component="span"
          sx={{
            wordBreak: 'break-all',
            fontSize: {
              lg: '16px',
              md: '14px',
              xs: '12px',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: rev?.comment,
          }}
        />
        {/* Review user */}
        <Stack
          sx={{
            '& .react-stars': {
              flexDirection: 'column',
              display: 'flex',
              gap: 1,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: 'auto',
            }}
          >
            <Rating
              disable={true}
              totalRating={rev?.rating ? rev?.rating : 0}
              disabled
            />
          </Box>

          {!rev?.rating ? (
            <>{lang === 'en' ? "Ther's No Rating" : 'لم يتم التقييم بعد'}</>
          ) : null}
        </Stack>
        <Typography
          component="span"
          sx={{
            fontSize: {
              lg: '15px',
              md: '13px',
              xs: '11px',
            },
          }}
        >
          {user?.name}
        </Typography>

        {currentUser?._id === rev.user._id && (
          <Button
            sx={{
              width : "fit-content",
              border: '1px solid black',
              color: 'black',
              border: 1,
              fontFamily: `Noto Kufi Arabic !important`,
              mt: '6px',
            }}
            onClick={handleRemoveUserReview}
          >
            {lang === 'en' ? 'remove my review' : 'حذف تقيمى'}
          </Button>
        )}
      </Stack>
    </Box>
    // <></>
  )
}
