import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMarkNotificationAsReadMutation } from '../../redux/apis/NotificationsApi'
const NotifyItem = ({ title, desc, createdAt, read, _id }) => {
  const [, { language: lng }] = useTranslation()
  const navigate = useNavigate()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()
  const customMoment = (time) => {
    const custom = moment(time).locale(lng).fromNow()
    return custom
  }
  const handleMarkAsRead = (_id) => {
    markNotificationAsRead({ id: _id, payload: { read: true } })
      .unwrap()
      .then((res) => {
        toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
      })
      .catch((e) => {
        toast.error(e[`error_${lng === 'en' ? 'en' : 'ar'}`])
      })
  }

  const MAX_WORDS = 5

  // Function to slice text if it contains more than MAX_WORDS
  const sliceText = (text) => {
    const words = text.split(' ')
    if (words.length > MAX_WORDS) {
      return '...' + words.slice(0, MAX_WORDS).join(' ')
    }
    return text
  }

  return (
    <Stack
      onClick={() => {
        localStorage.setItem('notifyId', _id)
        navigate(`/notifications`)
        {
          !read && handleMarkAsRead(_id)
        }
      }}
      direction={'row-reverse'}
      sx={{ width: '100%' }}
    >
      <Box width={'100%'}>
        <Typography textAlign={'end'} noWrap>
          {sliceText(desc)}
          {/* <span style={{ fontWeight: '400' }}>{`${desc}`}</span> */}
        </Typography>

        <Typography
          my={1}
          textAlign={'end'}
          sx={{ fontSize: '13px', color: '#9e8356' }}
        >{`${customMoment(createdAt)}`}</Typography>
      </Box>
    </Stack>
  )
}

export default NotifyItem
