import { Box, Grid, Stack, Typography } from '@mui/material'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import { SavedProductsStyles } from './SavedProducts.style.jsx'
import { useEffect, useState } from 'react'
import { useGetAllSavedProductsQuery } from '../../redux/apis/SavedProductApi.js'

import Card from '../singleProductPages/noon1/NewCard/index.jsx'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import AnimatedLoader from '../../components/animatedLoader/AnimatedLoader.jsx'
function AllProductsGrid({ cards }) {
  return (
    <>
      {cards?.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
          }}
        >
          {card}
        </Grid>
      ))}
    </>
  )
}
const EmptyFavorite = ({ lng }) => {
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      mt={10}
      sx={{ minHeight: '80vh' }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          bgcolor: '#9e8356',
          width: { xs: '200px', md: '250px' },
          height: { xs: '200px', md: '250px' },
          borderRadius: '50%',
          mb: 2,
        }}
      >
        <FaHeart fontSize={'4rem'} color="#000" />
      </Stack>
      <Typography sx={{ fontSize: '1.5rem' }} fontWeight={'bold'}>
        {lng === 'en' ? 'No Favorites Products Yet' : 'لا يوجد منتجات مفضله'}
      </Typography>
      <Typography variant="caption" color={'GrayText'}>
        {lng === 'en'
          ? 'Save your favorite products to find them easily'
          : 'قم بحفظ المنتجات المفضله لك لتجدها بسهوله'}
      </Typography>
      <Typography
        component={Link}
        to={'/departments'}
        sx={{
          color: '#ffff',
          bgcolor: '#000000',
          fontSize: { xs: '1.2rem', md: '1.3rem' },
          textDecoration: 'none',
          px: 7,
          py: 1.5,
          cursor: 'pointer',
          mt: 5,
        }}
      >
        {lng == 'en' ? 'Start Shopping' : 'ابدأ التسوق'}
      </Typography>
    </Stack>
  )
}
export const SavedProductsPage = (props) => {
  const [data, setData] = useState([])

  const [query, setQuery] = useState('')

  const [, { language: lng }] = useTranslation()
  //custom hook
  const {
    data: favPros,
    isLoading: favLoading,
    isSuccess,
  } = useGetAllSavedProductsQuery(query)
  const Styles = SavedProductsStyles({ props, lng })
  //custom hook
  useEffect(() => {
    setData(favPros?.data?.favourite)
  }, [favPros, favLoading])
   
  return (
    <Box>
      {isSuccess && data?.length !== 0 && (
        <>
          <Typography
            align={'center'}
            sx={{
              bgcolor: '#e0e0e0',
              py: 4,
              fontWeight: '600',
              fontSize: { xs: '2rem', lg: '2.6rem' },
              textTransform: 'capitalize',
            }}
          >
            {lng === 'en' ? 'Saved Products' : 'المنتجات المحفوظة'}
          </Typography>
          <Box sx={Styles.Box} direction={'column'}>
            <Stack direction={'column'} sx={Styles.StackDirection}>
              <Grid container mt={10} spacing={2}>
                <AllProductsGrid
                  cards={data?.map((pro, index) => (
                    <Card item={pro} key={pro.id} />
                  ))}
                />
              </Grid>
            </Stack>
          </Box>
        </>
      )}
      {favLoading &&  <AnimatedLoader/>}
      {(data?.length < 1 || data?.length === undefined) && (
        <EmptyFavorite lng={lng} />
      )}
    </Box>
  )
}
