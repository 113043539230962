import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { setCurrentUser } from '../../redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { useLazyGetMeQuery } from '../../redux/apis/UserApis'
import { Box } from '@mui/joy'
import { baseUrl } from '../../constants/baseUrl'
import AnimatedLoader from '../animatedLoader/AnimatedLoader'

const Google = () => {
  const [getMe] = useLazyGetMeQuery()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const name = useLocation().pathname.concat(useLocation().search)
  useEffect(() => {
    // fetch(`https://reusable-servieces.onrender.com/api/v1/${name}`)
    fetch(`${baseUrl}${name}`)
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem('token', res?.data?.token)
        dispatch(setCurrentUser(res?.data?.user))
        getMe()
        navigate('/')
      })

      .catch(() => {
        return <AnimatedLoader />
      })
  }, [dispatch, getMe, name, navigate])

  return <AnimatedLoader />
}

export default Google
