import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCreateGuestUserMutation } from '../../redux/apis/gestUserApi'
import { savedProductsApi } from '../../redux/apis/SavedProductApi'
import cartApi from '../../redux/apis/cartApi'
import { NotificationsApi } from '../../redux/apis/NotificationsApi'
import { userApi } from '../../redux/apis/UserApis'
import { Box, Button, Menu, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PersonIcon from '@mui/icons-material/Person'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LoginIcon from '@mui/icons-material/Login'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import LogoutIcon from '@mui/icons-material/Logout'
import Style from './Styles'
import { store } from '../../redux/Store'
import { toast } from 'react-toastify'
import { removeCurrentUser } from '../../redux/slices/userSlice'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { dashboardUrl } from '../../constants/baseUrl'
function ProfileButton(colors) {
  const { currentUser } = useSelector((state) => state)

  // const styleProfileMenu = profileMenu({ colors })
  const [, { language }] = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [createGuestUser] = useCreateGuestUserMutation()
  const open = Boolean(anchorEl)
  const handleLogout = () => {
    handleClose()
    localStorage.clear()
    store.dispatch(savedProductsApi.util.resetApiState())
    store.dispatch(cartApi.util.resetApiState())
    store.dispatch(NotificationsApi.util.resetApiState())
    store.dispatch(userApi.util.resetApiState())
    toast.success(language === 'en' ? 'You are logged out' : 'تم تسجيل الخروج')
    createGuestUser()
      .unwrap()
      .then((res) => {
        localStorage.setItem('token', res?.token)
      })
      .catch({})
    navigate('/')
    dispatch(removeCurrentUser())
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMenuItem = (item) => {
    item.name_en === 'Logout'
      ? handleLogout()
      : item?.name_en === 'dashboard'
      ? window.open(item.path)
      : navigate(item.path)
    handleClose()
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        sx={{ ...Style.profileMenu.Button, maxWidth: 0, mx: 1 }}
      >
        {open ? (
          <CloseIcon sx={Style.profileMenu.CloseIcon} />
        ) : (
          <PersonIcon sx={Style.profileMenu.PersonIcon} />
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={Style.profileMenu.Menu}
        MenuListProps={Style.profileMenu.MenuListProps}
        disableScrollLock={true}
      >
        {constants.ProfileMenuData.filter(({ name_en }) =>
          currentUser?.email || currentUser?.phone
            ? name_en !== 'Login' && name_en !== 'Register'
            : name_en !== 'Profile' && name_en !== 'Logout'
        ).map((item, index) => (
          <Box key={index} onClick={() => handleClickMenuItem(item)}>
            <ProfileMenuItem item={item} Style={Style} />
          </Box>
        ))}
        {currentUser &&
          ['rootAdmin', 'subAdmin', 'adminA', 'adminB', 'adminC'].includes(
            currentUser?.role
          ) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={Style.profileMenu.Stack}
              onClick={() => {
                window.open(dashboardUrl, '_blank')
                handleClose()
              }}
            >
              <DashboardIcon />
              <Typography>
                {language === 'en' ? 'Dashboard' : 'لوحة التحكم'}
              </Typography>
            </Stack>
          )}
      </Menu>
    </div>
  )
}

export default ProfileButton
const iconStyle = {
  color: '#fff !important',
  fontSize: '20px',
}
const constants = {
  ProfileMenuData: [
    {
      name_en: 'Login',
      name_ar: 'تسجيل الدخول',
      path: 'sign-in',
      icon: <LoginIcon sx={iconStyle} />,
    },
    {
      name_en: 'Register',
      name_ar: 'تسجيل حساب',
      path: 'register',
      icon: <AppRegistrationIcon sx={iconStyle} />,
    },
    {
      name_en: 'Profile',
      name_ar: 'الملف الشخصي',
      path: 'profile',
      icon: <AccountCircleIcon sx={iconStyle} />,
    },
    {
      name_en: 'Logout',
      name_ar: 'تسجيل خروج',
      path: '',
      icon: <LogoutIcon sx={iconStyle} />,
    },
  ],
}
const ProfileMenuItem = ({ item, Style }) => {
  const [, { language }] = useTranslation()
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        ...Style.profileMenu.Stack,
        mb: '5px',
      }}
    >
      {item.icon}
      <Typography sx={Style.profileMenu.Typography}>
        {language === 'en' ? item.name_en : item.name_ar}
      </Typography>
    </Stack>
  )
}
