import { Box, CardMedia, Stack, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavIcons from './NavIcons'
import { colors } from './Styles'
import { useTranslation } from 'react-i18next'
import Navigators from './Navigators'
import NavDrawer from './NavDrawer'
import { useTheme } from '@emotion/react'

const Navbar = ({ logoPath }) => {
  const route = useLocation()
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [route?.pathname])
  const [_, { language: lang }] = useTranslation()
  const { breakpoints } = useTheme()
  const phoneScreen = useMediaQuery(breakpoints.down('md'))
  const excludePages = ['/sign-in', '/register', '/thankYou', '/forgetPassword']
  const { pathname } = useLocation()
  return (
    <>
      <Box
        sx={{
          direction: lang === 'en' ? 'ltr' : 'rtl',
          display: excludePages.includes(pathname) ? 'none' : 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: '#000000',
          py: '10px',
          color: '#fff',
          // px: {
          //   lg: '30px',
          //   md: '20px',
          //   xs: '10px',
          // },
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: '4',
        }}
      >
        {!phoneScreen ? <Navigators /> : undefined}
        {/* <Stack direction={'row'} alignItems={'center'}> */}
        {phoneScreen && <NavDrawer />}
        <Link to={'/'}>
          <CardMedia
            component="img"
            src={logoPath}
            sx={{
              height: { xs: '70px', md: '100px' },
              width: { xs: '70px', md: '100px' },
              transform: {
                xl: `translateX(${lang === 'en' ? '-140px' : '105px'})`,
                lg: `translateX(${lang === 'en' ? '-90px' : '65px'})`,
                md: `translateX(${lang === 'en' ? '-65px' : '30px'})`,
                xs: 0,
              },
            }}
          />
        </Link>
        {/* </Stack> */}
        <NavIcons />
      </Box>
      {!excludePages.includes(pathname) && (
        <Box
          sx={{
            height: {
              lg: '120px',
              md: '100px',
            },
            bgcolor: '#000000',
          }}
        />
      )}
    </>
  )
}

export default Navbar
