import { Box, Typography, CardMedia, Stack } from '@mui/material'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { imageBaseUrl } from '../../../constants/baseUrl'
import moment from 'moment'
import { useGetAllBlogsQuery } from '../../../redux/apis/blogsApi'
import '../../../components/Home/About/AboutImgButton/styleCss.css'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader'
const BlogCard = ({ language, item }) => {
  return (
    <Box sx={styles.card} elevation={3}>
      <Box>
        <CardMedia
          component="img"
          src={imageBaseUrl + item.image}
          sx={{
            borderRadius: '10px',
            height: 250,
          }}
        />
        <Typography variant="overline" color="textSecondary">
          {moment(item.createdAt).fromNow()}
        </Typography>
        <Typography variant="h6" sx={styles.blogTitle}>
          {item.title}
        </Typography>
      </Box>

      <Box>
        {item.description?.length > 400 ? (
          <Box
            variant="body2"
            sx={styles.blogDesc}
            dangerouslySetInnerHTML={{
              __html: item.description?.slice(0, 400) + '...',
            }}
          />
        ) : (
          <Box
            variant="body2"
            sx={styles.blogDesc}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </Box>
      <Box sx={styles.seeMore}>
        <Link
          className="btn btn-fill-middle"
          to={`/blogs/${item._id}/${item.title.replace(/\s/g, '-')}`}
        >
          {language === 'en' ? 'See More' : 'شاهد المزيد'}
        </Link>
      </Box>
    </Box>
  )
}

const Blogs1 = () => {
  const [_, { language }] = useTranslation()
  const {
    data: dataBlogs,
    error: errorBlogs,
    isLoading,
  } = useGetAllBlogsQuery()
  return (
    <Box sx={styles.root}>
      <Typography
        // variant='h4'
        // gutterBottom
        align={'center'}
        sx={{
          bgcolor: '#e0e0e0',
          py: 4,
          mb: 5,
          fontWeight: '600',
          fontSize: { xs: '2rem', lg: '2.6rem' },
        }}
      >
        {language === 'en' ? 'Blogs' : 'مدوناتنا'}
      </Typography>
      <Box sx={styles.container}>
        {isLoading ? (
          <AnimatedLoader />
        ) : dataBlogs && !errorBlogs ? (
          <Stack direction={'row'} justifyContent={'center'} flexWrap={'wrap'}>
            {dataBlogs.data.map((item) => (
              <BlogCard language={language} item={item} />
            ))}
          </Stack>
        ) : (
          <Stack sx={styles.wrapper}>
            <Typography
              sx={{
                fontSize: '26px',
                color: 'red',
              }}
            >
              {errorBlogs?.data && errorBlogs.data[`error_${language}`]}
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  )
}
export default Blogs1
