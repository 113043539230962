import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Pagination,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import {
  AllProductsGridStyle,
  CategoriesStyle,
  filterMenuStyles,
} from './categoriespage.style.jsx'
import {
  useGetAllProductsByCategoryQuery,
  useGetProductsOfDepartmentQuery,
} from '../../../redux/apis/ProductApis.js'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './categoriespage.style.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import Card from '../../singleProductPages/noon1/NewCard/index.jsx'
import FilerAttributesLarge from '../../../components/filerAttributesLarge/FilerAttributesLarge.jsx'
import SortByMenu from '../../../components/SortByMenu/index.jsx'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader.jsx'

//===================================Products Grid=============================
function AllProductsGrid({ item, filteredQueries, sortBy }) {
  const id = item?.category.id
  const [pageNumber, setPageNumber] = useState(1)
  const { data, error, isLoading } = useGetProductsOfDepartmentQuery({
    id: `${id}?limit=24&page=${pageNumber}${filteredQueries}`,
    sorting: sortBy,
  })
  const totalPages = data?.paginationResult?.totalPages
  const handlPagination = (e, page) => {
    setPageNumber(page)
  }
  const [_, { language: lang }] = useTranslation()
  return (
    <>
      <Grid
        container
        spacing={{
          width: '100% !important',
          justifyContent: 'flex-start !important',
        }}
      >
        {isLoading ? (
         <></>
        ) : data && !error ? (
          data?.data?.map((card, index) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
              <Box
                sx={{
                  p: '20px',
                }}
              >
                <Card item={card} />
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                color: 'red',
                textAlign: 'center',
              }}
            >
              {lang === 'en' ? 'No products found' : 'لا توجد منتجات'}
            </Typography>
          </Grid>
        )}
      </Grid>
      {totalPages > 1 && !error && (
        <Box
          sx={{
            direction: 'ltr',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 5,
            width: '100%',
          }}
        >
          <Pagination
            count={totalPages}
            page={pageNumber}
            variant="outlined"
            shape="rounded"
            onChange={handlPagination}
            sx={{
              '& button': {
                width: '32px',
                height: '32px',
              },
            }}
          />
        </Box>
      )}
    </>
  )
}
//===================================productPage=============================
export const CategoriesPage = () => {
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  // sortBy
  const [sortBy, setSortBy] = useState('')
  const handleSortChange = (sortBy) => {
    setSortBy(sortBy)
  }
  const { data: allProducts, isLoading: allProductsLoading } =
    useGetAllProductsByCategoryQuery()

  const Styles = CategoriesStyle(lng)
  useEffect(() => {
    setData(allProducts?.data)
    setLoading(allProductsLoading)
  }, [allProducts, allProductsLoading, query])

  return (
    <Box
      sx={{
        width: 0.95,
        mx: 'auto',
        minHeight: '50vh',
        direction: lng === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: {
            md: '120px',
            xs: '90px',
          },
          zIndex: '2',
          py: '14px',
          width: `100%`,
          display: 'flex',
          justifyContent: {
            md: 'flex-end',
            xs: 'space-between',
          },
          bgcolor: '#fff',
        }}
      >
        <SortByMenu onSortChange={handleSortChange} />
        <Box
          sx={{
            display: {
              md: 'none',
              xs: 'block',
            },
          }}
        >
          <FilterAttributesMenu
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        </Box>
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        mb={4}
      >
        <Box
          width={0.2}
          sx={{
            pt: '40px',
            display: {
              md: 'block',
              xs: 'none',
            },
            position: 'sticky',
            top: '155px',
          }}
        >
          <FilerAttributesLarge
            setFilterQuery={setFilterQuery}
            filterQuery={filterQuery}
            colors={colors}
            filterMenuStyles={filterMenuStyles}
          />
        </Box>
        <Box
          width={{
            md: 0.78,
            xs: 1,
          }}
        >
          {!loading ? (
            data?.map((item, index) =>
              item?.products?.length > 0 ? (
                <>
                  <Typography
                    sx={{
                      ...Styles.Typography,
                      textAlign: 'center',
                      mb: '30px',
                      mt: index === 0 ? '120px' : 0,
                      bgcolor: '#E0E0E0',
                    }}
                    onClick={() =>
                      nav(
                        `/departments/${
                          item.category.id
                        }/${item?.category.name_en.replace(/\s/g, '')}`
                      )
                    }
                  >
                    {item.category[`name_${lng === 'en' ? 'en' : 'ar'}`]}
                  </Typography>

                  {/* stack of sort filterMenu in small screen */}
                  <Stack
                    sx={{
                      mt: '10px',
                      width: 1,
                    }}
                  >
                    <Stack
                      width={'100%'}
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent={'space-between'}
                    >
                      {/* filterMenu Large */}
                      <Box width={1}>
                        <AllProductsGrid
                          filteredQueries={`${
                            filterQuery ? `&${query}` : query
                          }${query ? `&${filterQuery}` : filterQuery}`}
                          sortBy={sortBy}
                          item={item}
                          cards={item?.products?.map((pro) => {
                            return <Card item={pro} />
                          })}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </>
              ) : null
            )
          ) : (
            <AnimatedLoader />
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography
              color={'error'}
              sx={{ m: 5, fontSize: '2rem', textAlign: 'center' }}
            >
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  )
}
//===================================productPage=============================

const GenerateAttributeData = () => {
  const [getAllAttributes] = useLazyGetAllAttributesQuery()
  const [attributesData, setAtts] = useState()
  useEffect(() => {
    getAllAttributes()
      .unwrap()
      .then((res) => {
        setAtts(res.data)
      })
      .catch((err) => {
        setAtts()
      })
  }, [])
  return {
    attributesData,
  }
}
function FilterAttributesMenu({ filterQuery, setFilterQuery }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lang }] = useTranslation()
  const { attributesData } = GenerateAttributeData()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    formik.resetForm()
    setExpanded(false)
  }
  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const manipulateQuery = () => {
    let query = ''
    let selAttsValues = selectedAtts
      .reduce((item, obj) => {
        return item.concat(obj.values)
      }, [])
      .map(({ value_en }) => value_en)
    selAttsValues.map((val) => (query += `attributes.values.value_en=${val}&`))
    if (priceState) {
      query += !query ? `` : '&'
      query += `priceBeforeDiscount[gte]=
      ${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    }
    return query
  }
  const [selectedAtts, setSelectedAtts] = useState([])
  const [priceState, setPriceState] = useState()
  const hundleAddAtt = (attribute, selectedValue, event) => {
    const existedAtt = selectedAtts.find(
      (item) => item.key_en === attribute.key_en
    )
    const { checked, value } = event.target
    if (checked) {
      existedAtt
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === attribute.key_en
                ? {
                    key_en: attribute.key_en,
                    key_ar: attribute.key_ar,
                    values: [...existedAtt.values, { ...selectedValue }],
                  }
                : item
            )
          )
        : setSelectedAtts([
            ...selectedAtts,
            {
              key_en: attribute.key_en,
              value_en: attribute.key_ar,
              values: [selectedValue],
            },
          ])
    } else {
      existedAtt?.values?.length > 1
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === existedAtt.key_en
                ? {
                    ...existedAtt,
                    values: existedAtt?.values.filter(
                      (val) => val.value_en !== value
                    ),
                  }
                : item
            )
          )
        : setSelectedAtts(
            selectedAtts.filter((sel) => sel.key_en !== existedAtt?.key_en)
          )
    }
  }
  useEffect(() => {
    setFilterQuery(manipulateQuery())
  }, [selectedAtts, priceState])
  const formik = useFormik({
    initialValues: { from: '', to: '' },
    validationSchema: object({
      from: string().required(),
      to: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
       
      setPriceState(values)
      let submitQueries = `priceBeforeDiscount[gte]=${values.from}&priceBeforeDiscount[lte]=${values.to}`
      setFilterQuery(query ? `${query}&${submitQueries}` : submitQueries)
      resetForm()
    },
  })
  const refetchAllProducts = () => {
    setFilterQuery('')
    setSelectedAtts([])
    setPriceState()
  }
  const clearPriceFilter = () => {
    let priceQueries = `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    setFilterQuery(filterQuery.replace(priceQueries, ''))
    setTimeout(() => {
      setPriceState()
    }, 240)
  }
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      sx={{
        width: '100%',
        mx: 'auto',
        gap: '10px',
      }}
    >
      <Box>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={
            <FilterAltOutlinedIcon
              sx={{
                color: `#333 !important`,
              }}
            />
          }
        >
          <Typography
            sx={{
              ...filterMenuStyles.menuButton,
              color: '#333',
            }}
          >
            {lang === 'en' ? 'filter by' : 'تصفية حسب'}
          </Typography>
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            maxHeight: 600,
            p: 0,
            direction: lang === 'en' ? 'ltr' : 'rtl',
          }}
        >
          <Accordion
            expanded={expanded === 'price'}
            onChange={handleChange('price')}
            sx={{
              m: '0 !important',
            }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                justifyContent: 'center',
              }}
            >
              <Typography sx={filterMenuStyles.priceBtn}>
                {lang === 'en' ? 'Price' : 'السعر'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: 1,
                borderColor: 'divider',
                overflow: 'hidden',
              }}
            >
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 200,
                  }}
                >
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'From' : 'من'}
                    </Typography>
                    <InputBase
                      name="from"
                      type="number"
                      value={formik.values.from}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.from && formik.touched.from
                            ? 'red'
                            : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'To' : 'إلي'}
                    </Typography>
                    <InputBase
                      type="number"
                      name="to"
                      value={formik.values.to}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.to && formik.touched.to ? 'red' : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                </Stack>

                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Button
                    disabled={priceState}
                    type="submit"
                    sx={filterMenuStyles.formPriceBtn}
                  >
                    {lang === 'en' ? 'Search by price' : 'بحث بالسعر'}
                  </Button>
                  {priceState ? (
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={clearPriceFilter}
                    />
                  ) : null}
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>
          {attributesData?.length > 0 &&
            attributesData?.map((attribute) => (
              <Accordion
                expanded={expanded === attribute.key_en}
                onChange={handleChange(attribute.key_en)}
                sx={{
                  m: '0 !important',
                }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {selectedAtts.includes((item) =>
                      item.key_en == attribute.key_en ? (
                        <CheckBoxIcon
                          sx={{
                            color: 'blue',
                            fontSize: '20px',
                          }}
                        />
                      ) : undefined
                    )}
                    <Typography sx={filterMenuStyles.checkBoxLabel}>
                      {attribute[`key_${lang}`]}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    overflow: 'hidden',
                  }}
                >
                  {attribute.values?.map((value) => (
                    <Stack sx={filterMenuStyles.checkBoxContainer}>
                      <input
                        type="checkbox"
                        id={value.value_en}
                        value={value.value_en}
                        checked={selectedAtts.find(
                          (item) =>
                            item.key_en === attribute.key_en &&
                            item.values.find(
                              ({ value_en }) => value_en === value.value_en
                            )
                        )}
                        style={{
                          accentColor: '#363636',
                        }}
                        onChange={(event) =>
                          hundleAddAtt(attribute, value, event)
                        }
                      />
                      <Typography
                        component="label"
                        htmlFor={value.value_en}
                        sx={filterMenuStyles.checkBoxLabel}
                      >
                        {value[`value_${lang}`]}
                      </Typography>
                    </Stack>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Menu>
      </Box>
      {selectedAtts?.length > 0 || filterQuery ? (
        <RefreshIcon
          sx={{
            color: colors.filter.checkBoxLabel,
            cursor: 'pointer',
          }}
          onClick={refetchAllProducts}
        />
      ) : undefined}
    </Stack>
  )
}
