import { useState } from 'react'
import CategoryIcon from '@mui/icons-material/Category'
import { Box, Button, Drawer, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import Style from './Styles'
import { useFetchCategoriesWithSubs } from './nav.hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavCategoryMenu } from './NavCategoryMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { CountriesSelect } from './NavIcons'
import Notifications from '../../components/Notifications/Notifications'
const NavDrawer = () => {
  const [_, { language: lng }] = useTranslation()
  const [open, setOpen] = useState(false)
  const handleDrawerToggle = () => setOpen(!open)
  const { categoriesWithSubs } = useFetchCategoriesWithSubs()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        direction: lng === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Button onClick={handleDrawerToggle}>
        <MenuIcon
          sx={{
            color: '#fff',
          }}
        />
      </Button>
      <Drawer
        variant="temporary"
        anchor={lng === 'en' ? 'left' : 'right'}
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          ...Style.navDrawer.Drawer,
          alignItems: 'flex-start',
          '.MuiPaper-elevation': {
            bgcolor: '#000',
          },
          direction: lng === 'en' ? 'ltr' : 'rtl',
        }}
      >
        <Stack
          direction={'column'}
          alignItems={'center'}
          sx={{
            position: 'relative',
          }}
        >
          <Box sx={{ ...Style.navDrawer.Box }}>
            <Stack
              sx={{
                top: '-46px',
                position: 'absolute',
                right: '0px',
              }}
              px={2}
            >
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  color: '#fff',
                }}
                onClick={handleDrawerToggle}
              />
            </Stack>

            <Box
              sx={{
                direction: lng === 'en' ? 'ltr' : 'rtl',
              }}
            >
              {categoriesWithSubs.data.map((item, index) =>
                !item.subCategories[0] ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    key={index}
                    mb={'15px'}
                  >
                    <Button
                      sx={{
                        color: pathname.includes(
                          `/departments/${item.category._id}`
                        )
                          ? '#ffff'
                          : '#fff',
                        '&:hover': {
                          color: '#ffff',
                        },
                      }}
                      onClick={() => {
                        navigate(
                          `/departments/${item.category._id}/${item.category.name_en}`
                        )
                        handleDrawerToggle()
                      }}
                    >
                      {item.category[`name_${lng}`]}
                    </Button>
                  </Stack>
                ) : (
                  <>
                    (
                    <NavCategoryMenu
                      item={item}
                      handleDrawerToggle={handleDrawerToggle}
                    />
                    )
                  </>
                )
              )}
              <Stack
                direction={'row'}
                justifyContent={{ md: 'center', xs: 'flex-start' }}
                mb={'15px'}
              >
                <Button
                  onClick={() => {
                    navigate(`/products/newest`)
                    handleDrawerToggle()
                  }}
                  sx={{
                    color: pathname.includes(`/products/newest`)
                      ? '#ffff'
                      : '#fff',
                    position: 'relative !important',
                    '&:before , &:after': {
                      position: 'absolute',
                      display: 'block',
                      bottom: 0,
                      content: `""`,
                      height: '3px',
                      width: 0,
                      backgroundColor: '#ffff',
                    },

                    '&:hover': {
                      color: '#ffff',
                    },
                  }}
                >
                  {lng === 'en' ? 'Newest' : 'الأحدث'}
                </Button>
              </Stack>
              <Stack
                direction={'row'}
                justifyContent={{ md: 'center', xs: 'flex-start' }}
              >
                <Button
                  onClick={() => {
                    navigate(`/products/sales`)
                    handleDrawerToggle()
                  }}
                  sx={{
                    color: pathname.includes(`/products/sales`)
                      ? '#ffff'
                      : '#fff',
                    position: 'relative !important',
                    '&:before , &:after': {
                      position: 'absolute',
                      display: 'block',
                      bottom: 0,
                      content: `""`,
                      height: '3px',
                      width: 0,
                      backgroundColor: '#fff',
                    },
                    '&:hover': {
                      color: '#fff',
                    },
                  }}
                >
                  {lng === 'en' ? 'Most sellers' : 'الأكثر مبيعاً'}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{
            display: { xs: 'block', md: 'none' },
            textAlign: 'center',
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            // textAlign: 'center',
            mt: 2,
            justifyContent: 'center',
            '& .css-7wjufr-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root #demo-simple-select':
              {
                ' justify-content': ' space-between !important',
              },
          }}
        >
          <Notifications
            lng={lng}
            iconColor={'#fff'}
            bgColorBtn={'transparent'}
          />
          <Box
            sx={{
              margin: '0px 20px',
            }}
          >
            <CountriesSelect />
          </Box>
        </Box>
      </Drawer>
    </Box>
  )
}

export default NavDrawer
