import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

const SelectMenu = ({
  name,
  value,
  options,
  label,
  text,
  error,
  touched,
  field,
  handleChange,
  handleBlur,
  optionValue,
}) => {
  const [_, { language }] = useTranslation()
  return (
    <FormControl
      id={'selectData'}
      sx={{
        mt: '30px',
        width: 1,
        svg: {
          color: `#fff !important`,
        },
      }}
    >
      {' '}
      <Typography
        sx={{
          fontSize: '25px',
        }}
      >
        {label}
      </Typography>
      <Box
        position={'relative'}
        id={'selectData'}
        sx={{
          width: 1,
          height: 60,
        }}
      >
        <Select
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '450px',
              },
            },
          }}
          sx={{
            width: 1,
            border: `2px solid #000`,
            height: 60,
            bgcolor: 'transparent',
            color: '#fff !important',
            fontWeight: 'bold',
            mt: '20px',
          }}
        >
          {/* {options.length > 0 ? <MenuItem value={''}>{text}</MenuItem> : null} */}
          {options.map((option) => (
            <MenuItem
              sx={{
                justifyContent: language === 'en' ? 'flex-start' : 'flex-end',
              }}
              value={option[optionValue]}
            >
              {option[field]}
            </MenuItem>
          ))}
        </Select>
        {value && (
          <Typography
            right={'20px'}
            sx={{
              position: 'absolute',
              top: '31px',
              right: language === 'ar' ? '20px' : null,
              left: language === 'en' ? '20px' : null,
              pointerEvents: 'none',
              fontSize: '20px',
            }}
          >
            {options?.find((item) => item?.[optionValue] === value)?.[field] ||
              ''}
          </Typography>
        )}
      </Box>
      {error && touched && (
        <Typography
          sx={{
            color: 'red',
            mt: '20px',
          }}
        >
          {error}
        </Typography>
      )}
    </FormControl>
  )
}

export default SelectMenu
