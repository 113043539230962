import { mainColors } from "../../../constants/colors"
export const colors = {
  header: mainColors.primary,
  title:mainColors.primary,
  remove: mainColors.primary,
  productsBg: mainColors.white,
  priceBefore: mainColors.primary,
  priceAfter: mainColors.primary,
  button: 'white',
  buttonBg: mainColors.primary,
  orderSummary: '#707070',
  orderSummaryBg: '#f1f7f6',
  orderSummaryBody: '#544e5e',
  quantity: '#707070',
  desc: '#707070',
  mainBgColor:mainColors.gray2,
  secondBgColor:mainColors.white,
}
