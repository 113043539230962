import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { GrSort } from 'react-icons/gr'
import TuneIcon from '@mui/icons-material/Tune'
import { useTranslation } from 'react-i18next'
import { Fade } from '@mui/material'
export default function SortByMenu({ onSortChange }) {
  const [_, { language: lang }] = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSortChange = (sortBy) => {
    onSortChange(sortBy)
    handleClose()
  }
  const filterTitles = [
    {
      text_en: 'Newest Arrivals',
      text_ar: 'ألاحدث وصولا',
      sortBy: '-createdAt',
    },
    { text_en: 'Best Sellers', text_ar: 'الأكثر مبيعا', sortBy: '-sales' },
    {
      text_en: 'Price: High to Low',
      text_ar: 'السعر: من الأعلى إلى الأقل',
      sortBy: '-priceBeforeDiscount ',
    },
    {
      text_en: 'Price: Low to High',
      text_ar: 'السعر: من الأقل إلى الأعلى',
      sortBy: 'priceBeforeDiscount ',
    },
  ]
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <TuneIcon />
        {lang === 'en' ? 'Sort By' : 'ترتيب حسب'}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        TransitionComponent={Fade}
        disableScrollLock={true}
        sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}
      >
        {filterTitles.map((item,idx) => (
          <MenuItem key={idx} onClick={() => handleSortChange(item.sortBy)}>
            {item[`text_${lang}`]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
