import { Navigate, Route, Routes } from 'react-router-dom'
import HomePage from '../pages/home/Index'
import authBackground from '../assets2/Auth.png'
import Login1logo from '../assets2/LOGO DRESS CODE-02.png'
import { CategoriesPage } from '../pages/shop/categoriespage'
import { CategoryPage } from './../pages/shop/Category/index'
import { SubCategoriePage } from '../pages/shop/subcategory'
import ContactNoon6 from '../pages/ContactUs/Noon6/ContactNoon6'
import Register3 from '../pages/Register/Register3/Register3.jsx'
import Login3 from '../pages/Login/Login3/Login3'
import ForgetPassword from './../pages/ForgetPassword/ForgetPassword'
import Google from '../components/google/Google'
import { SavedProductsPage } from './../pages/savedProducts/index'

import { NoonSingleProduct } from '../pages/singleProductPages/noon1/index.jsx'
import PaymentMoyasar from '../pages/Payment/PaymentMoyasar/PaymentMoyasar.jsx';
import SelectPayment from '../pages/Payment/SelectPayment/SelectPayment.jsx';
import ThanksPage from '../pages/ThanksPage/ThanksPage.jsx'

import { NoImage } from './../pages/privacies/NoImage/index'

import CheckOutTomoor1 from '../pages/PaymentPage/Tomoor1/CheckOutTomoor1'
import { useSelector } from 'react-redux'
import ProtectedRoutes from './ProtectedRoutes'

import NotificationsPage from '../pages/Notifications/index.jsx'
import BlogsPage from '../pages/blogs/blogs1/Blogs1.jsx'
import SingleBlog1 from '../pages/singleBlog/singleBlog1/SingleBlog1.jsx'
import { Cart03 } from './../pages/cart/cart3/Cart03'
import Profile1 from '../pages/profile_2/profile1/Profile1.jsx'
import About3 from '../pages/about/about3/About3.jsx'
import { SortedProductsPage } from '../pages/shop/sortedProductsPage/index.jsx'
import { SubCategorySubSub } from '../pages/shop/SubCategorySubSub/index.jsx'
import { AllSubSubs, AllsubSubs } from '../pages/shop/AllsubSubs/index.jsx'
import { SubSubCategoryById } from '../pages/shop/SubSubCategoryById/index.jsx'

import ThanksPageTabby from '../pages/ThanksPage/ThanksPageTabby.jsx';
import ThanksPageTamara  from '../pages/ThanksPage/ThanksPageTamara.jsx';

const AppRoutes = ({ isCartDrawer }) => {
  const { currentUser } = useSelector((state) => state)
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/products/:id/:name" element={<NoonSingleProduct />} />
      <Route
        element={
          <ProtectedRoutes
            condition={
              !currentUser ||
              (typeof currentUser === 'object' &&
                Object.keys(currentUser).length === 0)
            }
          />
        }
      >
        <Route
          path="/sign-in"
          element={
            <Login3 backgroundImage={authBackground} logo={Login1logo} />
          }
        />
        <Route
          path="/register"
          element={
            <Register3 backgroundImage={authBackground} logo={Login1logo} />
          }
        />
      </Route>

      <Route
        element={
          <ProtectedRoutes
            condition={currentUser && Object.keys(currentUser).length > 0}
          />
        }
      >
        <Route path="/profile" element={<Profile1 />} />
        <Route path="/checkout" element={<CheckOutTomoor1 />} />
      </Route>
      <Route
        path="/contactUs"
        element={<ContactNoon6 backgroundImag={authBackground} />}
      />
      <Route path="/aboutus" element={<About3 />} />
      <Route path="/notifications" element={<NotificationsPage />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/departments" element={<CategoriesPage />} />
      <Route path="/departments/:categoryId/:name" element={<CategoryPage />} />
      <Route path="/products/:sortedParam" element={<SortedProductsPage />} />
      <Route path="/auth/google/callback" element={<Google />} />
      {!isCartDrawer && <Route path="/cart" element={<Cart03 />} />}
      <Route
        path="/departments/:categoryId/:subId/:name"
        element={<SubCategoriePage />}
      />
      {/* Specific Sub Sub for Sub Category */}
      <Route
        path="/departments/:categoryId/:subId/:subSubId/:subSubName"
        element={<SubCategorySubSub />}
      />
      {/* All Sub Subs for Sub Category */}
      <Route path="/subSubCategories" element={<AllSubSubs />} />
      <Route
        path="/subSubCategories/:subSubId/:subName"
        element={<SubSubCategoryById />}
      />
      <Route path="/savedProducts" element={<SavedProductsPage />} />

      <Route path="/select-payment" element={<SelectPayment />} />
      <Route path="/payment-moyasar" element={<PaymentMoyasar />} />
      <Route path="/thankYou" element={<ThanksPage />} />

      <Route path="/tabbyPayment/:type" element={<ThanksPageTabby />} />
      {/* <Route path="/cancel" element={<ThanksPageTabby />} />
      <Route path="/failure" element={<ThanksPageTabby />} /> */}

      <Route path="/tamaraPayment/:type" element={<ThanksPageTamara />} />
      {/* <Route path="/cancel" element={<ThanksPageTamara />} />
      <Route path="/fail" element={<ThanksPageTamara />} /> */}

      <Route path="/policies/:policyType" element={<NoImage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="blogs/:blogId/:blogName" element={<SingleBlog1 />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Routes>
  )
}

export default AppRoutes
