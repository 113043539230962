import { mainColors } from "../../constants/colors"
export const ThanksStyle={
    color:{
        titleColor: mainColors.primary,
        subTitleColor: mainColors.primary,
        Btn: {
          textColor: 'white',
          bgColor:  mainColors.primary,
        },
      }
}