import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../constants/baseUrl";
export const socialApi = createApi({
  reducerPath: "socialApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Social"],
  endpoints: (builder) => ({
    getAllSocial: builder.query({
      query: (params) => `/socials${params ? params : ""}`,
      providesTags: ["Social"],
    }),

    createSocial: builder.mutation({
      query: (payload) => ({
        url: `/socials`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: ["Social"],
    }),

    updateSocial: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/socials/${id}`,
        body: payload,
        method: "PUT",
      }),
      invalidatesTags: ["Social"],
    }),
  }),
});
export const {
  useCreateSocialMutation,
  useGetAllSocialQuery,
  useUpdateSocialMutation,
} = socialApi;
