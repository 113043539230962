import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Menu, MenuItem, Stack } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { NavSubSubMenu } from './NavSubSubMenu'
export function NavCategoryMenu({ item, handleDrawerToggle }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lng }] = useTranslation()
  const { pathname } = useLocation()
  const open = Boolean(anchorEl)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const navigate = useNavigate()
  const { category, subCategories } = item
  const HandleNavigate = (url) => {
    handleClose()
    navigate(url)
    if (handleDrawerToggle) {
      handleDrawerToggle()
    }
  }
  return (
    <Stack
      direction={'row'}
      justifyContent={{
        md: 'center',
        xs: 'flex-start',
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: pathname.includes(category._id) ? '#A1875B' : '#fff',
          mb: 0,
          '&:hover': {
            color: '#A1875B',
          },
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'3px'}>
          <span title={category[`name_${lng}`]}>
            {category[`name_${lng === 'en' ? 'en' : 'ar'}`]}
          </span>
          <ArrowDropDownIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotatex(0)',
            }}
          />
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {subCategories.map((el) =>
          el.subSubCategory[0] ? (
            <NavSubSubMenu
              item={el}
              categoryItem={category}
              handleCloseSubMenu={handleClose}
              handleDrawerToggle={handleDrawerToggle}
            />
          ) : (
            <MenuItem
              key={el?.subCategory?._id}
              sx={{
                color: '#000',
                direction: lng === 'en' ? 'ltr' : 'rtl',
                justifyContent: 'flex-start !important',
                width: 1,
              }}
              onClick={() =>
                HandleNavigate(
                  `/departments/${category?.id}/${
                    el?.subCategory?.id
                  }/${el?.subCategory?.name_en.replace(/\s+/g, '-')}`
                )
              }
            >
              {el?.subCategory[`name_${lng}`]}
            </MenuItem>
          )
        )}
        {subCategories[0] && (
          <MenuItem
            sx={{
              direction: lng === 'en' ? 'ltr' : 'rtl',
              justifyContent: 'flex-start !important',
            }}
            onClick={() =>
              HandleNavigate(
                `/departments/${category._id}/${category.name_en.replace(
                  /\s+/g,
                  '-'
                )}`
              )
            }
          >
            {lng === 'en' ? 'All Sub categories' : 'جميع الأقسام الفرعية'}
          </MenuItem>
        )}
      </Menu>
    </Stack>
  )
}
