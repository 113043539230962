import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Pagination,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AllProductsGridStyles,
  DepertmentStyles,
  SearchStyles,
} from './sortedProducts.style.jsx'
import { CiSearch } from 'react-icons/ci'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis.js'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './sortedProducts.style.jsx'
import { filterMenuStyles } from './sortedProducts.style.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import MetaTags from '../../../components/metaTags/MetaTags.jsx'
import CloseIcon from '@mui/icons-material/Close'
import { useLazyGetCategoryDetailsQuery } from '../../../redux/apis/categoriesApi.js'
import Card from '../../singleProductPages/noon1/NewCard/index.jsx'
import FilerAttributesLarge from '../../../components/filerAttributesLarge/FilerAttributesLarge.jsx'
import { useSortProducts } from './sorted.hooks.js'
import AnimatedLoader from '../../../components/animatedLoader/AnimatedLoader.jsx'

function AllProductsGrid({ cards }) {
  return (
    <Grid
      container
      spacing={AllProductsGridStyles.GridContainer}
      width={'100%'}
    >
      {cards?.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          key={index}
          sx={AllProductsGridStyles.CardItem}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
// ============FilterSearch=========================

// ============================   HookCategory      ====================
export const SortedProductsPage = (props) => {
  const { categoryId } = useParams()
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  const { sortedParam } = useParams()
  const {
    sortedProducts,
    isLoading,
    isError,
    error,
    loading,
    params,
    paginationData,
    setSearchParams,
  } = useSortProducts(
    sortedParam === 'newest' ? 'createdAt' : 'sales',
    `${filterQuery ? `&${query}` : query}${
      query ? `&${filterQuery}` : filterQuery
    }`
  )

  const { totalPages, page } = paginationData

  const handlPagination = (e, page) => {
    params.set('page', page)
    setSearchParams(params)
  }
  if (isLoading) { 
    return <AnimatedLoader />
  }

  return (
    <Box>
      <MetaTags id={categoryId} />
      <Typography
        align={'center'}
        sx={{
          bgcolor: '#e0e0e0',
          py: 4,
          mb: 5,
          fontWeight: '600',
          fontSize: { xs: '2rem', lg: '2.6rem' },
        }}
      >
        {sortedParam === 'newest'
          ? lng === 'en'
            ? 'Newest products'
            : 'المنتجات الأحدث'
          : lng === 'en'
          ? 'Most sellers products'
          : 'المنتجات الأكثر مبيعا'}
      </Typography>
      <Stack
        mx={{ xs: 2, md: 5 }}
        my={5}
        direction={{ xs: 'column', md: 'column' }}
        alignItems={'flex-start'}
        spacing={4}
      >
        <>
          {/* stack of sort filterMenu in small screen */}
          <Box
            sx={{
              width: '100%',
              display: {
                md: 'none',
                xs: 'block',
              },
              position: 'sticky',
              top: '90px',
              zIndex: 10,
              backgroundColor: '#fff',
            }}
          >
            <FilterAttributesMenu
              setFilterQuery={setFilterQuery}
              filterQuery={filterQuery}
            />
          </Box>

          <Stack
            width={'100%'}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
          >
            <Box flex={'0 0 80%'}>
              {!loading ? (
                <AllProductsGrid
                  cards={sortedProducts?.map((pro, index) => (
                    <Card item={pro} />
                  ))}
                />
              ) : (
                <AnimatedLoader />
              )}
            </Box>
            <Box
              flex={'0 0 17%'}
              sx={{
                display: {
                  md: 'block',
                  xs: 'none',
                },
              }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: '155px',
                }}
              >
                <FilerAttributesLarge
                  setFilterQuery={setFilterQuery}
                  filterQuery={filterQuery}
                  colors={colors}
                  filterMenuStyles={filterMenuStyles}
                />
              </Box>
            </Box>
          </Stack>
          {totalPages > 1 && !error && (
            <Box
              sx={{
                direction: 'ltr',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,
                width: '100%',
              }}
            >
              <Pagination
                count={totalPages}
                page={Number(params.get('page' || 1))}
                variant="outlined"
                shape="rounded"
                onChange={handlPagination}
                sx={{
                  '& button': {
                    width: '32px',
                    height: '32px',
                  },
                }}
              />
            </Box>
          )}
        </>

        {!loading &&
          (sortedProducts?.length < 1 ||
            sortedProducts?.length === undefined) && (
            <Typography
              color={'error'}
              sx={{
                minHeight: '40vh',
                width: '100%',
                fontSize: '2rem',
                textAlign: 'center',
              }}
            >
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
              {/* {error?.data[`error_${lng}`]} */}
            </Typography>
          )}
        {/* not fo */}
      </Stack>
    </Box>
  )
}
function FilterAttributesMenu({ filterQuery, setFilterQuery }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [_, { language: lang }] = useTranslation()
  const { attributesData } = GenerateAttributeData()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    formik.resetForm()
    setExpanded(false)
  }
  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const manipulateQuery = () => {
    let query = ''
    let selAttsValues = selectedAtts
      .reduce((item, obj) => {
        return item.concat(obj.values)
      }, [])
      .map(({ value_en }) => value_en)
    selAttsValues.map((val) => (query += `attributes.values.value_en=${val}&`))
    if (priceState) {
      query += !query ? `` : '&'
      query += `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    }
    return query
  }

  const [selectedAtts, setSelectedAtts] = useState([])
  const [priceState, setPriceState] = useState()
  const hundleAddAtt = (attribute, selectedValue, event) => {
    const existedAtt = selectedAtts.find(
      (item) => item.key_en === attribute.key_en
    )
    const { checked, value } = event.target
    if (checked) {
      existedAtt
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === attribute.key_en
                ? {
                    key_en: attribute.key_en,
                    key_ar: attribute.key_ar,
                    values: [...existedAtt.values, { ...selectedValue }],
                  }
                : item
            )
          )
        : setSelectedAtts([
            ...selectedAtts,
            {
              key_en: attribute.key_en,
              value_en: attribute.key_ar,
              values: [selectedValue],
            },
          ])
    } else {
      existedAtt?.values?.length > 1
        ? setSelectedAtts(
            selectedAtts.map((item) =>
              item.key_en === existedAtt.key_en
                ? {
                    ...existedAtt,
                    values: existedAtt?.values.filter(
                      (val) => val.value_en !== value
                    ),
                  }
                : item
            )
          )
        : setSelectedAtts(
            selectedAtts.filter((sel) => sel.key_en !== existedAtt?.key_en)
          )
    }
  }
  useEffect(() => {
    setFilterQuery(manipulateQuery())
  }, [selectedAtts, priceState])
  const formik = useFormik({
    initialValues: { from: '', to: '' },
    validationSchema: object({
      from: string().required(),
      to: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      setPriceState(values)
      let submitQueries = `priceBeforeDiscount[gte]=${values.from}&priceBeforeDiscount[lte]=${values.to}`
      setFilterQuery(query ? `${query}&${submitQueries}` : submitQueries)
      resetForm()
    },
  })
  const refetchAllProducts = () => {
    setFilterQuery('')
    setSelectedAtts([])
    setPriceState()
  }
  const clearPriceFilter = () => {
    let priceQueries = `priceBeforeDiscount[gte]=${priceState.from}&priceBeforeDiscount[lte]=${priceState.to}`
    setFilterQuery(filterQuery.replace(priceQueries, ''))
    setTimeout(() => {
      setPriceState()
    }, 240)
  }
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      sx={{
        width: 0.8,
        mx: 'auto',
        mt: '30px',
        gap: '10px',
      }}
    >
      <Box>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={
            <FilterAltOutlinedIcon
              sx={{
                color: `#333 !important`,
              }}
            />
          }
        >
          <Typography
            sx={{
              ...filterMenuStyles.menuButton,
              color: '#333',
            }}
          >
            {lang === 'en' ? 'filter by' : 'تصفية حسب'}
          </Typography>
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            maxHeight: 600,
            p: 0,
            direction: lang === 'en' ? 'ltr' : 'rtl',
          }}
        >
          {attributesData?.length > 0 &&
            attributesData?.map((attribute) => (
              <Accordion
                expanded={expanded === attribute.key_en}
                onChange={handleChange(attribute.key_en)}
                sx={{
                  m: '0 !important',
                }}
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    {selectedAtts.includes((item) =>
                      item.key_en == attribute.key_en ? (
                        <CheckBoxIcon
                          sx={{
                            color: 'blue',
                            fontSize: '20px',
                          }}
                        />
                      ) : undefined
                    )}
                    <Typography sx={filterMenuStyles.checkBoxLabel}>
                      {attribute[`key_${lang}`]}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    overflow: 'hidden',
                  }}
                >
                  {attribute.values?.map((value) => (
                    <Stack sx={filterMenuStyles.checkBoxContainer}>
                      <input
                        type="checkbox"
                        id={value.value_en}
                        value={value.value_en}
                        checked={selectedAtts.find(
                          (item) =>
                            item.key_en === attribute.key_en &&
                            item.values.find(
                              ({ value_en }) => value_en === value.value_en
                            )
                        )}
                        style={{
                          accentColor: 'blue',
                        }}
                        onChange={(event) =>
                          hundleAddAtt(attribute, value, event)
                        }
                      />
                      <Typography
                        component="label"
                        htmlFor={value.value_en}
                        sx={filterMenuStyles.checkBoxLabel}
                      >
                        {value[`value_${lang}`]}
                      </Typography>
                    </Stack>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          <Accordion
            expanded={expanded === 'price'}
            onChange={handleChange('price')}
            sx={{
              m: '0 !important',
            }}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                justifyContent: 'center',
              }}
            >
              <Typography sx={filterMenuStyles.priceBtn}>
                {lang === 'en' ? 'Price' : 'السعر'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                border: 1,
                borderColor: 'divider',
                overflow: 'hidden',
              }}
            >
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 200,
                  }}
                >
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'From' : 'من'}
                    </Typography>
                    <InputBase
                      name="from"
                      type="number"
                      value={formik.values.from}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.from && formik.touched.from
                            ? 'red'
                            : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 0.45,
                    }}
                  >
                    <Typography
                      sx={{
                        ...filterMenuStyles.checkBoxLabel,
                        fontSize: '12px',
                      }}
                    >
                      {lang === 'en' ? 'To' : 'إلي'}
                    </Typography>
                    <InputBase
                      type="number"
                      name="to"
                      value={formik.values.to}
                      onChange={formik.handleChange}
                      disabled={priceState}
                      sx={{
                        border: `1px solid ${
                          formik.errors.to && formik.touched.to ? 'red' : '#ddd'
                        } `,
                      }}
                    />
                  </Box>
                </Stack>

                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Button
                    disabled={priceState}
                    type="submit"
                    sx={filterMenuStyles.formPriceBtn}
                  >
                    {lang === 'en' ? 'Search by price' : 'بحث بالسعر'}
                  </Button>
                  {priceState ? (
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={clearPriceFilter}
                    />
                  ) : null}
                </Stack>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Menu>
      </Box>
      {selectedAtts?.length > 0 || filterQuery ? (
        <RefreshIcon
          sx={{
            color: colors.filter.checkBoxLabel,
            cursor: 'pointer',
          }}
          onClick={refetchAllProducts}
        />
      ) : undefined}
    </Stack>
  )
}

const GenerateAttributeData = () => {
  const [getAllAttributes] = useLazyGetAllAttributesQuery()
  const [attributesData, setAtts] = useState()
  useEffect(() => {
    getAllAttributes()
      .unwrap()
      .then((res) => {
        setAtts(res.data)
      })
      .catch((err) => {
        setAtts()
      })
  }, [])
  return {
    attributesData,
  }
}
