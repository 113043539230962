import { useState, useEffect, useRef } from 'react'
import { Tab, Tabs, Box, CardMedia, Typography, Skeleton } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import './style.css'
import { useTranslation } from 'react-i18next'

import { imageBaseUrl } from '../../../../constants/baseUrl'
import { useLazyGetAllCategoriesQuery } from '../../../../redux/apis/categoriesApi'
import {
  useGetAllProductsByCategoryQuery,
  useGetProductsOfCategoryQuery,
} from '../../../../redux/apis/ProductApis'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import AnimatedLoader from '../../../animatedLoader/AnimatedLoader'

const ErrorSection = ({ error }) => {
  const [, { language: lang }] = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color="error"
      >
        {error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}
const CategoryTabs = ({ categories, selectedCategory, onChange, lang }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        mb: 5,
      }}
    >
      <Tabs
        value={selectedCategory ? categories.indexOf(selectedCategory) : 0}
        onChange={onChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          maxWidth: { xs: '95%', md: '80%' },
          '& .MuiTabs-indicator': {
            backgroundColor: '#9e8356',
            height: '3px',
          },
        }}
      >
        {categories.map(({ category }, index) => (
          <Tab
            key={category?.name_en}
            label={lang == 'ar' ? category?.name_ar : category?.name_en}
            sx={{
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '0%',
                height: '3px',
                backgroundColor: '#9e8356',
                transition: 'all .3s ease',
              },
              '&:hover:after': {
                width: '80%',
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  )
}

const ProductCarousel = ({ isLoading, products, lang }) => {
  const [imgLoading, setImgLoading] = useState(true)
  const navigate = useNavigate()
  const getSlidesPerView = (num) => {
    const maxSlides = Math.min(products.length, 4) // Adjust the maximum number of slides as needed
    return Math.min(maxSlides, num)
  }
  const handleImageLoad = () => {
    setImgLoading(false)
  }
  if (isLoading) return <AnimatedLoader />
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component={Swiper}
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          600: { slidesPerView: 2.8, spaceBetween: 20 },
          900: { slidesPerView: 3, spaceBetween: 20 },
          1200: { slidesPerView: 4, spaceBetween: 25 },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        sx={{
          width: { xs: '100%', md: '85%' },
          position: 'static',
          // mx: 'auto',
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <Box>
              <Box
                className="product-item"
                onClick={() => {
                  navigate(
                    `/products/${product?._id}/${product?.title_en.replace(
                      /\s/g,
                      '-'
                    )}`
                  )
                }}
                sx={{
                  width: '100%',
                  minHeight: '470px', //480px
                  aspectRatio: 0.6,
                  position: 'relative',
                  my: { xs: 1, md: 0 },
                  cursor: 'pointer',
                  '&:hover': {
                    '& img': {
                      opacity: 0,
                    },
                    '& img:nth-child(1)': {
                      opacity: 1,
                    },

                    '& .bttn': {
                      opacity: 1,
                      // bottom: '15%',
                    },
                  },
                }}
              >
                {imgLoading && (
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={'100%'}
                  />
                )}
                <Box>
                  <CardMedia
                    onLoad={handleImageLoad}
                    loading="lazy"
                    component="img"
                    image={`${imageBaseUrl}${product?.images[1]}`}
                    alt={`${product?.title_en}`}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      transition: 'all 0.5s ease',
                      objectFit: 'contain',
                    }}
                  />

                  <CardMedia
                    loading="lazy"
                    component="img"
                    image={`${imageBaseUrl}${product?.images[0]}`}
                    alt={`${product?.title_en}`}
                    onLoad={handleImageLoad}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      transition: 'all 0.5s ease',
                      objectFit: 'contain',
                    }}
                  />
                </Box>

                <Box
                  className="bttn"
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    position: 'absolute',

                    bottom: 0,
                    left: '60%',
                    transform: 'translateX(-50%)',
                    width: '100%',
                    opacity: 0,
                  }}
                >
                  <Link to={`/departments`} className="btn btn-fill-middle">
                    {lang == 'ar' ? 'تسوق الأن' : 'Shop now'}
                  </Link>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Box>
    </Box>
  )
}

const Scrolling5 = () => {
  const [, { language: lang }] = useTranslation()
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)

  const [
    getAllCategories,
    { error: CategoryError, isLoading: CategoryIsLoading },
  ] = useLazyGetAllCategoriesQuery()
  const {
    data: newCategories,
    isError: CategoryErrorN,
    isLoading: CategoryIsLoadingN,
  } = useGetAllProductsByCategoryQuery()
  useEffect(() => {
    if (!CategoryIsLoadingN && !CategoryErrorN) {
      const CategiriesFilterProducts = newCategories?.data?.filter(
        ({ products }) => products?.length
      )
      setCategories(CategiriesFilterProducts)
    }

    //   })
  }, [newCategories])

  useEffect(() => {
    setSelectedCategory(categories[0])
  }, [categories])
  const {
    data: categoryProducts,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProductsOfCategoryQuery(selectedCategory?._id)

  const handleChange = (event, newValue) => {
    setSelectedCategory(categories[newValue])
  }
  console.log(categoryProducts, '123123123')
  if (!categoryProducts) {
    return <></>
  }
  return (
    <Box className="shop_by_category" mb={6} minHeight={'600px'}>
      <Typography
        variant="h3"
        sx={{
          fontSize: { xs: '2rem', sm: '3rem', md: '2rem' },

          textAlign: 'center',
          mt: 7,
          mb: 2,
          'font-size': '2rem',
          'font-weight': 'bold',
          ' text-transform': 'uppercase',
        }}
      >
        {lang === 'en' ? 'Shop By Category' : 'تسوق حسب القسم'}
      </Typography>

      <CategoryTabs
        categories={categories}
        selectedCategory={selectedCategory}
        onChange={handleChange}
        lang={lang}
      />
      {/* {isError && error && <ErrorSection error={error} isError={isError} />}
       */}
      <ProductCarousel
        isLoading={isLoading}
        products={selectedCategory?.products || []}
        lang={lang}
      />
    </Box>
  )
}

export default Scrolling5
