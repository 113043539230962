import { Button, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { extractData } from './utls/getthefirstItem'
export default function Qualites(prop) {

    const [price, setPrice] = useState(0)
    const [pushIndex, setPushIndex] = useState(-1)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [productImages, setproductImages] = useState({
        image: '',
        images: [],
    })
    const checkQuantity = (item) => {
        console.log(item, 'itemcawwacwaac')
        return (
            (item?.child === undefined || item?.child?.length === 0) &&
            item?.quantity === 0
        )
    }

    function isColorSimilarToWhite(IsSelected, hexColor = "#fff", threshold = 0.8) {
        // Convert hex color to RGB
        if (hexColor !== "") {

            let r = parseInt(hexColor.substring(1, 3), 16)
            let g = parseInt(hexColor.substring(3, 5), 16)
            let b = parseInt(hexColor.substring(5), 16)

            // Calculate luminance
            let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255

            // Check if luminance is greater than the threshold

            if (luminance > threshold) {
                IsSelected ? "#fff" : "#333"
            } else {

                return '#fff'

            }
        }
        return IsSelected ? "white" : 'black'
    }

    const compareValues = (item1, item2) => {
        return item2.some(
            (item) =>
                item1?.key_ar === item?.key_ar &&
                item1?.key_en === item?.key_en &&
                item1?.value_ar === item?.value_ar &&
                item1?.value_en === item?.value_en
        )
    }



    // useEffect(() => {
    //     // if item not selected reset its values
       
    //     if(!!prop?.item[0]){
    //         setPushIndex(-1)
    //         setSelectedIndex(0);
    //         let  GetFirstItemChildsArray = extractData(prop?.item[0]);

    //         prop.setSelectedItems(GetFirstItemChildsArray?.extractedData)

    //         prop.setQuantity(GetFirstItemChildsArray?.details?.quantity)
    //         prop.setImages(GetFirstItemChildsArray?.details?.images)
    //         prop.setCheck(true)    
        
        
    //     }
     


    // }, [])

    // useEffect(() => {
    //     // if item not selected reset its values
       
    //     if(prop?.selectedItems?.length&&!!prop?.item[selectedIndex]){
         
    //         console.log(prop.pushIndex,'MModassssss')
    //         let  GetFirstItemChildsArray = extractData(prop?.item[selectedIndex]);

    //         prop.setSelectedItems(GetFirstItemChildsArray?.extractedData)

    //         prop.setQuantity(GetFirstItemChildsArray?.details?.quantity)
    //         prop.setImages(GetFirstItemChildsArray?.details?.images)
    //         prop.setCheck(true)    
        
    //         console.log(selectedIndex,pushIndex,'selecteditems')

    //     }
     

    // }, [selectedIndex])

    useEffect(() => {
        // if item not selected reset its values
        if (!compareValues(prop?.item[selectedIndex], prop.selectedItems)) {
            setPushIndex(-1)
            setSelectedIndex(-1);
            // const GetFirstItem=FirstItem(prop?.item[selectedIndex])

            // console.log(GetFirstItem,'GetFirstItem')

        }
     })


    
    return (
        <div>
            <Stack
                className="headerText"
                sx={{
                    margin: '10px 12px',
                }}
            >
                {prop?.language ? prop?.item[0]?.key_ar : prop?.item[0]?.key_en}
            </Stack>
            <Stack
                className="container"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: {
                        justifyContent: prop?.language ? 'flex-end' : 'flex-end',
                    },
                    flexWrap: 'wrap',
                }}
            >
                {prop?.item.map((item, index) => (
                    <Stack
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: prop?.language ? 'flex-end' : 'flex-end',
                            flexWrap: 'wrap',
                        }}
                    >
                        {
                            // console.log(checkQuantity(prop?.item[index]),prop?.item[index],'dassa34q')
                        }

                        {/* //لا تسب  لا تلعن غصب عني  */}
                        <Button

                            sx={{
                                backgroundColor: selectedIndex !== index
                                    ? `${item?.color} !important ` || '#fff !important'
                                    : '#333 !important',
                                border: '1px solid #ddd',
                                color:
                                    isColorSimilarToWhite(index === selectedIndex, item?.color),
                                margin: '3px 5px',
                                fontSize: '12px',
                                boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                width: prop?.isModal && item?.color !== "" ? { xs: '12px !important', sm: '26px  !important' } :
                                    'fit-content !important',
                                minWidth: prop?.isModal && item?.color !== "" ?
                                    { xs: '12px !important', sm: '20px  !important' } : '64px',

                                height: prop?.isModal && item?.color !== "" ? { xs: '20px', sm: '26px' } : '30px',
                                opacity:checkQuantity(prop?.item[index])?`0.4 !important ` :`1 !important ` ,

                                '&::after': {
                                    display: prop?.isModal && item?.color !== "" ? "block" : 'none',
                                    position: index === selectedIndex ?
                                        'absolute' : 'none',
                                    height: '1px',
                                    content: index === selectedIndex ? "''" : 'unset',
                                    width: {
                                        xs: '30px',
                                        md: '40px'
                                    },
                                    background: '#333',
                                    transform: 'rotate(136deg)',
                                    top: '10px',
                                    left: '-3px',
                                }
                            }}
                            // disabled={checkQuantity(prop?.item[index])}
                            onClick={() => {
                                //save the selected item index
                                setSelectedIndex(index)
       
                                //make sure there are no more children 
                                //in order to add the item
                                //add price and image if image exists 

                                if (item?.child == undefined ||
                                    item?.child?.length == 0) {
                                    prop.setCheck(true)
                                    prop.setQuantity(item.quantity)

                                    prop.setPrice(item.price)
                                    console.log(item?.image != undefined ,
                                     item?.image?.length > 0)
                                    if (item?.image?.length > 0) {
                                        prop.setImages(item?.image)
                                        prop.setImage(item?.image[0])
                                    }else{
                                     prop.setImages(prop.defaultImages)
                                     prop.setImage(prop.defaultImages[0])
 
                                    }


                                }
                                //other wise reset
                                else {
                                    prop.setCheck(false)
                                    prop.setQuantity(0)
                                    prop.setPrice(0)
                                    prop.setImages(prop.defaultImages)
                                    prop.setImage(prop.defaultImages[0])


                                }
                                //if item wasnt selected before add and 
                                // save the index in selectedItems
                                if (pushIndex == -1) {
                                    setPushIndex(prop?.selectedItems?.length)
                                    prop.setSelectedItems([...prop.selectedItems, {
                                        key_en: item?.key_en, key_ar: item?.key_ar,
                                        value_en: item?.value_en, value_ar: item?.value_ar,
                                    }])
                                }
                                //otherwise replace current item
                                else {
                                    //remove all items after it
                                    if (pushIndex < prop?.selectedItems?.length - 1)
                                        prop.selectedItems.splice(pushIndex,
                                            prop?.selectedItems?.length - pushIndex - 1)
                                    prop.selectedItems.splice(pushIndex, 1,
                                        {
                                            key_en: item?.key_en,
                                            key_ar: item?.key_ar,
                                            value_en: item?.value_en,
                                            value_ar: item?.value_ar,
                                        })
                                }

                         }}

                        >
                            {
                                console.log(item?.color, prop?.isModal, 'item?.color')
                            }

                            {
                                prop?.isModal && item?.color !== "" ? <></> : <> {prop.language ? `${item.value_ar} ` : item.value_en} </>

                            }

                        </Button>
                    </Stack>
                ))}
            </Stack>
            {selectedIndex === -1 ||
                prop?.item[selectedIndex]?.child === undefined ||
                prop?.item[selectedIndex]?.child?.length === 0 ? null : (
                <div>
                    <Qualites
                        item={prop?.item[selectedIndex].child}
                        defaultImage={prop.defaultImage}
                        setImage={prop.setImage}
                        check={prop.check}
                        setCheck={prop.setCheck}
                        setQuantity={prop.setQuantity}
                        pushIndex={pushIndex+1}

                        defaultImages={prop.defaultImages}
                        setImages={prop.setImages}
                        images={prop.images}
                        setPrice={prop.setPrice}
                        selectedItems={prop.selectedItems}
                        setSelectedItems={prop.setSelectedItems}
                        language={prop.language}
                        isModal={prop?.isModal}
                    />
                </div>
            )}
        </div>
    )
}
