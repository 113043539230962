import { createSlice } from "@reduxjs/toolkit";

export const ProductModalSlice = createSlice({
    name:'productModal',
    initialState:{
            open:false,
            product:{},

    },
    reducers:{
        openModal:(state,action)=>{
                state.open=true,
                state.product=action.payload
        },
        closeModal:(state,action)=>{
            state.open=false;
            state.product={}
        }
    }


});
export const ProductModalReducer = ProductModalSlice.reducer;
export const ProductModalAction = ProductModalSlice.actions