import { useEffect, useState } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import AppRoutes from './router/Router'
import logoPath from './assets2/LOGO DRESS CODE-01.png'
// import TomoorTextErea from './components/Footers/Tomoor/TomoorTextErea/index.jsx'
import { useTranslation } from 'react-i18next'
import { useLazyGetMeQuery } from './redux/apis/UserApis.js'
// import Navbar_ButtunStyle_menu from './layouts/Navbars/buttonStyle/with_menu/Navbar_ButtunStyle_mnu.jsx'
import { useCreateGuestUserMutation } from './redux/apis/gestUserApi.js'
import JarirTextEarea from './components/Footers/jarir/jarirTextErea/index.jsx'
import Navbar from './layouts/Navbar/Navbar.jsx'
import ProductModal from './pages/singleProductPages/noon1/ProductModal/index.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
function App() {
  const [getMe] = useLazyGetMeQuery()
  const [, { _, changeLanguage }] = useTranslation()
  const [createGuestUser] = useCreateGuestUserMutation()
  const { open, product } = useSelector((state) => state.ProductModalReducer)

  useEffect(() => {
    if (localStorage?.i18nextLng) {
      changeLanguage(localStorage?.i18nextLng)
    }
  }, [])
  useEffect(() => {
    if (localStorage.token) {
      getMe()
        .unwrap()
        .then((res) => res)
        .catch((err) => {
          createGuestUser()
            .unwrap()
            .then((res) => {
              localStorage.setItem('token', res.token)
            })
            .catch((err) => {
              console.log(err)
            })
        })
    }
  }, [])

  useEffect(() => {
    if (!localStorage.token) {
      createGuestUser()
        .unwrap()
        .then((res) => {
          localStorage.setItem('token', res.token)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    var link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.head.appendChild(link)
    }
    link.href = 'https://dresscodesa.com/2a1fb51564a54eba1b56.png'
  }, [])
  return (
    <BrowserRouter>
      <Navbar logoPath={logoPath} />
      <AppRoutes />
      <JarirTextEarea logo={logoPath} />
      <ProductModal open={open} item={product} />
    </BrowserRouter>
  )
}
export default App
