import {
  Badge,
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetAllCartsQuery } from '../../redux/apis/cartApi'
import { useGetAllSavedProductsQuery } from '../../redux/apis/SavedProductApi'

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import Style from './Styles'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ProfileButton from './ProfileButton'
import Notifications from '../../components/Notifications/Notifications'
import { useGetCurrencyQuery } from '../../redux/apis/currencyApi'
import { countries } from '../../components/providers/countries'
import { CurencyActions } from '../../redux/slices/currency/currencySlice'

export const CountriesSelect = (props) => {
  const [, { language: lang }] = useTranslation()
  const dispatch = useDispatch()
  const { data } = useGetCurrencyQuery()
  const [country, setCountry] = useState(
    JSON.parse(localStorage.getItem('country'))?.value || 'sar'
  ) //when refresh get the value from local storage
  const handleChange = (event) => {
    localStorage.setItem(
      'country',
      JSON.stringify(
        countries.find((country) => country.value === event.target.value)
      )
    )
    setCountry(event.target.value)
  }

  useEffect(() => {
    // to avoid null value and sure localStorage has value
    localStorage.setItem(
      'country',
      JSON.stringify(
        JSON.parse(localStorage.getItem('country')) || {
          label: 'SA - Saudi Riyal (ر.س)',
          value: 'sar',
          currency: data?.data[0]?.rates[`${country.toUpperCase()}`],
        }
      )
    )
    setCountry(JSON.parse(localStorage.getItem('country'))?.value || 'sar')
  }, [])

  const countryData = JSON.parse(localStorage.getItem('country'))

  useEffect(() => {
    dispatch(
      CurencyActions.setCurrency({
        currency: data?.data[0]?.rates[`${country.toUpperCase()}`] || 1,
        label: countryData?.label || 'SAR - Saudi Riyal (ر.س)',
      })
    )
  }, [country, data?.data])

  return (
    <Box
      sx={{
        minWidth: 'auto',
        mr: lang === 'ar' && '10px',
        ml: lang === 'en' && '10px',
        width: 'fit-content',
      }}
      className={'currencyContainer'}
    >
      <Select
        id="demo-simple-select"
        value={country}
        onChange={handleChange}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #fff',
          height: '25px',
          width: '40px',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          p: 0,
          '& > .currency-flag': {
            opacity: '0 !important',
            width: '50px',
            height: '20px',
            margin: '10px',
          },

          '& #demo-simple-select': {
            justifyContent: lang === 'ar' ? 'flex-end' : 'flex-start',
          },
          '& > svg': {
            color: '#fff',
            height: '15px',
            width: '15px',
            top: '3px',
            right: lang === 'ar' ? '-2px' : 'auto',
            left: lang === 'ar' ? 'auto' : '-2px',
          },
          '.MuiSelect-select': {
            paddingRight: lang === 'ar' ? '10px !important' : '-1px !important',
            paddingBottom: '10px !important',
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px', // Set your custom height here
            },
          },
          disableScrollLock: true,
        }}
      >
        {countries.map((country) => {
          return (
            <MenuItem
              value={country.value}
              key={country.value}
              onClick={(e) =>
                JSON.parse(localStorage.getItem('country'))?.value
              }
              sx={{
                gap: 2,
              }}
            >
              <div
                className={`currency-flag currency-flag-${country.value}`}
              ></div>
              {/* {country.currency} */}
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

const NavIcons = () => {
  const [, { language: lng, changeLanguage }] = useTranslation()
  const { data: dataCart, error: errorCart } = useGetAllCartsQuery()
  const { data: favPros, error: errorFav } = useGetAllSavedProductsQuery()
  const toggleLanguage = () => {
    lng === 'en' ? changeLanguage('ar') : changeLanguage('en')
  }
  return (
    <Box
      sx={{
        direction: lng === 'en' ? 'ltr' : 'rtl',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '0px',
        gap: {
          md: '10px',
          xs: '5px',
        },
        ml: {
          lg: lng === 'ar' ? '50px' : undefined,
          md: lng === 'ar' ? '35px' : undefined,
          xs: lng === 'ar' ? '20px' : undefined,
        },
        mr: {
          lg: lng === 'en' ? '50px' : undefined,
          md: lng === 'en' ? '35px' : undefined,
          xs: lng === 'en' ? '20px' : undefined,
        },
      }}
    >
      <ProfileButton />
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Notifications
          lng={lng}
          iconColor={'#9E8356'}
          bgColorBtn={'transparent'}
        />
      </Box>
      <Badge
        sx={{ ...Style.Badge, mt: 1 }}
        badgeContent={favPros && !errorFav ? favPros.data.favourite.length : 0}
      >
        <Link to={'/savedProducts'}>
          <FavoriteBorderOutlinedIcon
            sx={{ ...Style.navIcons.icon, color: '#fff' }}
          />
        </Link>
      </Badge>
      <Badge
        badgeContent={dataCart && !errorCart ? dataCart.data.totalQuantity : 0}
        sx={{ ...Style.Badge, mt: 1 }}
      >
        <Link to={'/cart'}>
          <ShoppingCartIcon sx={{ ...Style.navIcons.icon, color: '#fff' }} />
        </Link>
      </Badge>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CountriesSelect />
      </Box>
      <Button onClick={toggleLanguage} sx={Style.navIcons.languageButton}>
        {lng === 'en' ? 'Ar' : 'En'}
      </Button>
    </Box>
  )
}

export default NavIcons
