import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material'

import Rating from './Rating'
import { useTranslation } from 'react-i18next'
import DraftEditor from './draftEditor/DraftEditor'
import { useEffect, useState } from 'react'
import { useCreateCommentForProductMutation } from '../../redux/apis/commentApi'
import { toast } from 'react-toastify'

const RatingModal = ({ productAssets }) => {
  const [_, { language: lang }] = useTranslation()
  const [selectedRate, setRating] = useState(0)
  const [typedComment, setTypingComment] = useState('')
  const [createCommentForProduct] = useCreateCommentForProductMutation()
  const [hasValue, setHasValue] = useState('')

  const handleClose = () => {
    setTypingComment('')
    setRating(0)
  }
  const addingReviewForProduct = () => {
    let temp = {
      comment: typedComment,
      rating: selectedRate || 0,
    }
    // selectedRate === 0 ? delete temp.rating : undefined
    createCommentForProduct({
      payload: temp,
      productId: productAssets.id,
    })
      .unwrap()
      .then((res) => {
        toast.success(
          lang === 'en'
            ? 'Thanks for your comment and will be reviewed'
            : 'شكرا على تعليقك و سوف يتم مراجعته'
        )
        handleClose()
      })
      .catch((err) => {
        toast.error(err?.data?.[`error_${lang}`])
      })
  }
  return (
    <Box
      sx={{
        width: '70%',
        margin: 'auto',
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box
        sx={{ marginBottom: '12px', display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {lang === 'en'
            ? `Product Rating & Reviews`
            : `تقييم المنتج والتعليقات`}
        </Typography>
        <Rating totalRating={productAssets?.rating} disable={true} />
        <Typography variant="p">
          {lang === 'en' ? 'Add rate and review' : 'أضف تقييم و تعليق'}
        </Typography>
      </Box>
      <div
        style={{
          border: '1px solid black',
          padding: '12px',
        }}
      >
        <Box>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography variant="p">
              {lang === 'en' ? 'Rate' : 'تقييم'}{' '}
            </Typography>
            <Rating /* totalRating={productRating} */
              setRating={setRating}
              totalRating={selectedRate}
              id={productAssets.id}
              disable={false}
            />
          </Stack>
          <Box>
            {/* <TextField fullWidth placeholder="Add Your Comment" /> */}
            <DraftEditor
              handleChange={(value) => {
                setTypingComment(value)
              }}
              setHasValue={setHasValue}
              handleBlur={(v) => {}}
            />
          </Box>
          <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
            <Button
              onClick={addingReviewForProduct}
              disabled={hasValue?.trim() === ''}
              sx={{
                marginTop: '12px',
                border: '1px solid black',
                width: '100%',
                bgcolor: 'transparent !important',
                color: 'black',
              }}
              variant="contained"
            >
              {lang === 'en' ? 'Add' : 'أضافة'}
            </Button>
          </Stack>
        </Box>
      </div>
    </Box>
  )
}

export default RatingModal
