export const Nooncolors = {
  attrKeyColor: '#000',
  attrValueColor: '#333',
  buttonBgColor: '#000',
  buttonColor: 'white !important',
  priceBeforeColor: '#000',
  titleColor: '#000',
  attrValueBgColor: 'transparent',
  descColor: '#333',
  priceAfterColor: '#333',
  attrValueBorderColor: '#eee',

  attrAciveColors: {
    background: '#333 !important',
    ActiveColor: '#fff !important',
  },
  commentsColors: {
    sectionBg: '#F7F7FA',
    heading: 'green',
    borderColor: 'divider',
  },
}
