import {
  Autocomplete,
  Box,
  Container,
  TextField,
  Typography,
  Stack,
  Grid,
  FormLabel,
  Divider,
  Button,
  InputBase,
  CircularProgress,
  InputAdornment,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useMemo, useState, useEffect } from 'react'
import * as yup from 'yup'
import { useAddOrderMutation } from '../../../redux/apis/ordersApi'
import { useGetUserCodeMutation } from '../../../redux/apis/verifiedCodeApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import PhoneIcon from '@mui/icons-material/Phone'

import {
  useGetAllCartsQuery,
  useVerifyCartMutation,
} from '../../../redux/apis/cartApi'
import {
  cityArabic,
  cityEnglish,
} from '../../../components/providers/city-english'
import VerifiedCode from '../../../components/verification/VerifiedCode'
import { useLazyGetMeQuery } from '../../../redux/apis/UserApis'
import { CheckOutTomoor1Style } from './Style'
import { useTranslation } from 'react-i18next'
import { locations, useFilterAddress, useFilterAreas } from '../locations'
import SelectMenu from '../SelectMenu'

// ================================|| CHECKOUT - BILLING DETAILS ||================================ //
const BillingDetails = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [userPhone, setUserPhone] = useState('')
  const [userData, setUserData] = useState({})
  const [_, { language }] = useTranslation()
  const [addOrder, { isLoading: orderLoad }] = useAddOrderMutation()
  const { refetch } = useGetAllCartsQuery(undefined)
  const [getUserCode, { isLoading: codeLoad }] = useGetUserCodeMutation()
  const navigate = useNavigate()
  const [getMe] = useLazyGetMeQuery(undefined)
  // const [location, setLocation] = useState(null)

  const [submitCheckout, { isLoading: verfyCouponLoading }] =
    useVerifyCartMutation()

  useEffect(() => {
    getMe(undefined).then(({ data }) => {
      if (data?.data) {
        setUserData(data?.data)
        formik.setFieldValue('email', data?.data?.email || '')
        formik.setFieldValue('phone', data?.data?.phone || '')
        formik.setFieldValue('name', data?.data?.name || '')
      }
    })
  }, [])
  const handelerCode = (code, phone) => {
    getUserCode({ code, phone })
      .unwrap()
      .then((res) => {
        refetch()
        toast.success(res[`success_${language}`])
        if (res.paymentType === 'cash') {
          navigate('/thankYou')
        } else {
          navigate('/select-payment')
        }
      })
      .catch((err) => {
        toast.error(err.data[`error_${language}`])
      })
  }

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords
  //         setLocation({ latitude, longitude })
  //          
  //       },
  //       (error) => {
  //         console.error('Error getting location:', error)
  //         toast.error(
  //           language === 'en'
  //             ? 'You Have To Enable Your Location Please'
  //             : ' يجب عليك تمكين موقعك من فضلك'
  //         )
  //       }
  //     )
  //   } else {
  //     console.error('Geolocation is not supported by this browser.')
  //   }
  // }, [])

  // const handelerCode = (code, phone) => {
  //   getUserCode({ code, phone })
  //     .unwrap()
  //     .then((res) => {
  //       refetch()

  //       toast.success(res[`success_${language}`])
  //       if (res.paymentType === 'cash') {
  //         navigate('/thankYou')
  //       } else {
  //         navigate('/payment-order')
  //       }
  //     })
  //     .catch((e) => {
  //       if (e?.data) {
  //         toast.error(language === 'en' ? e?.data?.en : e?.data?.ar)
  //       } else {
  //         toast.error(language === 'en' ? e?.en : e?.ar)
  //       }
  //     })
  // }
  const { regions, cities } = locations
  const [referenceValues, setReferenceValues] = useState()

  const findItem = (array, field, value) =>
    array.find((obj) => obj[field] === value)
  const couponData = JSON.parse(localStorage.getItem('couponData'))

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      area: '',
      city: '',
      postalCode: '',
      phone: '',
      email: '',
      orderNotes: '',
      country: 'SA',
      // Longitude: '',
      // Latitude: '',
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(language === 'en' ? 'Name Required' : 'الاسم مطلوب')
       ,

      area: yup
        .string()
        .required(language === 'en' ? 'City Required' : 'المدينة مطلوبة'),
      address: yup
        .string()
        .required(language === 'en' ? 'Address Required' : 'العنوان مطلوب'),
      city: yup
        .string()
        .required(language === 'en' ? 'Area Required' : 'المنطقة مطلوبة'),
      phone: yup
        .string()
        .required(language === 'en' ? 'Phone Required' : 'الهاتف مطلوب')
        .matches(
          /^\+?[0-9]+$/, // Updated regular expression to allow only digits
          language === 'en'
            ? 'Phone must contain only numbers'
            : 'يجب أن يحتوي الهاتف على أرقام فقط'
        )
        .length(
          userData?.email && !userData?.phone ? 9 : 13,
          language === 'en'
            ? 'Phone must be 9 digits'
            : 'يجب أن يكون رقم الهاتف 9 رقمًا'
        ),
      email: yup
        .string()
        .required(
          language === 'en' ? 'Email Required' : 'البريد الالكتروني مطلوب'
        )
        .email(
          language === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صالح'
        ),
      postalCode: yup
        .string()
        .matches(
          /^\d+$/,
          language === 'en'
            ? 'Postal Code must contain only numbers'
            : 'يجب أن يحتوي الرمز البريدي على أرقام فقط'
        )
        .required(
          language === 'en' ? 'Postal Code Required' : 'الرمز البريدي مطلوب'
        ),
      orderNotes: yup.string().optional(),
    }),
    onSubmit: (values) => {
      const data = {
        ...values,
        phone: values.phone.startsWith('+966')
          ? values.phone
          : `+966${values.phone}`,
        // Longitude: location?.longitude.toString(),
        // Latitude: location?.latitude.toString(),
      }
      if (values.orderNotes === '') {
        delete data.orderNotes
      }

      if (couponData && couponData?.couponEnter !== '') {
        submitCheckout({
          productsIds: couponData?.products,
          code: couponData?.couponEnter,
        })
          .unwrap()
          .then((res) => {
            toast.success(res[`success_${language === 'en' ? 'en' : 'ar'}`])

            // cartApi.endpoints.getAllCarts.initiate()
            // localStorage.removeItem('couponData')
            localStorage.removeItem('couponData')

            let temp = {
              ...data,
              city:
                findItem(regions, 'region_id', formik.values.city)?.name_ar ||
                '',
              area:
                findItem(cities, 'city_id', formik.values.area)?.name_ar || '',
            }
            addOrder(temp)
              .unwrap()
              .then((res) => {
                setUserPhone(res?.data?.phone)
                setReferenceValues(temp)
                setOpenModal(true)
              })
              .catch((e) => {
                if (e?.data) {
                  toast.error(language === 'en' ? e?.data?.en : e?.data?.ar)
                } else {
                  toast.error(language === 'en' ? e?.en : e?.ar)
                }
              })
            // nav('/checkout')
          })
          .catch((e) => {
            localStorage.removeItem('couponData')
             // toast.error(e?.data[`error_${lng === 'en' ? 'en' : 'ar'}`]);
            if (e?.data) {
              toast.error(language === 'en' ? e?.data?.en : e?.data?.ar)
            } else {
              toast.error(language === 'en' ? e?.en : e?.ar)
            }
          })
      } else {
        let temp = {
          ...data,
          city:
            findItem(regions, 'region_id', formik.values.city)?.name_ar || '',
          area: findItem(cities, 'city_id', formik.values.area)?.name_ar || '',
        }
        addOrder(temp)
          .unwrap()
          .then((res) => {
            setUserPhone(res?.data?.phone)
            setReferenceValues(temp)
            setOpenModal(true)
          })
          .catch((err) => {
            toast.error(
              err?.data?.[language === 'en' ? 'error_en' : 'error_ar']
            )
          })
      }
    },
  })
  const DataEn = useMemo(() => cityEnglish, [cityEnglish, language])
  const DataAr = useMemo(() => cityArabic, [cityArabic, language])
  const { filteredAreas } = useFilterAreas(formik.values.city)
  const handleResendSMSCode = () => {
    addOrder(referenceValues)
      .unwrap()
      .then((res) => {
        toast.success(
          language === 'en'
            ? 'Another code has been re-sent'
            : 'تم اعادة ارسال كود اخر'
        )
      })
      .catch((e) => {
        if (e?.data) {
          toast.error(language === 'en' ? e?.data?.en : e?.data?.ar)
        } else {
          toast.error(language === 'en' ? e?.en : e?.ar)
        }
      })
  }
   
  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Container
        sx={{
          py: 2,
          minWidth: { xs: '100%', md: '97%' },
        }}
      >
        <Box
          sx={{
            borderBottom: 2,
            borderColor: props.color ? props.color : 'gray',
            py: 2,

            marginBottom: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '25px', md: '30px' },
              color: props.color?.title,
            }}
          >
            {language === 'en' ? 'Billing Details' : 'تفاصيل الفاتورة'}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: props.color?.label, mt: 2 }} />
        {/* form */}

        <form onSubmit={formik.handleSubmit}>
          <Box mt={4}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {/* {language === 'en' ? 'First Name and Last Name' : 'الاسم الاول والاخير'} */}
              {language === 'en' ? 'Name' : 'الأسم'}
            </FormLabel>
            <InputBase
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.name}
              </Typography>
            ) : null}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Address' : 'العنوان'}
            </FormLabel>
            <InputBase
              name="address"
              onBlur={formik.handleBlur}
              value={formik.values.address}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' },
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.address && formik.errors.address && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.address}
              </Typography>
            )}
          </Box>

          <SelectMenu
            name={`city`}
            label={language === 'en' ? 'Area' : 'المنطقة'}
            value={formik.values.city}
            touched={formik.touched.city}
            error={formik.errors.city}
            handleChange={formik.handleChange}
            handleBlur={formik.handleChange}
            options={regions}
            optionValue={`region_id`}
            // optionValue={`name_ar`}
            field={`name_${language}`}
          />
          <SelectMenu
            name={`area`}
            value={formik.values.area}
            label={language === 'en' ? 'City' : 'المدينة'}
            touched={formik.touched.area}
            error={formik.errors.area}
            handleChange={formik.handleChange}
            handleBlur={formik.handleChange}
            options={filteredAreas}
            optionValue={`city_id`}
            // optionValue={`name_ar`}
            field={`name_${language}`}
          />
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Postal Code' : 'الرمز البريدي'}
            </FormLabel>
            <InputBase
              name="postalCode"
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.postalCode && formik.errors.postalCode && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.postalCode}
              </Typography>
            )}
          </Box>

          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Phone' : 'الهاتف'}
            </FormLabel>

            <Box
              sx={{
                direction: 'rtl',
              }}
            >
              <InputBase
                name="phone"
                onBlur={formik.handleBlur}
                endAdornment={
                  <InputAdornment position="start">
                    <InputAdornment
                      position="end"
                      className="startIIII"
                      sx={{
                        padding: '0px 20px',
                        mx: 1,
                        height: '100%',
                        padding: '31px 9px',
                        'border-right': '1px solid #333',
                      }}
                    >
                      966+
                    </InputAdornment>
                    <PhoneIcon
                      sx={{
                        ml: 1,
                      }}
                    />
                  </InputAdornment>
                }
                value={
                  userData?.email && !formik.values.phone.startsWith('+966')
                    ? `${formik.values.phone}`
                    : formik.values.phone
                }
                onChange={(event) => {
                  const { value, selectionStart, selectionEnd } = event.target
                  const prefix = '+966'
                  // Check if the value starts with "+966" and only allow editing the part after it
                  if (userData?.email && !userData?.phone) {
                    //   if (value.startsWith('+966')) {
                    //     const userInput = value.substring(4) // Remove "+966" from the input
                    //     formik.handleChange('phone')(userInput) // Update the user's input
                    //   } else {
                    //     // If the input does not start with "+966," keep the previous value
                    //     formik.handleChange('phone')(formik.values.phone)
                    //   }
                    // } else {
                    //   // When user doesn't have an email, prevent deletion of "+966"
                    //   if (
                    //     (selectionStart && selectionStart < prefix.length) ||
                    //     (selectionEnd && selectionEnd < prefix.length)
                    //   ) {
                    //     event.preventDefault()
                    //   } else {
                    //   }
                    formik.handleChange('phone')(value)
                  }
                }}
                sx={{
                  height: { xs: '65px', sm: '75px', md: '60px' },
                  borderRadius: 0,
                  border: `2px solid ${
                    props.color?.label ? props.color?.label : 'gray'
                  }`,
                  mt: 2,
                  '& input': {
                    fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                    ml: 2,
                  },
                  width: '100%',
                  backgroundColor: 'transparent !important',
                  px: language === 'en' ? 0 : 2,
                  '& > .MuiInputBase-input': {
                    textAlign: 'left !important',
                    direction: 'ltr',
                  },
                }}
              />
            </Box>
            {formik.touched.phone && formik.errors.phone && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.phone}
              </Typography>
            )}
          </Box>

          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Email Address' : 'البريد الالكتروني'}
            </FormLabel>
            <InputBase
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                width: '100%',
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.email}
              </Typography>
            )}
          </Box>

          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en'
                ? ' Order Notes (optional)'
                : 'ملاحظات الطلب (اختياري)'}
            </FormLabel>
            <InputBase
              name="orderNotes"
              onBlur={formik.handleBlur}
              value={formik.values.orderNotes}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                width: '100%',
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.orderNotes && formik.errors.orderNotes && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.orderNotes}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Button
              disabled={ orderLoad}
              type="submit"
              sx={{
                mt: 6,
                mb: 5,
                width: { xs: '80%', md: '50%' },
                height: '60px',
                borderRadius: '5px',
                bgcolor: props.button?.backgroundColor
                  ? `${props.button?.backgroundColor} !important`
                  : 'black !important',
                color: props.button?.color
                  ? `${props.button?.color} !important`
                  : 'white',
                fontSize: { xs: '15px', md: '20px' },
                textTransform: 'none',
              }}
            >
              {orderLoad ? (
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress
                    sx={{
                      color: props.button?.color || 'white',
                    }}
                  />
                </Box>
              ) : language === 'en' ? (
                'Complete Order'
              ) : (
                'اكمل الطلب'
              )}
            </Button>
          </Box>
        </form>
      </Container>

      <VerifiedCode
        open={openModal}
        handelerCode={handelerCode}
        handleClose={setOpenModal}
        userPhone={userPhone}
        loading={codeLoad}
        isCopoun={couponData && couponData?.couponEnter !== ''}
        SMSCodeOptions={{
          loading: orderLoad,
          handleResendSMSCode,
        }}
      />
    </Box>
  )
}
// ================================================================================================ //

// ===========================|| CHECKOUT ||=========================== //

const CheckOutTomoor1 = () => {
  const [, { language }] = useTranslation()

  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
        mt: 5,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '90%' },
          mx: 'auto',
        }}
      >
        <BillingDetails
          color={CheckOutTomoor1Style.color}
          button={CheckOutTomoor1Style.button}
        />
      </Box>
    </Box>
  )
}

export default CheckOutTomoor1
